import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DonutChart from '../../form/DonutChart';
import { Grid } from '@mui/material';
import { useState } from 'react';
import moment from 'moment';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

export default function BasicCard({ datas, viewTabs }) {
    const [count, setcount] = useState([
        {
            no: 1, name: 'UI'
        }, {
            no: 2, name: 'HTML'
        }, {
            no: 3, name: 'Integration'
        }, {
            no: 4, name: 'Data Entry'
        }, {
            no: 5, name: 'Testing'
        },
    ])


    return (
        <Card sx={{ width: 550, maxWidth: 550, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)' }}>
            <CardContent>
                {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Word of the Day
                </Typography> */}
                <Typography style={{cursor:'pointer'}} onClick={() => viewTabs(datas.id)} variant="h6" component="div">
                    {datas.name}
                </Typography>
                <Grid container mt={1} display={'flex'} justifyContent={'space-between'} spacing={1}>
                    {count.map((item, index) => (
                        <Grid item xs={2} key={index}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                                <DonutChart width={140} />
                                <h6 style={{ marginTop: -10,fontSize:'13px' }}>{item.name}</h6>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <Grid display={'flex'} justifyContent={'space-between'}>
                    <a style={{ fontSize: '12px', fontWeight: 600, color: 'grey' }}>Created user: <span style={{ color: 'black' }}>{datas?.created_user?.name}</span></a>
                    <a style={{ fontSize: '12px', fontWeight: 600, color: 'grey' }}>Created at: <span style={{ color: 'black' }}>{moment(datas?.created_at).format('DD-MM-YYYY')}</span></a>
                </Grid>
            </CardContent>
        </Card>
    );
}
