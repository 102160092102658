import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Backdrop, Button, Card, CircularProgress, Grid, TextField } from "@mui/material";
import Details from './Tabs/Details';
import Members from './Tabs/Members';
import Projects from './Tabs/Projects';
import WorkReqNotes from './Tabs/Notes';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Users } from '../../../api/Endpoints/Users';
import { SubscribeApi } from '../../../api/Endpoints/Subscribe';
import { useEffect } from 'react';
import WRSubscribeTab from './Tabs/Subscribers';
import WRTask from './Tabs/Task';
import Documents from './Tabs/Documents';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs({ setTabs, WorkID }) {
    const [value, setValue] = React.useState(0);
    const [subscription, setSubscription] = useState(false)
    const [refresh, setRefresh] = useState()
    const [userID, setUserID] = useState()
    const [subscribeLoading, setsubscribeLoading] = useState(false)



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const subscribeWR = async () => {


        let dataToSubmit = {
            subscribable_type: "work_request",
            subscribable_id: WorkID,
            users_id: userID
        }
        if (!subscription) {
            setsubscribeLoading(true)
        }
        SubscribeApi.subscribe(dataToSubmit).then((response) => {
            console.log(response);
            setSubscription(true)
            setsubscribeLoading(false)
            toast.success(response.data.message)
            setRefresh(Math.random() * 0.2)
            console.log(refresh);
        })
    }

    const fetchUser = async () => {
        const accessToken = window.localStorage.getItem('accessToken');
        const user = await Users.me(accessToken);
        setUserID(user.data.data.id)
    }
    const checkSubscription = async () => {
        const accessToken = window.localStorage.getItem('accessToken');
        const user = await Users.me(accessToken);
        let subscriptions = await SubscribeApi.getSubscribers({ subscribable_type: 'work_request', subscribable_id: WorkID })
        console.log(subscriptions);
        let subscribed = subscriptions.data.data.data.some(item => item.users_id == user.data.data.id)
        console.log(subscribed);
        if (subscribed) {
            setSubscription(true)
        }
    }

    useEffect(() => {
        fetchUser()
        checkSubscription()
    }, [])

    // useEffect(() => {
    //     checkSubscription()
    // }, [subsRefresh])

    return (
        <>

            <Box sx={{ width: '100%', mt: 5 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Grid display={'flex'} justifyContent={'end'}>
                        <Button
                            variant="contained"
                            onClick={subscribeWR}
                            style={{ marginRight: 20, height: 30, width: 90, backgroundColor: subscription ? '#78b525' : 'blue' }}>
                            {subscribeLoading ? <CircularProgress size={20} color="inherit" /> : subscription ? 'Subscribed' : "Subscribe"}
                        </Button>
                    </Grid>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ ml: 2 }}>
                        <Tab label="Details" {...a11yProps(0)} />
                        <Tab label="Tasks" {...a11yProps(1)} />
                        <Tab label="History" {...a11yProps(2)} />
                        <Tab label="Notes" {...a11yProps(3)} />
                        <Tab label="Subscribers" {...a11yProps(4)} />
                        <Tab label="Documents" {...a11yProps(5)} />

                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Details WorkID={WorkID} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <WRTask WorkID={WorkID} />
                </TabPanel>
                <TabPanel value={value} index={2}>

                </TabPanel>
                <TabPanel value={value} index={3}>
                    <WorkReqNotes WorkID={WorkID} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <WRSubscribeTab id={WorkID} refresh={refresh} setSubscription={setSubscription} userID={userID} setRefresh={setRefresh} setTab={setValue} />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <Documents />
                </TabPanel>

            </Box>
            <Divider />

            <div style={{ width: '95%', display: 'flex', justifyContent: 'end', marginTop: 5 }}>
                <Button variant="contained" onClick={() => setTabs(false)} style={{ width: 'fit-content', mr: 7 }}>
                    Cancel
                </Button>

            </div>
        </>
    );
}
