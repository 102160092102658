import React, { useEffect, useState } from "react";
import { useAppSettings } from "../../../hooks/use-app-settings";
import moment from "moment";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputLabel,
  Rating,
  Tabs,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Activities } from "../../../api/Endpoints/Activities";
// import Create from "./create";
// import DetailView from './DetailView';
import ReactSelector from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Employee } from "../../../api/Endpoints/Employee";
import { Project } from "../../../api/Endpoints/Project";
import EditIcon from "@mui/icons-material/Edit";
import { Edit } from "@mui/icons-material";
import { UserIdContext } from "../../../contexts/UserID/UserID";
import { Departments } from "../../../api/Endpoints/Departments";
import CurrentDateInput from "../../form/CurrenDateInput";
import Create from "./create";

const scheme = yup.object().shape({});

const Index = (props) => {
  const { UserID, UserName, ID, userDetail } = UserIdContext();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm({ resolver: yupResolver(scheme) });
  const [editId, setEditId] = useState();
  const [refresh, setRefresh] = useState(0);
  const [listByProject, setListByProject] = useState(false);
  const [detailViewId, setDetailViewId] = useState(false);
  const [projects, setProjects] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [selectedDepartments, setselectedDepartments] = useState(null);
  const [selectedProjectID, setSelectedProjectID] = useState(null);
  const [tabValue, setTabValue] = useState("My Ratings");

  const DetailViewOpen = (id) => {
    setDetailViewId(id);
  };
  const handleEdit = (id) => {
    setRefresh(Math.random);
    setEditId(parseInt(id));
  };
  const [ratingData, setRatingData] = useState([
    {
      id: 1,
      stars: 5,
      reason: "Excellent work",
      type: "positive",
      date: "2022-01-01",
      rated: "John Doe",
    },
    {
      id: 2,
      stars: 3,
      reason: "Average work",
      type: "positive",
      date: "2022-01-02",
      rated: "Jane Doe",
    },
    {
      id: 3,
      stars: 1,
      reason: "Poor work",
      type: "negative",
      date: "2022-01-03",
      rated: "Emily Johnson",
    },
    {
      id: 4,
      stars: 5,
      reason: "Excellent work",
      type: "positive",
      date: "2022-01-04",
      rated: "Michael Brown",
    },
    {
      id: 5,
      stars: 3,
      reason: "Average work",
      type: "negative",
      date: "2022-01-05",
      rated: "Sarah Taylor",
    },
    {
      id: 6,
      stars: 1,
      reason: "Poor work",
      type: "negative",
      date: "2022-01-06",
      rated: "David Lee",
    },
  ]);

  const [ratingSetByMe, setRatingSetByMe] = useState([
    {
      id: 1,
      stars: 4,
      reason: "Good work",
      type: "positive",
      date: "2022-01-07",
      rated: "Alex Smith",
    },
    {
      id: 2,
      stars: 4,
      reason: "Good work",
      type: "positive",
      date: "2022-01-08",
      rated: "Lily Brown",
    },
    {
      id: 3,
      stars: 2,
      reason: "Fair work",
      type: "negative",
      date: "2022-01-09",
      rated: "Olivia Davis",
    },
    {
      id: 4,
      stars: 4,
      reason: "Good work",
      type: "positive",
      date: "2022-01-10",
      rated: "Ethan Hall",
    },
    {
      id: 5,
      stars: 4,
      reason: "Good work",
      type: "positive",
      date: "2022-01-11",
      rated: "Ava Martin",
    },
    {
      id: 6,
      stars: 2,
      reason: "Fair work",
      type: "negative",
      date: "2022-01-12",
      rated: "Isabella Wilson",
    },
  ]);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 200,
      renderCell: (params) => {
        return moment(params.value).format("DD-MM-YYYY");
      },
    },
    {
      field: "stars",
      headerName: "Stars",
      width: 350,
      renderCell: (params) => {
        return (
          <>
            {" "}
            <Rating
              value={params.value}
              readOnly
              getLabelText={(value) => `${value} Star${value !== 1 ? "s" : ""}`}
              label="custom"
              style={{
                color: params.row.type == "positive" ? "green" : "red",
              }}
            />{" "}
          </>
        );
      },
    },
    {
      field: "rated",
      headerName: tabValue == "My Ratings" ? "Rated By" : "Rated To",
      width: 150,
      renderCell: (params) => <p>{params.value}</p>,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 180,
      renderCell: (params) => (
        <p
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => DetailViewOpen(params.row.id)}
        >
          {params.value}
        </p>
      ),

      // console.log("This is data", params);
      // if (params) {

      // } else {
      //     return "Not started";
      // }
    },
  ];

  const [list, setList] = useState([]);
  const [listType, setListType] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
  const [selectedUserID, setSelectedUserID] = useState(ID);
  const [loading, setLoading] = useState(false);

  const handleListingSearchChange = (keyword) => {
    setSearchKeyword(keyword);
    setPageNumber(0);
  };
  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handleLeadClick = (leadId) => {
    props.onPreQualifierIdChange(leadId);
    setSelectedPreQualifierId(leadId);
  };

  const fetchTable = () => {
    setLoading(true);
    let from = moment(watch("from")).format("YYYY-MM-DD");
    let to = moment(watch("to")).format("YYYY-MM-DD");

    Activities.get({
      projects_id: selectedProjectID,
      users_id: selectedUserID,
      from,
      to,
      department: selectedDepartments,
      keyword: searchKeyword,
      page: parseInt(pageNumber) + 1,
      limit: 30,
    }).then((response) => {
      setList(response.data.data);
      setLoading(false);
    });
  };

  const fetchProjects = (e) => {
    Project.get({ keyword: e }).then((response) => {
      if (typeof response.data.data.data !== "undefined") {
        setProjects(response.data.data.data);
      } else {
        return [];
      }
    });
  };

  const fetchEmployees = (e) => {
    Employee.get({ keyword: e, limit: 100 }).then((response) => {
      // console.log(response.data.data.data);
      if (typeof response.data.data.data !== "undefined") {
        setEmployees(response.data.data.data);
      } else {
        return [];
      }
    });
  };
  console.log(employees);

  const fetchDepartments = (e) => {
    Departments.get({ keyword: e, limit: 100 }).then((response) => {
      // console.log(response.data.data.data);
      if (typeof response.data.data.data !== "undefined") {
        setDepartments(response.data.data.data);
      } else {
        return [];
      }
    });
  };

  // const selectProject = (project_id) => {
  //     setSelectedProjectID(project_id?.id)
  //     setValue('projects', project_id?.name || "")
  // }

  const selectByUser = (user) => {
    setSelectedUserID(user?.id);
    setValue("user", user?.name || "");
  };

  const selectDepartments = (data) => {
    setselectedDepartments(data?.id);
    setValue("departments", data?.name || "");
  };

  const handleUpdate = () => {
    setPageNumber();
    setPageNumber(0);
  };

  const handleCreate = () => {
    setEditId(0);
    setRefresh(Math.random);
  };

  const handleSearchEntry = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handlePageNumberChange = (event) => {
    const newPageNumber = parseInt(event.target.value, 10) - 1;
    setPageNumber(newPageNumber);
    // Add any other logic you want to perform when the page number changes
  };

  const handleResetDate = () => {
    setValue("from", moment());
    setValue("to", moment());
  };

  // useEffect(() => {
  //     fetchProjects()
  //     fetchEmployees()
  // }, [])

  useEffect(() => {
    setValue("user", UserName || "");
    // console.log(UserName);
  }, [UserName]);

  useEffect(() => {
    fetchProjects();
    fetchDepartments();
    fetchEmployees();
  }, []);

  useEffect(() => {
    fetchTable();
  }, [
    searchKeyword,
    listType,
    pageNumber,
    selectedProjectID,
    selectedUserID,
    selectedDepartments,
    watch("from"),
    watch("to"),
  ]);

  return (
    <>
      {/* {
                detailViewId &&
                <DetailView id={detailViewId} handleClose={setDetailViewId} />
            } */}

      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        marginRight={10}
        alignItems={"center"}
      >
        <PageHeader title={"Star Ratings"} />
      </Grid>

      <Card
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          width: { xs: "100%", md: "auto" },
        }}
        variant="outlined"
      >
      <Tabs value={tabValue} aria-label="basic tabs example">
        <Button
          variant={tabValue == "My Ratings" ? "contained" : "text"}
          onClick={() => setTabValue("My Ratings")}
          sx={{
            padding: "12px 45px",
            borderRadius: "12px",
            position: "relative",
            transition: "background-color 0.3s, transform 0.3s",
            "&:hover": {
              backgroundColor: "lightgray",
              transform: "scale(1.05)",
            },
            '&[aria-selected="true"]': {
              backgroundColor: "lightgray", // Changed to match hover effect background color
              color: "white",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transform: "scale(1.1)",
            },
          }}
        >
          My Ratings
        </Button>
        <Button
          variant={tabValue == "Ratings Given By Me" ? "contained" : "text"}
          onClick={() => setTabValue("Ratings Given By Me")}
          sx={{
            padding: "12px 20px",
            borderRadius: "12px",
            position: "relative",
            transition: "background-color 0.3s, transform 0.3s",
            "&:hover": {
              backgroundColor: "lightgray",
              transform: "scale(1.05)",
            },
            '&[aria-selected="true"]': {
              backgroundColor: "lightgray",
              color: "white",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transform: "scale(1.1)",
            },
          }}
        >
          Ratings Given By Me
        </Button>
      </Tabs>
      </Card>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        variant="outlined"
      >
        <Grid item display={"flex"} alignItems={"center"}>
          <Grid sx={{ width: 200 }} mr={3}>
            <PageHeader
              title={
                tabValue == "My Ratings" ? "My Ratings" : "Ratings Given By Me"
              }
              size={16}
              color="#77828F"
              variant={"h6"}
            />
          </Grid>
          <Grid sx={{ width: 200 }} mr={3}>
            <ReactSelector
              placeholder="Employees"
              onInputChange={fetchEmployees}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={employees}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={projects.find(
                (options) => options.name === watch("employees")
              )}
              name="employees"
              isClearable
              defaultValue={watch("employees")}
              // onChange={(selectedOption) => selectProject(selectedOption)}
            />
          </Grid>

          <Grid
            ml={5}
            display={"flex"}
            alignItems={"center"}
            xs={3}
            sx={{ px: 2 }}
          >
            <InputLabel sx={{ mr: 1 }}>From</InputLabel>
            <Grid width={"160px"}>
              <CurrentDateInput
                control={control}
                name="from"
                value={watch("from")}
              />
            </Grid>
          </Grid>

          <Grid display={"flex"} alignItems={"center"} xs={3} sx={{ px: 2 }}>
            <InputLabel sx={{ mr: 1 }}>To</InputLabel>
            <Grid mr={1} width={"160px"}>
              <CurrentDateInput
                placeholder={"to"}
                control={control}
                name="to"
                value={watch("to")}
              />
            </Grid>
            {tabValue == "Ratings Given By Me" && (
              <Create
                key={refresh}
                onNew={handleCreate}
                onUpdate={handleUpdate}
                editId={editId}
                setEditId={setEditId}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Card
        sx={{
          m: 2,
          height: list?.data?.length > 0 ? 1350 : 400,
          position: "relative",
        }}
        variant="outlined"
      >
        {typeof list.data === "object" && (
          <>
            {tabValue == "My Ratings" ? (
              <DataGrid
                paginationMode="server"
                rows={ratingData}
                columns={columns}
                page={pageNumber}
                pageSize={list?.per_page}
                rowHeight={10}
                // autoHeight
                // rowsPerPageOptions={[10]}
                rowCount={list?.total}
                onPageChange={handlePageChange}
                density={"comfortable"}
              />
            ) : (
              <DataGrid
                paginationMode="server"
                rows={ratingSetByMe}
                columns={columns}
                page={pageNumber}
                pageSize={list?.per_page}
                rowHeight={10}
                // autoHeight
                // rowsPerPageOptions={[10]}
                rowCount={list?.total}
                onPageChange={handlePageChange}
                density={"comfortable"}
              />
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                position: "absolute",
                bottom: 16,
                left: "77%",
                transform: "translateX(-50%)",
                zIndex: 1000,
              }}
            >
              <label htmlFor="pageNumber" style={{ marginRight: "10px" }}>
                Go to Page:
              </label>
              <select
                id="pageNumber"
                value={pageNumber + 1}
                onChange={handlePageNumberChange}
              >
                {Array.from({ length: Math.ceil(list?.last_page) }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default Index;
