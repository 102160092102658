import React, {useEffect, useState} from 'react';
import {
    Backdrop, Button, Card, CircularProgress, Grid, Pagination, Skeleton,
} from "@mui/material";
import PrequalifierListingSearch from "./prequalifier-listing/prequalifier-listing-search";
import PrequalifierListingItem from "./prequalifier-listing/prequalifier-listing-item";
import {PreQualifiers} from "../../api/Endpoints/PreQualifiers";
import {useAppSettings} from "../../hooks/use-app-settings";
import {DataGrid} from "@mui/x-data-grid";
import moment from "moment";

const PrequalifierListing = (props) => {
    const appSettings = useAppSettings();


    const columns = [
        { field: 'id', headerName: 'ID' },
        { field: 'name', headerName: 'Assigned to', width: 150 },
        { field: 'name1', headerName: 'Assigned by', width: 150 },
        { field: 'requirement', headerName: 'Title', width: 300 },
        { field: 'created_at', headerName: 'Created on', width: 150, renderCell: (params) => { return moment(params.value).format('MMM Mo dddd')}  },
        { field: 'updated_at', headerName: 'Due', width: 150, renderCell: (params) => { return moment(params.value).format('MMM Mo dddd')} },
        { field: 'status', headerName: 'Status', width: 100, renderCell :  (params) => <Button
                variant="contained"
                size="small"
                style={{ marginLeft: 16 }}
                tabIndex={params.value ? 0 : -1}
            >
                {params.value}
            </Button>},
    ]

    const [preQualifiersList, setPreQualifiersList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();

    const [loading, setLoading] = useState(false);


    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleLeadClick = (leadId) => {props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId);}

    const fetchPreQualifiers = () => {
        setLoading(true);
        PreQualifiers.index({lead_type_id : appSettings.get_lead_type(),keyword:searchKeyword, limit: 10, page : pageNumber+1}).then(response => {
            setPreQualifiersList(response.data.data);
            setLoading(false);
        })
    }





    useEffect( async ()=>{
        fetchPreQualifiers();
    },[searchKeyword, listType, pageNumber])

    return (
        <Card sx={{py:2}} variant="outlined">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {typeof preQualifiersList.data === "object" &&
                <DataGrid
                    paginationMode="server"
                    rows={preQualifiersList?.data}
                    columns={columns}
                    page={pageNumber}
                    pageSize={10}
                    rowHeight={20}
                    rowsPerPageOptions={[10]}
                    rowCount={preQualifiersList.total}
                    onPageChange={handlePageChange}
                    autoHeight
                    density={"comfortable"}
                />
            }


        </Card>
    );
};

export default PrequalifierListing;
