import { Button, Card, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactSelector from "react-select";
import PageHeader from '../common/page-header'
import { useForm } from 'react-hook-form';
import { Departments } from '../../../api/Endpoints/Departments';
import { Employee } from '../../../api/Endpoints/Employee';
import { UserIdContext } from '../../../contexts/UserID/UserID';
import Create from './create';
import { DataGrid } from '@mui/x-data-grid';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import moment from 'moment';
function PerformanceDashboard() {
const { ID } = UserIdContext()

    const { watch, setValue } = useForm()
    const [editId, setEditId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [selectedDepartments, setSelectedDepartments] = useState(null);
    const [selectedUserID, setSelectedUserID] = useState(ID)
    const [departments, setDepartments] = useState([]);
    const [employees, setEmployees] = useState([])
    const [list, setList] = useState([]);

    const column = [
        {
            field:"name", headerName: "Title",width: 200,
        },
        {
            field:"email", headerName: "User Name",width: 350,
        },
        {
            field:"last_login", headerName: "Due Date",width: 200,
            renderCell: (params) => {
                       return params.value ? moment(params.value).format("DD-MM-YYYY") : "";
                    },
        },
        {
          feild: "status", headerName: "Status",
          renderCell: (params) => {
            return (
              <>
              <CancelOutlinedIcon sx={{color: "red", cursor: "pointer"}}/>
              </>
            )
          }
        }
    ]

    //dummy data
    const fetchTable = () => {
        //   setLoading(true);
          let from = moment(watch("from")).format("YYYY-MM-DD");
          let to = moment(watch("to")).format("YYYY-MM-DD");
      
          Employee.get({
            // projects_id: selectedProjectID,
            from,
            to,
            department: selectedDepartments,
            // page: parseInt(pageNumber) + 1,
            limit: 30,
          }).then((response) => {
            setList(response.data.data);
            // setLoading(false);
          });
        };

     // fetching departments
  const fetchDepartments = (e) => {
    Departments.get({ keyword: e, limit: 100 }).then((response) => {
      if (typeof response.data.data.data !== "undefined") {
        setDepartments(response.data.data.data);
      } else {
        console.log("department not fetched");
      }
    });
  };
//fettching employees
const fetchEmployees = (e) => {
    Employee.get({ keyword: e, limit: 100 }).then(response => {
        // console.log(response.data.data.data);
        if (typeof response.data.data.data !== "undefined") {
            setEmployees(response.data.data.data)
        } else {
            return [];
        }
    })
}
  useEffect(() => {
    fetchDepartments()
    fetchEmployees()
    fetchTable()
  },[])

  const selectDepartments = (data) => {
    setSelectedDepartments(data?.id);
    setValue("departments", data?.name || "");
  };
  const selectByUser = (user) => {
    setSelectedUserID(user?.id)
    setValue('user', user?.name || "")
}
const handleCreate = () => {
    setEditId(0)
    setRefresh(Math.random)
}
  return (
   <>
   <Grid display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <PageHeader title={"Performance Dashboard"} />
        <Grid display={"flex"} alignItems={"center"} gap={3} pr={20} >
        <ReactSelector
          placeholder="User Name"
          onInputChange={fetchEmployees}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          options={employees}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          // inputValue={inProject}
          value={
              employees.find(options =>
                                    options.name === watch('user')
              )
          }
          name='user'
          isClearable
          defaultValue={(watch('user'))}
          onChange={(selectedOption) => selectByUser(selectedOption)}
                        />
        <ReactSelector
          placeholder="Department"
          onInputChange={fetchDepartments}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 })}}
          options={departments}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          // inputValue={inProject}
          value={departments.find(
            (options) => options.name === watch("departments")
          )}
          name="departments"
          isClearable
          defaultValue={watch("departments")}
          onChange={(selectedOption) => selectDepartments(selectedOption)}
        />
        </Grid>
      </Grid>
      <Grid display={"flex"} alignItems={"center"} justifyContent={"space-between"} px={2} py={2}>
      <Grid display={"flex"} alignItems={"center"} gap={3}>
        <Typography variant='h6' sx={{color: 'green'}}>Your Current Performance Rank: </Typography>
        </Grid>
        <Grid pr={18} >
        <Create onNew={handleCreate} key={refresh}  setEditId={setEditId} editId={editId}   />
        </Grid>
      </Grid>
      <Card sx={{  width:'80%', height: 500, position: "relative" }} variant="outlined">
      {typeof list.data === "object" && (
          <>
            <DataGrid
              paginationMode="server"
              rows={list?.data}
              columns={column}
            //   page={pageNumber}
              pageSize={list?.per_page}
              rowHeight={20}
              // autoHeight
              // rowsPerPageOptions={[10]}
              rowCount={list?.total}
            //   onPageChange={handlePageChange}
              density={"comfortable"}
            />
          </>
        )}
      </Card>
   </>
  )
}

export default PerformanceDashboard
