import { get, post } from './../Config/config';


export const CreativeCalenderApi = {

    get: (data) => get('/projects/creative-calendars', { params: data }),
    add: (data) => post('/projects/creative-calendars/store', data),
    getDetails: (data) => get('/projects/creative-calendars/view', { params: data }),
    update: (data) => post('/projects/creative-calendars/update', data),
    // delete: (data) => get('/meetings/delete?id=', { params: data }),

    getNotes: (data) => get('/projects/creative-calendars/notes', { params: data }),
    addNote: (data) => post('/projects/creative-calendars/notes/store', data),
    getNoteDetails: (data) => get('/projects/creative-calendars/notes/view', { params: data }),
    updateNote: (data) => post('/projects/creative-calendars/notes/update', data),

    getPosts: (data) => get('/projects/creative-calendars/posts', { params: data }),
    addPost: (data) => post('/projects/creative-calendars/posts/store', data),
    getPostDetails: (data) => get('/projects/creative-calendars/posts/view', { params: data }),
    updatePost: (data) => post('/projects/creative-calendars/posts/update', data),

    getPostNotes: (data) => get('/projects/creative-calendars/posts/notes', { params: data }),
    addPostNote: (data) => post('/projects/creative-calendars/posts/notes/store', data),
    getPostNoteDetails: (data) => get('/projects/creative-calendars/posts/notes/view', { params: data }),
    updatePostNote: (data) => post('/projects/creative-calendars/posts/notes/update', data),

    getCopies: (data) => get('/projects/creative-calendars/posts/copies', { params: data }),
    addCopy: (data) => post('/projects/creative-calendars/posts/copies/store', data),
    getCopyDetails: (data) => get('/projects/creative-calendars/posts/copies/view', { params: data }),
    updateCopy: (data) => post('/projects/creative-calendars/posts/copies/update', data),

    getCreatives: (data) => get('/projects/creative-calendars/posts/creatives', { params: data }),
    addCreatives: (data) => post('/projects/creative-calendars/posts/creatives/store', data),
    getCreativesDetails: (data) => get('/projects/creative-calendars/posts/creatives/view', { params: data }),
    updateCreatives: (data) => post('/projects/creative-calendars/posts/creatives/update', data),

    getPreviews: (data) => get('/projects/creative-calendars/posts/previews', { params: data }),
    addPreviews: (data) => post('/projects/creative-calendars/posts/previews/store', data),
    getPreviewDetails: (data) => get('/projects/creative-calendars/posts/previews/view', { params: data }),
    updatePreviews: (data) => post('/projects/creative-calendars/posts/previews/update', data),


}

