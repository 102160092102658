import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    Select,
    Slide, TextField, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { ErrorMessage } from "@hookform/error-message";
import ReactSelector from 'react-select';
import { Task } from '../../../../../api/Endpoints/Task';
import { Project } from '../../../../../api/Endpoints/Project';
import { Activities } from '../../../../../api/Endpoints/Activities';
import { Users } from '../../../../../api/Endpoints/Users';
import { Auth } from '../../../../../api/Endpoints/Auth';
import SelectX from '../../../../form/SelectX';
import TextInput from '../../../../form/TextInput';
import LoadingScreen from '../../../../form/progress';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({


    title: yup.string().required(),
    // durationHours: yup.number().nullable(),
    task: yup.string(),
    projects_id: yup.object().nullable()
})

const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });

    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => { setOpen(false); };
    const [recentProjects, setRecentProjects] = useState([])
    const [currentUser, setCurrentUser] = useState(0)
    const [tasks, setTasks] = useState([])
    const [taskId, setTaskId] = useState()
    const [durationMinutes, setDurationMinutes] = React.useState();
    const [durationHours, setDurationHours] = React.useState();
    const [taskStatus, setTaskStatus] = useState([
        "Not Started",
        "In progress",
        "Review pending",
        "In review"
    ])


    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchProjects = (e) => {
        return Project.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    // const fetchTask = (e) => {
    //     return Task.get({ keyword: e, assigned_to: currentUser, status: "Not Started" }).then(response => {
    //         if (typeof response.data.data.data !== "undefined") {
    //             console.log();
    //             return response.data.data.data;
    //         } else {
    //             return [];
    //         }
    //     })
    // }
    const fetchTask = (e) => {
        Task.get({ keyword: e, assigned_to: currentUser, status: "Not Started" }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                console.log();
                setTasks(response.data.data.data)
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }
    const fetchTasks = (id) => {
        Task.get({ assigned_to: id, status: "Not Started" }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                console.log();
                setTasks(response.data.data.data)
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }


    const handleMinuteChange = (e) => {
        if (e.target.value.length <= 2) {
            setDurationMinutes(e.target.value);
        }
    };
    const handleHourChange = (e) => {
        if (e.target.value.length <= 2) {
            setDurationHours(e.target.value);
        }
    };


    const handlePriorityChange = (id) => {
        setSelectedPriority(id);
    }

    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })



        let dataToSubmit = {
            id: props.editId,
            projects_id: data.projects_id?.id,
            title: data.title,
            description: data.description,
            due_date: data.due_date,
            assigned_to_id: data.assign_to?.id,
            reviewer_id: data.reviewer_id?.id,
            project_tasks_id: taskId,
            duration_hours: durationHours,
            duration_minutes: durationMinutes

        };


        let action;
        if (props.editId > 0) {
            console.log("dataToSubmit", dataToSubmit);
            action = Activities.update(dataToSubmit);
        } else {
            action = Activities.add(dataToSubmit);
        }

        action.then(response => {
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    const fetchActivityDetails = async () => {
        setLoading(true)
        let task = await Activities.getDetails({ id: props.editId });
        if (task.data.status === "success") {
            let data = task.data.data;
            setValue('title', data.title);
            setValue('description', data.description);
            // setValue('durationMinutes', data.duration_minutes);
            // setValue('durationHours', data.duration_hours);
            setValue('start_time', data.start_time);
            setValue('end_time', data.end_time);
            setValue('projects_id', { id: data.project?.id, name: data.project?.name });
            setValue('task', data.project_task?.title);
            setSelectedPriority(data.priority);
            setDurationMinutes(data.duration_minutes)
            setDurationHours(data.duration_hours)
        }
        setLoading(false)
    };

    const fetchRecentProjects = () => {
        Users.recentProjects().then((response) => {
            console.log(response);
            setRecentProjects(response.data.data)

        })
    }

    const getProjectDetails = () => {
        Project.getDetails({ project_id: props.ProjectID }).then((response) => {
            let data = response.data.data
            setValue('projects_id', { id: data?.id, name: data?.name });
        })
    }

    const fetchUser = async () => {
        const accessToken = window.localStorage.getItem('accessToken');
        const user = await Auth.me(accessToken);
        setCurrentUser(user.data.data.id)
        // setValue('reviewer_id', { id: user.data.data.id, name: user.data.data.name })
        fetchTasks(user.data.data.id)
    }
    const selectTask = (data) => {
        setValue('task', data?.title)
        setTaskId(data?.id)
    }
    // console.log(currentUser);
    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('projects_id')])

    useEffect(() => {
        fetchRecentProjects()
        fetchUser()
    }, [])

    // useEffect(() => {
    //     fetchTasks()
    //     setRefresh(Math.random())
    // }, [currentUser])


    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchActivityDetails();
            fetchUser()
            setOpen(true);
        } else if (parseInt(props.editId) === 0) {
            setOpen(true);
            fetchUser()
            getProjectDetails()

        }

    }, [props.editId])

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                Add Activity
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{parseInt(props.editId) > 0 ? "Edit Activity" : "Create Activity"}</DialogTitle>

                {!loading ? (

                    <>
                        <DialogContent>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <ErrorMessage
                                    errors={errors}
                                    name="multipleErrorInput"
                                    render={({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(([type, message]) => (
                                            <p key={type}>{message}</p>
                                        ))
                                    }
                                />

                                <Grid sx={{ mt: 2 }} item xs={12}>
                                    <InputLabel sx={{
                                        color: "black",
                                        fontWeight: 500,
                                        fontSize: '14px'
                                    }}>
                                        <Grid display={'flex'} justifyContent={'space-between'}>
                                            <a style={{ marginRight: 20 }}>Choose Project</a>
                                            <Grid>
                                                {recentProjects.map((proj, index) => (
                                                    <a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setValue('projects_id', { id: proj.id, name: proj.name })}> {proj.name}{index != 2 && ','}</a>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </InputLabel>
                                    <SelectX
                                        // label={"Choose project"}
                                        options={fetchProjects}
                                        control={control}
                                        error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                                        error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                                        name={'projects_id'}
                                        defaultValue={watch('projects_id')}
                                    />
                                </Grid>
                                <Grid sx={{}} mt={2}>
                                    <InputLabel sx={{
                                        color: "black",
                                        fontSize: "13px",
                                        pb: .5,
                                        fontWeight: 500
                                    }}>Choose Task</InputLabel>
                                    <ReactSelector
                                        onInputChange={fetchTask}
                                        // placeholder="Reviewer"
                                        styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                        options={tasks}
                                        getOptionLabel={option => option.title}
                                        getOptionValue={option => option.id}

                                        // inputValue={inProject}
                                        value={
                                            tasks.filter(options =>
                                                options.title === watch('task')
                                            )
                                        }
                                        name='task'
                                        isClearable={true}
                                        defaultValue={(watch('task'))}
                                        onChange={(selectedOption) => selectTask(selectedOption)}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="task"
                                        render={({ message }) => (
                                            <p style={{ marginTop: -1, fontSize: '12px', color: "red" }} className="error-message">{message}</p>
                                        )}
                                    />
                                </Grid>

                                {/* <Grid sx={{ mt: 2 }} item xs={12}>
                                    <SelectX

                                        label={"Choose Task"}
                                        options={fetchTask}
                                        control={control}
                                        error={errors?.task?.id ? errors?.task?.id?.message : false}
                                        error2={errors?.task?.message ? errors?.task?.message : false}
                                        name={'task'}
                                        defaultValue={watch('task')}
                                    />
                                </Grid> */}

                                <Grid sx={{ mt: 2 }} item xs={12}>
                                    <TextInput control={control} name="title"
                                        label="Title"
                                        value={watch('title')} />
                                </Grid>

                                <Grid sx={{ mt: 2 }} item xs={12}>
                                    <InputLabel sx={{
                                        color: "black",
                                        pb: .5
                                    }}>Description </InputLabel>
                                    <TextField
                                        {...register('description')}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        sx={{ width: '100%', }}
                                        // required
                                    />
                                </Grid>

                                <Grid container spacing={1} sx={{ mt: 2 }}>
                                    <Grid item xs={6}>
                                        <InputLabel sx={{
                                            color: "black",
                                            pb: .5
                                        }}>Duration </InputLabel>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ display: 'flex', }}>
                                    <Grid item xs={12} sm={6}>
                                        {/* <TextField
                                            {...register('durationHours')}
                                            type='text'
                                            control={control}
                                            name="durationHours"
                                            label="Hours"
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                maxLength: 2
                                            }}
                                        /> */}
                                        <TextField
                                            type='number'
                                            control={control}
                                            name="durationHours"
                                            label="Hours"
                                            onChange={handleHourChange}
                                            defaultValue={durationHours}
                                            value={durationHours}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {/* <TextField
                                            {...register('durationMinutes')}
                                            type='text'
                                            control={control}
                                            name="durationMinutes"
                                            label="Minutes"
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                maxLength: 2
                                            }}
                                        /> */}
                                        <TextField
                                            type='number'
                                            control={control}
                                            name="durationMinutes"
                                            label="Minutes"
                                            onChange={handleMinuteChange}
                                            defaultValue={durationMinutes}
                                            value={durationMinutes}
                                        />
                                        {/* <TextField
                                            type="number"
                                            id="outlined-basic"
                                            label="Outlined"
                                            variant="outlined"
                                            onChange={(e) => handleChange(e)}
                                            defaultValue={num}
                                            value={num}
                                        /> */}
                                    </Grid>


                                </Grid>


                                <Grid sx={{ mt: 2 }} item xs={12}>
                                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                </Grid>

                            </form>

                        </DialogContent>
                        {/* <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions> */}
                    </>
                ) : (

                    <Box className="loadingScreen" >
                        <LoadingScreen />
                    </Box>


                )}
            </Dialog>
        </div >
    );
};

export default Create;
