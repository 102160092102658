




import React from 'react'
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Modal,
    Select,
    Slide, TextField, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-hot-toast';
import { Waveform } from '@uiball/loaders'
import { CloudUpload, DeleteOutline } from '@mui/icons-material';
import { Employee } from '../../../../api/Endpoints/Employee';
import Delete from '../../common/Popup/Delete';
import SelectX from '../../../form/SelectX';
import TextInput from '../../../form/TextInput';
import { VideoShootApi } from '../../../../api/Endpoints/VideoShoot';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function VideoShootAttachments({ id, paymentId }) {
    console.log(id)
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({});
    const [loading, setLoading] = useState(false)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [ID, setID] = useState(0)
    const [refresh, setRefresh] = React.useState(false);
    const [list, setList] = useState([])
    const [pageNumber, setPageNumber] = useState(0);
    const [modalOpen, setmodalOpen] = useState(false)
    const [file, setFile] = useState()
    //state for storing the paymentlist data
    const [paymentList, setPaymentList] = useState([])


    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });


    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const columns = [
        {
            field: 'created_user',
            headerName: 'User',
            width: 180,
            renderCell: (params) => {

                return (
                    <a style={{ color: 'black' }}>{params.value?.name}</a >
                );
            },
        },

        {
            field: 'attachment',
            headerName: 'Attachments',
            width: 330,
            renderCell: (params) => {
                return (
                    <>
                        <a target='_blank' href={`https://dashboard.omnisellcrm.com/${params?.value}`} >{params.row?.attachment?.split("/")[3]?.split(/[\d_]+/)[1]}</a >
                    </>
                );
            },
        },

        {
            field: 'action', headerName: '', width: 50, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <>
                    <Button style={{ color: 'black' }} size={'small'} onClick={() => handleDeleteAlert(params.row.id)}>
                        <DeleteOutline />
                    </Button>
                </>;
            }
        },
    ]
    const fetchList = () => {
        setLoading(true);
        VideoShootApi.listAttachments({ project_video_shoots_id: id }).then(response => {
            // console.log(response);
            setList(response.data.data);
            console.log(list)
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        })


    }

    console.log(list);

    //fetching the api for payment attachments
    //  const fetchPayment = () => {
    //     setLoading(true)
    //     VideoShootApi.assignVendorPayment().then(response => {
    //         console.log(response)
    //         setPaymentList(response?.data?.data)
    //         setLoading(false);
    //     }).catch(error => {
    //         console.log(error);
    //         setLoading(false);
    //     })

    //  }

    const handleFileChange = (e) => {
        const newFile = e?.target?.files[0];
        // setFile([...file, newFile]); // Add the new file to the state
        setFile(newFile)
    };

    const handleDeleteAttachment = (index) => {
        setFile()
        // const updatedAttachments = [...file];
        // updatedAttachments.splice(index, 1);
        // setFile(updatedAttachments);
    };
    useEffect(() => {

        fetchList()
    }, [])

    // useEffect( () => {
    //     fetchPayment()
    // },[])

    const handleDeleteAlert = (id) => {
        setID(id)
        setDeletePopup(true)
    }

    const onSubmit = async () => {
        if (file) {
            setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });

            const formData = new FormData();

            formData.append('project_video_shoots_id', id)
            formData.append('attachment', file)

            VideoShootApi.addAttachments(formData).then((response) => {
                console.log("respponser here", response);
                if (response?.data?.status == 'success') {
                    fetchList()
                    setmodalOpen(false)
                    toast.success('Attachments has been successfully saved.')
                    setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
                    setFile()
                } else {
                    toast.error(response?.data?.error?.message)

                }
            })
        } else {
            toast.error('Please choose a file')
        }
    }

    //posting on payment 

    const onSubmitPayment = async () => {
        if (file) {
            setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
    
            const formData = new FormData();
    
            if (id) {
                formData.append('payment_id', id);
            } else {
                console.error("paymentId is undefined");
                return; 
            }
    // Exit the function if paymentId is undefined
            formData.append('attachment', file);
    
            try {
                const response = await VideoShootApi.assignVendorPayment(formData);
                console.log("response:", response);
                if (response?.data?.status === 'success') {
                    setmodalOpen(false);
                    toast.success('Attachment has been successfully saved.');
                } else {
                    toast.error(response?.data?.error?.message);
                }
            } catch (error) {
                console.error("Error:", error);
                toast.error('An error occurred while saving attachment.');
            } finally {
                setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
                setFile(null); // Clear the file state
            }
        } else {
            toast.error('Please choose a file');
        }
    }
    



    return (
        <div>

            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                Callfunc={() => fetchList()}
                url="/projects/video-shoots/attachments/delete?id="
                title="Attachment" />}



            {loading ? (
                <Grid mt={'40%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Waveform
                        size={40}
                        lineWeight={3.5}
                        speed={1}
                        color="black"
                    />
                </Grid>
            ) : (

                <>
                    <Grid display={'flex'} justifyContent={'end'} item xs={2} sm={2} >
                        <Button onClick={() => setmodalOpen(true)} variant="outlined">Add</Button>
                    </Grid>
                    <div style={{ height: 300, width: '100%', marginTop: '20px' }}>
                        {typeof list === "object" &&
                            <DataGrid
                                paginationMode="server"
                                rows={list}
                                columns={columns}
                                page={pageNumber}
                                pageSize={10}
                                rowHeight={20}
                                rowsPerPageOptions={[10]}
                                rowCount={list?.meta?.total}
                                onPageChange={handlePageChange}
                                autoHeight
                                density={"comfortable"}
                                getRowId={(row) => row?.id || row?.title || row?.shoot_start_date || /* add more properties to check */ undefined}
                            />

                        }
                    </div>
                </>
            )}

            <Modal
                open={modalOpen}
                onClose={() => setmodalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ textAlign: "start", pb: 5 }}>
                        Attach Document
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input
                            type="file"
                            id="file-upload"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />

                        <label htmlFor="file-upload">
                            <Button
                                variant="contained"
                                startIcon={<CloudUpload />}
                                component="span"
                                sx={{
                                    mt: 2,

                                }}
                            >
                                Choose File
                            </Button>

                        </label>
                        
                        {
                            file &&
                            <List sx={{ mt: 2, maxHeight: 200, overflowY: 'auto' }}>
                                <ListItem >
                                    <ListItemIcon>
                                        <CloudUpload />
                                    </ListItemIcon>
                                    <ListItemText primary={file?.name} />
                                    <Button

                                        edge="end"
                                        onClick={() => handleDeleteAttachment()}
                                    >
                                        <DeleteOutline />
                                    </Button>
                                </ListItem>

                            </List>
                        }
                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{
                                    mt: 2, mr: 2,
                                    background: "gray",
                                    '&:hover': {
                                        backgroundColor: 'gray',
                                    },
                                }}
                                onClick={() => setmodalOpen(false)}
                            >
                                Cancel
                            </Button>

                            <LoadingButton style={{ height: '40px' }} sx={{ mt: 2 }} loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                variant="contained" type="submit">{formButtonStatus.label}</LoadingButton>

                        </div>
                    </form>

                    

                </Box>
            </Modal>
        </div >

    )
}

export default VideoShootAttachments



// import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import DeleteIcon from '@material-ui/icons/Delete';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import moment from "moment";

// import {
//     Backdrop,
//     Button,
//     Card,
//     CircularProgress,
//     Divider,
//     List,
//     Box,
//     Modal,
//     IconButton,
//     ListItemIcon,
//     ListItem,
//     ListItemText,
//     TextField,
//     Typography
// } from "@mui/material";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: '40%',
//     maxHeight: '80vh',
//     borderRadius: '8px',
//     bgcolor: 'background.paper',
//     boxShadow: 24,
//     p: 4,
// };



// function Attachments() {

//     const [user, setUser] = useState({ name: '', email: '' });
//     const [loading, setLoading] = useState(false);
//     const [selectedFiles, setSelectedFiles] = useState([]);
//     const [textValue, setTextValue] = useState('');
//     const [open, setOpen] = useState(false);


//     const handleOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const handleFileChange = (event) => {
//         setSelectedFiles([event.target.files[0]]);
//     };

//     const handleTextChange = (event) => {
//         setTextValue(event.target.value);
//     };

//     const handleRemoveFile = (file) => {
//         setSelectedFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile !== file));
//     };






//     const handleSubmit = (event) => {
//         event.preventDefault();

//         handleClose();
//     };


//     return (

//         <div>
            {/* {loading ? (
                <Backdrop open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                (
                    <>

                        <div className='flex' style={{ width: '100', display: 'flex', justifyContent: "end", marginRight: 10 }}>


                            < Button variant="contained" sx={{ width: "180px", }}
                                onClick={handleOpen}  >Add Attachment</Button >
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ textAlign: "center", pb: 5 }}>
                                        Attach Document
                                    </Typography>
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            type="file"
                                            id="file-upload"
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />

                                        <TextField
                                            id="text-input"
                                            label="Document Title"
                                            variant="outlined"
                                            value={textValue}
                                            onChange={handleTextChange}
                                            fullWidth
                                            autoFocus
                                            required
                                            margin="normal"
                                            sx={{ mt: 2 }}
                                        />
                                        <label htmlFor="file-upload">
                                            <Button
                                                variant="contained"
                                                startIcon={<CloudUploadIcon />}
                                                component="span"
                                                sx={{
                                                    mt: 2,

                                                }}
                                            >
                                                Choose File
                                            </Button>

                                        </label>
                                        <List sx={{ mt: 2, maxHeight: 200, overflowY: 'auto' }}>
                                            {selectedFiles &&
                                                selectedFiles.map((file) => (
                                                    <ListItem key={file.name}>
                                                        <ListItemIcon>
                                                            <CloudUploadIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={file.name} />
                                                        <IconButton
                                                            startIcon={<CloudUploadIcon />}
                                                            edge="end"
                                                            onClick={() => handleRemoveFile(file)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </ListItem>
                                                ))}
                                        </List>
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                sx={{
                                                    mt: 2, mr: 2,
                                                    background: "gray",
                                                    '&:hover': {
                                                        backgroundColor: 'gray',
                                                    },
                                                }}
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>

                                            <Button variant="contained" type="submit" sx={{ mt: 2 }}>
                                                Submit
                                            </Button>

                                        </div>
                                    </form>

                                </Box>
                            </Modal>

                        </div >
                        <Card sx={{ mt: 5 }} variant="outlined">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>NO</TableCell>
                                            <TableCell>Title</TableCell>
                                            <TableCell>File Name</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>File Size</TableCell>
                                            <TableCell> </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow

                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, marginTop: 5 }}
                                        >
                                            <TableCell>1</TableCell>
                                            <TableCell>Screenshot(1).jpg </TableCell>
                                            <TableCell>Travelling Bill</TableCell>
                                            <TableCell>New</TableCell>
                                            <TableCell>1.26 MB</TableCell>
                                            <TableCell>delete</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Card>

                    </>
                )

            )
            } */}
//         </div >

//     );
// }

// export default Attachments;
