import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Button, DialogActions, Divider, Grid, InputLabel, Slide, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-hot-toast';
import { Task } from '../../../../api/Endpoints/Task';
import SelectX from '../../../form/SelectX';
import { Users } from '../../../../api/Endpoints/Users';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline } from '@material-ui/icons';
import Delete from '../../common/Popup/Delete';
import { SubscribeApi } from '../../../../api/Endpoints/Subscribe';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
    user_id: yup.object().required(),
});


const SubscibeTab = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editValues, setEditValues] = useState({})
    const [refresh, setRefresh] = React.useState(false);
    const [subscribers, setSubscribers] = useState([])
    const [pageNumber, setPageNumber] = useState(0);
    const [deleteID, setDeleteID] = useState()



    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })



    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        props.setEditId()
        setOpen(false);
    };
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleDeleteAlert = (id) => {
        setDeleteID(id)
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.row?.user?.name}</Button >
                    </>
                );
            },
        },

        {
            field: 'email',
            headerName: 'Email',
            width: 250,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.row?.user?.email}</Button >
                    </>
                );
            },
        },

        {
            field: 'action', headerName: 'delete', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <>
                    <Button style={{ color: 'black' }} size={'small'} onClick={() => handleDeleteAlert(id)}>
                        <DeleteOutline />
                    </Button>
                </>;
            }
        },
    ]

    const onSubmit = (data) => {
        console.log(data);

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let dataToSubmit = {
            subscribable_type: "task",
            subscribable_id: props.id,
            users_id: data.user_id?.id
        }

        console.log(dataToSubmit);
        let action;

        // if (props.editId > 0) {
        //     console.log(dataToSubmit);
        //     action = Task.updateNote(dataToSubmit)
        // } else {
        console.log(dataToSubmit);
        action = SubscribeApi.subscribe(dataToSubmit)
        // }
        action.then((response) => {
            console.log(response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true })
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false })
            toast.success(response.data.message)
            props.setRefresh(Math.random() * .02)
            // props.onUpdate()
            setValue('user_id', '')
            setOpen(false)
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            setOpen(false)
            toast.error("server error")
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    const fetchDetails = () => {
        setLoading(true)
        SubscribeApi.getSubscribers({ subscribable_type: 'task', subscribable_id: props.id, page: parseInt(pageNumber) + 1 }).then((response) => {
            let data = response.data.data
            console.log(data.data);
            let subscription = data.data.some(item => item.users_id == props.userID)
            if (subscription) {
                props.setSubscription(true)
            } else {
                props.setSubscription(false)
            }
            setSubscribers(data.data)
            // setValue('note', data.note)
            setLoading(false)
        })
    }

    const fetchUsers = (e) => {
        // console.log(e);
        return Users.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }
    useEffect(() => {
        fetchDetails()
        console.log('refreshing');
    }, [props.refresh])

    useEffect(() => {
        fetchDetails()
        setOpen(true);
    }, [])

    return (
        <div>

            {deleteID && <Delete
                ID={deleteID}
                setID={setDeleteID}
                setDeletePopup={setDeleteID}
                Callfunc={() => fetchDetails()}
                url="/subscribers/delete?id="
                title="Subscriber" />}

            <form onSubmit={handleSubmit(onSubmit)}>

                <ErrorMessage
                    errors={errors}
                    name="multipleErrorInput"
                    render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                            <p key={type}>{message}</p>
                        ))
                    }
                />


                <Grid xs={12} display={'flex'} justifyContent={'space-between'}>
                    <Grid width={400} sx={{ pt: 2 }} item sm={8} xs={8}>
                        <SelectX
                            key={refresh * 0.2}
                            label={"Select User"}
                            options={fetchUsers}
                            control={control}
                            error={errors?.assigned_to_id?.id ? errors?.assigned_to_id?.id?.message : false}
                            error2={errors?.assigned_to_id?.message ? errors?.assigned_to_id?.message : false}
                            name={'user_id'}
                            defaultValue={watch('user_id')}
                        />
                    </Grid>

                    <Grid display={'flex'} alignItems={'end'} sx={{ pt: 2, pb: 2, mt: 3 }} item sm={4} xs={4}>
                        <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                            variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                    </Grid>
                </Grid>
            </form>


            <Divider />

            <Accordion sx={{ mb: 3, mt: 2 }}>
                <AccordionSummary
                    sx={{
                        backgroundColor: 'rgb(243,242,255)'
                    }}
                    expandIcon={< ExpandMoreIcon />}
                    aria-controls="internal-content"
                    id="internal-header"
                >
                    <Typography sx={{ fontWeight: 500 }}>Subscribers</Typography>
                </AccordionSummary>
                <div>
                    <div style={{ height: 300, width: '100%', }}>
                        {subscribers &&
                            <DataGrid
                                paginationMode="server"
                                rows={subscribers}
                                columns={columns}

                                pageSize={10}
                                rowHeight={20}
                                rowsPerPageOptions={[10]}
                                // rowCount={list.total}
                                onPageChange={handlePageChange}
                                autoHeight={false}
                                density={"comfortable"}
                            />
                        }
                    </div>
                </div>
            </Accordion>

            {/* <Grid container mt={2} spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                        Subscribers:
                    </Typography>
                    {
                        subscribers.map((subscriber) => (

                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {subscriber?.user.name}
                            </Typography>
                        ))
                    }
                </Grid>
            </Grid> */}
            {/* <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions> */}

        </div>
    )
}

export default SubscibeTab;
