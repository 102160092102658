import { get, post } from './../Config/config';

export const Meetings = {

    get: (data) => get('/meetings?', { params: data }),
    add: (data) => post('/meetings/store', data),
    getDetails: (data) => get('/meetings/view?id=', { params: data }),
    update: (data) => post('/meetings/update', data),
    delete: (data) => get('/meetings/delete?id=', { params: data }),
    

    //Meeting platfrom
    getMeetingPlatform: (data) => get('/meeting-modes', { params: data }),

    //Attendees
    getAttendees: (data) => get('/meeting-attendee?', { params: data }),
    addAttendees: (data) => post('/meeting-attendee/store', data),

    //Meeting Minutes
    getMeetingMinutes: (data) => get('/meeting-minutes?', { params: data }),
    addMeetingMinutes: (data) => post('/meeting-minutes/store', data),
    getMeetingMinutesDetails: (data) => get('/meeting-minutes/view?id=', { params: data }),
    updateMeetingMinutes: (data) => post('/meeting-minutes/update', data),
    assignMinutes: (data) => post('/meeting-minutes/assign', data),
    changeMinutesStatus: (data) => post('/meeting-minutes/change-status', data),
}

