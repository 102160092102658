import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import {
    Button,
    Box,
    Modal,
    Grid,
    TextField,
    InputLabel
} from "@mui/material";
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import { DotSpinner } from '@uiball/loaders'
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { Meetings } from '../../../../../api/Endpoints/Meeting';
import { Employee } from '../../../../../api/Endpoints/Employee';
import TextInput from '../../../../form/TextInput';
import SelectX from '../../../../form/SelectX';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '20%',
    height: '40%',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function AssignTo({ Open, setOpen, editId, fetchList, setEditId, ViewType }) {
    const validationSchema = yup.object().shape({
        // title: yup.string().required('Minutes is required'),
    });
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(validationSchema), });
    const [loading, setLoading] = useState(false)
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const handleClose = () => {
        setOpen(false);
        setEditId(0)
    };



    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });




        let dataToSubmit = {
            id: editId,
            // title: data.title,
            assign_to: data.employee_id?.id,
            // description: data.description
        };


        let action;
        action = Meetings.assignMinutes(dataToSubmit);

        action.then(response => {
            console.log(response);
            //Storing the Meeting Id in a Global State 

            setFormButtonStatus({
                ...formButtonStatus, loading: false, disabled: false
            });

            toast.success(response.data.message)
            handleClose()
            fetchList()

        }).catch(errors => {
            toast.error("Internal server error");


        })


    }
    useEffect(() => {
        fetchDetails()
    }, [editId])


    const fetchDetails = async () => {
        setLoading(true)
        let response = await Meetings.getMeetingMinutesDetails({ id: editId });
        if (response.data.status === "success") {
            let data = response.data.data;
            // setValue('title', data.title);
            setValue('employee_id', data.assign_to_user);
            // setValue('description', data.description);
        }
        setLoading(false)
    }

    const fetchEmployees = (e) => {
        let project = watch('projects_id');
        return Employee.get({ keyword: e}).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }



    return (
        <div>
            <div className='flex' style={{ width: '100', display: 'flex', justifyContent: "end", marginRight: 10 }}>

                <Modal
                    open={Open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >


                    <Box sx={style}>
                        {!loading ? (

                            <form onSubmit={handleSubmit(onSubmit)}>


                                <Grid container spacing={2} mt={0.1}>
                                    {/* <Grid item xs={12} sm={6}>
                                        <TextInput
                                            label={'Minutes'}
                                            control={control}
                                            name="title"
                                            value={watch('title')}
                                            disabled={ViewType ? true : false}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} sm={12} mb={10}>
                                        {/* First Field */}

                                        <SelectX
                                            label={"Assign to"}
                                            options={fetchEmployees}
                                            control={control}
                                            error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                            error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                            name={'employee_id'}
                                            defaultValue={watch('employee_id')}
                                            disabled={ViewType ? true : false}
                                        />


                                    </Grid>
                                </Grid>


                                {/* <Grid item xs={12} mt={4}>
                                    <InputLabel sx={{
                                        color: "black",
                                        pb: 1
                                    }}>Description </InputLabel>
                                    <TextField
                                        {...register('description')}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        sx={{ width: '100%', }}
                                        disabled={ViewType ? true : false}
                                    />
                                </Grid> */}
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                                    <Button
                                        onClick={handleClose}
                                        variant="outlined"
                                        color='error'
                                        type="submit"
                                        sx={{ mr: 2 }}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                </div>
                            </form>
                        ) : (
                            <Box width={'100%'} display={'flex'} justifyContent={'center'} >
                                < DotSpinner
                                    size={40}
                                    speed={0.9}
                                    color="blue"
                                />

                            </Box>


                        )}
                    </Box>

                </Modal>
            </div>

        </div >
    );
}

export default AssignTo;
