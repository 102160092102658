import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import TextInput from '../../../form/TextInput';
import { useForm } from 'react-hook-form';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

export default function MarkButton(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        props?.setOpen(false)
        setOpen(false);
    }

    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm()

    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    React.useEffect(() => {
        if (props.open) {
            setOpen(true)
        }
    }, [props.open])


    return (
        <div>
            <Button size='small' variant='outlined' onClick={handleOpen}>Verify</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        MARK AS COMPLETED
                    </Typography>
                    <Grid container mt={2} display={'flex'} alignItems={'center'}>
                        <Grid item md={7}>
                            <Typography style={{fontSize:'14px',fontWeight:500}} id="modal-modal-description" >
                                 Word Count:
                            </Typography>
                        </Grid>
                        <Grid item md={4}>
                            <TextInput type={'number'} control={control} name="count"
                                // label="Project Name"
                                value={watch('count')} />
                        </Grid>
                    </Grid>
                    <Grid container mt={2} display={'flex'} alignItems={'center'}>
                        <Grid item md={7}>
                            <Typography style={{fontSize:'14px',fontWeight:500}} id="modal-modal-description" >
                                Task as Completed:
                            </Typography>
                        </Grid>
                        <Grid item md={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={checked} onChange={handleChange} />}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid container mt={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Button variant='outlined'>Completed</Button>
                        {/* <Button variant='outlined'>Failed</Button> */}
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}