import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    
    Card,
    CardContent,
    Typography,
    Grid,
    Divider,
    Box,
    CircularProgress,
} from "@mui/material";
import { useState } from 'react';
import moment from 'moment';
import { ReimbursementAPI } from '../../../../api/Endpoints/Reimbursment';
const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const Details = ({ ID }) => {
    const classes = useStyles();

    useEffect(() => {
        fetchDetails()
    }, [ID])

    const [Loading, setLoading] = useState(false)
    const [Data, setData] = useState()



    const fetchDetails = () => {
        setLoading(true);
        ReimbursementAPI.getDetails({ id: ID }).then(response => {
            setData(response.data.data);
            setLoading(false);
        })
    }
    return (
        <div>
            {
                Loading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }} >
                        <CircularProgress size={33} />
                        <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                            Loading...
                        </Typography>
                    </Box >
                ) : (
                    <div>

                        <Card className={classes.card}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                            Title:
                                            <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                                {Data?.title}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}  >
                                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                            Date of Activity:
                                            <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                                {moment(Data?.date_of_activity).format('DD/MM/YYYY')}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider className={classes.divider} />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} mt={2}>
                                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                            Manager:
                                            <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                                {Data?.report_to?.name}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} mt={2}>
                                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                            Status:
                                            <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                                {Data?.status}
                                            </Typography>
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Divider className={classes.divider} />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} mt={2}>
                                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                            Amount:
                                            <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                                {Data?.amount}
                                            </Typography>
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Divider className={classes.divider} />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                            Description :
                                        </Typography>
                                        <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >

                                            {Data?.description}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card >
                    </div>
                )
            }
        </div >
    );
};

export default Details;
