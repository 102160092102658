import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Card,
    CardContent,
    Typography,
    Grid,
    Divider,
    Select,
    Backdrop,
    MenuItem,
    DialogTitle,
    InputLabel,
    TextareaAutosize,
    TextField,
    Slide,
    CircularProgress,
    InputAdornment,
} from "@mui/material";
import { useEffect } from 'react';
import { useState } from 'react';
import { Account } from '../../../../api/Endpoints/Account';
import { WorkRequest } from '../../../../api/Endpoints/WorkRequest';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ErrorMessage } from '@hookform/error-message';
import toast from 'react-hot-toast';
import { DeleteForever, Edit, Padding } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { useRef } from 'react';
import Delete from '../../common/Popup/Delete';
import moment from 'moment';



const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
        maxHeight: 500,
        overflowY: 'scroll'
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));


const scheme = yup.object().shape({
    // name: yup.string().required(),
});

const WorkReqNotes = ({ WorkID }) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const classes = useStyles();

    const [Loading, setLoading] = useState(false)
    const [Data, setData] = useState()
    const [noteID, setNoteID] = useState()
    const [deleteNoteId, setDeleteNoteId] = useState()



    // const handleUpdate = () => {
    //     setPageNumber();
    //     setPageNumber(0);
    // }

    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })




        let dataToSubmit = {
            id: noteID,
            project_work_requests_id: WorkID,
            note: data.note,
        };

        let action;

        if (noteID > 0) {
            action = WorkRequest.updateNote(dataToSubmit)
        } else {
            action = WorkRequest.addNote(dataToSubmit)
        }
        action.then(response => {
            console.log(response);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
            toast.success(response.data.message)
            setNoteID()
            setValue('note','')
            fetchDetails();
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    const fetchDetails = () => {
        setLoading(true);
        WorkRequest.getNote({ project_work_requests_id: WorkID }).then(response => {
            console.log(response);
            setData(response.data.data.data);
            setLoading(false);
        })
    }
    const deleteNote = (id) => {
        setDeleteNoteId(id)
    }
    const handleEdit = (data) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        setNoteID(data.id)
        setValue('note', data.note)
    }

    const handleClearNote = () => {
        setValue('note', '')
        setNoteID()
    }

    useEffect(() => {
        fetchDetails()
    }, [])

    console.log(Data);
    return (
        <>
            {
                deleteNoteId &&
                <Delete
                    ID={deleteNoteId}
                    setID={setDeleteNoteId}
                    setDeletePopup={setDeleteNoteId}
                    Callfunc={() => fetchDetails()}
                    url="/projects/work-requests/notes/delete"
                    title="Note"
                />
            }
            {Loading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={Loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    <Card sx={{ margin: 2 }}>
                        <CardContent>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <ErrorMessage
                                    errors={errors}
                                    name="multipleErrorInput"
                                    render={({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(([type, message]) => (
                                            <p key={type}>{message}</p>
                                        ))
                                    }
                                />


                                <Grid item xs={12}>
                                    <InputLabel sx={{
                                        color: "black",
                                        pb: 1
                                    }}>Add Note </InputLabel>
                                    <TextField
                                        {...register('note')}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        sx={{ width: '100%', }}
                                        required
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {watch('note') && (
                                                        <ClearIcon
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={handleClearNote}
                                                        />
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid sx={{ pt: 2 }} item xs={12}>
                                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                    <Card className={classes.card}>
                        <CardContent>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem', marginBottom: 20 }}>
                                        Notes:
                                    </Typography>
                                    {
                                        Data?.map(data => (

                                            <>

                                                <Typography variant="body2" style={{ display: 'inline', fontSize: '16px', justifyContent: 'start', marginTop: 1 }}>
                                                    {data?.note}
                                                </Typography>
                                               <Grid display={'flex'} justifyContent={'end'}>
                                                    <Grid display={'flex'} justifyContent={'space-between'} xs={4}>
                                                        <a style={{ fontSize: 13 }}>Date: {moment(data?.created_at).format('DD-MM-YYYY')}</a>
                                                        <a style={{ fontSize: 13 }}>Added By: {data?.created_user?.name}</a>
                                                    </Grid>
                                               </Grid>
                                                {/* <Grid display={'flex'} justifyContent={'end'}>
                                                    <Button onClick={() => handleEdit(data)}>
                                                        <Edit fontSize='small' />
                                                    </Button>
                                                    <LoadingButton onClick={() => deleteNote(data.id)}>
                                                        <DeleteForever color='error' fontSize='small' />
                                                    </LoadingButton>
                                                </Grid> */}
                                                <Divider className={classes.divider} sx={{}} />
                                            </>

                                        ))
                                    }


                                </Grid>
                            </Grid>


                        </CardContent>
                    </Card>
                </>
            )
            }
        </>
    );
};

export default WorkReqNotes;
