import React, { useState } from 'react';
import Button from '@mui/material/Button';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, IconButton, List, Modal, TextField, Typography } from '@mui/material';

function ImageUploader(props) {
    const [selectedImages, setSelectedImages] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [open, setOpen] = useState(true);

    const onSelectFile = (event) => {
        const selectedFiles = event.target.files;
        const selectedFilesArray = Array.from(selectedFiles);

        const imagesArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file);
        });

        setSelectedImages((previousImages) => previousImages.concat(imagesArray));
        setFileNames((previousFileNames) => previousFileNames.concat(selectedFilesArray.map((file) => file.name)));
        event.target.value = ""; // Clear the file input value after selection
    };

    function deleteHandler(image) {
        setSelectedImages((previousImages) => previousImages.filter((e) => e !== image));
        setFileNames((previousFileNames) => previousFileNames.filter((_, index) => index !== selectedImages.indexOf(image)));
        URL.revokeObjectURL(image);
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90vh',
        borderRadius: '8px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const handleClose = () => {
        setOpen(false);
        props.setShowPopup(false);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={{ marginTop: 5 }}>
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ textAlign: "center", pb: 5 }}>
                            Upload Design
                        </Typography>
                        <form>
                            <label htmlFor="file-input">
                                <Button variant="contained" color="primary" component="span">
                                    <input
                                        id="file-input"
                                        type="file"
                                        multiple
                                        accept="image/png, image/jpeg, image/webp"
                                        onChange={onSelectFile}
                                        style={{ display: 'none' }}
                                    />
                                    <CloudUploadIcon />
                                    <Typography ml={1}>Choose Design</Typography>
                                </Button>
                            </label>

                            <List sx={{ mt: 2, maxHeight: 200, overflowY: 'auto' }}>
                                {selectedImages.map((image, index) => (
                                    <div key={image} style={{ marginBottom: '10px', backgroundColor: '#e6f5e9', display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ border: '1px solid black', width: '50px', height: '50px', overflow: 'hidden' }}>
                                            <img
                                                src={image}
                                                alt="Selected"
                                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                            />
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>{fileNames[index]}</div> {/* Align file name to the left */}
                                        <IconButton>
                                            <DeleteIcon onClick={() => deleteHandler(image)} />
                                        </IconButton>
                                    </div>
                                ))}
                            </List>

                            <TextField
                                id="text-input"
                                label="Remarks"
                                variant="outlined"
                                fullWidth
                                rows={2}
                                multiline
                                autoFocus
                                margin="normal"
                                sx={{ mt: 4 }}
                            />


                            <div style={{ display: "flex", justifyContent: "end" }}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                        mt: 2, mr: 2,
                                        background: "gray",
                                        '&:hover': {
                                            backgroundColor: 'gray',
                                        },
                                    }}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button variant="contained" type="submit" sx={{ mt: 2 }}>
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </Box>
                </div>
            </Modal>
        </>
    );
}

export default ImageUploader;
