import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    InputLabel,
    TextField,
    Slide,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import TextInput from '../../form/TextInput';
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import SelectX from '../../form/SelectX';
import moment from "moment";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import { Account } from "../../../api/Endpoints/Account";
import CurrentDateInput from '../../form/CurrenDateInput';
import { Project } from '../../../api/Endpoints/Project';
import { Country } from '../../../api/Endpoints/Country';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({


    project_name: yup.string().required(),
    description: yup.string(),
    start_date: yup.string(),
    end_date: yup.string(),
    due_date: yup.string(),
    Owner_Id: yup.object(),
    assigned_by_id: yup.object(),
    projects_id: yup.object().shape({
        id: yup.string().required("Please choose a project").typeError("Please choose a project")
    }).required().typeError("Please choose a project"),
    country: yup.object().nullable()
    // reviewer_id: yup.object().shape({
    //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    // }).required().typeError("Please choose a reviewer"),
})




const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({})
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [selectedScopes, setSelectedScopes] = useState([]);

    const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => {
        props.setEditId(undefined)
        setOpen(false);
    };



    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchClient = (e) => {
        let cleint_id = watch('client_id')
        return Account.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }


    const prioriyTask = () => {

    }

        ;


    const handlePriorityChange = (id) => {
        setSelectedPriority(id);
    }


    const options = [
        { value: "New", label: 'New' },
        { value: "On Hold", label: 'On Hold' },
        { value: "Accepted", label: 'Accepted' },
        { value: "Work In Progress", label: 'Work In Progress' },
        { value: "Completed", label: 'Completed' },
        { value: "Cancelled ", label: 'Cancelled ' },
    ];

    const RequestType = [
        { value: 'Internal', label: 'Internal' },
        { value: 'Client', label: 'Client' },
    ];

    const priorityOptions = [
        {
            value: "Low", label: "Low"
        },
        {
            value: "Medium", label: "Medium"
        },
        {
            value: "High", label: "High"
        },

    ];



    const costmodel = [
        { value: "No Cost", label: "No Cost" },
        { value: "Fixed Cost", label: "Fixed Cost" },
        { value: "Hourly Cost", label: "Hourly Cost" }
    ]


    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })


        let date = moment(data.start_date).format('YYYY-MM-DD');
        let enddate = moment(data.end_date).format('YYYY-MM-DD');
        let duedate = moment(data.due_date).format('YYYY-MM-DD');

        let dataToSubmit = {
            id: props.editId,
            name: data.project_name,
            description: data.description,
            start_date: date,
            due_date: duedate,
            accounts_id: data.client_id?.id,
            end_date: enddate,
            country_id: data.country?.id,
            scopes: selectedScopes
        };

        console.log(dataToSubmit);
        let action;

        if (props.editId > 0) {
            console.log("dataToSubmit", dataToSubmit);
            action = Project.update(dataToSubmit)
        } else {
            action = Project.add(dataToSubmit)
        }
        action.then(response => {

            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            props.setEditId()
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }


    // console.log(selectedScopes);
    const fetchDetails = async () => {
        console.log('----------');
        const response = await Project.getDetails({ project_id: props.editId })
        if (response.data.status === "success") {
            const data = response.data.data
            console.log(data);
            setSelectedScopes(data.scopes?.map(item => item.scope))
            setValue('project_name', data.name)
            setValue('description', data.description)
            if (data.accounts_id) {
                setValue('client_id', {
                    id: data.accounts_id, name: data.account?.account_name
                })
            } if (!data.accounts_id) {
                setValue('client_id', '')
            }
            if (data.country_id) {
                setValue('country', {
                    id: data.country?.id, name: data.country?.name
                })
            }
            setValue('start_date', data?.start_date)

        }

    };
    
    const isScopeChecked = (value) => selectedScopes.includes(value);

    const fetchCountry = (e) => {
        return Country.get({ keyword: e }).then(response => {
            if (typeof response.data.data !== "undefined") {
                return response.data.data
            } else {
                return [];
            }
        })
    }

    const handleScopeChange = (event) => {
        const checkboxValue = event.target.value;
        if (event.target.checked) {
            // Add the checkbox value to the selectedScopes array if checked
            setSelectedScopes([...selectedScopes, checkboxValue]);
        } else {
            // Remove the checkbox value from the selectedScopes array if unchecked
            setSelectedScopes(selectedScopes.filter((value) => value !== checkboxValue));
        }
    };



    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetails()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        }

    }, [])

    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('client_id')])



    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Create Project"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Project" : "Create Project"}</DialogTitle>
                <DialogContent>
                    {!loading ?
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="project_name"
                                    label="Project Name"
                                    value={watch('project_name')} />
                            </Grid>



                            <Grid sx={{ p: 1, }} item xs={12}>
                                <SelectX
                                    label={"Choose Client"}
                                    options={fetchClient}
                                    control={control}
                                    error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                    error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                    name={'client_id'}
                                    defaultValue={watch('client_id')}
                                />
                            </Grid>
                            <Grid container spacing={2} sx={{ display: 'flex', p: 1 }}>
                                <Grid item xs={12} sm={6}>
                                    <CurrentDateInput
                                        control={control}
                                        name="start_date"
                                        label="Start Date"
                                        value={watch('start_date')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SelectX
                                        label='Choose Country'
                                        options={fetchCountry}
                                        control={control}
                                        name={'country'}
                                        defaultValue={watch('country')}
                                    />
                                </Grid>


                            </Grid>
                            {/* <Grid container spacing={2} sx={{ display: 'flex', p: 1 }}>
                                <Grid item xs={6} sm={6}>
                                    
                                </Grid>
                                <Grid xs={6} sm={6}>
                                   
                                </Grid>
                            </Grid> */}

                            <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1
                                }}>Description </InputLabel>
                                <TextField
                                    {...register('description')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    sx={{ width: '100%', }}

                                />
                            </Grid>

                            <Grid sx={{ p: 1, mt: 1, pl: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1
                                }}>Scope </InputLabel>

                                <FormGroup style={{ padding: 1 }}>
                                    <Grid container spacing={2} sx={{ display: 'flex', }}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel control={<Checkbox onChange={handleScopeChange} value="Website Development" checked={isScopeChecked('Website Development')} />} label="Website Development" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel control={<Checkbox onChange={handleScopeChange} value="SEO" checked={isScopeChecked('SEO')} />} label="SEO" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ display: 'flex', }}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel control={<Checkbox onChange={handleScopeChange} value="Influencer Marketing" checked={isScopeChecked('Influencer Marketing')} />} label="Influencer Marketing" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel control={<Checkbox onChange={handleScopeChange} value="Social Media Management" checked={isScopeChecked('Social Media Management')} />} label="Social Media Management" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ display: 'flex', }}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel control={<Checkbox onChange={handleScopeChange} value="Paid Ads" checked={isScopeChecked('Paid Ads')} />} label="Paid Ads" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel control={<Checkbox onChange={handleScopeChange} value="Video Shoot" checked={isScopeChecked('Video Shoot')} />} label="Video Shoot" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ display: 'flex', }}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel control={<Checkbox onChange={handleScopeChange} value="App Development" checked={isScopeChecked('App Development')} />} label="App Development" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel control={<Checkbox onChange={handleScopeChange} value="IT Managemenet" checked={isScopeChecked('IT Managemenet')} />} label="IT Managemenet" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ display: 'flex', }}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel control={<Checkbox onChange={handleScopeChange} value="Branding" checked={isScopeChecked('Branding')} />} label="Branding" />
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </Grid>

                            <Grid sx={{ p: 1, mb: 3 }} item xs={12}>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            </Grid>



                        </form>
                        :
                        <>Loading...</>
                    }

                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        </div >
    );
};

export default Create
