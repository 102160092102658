import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextareaAutosize,
  Typography,
} from "@mui/material";

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Activities } from "../../../../api/Endpoints/Activities";
import { makeStyles } from "@material-ui/core";
import { DotSpinner } from "@uiball/loaders";
import { WorkPlan } from "../../../../api/Endpoints/WorkPlan";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: "1px 2px 2px 2px rgba(0, 0, 0, 0.3)",
  },
  title: {
    fontWeight: "bold",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  select: {
    minWidth: 200,
  },
}));

const divider = {
  marginTop: 2,
};

const DetailView = (props) => {
  //static data
  const data = [
    {
      id: 1,
      title: "Implement New Feature",
      description: "Implement a new feature to enhance user experience. This feature will provide users with a more efficient and enjoyable experience.",
      assignedTo: ["User 1", "User 2"],
      dueDate: "1-01-2022",
      status: "Not Started",
      explanation: "This task is yet to be started. It is a high-priority task that requires immediate attention.",
    },
    {
      id: 2,
      title: "Optimize Database",
      description: "Optimize database performance for faster data retrieval. This will improve the overall system performance and user experience.",
      assignedTo: ["User 2", "User 3"],
      dueDate: "2-01-2022",
      status: "In Progress",
      explanation: "This task is currently in progress. The database optimization process has begun, and significant improvements are expected.",
    },
    {
      id: 3,
      title: "Fix Bugs",
      description: "Identify and fix all known bugs in the system. This will ensure a more stable and reliable system for the users.",
      assignedTo: ["User 3", "User 4"],
      dueDate: "3-01-2022",
      status: "Partially Achieved",
      explanation: "This task has been partially achieved. Most of the bugs have been identified and fixed, but a few minor issues remain.",
    },
    {
      id: 4,
      title: "Deploy to Production",
      description: "Deploy the updated system to production. This will make the new features and improvements available to all users.",
      assignedTo: ["User 4", "User 1"],
      dueDate: "4-01-2022",
      status: "Achieved",
      explanation: "This task has been successfully achieved. The updated system has been deployed to production without any issues.",
    },
  ];

  const fetchData = data.find((value) => value.id == props.id);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  // const handleClickOpen = () => { props.onNew(); };
  const handleClose = () => {
    props.handleClose(false);
    // setOpen(false);
  };
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);

  //static data state
  const [detail, setDetail] = useState({
    id: 0,
    title: "",
    description: "",
    assignedTo: "",
    dueDate: "",
  });

  // const fetchTaskDetails = () => {
  //   console.log(props.id);

  //   const fetchDetail = data.find((value) => value.id === props.id);

  //   setDetail(fetchDetail);
  //   console.log(detail);

  //   // WorkPlan.getDetails({ id: props.id })
  //   //   .then((response) => {
  //   //     console.log(response);
  //   //     setDetails(response.data.data);
  //   //     setLoading(false);
  //   //     console.log(response.data.data);
  //   //   })
  //   //   .catch((errors) => {
  //   //     console.log(errors);
  //   //   });
  // };

  const handleStorageChange = () => {
    console.log("testing");
  };

  // useEffect(() => {
  //   fetchTaskDetails();
  // }, []);

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: "100%",
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>VIEW TARGET</DialogTitle>
        <DialogContent>
          <>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h1"
                  component="h2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.3rem",
                    marginRight: "10px",
                  }}
                >
                  Title:
                </Typography>
                <Typography variant="body2" style={{  fontSize: "1.2rem" }}>
                  {fetchData.title}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop:'10px'
                }}
              >
                <Typography
                  variant="body1"
                  component="h2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.3rem",
                    marginRight: "10px",
                  }}
                >
                  Due Date:
                </Typography>
                <Typography variant="body2" style={{  fontSize: "1.2rem" }}>
                  {fetchData.dueDate}
                  {/* {details?.created_at?.slice(8, 10)}-{details?.created_at?.slice(5, 7)}-{details?.created_at?.slice(0, 4)} */}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="body1"
                  component="h2"
                  style={{ fontWeight: "bold",  fontSize: "1.2rem",marginTop:'10px' }}
                >
                  Description
                </Typography>
                <Typography
                  variant="body2"
                  style={{ paddingTop: 10, fontSize: "14px" }}
                >
                  {fetchData.description}
                  {/* {details?.duration_hours ? details?.duration_hours == 1 ? details?.duration_hours + "hr" : details?.duration_hours + 'hrs' : ''} {details?.duration_minutes ? details?.duration_minutes + "mins" : ''} */}
                </Typography>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Typography
              variant="body1"
              component="h2"
              style={{ fontWeight: "bold",  fontSize: "1.1rem",textDecoration:'underline' }}
            >
              Result By Assignee:
            </Typography>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <Typography
                  variant="body1"
                  component="h2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    marginRight: "10px",
                  }}
                >
                  Status
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={details?.user?.name}
                    label="Status"
                  >
                    <MenuItem value="completed">Not Started</MenuItem>
                    <MenuItem value="in-progress">In Progress</MenuItem>
                    <MenuItem value="pending">Partially Achieved</MenuItem>
                    <MenuItem value="pending">Achieved</MenuItem>
                    <MenuItem value="pending">Not Achieved</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} style={{ marginTop: "15px" }}>
                <Typography
                  variant="body1"
                  component="h2"
                  style={{ fontWeight: "bold",  fontSize: "1.1rem" }}
                >
                  Explanation
                </Typography>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={10}
                  placeholder="Minimum 3 rows"
                  defaultValue={details?.status}
                  style={{ width: "100%", paddingTop: 10, fontSize: "16px" }}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" style={{ marginTop: 20 }}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Grid>

            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="body1"
                  component="h2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    marginTop: 2,
                  }}
                >
                  Result By Assigner:
                </Typography>
              </Grid>
            </Grid>

            {/* <Divider className={classes.divider} /> */}
            <Grid container spacing={2} style={{paddingTop:10}}>
              <Grid item xs={2} sm={2}>
                <Typography
                  variant="body1"
                  component="h2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                  }}
                >
                  Status:
                </Typography>
              </Grid>
              <Grid item xs={10} sm={10}>
                <Typography variant="body2" style={{ fontSize: "16px" }}>
                  In Progress
                </Typography>
              </Grid>
            </Grid>

            {/* <Divider className={classes.divider} /> */}
            <Grid container spacing={2} style={{paddingTop:10}}>
              <Grid item xs={2} sm={2}>
                <Typography
                  variant="body1"
                  component="h2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                  }}
                >
                  Remarks
                </Typography>
              </Grid>

              <Grid item xs={10} sm={10}>
                <Typography variant="body2" style={{ fontSize: "1.1rem" }}>
                  Good Work, Sanjeev.
                </Typography>
              </Grid>
            </Grid>
          </>

                  {/* Loading Spinner */}
          {/* <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            height={"30%"}
            mt={10}
          >
            <DotSpinner size={40} speed={0.9} color="blue" />
          </Box> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DetailView;
