import { Box, Button, DialogActions, Divider, Grid, Slide, Typography, } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { DeleteForever, Edit } from '@material-ui/icons';
import parse from 'html-react-parser';
import { DotSpinner } from '@uiball/loaders';
import { Task } from '../../../../../../api/Endpoints/Task';
import Delete from '../../../../common/Popup/Delete';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const divider = {
    marginTop: 2,
}

const DetailView = (props) => {

    const classes = useStyles();
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const [open, setOpen] = React.useState(true);
    // const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => {
        props.handleClose(false)
        // setOpen(false); 
    };
    const [details, setDetails] = useState()
    const [loading, setLoading] = useState(false);
    const [Notes, setNotes] = useState([])
    const [deleteID, setDeleteID] = useState(false)
    const [refresh, setRefresh] = useState()



    const handleEdit = (id) => {
        console.log(id);
        props.setEditId(id)
        props.setTab(1)
    }

    const handleRefresh = () => {
        setRefresh(Math.random)
    }

    const fetchTaskDetails = () => {
        console.log(props.id);
        setLoading(true)
        Task.getTaskDetails({ id: props.id }).then((response) => {
            // fetchNotes()
            console.log(response);
            setDetails(response.data.data)
            setLoading(false)
            console.log(response.data.data);
        }).catch(errors => {
            console.log(errors);
        })

    }

    const fetchNotes = () => {
        Task.getNote({ project_tasks_id: props.id }).then((notes) => {
            console.log(notes);
            setNotes(notes.data.data.data)
        })
    }

    const deleteNote = (id) => {
        setDeleteID(id)
    }

    const handleStorageChange = () => {
        console.log('testing');
    };


    useEffect(() => {
        props.setEditId()
        fetchTaskDetails()
        fetchNotes()
    }, [props.id, refresh,props.refresh])

    return (
        <>
            {
                deleteID &&
                <Delete
                    ID={deleteID}
                    setID={setDeleteID}
                    setDeletePopup={setDeleteID}
                    Callfunc={() => fetchNotes()}
                    url="/projects/tasks/notes/delete?id="
                    title="Note"
                />
            }

            {/* <Grid container spacing={2}>
                <Grid display={'flex'} justifyContent={'end'} item xs={12} sm={12}>
                    <Button
                        variant="contained"
                        onClick={subscribeTask}
                    >
                        Subscribe
                    </Button>
                </Grid>
            </Grid> */}
            {
                details ? <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                Title:
                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {details?.title}
                            </Typography>

                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Assigned To:

                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {details?.assigned_to_user?.name}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Assigned By:
                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                                {details?.assigned_by_user?.name}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Project:

                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {details?.project?.name}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Status:
                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                                {details?.status}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Due Date:

                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {moment(details?.due_date).format("DD-MM-YYYY")}
                            </Typography>

                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Assigned By:
                        </Typography>
                        <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                            {details?.assigned_by_user?.name}
                        </Typography>
                    </Grid> */}
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                Description:
                            </Typography>
                            <Typography variant="body2" style={{ fontSize: '16px' }}>
                                <a>{details?.description && parse(details?.description)}</a>
                                {/* {details?.description} */}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem', marginTop: 2 }}>
                                Notes:
                            </Typography>
                            {
                                Notes &&
                                Notes.map((note) => (


                                    <>

                                        <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                            <Grid display={'flex'} justifyContent={'space-between'} xs={8}>
                                                <a style={{ fontSize: 13 }}>Date: {moment(note.created_at).format('DD-MM-YYYY')}</a>
                                                <a style={{ fontSize: 13 }}>Added By: {note.created_user?.name}</a>
                                            </Grid>
                                            {/* <hr /> */}

                                            <p style={{}} className='text'>
                                                {
                                                    note.note.length <= 119 ? (
                                                        <a>{note.note}</a>
                                                    ) : (
                                                        isReadMore ? (
                                                            <div>
                                                                <a>{note.note.slice(0, 120)}</a>
                                                                <a onClick={toggleReadMore} style={{ color: 'blue', cursor: 'pointer' }}> ...read more</a>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <a>{note.note}</a>
                                                                <a onClick={toggleReadMore} style={{ color: 'blue', cursor: 'pointer' }}>show less</a>
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </p>
                                            <Grid display={'flex'} justifyContent={'end'}>
                                                <Button onClick={() => handleEdit(note.id)}>
                                                    <Edit fontSize='small' />
                                                </Button>
                                                <LoadingButton onClick={() => deleteNote(note.id)}>
                                                    <DeleteForever color='error' fontSize='small' />
                                                </LoadingButton>
                                            </Grid>

                                            {/* {details?.notes} */}
                                        </Typography>
                                        <Divider className={classes.divider} />

                                    </>
                                ))}
                        </Grid>
                    </Grid>

                    {/* <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Start Date:
    
                        </Typography>
                        <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                            {details?.start_date?.slice(8, 10)}-{details?.start_date?.slice(5, 7)}-{details?.start_date?.slice(0, 4)}
                        </Typography>
    
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Remarks By SpiderWorks:
                        </Typography>
                        <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                            {details?.remarks_for_client}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Cost Model:
    
                        </Typography>
                        <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
    
                            {details?.cost_model}
                        </Typography>
    
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Status :
                        </Typography>
                        <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
    
    
                            {details?.status}
                        </Typography>
                    </Grid>
                </Grid> */}
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>

                </> : (
                    <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'30%'} mt={10}>
                        <DotSpinner size={40} speed={0.9} color="blue" />
                    </Box>
                )}

        </>
    )
}

export default DetailView;
