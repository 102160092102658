import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Backdrop, Button, Card, CircularProgress, Dialog, DialogContent, Grid, Modal, Slide, TextField } from "@mui/material";
import { useState } from 'react';
import DetailView from './Details/DetailView';
import DetailMinutes from './Details/Minutes';
import DetailAttendees from './Details/Attendees';
import Index from './Tabs/Minutes';
import Agenda from './Tabs/Agenda';
import { Meetings } from '../../../api/Endpoints/Meeting';
import { useForm } from 'react-hook-form';
import SendTab from './Tabs/send';
import { Done } from '@mui/icons-material';
import { useEffect } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function content(props) {
    return (
        <div
            hidden={props.value !== props.index}
        >

        </div>
    )

}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs(props) {
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(true);
    const [editId, setEditId] = useState()



    const handleClose = () => {
        props.handleClose(false)
        // setOpen(false); 
    };

    const [details, setDetails] = useState(true)
    const [copiedText, setcopiedText] = useState('Copy Link')
    const [currentUrl, setCurrentUrl] = useState();


    const handleCopyLink = async () => {
        setcopiedText('Copied')
        await navigator.clipboard.writeText(currentUrl);

        setTimeout(() => {
            setcopiedText('Copy Link')
        }, 2000);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const showDetails = () => {
        setDetails(true)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 400,
        bgcolor: 'background.paper',
        p: 4,
        borderRadius: 3
    };
    console.log(props.id);

    const onSubmitSendTab = (data) => {
        console.log(data);
    };

    useEffect(() => {
        if (props.id) {
            const url = window.location.href
            if (url.includes('?id=')) {
                setCurrentUrl(url);
            } else {
                setCurrentUrl(window.location.href + '?id=' + props?.id)
            }
        }
    }, [props.id, currentUrl]);


    return (
        <>

            {/* <Dialog
                open={open}
                onClose={handleClose}
            // sx={{ width: '100%', mt: 1 }}
            > */}
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                disableEscapeKeyDown
                aria-describedby="alert-dialog-slide-description"
            >
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ ml: 2 }}>
                    <Tab label="Details" {...a11yProps(0)} />
                    <Tab label="Attendees" {...a11yProps(1)} />
                    <Tab label="Agenda" {...a11yProps(2)} />
                    <Tab label="Minutes" {...a11yProps(3)} />
                    {
                        value == 4 &&
                        <Tab label="Send" {...a11yProps(4)} />
                    }
                    <Button
                        variant="outlined"
                        onClick={handleCopyLink}
                        sx={{ height: 30, width: 90, mt: 1, mr: 1, marginLeft: 'auto', fontSize: '12px' }}
                    >{copiedText}{copiedText == 'Copied' && <Done fontSize='small' />}</Button>
                    <Button
                        variant="contained"
                        onClick={() => setValue(4)}
                        sx={{ height: 30, width: 90, mt: 1, marginLeft: 'auto', marginRight: 5, fontSize: '12px' }}
                    >SEND</Button>
                </Tabs>
                <DialogContent>

                    <TabPanel value={value} index={0}>
                        <DetailView detailEdit={props?.detailEdit} setTab={setValue} setEditId={setEditId} id={props.id} handleClose={props.handleClose} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <DetailAttendees projectID={props.projectID} id={props.id} setTab={setValue} editId={editId} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Agenda projectID={props.projectID} id={props.id} setTab={setValue} editId={editId} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Index meetingID={props.id} setTab={setValue} projectID={props.projectID} />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <SendTab onSubmit={onSubmitSendTab} meetingID={props.id} setTab={setValue} projectID={props.projectID} />
                    </TabPanel>

                    {/* <Divider />

                    <div style={{ width: '95%', display: 'flex', justifyContent: 'end', marginTop: 5 }}>
                        <Button variant="contained" onClick={() => props.handleClose(false)} style={{ width: 'fit-content', mr: 7 }}>
                            Cancel
                        </Button>

                    </div> */}
                </DialogContent>
            </Dialog>

        </>
    );
}
