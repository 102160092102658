import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Button, DialogActions, Divider, Grid, InputLabel, Slide, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Users } from '../../../../api/Endpoints/Users';
import Delete from '../../common/Popup/Delete';
import Create from '../../VendorPayments/create';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteOutline } from '@mui/icons-material';
import { VendorPayments } from '../../../../api/Endpoints/VendorPayments';
import { DataGrid } from '@mui/x-data-grid';
import { VideoShootApi } from '../../../../api/Endpoints/VideoShoot';
import { Waveform } from '@uiball/loaders';
import BasicTabs from '../../VendorPayments/TabMenus';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
    user_id: yup.object().required(),
});


const VideoVendorPayment = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = React.useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [deleteID, setDeleteID] = useState()
    const [editId, setEditId] = useState();
    const [list, setList] = useState([])
    const [detailViewId, setDetailViewId] = useState(false)
    const [totalAmount, setTotalAmount] = useState()



    const DetailViewOpen = (id) => {
        console.log(id);
        // console.log(`this is:${id}`)
        setDetailViewId(id)
    }

console.log(detailViewId)
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })



    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        props.setEditId()
        setOpen(false);
    };
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleDeleteAlert = (id) => {
        setDeleteID(id)
    }

    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }


    const columns = [
        {
            field: 'title',
            headerName: 'Name',
            width: 220,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button onClick={() => DetailViewOpen(params.row.id)} style={{ color: 'black' }} size="small" >{params.value}</Button >
                    </>
                );
            },
        },
        {
            field: 'reimbursement_type',
            headerName: 'Type',
            width: 130,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.value}</Button >
                    </>
                );
            },
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 120,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.value}</Button >
                    </>
                );
            },
        },
        {
            field: 'edit', headerName: '', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <>
                    <a size="small" onClick={() => handleEdit(params.row.id)}>
                        <EditIcon fontSize='small' style={{ color: 'blue', cursor: 'pointer', marginRight: 15 }} />
                    </a>
                </>;
            }
        },

        // {
        //     field: 'action', headerName: '', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
        //         let id = params.value?.id;
        //         return <>
        //             <Button style={{ color: 'black' }} size={'small'} onClick={() => handleDeleteAlert(id)}>
        //                 <DeleteOutline fontSize='small' />
        //             </Button>
        //         </>;
        //     }
        // },
    ]

    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    useEffect(() => {
        // fetchDetails()
        console.log('refreshing');
    }, [props.refresh])

    useEffect(() => {
        fetchList()
        setOpen(true);
    }, [])

    const fetchList = () => {
        setLoading(true);
        VendorPayments.get({ id: props?.id })
            .then(response => {
                console.log(response);
                const responseData = response.data?.data
                console.log(responseData);
                setList(responseData);
                let totalAmount=0
                responseData.map((obj) => {
                    totalAmount=totalAmount+obj?.amount
                })
                setTotalAmount(totalAmount)
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }
    console.log(list)

    return (
        <div>

            {deleteID && <Delete
                ID={deleteID}
                setID={setDeleteID}
                setDeletePopup={setDeleteID}
                Callfunc={() => fetchList()}
                url="/accounts/vendor-payments/delete?id="
                title="Subscriber" />}

            {
                detailViewId &&
                <BasicTabs id={detailViewId} setId={setDetailViewId} />
            }

            {loading ? (
                <Grid mt={'40%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Waveform
                        size={40}
                        lineWeight={3.5}
                        speed={1}
                        color="black"
                    />
                </Grid>
            ) : (

                <>
                    <Grid alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                        <Grid>
                            <a style={{ color: 'grey' }}>Total Amount: {totalAmount}</a>
                        </Grid>
                        <Grid>
                            <Create source={'videoShoot'} key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} VSid={props.id} fetchData={fetchList} />
                        </Grid>
                    </Grid>

                    <Grid mt={1} height={300}>
                        <DataGrid
                            paginationMode="server"
                            rows={list}
                            columns={columns}
                            page={pageNumber}
                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            onPageChange={handlePageChange}
                            autoHeight
                            density={'comfortable'}
                        />
                    </Grid>
                </>
            )
            }

            {/* <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 50 }}>

                <h3>Add New Payment</h3>

                <ErrorMessage
                    errors={errors}
                    name="multipleErrorInput"
                    render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                            <p key={type}>{message}</p>
                        ))
                    }
                />
                <Grid sx={{ p: 1 }} item xs={12}>
                    <TextInput control={control} name="title"
                        label="Title"
                        value={watch('title')} />
                </Grid>

                <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                    <InputLabel sx={{
                        color: "black",
                        pb: 0.5,
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Description </InputLabel>
                    <TextField
                        {...register('description')}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={2}
                        sx={{ width: '100%', }}
                    />
                </Grid>

                <Grid sm={12} spacing={2} display={'flex'} justifyContent={'space-between'} container sx={{ p: 1, pt: 1 }}>
                    <Grid item xs={12} sm={6}>
                        <InputLabel sx={{
                            color: "black",
                            pb: 0.5,
                            fontSize: '14px',
                            fontWeight: 500
                        }}>Paid By </InputLabel>
                        <SelectX
                            key={refresh * 0.2}
                            // label={"Paid By"}
                            options={fetchUsers}
                            control={control}
                            error={errors?.assigned_to_id?.id ? errors?.assigned_to_id?.id?.message : false}
                            error2={errors?.assigned_to_id?.message ? errors?.assigned_to_id?.message : false}
                            name={'user_id'}
                            defaultValue={watch('user_id')}
                            menuPlacement="top"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <TextInput control={control} name="amount"
                            label="Amount"
                            value={watch('amount')} />
                    </Grid>
                </Grid>

                <Grid sm={12} spacing={2} display={'flex'} justifyContent={'space-between'} container sx={{ p: 1, pt: 1 }}>
                    <Grid item xs={12} sm={6}>
                        <InputLabel sx={{
                            color: "black",
                            pb: 0.5,
                            fontSize: '14px',
                            fontWeight: 500
                        }}>Vendor</InputLabel>
                        <SelectX
                            key={refresh * 0.2}
                            // label={"Paid By"}
                            options={fetchUsers}
                            control={control}
                            error={errors?.assigned_to_id?.id ? errors?.assigned_to_id?.id?.message : false}
                            error2={errors?.assigned_to_id?.message ? errors?.assigned_to_id?.message : false}
                            name={'vendor'}
                            defaultValue={watch('vendor')}
                            menuPlacement="top"
                        />
                    </Grid>

                </Grid>


                <Grid sx={{ pt: 2, pb: 2, mt: 2 }} item xs={12}>
                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                </Grid>
            </form> */}


        </div>
    )
}

export default VideoVendorPayment;
