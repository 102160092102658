import React from 'react'
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    Select,
    Slide, TextField, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-hot-toast';
import { Waveform } from '@uiball/loaders'
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Employee } from '../../../../api/Endpoints/Employee';
import Delete from '../../common/Popup/Delete';
import SelectX from '../../../form/SelectX';
import TextInput from '../../../form/TextInput';
import { VideoShootApi } from '../../../../api/Endpoints/VideoShoot';

function VideoShootLinks({ id }) {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({});
    const [loading, setLoading] = useState(false)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [ID, setID] = useState(0)
    const [refresh, setRefresh] = React.useState(false);
    const [list, setList] = useState([])
    const [editID, setEditID] = useState(0)
    const [pageNumber, setPageNumber] = useState(0);

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "ADD",
        loading: false,
        disabled: false,
    });
    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            width: 150,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.value}</Button >
                    </>
                );
            },
        },

        {
            field: 'link',
            headerName: 'Link',
            width: 330,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <a href={params.value} >{params.value}</a >
                    </>
                );
            },
        },
        {
            field: 'edit', headerName: '', width: 10, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <>
                    <a style={{ color: 'black', cursor: 'pointer' }} size={'small'} onClick={() => handleEdit(params?.row)}>
                        <EditOutlined fontSize='small' />
                    </a>
                </>;
            }

        },
        {
            field: 'action', headerName: '', width: 10, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <>
                    <a style={{ color: 'black' }} size={'small'} onClick={() => handleDeleteAlert(id)}>
                        <DeleteOutline fontSize='small' />
                    </a>
                </>;
            }
        },
    ]

    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleEdit = (data) => {
        setEditID(data?.id)
        setValue('title', data?.title)
        setValue('url', data?.link)
        setFormButtonStatus({ ...formButtonStatus, label: 'Edit' });
    }

    const handleCancelEdit = () => {
        setEditID(0)
        setValue('title', null)
        setValue('url', null)
        setFormButtonStatus({ ...formButtonStatus, label: 'Add' });
    }

    const onSubmit = async (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: false });

        const dataToSubmit = {
            id: editID,
            project_video_shoots_id: id,
            title: data.title,
            link: data.url,
        };
        console.log(dataToSubmit);
        let action;

        if (editID > 0) {
            action = VideoShootApi.updateLink(dataToSubmit)
            console.log('update');
        } else {
            action = VideoShootApi.addLink(dataToSubmit)
            console.log('here');
        }

        action.then((response) => {
            console.log("respponser here", response);
            if (response?.data?.status === "success") {

                fetchList()
                toast.success(response?.data?.message)
                setValue('title', null)
                setValue('url', null)
                setEditID(0)
            }

            setFormButtonStatus({ ...formButtonStatus, label: 'Add', loading: false, disabled: false });
        }).catch((error) => {
            console.log(error);
            setFormButtonStatus({ ...formButtonStatus, label: 'Add', loading: false, disabled: false });
        })
    }

    const fetchList = () => {
        setLoading(true)
        VideoShootApi.getLinks({ project_video_shoots_id: id }).then((response) => {
            console.log(response);
            if (response?.data?.data) {
                setList(response.data.data)
                setLoading(false)
            }
            setLoading(false)
        }).catch((error) => {
            console.log(error);
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchList()
    }, [])

    const handleDeleteAlert = (id) => {
        setID(id)
        setDeletePopup(true)
    }

    return (
        <div>

            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                Callfunc={() => fetchList()}
                url="/projects/video-shoots/links/delete?id="
                title="Link" />}


            {editID ? <Grid><a style={{ fontSize: '14px', color: 'blue' }}>You are editing a Link, <span onClick={handleCancelEdit} style={{ textDecoration: 'underline', color: 'red', cursor: 'pointer', fontSize: '14px' }}>Click</span> to cancel</a></Grid> : ''}
            <Grid container spacing={2} mb={1} mt={0}>
                <Grid item xs={5} sm={5}>
                    <TextInput control={control} name="title"
                        // label="Title"
                        placeholder={'Title'}
                        value={watch('title')}
                        maxLength={'249'}
                    />
                </Grid>
                <Grid item xs={5} sm={5}>
                    <TextInput control={control} name="url"
                        // label="URL"
                        placeholder={'URL'}
                        value={watch('url')}
                        maxLength={'249'}
                    />
                </Grid>
                <Grid item xs={2} sm={2} >
                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                        onClick={handleSubmit(onSubmit)}
                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>

                </Grid>

            </Grid>
            {loading ? (
                <Grid mt={'40%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Waveform
                        size={40}
                        lineWeight={3.5}
                        speed={1}
                        color="black"
                    />
                </Grid>
            ) : (

                //height 520, no autoheight
                <div style={{ height: '100%', width: '100%' }}>
                    {typeof list === "object" &&
                        <DataGrid
                            autoHeight
                            paginationMode="server"
                            rows={list}
                            columns={columns}
                            page={pageNumber}
                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            rowCount={list?.meta?.total}
                            onPageChange={handlePageChange}
                            density={"comfortable"}
                        // getRowId={(row) => row?.id || row?.title || row?.shoot_start_date || /* add more properties to check */ undefined}
                        />
                    }
                </div>
            )}
        </div >

    )
}

export default VideoShootLinks