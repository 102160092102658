import React, { useState } from "react";
import ImageUloder from "../../common/ImageUploader";
import { Button, Typography } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const Designs = () => {

    const [shwoPopup, setShowPopup] = useState(false)


    return (
        <>

            <div style={{ marginTop: 5 }} onClick={() => { setShowPopup(true) }}>

                <div style={{ marginTop: 20, textAlign: 'center' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        component="label"
                        style={{ border: '2px dashed #aaa', borderRadius: '5px', padding: '30px', width: '300px' }}
                    >
                        <CloudUploadIcon fontSize="large" />

                        <Typography variant="h6" component="span" ml={1} >
                            Upload Designs
                        </Typography>


                    </Button>
                </div>
            </div >
            {shwoPopup &&
                < ImageUloder setShowPopup={setShowPopup} />
            }
        </>
    );
};

export default Designs;
