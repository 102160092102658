import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../form/TextInput";
import { Project } from "../../../api/Endpoints/Project";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import { Website_live_tracker } from "../../../api/Endpoints/WebsiteTracker";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
  title: yup.string().required("Title is requried"),
});

const Create = (props) => {
  const {
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm({ resolver: yupResolver(scheme) });
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    props.onNew();
  };

  const handleClose = () => {
    setOpen(false);
    reset();
    setTimeout(() => {
      props.setEditId(undefined);
    }, 500);
  };

  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Submit",
    loading: false,
    disabled: false,
  });

  const onSubmit = (data) => {
    setFormButtonStatus({
      ...formButtonStatus,
      loading: true,
      disabled: false,
    });

    let dataToSubmit = {
      title: data.title,
      url: data.url,
    };

    let action;

    if (props.editId > 0) {
      dataToSubmit.id = props.editId;
      action = Website_live_tracker.update(dataToSubmit);
    } else {
      action = Website_live_tracker.add(dataToSubmit);
    }
    action
      .then((response) => {
        setFormButtonStatus({
          ...formButtonStatus,
          loading: false,
          disabled: false,
        });
        toast.success(response.data.message);
        handleClose();
        props.onUpdate();
      })
      .catch((errors) => {
        toast.error("Internal server error");

        setFormButtonStatus({
          label: "Create",
          loading: false,
          disabled: false,
        });
      });
  };

  const fetchDetails = async () => {
    setLoading(true);
    const response = await Website_live_tracker.getDetails({
      id: props.editId,
    });
    setLoading(false);
    if (response.data.status === "success") {
      const data = response.data.data;
      setValue("title", data.title);
      setValue("url", data.url);
    }
  };

  useEffect(() => {
    if (parseInt(props.editId) > 0) {
      fetchDetails();
      setOpen(true);
    } else if (Number(props.editId) === 0) {
      setOpen(true);
    }
  }, [props.editId]);

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        {props.icon ? (
          <AddIcon
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          />
        ) : (
          "Add Website"
        )}
      </Button>

      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: "100%",
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {parseInt(props.editId) > 0 ? "Edit Website" : "Add Website"}
        </DialogTitle>
        <DialogContent>
          {!loading ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <ErrorMessage
                errors={errors}
                name="multipleErrorInput"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p key={type}>{message}</p>
                  ))
                }
              />

              <Grid sx={{ p: 1 }} item xs={12}>
                <TextInput
                  control={control}
                  name="title"
                  label="Title"
                  value={watch("title")}
                />
              </Grid>

              <Grid sx={{ p: 1 }} item xs={12}>
                <TextInput
                  control={control}
                  name="url"
                  label="URL"
                  value={watch("url")}
                />
              </Grid>
              <Grid sx={{ p: 1 }} item xs={12}>
                <TextInput
                  type="number"
                  control={control}
                  name="frequency"
                  label="Frequency to check (Number of minutes)"
                  value={watch("frequency")}
                />
              </Grid>
              <Grid sx={{ p: 1 }} item xs={12}>
                <TextInput
                  control={control}
                  name="text"
                  label="Text to check "
                  value={watch("text")}
                />
              </Grid>

              <Grid sx={{ p: 1 }} item xs={12}>
                <LoadingButton
                  loading={formButtonStatus.loading}
                  disabled={formButtonStatus.disabled}
                  variant="outlined"
                  type="submit"
                >
                  {formButtonStatus.label}
                </LoadingButton>
              </Grid>
            </form>
          ) : (
            <>Loading...</>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Create;
