import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { CreativeCalenderApi } from '../../../../../../../api/Endpoints/CreativeCalender';
import { useState } from 'react';
import { DotSpinner } from '@uiball/loaders';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '70%',
//   width: 400,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  overflowY: 'auto', // Add this line to make it scrollable
};

export default function CopyModal({ id, setId, postID, setRefresh }) {

    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm()


    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setId()
        setOpen(false);
        reset()
        setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
    }

    const [formButtonStatus, setFormButtonStatus] = React.useState({
        label: "Save",
        loading: false,
        disabled: false,
    });

    const onSubmit = async (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });

        let dataToSubmit = {
            id: id,
            project_creative_calendar_post_id: postID,
            copy_text: data?.content,
            remarks: data?.remarks,
        }
        console.log(dataToSubmit);

        let action;

        if (id > 0) {
            action = CreativeCalenderApi.updateCopy(dataToSubmit)
        } else {
            action = CreativeCalenderApi.addCopy(dataToSubmit)
        }

        action.then((response) => {
            console.log("respponser here", response);
            if (response?.data?.status == 'success') {
                // fetchList()
                toast.success(response?.data?.message)
                handleClose()
                reset()
                setRefresh(Math.random() * 0.2)
                setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            } else {
                toast.error(response?.data?.error?.message)

            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const getDetails = async () => {
        setLoading(true)
        try {
            let copy = await CreativeCalenderApi.getCopyDetails({ id: id });
            console.log(copy);
            if (copy?.data?.data) {
                let data = copy?.data?.data;
                setValue('content', data.copy_text);
                setValue('remarks', data.remarks)
                setLoading(false)
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }
    useEffect(() => {
        if (id > 0) {
            setOpen(true)
            getDetails()
        } if (id == 0) {
            setOpen(true)
        }
    }, [id])


    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {
                        loading ?
                            (
                                <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'30%'} >
                                    <DotSpinner size={40} speed={0.9} color="blue" />
                                </Box>
                            ) :
                            <>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {id > 0 ? 'Edit Copy' : 'Add Copy'}
                                </Typography>
                                <Grid width={500}>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <Grid mt={2} sx={{}} item sm={12} >
                                            <TextField
                                                // placeholder='Add Note'
                                                {...register('content')}
                                                label="Content"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                minRows={3}
                                                // rows={3}
                                                sx={{ width: '100%', }}
                                                InputLabelProps={{ shrink: true }}
                                                required
                                            />
                                        </Grid>
                                        <Grid mt={2} sx={{}} item sm={12} >
                                            <TextField
                                                // placeholder='Add Note'
                                                {...register('remarks')}
                                                label="Remarks"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                sx={{ width: '100%', }}
                                                InputLabelProps={{ shrink: true }}
                                            // required
                                            />
                                        </Grid>
                                        <Grid display={'flex'} justifyContent={'end'} sx={{ mt: 1 }} item xs={12}>
                                            <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                        </Grid>
                                    </form>
                                </Grid>
                            </>
                    }

                </Box>
            </Modal>
        </div>
    );
}
