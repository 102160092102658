import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    Slide, 
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../form/TextInput";
import { Project } from "../../../api/Endpoints/Project";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import { Website_live_tracker } from '../../../api/Endpoints/WebsiteTracker';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({
    title: yup.string().required("Title is requried"),
})




const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = React.useState(false);
    const [attachments, setAttachments] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const handleClickOpen = () => { props.onNew(); };
    const insertFile = (e) => {
        setAttachments([e.target.files[0]])
        console.log(attachments);
        setRefresh(Math.random())
    }
    const handleRemoveFile = (file) => {
        setOpenDeleteModal(true)
        // console.log(file);
        // setAttachments((prevFiles) => prevFiles.filter((prevFile) => prevFile !== file));
        // setAttachments([])
        // setRefresh(Math.random())
    };


    const handleClose = () => {
        props.setEditId()
        setAttachments([])
        setOpen(false);
    };

    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })



    const fetchProjects = (e) => {
        return Project.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }



    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })



        let dataToSubmit = {
            id: props.editId,
            title: data.title,
            url: data.url

        };

        console.log(dataToSubmit);
        let action;

        if (props.editId > 0) {
            console.log("dataToSubmit");
            action = Website_live_tracker.update(dataToSubmit);
        } else {
            action = Website_live_tracker.add(dataToSubmit);
        }
        action.then(response => {
            console.log(response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }



    const fetchDetails = async () => {
        setLoading(true)
        const response = await Website_live_tracker.getDetails({ id: props.editId })
        setLoading(false)
        if (response.data.status === "success") {
            const data = response.data.data
            console.log(data);
            setValue('title', data.title)
            setValue('url', data.url)
        }

    };


    useEffect(() => {
        setRefresh(Math.random())
        console.log(props.editId);
    }, [watch('projects_id')])



    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            console.log("this is editi id");
            fetchDetails();
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            console.log("this is edit id", props.editId);
            setOpen(true);
        }

    }, [props.editId])



    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Add URL"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit" : "Add"}</DialogTitle>
                <DialogContent>
                    {!loading ?
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="title"
                                    label="Title"
                                    value={watch('title')} />
                            </Grid>

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="url"
                                    label="URL"
                                    value={watch('url')} />
                            </Grid>



                            <Grid sx={{ p: 1,mt:1 }}  mb={5} item xs={12}>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            </Grid>

                        </form>
                        :
                        <>Loading...</>
                    }
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        </div >
    );
};

export default Create