import React, { Fragment, useEffect, useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
} from "@mui/material"
import { Website_live_tracker } from "../../../api/Endpoints/WebsiteTracker"
import { DotSpinner } from "@uiball/loaders"

const FailedWebsites = (refresh) => {
  const [data, setData] = useState([])
  const [showRefreshModal, setShowRefreshModal] = useState(false)
  useEffect(() => {
    fetchFailedWebsites()
  }, [refresh])

  const fetchFailedWebsites = async () => {
    try {
      setShowRefreshModal(true)
      const response = await Website_live_tracker.failedWbsites()
      setData(response.data.data)
    } catch (error) {
      console.error(error)
    } finally {
      setShowRefreshModal(false)
    }
  }
  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>Response Time</TableCell>
              <TableCell>occurrence</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.title}</TableCell>
                <TableCell>
                  <a href={`${row.url}`} target="_blank">
                    {row.url}
                  </a>
                </TableCell>
                <TableCell>
                  {Math.round(row.response_time * 1000) / 1000}
                </TableCell>
                <TableCell>{row.occurence}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={showRefreshModal}>
        <DialogContent>
          <DotSpinner size={40} speed={0.9} color="blue" />
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default FailedWebsites
