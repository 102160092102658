import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    TextareaAutosize,
    Select,
    TextField,
    Slide, Typography
} from "@mui/material";
import { TimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/lab';
import * as yup from "yup";
import dayjs from 'dayjs';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../form/TextInput";
import DateInput from '../../form/DateInput';
import TimeInput from '../../form/DateTime';
import SelectX from "../../form/SelectX";
import { Project } from "../../../api/Endpoints/Project";
import DynamicChip from "../../../utils/DynamicChip";
import { FormHelpers } from "../../../helpers/FormHelpers";
import { FollowUp } from "../../../api/Endpoints/FollowUp";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { Task } from "../../../api/Endpoints/Task";
import moment from "moment";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import * as PropTypes from "prop-types";
import Editor from "../../form/Editor";
import { Employee } from "../../../api/Endpoints/Employee";
import StatusSelector from '../../form/StatusSelector'
import CurrentDateInput from '../../form/CurrenDateInput';
import SelectDropdown from '../../form/StatusSelector';
import { WorkRequest } from '../../../api/Endpoints/WorkRequest';
import ReactSelector from 'react-select';
import { faHouseFloodWaterCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Account } from '../../../api/Endpoints/Account';
import { WorkOrders } from '../../../api/Endpoints/WorkOrder';
import { PaymentProfile } from '../../../api/Endpoints/PaymentProfile';
import { VideoShootApi } from '../../../api/Endpoints/VideoShoot';
import { Departments } from '../../../api/Endpoints/Departments';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({


    title: yup.string().required(),
    amount: yup.string().required(),
    // description: yup.string(),
    // start_date: yup.string(),
    // end_date: yup.string(),
    // due_date: yup.string(),
    // Owner_Id: yup.object(),
    // assigned_by_id: yup.object(),
    // projects_id: yup.object().shape({
    //     id: yup.string().required("Please choose a project").typeError("Please choose a project")
    // }).required().typeError("Please choose a project"),
    // reviewer_id: yup.object().shape({
    //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    // }).required().typeError("Please choose a reviewer"),
})




const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => { setOpen(false); };


    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchProjects = (e) => {
        return Project.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const fetchPaymentProfile = (e) => {
        return PaymentProfile.get({ keyword: e }).then(response => {
            // console.log(response.data.data);
            if (typeof response.data.data !== "undefined") {
                return response.data.data;
            } else {
                return [];
            }
        })
    }


    const fetchClient = (e) => {
        return Account.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }
    const fetchDepartments = (e) => {
        let project = watch('projects_id');
        return Departments.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                console.log(response.data.data.data);
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const options = [
        { value: "", label: '' },

    ];



    const Status = [
        { value: 'Created', label: 'Created' },
        { value: 'Approved', label: 'Approved' },
        { value: 'Denied', label: 'Denied' },
        { value: 'Needs Discussion', label: 'Needs Discussion' },
        { value: 'Ready to Invoice', label: 'Ready to Invoice' },
        { value: 'Invoice Created', label: 'Invoice Created' },
    ];

    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let dataToSubmit = {
            id: props.editId,
            projects_id: data.projects_id?.id,
            title: data.title,
            client_id: data.client?.id,
            account_payment_profile_id: data.payment_profile?.id,
            department_id: data?.department?.id,
            status: data?.status,
            amount: data.amount,
            remarks: data.remarks,
        };

        console.log(dataToSubmit);

        let action;

        if (props.editId > 0) {
            console.log("dataToSubmit", dataToSubmit);
            action = WorkOrders.update(dataToSubmit);
        } else {
            action = WorkOrders.add(dataToSubmit);
        }
        action.then(response => {
            console.log(response);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            if (props?.source == 'videoShoot') {
                let datas = {
                    id: props.VSid,
                    work_order_id: response?.data?.data?.id
                }
                VideoShootApi.assignWorkOrder(datas).then((assignResponse) => {
                    console.log(assignResponse);
                    props.fetchData()
                })
            }
            toast.success(response.data.message)
            props.onUpdate();
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }



    const fetchDetails = async () => {
        setLoading(true);
        let workOrder = await WorkOrders.getDetails({ id: props.editId });
        console.log(workOrder);
        if (workOrder?.data?.data) {
            let data = workOrder.data.data;
            setValue('title', data?.title);
            setValue('projects_id', data?.project)
            setValue('client', data?.client)
            setValue('payment_profile', data?.payment_profile);
            setValue('remarks', data?.remarks)
            setValue('amount', data?.amount)
            setValue('department', data?.department)
            setValue('status', data?.status)
        }
        setLoading(false);
    };


    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('projects_id')])



    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetails();
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
            if (props?.currentProject) {
                setValue('projects_id', { id: props?.currentProject?.id, name: props?.currentProject?.name })
            }
        }

    }, [props.editId])



    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Create Work Order"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Work Order" : "Create Work Order"}</DialogTitle>
                <DialogContent>
                    {!loading ?
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="title"
                                    label="Title"
                                    value={watch('title')} />
                            </Grid>

                            <Grid sx={{ p: 1, }} item xs={12}>
                                <SelectX
                                    label={"Choose Project"}
                                    options={fetchProjects}
                                    control={control}
                                    error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                    error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                    name={'projects_id'}
                                    defaultValue={watch('projects_id')}
                                />
                            </Grid>

                            <Grid container sm={12} sx={{}} item md={12}>
                                <Grid p={1} xs={6} md={6}>
                                    <SelectX
                                        key={refresh * 0.2}
                                        label={"Client"}
                                        options={fetchClient}
                                        control={control}
                                        error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                        error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                        name={'client'}
                                        defaultValue={watch('client')}
                                    />
                                </Grid>
                                <Grid p={1} xs={6} md={6}>
                                    <SelectX
                                        required={true}
                                        key={refresh * 0.2}
                                        label={"Payment Profile"}
                                        options={fetchPaymentProfile}
                                        control={control}
                                        error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                        error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                        name={'payment_profile'}
                                        defaultValue={watch('payment_profile')}
                                    />
                                </Grid>
                            </Grid>


                            <Grid container sm={12} sx={{}} item md={12}>
                                <Grid p={1} xs={6} md={6}>
                                    <InputLabel sx={{
                                        color: "black",
                                        pb: 0.3,
                                    }}>Status</InputLabel>
                                    <ReactSelector
                                        styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                        options={Status}
                                        value={
                                            Status.filter(option =>
                                                option.label === watch('status'))
                                        }
                                        name='status'
                                        isClearable={true}
                                        defaultValue={(watch('status'))}
                                        onChange={(selectedOption) => setValue('status', selectedOption?.label || '')}
                                    />
                                </Grid>

                                <Grid p={1} xs={6} md={6}>
                                    <SelectX
                                        key={refresh * 0.2}
                                        label={"Deparment"}
                                        options={fetchDepartments}
                                        control={control}
                                        error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                        error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                        name={'department'}
                                        defaultValue={watch('department')}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container sm={12} sx={{}} item md={12}>
                                <Grid p={1} xs={6} md={6}>
                                    <TextInput required control={control} name="amount"
                                        label="Amount"
                                        value={watch('amount')} />
                                </Grid>
                            </Grid>

                            <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1
                                }}>Remarks </InputLabel>
                                <TextField
                                    {...register('remarks')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    sx={{ width: '100%', }}
                                />
                            </Grid>

                            <Grid sx={{ p: 1, mt: 1 }} mb={5} item xs={12}>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            </Grid>

                        </form>
                        :
                        <>Loading...</>
                    }

                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        </div >
    );
};

export default Create
