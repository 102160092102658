import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Box, Button, Card, DialogActions, Divider, Grid, InputLabel, Modal, Slide, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-hot-toast';
import { Task } from '../../../../api/Endpoints/Task';
import SelectX from '../../../form/SelectX';
import { Users } from '../../../../api/Endpoints/Users';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline } from '@material-ui/icons';
import Delete from '../../common/Popup/Delete';
import { SubscribeApi } from '../../../../api/Endpoints/Subscribe';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
    user_id: yup.object().required(),
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '3px',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const WRSubscribeTab = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editValues, setEditValues] = useState({})
    const [refresh, setRefresh] = React.useState(false);
    const [subscribers, setSubscribers] = useState([])
    const [pageNumber, setPageNumber] = useState(0);
    const [deleteID, setDeleteID] = useState()
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);



    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })



    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        props.setEditId()
        setOpen(false);
    };
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleDeleteAlert = (id) => {
        setDeleteID(id)
    }

    const col = [
        {
            field: 'test',
            headerName: 'test'
        }
    ]
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.row?.user?.name}</Button >
                    </>
                );
            },
        },

        {
            field: 'email',
            headerName: 'Email',
            width: 250,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.row?.user?.email}</Button >
                    </>
                );
            },
        },

        {
            field: 'action', headerName: 'delete', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <>
                    <Button style={{ color: 'black' }} size={'small'} onClick={() => handleDeleteAlert(id)}>
                        <DeleteOutline />
                    </Button>
                </>;
            }
        },
    ]

    const onSubmit = (data) => {
        console.log(data);

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let dataToSubmit = {
            subscribable_type: "work_request",
            subscribable_id: props.id,
            users_id: data.user_id?.id
        }

        console.log(dataToSubmit);
        let action;

        // if (props.editId > 0) {
        //     console.log(dataToSubmit);
        //     action = Task.updateNote(dataToSubmit)
        // } else {
        console.log(dataToSubmit);
        action = SubscribeApi.subscribe(dataToSubmit)
        // }
        action.then((response) => {
            console.log(response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true })
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false })
            toast.success(response.data.message)
            props.setRefresh(Math.random() * .02)
            // props.onUpdate()
            setValue('user_id', '')
            setOpen(false)
            handleModalClose()
            props.setRefresh(Math.random()*.02)
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            setOpen(false)
            toast.error("server error")
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    const fetchDetails = () => {
        setLoading(true)
        SubscribeApi.getSubscribers({ subscribable_type: 'work_request', subscribable_id: props.id, page: parseInt(pageNumber) + 1 }).then((response) => {
            let data = response.data.data
            console.log(data.data);
            let subscription = data.data.some(item => item.users_id == props.userID)
            if (subscription) {
                props.setSubscription(true)
            } else {
                props.setSubscription(false)
            }
            setSubscribers(data.data)
            // setValue('note', data.note)
            setLoading(false)
        })
    }

    const fetchUsers = (e) => {
        // console.log(e);
        return Users.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }
    useEffect(() => {
        fetchDetails()
        console.log('refreshing');
    }, [props.refresh])

    useEffect(() => {
        fetchDetails()
        setOpen(true);
    }, [])

    const dummyData = [
        { id: 1, name: 'John Doe', email: 'john@example.com', age: 30 },
        { id: 2, name: 'Jane Smith', email: 'jane@example.com', age: 28 },
        { id: 3, name: 'Bob Johnson', email: 'bob@example.com', age: 35 },
        // Add more rows as needed
    ];
    const cols = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'age', headerName: 'Age', width: 100 },
    ];

    return (
        <div>

            {deleteID && <Delete
                ID={deleteID}
                setID={setDeleteID}
                setDeletePopup={setDeleteID}
                Callfunc={() => fetchDetails()}
                url="/subscribers/delete?id="
                title="Subscriber" />}
            {/* 
            <form onSubmit={handleSubmit(onSubmit)}>

                <ErrorMessage
                    errors={errors}
                    name="multipleErrorInput"
                    render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                            <p key={type}>{message}</p>
                        ))
                    }
                />


                <Grid sx={{ pt: 2 }} item xs={12}>
                    <SelectX
                        key={refresh * 0.2}
                        label={"Select User"}
                        options={fetchUsers}
                        control={control}
                        error={errors?.assigned_to_id?.id ? errors?.assigned_to_id?.id?.message : false}
                        error2={errors?.assigned_to_id?.message ? errors?.assigned_to_id?.message : false}
                        name={'user_id'}
                        defaultValue={watch('user_id')}
                    />
                </Grid>

                <Grid sx={{ pt: 2, pb: 2, mt: 2 }} item xs={12}>
                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                </Grid>
            </form> */}


            {/* <Divider /> */}

            {/* <Accordion sx={{ mb: 3, mt: 2 }}>
                <AccordionSummary
                    sx={{
                        backgroundColor: 'rgb(243,242,255)'
                    }}
                    expandIcon={< ExpandMoreIcon />}
                    aria-controls="internal-content"
                    id="internal-header"
                >
                    <Typography sx={{ fontWeight: 500 }}>Subscribers</Typography>
                </AccordionSummary>
            <div style={{ height: 300, width: '100%', }}> */}
            <Card sx={{ display: 'flex', justifyContent: 'end', height: 50, alignItems: 'center' }}>
                <Button variant='contained'
                    onClick={handleModalOpen}
                    sx={{ mr: 10, height: 30 }}>
                    Add Subscriber
                </Button>
            </Card>
            <div style={{  }}>
                <Card sx={{ m: 2, height: 350, width: '100%', }} variant="outlined">

                    {subscribers &&
                        <DataGrid
                            paginationMode="server"
                            rows={subscribers}
                            columns={columns}
                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            rowCount={subscribers.total}
                            onPageChange={handlePageChange}
                            autoHeight={false}
                            density={"comfortable"}
                        />
                    }
                </Card>

            </div>

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Subscriber
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <ErrorMessage
                            errors={errors}
                            name="multipleErrorInput"
                            render={({ messages }) =>
                                messages &&
                                Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                ))
                            }
                        />


                        <Grid sx={{ pt: 2 }} item xs={12}>
                            <SelectX
                                key={refresh * 0.2}
                                label={"Select User"}
                                options={fetchUsers}
                                control={control}
                                error={errors?.assigned_to_id?.id ? errors?.assigned_to_id?.id?.message : false}
                                error2={errors?.assigned_to_id?.message ? errors?.assigned_to_id?.message : false}
                                name={'user_id'}
                                defaultValue={watch('user_id')}
                            />
                        </Grid>

                        <Grid sx={{ pt: 2, pb: 2, mt: 2, display: 'flex', justifyContent: 'end' }} item xs={12}>
                            <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                        </Grid>
                    </form>
                </Box>
            </Modal>

        </div>
    )
}

export default WRSubscribeTab;

