import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import { Website_live_tracker } from "../../../api/Endpoints/WebsiteTracker";
import { DotSpinner } from "@uiball/loaders";
import { LoadingButton } from "@mui/lab";
import Create from "./create";

const WebsiteTracker = ({ refresh, setTotal, setRefresh, total }) => {
  const [data, setData] = useState([]);
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const [limit, setLimit] = useState(50);
  const [loadingstatus, setLoadingstatus] = useState(false);
  const [editId, setEditId] = useState();
  useEffect(() => {
    fetchWebsiteTrackingResult();
  }, [refresh, limit]);

  const fetchWebsiteTrackingResult = async () => {
    try {
      setShowRefreshModal(true);
      const response = await Website_live_tracker.get({ limit: limit });
      setTotal(response.data.data.total);
      setData(response.data.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setShowRefreshModal(false);
      if (loadingstatus) setLoadingstatus(false);
    }
  };

  function formatNumber(number) {
    if (number >= 1000) {
      return Math.floor(number / 1000) + "k";
    }
    return number;
  }

  function formatErrorToreadable(error) {
    if (error) {
      const regex = /\(([^)]+)\)/;
      const match = error.match(regex);

      // Extract the first error message
      const firstError = match && match.length > 1 ? match[1] : null;

      // Extract the first 2 characters of the error message
      return firstError ? firstError.substring(0, 100) : null;
    }
  }
  const handleCreate = () => {
    setEditId(0);
  };

  const updateHandler = () => {
    setRefresh(Math.random());
  };

  const LoadMoreHandler = () => {
    setLoadingstatus(true);
    setLimit(limit + 10);
  };

  return (
    <Fragment>
      <Box width={"100%"} display={"flex"} justifyContent={"end"}>
        <Create
          onNew={handleCreate}
          editId={editId}
          setEditId={setEditId}
          onUpdate={updateHandler}
        />
      </Box>

      <div style={{ height: "100vh" }}>
        <Box mt={4}>
          <Grid container spacing={2}>
            {data
              .filter((item) => item.http_status_code != 500)
              .map((item) => {
                const { statuses } = item;
                console.log("all status", item);
                return (
                  <Grid
                    className="card-block-web"
                    item
                    xs={6}
                    md={4}
                    xl={3}
                    key={item.id}
                  >
                    <Card sx={{ height: 200 }}>
                      <CardContent>
                        <Box>
                          <Typography
                            className="block-title"
                            fontWeight={600}
                            textTransform="uppercase"
                          >
                            {item.title}
                          </Typography>
                          <a href={`${item.url}`} target="_blank">
                            {item.url}
                          </a>
                        </Box>
                        <Box mt={2}>
                          <Divider />
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt={2}
                        >
                          <Box className="occurrence-block">
                            <Typography className="occurrence-block-title">
                              occurrence
                            </Typography>
                            {statuses.map((item) => (
                              <Tooltip
                                className="occurrence-block-item"
                                arrow
                                title={formatErrorToreadable(
                                  item?.status_response
                                )}
                              >
                                <Typography
                                  key={item.id}
                                  fontWeight={600}
                                  sx={{
                                    backgroundColor:
                                      item.status_code === 200
                                        ? "green"
                                        : "red",
                                    cursor: "pointer",
                                  }}
                                >
                                  {formatNumber(item.occurence)}
                                </Typography>
                              </Tooltip>
                            ))}
                          </Box>
                          <Box>
                            <Typography className="block-item-title">
                              Response Time
                            </Typography>
                            <Typography fontWeight={600}>
                              {Math.round(item.response_time * 1000) / 1000}
                              {"/s"}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
        {data.length > 0 && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "30px 0",
            }}
          >
            {total > limit && (
              <LoadingButton
                loading={loadingstatus}
                variant="outlined"
                onClick={LoadMoreHandler}
              >
                Load More
              </LoadingButton>
            )}
          </Box>
        )}
      </div>
      <Dialog open={showRefreshModal && !loadingstatus}>
        <DialogContent>
          <DotSpinner size={40} speed={0.9} color="blue" />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default WebsiteTracker;
