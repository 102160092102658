import React, { useState, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PageHeader from "../common/page-header";
import { Documents } from '../../../api/Endpoints/Documents';
import EditIcon from '@mui/icons-material/Edit';
import {
    Backdrop,
    Button,
    Card,
    CircularProgress,
    Divider,
    List,
    Box,
    Modal,
    IconButton,
    ListItemIcon,
    ListItem,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-hot-toast';
import Delete from '../common/Popup/Delete';
import PdfPopup from '../common/Popup/DocumentView';
import { LoadingButton } from '@mui/lab';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};



function FileUploader() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [textValue, setTextValue] = useState('');
    const [open, setOpen] = useState(false);
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [ID, setID] = useState(0)
    const [PopLoading, setPopupLoading] = useState(false)
    const [textError, setTextError] = useState('');
    const [fileError, setFileError] = useState('');
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const handleOpen = () => {
        setTextValue('')
        setSelectedFiles('')
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setID(0)
        setTextError('');
        setFileError('');
    };
    const handleDelete = (id) => {
        setID(id)
        setDeletePopup(true)
    }
    const handleFileChange = (event) => {
        setSelectedFiles([event.target.files[0]]);
        setFileError('')
    };

    const handleTextChange = (event) => {
        setTextValue(event.target.value);
        setTextError('')
    };

    const handleRemoveFile = (file) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile !== file));

    };


    const User = localStorage.getItem('user')

    useEffect(() => {
        fetchDocuments()
    }, []);

    function extractFilenameFromURL(url) {
        const parts = url.split('_');
        return parts[parts.length - 1];
    }


    const fetchDocuments = () => {
        setLoading(true);
        Documents.get()
            .then(response => {
                setList(response.data.data)
                // setUser({ name: response.data.data.name, email: response.data.data.email });
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });

    }

    if (selectedFiles.length == 0) {
        console.log(true);
    }
    const handleSubmit = async (event) => {

        event.preventDefault();


        if (!textValue.trim() && selectedFiles.length == 0) {
            setTextError('Document title is required');
            setFileError('File is required');
            return
        } else if (selectedFiles.length == 0) {
            setFileError('File is required');
            return
        }
        else if (!textValue.trim()) {
            setTextError('Document title is required');
            return
        }
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        const formData = new FormData()

        if (ID > 0) {
            formData.set('id', ID)
        }
        formData.append('title', textValue)
        formData.append('file', selectedFiles[0]);

        let action

        if (ID > 0) {
            action = await Documents.update(formData)

        } else {
            action = await Documents.add(formData)

        }

        if (action.data.message === "Document has been successfully updated.") {
            toast.success('Document has been successfully updated')
        } else if (action.data.message === "New document has been successfully saved.") {
            toast.success('Document has been successfully updated')
        } else {
            toast.error('Something went wrong!')
        }
        setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        setSelectedFiles('')
        fetchDocuments()
        handleClose();
    };


    const handleEditButtonClick = async (id) => {
        setOpen(true)
        setPopupLoading(true)
        setID(id)
        const response = await Documents.getDetails({ id: id })
        const data = response.data.data
        setTextValue(data.title)
        setSelectedFiles([data.document])
        setPopupLoading(false)
    }

    const columns = [
        { field: 'title', headerName: 'Title', width: 200 },
        {
            field: 'document',
            headerName: 'Document',
            width: 500,
            valueGetter: (params) => {
                const documentUrl = params.row.document;
                return extractFilenameFromURL(documentUrl);
            },
        },
        {
            field: 'Edit',
            headerName: 'Actions',
            width: 230,
            renderCell: (params) => (
                <>

                    <PdfPopup documentURL={params.row.document} />
                    <Button size="small" onClick={() => handleEditButtonClick(params.row.id)}>
                        <EditIcon style={{ color: 'blue', height: 19 }} />
                    </Button>
                    <Button size='small' onClick={() => handleDelete(params.id)}>
                        <DeleteIcon style={{ color: 'red', height: 19 }} />
                    </Button>

                </>
            ),
        },
    ];

    return (

        <div>
            {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                    <CircularProgress size={33} />
                    <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                        Loading...
                    </Typography>
                </Box>
            ) : (
                (
                    <>
                        {showDeletePopup && <Delete
                            ID={ID}
                            setID={setID}
                            setDeletePopup={setDeletePopup}
                            Callfunc={() => fetchDocuments()}
                            url="/employees/documents/delete?id="
                            title="Document" />}

                        <PageHeader title={`DOCUMENTS OF ${User.toLocaleUpperCase()}`} />
                        <div className='flex' style={{ width: '100', display: 'flex', justifyContent: "end", marginRight: 10 }}>


                            < Button variant="contained" sx={{ width: "180px", }}
                                onClick={handleOpen}  >Upload Document</Button >
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >


                                <Box sx={style}>
                                    {PopLoading ?
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                                            <CircularProgress size={33} />
                                            <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                                                Loading...
                                            </Typography>
                                        </Box>
                                        : (
                                            <>
                                                <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ textAlign: "center", pb: 5 }}>
                                                    Upload Document
                                                </Typography>

                                                <form onSubmit={handleSubmit}>
                                                    <input
                                                        type="file"
                                                        accept=".xlsx, .jpg, .jpeg, .png, .pdf"
                                                        id="file-upload"
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange}
                                                    />

                                                    <TextField
                                                        id="text-input"
                                                        placeholder="Document Title"
                                                        variant="outlined"
                                                        value={textValue}
                                                        onChange={handleTextChange}
                                                        fullWidth
                                                        autoFocus
                                                        margin="normal"
                                                        sx={{ mt: 2 }}
                                                    />

                                                    {textError && <p style={{ color: 'red' }}>{textError}</p>}

                                                    <label htmlFor="file-upload">
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<CloudUploadIcon />}
                                                            component="span"
                                                            sx={{
                                                                mt: 2,

                                                            }}
                                                        >
                                                            <Typography ml={1}>{ID > 0 ? "Update File" : "Choose File"}</Typography>
                                                        </Button>
                                                        {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                                                    </label>
                                                    <List sx={{ mt: 2, maxHeight: 200, overflowY: 'auto' }}>
                                                        {selectedFiles &&
                                                            selectedFiles.map((file) => (
                                                                console.log("this is file ", file),
                                                                <ListItem ListItem key={file.name} >

                                                                    <ListItemIcon>
                                                                        <CloudUploadIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={file.name || file} />
                                                                    {file.name == null ? (
                                                                        null
                                                                    ) : (
                                                                        <IconButton IconButton
                                                                            startIcon={<CloudUploadIcon />}
                                                                            edge="end"
                                                                            onClick={() => handleRemoveFile(file)}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    )
                                                                    }

                                                                </ListItem>
                                                            ))}
                                                    </List>
                                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                                        <Button
                                                            variant="outlined"
                                                            color='error'
                                                            sx={{
                                                                mt: 2, mr: 2,

                                                            }}
                                                            onClick={handleClose}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <LoadingButton sx={{ mt: 2 }} loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                                            variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>

                                                    </div>
                                                </form>
                                            </>
                                        )}
                                </Box>

                            </Modal>

                        </div >
                        {list?.data?.length !== 0 ? (
                            <Card sx={{ mt: 5 }} variant="outlined">
                                {typeof list?.data === "object" && (
                                    <DataGrid
                                        paginationMode="server"
                                        rows={list?.data}
                                        columns={columns}
                                        pageSize={10}
                                        rowHeight={20}
                                        rowsPerPageOptions={[10]}
                                        autoHeight
                                        density={'comfortable'}
                                        getRowHeight={() => 35}
                                    />
                                )}
                            </Card>
                        ) : (
                            <Box mt={10} width={'100%'} textAlign={'center'}>
                                <Typography>Documents not found</Typography>
                            </Box>

                        )}

                    </>
                )

            )
            }
        </div >

    );
}

export default FileUploader;
