import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BasicTabs from './TabMenus';
import { CircularProgress, Grid, TextField } from '@mui/material';
import { CheckCircle, Close, Warning } from '@mui/icons-material';
import moment from 'moment';
import { useState } from 'react';
import { TimePicker } from '@mui/x-date-pickers';
import SelectX from '../../form/SelectX';
import { Employee } from '../../../api/Endpoints/Employee';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Users } from '../../../api/Endpoints/Users';
import toast from 'react-hot-toast';
import { UserIdContext } from '../../../contexts/UserID/UserID';
import { useEffect } from 'react';


const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
        // height:'100%'
    },
    // title: {
    //     fontWeight: 'bold',
    // },
    // divider: {
    //     margin: theme.spacing(2, 0),
    // },
    // select: {
    //     minWidth: 200,
    // },
}));


const validationSchema = yup.object().shape({
    manager_id: yup.object().nullable(),
    // hours: yup.number(),
    // hours: yup.number(),
    // Add validation rules for other fields if needed
});

export default function SignOutButton(props) {
    const { UserID } = UserIdContext();

    // const { handleSignOut } = props

    const classes = useStyles();
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) });

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [totalHours, setTotalHours] = useState(0)
    const [totalMinutes, setTotalMinutes] = useState(0)
    const [signOut, setSignOut] = useState(true)
    const [selectedTime, setSelectedTime] = useState(null);
    const [loading, setloading] = useState(false)
    const [time, setTime] = useState(moment().format('D MMM YYYY HH:mm:ss A'))
    const [signInData, setSignInData] = useState(null);
    const [disabled, setDisabled] = useState(false)



    const updateCurrentTime = () => {
        setTime(moment().format('D MMM YYYY HH:mm:ss A'));
    };

    useEffect(() => {
        // Update the time initially
        updateCurrentTime();

        // Set up an interval to update the time every second
        const intervalId = setInterval(updateCurrentTime, 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: signOut ? '90%' : '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: 1
    };

    const handleClose = (event, reason) => {
        if (reason !== "backdropClick") {
            setOpen(false);
            props.setOpen(false)
            sessionStorage.clear()
        }
    }

    const checkAttendance = async () => {
        let response = await Employee.checkAttendence({ date: moment().format("DD-MM-YYYY") });
        // console.log(response);
        if (response.data.status === "success") {
            setSignInData(response.data.data);

        } else {
            setSignInData();
        }
        // console.log("checkAttendance", response);
    }

    const getHours = (data) => {
        // console.log(data);
        let totalDuration = moment.duration(); // Initialize a Moment.js duration object

        data.forEach((item) => {
            const duration = moment.duration({
                hours: item.duration_hours,
                minutes: item.duration_minutes,
            });

            totalDuration.add(duration); // Add the duration of each item to the total
        });

        setTotalHours(totalDuration.hours())
        setTotalMinutes(totalDuration.minutes())

    }

    const handleTimeChange = (newTime) => {
        setSelectedTime(newTime);
    };

    const fetchManager = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const handleSignOut = async () => {
        setDisabled(true)
        setloading(true);
        try {
            // let ip = await fetch('https://geolocation-db.com/json/')
            //     .then(response => response.json())
            //     .catch(error => {
            //         console.log(error);
            //         throw error; // Rethrow the error to handle it later
            //     });

            // console.log("IP", ip);

            let data = {
                // logged_out_ip: ip.IPv4,
                logged_out_time: moment().format('yyyy-MM-DD HH:mm:ss'),
                users_id: UserID
            };

            let response = await Employee.signOut(data);

            if (response.data.status === "error") {
                toast.success(response.data.message);
            } else if (response.data.status === "success") {
                toast.success("Successfully signed out");
                localStorage.removeItem('signIn')
            }

            console.log("handleSignIn", response);
        } catch (error) {
            console.error("An error occurred:", error);
        } finally {
            setloading(false);
            window.location.reload();
        }
    };


    useEffect(() => {
        if (props.open) {
            checkAttendance()
        }
    }, [props.open])



    return (
        <div>
            <Button variant="contained" onClick={handleOpen}>Sign Out</Button>
            <Modal
                open={props.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disable
            >
                <Box sx={style}>
                    <Box sx={{ border: 'solid', padding: 2, borderWidth: 1, height: '99%', borderRadius: '3px' }}>
                        <Grid display={'flex'} justifyContent={'space-between'}>
                            <Typography id="modal-modal-title" variant="h4" component="h2">
                                Daily Sign Out
                            </Typography>
                            <Button onClick={handleClose}><Close sx={{ cursor: 'pointer' }} /></Button>
                        </Grid>

                        {
                            signOut ?
                                <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: '18px' }}>
                                    Enter your today's Activities along with the Duration. Total should be 8+ Hours.
                                </Typography>
                                :
                                <>
                                    <Typography id="modal-modal-description" sx={{ mt: 1, fontSize: '18px', width: '80%' }}>
                                        <b style={{ color: 'red', lineHeight: '.1' }}>
                                            You missed to Sign Out on 19 Sep 2023. You need to Sign Out before you can Sign In again,Select the time you actually finished the work and select your manager for approval
                                        </b>
                                    </Typography>

                                    <Grid display={'flex'}>
                                        <Grid>
                                            {/* <h4>Select Time</h4> */}
                                            <TimePicker
                                                label="Sign Out Time"
                                                value={selectedTime}
                                                onChange={handleTimeChange}
                                                renderInput={(params) => <TextField style={{ width: 250, marginTop: 10 }} {...params} />}
                                            />
                                        </Grid>
                                        <Grid display={'flex'} alignItems={'center'} item ml={'40%'} width={200}>
                                            <SelectX
                                                placeholder={'Manager'}
                                                options={props?.open && fetchManager}
                                                control={control}
                                                // error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                                                // error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                                                name={'manager_id'}
                                                defaultValue={watch('manager_id')}
                                            />
                                        </Grid>
                                    </Grid>
                                    <hr style={{ marginTop: '20px', marginBottom: '-15px' }} />
                                </>

                        }
                        <Box >
                            <Box >
                                <BasicTabs open={props?.open} getHours={getHours} />
                                <Grid mt={'auto'} ml={3} style={{ position: 'absolute', bottom: 25 }}>


                                    {
                                        totalHours == 0 && totalMinutes == 0 ? ''
                                            // <a>Your Current Work Total For The Day Is Zero</a> 
                                            :
                                            totalHours >= 8 ?
                                                <a style={{ display: 'flex', alignItems: 'center' }}>
                                                    <CheckCircle fontSize='small' style={{ color: 'green', marginRight: 5 }} />  <a style={{ color: 'green' }}>  Your Current Work Total For The Day Is {totalHours + " Hours"} {totalMinutes > 0 ? ',' : ''} {totalMinutes > 0 ? `${totalMinutes} Minutes` : ''} </a>
                                                </a> :
                                                <a style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Warning fontSize='small' style={{ color: 'red', marginRight: 5 }} /> <a style={{ color: 'red' }}>  Your Current Work Total For The Day Is Only {totalHours > 0 ? `${totalHours} Hours` : ''}  {totalMinutes > 0 ? `${totalMinutes} Minutes` : ''} </a>
                                                </a>
                                    }


                                </Grid>
                                <Grid ml={2} style={{ position: 'absolute', bottom: 25, left: 700 }} mt={1} display={'flex'}>
                                    <Grid display={'flex'} alignItems={'center'} width={400}>
                                        {
                                            signInData &&
                                            <>
                                                <a style={{ marginRight: 10, fontSize: '14px' }}>Sign In Time:</a>
                                                <Grid width={250}>
                                                    <a style={{ fontSize: '14px', fontStyle: 'italic' }}>
                                                        {moment(signInData?.created_at).format("D MMM YYYY HH:mm:ss A")}
                                                    </a>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>

                                    {/* <Grid display={'flex'} alignItems={'center'} width={380}>
                                        <a style={{ marginRight: 10, fontSize: '14px' }}>Sign Out Time:</a>
                                        <Grid width={250}>
                                            <a style={{ fontSize: '14px', fontStyle: 'italic' }}>
                                                {time}
                                            </a>
                                        </Grid>
                                    </Grid> */}

                                </Grid>
                                <div style={{ width: '95%', display: 'flex', justifyContent: 'end', position: 'absolute', bottom: 30, right: 50 }}>
                                    <Button disabled={loading || disabled} onClick={handleSignOut} variant="contained" style={{ width: 'fit-content' }}>
                                        {loading ? <CircularProgress size={20} color="inherit" /> : "Sign Out"}
                                        {/* Sign Out */}
                                    </Button>

                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}