import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  TextField,
} from "@mui/material";
import Details from "./Tabs/Details";
import Members from "./Tabs/Members";
//import Projects from "./Tabs/Projects";
import Projects from "../../pages/projects";
import EmployeeTask from "./Tabs/task/Index";
import EmployeeSelfies from "./Tabs/selfi";
import EmployeeAttendance from "./Tabs/Attendance";
import Activities from "../../pages/activities";
import WorkPlan from "../../pages/WorkPlan";
import { Employee } from "../../../api/Endpoints/Employee";
import PageHeader from "../common/page-header";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  setTabs,
  EmployeeID,
  employee_id,
  EmployeeName,
}) {
  console.log("employee id ", EmployeeID);
  const [value, setValue] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [Data, setData] = useState();

  const fetchDetails = () => {
    setLoading(true);

    Employee.getEmployeeDetails({ id: employee_id }).then((response) => {
      // console.log(response);
      setData(response.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchDetails();
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("this is employee data from menu", Data);

  return (
    <>
      <PageHeader title={Data?.user?.name} />
      <Box sx={{ width: "100%", mt: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ ml: 2 }}
          >
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Projects" {...a11yProps(1)} />
            <Tab label="Tasks" {...a11yProps(2)} />
            <Tab label="Attendance" {...a11yProps(3)} />
            <Tab label="Selfie" {...a11yProps(4)} />
            <Tab label="Activities" {...a11yProps(5)} />
            <Tab label="Work Plans" {...a11yProps(6)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Details EmployeeID={employee_id} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Projects employeeID={EmployeeID} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EmployeeTask employee_id={employee_id} EmployeeID={EmployeeID} />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <EmployeeAttendance EmployeeID={EmployeeID} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <EmployeeSelfies employeeID={employee_id} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Activities employeeID={EmployeeID} employeeName={EmployeeName} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <WorkPlan employeeID={EmployeeID} />
        </TabPanel>
      </Box>
      <Divider />

      <div
        style={{
          width: "95%",
          display: "flex",
          justifyContent: "end",
          marginTop: 5,
        }}
      >
        <Button
          variant="contained"
          onClick={() => setTabs(false)}
          style={{ width: "fit-content", mr: 7 }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
