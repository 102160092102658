import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Employee } from "../../../api/Endpoints/Employee";
import { Link } from "react-router-dom";
import BasicTabs from "./TabMenus";
import Create from "./create";
import AsyncSelect from "react-select/async";
import { useForm } from "react-hook-form";
import { Departments } from "../../../api/Endpoints/Departments";

const Index = (props) => {
  const {
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => (
        <>
          <Button
            size="small"
            onClick={() => viewTabs(params.row)}
            style={{ color: "black" }}
          >
            {params.row.name}
          </Button>
        </>
      ),
    },

    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 150,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "last_login",
      headerName: "Last Login",
      width: 130,
      renderCell: (params) => {
        return params.value ? moment(params.value).format("DD-MM-YYYY") : "";
      },
    },
  ];

  const [list, setList] = useState([]);
  const [listType, setListType] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [refresh, setRefresh] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
  const [EmployeeID, setEmployeeID] = useState(0);
  const [EmployeeName, setEmployeeName] = useState("");
  const [loading, setLoading] = useState(false);
  const [Tabs, setTabs] = useState(false);
  const [editId, setEditId] = useState();
  const [employee_id, setEmployee_id] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const handleListingSearchChange = (keyword) => {
    setSearchKeyword(keyword);
    setPageNumber(0);
  };
  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handleLeadClick = (leadId) => {
    props.onPreQualifierIdChange(leadId);
    setSelectedPreQualifierId(leadId);
  };

  const fetchTable = () => {
    setLoading(true);
    Employee.get({ keyword: searchKeyword, page: Number(pageNumber) + 1 }).then(
      (response) => {
        setList(response.data.data);
        setLoading(false);
      }
    );
  };

  const fetchDepartments = (e) => {
    return Departments.get({ keyword: e }).then((response) => {
      // console.log(response);
      if (typeof response.data.data.data !== "undefined") {
        return response.data.data.data;
      } else {
        return [];
      }
    });
  };

  const selectDepartment = (data) => {
    setValue("department", data || "");
    setSelectedDepartment(data);
  };

  const handleCreateNew = () => {};
  const handleSearchEntry = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleCreate = () => {
    setEditId(0);
    setRefresh(Math.random);
  };
  const handleUpdate = () => {
    setPageNumber();
    setPageNumber(0);
  };

  useEffect(async () => {
    fetchTable();
  }, [searchKeyword, listType, pageNumber, selectedDepartment]);

  const viewTabs = (data) => {
    console.log(data);
    setEmployeeID(data.id);
    setEmployeeName(data.name);
    setEmployee_id(data.employee_id);
    setTabs(true);
  };

  return (
    <>
      {Tabs ? (
        <BasicTabs
          employee_id={employee_id}
          setTabs={setTabs}
          EmployeeID={EmployeeID}
          EmployeeName={EmployeeName}
        />
      ) : (
        <>
          <PageHeader title={"Employees"} onCreateNew={handleCreateNew} />
          <Grid
            sx={{
              m: 2,
              p: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid>
              <Grid mr={2} sx={{ width: 250 }}>
                <AsyncSelect
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  isClearable={true}
                  defaultOptions
                  name="department"
                  loadOptions={fetchDepartments}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.id}
                  placeholder={"Department"}
                  value={watch("department")}
                  onInputChange={fetchDepartments}
                  onChange={selectDepartment}
                />
              </Grid>
            </Grid>

            <Grid display={"flex"}>
              <TextField
                size="small"
                sx={{ width: 200, marginRight: 3 }}
                id="outlined-name"
                label="Search Employees"
                onChange={handleSearchEntry}
              />
              {/* <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} /> */}
            </Grid>
          </Grid>
          <Card sx={{ m: 2 }} variant="outlined">
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {typeof list.data === "object" && (
              <DataGrid
                paginationMode="server"
                rows={list?.data}
                columns={columns}
                page={pageNumber}
                pageSize={10}
                rowHeight={20}
                rowsPerPageOptions={[10]}
                rowCount={list.total}
                onPageChange={handlePageChange}
                autoHeight
                density={"comfortable"}
              />
            )}
          </Card>
        </>
      )}
    </>
  );
};

export default Index;
