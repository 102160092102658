import React, { useEffect, useState } from 'react';
import PageHeader from "../common/page-header";
import Create from './create';
import { useForm } from 'react-hook-form';
import { CreativeCalenderApi } from '../../../api/Endpoints/CreativeCalender';
import { Edit } from '@mui/icons-material';
import CalenderList from './components/calender';
import ItemsList from './components/items/items';
import ItemDetailsList from './components/post details/item-details';
import { Waveform } from '@uiball/loaders';
import { Backdrop, CircularProgress, Grid, TablePagination } from '@mui/material';
import AsyncSelect from "react-select/async";
import SelectedCalendar from './components/selectCalendar';
import Notes from './components/notes/notes';
import BasicCard from './CardComponent';
import DetialIndex from './CalendarDetailIndex';


const Index = () => {


    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm();
    const [editId, setEditId] = useState();
    const [refresh, setRefresh] = useState(0);
    const [detailViewId, setDetailViewId] = useState(false)

    const DetailViewOpen = (id) => {
        setDetailViewId(id)
    }
    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }

    const columns = [
        {

            field: 'name', headerName: 'title', width: 350,
            renderCell: (params) => <p style={{ cursor: 'pointer', color: 'blue' }} onClick={() => DetailViewOpen(params.row.id)}>{params.value}</p>

        }, {
            field: 'project', headerName: 'Project name', width: 200,
            renderCell: (params) => <p style={{ cursor: 'pointer' }} >{params.value?.name}</p>
        },
        {
            field: 'calender_for', headerName: 'Calender For', width: 180,
            renderCell: (params) => <p>{params.row?.calendar_month} {params?.row?.calendar_year}</p>
        },
        {
            field: 'report_to', headerName: 'Manager', width: 150, renderCell: (params) => {
                // console.log("This is data", params);
                // if (params) {

                return <p>{params?.value?.name}</p>
                // } else {
                //     return "Not started";
                // }
            }
        },
        {
            field: 'status', headerName: 'Status', width: 120,
            renderCell: (params) => <p>{params?.value}</p>
        },
        {
            field: 'action', headerName: 'Edit', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><a
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEdit(id)}><Edit color='primary' fontSize='small' /></a>
                </>;
            }
        },
    ]

    const [list, setList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [calendarLoading, setcalendarLoading] = useState(false)
    const [calendarID, setCalendarID] = useState()
    const [postID, setPostID] = useState()
    const [selectedCalendar, setselectedCalendar] = useState([])
    const [postLoading, setpostLoading] = useState(false)
    const [calendarDetail, setCalendarDetail] = useState(false)

    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }

    const fetchTable = () => {
        setcalendarLoading(true);
        CreativeCalenderApi.get({ page: parseInt(pageNumber) + 1, limit: 15 }).then(response => {
            setList(response.data);
            setcalendarLoading(false);
        }).catch((error) => {
            setcalendarLoading(false)
        })
    }

    const selectCalendar = (data) => {
        setCalendarDetail(true)
        setselectedCalendar(data)
        console.log(data);
        setCalendarID(data?.id)
        setPostID()
    }

    const handleCloseDetails = () => {
        setCalendarDetail(false)
        setselectedCalendar()
        setCalendarID()
        setPostID()
    }

    const handleChangePage = (event, newPage) => {
        setPageNumber(newPage);
    };

    const handleUpdate = () => {
        setRefresh(refresh * 2)
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    const handleOpenPosts = (id) => {
        setPostID()
        setCalendarID(id)
    }

    const handleOpenPostDetails = (id) => {
        setPostID(id)
    }

    const handleCloseOthers = () => {
        setPostID()
        setCalendarID()
    }

    useEffect(() => {
        fetchTable();
    }, [pageNumber])

    console.log(list);

    return (

        <>
            {/* {detailViewId ? (<BasicTabs setTabs={setDetailViewId} />
            ) : ( */}


            <Grid p={1}>
                <Grid display={'flex'} justifyContent={'space-between'}>
                    <PageHeader title={'Creative Calendar'} />
                </Grid>
                {
                    !calendarDetail &&
                    <Grid display={'flex'} justifyContent={'end'}>
                        <Create projectName={selectedCalendar?.project?.name} key={refresh} setRefresh={setRefresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} handleCloseOthers={handleCloseOthers} setValue={setValue} setCalendarID={setCalendarID} setselectedCalendar={setselectedCalendar} />
                    </Grid>
                }
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={calendarLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container spacing={2}>
                    {
                        calendarDetail ?
                            <DetialIndex selectedCalendar={selectedCalendar} setselectedCalendar={setselectedCalendar} calendarID={calendarID} setCalendarID={setCalendarID} handleClose={handleCloseDetails} />
                            :
                            <>
                                {
                                    list?.data?.map((card, index) => (
                                        <Grid item
                                            xs={6}
                                            md={4}
                                            xl={4}
                                            key={card?.id} mt={2}>
                                            <BasicCard datas={card} selectCalendar={selectCalendar} calendarID={calendarID} setCalendarID={setCalendarID} />
                                        </Grid>
                                    ))
                                }
                                {
                                    list?.data?.length > 0 &&
                                    <Grid md={12}>
                                        <TablePagination
                                            component="div"
                                            count={list?.meta?.total}
                                            rowsPerPage={list?.meta?.per_page}
                                            page={pageNumber}
                                            onPageChange={handleChangePage} // Update this line
                                            rowsPerPageOptions={[]}
                                            labelRowsPerPage="" // This will hide the "Rows per page" label
                                            SelectProps={{ native: true }} // This will hide the select dropdown
                                        />
                                    </Grid>
                                }
                            </>
                    }
                </Grid>
            </Grid>
            {/* )} */}

        </>
    )
}



export default Index