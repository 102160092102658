import { Box, Button, Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TabPanel from '../../../../../utils/TabPanel';
import ItemTasks from './item sections/item-task/item-tasks';
import PostCopy from './item sections/copy/post-copy';
import CopyModal from './item sections/copy/add-copy';
import PostCreative from './item sections/creatives/post-creative';
import CreativeModal from './item sections/creatives/add-creatives';
import PostPreview from './item sections/preview/post-preview';
import PreviewModal from './item sections/preview/add-preview';
import PostNotes from './item sections/notes/post-notes';
import CreateNotes from '../notes/create-note';

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PostTabs = (props) => {

    const [copyRefresh, setcopyRefresh] = useState(0)
    const [creativeRefresh, setCreativeRefresh] = useState(0)

    const tabs = [
        {
            component: <PostPreview fetchPosts={props?.fetchPosts} postID={props.postID} refresh={creativeRefresh} setRefresh={setCreativeRefresh} />,
            label: 'Preview'
        },
        {
            component: <PostCreative fetchPosts={props?.fetchPosts} postID={props.postID} refresh={creativeRefresh} setRefresh={setCreativeRefresh} />,
            label: 'Creatives'
        },
        {
            component: <PostCopy fetchPosts={props?.fetchPosts} postID={props.postID} refresh={copyRefresh} setRefresh={setcopyRefresh} />,
            label: 'Copy'
        },
        {
            component: <ItemTasks project={props?.project} fetchPosts={props?.fetchPosts} postID={props.postID} />,
            label: 'Tasks'
        },
        {
            component: <PostNotes fetchPosts={props?.fetchPosts} postID={props.postID} />,
            label: 'Notes'
        },

    ];

    const [activeTab, setActiveTab] = useState(0);
    const [copyID, setCopyID] = useState()
    const [creativeID, setCreativeID] = useState()
    const [previewID, setPreviewID] = useState()
    const [noteEditID, setNoteEditID] = useState()

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleCreateCopy = () => {
        setCopyID(0)
    }
    const handleCreateCreative = () => {
        setCreativeID(0)
    }
    const handleCreatePreview = () => {
        setPreviewID(0)
    }
    const handleAddNote = () => {
        setNoteEditID(0)
    }




    return (
        <>
            <CopyModal postID={props.postID} id={copyID} setId={setCopyID} setRefresh={setcopyRefresh} />
            <CreativeModal postID={props.postID} id={creativeID} setId={setCreativeID} setRefresh={setCreativeRefresh} />
            <PreviewModal postID={props.postID} id={previewID} setId={setPreviewID} setRefresh={setCreativeRefresh} />
            <CreateNotes from={'post'} calendarID={props.postID} editID={noteEditID} setEditID={setNoteEditID} setRefresh={setCreativeRefresh} />

            {/* <Card variant="outlined"> */}

            <Box sx={{ borderBottom: '1px solid #e8e8e8', px: 2 }} >
                <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example" >
                    {tabs.map((obj, index) => (
                        <Tab label={obj.label} key={index} {...a11yProps(index)} />
                    ))}
                    <Grid display={'flex'} alignItems={'center'} style={{ marginLeft: 'auto' }}>
                        {
                            activeTab == 4 &&
                            <Button size='small' variant='outlined' onClick={handleAddNote}>Add Note</Button>
                        }
                        {
                            activeTab == 2 &&
                            <Button size='small' variant='outlined' onClick={handleCreateCopy}>Add Copy</Button>
                        }
                        {
                            activeTab == 1 &&
                            <Button size='small' variant='outlined' onClick={handleCreateCreative}>Add Creatives</Button>
                        }
                        {
                            activeTab == 0 &&
                            <Button size='small' variant='outlined' onClick={handleCreatePreview}>Add Preview</Button>
                        }
                        {
                        }
                    </Grid>
                </Tabs>
            </Box>

            {tabs.map((obj, index) => {
                return <TabPanel value={activeTab} index={index} key={index}>
                    {obj.component}
                </TabPanel>
            })}
            {/* </Card> */}
        </>
    );
};

export default PostTabs;

