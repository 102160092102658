import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Card,
    CardContent,
    Typography,
    Grid,
    Divider,
    Select,
    Backdrop,
    MenuItem,
    DialogTitle,
    InputLabel,
    TextareaAutosize,
    TextField,
    Slide,
    CircularProgress,
} from "@mui/material";
import { useEffect } from 'react';
import { useState } from 'react';
import { Account } from '../../../../api/Endpoints/Account';
import { WorkRequest } from '../../../../api/Endpoints/WorkRequest';


const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    select: {
        minWidth: 200,
    },
}));




const Details = ({ WorkID }) => {
    const classes = useStyles();


    const [Loading, setLoading] = useState(false)
    const [Data, setData] = useState()



    const fetchDetails = () => {
        setLoading(true);
        WorkRequest.getWorkRequestDetails({ id: WorkID }).then(response => {
            setData(response.data.data);
            setLoading(false);
        })
    }


    useEffect(() => {
        fetchDetails()
    }, [])
    return (




        <>
            {Loading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={Loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Title:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.title}
                                        </Typography>
                                    </Typography>


                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Project:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.project.name}
                                        </Typography>
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} sx={{ pt: 2 }} />
                            
                            <Grid container spacing={1} >
                                <Grid item xs={6} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Assigned To:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.assigned_to_user?.name}

                                        </Typography>
                                    </Typography>


                                </Grid>

                                <Grid item xs={12} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Accepted By:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>

                                        </Typography>

                                    </Typography>


                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} sx={{ pt: 2 }} />
                            <Grid container spacing={1} >
                                <Grid item xs={6} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Requested Date :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.expected_completion_date ? new Date(Data.start_date).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric"
                                            }) : ""}

                                        </Typography>
                                    </Typography>


                                </Grid>

                                <Grid item xs={12} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Due Date:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.expected_completion_date ? new Date(Data.expected_completion_date).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric"
                                            }) : ""}

                                        </Typography>

                                    </Typography>


                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} sx={{ pt: 2 }} />
                            <Grid container spacing={1} >
                                <Grid item xs={6} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Started Date :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.expected_completion_date ? new Date(Data.start_date).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric"
                                            }) : ""}

                                        </Typography>
                                    </Typography>


                                </Grid>

                                <Grid item xs={12} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Closed Date :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.close_date
                                                ? new Date(Data.close_date
                                                ).toLocaleDateString("en-GB", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric"
                                                }) : ""}

                                        </Typography>

                                    </Typography>


                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} sx={{ pt: 2 }} />
                            <Grid container spacing={1} >
                                <Grid item xs={6} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Actual Hours Spent :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.actual_hours_spent}

                                        </Typography>
                                    </Typography>


                                </Grid>

                                <Grid item xs={12} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Estimated Hours :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.estimated_hours}

                                        </Typography>

                                    </Typography>


                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} sx={{ pt: 2 }} />
                          
                            <Grid container spacing={1} >
                                <Grid item xs={6} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Status:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.status}
                                        </Typography>
                                    </Typography>


                                </Grid>

                                <Grid item xs={12} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Request Type:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.request_type
                                            }
                                        </Typography>

                                    </Typography>


                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} sx={{ pt: 2 }} />
                            
                            <Grid container spacing={1} >
                                <Grid item xs={6} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Cost Model:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.cost_model}
                                        </Typography>
                                    </Typography>


                                </Grid>

                                <Grid item xs={12} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Priority:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.priority}
                                        </Typography>

                                    </Typography>


                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} sx={{ pt: 2 }} />
                            <Grid item xs={12} sm={12} mt={2}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Remarks For Client :
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        {Data?.remarks_for_client}

                                    </Typography>

                                </Typography>


                            </Grid>
                            <Divider className={classes.divider} sx={{ pt: 2 }} />
                            <Grid item xs={12} sm={12} mt={2}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Internal Remarks:
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        {Data?.remarks_for_team}

                                    </Typography>

                                </Typography>


                            </Grid>
                            <Divider className={classes.divider} sx={{ pt: 2 }} />
                            <Grid item xs={12} sm={12} mt={2}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Description :
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        {Data?.description}

                                    </Typography>

                                </Typography>


                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )
            }
        </>
    );
};

export default Details;
