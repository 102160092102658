import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Slide, Typography, } from '@mui/material';

import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { DotSpinner } from '@uiball/loaders';
import { CreativeCalenderApi } from '../../../../../../../api/Endpoints/CreativeCalender';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const divider = {
    marginTop: 2,
}

const DetailView = (props) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    // const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => {
        props.handleClose(false)
        setOpen(false);
        props.setId()
    };
    const [details, setDetails] = useState()
    const [loading, setLoading] = useState(false);

    const fetchTaskDetails = () => {
        console.log(props.id);
        setLoading(true)
        CreativeCalenderApi.getCreativesDetails({ id: props.id }).then((response) => {
            console.log(response);
            setDetails(response.data.data)
            setLoading(false)
            console.log(response.data.data);
        }).catch(errors => {
            console.log(errors);
        })

    }

    const handleStorageChange = () => {
        console.log('testing');
    };

    useEffect(() => {

        fetchTaskDetails()
    }, [props.id])

    return (
        <>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >Detail View</DialogTitle>
                <DialogContent>
                    {details ? <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Title:
                                </Typography>
                                <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                    {details?.title}
                                </Typography>

                            </Grid>
                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <img width={'100%'} src={details?.file} />
                            </Grid>
                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Created By:
                                </Typography>
                                <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                                    {details?.created_user?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Created Date:

                                </Typography>
                                <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                    {moment(details?.created_at).format('DD-MM-YYYY')}
                                </Typography>

                            </Grid>

                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem', marginTop: 2 }}>
                                    File Size:
                                </Typography>
                                <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                    {details?.file_size} kb
                                </Typography>
                            </Grid>
                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem', marginTop: 2 }}>
                                    Remarks:
                                </Typography>
                                <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                    {details?.remarks}
                                </Typography>
                            </Grid>
                        </Grid>
                    </> : (
                        <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'30%'} mt={10}>
                            <DotSpinner size={40} speed={0.9} color="blue" />
                        </Box>
                    )
                    }
                    {/* <Divider className={classes.divider} /> 
                    <Grid container spacing={2}> 
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Cost Model:

                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >

                                {details?.cost_model}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Status :
                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >


                                {details?.status}
                            </Typography>
                        </Grid>
                    </Grid> */}

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default DetailView;
