import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";

import {
    Button,
    Box,
} from "@mui/material";

import { Project } from '../../../../../api/Endpoints/Project';
import { DataGrid } from '@mui/x-data-grid';

import Create from './create';
import { MoreOptionsDropdown } from '../../../common/MoreAction/actionButton';
import Delete from '../../../common/Popup/Delete';
import { DotSpinner } from '@uiball/loaders';
import moment from 'moment';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '85vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function Notes(props) {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({})
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([])
    const [ViewDetails, setViewDetails] = useState(false);
    const [editID, setEditId] = useState(0)
    const [ID, setID] = useState(0)
    const [pageNumber, setPageNumber] = useState(0);

    const [showDeletePopup, setDeletePopup] = useState(false)
    const [viewOnly, setViewOnly] = useState(false)
    const columns = [
        {
            field: 'title',
            headerName: 'title',
            width: 300,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" onClick={() => viewDetails(id)}>{params.value}</Button >
                    </>
                );
            },
        },

        {
            field: 'note_type',
            headerName: 'Note Type',
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.value}</Button >
                    </>
                );
            },
        },
        { field: 'created_at', headerName: 'Created On', width: 180, renderCell: (params) => { return moment(params.value).format('DD-MM-YYYY') } },
        { field: "created_user", headerName: "Added By", width: 100, renderCell: (params) => { return params.value?.name } },
        {
            width: 50,
            renderCell: (params) => <MoreOptionsDropdown
                params={params}
                setEditId={setEditId}
                setID={setID}
                setOpen={setViewDetails}
                setDeletePopup={setDeletePopup}
            />,
        },


    ]

    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const viewDetails = (id) => {
        setID(id)
        setViewOnly(true)
        setViewDetails(true)
    }



    const fetchNotes = async () => {
        // setLoading(true)
        const response = await Project.getNotes({ projects_id: props.projectID, page: parseInt(pageNumber) + 1 })
        if (response.data.status === "success") {
            console.log(response.data);
            setList(response.data.data)
            props.setCount(response?.data?.data?.total)
        }
        // setLoading(false)
    }


    useEffect(() => {
        fetchNotes()
    }, [pageNumber])

    return (
        <div>
            {!loading ? (
                <>

                    {showDeletePopup && <Delete
                        ID={ID}
                        setID={setID}
                        setDeletePopup={setDeletePopup}
                        Callfunc={() => fetchNotes()}
                        url="/projects/notes/delete?id="
                        title="Note" />}

                    <Create projectID={props.projectID} fetchNotes={fetchNotes}
                        editID={editID} setID={setID}
                        setViewDetails={setViewDetails}
                        viewOnly={viewOnly}
                        setViewOnly={setViewOnly}
                        viewDetails={ViewDetails}
                        ID={ID}
                        setEditId={setEditId}
                        fetch={props?.fetch}
                        />

                    <div style={{ height: 500, width: '100%', marginTop: 10 }}>
                        {typeof list?.data === "object" &&
                            <DataGrid
                                paginationMode="server"
                                rows={list?.data}
                                columns={columns}
                                pageSize={10}
                                rowHeight={20}
                                rowsPerPageOptions={[10]}
                                rowCount={list?.total}
                                onPageChange={handlePageChange}
                                autoHeight={false}
                                density={"comfortable"}
                            />

                        }
                    </div>
                </>
            ) : (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '30vh'
                }}>
                    < DotSpinner
                        size={40}
                        speed={0.9}
                        color="blue"
                    />
                </Box >
            )
            }
        </div >
    );
}

export default Notes;
