import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Modal, Slide, TextField, Typography, } from '@mui/material';

import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Activities } from '../../../../api/Endpoints/Activities';
import { makeStyles } from '@material-ui/core';
import { Meetings } from '../../../../api/Endpoints/Meeting';
import moment from 'moment';
import { a } from '@react-spring/web';
import { DotSpinner } from '@uiball/loaders';
import { Employee } from '../../../../api/Endpoints/Employee';
import { useForm } from 'react-hook-form';
import SelectX from '../../../form/SelectX';
import AsyncSelect from "react-select/async";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const divider = {
    marginTop: 2,
}



const DetailView = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm();


    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [projects, setProjects] = useState([])
    // const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => {
        props.handleClose(false)
        // setOpen(false); 
    };
    const [Data, setData] = useState()
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = React.useState(false);


    const fetchTaskDetails = () => {
        console.log(props.id);
        setLoading(true)
        Meetings.getDetails({ id: props.id }).then((response) => {
            console.log(response);
            setData(response.data.data)
            let Proj = response.data.data.meetables
            let projName = Proj.map(item => item.meetable.name)
            console.log(projName);
            setProjects(projName)
            setLoading(false)
            console.log(response.data.data);
        }).catch(errors => {
            console.log(errors);
        })

    }

    const handleStorageChange = () => {
        console.log('testing');
    };

    // console.log(projects);




    useEffect(() => {

        fetchTaskDetails()
    }, [props.id])

    return (
        <>

            {
                Data ?
                    <>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Title :
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        {Data?.title}
                                    </Typography>
                                </Typography>

                            </Grid>
                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Project :
                                    {projects?.map((item, index) => (

                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {item},
                                            {/* {projects.length==index-1?'':','} */}
                                        </Typography>
                                    ))}
                                </Typography>

                            </Grid>
                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Meeting On :
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        {moment(Data?.meeting_on).format("hh:mm A (DD-MM-YYYY)")}
                                    </Typography>
                                </Typography>

                            </Grid>
                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Meeting Link :
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        <a href={Data?.meeting_link}>{Data?.meeting_link}</a>
                                    </Typography>
                                </Typography>
                            </Grid>


                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid container spacing={2} >

                            <Grid item xs={12} sm={12} >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Meeting Type :
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        {Data?.meetable_type}
                                    </Typography>
                                </Typography>


                            </Grid>

                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid item xs={12} sm={12} mt={2}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Meeting Mode :
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {Data?.meeting_mode?.name}

                                </Typography>

                            </Typography>


                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid item xs={12} sm={12} mt={2}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Remarks :
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {Data?.remarks}

                                </Typography>

                            </Typography>


                        </Grid>


                    </>
                    : (
                        <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'30%'} mt={10}>
                            <DotSpinner size={40} speed={0.9} color="blue" />
                        </Box>
                    )}

            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <a onClick={() => props.detailEdit(Data)} style={{ textDecoration: 'underline', fontSize: 12, cursor: 'pointer', color: 'blue', fontSize: '13px', marginLeft: '-30px' }}>Edit</a>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          


        </>
    )
}

export default DetailView;
