import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Card, Box, CircularProgress, Typography, IconButton, } from '@mui/material';
import { Employee } from '../../../../api/Endpoints/Employee';
import DocumentView from '../../common/DocumentView';
import ImageView from '../../common/Popup/ImageView';


function EmployeeSelfies({ employeeID }) {
    const [showImageUploadPopup, setShowImagePopup] = useState(false)
    const [list, setList] = useState(null)
    const [loading, setLoading] = useState(false);
    const [ID, setID] = useState(0)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [ZoomView, setZoomView] = useState(false)
    const [photos, setPhotos] = useState([]);
    const [DeleteType, setDeleteType] = useState('')
    const [employeeDetails, setEmployeeDetails] = useState()



    useEffect(() => {
        fetchSelfies()
    }, []);


    const fetchSelfies = () => {
        setPhotos([]);
        setLoading(true);
        Employee.getEmployeeDetails({ id: employeeID })
            .then(response => {
                console.log(response.data.data);
                setEmployeeDetails(response.data.data)

                const photosArray = [];

                // if (response.data.data.family_photo) {
                //     photosArray.push({ type: 'family_photo', url: response.data.data.family_photo });
                // }
                if (response.data.data.selfi_photo) {
                    photosArray.push({ type: 'selfi_photo', url: response.data.data.selfi_photo });
                }

                setPhotos(photosArray);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });

    }
    const handleShowpopup = () => {
        setShowImagePopup(true)
        setID(employeeID)
    }

    const handleEditButtonClick = (id) => {
        setID(id)
        setShowImagePopup(true)
    }

    const handleDelete = (data) => {
        setID(employeeID)
        setDeleteType(data.type)
        setDeletePopup(true)
    }

    const handleZoomView = () => {
        setZoomView(true)
    }

    return (
        <div style={{ marginTop: 1 }}>
            {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                    <CircularProgress size={33} />
                    <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                        Loading...
                    </Typography>
                </Box>
            ) : (
                <>
                    {/* {showDeletePopup && <Delete
                        ID={ID}
                        setID={setID}
                        setDeletePopup={setDeletePopup}
                        Callfunc={() => fetchSelfies()}
                        url="/employees/photos/delete?id="
                        title="Photo"
                        type={DeleteType}
                    />
                    } */}



                    {list == null ? (
                        <div div className='flex' style={{ width: '100', display: 'flex', justifyContent: "end", marginRight: 10 }}>
                            {/* <Button variant="contained" color="primary" component="label"
                                onClick={handleShowpopup} >
                                Add Selfie
                            </Button> */}
                        </div>
                    ) : (
                        null
                    )
                    }
                    {
                        showImageUploadPopup &&
                        <ImageView
                            showImageUploadPopup={showImageUploadPopup}
                            setShowImagePopup={setShowImagePopup}
                            callFunc={() => fetchSelfies()}
                            ID={ID}
                            setID={setID}
                        />
                    }


                    {photos.length === 0 ? (
                        <>

                            <Box display={'flex'} justifyContent={'center'}>
                                <p>No Photo Added</p>
                            </Box>

                        </>
                    ) : (
                        <>
                            {
                                photos.map((photo) => (
                                    <Card sx={{ mt: 5, }}>
                                        <div onClick={() => handleZoomView(photo)}>
                                            <img
                                                src={`https://dashboard.omnisellcrm.com/${photo.url}`}
                                                style={{ maxWidth: '20%', height: 'auto' }}
                                            />
                                        </div>
                                        {/* <div onClick={() => handleZoomView()}>
                                        <img
                                            src={`https://dashboard.omnisellcrm.com/${list.photo}`}
                                            style={{ maxWidth: '20%', height: 'auto' }}
        
                                        />
                                    </div> */}

                                        {ZoomView && <DocumentView imageUrl={photos[0].url} fileName={"selfi"} setZoomView={setZoomView} />}
                                        {/* <DocumentView documentURL={photos[0].url} fileName={"selfi"} /> */}
                                        <Box width={'20%'} display={'flex'} justifyContent={'end'} mt={1}>

                                            {/* <Button onClick={() => handleEditButtonClick(list.id)}> <EditIcon style={{ color: 'blue', height: 19 }} /></Button> */}
                                            {/* <Button onClick={() => handleDelete(photo)}><DeleteIcon style={{ color: 'red', height: 19 }} /></Button> */}


                                        </Box>
                                    </Card>
                                ))
                            }
                        </>

                    )}

                </>
            )
            }
        </div >
    );
}

export default EmployeeSelfies;
