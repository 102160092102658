import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    InputLabel,
    TextField,
    Slide,
    RadioGroup,
    FormControlLabel,
    Radio, 
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../../components/Form/TextInput"
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";





import ReactSelector from 'react-select';
import { faHouseFloodWaterCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ReportIssue } from '../../../api/Endpoints/ReportIssue';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({

    title: yup.string().required(),
    priority: yup.string(),

})




const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => {
        props.setEditId()
        setOpen(false);
    };


    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchProjects = (e) => {

    }

    const fetchEmployees = (e) => {
        let project = watch('projects_id');


    }

    const prioriyTask = () => {

    }

        ;


    const handlePriorityChange = (id) => {
        setSelectedPriority(id);
    }


    const priorityOptions = [
        {
            value: "Low", label: "Low"
        },
        {
            value: "Medium", label: "Medium"
        },
        {
            value: "High", label: "High"
        },

    ];





    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let dataToSubmit = {
            id: props.editId,
            title: data.title,
            description: data.description,
            priority: data.priority,
        };

        console.log(dataToSubmit);
        let action;

        if (props.editId > 0) {
            action = ReportIssue.update(dataToSubmit);
        } else {
            action = ReportIssue.add(dataToSubmit);
        }

        action.then(response => {
            console.log(response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }



    const fetchWorkRequestDetails = async () => {
        setLoading(true);


        setLoading(false);
    };

    const fetchDetails = async () => {
        setLoading(true)
        const response = await ReportIssue.getDetails({ id: props.editId })
        setLoading(false)
        if (response.data.status === "success") {
            const data = response.data.data
            console.log(data);
            setValue('title', data.title)
            setValue('priority', data.priority)
            setValue('description', data.description)
        }

    };





    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetails()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        }

    }, [])

    useEffect(() => {
        setRefresh(Math.random())
    }, [])



    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Report"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit a work Request" : "Issue Report Form"}</DialogTitle>
                <DialogContent>
                    {!loading ?
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="title"
                                    label="Title"
                                    value={watch('title')} />
                            </Grid>


                            <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1
                                }}>Description </InputLabel>
                                <TextField
                                    {...register('description')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    sx={{ width: '100%', }}
                                    required
                                />
                            </Grid>

                            <Grid sx={{ p: 1, mt: 0.5, }} item xs={6}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 0.5
                                }}>Report Anonymously   </InputLabel>
                                <Grid sx={{ p: 1, mt: 0.5, display: "flex",  alignItems: "center"  }} item xs={6}>
                                <RadioGroup
                                 row
                                 aria-labelledby="demo-row-radio-buttons-group-label"
                                 name="row-radio-buttons-group"
                                //  value={selectedValue}
                                //  onChange={handleChange}
                                >
                                <FormControlLabel value="YES" control={<Radio />} label="YES" />
                                <FormControlLabel value="NO" control={<Radio />} label="NO" />
                                </RadioGroup>
                            
                                        </Grid>
                                </Grid>




                            {/* Part for Dropdown's   */}

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            </Grid>



                        </form>
                        :
                        <>Loading...</>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default Create
