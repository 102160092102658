import React, { useEffect, useState } from 'react';
import PageHeader from "../common/page-header";
import { useForm } from 'react-hook-form';
import { CreativeCalenderApi } from '../../../api/Endpoints/CreativeCalender';
import { Edit } from '@mui/icons-material';
import { Waveform } from '@uiball/loaders';
import { Backdrop, CircularProgress, Grid, InputLabel, TablePagination, TextField } from '@mui/material';
import AsyncSelect from "react-select/async";
import BasicCard from './CardComponent';
import { Project } from '../../../api/Endpoints/Project';
import { Employee } from '../../../api/Endpoints/Employee';
import CurrentDateInput from '../../form/CurrenDateInput';


const CreativeWorkIndex = () => {


    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm();
    const [editId, setEditId] = useState();
    const [refresh, setRefresh] = useState(0);
    const [detailViewId, setDetailViewId] = useState(false)

    const DetailViewOpen = (id) => {
        setDetailViewId(id)
    }
    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }

    const columns = [
        {

            field: 'name', headerName: 'title', width: 350,
            renderCell: (params) => <p style={{ cursor: 'pointer', color: 'blue' }} onClick={() => DetailViewOpen(params.row.id)}>{params.value}</p>

        }, {
            field: 'project', headerName: 'Project name', width: 200,
            renderCell: (params) => <p style={{ cursor: 'pointer' }} >{params.value?.name}</p>
        },
        {
            field: 'calender_for', headerName: 'Calender For', width: 180,
            renderCell: (params) => <p>{params.row?.calendar_month} {params?.row?.calendar_year}</p>
        },
        {
            field: 'report_to', headerName: 'Manager', width: 150, renderCell: (params) => {
                // console.log("This is data", params);
                // if (params) {

                return <p>{params?.value?.name}</p>
                // } else {
                //     return "Not started";
                // }
            }
        },
        {
            field: 'status', headerName: 'Status', width: 120,
            renderCell: (params) => <p>{params?.value}</p>
        },
        {
            field: 'action', headerName: 'Edit', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><a
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEdit(id)}><Edit color='primary' fontSize='small' /></a>
                </>;
            }
        },
    ]

    const [list, setList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [calendarLoading, setcalendarLoading] = useState(false)
    const [calendarID, setCalendarID] = useState()
    const [postID, setPostID] = useState()
    const [selectedCalendar, setselectedCalendar] = useState([])
    const [postLoading, setpostLoading] = useState(false)
    const [calendarDetail, setCalendarDetail] = useState(false)

    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }

    const fetchTable = () => {
        setcalendarLoading(true);
        CreativeCalenderApi.get({ page: parseInt(pageNumber) + 1, limit: 5 }).then(response => {
            setList(response.data);
            setcalendarLoading(false);
        }).catch((error) => {
            setcalendarLoading(false)
        })
    }

    const selectCalendar = (data) => {
        setCalendarDetail(true)
        setselectedCalendar(data)
        console.log(data);
        setCalendarID(data?.id)
        setPostID()
    }

    const handleCloseDetails = () => {
        setCalendarDetail(false)
        setselectedCalendar()
        setCalendarID()
        setPostID()
    }

    const handleChangePage = (event, newPage) => {
        setPageNumber(newPage);
    };

    const handleUpdate = () => {
        setRefresh(refresh * 2)
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    const handleOpenPosts = (id) => {
        setPostID()
        setCalendarID(id)
    }

    const handleOpenPostDetails = (id) => {
        setPostID(id)
    }

    const handleCloseOthers = () => {
        setPostID()
        setCalendarID()
    }

    const selectEmployees = (data) => {
        setValue('posted_by', data || '')
    }
    const selectProject = (data) => {
        setValue('project', data || '')
    }

    const fetchProjects = (e) => {
        return Project.get({ keyword: e }).then(response => {
            // console.log(response);
            if (typeof response?.data?.data?.data !== "undefined") {
                return response.data.data.data
            } else {
                return [];
            }
        })
    }

    const fetchEmployees = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            console.log(response);
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data
            } else {
                return [];
            }
        })
    }

    useEffect(() => {
        fetchTable();
    }, [pageNumber, watch('project'), watch('posted_by')])

    console.log(list);

    return (

        <>
            {/* {detailViewId ? (<BasicTabs setTabs={setDetailViewId} />
            ) : ( */}


            <Grid p={1}>
                <Grid display={'flex'} justifyContent={'space-between'}>
                    <PageHeader title={'Creative Works'} />
                </Grid>
                <Grid sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'space-between' }}  >
                    <Grid display={'flex'}>
                        <Grid mr={2} sx={{ width: 250 }}>
                            <AsyncSelect
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isClearable={true}
                                defaultOptions
                                name='posted_by'
                                loadOptions={fetchEmployees}
                                getOptionLabel={(e) => e.name}
                                getOptionValue={(e) => e.id}
                                placeholder={'Posted By'}
                                value={watch('posted_by')}
                                onInputChange={fetchEmployees}
                                onChange={selectEmployees}
                            />
                        </Grid>
                        <Grid mr={2} sx={{ width: 250 }}>
                            <AsyncSelect
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isClearable={true}
                                defaultOptions
                                name='project'
                                loadOptions={fetchProjects}
                                getOptionLabel={(e) => e.name}
                                getOptionValue={(e) => e.id}
                                placeholder={'Project'}
                                value={watch('project')}
                                onInputChange={fetchProjects}
                                onChange={selectProject}
                            />
                        </Grid>
                        <Grid item display={'flex'} alignItems={'center'}>
                            {/* <InputLabel sx={{ mr: 1,fontWeight:500,fontSize:'13px' }}>MONTH</InputLabel> */}
                            <Grid display={'flex'} alignItems={'center'} xs={3} sx={{ px: 2, }} >
                                <InputLabel sx={{ mr: 1 }}>From</InputLabel>
                                <Grid width={'200px'}>
                                    <CurrentDateInput
                                        control={control}
                                        name="from"
                                        value={watch('from')}
                                    // format='MMMM YYYY'
                                    // view={['year', 'month']}
                                    // openTo='month'
                                    />
                                </Grid>
                            </Grid>

                            <Grid display={'flex'} alignItems={'center'} xs={3} sx={{ px: 2, }} >
                                <InputLabel sx={{ mr: 1 }}>To</InputLabel>
                                <Grid width={'200px'}>
                                    <CurrentDateInput
                                        placeholder={'to'}
                                        control={control}
                                        name="to"
                                        value={watch('to')}
                                    // format='MMMM YYYY'
                                    // view={['year', 'month']}
                                    // openTo='month'
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    {/* <Grid display={'flex'}>
                        <TextField
                            size='small'
                            sx={{ width: 200, marginRight: 3 }}
                            id="outlined-name"
                            label="Search Employees"
                            onChange={handleSearchEntry}
                        />
                    </Grid> */}
                </Grid>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={calendarLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container spacing={2}>
                    {
                        list?.data?.map((card, index) => (
                            <Grid item
                                xs={6}
                                md={4}
                                xl={4}
                                key={card?.id} mt={2}>
                                <BasicCard datas={card} index={index} selectCalendar={selectCalendar} calendarID={calendarID} setCalendarID={setCalendarID} />
                            </Grid>
                        ))
                    }
                    {
                        list?.data?.length > 0 &&
                        <Grid md={12}>
                            <TablePagination
                                component="div"
                                count={list?.meta?.total}
                                rowsPerPage={list?.meta?.per_page}
                                page={pageNumber}
                                onPageChange={handleChangePage} // Update this line
                                rowsPerPageOptions={[]}
                                labelRowsPerPage="" // This will hide the "Rows per page" label
                                SelectProps={{ native: true }} // This will hide the select dropdown
                            />
                        </Grid>
                    }

                </Grid>
            </Grid>
            {/* )} */}

        </>
    )
}



export default CreativeWorkIndex