import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import { Avatar, Grid, List, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import TextInput from '../../../../../../form/TextInput';
import { Check, CheckCircle, CloudUpload, DeleteOutline } from '@mui/icons-material';
import { useState } from 'react';
import { CreativeCalenderApi } from '../../../../../../../api/Endpoints/CreativeCalender';
import toast from 'react-hot-toast';
import { DotSpinner } from '@uiball/loaders';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '80vh', // Set the maximum height to 80% of the viewport height
    overflowY: 'auto',    // width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

export default function PreviewModal({ id, setId, postID, setRefresh }) {

    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm()


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [file, setFile] = useState()
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedContent, setSelectedContent] = useState(null);
    const [copies, setCopies] = useState([])
    const [creatives, setCreatives] = useState([])
    const [loading, setLoading] = useState(false)

    const handleImageClick = (id) => {
        if (selectedImages.includes(id)) {
            // If the ID is already in selectedImages, remove it
            setSelectedImages(selectedImages.filter(imageId => imageId !== id));
        } else {
            // If the ID is not in selectedImages, add it
            setSelectedImages([...selectedImages, id]);
        }
    };

    // console.log(selectedImages);
    const handleContentClick = (index) => {
        setSelectedContent(index === selectedContent ? null : index);
    };

    const handleClose = () => {
        setId()
        setOpen(false);
        setSelectedContent()
        setSelectedImages([])
        reset()
    }

    const [formButtonStatus, setFormButtonStatus] = React.useState({
        label: "Save",
        loading: false,
        disabled: false,
    });

    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });

        let dataToSubmit = {
            id: id,
            project_creative_calendar_post_id: postID,
            project_creative_calendar_copy_id: selectedContent,
            creatives: selectedImages,
            remarks: data?.remarks,
        }
        console.log(dataToSubmit);

        let action;

        if (id > 0) {
            action = CreativeCalenderApi.updatePreviews(dataToSubmit)
        } else {
            action = CreativeCalenderApi.addPreviews(dataToSubmit)
        }

        action.then((response) => {
            console.log("respponser here", response);
            if (response?.data?.status == 'success') {
                // fetchList()
                toast.success(response?.data?.message)
                handleClose()
                reset()
                setRefresh(Math.random() * 0.2)
                setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            } else {
                toast.error(response?.data?.error?.message)

            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const getCopies = () => {
        CreativeCalenderApi.getCopies({ creative_calendar_post_id: postID }).then(response => {
            console.log(response);
            setCopies(response.data);
        }).catch(error => {
            console.log(error);
        })
    }

    const getCreatives = () => {
        CreativeCalenderApi.getCreatives({ creative_calendar_post_id: postID }).then(response => {
            setCreatives(response.data);
        }).catch(error => {
            console.log(error);
        })
    }

    const getDetails = async () => {
        setLoading(true)
        try {
            let Preview = await CreativeCalenderApi.getPreviewDetails({ id: id });
            console.log(Preview);
            if (Preview?.data?.data) {
                let data = Preview?.data?.data;
                setSelectedContent(data?.copy_text?.id);
                setValue('remarks', data.remarks)
                if (data?.creatives) {
                    let ids = []
                    data?.creatives?.map((obj) => {
                        ids.push(obj?.id)
                    })
                    setSelectedImages(ids)
                }
                setLoading(false)
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    // console.log(selectedImages);



    useEffect(() => {
        if (id > 0) {
            setOpen(true)
            getDetails()
        } if (id == 0) {
            setOpen(true)
        }
    }, [id])

    useEffect(() => {
        getCreatives()
        getCopies()
    }, [open])

    // console.log(creatives);


    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {
                        loading ?
                            (
                                <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'30%'} >
                                    <DotSpinner size={40} speed={0.9} color="blue" />
                                </Box>
                            )
                            :
                            <>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {id > 0 ? 'Edit Preview' : 'Add Preview'}
                                </Typography>
                                <Grid width={500}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Grid item container p={1} mt={1} sx={{}} sm={12} >
                                            <Grid item md={12}>
                                                <Typography id="modal-modal-title" style={{ fontWeight: 500 }} variant="a" component="a">
                                                    Select Creatives
                                                </Typography>
                                            </Grid>

                                            {creatives?.data?.map((obj, index) => (
                                                <Grid key={index} item pr={1} md={4} style={{ position: 'relative' }}>
                                                    <img
                                                        src={obj?.file}
                                                        style={{ maxWidth: '100%', height: 'auto', cursor: 'pointer', border: selectedImages?.includes(obj?.id) ? '0 0 5px 2px rgba(173, 216, 230, 0.7)' : 'none', }}
                                                        alt="example"
                                                        onClick={() => handleImageClick(obj?.id)}
                                                    />
                                                    {selectedImages?.includes(obj?.id) && (
                                                        <Avatar
                                                            style={{
                                                                position: 'absolute',
                                                                bottom: '5px',
                                                                right: '5px',
                                                                background: 'rgba(255, 255, 255, 0.7)',
                                                                padding: '5px',
                                                                // borderRadius: '10px',
                                                                height: '30px',
                                                                width: '30px'
                                                            }}
                                                        >
                                                            <Check />
                                                            {/* {index + 1} */}
                                                        </Avatar>
                                                    )}
                                                </Grid>
                                            ))}

                                            {/* <Grid item pr={1} md={4}>
                                        <img src='https://cdn.pixabay.com/photo/2016/01/08/11/57/butterflies-1127666_640.jpg' style={{ maxWidth: '100%', height: 'auto' }} alt="example" />
                                    </Grid>
                                    <Grid item pr={1} md={4}>
                                        <img src='https://cdn.pixabay.com/photo/2016/01/08/11/57/butterflies-1127666_640.jpg' style={{ maxWidth: '100%', height: 'auto' }} alt="example" />
                                    </Grid>
                                    <Grid item pr={1} md={4}>
                                        <img src='https://cdn.pixabay.com/photo/2016/01/08/11/57/butterflies-1127666_640.jpg' style={{ maxWidth: '100%', height: 'auto' }} alt="example" />
                                    </Grid>
                                    <Grid item pr={1} md={4}>
                                        <img src='https://cdn.pixabay.com/photo/2016/01/08/11/57/butterflies-1127666_640.jpg' style={{ maxWidth: '100%', height: 'auto' }} alt="example" />
                                    </Grid>
                                    <Grid item pr={1} md={4}>
                                        <img src='https://cdn.pixabay.com/photo/2016/01/08/11/57/butterflies-1127666_640.jpg' style={{ maxWidth: '100%', height: 'auto' }} alt="example" />
                                    </Grid>
                                    <Grid item pr={1} md={4}>
                                        <img src='https://cdn.pixabay.com/photo/2016/01/08/11/57/butterflies-1127666_640.jpg' style={{ maxWidth: '100%', height: 'auto' }} alt="example" />
                                    </Grid> */}
                                        </Grid>

                                        <Grid container item p={1} mt={1} sx={{}} sm={12} >
                                            <Grid item md={12}>
                                                <Typography id="modal-modal-title" style={{ fontWeight: 500 }} variant="a" component="a">
                                                    Select Copy
                                                </Typography>
                                            </Grid>
                                            {copies?.data?.map((obj, index) => (
                                                <Grid onClick={() => handleContentClick(obj?.id)} container mb={0.5} style={{ border: selectedContent == obj?.id ? '2px solid blue' : '1px solid grey', boxShadow: selectedContent == obj?.id ? '0 0 5px 2px rgba(173, 216, 230, 0.7)' : 'none' }} item md={12} p={1} pt={0} pb={0}>
                                                    <Grid item md={1} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <CheckCircle fontSize='small' style={{ verticalAlign: 'middle', color: selectedContent == obj?.id ? 'blue' : '' }} />
                                                    </Grid>
                                                    <Grid item md={11}>
                                                        <a style={{ fontSize: '15px', cursor: 'pointer', textAlign: 'justify' }}>
                                                            {obj?.copy_text?.length > 100 ? <p style={{whiteSpace: 'pre-line'}}>{obj?.copy_text?.slice(0, 100)}....</p> : <p style={{whiteSpace: 'pre-line'}}>{obj?.copy_text}</p>}
                                                        </a>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                        </Grid>

                                        <Grid mt={2} sx={{}} item sm={12} >
                                            <TextField
                                                // placeholder='Add Note'
                                                {...register('remarks')}
                                                label="Remarks"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                sx={{ width: '100%', }}
                                            // required
                                            />
                                        </Grid>
                                        <Grid display={'flex'} justifyContent={'end'} sx={{ mt: 1 }} item xs={12}>
                                            <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                        </Grid>
                                    </form>
                                </Grid>
                            </>
                    }
                </Box>
            </Modal >
        </div >
    );
}
