import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import { Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import TextInput from '../../../../../../form/TextInput';
import { CloudUpload, DeleteOutline } from '@mui/icons-material';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { CreativeCalenderApi } from '../../../../../../../api/Endpoints/CreativeCalender';
import { DotSpinner } from '@uiball/loaders';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    //   border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function CreativeModal({ id, setId, postID, setRefresh }) {


    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm()


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [image, setImage] = useState()
    const [file, setFile] = useState()
    const [loading, setLoading] = useState(false)


    const handleClose = () => {
        reset()
        setId()
        setImage()
        setFile()
        setOpen(false);
    }

    const handleFileChange = (e) => {
        setImage()
        const newFile = e?.target?.files[0];
        // setFile([...file, newFile]); // Add the new file to the state
        setFile(newFile)
    };
    const handleDeleteAttachment = (index) => {
        setFile()
    };

    const [formButtonStatus, setFormButtonStatus] = React.useState({
        label: "Save",
        loading: false,
        disabled: false,
    });

    const onSubmit = (data) => {
        // if (file) {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });

        const formData = new FormData();
        formData.append('id', id)
        formData.append('project_creative_calendar_post_id', postID)
        formData.append('title', data?.title)

        if (file) {
            formData.append('file', file)
        }
        formData.append('remarks', data?.remarks)

        // console.log('Form Data:');
        // for (const pair of formData.entries()) {
        //     console.log(pair[0] + ': ' + pair[1]);
        // }

        let action;

        if (id > 0) {
            action = CreativeCalenderApi.updateCreatives(formData)
        } else {
            action = CreativeCalenderApi.addCreatives(formData)
        }

        action.then((response) => {
            console.log("respponser here", response);
            if (response?.data?.status == 'success') {
                // fetchList()
                // setmodalOpen(false)
                toast.success(response?.data?.message)
                setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
                handleClose()
                setRefresh(Math.random() * 0.2)

            } else {
                toast.error(response?.data?.error?.message)

            }
        }).catch((error) => {
            console.log(error);
        })
        // } else {
        //     toast.error('Please choose a file')
        // }
    }

    const getDetails = async () => {
        setLoading(true)
        try {
            let copy = await CreativeCalenderApi.getCreativesDetails({ id: id });
            console.log(copy);
            if (copy?.data?.data) {
                let data = copy?.data?.data;
                console.log(data);
                setValue('title', data.title);
                setValue('remarks', data.remarks)
                setImage(data?.file)
                setLoading(false)
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }


    useEffect(() => {
        if (id > 0) {
            setOpen(true)
            getDetails()
        } if (id == 0) {
            setOpen(true)
        }
    }, [id])


    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {
                        loading ?
                            (
                                <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'30%'} >
                                    <DotSpinner size={40} speed={0.9} color="blue" />
                                </Box>
                            ) :
                            <>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {id > 0 ? 'Edit Creatives' : 'Add Creatives'}
                                </Typography>
                                <Grid width={500}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Grid mt={2} sx={{}} item sm={12} >
                                            <TextInput
                                                control={control}
                                                name="title"
                                                label="Title"
                                                value={watch('title')}
                                            />
                                        </Grid>

                                        <Grid mt={2} sx={{}} item sm={12} md={12} >
                                            <input
                                                type="file"
                                                id="file-upload"
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                            />

                                            <label htmlFor="file-upload">
                                                <Button
                                                    variant="contained"
                                                    startIcon={<CloudUpload />}
                                                    component="span"
                                                    sx={{
                                                        mt: 2,

                                                    }}
                                                >
                                                    {id > 0 ? 'Edit File' : 'Choose File'}
                                                </Button>

                                            </label>
                                            {
                                                image &&
                                                <Grid mt={1} md={12}>
                                                    <img src={image} height={'120px'} width={'140px'} />
                                                </Grid>
                                            }
                                            {
                                                file &&
                                                <List sx={{ mt: 2, maxHeight: 200, }}>
                                                    <ListItem >
                                                        <ListItemIcon>
                                                            <CloudUpload />
                                                        </ListItemIcon>
                                                        <ListItemText primary={file?.name} />
                                                        <Button

                                                            edge="end"
                                                            onClick={() => handleDeleteAttachment()}
                                                        >
                                                            <DeleteOutline />
                                                        </Button>
                                                    </ListItem>

                                                </List>
                                            }
                                        </Grid>

                                        <Grid mt={2} sx={{}} item sm={12} >
                                            <InputLabel sx={{
                                                color: "black",
                                                fontSize: '15px',
                                                pb: 0.5
                                            }}>Remarks  </InputLabel>
                                            <TextField
                                                // placeholder='Add Note'
                                                {...register('remarks')}
                                                // label="Remarks"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                sx={{ width: '100%', }}
                                            // required
                                            />
                                        </Grid>

                                        <Grid display={'flex'} justifyContent={'end'} sx={{ mt: 1 }} item xs={12}>
                                            <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                        </Grid>
                                    </form>
                                </Grid>
                            </>
                    }
                </Box>
            </Modal>
        </div>
    );
}
