import React, { useState, useEffect } from 'react';
import { Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';

import { DotSpinner } from '@uiball/loaders';
import { MeetingIdContext } from '../../../../contexts/MeetingID/meetingID';
import { Meetings } from '../../../../api/Endpoints/Meeting';
import Delete from '../../common/Popup/Delete';
import AssignTask from '../Tabs/Minutes/AssignTask';
import { MoreOptionsDropdown } from '../../common/MoreAction/actionButton';
import CreateMinute from '../Tabs/Minutes/create';
import Create from '../../projects/Tabs/Tasks/create';
import AssignTaskFromMinutes from '../Tabs/Minutes/AssignTask';
import Edit from '../Tabs/Minutes/edit';


function Index(props) {
    const { MeetingID } = MeetingIdContext();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [editId, setEditId] = useState();
    const [ID, setID] = useState(0);
    const [TaskPopup, setTaskPopup] = useState(false);
    const [showTaskIcon, setShowTaskIcon] = useState(true);
    const [AssignID, setAssignID] = useState(0);
    const [OpenEditPopup, setopenEditpopup] = useState(false);
    const [OpendetailsPopup, setOpenDetailPopup] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);



    useEffect(() => {
        fetchList();
        setShowTaskIcon(true);
    }, [props.id]);

    useEffect(() => {
        fetchList();

    }, []);



    const fetchList = async () => {
        setLoading(true);
        const response = await Meetings.getMeetingMinutes({ meetings_id: props.id });
        if (response.data.status === 'success') {
            console.log(response.data.data);
            setList(response.data.data);
        }
        setLoading(false);
    };

    const DetailView = (id) => {
        setID(id);
        setOpenDetailPopup(true);
    };
    console.log("this is list", list);

    const handleDeleteAlert = (id) => {
        setID(id);
        setDeletePopup(true);
    };

    const handleEditClick = (id) => {
        setEditId(id);
        setopenEditpopup(true);
    };

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    return (
        <div>
            {showDeletePopup && (
                <Delete
                    ID={ID}
                    setID={setID}
                    setDeletePopup={setDeletePopup}
                    Callfunc={() => fetchList()}
                    url="/meeting-minutes/delete?id="
                    title="Meeting Minutes"
                />
            )}

            {TaskPopup && (
                <AssignTaskFromMinutes key={refresh} onNew={handleCreate} onUpdate={handleUpdate} setOpen={setTaskPopup} editId={props.id} />
            )}

            {OpenEditPopup && (
                <Edit
                    Open={OpenEditPopup}
                    setOpen={setopenEditpopup}
                    editId={editId}
                    setEditId={setEditId}
                    fetchList={() => fetchList()}
                />
            )}

            {/* {OpendetailsPopup && (
                <EditPopup Open={OpendetailsPopup} setOpen={setOpenDetailPopup} editId={ID} setEditId={setID} ViewType={true} />
            )} */}

            {!loading ? (
                <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                    <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Minutes</TableCell>
                                    <TableCell>Assigned To</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>


                                {
                                    list.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <Button style={{ color: 'black', marginLeft: 10 }} size="small" onClick={() => DetailView(item.id)}>
                                                    {item.title}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button style={{ color: 'black', marginLeft: 10 }} size="small">
                                                    {item.assign_to_user?.name}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <MoreOptionsDropdown
                                                    params={item}
                                                    setEditId={setEditId}
                                                    setID={setID}
                                                    setDeletePopup={setDeletePopup}
                                                    setTaskPopup={setTaskPopup}
                                                    TaskPopup={TaskPopup}
                                                    showTaskIcon={showTaskIcon}
                                                    setAssignID={setAssignID}
                                                    setOpen={setopenEditpopup}
                                                    onEditClick={() => handleEditClick(item.id)}
                                                    onDeleteClick={() => handleDeleteAlert(item.id)}
                                                />
                                            </TableCell>

                                        </TableRow>
                                    ))
                                }


                                <TableRow className='no-hover-effect'>

                                    <CreateMinute meetingID={props.id} fetchList={() => fetchList()} />

                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            ) : (
                <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'30%'} mt={10}>
                    <DotSpinner size={40} speed={0.9} color="blue" />
                </Box>
            )
            }
        </div >
    );
}

export default Index;
