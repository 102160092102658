import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    TextareaAutosize,
    Select,
    TextField,
    Slide, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from '../../form/TextInput';
import DateInput from '../../form/DateInput';
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import SelectX from '../../form/SelectX';
import moment from "moment";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import { Account } from "../../../api/Endpoints/Account";
import { Project } from "../../../api/Endpoints/Project";
import { Employee } from '../../../api/Endpoints/Employee';
import { VendorPayments } from '../../../api/Endpoints/VendorPayments';
import ReactSelector from 'react-select';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({


    title: yup.string().required(),
    description: yup.string(),
    start_date: yup.string(),
    end_date: yup.string(),
    due_date: yup.string(),
    Owner_Id: yup.object(),
    assigned_by_id: yup.object(),
    projects_id: yup.object().shape({
        id: yup.string().required("Please choose a project").typeError("Please choose a project")
    }).required().typeError("Please choose a project"),
    // reviewer_id: yup.object().shape({
    //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    // }).required().typeError("Please choose a reviewer"),
})


const CurrentStatus = [
    { value: "Not Started", label: 'Not Started' },
    { value: "Development In Progress", label: 'Development In Progress' },
    { value: "Under Maintenance", label: 'Under Maintenance' },
    { value: "Testing In Progress", label: 'Testing In Progress' },
    { value: "Completed", label: 'Completed' },
    { value: "Live ", label: 'Live ' },
];

const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({})
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleClose = () => {

        setOpen(false);
        setTimeout(() => {
            props.setEditId(undefined)
        }, 500)
    };

    const handleClickOpen = () => {
        props.onNew()
    };
    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchClient = (e) => {
        let cleint_id = watch('client_id')
        return Account.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }
    const fetchVendors = (e) => {
        return VendorPayments.vendors({ keyword: e }).then(response => {

            if (typeof response.data.data !== "undefined") {
                return response.data.data;
            } else {
                return [];
            }
        })
    }
    const fetchProjects = async (e) => {
        const client_id = watch('client_id');

        try {
            const response = await Project.get({ accounts_id: client_id.id });
            console.log("this is project response", response);
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        } catch (error) {
            // Handle error
            console.error(error);
            return [];
        }
    };

    const prioriyTask = () => {

    }

        ;


    const handlePriorityChange = (id) => {
        setSelectedPriority(id);
    }


    const approvelstatus = [
        { value: "New", label: 'New' },
        { value: 'Manager Approval Pending', label: 'Manager Approval Pending' },
        { value: 'Manager_Approved', label: 'Manager Approved' },
        { value: 'finance approved', label: 'Finance Approved' },
        { value: 'User Action Required', label: 'User Action Required' },
        { value: 'paid', label: 'Paid' },
        { value: 'On Hold', label: 'On Hold' },
        { value: 'rejected', label: 'Rejected' },
    ];


    const yearList = [];
    for (let year = 2020; year <= 2030; year++) {
        yearList.push({ value: year.toString(), label: year.toString() });
    }


    const Months = [
        {
            value: "January ", label: "January "
        },
        {
            value: "February", label: "February"
        },
        {
            value: "March", label: "March"
        },

        {
            value: "April", label: "April"
        },
        {
            value: "May", label: "May"
        },
        {
            value: "June", label: "June"
        },
        {
            value: "July", label: "July"
        },
        {
            value: "August", label: "August"
        },
        {
            value: "September", label: "September"
        },
        {
            value: "October", label: "October"
        },
        {
            value: "November", label: "November"
        },
        {
            value: "December", label: "December"
        },



    ];


    const options = [
        { value: "Employee Reimbursement", label: 'Employee' },
        { value: "Vendor", label: 'Vendor' },
        { value: "None", label: 'None' },
    ];


    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })


        let date_of_activity = moment(data.date_of_activity).format('YYYY-MM-DD');


        let dataToSubmit = {
            id: props?.editId,
            title: data.title,
            description: data.description,
            reports_to_id: data.reports_to_id?.id,
            inventory_ventors_id: data.inventory_ventors_id?.id,
            date_of_activity: date_of_activity,
            reimbursement_type: "Vendor",
            amount: data.amount,
            status: data.status
            
        };
        // let date = ''
        // if (data.date_of_activity) {
        //     date = moment(data.date_of_activity).format('YYYY-MM-DD');
        // }

        let action;

        if (props.editId > 0) {

            action = VendorPayments.update(dataToSubmit)
        } else {
            action = VendorPayments.add(dataToSubmit)
        }
        action.then(response => {
            console.log(response);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props?.setDetailView(response?.data?.id)
            props.onUpdate();
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            // toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }



    const fetchVendorPaymentDetails = async () => {
        setLoading(true)
        let vendor = await VendorPayments.getDetails({ id: props.editId });
        if (vendor.data.status === "success") {

            let data = vendor.data.data;
            setValue('title', data.title);
            setValue('amount', data.amount);
            setValue('inventory_ventors_id', data.vendor);
            setValue('reports_to_id', data.report_to);
            setValue('description', data.description);
            setValue('reimbursement_type', data.reimbursement_type);
            setValue('status', data.status);
            setValue('date_of_activity', data.date_of_activity);
            setValue('is_active', data.is_active);
            setValue('remarks', data.remarks);


        }
        setLoading(false)
    };


    const fetchEmployees = (e) => {
        let project = watch('projects_id');
        return Employee.get({ keyword: e, projects_id: project?.id }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchVendorPaymentDetails()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            console.log("edit id is false");
            setOpen(true);
        }

    }, [])

    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('projects_id')])


    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Create Payments"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Payment" : "Create Payment"}</DialogTitle>
                <DialogContent>
                    {!loading ?
                        <form onSubmit={handleSubmit(onSubmit)}>

                            {loading ? <Typography ml={8} mt={3}> Loading...</Typography> : (


                                <Grid container spacing={2} sx={{ display: 'flex', }}>
                                    <Grid sx={{ p: 1 }} item xs={12}>
                                        <TextInput
                                            control={control}
                                            name="title"
                                            label="Title"
                                            value={watch('project_name')}
                                        />
                                    </Grid>
                                    <Grid sx={{ p: 1 }} item xs={12}>
                                        <TextInput
                                            control={control}
                                            name="description"
                                            label="Description"
                                            value={watch('description')}
                                            isMultiline
                                            row={5}
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={6}>
                                        <DateInput
                                            control={control}
                                            name="date_of_activity"
                                            label="Date Of Activity"
                                            value={watch('date_of_activity')}

                                        />

                                    </Grid>
                                    <Grid
                                        item xs={12} sm={6}>
                                        <TextInput
                                            control={control}
                                            name="amount"
                                            label="Amount"
                                            value={watch('amount')}
                                            type={'number'}
                                        />
                                    </Grid>
                                    <Grid sx={{ p: 1 }} item xs={12} sm={6}>
                                        <InputLabel sx={{
                                            color: "black",
                                            pb: 1,
                                        }}>Reimbursement To</InputLabel>
                                        <ReactSelector
                                            styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                            options={options}
                                            value={
                                                options.filter(option =>
                                                    option.label === watch('reimbursementType'))
                                            }
                                            name='reimbursementType'
                                            isClearable={true}
                                            defaultValue={(watch('reimbursementType'))}
                                            onChange={(selectedOption) => setValue('reimbursementType', selectedOption?.label || '')}
                                        />


                                    </Grid>
                                    {
                                        watch('reimbursementType') === 'Employee' &&
                                        <Grid item xs={12} sm={6} mt={1}>
                                            <SelectX
                                                key={refresh * 0.2}
                                                label={"Select Employee"}
                                                options={fetchEmployees}
                                                control={control}
                                                error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                                error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                                name={'employee_id'}
                                                defaultValue={watch('employee_id')}
                                            />
                                        </Grid>
                                    }

                                    <Grid sx={{ p: 1 }} item xs={12} sm={6}>
                                        <SelectX
                                            key={refresh * 0.2}
                                            label={"Select Vendor"}
                                            options={fetchVendors}
                                            control={control}
                                            error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                            error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                            name={'inventory_ventors_id'}
                                            defaultValue={watch('inventory_ventors_id')}
                                        />
                                    </Grid>




                                    <Grid sx={{ p: 1 }} item xs={12} sm={6}>
                                        <TextInput
                                            control={control}
                                            name="other_vendor"
                                            label="Other Vendor"
                                            value={watch('other_vendor')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput
                                            control={control}
                                            name="bank_details"
                                            label="Gpay/Bank Details "
                                            value={watch('bank_details')}
                                            isMultiline
                                            row={5}
                                        />
                                    </Grid>
                                    {/* 
                                    <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                                        <InputLabel sx={{
                                            color: "black",
                                            pb: .5,
                                            fontSize: '14px',
                                            fontWeight: 500
                                        }}>Gpay/Bank Details </InputLabel>
                                        <TextField
                                            {...register('bank_details')}
                                            variant="outlined"
                                            sx={{
                                                width: '100%',
                                                '& .Mui-disabled': {
                                                    backgroundColor: 'lightblue',
                                                }
                                            }}
                                            disabled={watch('reimbursementType') === 'Employee'}
                                            fullWidth
                                            multiline
                                            rows={2}
                                        // required
                                        />
                                    </Grid> */}


                                    <Grid sx={{ p: 1, }} item xs={12}>
                                        <SelectX
                                            key={refresh * 0.2}
                                            label={"Select Manager for Approval"}
                                            options={fetchEmployees}
                                            control={control}
                                            error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                            error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                            name={'reports_to_id'}
                                            defaultValue={watch('reports_to_id')}
                                        />
                                    </Grid>
                                    {/* <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                                        <InputLabel sx={{
                                            color: "black",
                                            pb: 1,
                                        }}>Status</InputLabel>
                                        <ReactSelector
                                            options={approvelstatus}
                                            value={approvelstatus.filter(approvalstatus =>
                                                approvalstatus.label === watch('status')
                                            )}

                                            name='status'
                                            isClearable={true}
                                            defaultValue={(watch('status'))}
                                            onChange={(selectedOption) => setValue('status', selectedOption.value)}
                                        />


                                    </Grid> */}
                                    <Grid sx={{ p: 1 }} mb={5} item xs={12}>
                                        <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                            variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                    </Grid>
                                </Grid>
                            )}
                        </form>
                        :
                        <>Loading...</>
                    }

                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        </div >
    );
};

export default Create
