import React, { useEffect, useState } from 'react';
import { useAppSettings } from "../../../hooks/use-app-settings";
import moment from "moment";
import { Backdrop, Button, Card, CircularProgress, TextField } from "@mui/material";
import { PreQualifiers } from "../../../api/Endpoints/PreQualifiers";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Account } from "../../../api/Endpoints/Account";
import toast from "react-hot-toast";
import Box from '@mui/material/Box';
import Create from './create';
import BasicTabs from './TabMenus';
function Index() {


    const [accounts, setAccounts] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)

    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    // const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }
    const fetchClientAccounts = () => {
        setLoading(true);
        Account.get({ page: Number(pageNumber) + 1 }).then(response => {
            setAccounts(response.data.data);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })


    }

    const handleCreateNew = () => {

    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const viewTabs = () => {
        setTabs(true)
    }

    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const rows = [
        {
            id: 1, name: 'Seven Capital', client: "test client", project: 'seven capital',
            assign_to: "Sobha Sudeesh", title: "test"

        },

        // Add more rows as needed...
    ];
    const columns = [
        { field: 'name', headerName: 'Title', width: 100 },
        { field: 'client', headerName: 'Client', width: 150 },
        { field: 'project', headerName: 'Project Name', width: 200 },
        { field: 'assign_to', headerName: 'Assign To', width: 200 },
        {
            field: 'view',
            headerName: 'View',
            width: 150,
            renderCell: (params) => (
                <Button onClick={viewTabs}
                    variant="contained"
                    color="primary"
                    size='small'
                >
                    View
                </Button >
            ),
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    size='small'
                >
                    Edit
                </Button>
            ),
        },
        // Add more columns as needed...
    ];

    return (




        <>
            {Tabs ? (<BasicTabs setTabs={setTabs} />
            ) : (


                <>
                    <PageHeader title={'Designs'} onCreateNew={handleCreateNew} />

                    <Card sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'space-between' }} variant="outlined" >

                        <TextField
                            style={{ width: 300, marginRight: 10 }}
                            size='small'
                            id="outlined-name"
                            label="Search Designs"
                            onChange={handleSearchEntry}
                        />
                        <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />

                    </Card>

                    <Card sx={{ m: 2 }} variant="outlined">

                        <DataGrid
                            paginationMode="server"
                            rows={rows}
                            columns={columns}
                            page={pageNumber}
                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            onPageChange={handlePageChange}
                            autoHeight
                            density={'comfortable'}
                        />

                    </Card>

                </>
            )}

        </>
    )
}



export default Index


