import React, { useEffect, useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Task } from "../../../api/Endpoints/Task";
import toast from "react-hot-toast";
import SingleTask from "./singleTask";
import Create from "../tasks/create";
import { Waveform } from '@uiball/loaders'
const priority = [
    { id: 0, name: "Low", color: "rgb(87, 155, 252)" },
    { id: 1, name: "Critical", color: "#e00f0f" },
    { id: 2, name: "High", color: "#c73f1b" },
    { id: 3, name: "Medium", color: "rgb(85, 89, 223)" },
    { id: 4, name: "Low", color: "rgb(87, 155, 252)" },
]

const Tasks = (props) => {

    const [editId, setEditId] = useState(-1);
    const [refresh, setRefresh] = useState(0);
    const [loading, setLoading] = useState(false)
    const handleEdit = (id) => { setRefresh(Math.random); setEditId(parseInt(id)); }
    const [taskList, setTaskList] = useState([]);

    const fetchTaskList = async () => {
        setLoading(true)
        if (props.user_id) {
            let data;
            if (props.assignedToME) {
                data = { assigned_to: props.user_id };
                data.status = ["Not Started", "In progress", "Review pending",];
            } else if (props.asssignedByMe) {
                data = { assigned_by: props.user_id };
                data.status = ["Not started", "In progress", "Review pending", "In review",];
            } else if (props.pedndingReview) {
                data = { reviewer: props.user_id };
                data.status = ["Review pending", "In review"];
            } else if (props.subscribeByMe) {
                data = { subscription: props.user_id };
                data.status = ["Not Started", "In progress", "Review pending", "In review"];
            }

            // data = {};
            let list = await Task.get(data);
            if (list.data.status === "success") {
                setTaskList(list.data.data.data)
            } else {
                toast.error("Unable to fetch the tasks " + list.data?.message);
            }
        }
        setLoading(false)
    }


    const handleUpdate = () => {
        fetchTaskList();
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    useEffect(() => {
        fetchTaskList();
    }, [props.user_id])

    return (
        <>
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}>

                {props.title}
                <Create icon key={refresh}
                    onNew={handleCreate}
                    onUpdate={handleUpdate}
                    editId={editId}
                    setEditId={setEditId} />
            </div>


            {
                loading ?
                    <Box id='loader-circle'>

                        < Waveform
                            size={40}
                            lineWeight={3.5}
                            speed={1}
                            color="blue"
                        />
                    </Box >
                    : (

                        <Box component="span" sx={{ display: 'block', width: '100%' }}>
                            {taskList.length > 0 ?
                                <TableContainer component={Paper}>
                                    <Table className='ass-me-block' sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: '10%' }}>Project name</TableCell>
                                                <TableCell sx={{ width: '30%' }}>Task Title</TableCell>
                                                <TableCell sx={{ width: '10%' }}>Assigned to</TableCell>
                                                <TableCell sx={{ width: '10%' }}>Assigned by</TableCell>
                                                <TableCell sx={{ width: '10%' }}>Reviewer</TableCell>
                                                <TableCell sx={{ width: '10%' }}>Status</TableCell>
                                                <TableCell sx={{ width: '25%' }}>Due date</TableCell>
                                                <TableCell sx={{ width: '1%' }}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {taskList.map((obj) => {
                                                let className = priority.filter(ob => ob.id === obj.priority);
                                                return (<SingleTask key={obj.id} onUpdate={handleUpdate} dataSet={obj} cName={className} Refresh={fetchTaskList} />);
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <>No tasks are there</>
                            }
                        </Box>
                    )
            }
        </>
    );
};

export default Tasks;
