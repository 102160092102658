import React, { useEffect, useState } from 'react';
import { Button, DialogActions, Divider, Grid, InputLabel, Slide, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-hot-toast';
import { Task } from '../../../../api/Endpoints/Task';
import moment from 'moment';
import { DeleteForever, Edit } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core';
import Delete from '../../common/Popup/Delete';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const scheme = yup.object().shape({
    // name: yup.string().required(),
});


const AddNote = (props) => {

    const classes = useStyles();
    const [isReadMore, setIsReadMore] = useState(true);

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editValues, setEditValues] = useState({})
    const [refresh, setRefresh] = React.useState(false);
    const [Notes, setNotes] = useState([])
    const [deleteID, setDeleteID] = useState(false)
    const [editID, setEditID] = useState(0)
    const [noteID, setNoteID] = useState()
    const [expandedNotes, setExpandedNotes] = useState([]); 


    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchNotes = () => {
        setLoading(true)
        Task.getNote({ project_tasks_id: props.id }).then((notes) => {
            console.log(notes);
            if(notes?.data?.data?.data){
                setNotes(notes.data.data.data)
                setLoading(false)
            }
            setLoading(false)
        }).catch((error)=>{
            console.log(error);
            setLoading(false)
        })
    }



    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        props.setEditId()
        setOpen(false);
    };

    const onSubmit = (data) => {
        console.log(data);

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let dataToSubmit = {
            id: editID,
            project_tasks_id: props.id,
            note: data.note,
            // description: data.description
        }

        console.log(dataToSubmit);
        let action;

        if (editID > 0) {
            console.log(dataToSubmit);
            action = Task.updateNote(dataToSubmit)
        } else {
            console.log(dataToSubmit);
            action = Task.addNote(dataToSubmit)
        }
        action.then((response) => {
            console.log(response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true })
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false })
            toast.success(response.data.message)
            props.count()
            fetchNotes()
            setValue('note', '')
            // props.setTab(0)
            setEditID()
            // props.onUpdate()
            setOpen(false)
            setTimeout(() => { setAlerts({}) }, 2000)
            // fetchNotes()
        }).catch(errors => {
            console.log(errors);
            setOpen(false)
            toast.error("server error")
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    const fetchDetails = () => {
        setLoading(true)
        Task.getNoteDetails({ id: props.editId }).then((response) => {
            console.log(response);
            let data = response.data.data
            setValue('note', data.note)
            setLoading(false)
        })
    }

    const handleEdit = (data) => {
        setEditID(data?.id)
        setValue('note', data.note)
        setFormButtonStatus({ ...formButtonStatus, label: 'Edit' });
    }

    const deleteNote = (id) => {
        setDeleteID(id)
    }

    const handleCancelEdit = () => {
        setEditID(0)
        setValue('note', '')
        setFormButtonStatus({ ...formButtonStatus, label: 'Add' });
    }

    const deleteFunction = () => {
        fetchNotes()
        props?.count()
    }

    const toggleReadMore = (id) => {
        if (expandedNotes.includes(id)) {
            setExpandedNotes((prevExpandedNotes) =>
                prevExpandedNotes.filter((noteId) => noteId !== id)
            );
        } else {
            setExpandedNotes((prevExpandedNotes) => [...prevExpandedNotes, id]);
        }
    };

    const toggleReadLess = (id) => {
        setExpandedNotes((prevExpandedNotes) =>
            prevExpandedNotes.filter((noteId) => noteId !== id)
        );
    };

    useEffect(() => {
        fetchNotes()
    }, [])


    useEffect(() => {
        // if (parseInt(props.editId) > 0) {
        //     fetchDetails()
        //     setOpen(true);
        // } else if (Number(props.editId) === 0) {
        // setOpen(true);
        // }

    }, [])

    return (
        <div>

            {
                deleteID &&
                <Delete
                    ID={deleteID}
                    setID={setDeleteID}
                    setDeletePopup={setDeleteID}
                    Callfunc={() => deleteFunction()}
                    url="/projects/tasks/notes/delete?id="
                    title="Note"
                />
            }



            <form onSubmit={handleSubmit(onSubmit)}>

                <ErrorMessage
                    errors={errors}
                    name="multipleErrorInput"
                    render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                            <p key={type}>{message}</p>
                        ))
                    }
                />
                {
                    editID ? <Grid mb={1}><a style={{ fontSize: '14px', color: 'blue' }}>You are editing a Note, <span onClick={handleCancelEdit} style={{ textDecoration: 'underline', color: 'red', cursor: 'pointer', fontSize: '14px' }}>Click</span> to cancel</a></Grid>
                        : ''
                }

                <Grid sx={{}} item xs={12}>
                    {/* <InputLabel sx={{
                        color: "black",
                        pb: 1
                    }}>Add Note </InputLabel> */}
                    <TextField
                        placeholder='Add Note'
                        {...register('note')}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        sx={{ width: '100%', }}
                        required
                    />
                </Grid>

                <Grid sx={{ pt: 2, pb: 2 }} item xs={12}>
                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                </Grid>
            </form>


            <Divider />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    {/* {Notes?.length > 0 && <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem', marginTop: 2 }}>
                        Notes:
                    </Typography>} */}
                    {Notes &&
                        Notes.map((note) => (
                            <>
                                <Typography variant="body2" style={{ paddingTop: 2, fontSize: '16px', whiteSpace: 'pre-line' }}>
                                    {/* <Grid display={'flex'} justifyContent={'space-between'} xs={8}>
                                        <a style={{ fontSize: 13, color: 'grey' }}>Date: {moment(note.created_at).format('DD-MM-YYYY')}</a>
                                        <a style={{ fontSize: 13, color: 'grey' }}>Added By: {note.created_user?.name}</a>
                                    </Grid> */}
                                    <a style={{}} className="text">
                                        {note.note.length <= 140 ? (
                                            <a style={{ fontWeight: 400 }}>{note.note}</a>
                                        ) : (
                                            expandedNotes.includes(note.id) ? (
                                                <div>
                                                    <a>{note.note}</a>
                                                    <a onClick={() => toggleReadLess(note?.id)} style={{ color: 'blue', cursor: 'pointer' }}>  read less</a>
                                                </div>
                                            ) : (
                                                <div>
                                                    <a>{note.note.slice(0, 140)}</a>
                                                    <a onClick={() => toggleReadMore(note?.id)} style={{ color: 'blue', cursor: 'pointer' }}> ...read more</a>
                                                </div>
                                            )
                                        )}
                                    </a>

                                    <Grid display={'flex'} justifyContent={'space-between'} mt={1}>
                                        <Grid display={'flex'} justifyContent={'space-between'} xs={6}>
                                            <a style={{ fontSize: 13, color: 'grey' }}>Date: {moment(note.created_at).format('DD-MM-YYYY')}</a>
                                            <a style={{ fontSize: 13, color: 'grey' }}>Added By: {note.created_user?.name}</a>
                                        </Grid>
                                        <Grid display={'flex'} justifyContent={'end'}>
                                            <Button onClick={() => handleEdit(note)}>
                                                <Edit fontSize='small' />
                                            </Button>
                                            <LoadingButton onClick={() => deleteNote(note.id)}>
                                                <DeleteForever color='error' fontSize='small' />
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Divider className={classes.divider} />
                            </>
                        ))}
                </Grid>
            </Grid>

            {/* <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions> */}

        </div>
    )
}

export default AddNote;
