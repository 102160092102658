import React from 'react'
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    Select,
    Slide, TextField, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-hot-toast';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { LoadingButton } from "@mui/lab";
import { MeetingIdContext } from '../../../../../contexts/MeetingID/meetingID';
import { Meetings } from '../../../../../api/Endpoints/Meeting';
import Delete from '../../../common/Popup/Delete';
import TextInput from '../../../../form/TextInput';
import { VideoShootApi } from '../../../../../api/Endpoints/VideoShoot';
function VideoShootGuests({ fetchList, list, id }) {
    console.log(list);
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({});
    const { MeetingID } = MeetingIdContext();
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [editID, setEditID] = useState(0)

    const [ID, setID] = useState(0)
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.row?.guest?.name}</Button >
                    </>
                );
            },
        },

        {
            field: 'phone',
            headerName: 'Phone',
            width: 130,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small">{params.row?.guest?.phone_number}</Button >
                    </>
                );
            },
        },
        {
            field: 'role',
            headerName: 'Role',
            width: 200,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small">{params.row?.guest?.role}</Button >
                    </>
                );
            },
        },
        {
            field: 'edit', headerName: '', width: 50, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <>
                    <a style={{ color: 'black',cursor:'pointer' }}  onClick={() => handleEdit(params?.row?.guest,params?.row?.id)}>
                        <EditOutlined fontSize={'small'} />
                    </a>
                </>;
            }

        },
        {
            field: 'action', headerName: '', width: 50, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <>
                    <a style={{ color: 'black',cursor:'pointer' }} onClick={() => handleDeleteAlert(params?.row?.id)}>
                        <DeleteOutline fontSize={'small'} />
                    </a>
                </>;
            }

        },
    ]
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "ADD",
        loading: false,
        disabled: false,
    });

    const handleEdit = (data,id) => {
        setEditID(id)
        setValue('client_user_name', data.name)
        setValue('role', data.role)
        setValue('phone_number', data.phone_number)
        setFormButtonStatus({ ...formButtonStatus, label: 'Edit' });
    }

    const handleCancelEdit = () => {
        setEditID(0)
        setValue('client_user_name', null)
        setValue('role', null)
        setValue('phone_number', null)
        setFormButtonStatus({ ...formButtonStatus, label: 'Add' });
    }

    const onSubmit = async (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: false });

        const dataToSubmit = {
            attendee_type: 'Guest',
            id: editID,
            project_video_shoots_id: id,
            guest_name: data.client_user_name,
            guest_role: data.role,
            guest_phone: data?.phone_number
        };

        let action;

        if (editID> 0) {
            action = VideoShootApi.updateAttendee(dataToSubmit)
        } else {
            action = VideoShootApi.addAttendee(dataToSubmit)
        }

        action.then((response) => {
            console.log("respponser here", response);
            if (response?.data?.status == "success") {

                fetchList().then(() => {
                    toast.success(response?.data?.message)
                })

                setValue('client_user_name', null)
                setValue('role', null)
                setValue('phone_number', null)
                setEditID(0)
            }

            setFormButtonStatus({ ...formButtonStatus,label: 'Add', loading: false, disabled: false });
        }).catch((error) => {
            console.log(error);
            setFormButtonStatus({ ...formButtonStatus,label: 'Add', loading: false, disabled: false });
        })
    }

    const handleDeleteAlert = (id) => {
        setID(id)
        setDeletePopup(true)
    }


    return (

        <div>
            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                Callfunc={() => fetchList()}
                url="/projects/video-shoots/attendees/delete?id="
                title="Attendee" />}

            <Grid container mt={0.5} mb={1} spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextInput
                        control={control}
                        name="client_user_name"
                        // label="Name"
                        value={watch('client_user_name')}
                        placeholder={'Name'}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput
                        control={control}
                        name="phone_number"
                        // label="Phone"
                        placeholder={'Phone Number'}
                        value={watch('phone_number')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput
                        control={control}
                        name="role"
                        // label="Phone"
                        placeholder={'Role'}
                        value={watch('role')}
                    />
                </Grid>
                <Grid display={'flex'} item xs={12} sm={6}>
                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                        onClick={handleSubmit(onSubmit)}
                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                {editID ? <Grid ml={1}><a style={{ fontSize: '14px', color: 'blue' }}><span onClick={handleCancelEdit} style={{ textDecoration: 'underline', color: 'red', cursor: 'pointer', fontSize: '14px' }}>Click</span> to cancel Edit</a></Grid> : ''}
                </Grid>

            </Grid>
            <div style={{ height: 500 }}>
                {typeof list === "object" &&
                    <DataGrid
                        paginationMode="server"
                        rows={list}
                        columns={columns}

                        pageSize={10}
                        rowHeight={20}
                        rowsPerPageOptions={[10]}
                        rowCount={list.total}

                        autoHeight={false}
                        density={"comfortable"}
                    />
                }
            </div>
        </div>
    )
}

export default VideoShootGuests 
