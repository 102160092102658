import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    TextareaAutosize,
    Select,
    TextField,
    Slide, Typography
} from "@mui/material";
import { TimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/lab';
import * as yup from "yup";
import dayjs from 'dayjs';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../form/TextInput";
import { Project } from "../../../api/Endpoints/Project";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import { Employee } from "../../../api/Endpoints/Employee";
import { WorkRequest } from '../../../api/Endpoints/WorkRequest';
import { Account } from '../../../api/Endpoints/Account';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({


    account_name: yup.string().required("Accout name is requried"),
    company_name: yup.string().required("Company name is requried"),

})




const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = React.useState(false);
    const handleClickOpen = () => { props.onNew(); };

    const handleClose = () => {
        props.setEditId(undefined)
        setOpen(false);
    };

    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })




    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })


        let dataToSubmit = {
            id: props.editId,
            account_name: data.account_name,
            company_name: data.company_name,
            address: data.address,
            remarks: data.remarks,

        };

        console.log(dataToSubmit);
        let action;

        if (props.editId > 0) {
            console.log("dataToSubmit", dataToSubmit);
            action = Account.update(dataToSubmit);
        } else {
            action = Account.add(dataToSubmit);
        }
        action.then(response => {
            console.log(response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }



    const fetchAccountRequestDetails = async () => {
        setLoading(true)
        const response = await Account.getDetails({ account_id: props.editId })
        setLoading(false)
        if (response.data.status === "success") {
            const data = response.data.data
            console.log(data);
            setValue('account_name', data.account_name)
            setValue('company_name', data.company_name)
            setValue('address', data?.address)
            setValue('remarks', data?.remarks)
        }

    };


    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('projects_id')])



    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            console.log("this is editi id");
            fetchAccountRequestDetails();
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            console.log("this is edit id", props.editId);
            setOpen(true);
        }

    }, [props.editId])



    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Add New"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Client" : "Create Client"}</DialogTitle>
                <DialogContent>
                    {!loading ?
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="account_name"
                                    label="Account Name"
                                    value={watch('account_name')} />
                            </Grid>
                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="company_name"
                                    label="Company Name"
                                    value={watch('company_name')} />
                            </Grid>

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="address"
                                    label="Address"
                                    value={watch('address')} />
                            </Grid>
                            <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1
                                }}>Remarks </InputLabel>
                                <TextField
                                    {...register('remarks')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    sx={{ width: '100%', }}

                                />
                            </Grid>

                            <Grid sx={{ p: 1,mt:1 }} mb={5} item xs={12}>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            </Grid>

                        </form>
                        :
                        <>Loading...</>
                    }
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        </div >
    );
};

export default Create