import React, { useEffect, useState } from 'react';
import { Modal, TextField, Button, Box, Typography, InputLabel, Grid } from '@mui/material';
import Select from 'react-select';
import DynamicChip from '../../../../utils/DynamicChip';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import ReactSelector from 'react-select';
import TextInput from '../../../form/TextInput';
import DateInput from '../../../form/DateInput';
import SelectX from '../../../form/SelectX';
import { Employee } from '../../../../api/Endpoints/Employee';
import { toast } from 'react-hot-toast';
import { WorkRequest } from '../../../../api/Endpoints/WorkRequest';
import { LoadingButton } from '@mui/lab';
import BasicDateTimePicker from '../../../form/DateTime';
import TimeInput from '../../../form/DateTime';
import moment from 'moment';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const scheme = yup.object().shape({
    remarks: yup.string()

})

function AcceptPopup({ openAcceptpopup, setOpenAcceptPopup, onUpdate, details }) {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedOption, setSelectedOption] = useState('');
    const [selectStatus, setSelectStatus] = useState()
    const [selectCostOption, setSelectCostOption] = useState()

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const handleClose = () => {
        setOpenAcceptPopup(false);
    };
    console.log("This is selected option", selectedOption);

    const fetchEmployees = (e) => {
        // let project = watch('projects_id');
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const options = [
        { value: 'No Cost', label: 'No Cost', id: 1 },
        { value: 'Fixed Cost', label: 'Fixed Cost', id: 2 },
        { value: 'Hourly Cost', label: 'Hourly Cost', id: 3 },
    ];
    const priorityOptions = [
        {
            value: "Low", label: "Low"
        },
        {
            value: "Medium", label: "Medium"
        },
        {
            value: "High", label: "High"
        },
        {
            value: "Urgent", label: "Urgent"
        },
        { value: "Critical", label: "Critical" },
    ];

    const Status = [
        { id: 1, name: "ACCEPT" },
        { id: 2, name: "REJECT" },
        { id: 3, name: "NEEDS DISCUSSION" },
        { id: 4, name: "ON HOLD" },
    ]

    const handleStatusChange = (id) => {
        console.log(id);
        setSelectStatus(id);
    }
    const handleCostOptionChange = (id) => {
        console.log(id);
        setSelectCostOption(id)
    }
    console.log(openAcceptpopup);

    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let status = Status.find((item) => item.id === selectStatus)
        console.log(status);



        let action;


        if (selectStatus == 4) {
            let dataToSubmit = {
                id: openAcceptpopup,
                status: "On Hold",
                remarks_for_team: data.on_hold_remarks
            };
            console.log(dataToSubmit);
            action = WorkRequest.accept(dataToSubmit);
            console.log('working');
        }

        if (selectStatus == 3) {
            let dataToSubmit = {
                id: openAcceptpopup,
                status: "NEEDS DISCUSSION",
                remarks_for_team: data.discussion_remarks
            };
            console.log(dataToSubmit);
            action = WorkRequest.accept(dataToSubmit);
            console.log('working');
        }

        if (selectStatus == 2) {
            let dataToSubmit = {
                id: openAcceptpopup,
                status: "Rejected",
                remarks_for_team: data.reject_remarks
            };
            console.log(dataToSubmit);
            action = WorkRequest.accept(dataToSubmit);
            console.log('working');
        }

        if (selectStatus == 1) {

            if (selectCostOption == 1) {
                let dataToSubmit = {
                    id: openAcceptpopup,
                    status: "Accepted",
                    cost_model: "No Cost",
                    "remarks_for_team": data.no_cost_remarks
                }
                action = WorkRequest.accept(dataToSubmit)
            }
            if (selectCostOption == 2) {
                let date = moment(data.fixed_cost_due_date).format('YYYY-MM-DD');
                console.log(date);

                let dataToSubmit = {
                    id: openAcceptpopup,
                    status: "Accepted",
                    cost_model: "Fixed Cost",
                    fixed_cost: data.fixed_cost,
                    assign_to: data.assigned_to_id?.id,
                    remarks_for_team: data.fixed_cost_remarks,
                    expected_completion_date: date
                }
                action = WorkRequest.accept(dataToSubmit)
            }
            if (selectCostOption == 3) {
                let date = moment(data.hourly_cost_due_date).format('YYYY-MM-DD');
                let dataToSubmit = {
                    id: openAcceptpopup,
                    status: "Accepted",
                    cost_model: "Hourly Cost",
                    hourly_cost: data.hourly_cost,
                    estimated_hours: data.estimated_hours,
                    remarks_for_team: data.hourly_cost_remarks,
                    expected_completion_date: date
                }
                action = WorkRequest.accept(dataToSubmit)
            }
        }

        action.then(response => {
            console.log('worked');
            console.log(response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            onUpdate();
            handleClose()
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    useEffect(() => {
        console.log(details);
        if (details.cost_model) {
            if (details.status == "Accepted" || details.status == "Assigned To Client") {
                console.log('hello');
                let costId = options.find(item => item.label == details.cost_model)
                console.log(costId)
                setSelectStatus(1)
                setSelectCostOption(costId.id)
                setValue('no_cost_remarks', details.remarks_for_client ? details.remarks_for_client : '')
                setValue('fixed_cost_remarks', details.remarks_for_client ? details.remarks_for_client : '')
                setValue('hourly_cost_remarks', details.remarks_for_client ? details.remarks_for_client : '')
                setValue('on_hold_remarks', details.remarks_for_client ? details.remarks_for_client : '')
                setValue('fixed_cost', details.fixed_cost ? details.fixed_cost : '')
                setValue('hourly_cost', details.hourly_cost ? details.hourly_cost : '')
                setValue('hourly_cost_due_date', details.expected_completion_date ? details.expected_completion_date : '')
                setValue('fixed_cost_due_date', details.expected_completion_date ? details.expected_completion_date : '')
                setValue('estimated_hours', details.estimated_hours ? details.estimated_hours : '')
            }
        }
    }, [])


    return (
        <div>
            <Modal
                open={openAcceptpopup}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={style}>
                        <Grid sx={{ p: 1 }} item xs={12}>
                            <Typography variant={"subtitle2"}>Select Option</Typography>
                            {Status.map(obj => {
                                return <DynamicChip color='primary' key={obj.id} name={obj.name} id={obj.id} active={selectStatus} onChipCLick={handleStatusChange} />
                            })}
                        </Grid>

                        {
                            selectStatus === 1 &&
                            <>
                                <Grid sx={{ p: 1, mt: 2 }} item xs={12}>
                                    <Typography variant={"subtitle2"}>Select Cost Option</Typography>
                                    {options.map(obj => {
                                        return <DynamicChip key={obj.id} name={obj.label} id={obj.id} active={selectCostOption} onChipCLick={handleCostOptionChange} />
                                    })}
                                </Grid>
                                {/* <Grid container spacing={1} sx={{ p: 1, mt: 1 }}>
                                    <Grid item xs={6}>
                                        <InputLabel sx={{
                                            color: "black",
                                            pb: 1,
                                        }}>Select Cost Options</InputLabel>
                                        <ReactSelector
                                            styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                            options={options}
                                            value={
                                                options.filter(option =>
                                                    option.label === watch('cost_option'))
                                            }
                                            name='cost_option'
                                            isClearable={true}
                                            defaultValue={(watch('cost_option'))}
                                            onChange={(selectedOption) => setValue('cost_option', selectedOption?.value || "")}
                                        />
                                    </Grid>
                                </Grid> */}

                                {
                                    selectCostOption == 1 &&
                                    // watch('cost_option') == "No Cost" &&
                                    <>
                                        {/* <Grid container spacing={1} sx={{ p: 1, }}>
                                            <Grid item xs={6}>
                                                <SelectX
                                                    // key={refresh * 0.2}
                                                    label={"Assign to"}
                                                    options={fetchEmployees}
                                                    control={control}
                                                    // error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                                    // error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                                    name={'assigned_to_id'}
                                                    defaultValue={watch('assigned_to_id')}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <InputLabel sx={{
                                                    color: "black",
                                                    pb: .5,
                                                    fontSize: '14px',
                                                    fontWeight: 500
                                                }}>Priority</InputLabel>
                                                <ReactSelector
                                                    styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                                    options={priorityOptions}
                                                    value={
                                                        priorityOptions.filter(priorityop =>
                                                            priorityop.label === watch('priority'))

                                                    }
                                                    name='priority'
                                                    isClearable={true}
                                                    defaultValue={(watch('priority'))}
                                                    onChange={(selectedOption) => setValue('priority', selectedOption.value)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1} sx={{ p: 1, }}>
                                            <Grid item xs={6}>
                                                <DateInput
                                                    control={control}
                                                    name="no_cost_due_date"
                                                    label="Due Date"
                                                    value={watch('no_cost_due_date')}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextInput
                                                    control={control}
                                                    name="no_cost_hours"
                                                    label="Expected Hours"
                                                    value={watch('no_cost_hours')}
                                                />
                                            </Grid>
                                        </Grid> */}

                                        <Box p={1}>
                                            <TextField
                                                {...register('no_cost_remarks')}
                                                label="Remarks"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                // required
                                            />
                                        </Box>
                                    </>
                                }
                                {
                                    selectCostOption == 2 &&
                                    // watch('cost_option') == "Fixed Cost" &&
                                    <>
                                        {/* <Grid container spacing={1} sx={{ p: 1, }}>
                                            <Grid item xs={6}>
                                                <SelectX
                                                    // key={refresh * 0.2}
                                                    label={"Assign to"}
                                                    options={fetchEmployees}
                                                    control={control}
                                                    // error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                                    // error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                                    name={'assigned_to_id'}
                                                    defaultValue={watch('assigned_to_id')}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <InputLabel sx={{
                                                    color: "black",
                                                    pb: .5,
                                                    fontSize: '14px',
                                                    fontWeight: 500
                                                }}>Priority</InputLabel>
                                                <ReactSelector
                                                    styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                                    options={priorityOptions}
                                                    value={
                                                        priorityOptions.filter(priorityop =>
                                                            priorityop.label === watch('priority'))

                                                    }
                                                    name='priority'
                                                    isClearable={true}
                                                    defaultValue={(watch('priority'))}
                                                    onChange={(selectedOption) => setValue('priority', selectedOption.value)}
                                                />
                                            </Grid>
                                        </Grid> */}

                                        <Grid container spacing={1} sx={{ p: 1 }}>

                                            <Grid item xs={6}>
                                                <TextInput
                                                    control={control}
                                                    name="fixed_cost"
                                                    label="Fixed Cost"
                                                    value={watch('fixed_cost')}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DateInput
                                                    control={control}
                                                    name="fixed_cost_due_date"
                                                    label="Due Date"
                                                    value={watch('fixed_cost_due_date')}
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* <Grid container spacing={1} sx={{ p: 1, }}>
                                                <Grid item xs={6}>
                                                    <TextInput
                                                        control={control}
                                                        name="fixed_cost_hours"
                                                        label="Expected Hours"
                                                        value={watch('fixed_cost_hours')}
                                                    />
                                                </Grid>

                                            </Grid> */}

                                        <Box p={1}>
                                            <TextField
                                                {...register('fixed_cost_remarks')}
                                                label="Remarks"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                // required
                                            />
                                        </Box>
                                    </>
                                }
                                {
                                    selectCostOption == 3 &&
                                    // watch('cost_option') == "Hourly Cost" &&
                                    <>

                                        <Grid container spacing={1} sx={{ p: 1, }}>

                                            <Grid item xs={6}>
                                                <TextInput
                                                    control={control}
                                                    name="hourly_cost"
                                                    label="Hourly Cost"
                                                    value={watch('hourly_cost')}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TimeInput
                                                    name="estimated_hours"
                                                    control={control}
                                                    label="Estimated Hours"
                                                    defaultValue={watch('estimated_hours')}
                                                />
                                                {/* <TextInput
                                                    control={control}
                                                    name="estimated_hours"
                                                    label="Expected Hours"
                                                    value={watch('estimated_hours')}
                                                /> */}
                                            </Grid>

                                        </Grid>

                                        <Grid container spacing={1} sx={{ p: 1, }}>
                                            <Grid item xs={6}>
                                                <DateInput
                                                    control={control}
                                                    name="hourly_cost_due_date"
                                                    label="Due Date"
                                                    value={watch('hourly_cost_due_date')}
                                                />
                                            </Grid>
                                        </Grid>


                                        <Box p={1}>
                                            <TextField
                                                {...register('hourly_cost_remarks')}
                                                label="Remarks"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                // required
                                            />
                                        </Box>
                                    </>
                                }
                            </>
                        }




                        {/* {selectedOption.label === 'Hourly Cost' && (
                            <Box mt={2}>
                                <TextField
                                    label="Hourly Rate"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Box>
                        )} */}

                        {selectStatus == 2 && (
                            < >
                                <Box mt={2} p={1}>
                                    <TextField
                                        {...register('reject_remarks')}
                                        label="Remarks"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        // required
                                    />
                                </Box>
                            </>
                        )}
                        {selectStatus == 3 && (
                            < >
                                <Box mt={2} p={1}>
                                    <TextField
                                        {...register('discussion_remarks')}
                                        label="Remarks"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        // required
                                    />
                                </Box>
                            </>
                        )}
                        {selectStatus == 4 && (
                            < >
                                <Box mt={2} p={1}>
                                    <TextField
                                        {...register('on_hold_remarks')}
                                        label="Remarks"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        required
                                    />
                                </Box>
                            </>
                        )}

                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 2, mr: 2,
                                    background: "gray",
                                    '&:hover': {
                                        backgroundColor: 'gray',
                                    },
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>

                            {
                                (details.status !== "Accepted" && details.status !== "Assigned To Client") &&
                                <LoadingButton sx={{ height: 40, marginTop: 2 }} loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            }
                        </div>
                    </Box>
                </form>
            </Modal>
        </div >
    );
}

export default AcceptPopup;
