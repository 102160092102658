import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  CircularProgress,
  Box,
} from "@mui/material";
import { Employee } from "../../../../api/Endpoints/Employee";
import moment from "moment";
import LoadingScreen from "../../../form/progress";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: "1px 2px 2px 2px rgba(0, 0, 0, 0.3)",
  },
  title: {
    fontWeight: "bold",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  select: {
    minWidth: 200,
  },
}));

const Details = ({ EmployeeID }) => {
  const classes = useStyles();
  const [Loading, setLoading] = useState(false);
  const [Data, setData] = useState();

  const fetchDetails = () => {
    setLoading(true);

    Employee.getEmployeeDetails({ id: EmployeeID }).then((response) => {
      // console.log(response);
      setData(response.data.data);
      setLoading(false);
    });
  };

  console.log("this is employee data", Data);
  useEffect(() => {
    fetchDetails();
  }, []);
  if (Loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 200,
        }}
      >
        <CircularProgress size={33} />
        <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
          Loading...
        </Typography>
      </Box>
    );
  }
  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Typography
              variant="body1"
              component="h2"
              style={{ fontWeight: "bold", fontSize: "1.1rem" }}
            >
              Name:
              <Typography
                variant="body2"
                style={{ display: "inline", paddingLeft: 10, fontSize: "16px" }}
              >
                {Data?.user?.name}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography
              variant="body1"
              component="h2"
              style={{ fontWeight: "bold", fontSize: "1.1rem" }}
            >
              Email:
              <Typography
                variant="body2"
                style={{ display: "inline", paddingLeft: 10, fontSize: "16px" }}
              >
                {Data?.user?.email}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} sx={{ pt: 4 }} />
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} mt={2}>
            <Typography
              variant="body1"
              component="h2"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              Department:
              <Typography
                variant="body2"
                style={{ display: "inline", paddingLeft: 10, fontSize: "16px" }}
              >
                {Data?.department?.name}
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} mt={2}>
            <Typography
              variant="body1"
              component="h2"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              Role :
              <Typography
                variant="body2"
                style={{ display: "inline", paddingLeft: 10, fontSize: "16px" }}
              >
                {Data?.role?.name}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} sx={{ pt: 4 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} mt={2}>
            <Typography
              variant="body1"
              component="h2"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              Phone :
              <Typography
                variant="body2"
                style={{ display: "inline", paddingLeft: 10, fontSize: "16px" }}
              >
                {Data?.phone_number}
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} mt={2}>
            <Typography
              variant="body1"
              component="h2"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              Emergency Contact :
              <Typography
                variant="body2"
                style={{ display: "inline", paddingLeft: 10, fontSize: "16px" }}
              >
                {Data?.emergency_contacts}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        {/* //comment */}
        <Divider className={classes.divider} sx={{ pt: 4 }} />
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} mt={2}>
            <Typography
              variant="body1"
              component="h2"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              Joining Date:
              <Typography
                variant="body2"
                style={{ display: "inline", paddingLeft: 10, fontSize: "16px" }}
              >
                {moment(Data?.joining_date).format("DD - MM - YYYY")}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} mt={2}>
            <Typography
              variant="body1"
              component="h2"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              Manager:
              <Typography
                variant="body2"
                style={{ display: "inline", paddingLeft: 10, fontSize: "16px" }}
              >
                {Data?.user?.manager?.name}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        {/* <Divider className={classes.divider} sx={{ pt: 4 }} />
                <Grid item xs={12} sm={12} mt={2}>
                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                        Remarks :
                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat veritatis harum repellendus reiciendis nisi nihil suscipit. Sit cumque, deserunt at unde corporis odio facilis, impedit reiciendis quam, debitis molestias eius harum modi iste optio doloremque neque recusandae placeat totam tempore!

                        </Typography>

                    </Typography>


                </Grid> */}
      </CardContent>
    </Card>
  );
};

export default Details;
