import { get, post } from './../Config/config';

export const WorkPlan = {
    get: (data) => get(`/projects/plans?projects_id=`, {params:data}),
    add: (data) => post('/projects/plans/store', data),
    getDetails: (data) => get(`/projects/plans/view?id=`, {params:data}),
    update: (data) => post(`/projects/plans/update`,data),
    remove: (data) => get(`/projects/plans/delete?id=`,{params:data})
}

