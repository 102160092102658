import { get, post } from './../Config/config';


export const WebsitePages = {

    get: (data) => get('/projects/websites/pages?project_website_id=', { params: data }),
    add: (data) => post('/projects/websites/pages/store', data),
    update: (data) => post('/projects/websites/pages/update', data),
    getDetails: (data) => get('/projects/websites/pages/view?id=', { params: data }),
    delete: (data) => get('/projects/websites/pages/delete?id=', { params: data }),
}
