import { get, post } from './../Config/config';


export const Leave_Eligibility = {

    get: (data) => get('/employees/available-leaves?users_id=', { params: data }),
    // add: (data) => post('/websites/store', data),
    // update: (data) => post('/websites/update', data),
    // getDetails: (data) => get('/websites/view?id=', { params: data }),
    // delete: (data) => get('websites/delete?id=', { params: data }),
}
 