import { get, post } from './../Config/config';

export const WorkRequest = {
    get: (data) => get('/projects/work-requests', { params: data }),
    add: (data) => post('/projects/work-requests/store', data),
    getWorkRequestDetails: (data) => get(`/projects/work-requests/view?`, { params: data }),
    update: (data) => post('/projects/work-requests/update', data),

    // AssignTo,reject,accept
    assign: (data) => post('/projects/work-requests/assign', data),
    reject: (data) => post('/projects/work-requests/reject', data),
    accept: (data) => post('/projects/work-requests/accept', data),
    needs_discussion: (data) => post('/projects/work-requests/need-discussion', data),

    // Notes
    getNote: (data) => get('projects/work-requests/notes?project_work_requests_id=', { params: data }),
    addNote: (data) => post('/projects/work-requests/notes/store', data),
    getNoteDetails: (data) => get(`/projects/work-requests/notes/view?id=`, { params: data }),
    updateNote: (data) => post('/projects/work-requests/notes/update', data),
    deletetNote: (data) => get('/projects/work-requests/notes/delete?id=', { params: data }),

    // WorkStatus
    addWorkStatus: (data) => post('/projects/work-requests/change-work-status', data),
    addReviewStatus: (data) => post('/projects/work-requests/change-review-status', data),

    
}
