import { CircularProgress, Grid, TablePagination, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Create from '../../../../../tasks/create'
import { Task } from '../../../../../../../api/Endpoints/Task';
import moment from 'moment';
import { Waveform } from '@uiball/loaders';
import { Edit } from '@mui/icons-material';
import BasicTabs from '../../../../../tasks/TabMenus';

function ItemTasks({ postID,project }) {

    const [detailViewId, setDetailViewId] = useState(false)
    const [pageNumber, setPageNumber] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [editId, setEditId] = useState();
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)

    const [limit, setlimit] = useState(10)
    const [total, setTotal] = useState()
    const [scrolLoading, setscrolLoading] = useState(false)

    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            // You can adjust the value (10) based on your preference for when to trigger the function
            // Call your function here when scrolling to the bottom
            setlimit(limit + 10)
        }
    };

    useEffect(() => {
        if (limit > 10 && total !== list?.data?.length) {
            scrollFunction();
        }
    }, [limit])

    const scrollFunction = () => {
        setscrolLoading(true);
        Task.get({ page: parseInt(pageNumber) + 1, related_type: 'creative_calendar_post', related_id: postID, limit: limit }).then(response => {
            console.log(response.data);
            setList(response.data);
            setTotal(response?.data?.meta?.total)
            setscrolLoading(false);
        }).catch((error) => {
            setscrolLoading(false)
        })
    }

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    const handleEdit = (id) => {
        setEditId(id)
    }

    const handleChartClose = () => {

    }

    const handleChangePage = (event, newPage) => {
        setPageNumber(newPage);
    };

    const fetchTable = () => {
        setLoading(true)
        Task.get({ page: parseInt(pageNumber) + 1, related_type: 'creative_calendar_post', related_id: postID }).then(response => {
            console.log(response.data.data);
            setList(response.data?.data);
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchTable()
    }, [postID])



    return (


        <>
            {
                detailViewId &&
                <BasicTabs fetch={fetchTable} id={detailViewId} handleClose={setDetailViewId} handleEdit={handleEdit} setChartOpen={handleChartClose} />
            }
            {
                loading ?
                    (
                        <Grid mt={'40%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Waveform
                                size={40}
                                lineWeight={4}
                                speed={1}
                                color="black"
                            />
                        </Grid>
                    ) : (


                        <Grid container>
                            <Grid item md={12} p={1.5} display={'flex'} justifyContent={'end'}>
                                {/* <Grid display={'flex'} alignItems={'center'}>
                        <a style={{ fontWeight: 600 }}>TASKS</a>
                    </Grid> */}
                                <Grid>
                                    <Create calendarProject={project} postID={postID} setDetailViewId={setDetailViewId} detailViewId={detailViewId} fetchTable={fetchTable} key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />
                                </Grid>
                            </Grid>
                            <Grid md={12} style={{ height: '59vh', maxHeight: '59vh', overflowY: 'auto' }}>
                                {
                                    list?.data?.length > 0 ? (
                                    <>
                                           { list?.data?.map((obj, index) => (
                                                <Grid key={index} item style={{ borderBottom: '1px solid #e8e8e8' }} md={12}>
                                                    <Grid p={1} container >
                                                        <Grid item md={12} display={'flex'} justifyContent={'space-between'}>
                                                            <Grid item md={11}>
                                                                {
                                                                    obj?.title?.length > 45 ?
                                                                        <Tooltip title={obj?.title}>
                                                                            <a onClick={() => setDetailViewId(obj?.id)} style={{ fontSize: '17px', cursor: 'pointer' }}>
                                                                                {obj?.title?.slice(0, 45)}...
                                                                            </a>
                                                                        </Tooltip>
                                                                        :
                                                                        <a onClick={() => setDetailViewId(obj?.id)} style={{ fontSize: '17px', cursor: 'pointer' }}>
                                                                            {obj?.title}
                                                                        </a>
                                                                }
                                                            </Grid>
                                                            <Grid item md={1}>
                                                                <a onClick={() => handleEdit(obj?.id)}>
                                                                    <Edit style={{ cursor: 'pointer', color: 'blue', fontSize: '13px' }} fontSize='small' />
                                                                </a>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item display={'flex'} md={12}>
                                                            <Grid item md={5}>
                                                                <a style={{ fontSize: '13px', color: 'grey' }}>Assigned to: {obj?.assigned_to_user?.name}</a>
                                                            </Grid>
                                                            <Grid item md={3}>
                                                                <a style={{ fontSize: '13px', color: 'grey' }}>due: {moment(obj?.due_date).format('DD-MM-YYYY')}</a>
                                                            </Grid>
                                                            <Grid item md={4}>
                                                                <a style={{ fontSize: '13px', color: 'grey' }}>Status: {obj?.status}</a>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
    
                                                </Grid>
                                            ))}
                                            {
                                            scrolLoading &&
                                            <Grid item md={12} display={'flex'} justifyContent={'center'}>
                                                <CircularProgress />
                                            </Grid>
                                        }
                                    </>
                                    ) : <Grid md={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                        <a> No Task Found</a>
                                    </Grid>
                                }
                            </Grid>

                        </Grid>
                    )}
        </>
    )
}

export default ItemTasks
