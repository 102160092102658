import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Card, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import TextInput from '../../../form/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { ContentCopy, Edit } from '@mui/icons-material';
import { Activities } from '../../../../api/Endpoints/Activities';
import Create from '../../activities/create';
import toast from 'react-hot-toast';
import SelectX from '../../../form/SelectX';
import { Project } from '../../../../api/Endpoints/Project';
import { LoadingButton } from '@mui/lab';
import { UserIdContext } from '../../../../contexts/UserID/UserID';
import { WorkPlan } from '../../../../api/Endpoints/WorkPlan';

const useStyles = makeStyles({
    customTextField: {
        height: '10px', // Adjust the height as needed
    },
});

const WorkPlanSignOut = (props) => {

    const classes = useStyles();

    const validationSchema = yup.object().shape({
        workplan: yup.string().required('workplan is required'),
        // hours: yup.number(),
        // hours: yup.number(),
        // Add validation rules for other fields if needed
    });
    const formRef = useRef();
    const { UserID } = UserIdContext()
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) });
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [ClientID, setClientID] = useState(0)
    const [deleteId, setDeleteId] = useState(false)
    const [durationMinutes, setDurationMinutes] = React.useState();
    const [durationHours, setDurationHours] = React.useState();

    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Add",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchProjects = (e) => {
        return Project.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleDelete = (id) => {
        setDeleteId(id)
    }
    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }
    const handleCreateNew = () => {

    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(async () => {

    }, [searchKeyword, listType, pageNumber])

    // useEffect(() => {
    //     console.log(props.copiedTask);
    //     if (props.copiedTask) {
    //         setValue('workplan', props.copiedTask?.title)
    //     }
    // }, [])

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }


    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const viewTabs = (id) => {
        setClientID(id)
        setTabs(true)
    }

    // const onSubmit = (data) => {
    //     console.log(data);
    //     setFormButtonStatus({ ...formButtonStatus, loading: true });
    //     setAlerts({ active: false, message: "Internal server error", type: "error" })



    //     let dataToSubmit = {
    //         projects_id: data.projects_id?.id,
    //         title: data.workplan,
    //         users_id: UserID
    //     };
    //     console.log(dataToSubmit);

    //     let action;

    //     action = WorkPlan.add(dataToSubmit);

    //     action.then(response => {
    //         console.log(response);
    //         setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
    //         setAlerts({ active: true, message: response.data.message, type: response.data.status })
    //         setFormButtonStatus({ label: "Add", loading: false, disabled: false });
    //         toast.success(response.data.message)
    //         sessionStorage.clear()
    //         setValue('workplan', '')
    //         props.fetchWorkplan()
    //         setTimeout(() => { setAlerts({}) }, 2000)
    //     }).catch(errors => {
    //         console.log(errors);
    //         toast.error("Internal server error");
    //         setAlerts({ active: true, message: "Internal server error", type: "error" })
    //         setFormButtonStatus({ label: "Add", loading: false, disabled: false });
    //     })
    // }


    const openWorkPlanEdit = (id) => {
        // console.log(id);
        // setEditId(id)
    }
    const handleHourChange = (e) => {
        if (e.target.value.length <= 2) {
            setDurationHours(e.target.value);
        }
    };
    const handleActivity = () => {
        sessionStorage.setItem('workTitle', watch('workplan'))
        // props.setCopiedTask(watch('activity'))
        // console.log(props.copiedTask);
    }

    const copyWork = (data) => {
        // console.log(data);
        sessionStorage.removeItem('activityTitle')
        sessionStorage.setItem('workTitle', data.title)
        props.setCopiedTask()
        props.setCopiedPlan(data)
        props.setTab(0)
    }

    useEffect(() => {
        if(props?.open){
            props?.fetchWorkplan()
            setValue('workplan', sessionStorage.getItem('workTitle') || '')
        }
    }, [pageNumber,props?.open])

    useEffect(() => {
        if(props?.open){
            setValue('workplan', sessionStorage.getItem('workTitle') || '')
        }
    }, [props?.open])

    return (
        <>

            <TableContainer component={Paper} style={{ marginTop: '10px', overflowY: 'scroll', height: 315, maxHeight: 315 }}>
                <Table>
                    {/* <TableHead>
                        <TableRow>
                            <TableCell width={'60%'}>Minutes</TableCell>
                            <TableCell>Assigned To</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead> */}
                    <TableBody>

                        {
                            props.workplan.length > 0 ?
                                props.workplan.map((plan) => (
                                    <TableRow style={{ border: '1px solid #ccc', }}>
                                        {/* width={'80%'} */}
                                        <TableCell style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width={'93%'} height={40}>
                                            <Grid display={'flex'}>
                                                <Grid display={'flex'} alignItems={'center'} width={'93%'}>
                                                    <a style={{ marginLeft: 4 }}>
                                                        {plan.title}
                                                    </a>

                                                </Grid>
                                                {/* <Grid width={'7%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                <a onClick={() => copyWork(plan)} style={{ marginLeft: 4, cursor: 'pointer' }}>
                                                    <ContentCopy color='primary' />
                                                </a>
                                            </Grid> */}
                                            </Grid>
                                        </TableCell>
                                        <TableCell style={{ borderBottom: '1px solid #ccc', textAlign: 'center' }}>
                                            <a onClick={() => copyWork(plan)} style={{ marginLeft: 4, cursor: 'pointer' }}>
                                                <ContentCopy color='primary' />
                                            </a>
                                        </TableCell>
                                        {/* <TableCell style={{ borderBottom: '1px solid' }}>
                                        <a style={{ marginLeft: 4 }}>
                                            {plan?.project?.name}
                                        </a>
                                    </TableCell> */}
                                    </TableRow>
                                ))
                                :
                                <Grid mt={12} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <a>
                                        No Work Plan Found For Today.
                                    </a>
                                </Grid>
                        }

                    </TableBody>
                </Table>
            </TableContainer>

            {/* <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} mt={0.1}>
                    <Grid item width={'69%'}>
                        <a onChange={handleActivity}>
                            <TextInput
                                autofocus
                                control={control}
                                placeholder={"Add Work Plan"}
                                name="workplan"
                                value={watch('workplan')}
                                onKeyDown={handleKeyPress}
                            />
                        </a>
                    </Grid>
                    <Grid mt={-0.5} item width={'22%'}>
                        <SelectX
                            placeholder={'Project'}
                            options={fetchProjects}
                            control={control}
                            error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                            error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                            name={'projects_id'}
                            defaultValue={watch('projects_id')}
                        />
                    </Grid>
                    <Grid item width={'8%'}>
                        <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                            variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                    </Grid>
                </Grid>
            </form > */}
        </>

    );
};

export default WorkPlanSignOut;
