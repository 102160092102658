import { get, post } from './../Config/config';

export const ClientReport = {

    get: (data) => get('/projects/client-reports', { params: data }),
    add: (data) => post('/projects/client-reports/store', data),
    getDetails: (data) => get('/projects/client-reports/view?id=', { params: data }),
    update: (data) => post('/projects/client-reports/update', data),
    delete: (data) => get('/projects/client-reports/delete?id=', { params: data })

}


//  = List
//  = Create
// = View
// = Update
//  = Delete