import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Modal, Slide, TextField, Typography, } from '@mui/material';

import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { a } from '@react-spring/web';
import { DotSpinner } from '@uiball/loaders';
import { useForm } from 'react-hook-form';
import { Meetings } from '../../../../api/Endpoints/Meeting';
import { VendorPayments } from '../../../../api/Endpoints/VendorPayments';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const divider = {
    marginTop: 2,
}



const DetailView = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm();

    const classes = useStyles();
    const [projects, setProjects] = useState([])

    const [Data, setData] = useState()
    const [loading, setLoading] = useState(false);


    const fetchDetails = () => {
        console.log(props.id);
        setLoading(true)
        VendorPayments.getDetails({ id: props.id }).then((response) => {
            console.log(response);
            setData(response.data.data)
            setLoading(false)
        }).catch(errors => {
            console.log(errors);
        })

    }



    useEffect(() => {

        fetchDetails()
    }, [props.id])

    return (
        <>

            {
                Data ?
                    <>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Title :
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        {Data?.title}
                                    </Typography>
                                </Typography>

                            </Grid>
                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Reimbursement Type:

                                </Typography>
                                <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                    {Data?.reimbursement_type}
                                </Typography>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Channel:
                                </Typography>
                                <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                                    {Data?.employee?.name || Data?.vendor?.name}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Amount:
                                </Typography>
                                <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                                    {Data?.amount}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Manager:

                                </Typography>
                                <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                    {Data?.report_to?.name}
                                </Typography>

                            </Grid>

                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Activity Date:
                                </Typography>
                                <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                                    {moment(Data?.date_of_activity).format('DD-MM-YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Status:
                                </Typography>
                                <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                                    {Data?.status}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Created User:
                                </Typography>
                                <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                                    {Data?.created_user?.name}
                                </Typography>
                            </Grid>
                        </Grid>

                        {
                            Data?.transaction_details &&
                            <>
                                <Divider className={classes.divider} />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                            Transaction Details :
                                            <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                                {Data?.transaction_details}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        }

                        <Divider className={classes.divider} />
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Description  :
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        {Data?.description}
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>

                    </>
                    : (
                        <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'30%'} mt={10}>
                            <DotSpinner size={40} speed={0.9} color="blue" />
                        </Box>
                    )}

            {/* <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <a onClick={() => props.detailEdit(Data)} style={{ textDecoration: 'underline', fontSize: 12, cursor: 'pointer', color: 'blue', fontSize: '13px', marginLeft: '-30px' }}>Edit</a>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions> */}



        </>
    )
}

export default DetailView;
