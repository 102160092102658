import React, { useEffect, useRef } from 'react';
import { useState } from 'react';

import { Button, FormControl, FormControlLabel, FormLabel, Grid, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { MeetingIdContext } from '../../../../../contexts/MeetingID/meetingID';
import { Employee } from '../../../../../api/Endpoints/Employee';
import { Meetings } from '../../../../../api/Endpoints/Meeting';
import TextInput from '../../../../form/TextInput';
import { Check } from '../../../../../icons/check';

// Using Create For Minutes
function CreateMinute({ meetingID, fetchList, DetailView }) {
  const validationSchema = yup.object().shape({
    title: yup.string().required("Minutes is required"),
    // Add validation rules for other fields if needed
  });


  const { register, handleSubmit, watch, formState: { errors }, control, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) });
  const { MeetingID } = MeetingIdContext();
  const [err, setErr] = useState(false);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false)
  const [rowCount, setRowCount] = useState(1);

  const [radioValue, setRadioValue] = React.useState('submit');

  const [formButtonStatus, setFormButtonStatus] = useState({
    label: 'ADD',
    loading: false,
    disabled: false,
  });
  const formRef = useRef();
  const textAreaRef = useRef(null)


  const onSubmit = async () => {
    if (!value.trim()) {
      setErr(true);
      return;
    }

    const loadingToast = toast.loading('creating...');
    setLoading(true);
    setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });

    if (value.includes('---')) {
      let linesArray = value?.split('---')?.map(line => line.trim());

      // Create an array to store the promises for each line
      const promises = linesArray.map(async (line) => {
        let meetID = meetingID ? meetingID : MeetingID;
        let minutes = '';
        if (line.includes('-')) {
          minutes = line.replace(/-/g, '').trim(); // Replace all dashes with empty string and trim the line
        } else {
          minutes = line.trim(); // If line does not contain '-', just trim it
        }

        const dataToSubmit = {
          meetings_id: meetID,
          title: minutes,
        };

        console.log(minutes);

        if (minutes) {
          try {
            await Meetings.addMeetingMinutes(dataToSubmit);
          } catch (error) {
            console.log(error);
            toast.dismiss(loadingToast);
            toast.error('Something went wrong. Please try again.');
            throw error; // Rethrow the error to halt further processing
          }
        }

      });

      // Wait for all promises to resolve or reject
      Promise.all(promises)
        .then(() => {
          // After all lines are saved successfully
          toast.dismiss(loadingToast);
          setValue('');
          setRowCount(1);
          toast.success('Meeting Minutes have been successfully saved.');
          fetchList();
          // let id = action?.data?.data?.id;
          setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
          setLoading(false);
        })
        .catch((error) => {
          // If any error occurs during saving lines
          console.error(error);
          // Any additional error handling if needed
        });
    }
    else {
      let meetID = meetingID ? meetingID : MeetingID;
      const dataToSubmit = {
        meetings_id: meetID,
        title: value,
      };

      try {
        let action = await Meetings.addMeetingMinutes(dataToSubmit);

        toast.dismiss(loadingToast);
        // console.log(action);

        if (action?.data?.status === 'success') {
          setValue('');
          setRowCount(1);
          toast.success(action?.data?.message || 'Meeting Minutes have been successfully saved.');
        }

        if (action?.data?.status === 'error') {
          // action?.data?.errors?.errors?.title[0] ||
          toast.error(action?.data?.message || 'Something went wrong. please try again');
        }

        fetchList();
        let id = action?.data?.data?.id;
        setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.dismiss(loadingToast);
        setLoading(false);
        toast.error('Something went wrong. Please try again.');
      }
    }
  };


  useEffect(() => {
    // Focus on the textarea when radioValue changes
    textAreaRef.current.focus();
  }, [radioValue]);

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    setErr(false);
  };

  // const handleKeyDown = (event) => {

  //   if (event.shiftKey && event.keyCode === 13) {
  //     event.preventDefault();
  //     setRowCount(rowCount + 1);
  //     setValue(value + '\n');

  //   } else if (!event.shiftKey && event.keyCode === 13) {
  //     if (radioValue == 'newLine') {
  //       event.preventDefault();
  //       setRowCount(rowCount + 1);
  //       setValue(value + '\n');
  //     } else {
  //       event.preventDefault();
  //       onSubmit()
  //     }
  //   } else if (event.keyCode === 8 && value.endsWith('\n')) {
  //     setRowCount(rowCount - 1);
  //   }
  // };

  const handleKeyDown = (event) => {
    const cursorPosition = event.target.selectionStart; // Get the cursor position
    const cursorEndPosition = event.target.selectionEnd; // Get the cursor end position

    if (event.shiftKey && event.keyCode === 13) {
      event.preventDefault();
      setRowCount(rowCount + 1);
      setValue(insertNewLine(value, cursorPosition, cursorEndPosition));
      // Set cursor position to the end of the newly created row
      setTimeout(() => {
        event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
      });
    } else if (!event.shiftKey && event.keyCode === 13) {
      if (radioValue === 'newLine') {
        event.preventDefault();
        setRowCount(rowCount + 1);
        setValue(insertNewLine(value, cursorPosition, cursorEndPosition));
        // Set cursor position to the end of the newly created row
        setTimeout(() => {
          event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
        });
      } else {
        event.preventDefault();
        onSubmit();
      }
    } else if (event.keyCode === 8 && value.endsWith('\n')) {
      setRowCount(rowCount - 1);
    }
  };

  // Function to insert a new line at a specific position in a string
  const insertNewLine = (str, startPosition, endPosition) => {
    const firstPart = str.substring(0, startPosition);
    const secondPart = str.substring(endPosition, str.length);
    return firstPart + '\n' + secondPart;
  };

  const handlePaste = (event) => {
    // Get the text being pasted
    const pastedText = event.clipboardData.getData('text');

    // Calculate the number of rows in the pasted text
    const pastedRows = pastedText.split('\n').length;

    // Adjust the number of rows of the textarea
    const newRowCount = Math.max(pastedRows, rowCount);

    // Update the state to reflect the new number of rows
    setRowCount(newRowCount);
  };




  return (
    <div className='cra-minutes'>
      <form ref={formRef} > {/* Add ref to the form */}
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={12}>
            {/* {
              loading &&
              <LinearProgress size="sm"
                value={25}
                sx={{
                  width: 455,
                  backgroundColor: 'transparent',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: 'grey',
                  },
                  borderRadius:'6px'
                }} />
            } */}

            <textarea
              ref={textAreaRef}
              autoFocus
              value={value}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              style={{
                width: '456px',
                padding: '10px',
                borderRadius: '8px',
                outline: 'none',
                lineHeight: '19px',
                animation: loading ? 'loading-animation 1s infinite' : 'none',
                resize: 'none', // Disable resizing by the user to auto adjust row
              }}
              rows={rowCount}
            />
            {err && <p style={{ color: 'red', padding: 0, margin: 0, fontSize: "14px", paddingBottom: 10, }}>{"please enter the minutes"}</p>}

            <Grid container style={{ width: '456px' }}>
              <Grid item md={8}>
                <div style={{ fontSize: '12px', color: 'gray' }}>

                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={radioValue}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel value="newLine" control={<Radio />} label="Press Enter for a new line" />
                      <FormControlLabel value="submit" control={<Radio />} label="Press Enter to submit data" />
                    </RadioGroup>
                  </FormControl>
                  {/* * Press Shift + Enter for a new line.
                  <br />
                  * Press Enter to submit data. */}
                </div>
              </Grid>
              <Grid display={'flex'} justifyContent={'end'} item md={4}>
                <Button onClick={onSubmit} style={{ width: '10px', height: '30px' }}><Check /></Button>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} mt={0.1}>
            <LoadingButton
              sx={{ display: 'none' }}
              loading={formButtonStatus.loading}
              disabled={formButtonStatus.disabled}
              type="submit"
              variant="outlined"

            >
              {formButtonStatus.label}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div >
  );
};



export default CreateMinute;