import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { MeetingIdContext } from '../../../contexts/MeetingID/meetingID';
import { ProjectIdContext } from '../../../contexts/Project ID/projectID';
import Index from './Tabs/Minutes';
import Create from './Tabs/create';
import Attendees from './Tabs/attendess';
import { Meetings } from '../../../api/Endpoints/Meeting';

const Modal = ({ projectID, editId, onUpdate, onNew, setShowCreatePage, setEditId, fetchTable, handleDetailView, detailEdit }) => {
    console.log('from modal view', editId)
    const { MeetingID, setMeetingID } = MeetingIdContext();
    // const {  setProjectID } = ProjectIdContext()

    const [open, setOpen] = useState(false);
    // const [projectID, setProjectID] = useState()
    const [proID, setProID] = useState()

    console.log(MeetingID);

    useEffect(() => {
        if (editId > 0) {
            console.log(editId);
            console.log(projectID);
            setOpen(true)
        }
        if (editId == 0) {
            console.log('editID==0');
            setOpen(true)
        }

    }, [])




    const handleClose = () => {
        fetchTable()
        setOpen(false)
        setShowCreatePage(false)
        setEditId(0)
        setMeetingID(0)
        sessionStorage.clear();
    }



    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box pt={2}>
                        <Typography>{children}</Typography>
                    </Box>
                )
                }
            </div >
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function BasicTabs({ viewID, setFormValue, control, watch, assignees, func }) {
        // const { ProjectID, setProjectID } = ProjectIdContext()
        const [value, setValue] = React.useState(0);
        const [proID, setProID] = useState([])
        const [titleKey, settitleKey] = useState()
        console.log(titleKey);

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        const fetchDetails = () => {
            Meetings.getDetails({ id: editId }).then((response) => {
                console.log(response);
                setProID(response.data.data.meetable_id)
            })
        }
        console.log(projectID);

        const setProId = (id) => {
            setProID(id)
            projectID = id
        }

        console.log(projectID);

        return (
            <Box sx={{ width: '100%', mt: 0 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={"Meeting"}{...a11yProps(0)} />
                        {
                            (editId > 0) &&
                            <Tab label="Attendees " {...a11yProps(1)} />
                        }
                        {/* {viewID > 0 && <Tab label="Meeting  Minutes" {...a11yProps(2)} />} */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Create
                        handleDetailView={handleDetailView}
                        fetchTable={() => fetchTable()}
                        getId={setProId}
                        projectID={projectID}
                        editId={editId}
                        onUpdate={onUpdate}
                        onNew={onNew}
                        setOpen={setShowCreatePage}
                    />
                </TabPanel>

                <TabPanel value={value} index={1}>

                    <Attendees
                        fetchTable={() => fetchTable()}
                        projectID={projectID}
                        editId={editId}
                        onUpdate={onUpdate}
                        onNew={onNew}
                    />


                </TabPanel>

                <TabPanel value={value} index={2}>
                    < Index
                        meetingID={editId}
                        projectID={projectID}
                        editId={editId}
                        onUpdate={onUpdate}
                        onNew={onNew}
                    />
                </TabPanel>



                {/* <TabPanel value={value} index={1}>
                    <DataTable viewID={viewID} />
                </TabPanel> */}

            </Box>
        );
    }

    return (
        <div>

            <Dialog
                open={open}
                PaperProps={{
                    sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' },
                }}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >

                < DialogContent sx={{
                    paddingLeft: '18px',
                    paddingRight: '10px',
                }} >
                    <BasicTabs viewID={editId} />
                </DialogContent>
                <DialogActions sx={{ pt: 0 }}>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default Modal;
