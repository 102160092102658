import React, { useEffect, useState } from 'react';
import Delete from '../../pages/common/Popup/Delete'
import moment from "moment";
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, ListItem, ListItemText, Popover,
    Slide, TableCell, TableRow, TextField, Typography
} from "@mui/material";
import { Task } from "../../../api/Endpoints/Task";
import TimeInput from '../../form/DateTime';

import toast from "react-hot-toast";
import parse from 'html-react-parser';
import { MoreOptionsDropdown } from '../common/MoreAction/actionButton';
import View from './view';
import Create from '../tasks/create';
import { UserIdContext } from '../../../contexts/UserID/UserID';
import { useForm } from 'react-hook-form';
import DateInput from '../../form/DateInput';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const listStatus = [
    { id: 1, name: "Not Started", color: "rgb(196, 196, 196)" },
    { id: 2, name: "In progress", color: "rgb(253, 171, 61)" },
    { id: 3, name: "Review pending", color: "#e8c8ff" },
    { id: 4, name: "Review Failed", color: "#b84bff" },
    { id: 5, name: "Completed", color: "#00c875" },
]
const SingleTask = (props) => {

    const { UserID } = UserIdContext();
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm();

    let dataSet = props.dataSet;
    // const [dataSet,setObj] = useState(props.dataSet);
    const [statusNote, setStatusNote] = useState();
    const [history, setHistory] = useState([]);
    const [status, setStatus] = useState();
    const [viewDeatils, setDetails] = useState(false)
    const [ID, setID] = useState(1)
    let due_date = moment(dataSet.due_date).format('');




    const [anchorEl, setAnchorEl] = useState(null);
    const handleStatusPopoverClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleStatusPopoverClose = () => { setAnchorEl(null); };
    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;



    // Due Date logic for Today and Tomorrow Condtion Rendering

    const today = moment();
    const tomorrow = moment().add(1, 'days');
    const responseDueDate = moment(due_date);

    if (responseDueDate.isSame(today, 'day')) {
        due_date = "Today";
    } else if (responseDueDate.isSame(tomorrow, 'day')) {
        due_date = "Tomorrow";
    } else {
        due_date = moment(due_date).format('MMM, DD-YYYY');
    }

    const [open, setOpen] = React.useState(false);
    const [viewPage, setViewPage] = useState(false)
    const [openChangeStatus, setOpenChangeStatus] = React.useState(false);
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [editid, setEditId] = useState()
    const handleClickOpen = () => { setViewPage(true); };
    const handleClose = () => { setOpen(false); };

    const handleClickOpenChangeStatus = () => { setOpenChangeStatus(true); };
    const handleCloseChangeStatus = () => { setOpenChangeStatus(false); };

    const handleStatusChange = (status) => {
        setStatus(status)
        setOpenChangeStatus(true);

    }

    const handleStatusNoteChange = (e) => {
        setStatusNote(e.target.value);
    }

    const handleStatusChangeConfirm = async () => {
        setOpenChangeStatus(true);
        const loadingToast = toast.loading('Changing...');
        let change = await Task.changeStatus(
            {
                task_id: dataSet.id,
                status: status,
                status_note: statusNote
            });

        if (change.data.status === "success") {
            toast.dismiss(loadingToast);
            toast.success(change.data.message)
            handleCloseChangeStatus();
            setTimeout(function () {
                props.onUpdate();
            }, 500)
            handleStatusPopoverClose()
            fetchStatus();
        } else {
            toast.dismiss(loadingToast);
            toast.error(change.data.message)
        }
        setOpen(false)
    }

    const fetchStatus = async () => {
        let history = await Task.getStatusHistory({ task_id: dataSet.id });
        if (history.data.status === "success") {
            setHistory(history.data.data);
        }
    }

    useEffect(() => {
        if (open) {
            fetchStatus();
        }

    }, [open])
    const handleUpdate = () => {
        props.Refresh();
    }

    const handleCreate = () => {
        setEditId(0);

    }

    const getStatusOptions = () => {
        const userIsAssignedUser = UserID === dataSet.assigned_to_id;
        const userIsReviewer = UserID === dataSet.reviewer_id;

        if (userIsAssignedUser && userIsReviewer) {
            return listStatus; // Show all status options
        } else if (userIsAssignedUser) {
            return [
                listStatus[0], // Not Started
                listStatus[1], // In Progress
                listStatus[2] // Review Pending
            ];
        } else if (userIsReviewer) {
            return [
                listStatus[3], // Review Failed
                listStatus[4] // Completed
            ];
        }
        return listStatus;
    };
    return (<>

        <Dialog open={openChangeStatus} onClose={handleClose}>
            <DialogTitle>Change status of task</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please submit remarks on this task, you can leave it blank if you want.
                </DialogContentText>
                <TextField
                    autoFocus
                    onChange={handleStatusNoteChange}
                    margin="dense"
                    id="name"
                    label="Remarks"
                    type="email"
                    fullWidth
                    variant="standard"
                />
                {
                    status == 'Completed' ?
                        <Grid mt={2} width={'50%'}>
                            <DateInput control={control} name="due_date"
                                label="Date of Completion"
                                value={watch('due_date')} />
                        </Grid>
                        : ''
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseChangeStatus}>Cancel</Button>
                <Button onClick={handleStatusChangeConfirm}>Change</Button>
            </DialogActions>
        </Dialog>


        {/* Status change Modal */}
        <Dialog
            open={open}
            PaperProps={{ sx: { width: "40%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%', maxWidth: '60%' } }}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle sx={{ fontSize: '17px', fontFamily: 'sans-serif' }}>{dataSet.title}</DialogTitle>
            <DialogContent sx={{ mt: 5 }}>
                <Grid item md={12} sm={12}>
                    <b>Status : </b> <br />
                    {getStatusOptions().map(ob => {
                        return (
                            <Chip
                                sx={{ mr: 1, mt: 2 }}
                                label={ob.name}
                                color="success"
                                onClick={() => handleStatusChange(ob.name)}
                                variant={ob.name !== dataSet.status ? "outlined" : ""}
                            />
                        );
                    })}
                </Grid>
                {history.length > 0 &&


                    <Grid item md={12} sx={{ my: 2, mt: 5 }}>
                        <b>Status history : </b>
                        {history.map(ob => {
                            return <ListItemText sx={{ border: "1px solid #e9e9e9", padding: 1 }}
                                primary={<React.Fragment>{ob.status}
                                    <Typography sx={{ display: "inline-block", fontSize: '10px', marginLeft: 2 }} variant={"string"}>{moment(ob.updated_at).fromNow()}, {moment(ob.updated_at).format('YYYY MMM DD HH:mm')}
                                        {"   - " + ob.created_user?.name}</Typography></React.Fragment>}
                                secondary={<React.Fragment> <Typography sx={{ display: "block", fontSize: '12px', }} variant={"string"}>{ob.status_note}</Typography></React.Fragment>}
                            />;
                        })}
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog >

        {/*  */}



        < TableRow
            key={dataSet.name}

        >

            <TableCell sx={{ borderLeft: '3px solid ' + props.cName[0].color + ' !important' }}>{dataSet.project?.name}</TableCell>
            <TableCell className={"link"} component="th" scope="row"
                onClick={handleClickOpen}>
                {dataSet.title}
            </TableCell>
            <TableCell >{dataSet.assigned_to_user?.name}</TableCell>
            <TableCell >{dataSet.assigned_by_user?.name}</TableCell>
            <TableCell >{dataSet.reviewer?.name}</TableCell>
            <TableCell >

                <Button sx={{ background: listStatus.find(o => o.name === dataSet.status)?.color, color: "black" }} aria-describedby={id} variant="contained" onClick={handleStatusPopoverClick} size={"small"}>
                    {dataSet.status}
                </Button>
                <Popover
                    id={id}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleStatusPopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {getStatusOptions().map(o => {
                        return (
                            <Button size={"small"} onClick={e => handleStatusChange(o.name)} sx={{ p: 1, m: 1, display: 'block', background: o.color, color: "black" }}>{o.name}</Button>
                        )
                    })}
                    {/* {listStatus.map(o => {
                        return <>
                            <Button size={"small"} onClick={e => handleStatusChange(o.name)} sx={{ p: 1, m: 1, display: 'block', background: o.color, color: "black" }}>{o.name}</Button>
                        </>
                    })} */}

                </Popover>
            </TableCell>
            <TableCell width={"30%"}>{due_date}</TableCell>
            <TableCell >
                <MoreOptionsDropdown
                    params={dataSet}
                    setDeletePopup={setDeletePopup}
                    setID={setID}
                    setEditId={setEditId}
                    showchageStatus={true}
                    setOpen={setOpen}
                /></TableCell>
        </ TableRow >

        {viewPage && <View setViewPage={setViewPage} dataSet={dataSet} />}
        <Create onNew={handleCreate} onUpdate={handleUpdate} editId={editid} disableCrtbtn={true} setEditId={setEditId} />

        {
            showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                Callfunc={() => props.Refresh()}
                url="/projects/tasks/delete?id="
                title="Task" />
        }


    </>);
};

export default SingleTask;
