import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Card, CircularProgress, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { Backlinks } from '../../../../../api/Endpoints/Backlinks';
import { useNavigate } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import CreateLinks from '../BackLinks/CreateLinks';
import Delete from '../../../common/Popup/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Link from '../../page/Link';
import { useParams } from 'react-router-dom';

const ListArticles = (props) => {

    const { id } = useParams();

    const columns = [
        {
            field: 'article_link', headerName: 'Article Link', width: 250, renderCell: (params) => (
                <p
                    size="small"
                    onClick={() => viewTabs(params.id)}
                >
                    {params.row.article_link}

                </p>
            ),

        },
        { field: 'payment', headerName: 'Payment', width: 150, renderCell: (params) => <>{params.row.payment}</> },
        { field: 'currency', headerName: 'Currency', width: 150, renderCell: (params) => <>{params.row.currency}</> },
        {
            field: 'linkss',
            headerName: 'Links',
            width: 150,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => ViewLinksPage(params.id)}>
                        <LinkIcon style={{ height: 19 }} />
                    </IconButton>

                </>
            ),
        },




        {
            field: 'Edit',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <>

                    <Button size="small" onClick={() => handleEdit(params.row.id)}>
                        <EditIcon style={{ color: 'blue', height: 19 }} />
                    </Button>
                    <Button size='small' onClick={() => handleDelete(params.id)}>
                        <DeleteIcon style={{ color: 'red', height: 19 }} />
                    </Button>

                </>
            ),
        },

    ];


    const [editId, setEditId] = useState();
    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0);
    const handleEdit = (id) => { setRefresh(Math.random); setEditId(parseInt(id)); }
    const [loading, setLoading] = useState(false);
    const [Tabs, setTabs] = useState(false)
    const [SiteID, setSiteID] = useState(0)
    const [Linklist, setLinkList] = useState()

    const [LinksView, setLinksView] = useState(false)
    const [ID, setID] = useState()
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [backLinkId, setbackLinkId] = useState()

    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }
    const ViewLinksPage = (id) => {
        setbackLinkId(id)
        // props.setShowTabs(true)
        setLinksView(true)
    }
    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }
    const handleDelete = (id) => {
        setID(id)
        setDeletePopup(true)
    }

    const fetchTable = () => {
        setLoading(true);
        Backlinks.ListArticles({ backlink_sites_id: id }).then(response => {
            setList(response.data.data.data);
            setLoading(false);
        })
    }


    const handleCreateNew = () => {

    }

    const viewTabs = (id) => {
        setSiteID(id)
        setTabs(true)
    }
    useEffect(async () => {
        fetchTable();
    }, [searchKeyword, listType, pageNumber])

    console.log(backLinkId);


    return (
        <>




            {
                !LinksView &&
                <Grid display={'flex'} justifyContent={'end'}>
                    <CreateLinks key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} />
                </Grid>
            }



            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                Callfunc={() => fetchTable()}
                url="/backlink-sites/articles/delete?id="
                title="BackLink" />}

            {
                LinksView ? <Link backLinkId={backLinkId} Linklist={Linklist} setLinkList={setLinkList} setLinksView={setLinksView} />
                    :
                    <Card sx={{ m: 2, height: 500 }} variant="outlined">
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        {list &&
                            <DataGrid
                                paginationMode="server"
                                rows={list}
                                columns={columns}
                                page={pageNumber}
                                pageSize={10}
                                rowHeight={50}
                                rowsPerPageOptions={[10]}
                                onPageChange={handlePageChange}
                                // autoHeight
                                density={"comfortable"}
                            />
                        }
                    </Card>

            }


        </>
    )

}



export default ListArticles;

