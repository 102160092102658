

import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button, Grid, IconButton, Input, Tooltip } from '@mui/material';
import { Employee } from "../../../api/Endpoints/Employee";
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { EditProfile } from '../../../api/Endpoints/EditProfile';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import DateInput from '../../form/DateInput';
import Box from '@mui/material/Box';
import moment from 'moment/moment';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Clear } from '@mui/icons-material';


const validationSchema = yup.object().shape({
    personal_email: yup.string().email('Invalid email address'),
    username: yup.string()
        // .required('Personal Phone is required')
        .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
    // phone_number: yup.string()
    //     // .required('Office Phone is required')Z
    //     .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
});
function EmployeesDetials({ getID }) {

    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, clearErrors, reset } = useForm({ resolver: yupResolver(validationSchema), });
    const [user, setUser] = useState({ name: '', email: '' });
    const [loading, setLoading] = useState(false);
    const [editable, setEditable] = useState(false)

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Save",
        loading: false,
        disabled: false,
    });

    useEffect(() => {
        fetchEmployeeDetail()
    }, []);
    const onSubmit = async (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        let official_date_of_birth = moment(data.official_date_of_birth).format('YYYY-MM-DD');
        let date_of_birth = moment(data.date_of_birth).format('YYYY-MM-DD');
        let marriage_date = moment(data.marriage_date).format('YYYY-MM-DD');


        let dataToSubmit = {
            id: watch('id'),
            users_id: watch('users_id'),
            name: watch('name'),
            joining_date: watch('joining_date'),
            emergency_contacts: watch('emergency_contacts'),
            username: data.username,
            email: watch('email'),
            personal_email: data.personal_email,
            phone_number: data.phone_number,
            address: data.current_address,
            permanent_address: data.permanent_address,
            official_date_of_birth: official_date_of_birth,
            date_of_birth: date_of_birth,
            marriage_date: data.marriage_date ? marriage_date : '',
            linkedin_url: data.linkedin_url,
            facebook_url: data.facebook_url,
            instagram_url: data.instagram_url,
            blog_url: data.blog_url,
        };

        let resonse;
        if (data.username) {

            let PhoneUpdate = {
                phone: data.username,
                users_id: watch('users_id'),
            }

            await Employee.updatePhone(PhoneUpdate)

            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });




        }
        let action;

        action = await Employee.update(dataToSubmit)
        if (action.data.status === "success") {
            toast.success("Your data has been successfully updated")

        }
        setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });

        setEditable(false)

    };


    const fetchEmployeeDetail = () => {
        setLoading(true);
        EditProfile.get()
            .then(response => {
                console.log("rsponsdfs", response.data);
                if (response.data.status === "success") {
                    let user = response.data.data
                    console.log(user);
                    let employee = response.data.data.employee

                    getID(employee?.id)

                    setValue('id', employee.id)
                    setValue('users_id', employee.users_id)
                    setValue('name', user.name)
                    setValue('username', user.username)
                    setValue('email', user.email)
                    setValue('office_email', user.email)
                    setValue('facebook_url', user.facebook_url)
                    setValue('instagram_url', user.instagram_url)
                    setValue('linkedin_url', user.linkedin_url)
                    setValue('blog_url', user.blog_url)
                    setValue('joining_date', employee.joining_date)
                    setValue('emergency_contacts', employee.emergency_contacts)
                    setValue('phone_number', employee.phone_number)
                    setValue('personal_email', employee.email)
                    setValue('official_date_of_birth', employee.official_date_of_birth)
                    setValue('marriage_date', employee.marriage_date ? employee.marriage_date : '')
                    setValue('date_of_birth', employee.date_of_birth)
                    setValue('current_address', employee.address)
                    setValue('permanent_address', employee.permanent_address)


                }
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });

    }


    return (
        <>

            {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                    <CircularProgress size={33} />
                    <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                        Loading...
                    </Typography>
                </Box>
            ) : (

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        '& > :not(style)': { m: 1, width: '90%' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                            mt: 2,
                        }}
                    >
                        {!editable ? (
                            <Button
                                variant="contained"
                                sx={{ width: '100px' }}
                                onClick={() => setEditable(true)} // Toggle editable state
                            >
                                Edit
                            </Button>
                        )
                            : (
                                null
                            )
                        }

                    </Box>
                    <form onSubmit={handleSubmit(onSubmit)}>


                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} >
                                    <TextField
                                        defaultValue={watch('name')}
                                        id="outlined-basic"
                                        label="Name"
                                        fullWidth
                                        variant="outlined"
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        {...register('username')}
                                        id="outlined-basic"
                                        label="Personal Phone"
                                        variant="outlined"
                                        defaultValue={watch('username')}
                                        error={!!errors.username} // Check if there is an error for the personal_email field
                                        helperText={errors.username?.message} // Display the error message
                                        disabled={editable ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        {...register('phone_number')}
                                        id="outlined-basic"
                                        label="Office Phone"
                                        variant="outlined"
                                        defaultValue={watch('phone_number')}
                                        error={!!errors.phone_number} // Check if there is an error for the personal_email field
                                        helperText={errors.phone_number?.message} // Display the error message
                                        disabled={editable ? false : true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} mt={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        {...register('personal_email')}
                                        id="outlined-basic"
                                        label="Personal Email"
                                        fullWidth
                                        variant="outlined"
                                        error={!!errors.personal_email} // Check if there is an error for the personal_email field
                                        helperText={errors.personal_email?.message} // Display the error message
                                        disabled={editable ? false : true}
                                    />

                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        defaultValue={watch('office_email')}
                                        id="outlined-basic"
                                        label="Office Email"
                                        fullWidth
                                        variant="outlined"
                                        disabled
                                    />
                                </Grid>

                            </Grid>
                            <Grid container spacing={3} mt={1}>
                                <Grid item xs={4}>
                                    <DateInput
                                        control={control}
                                        name="official_date_of_birth"
                                        label="Official Date Of Birth"
                                        value={watch('official_date_of_birth')}
                                        disabled={editable ? false : true}
                                        disableFuture={true}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <DateInput
                                        control={control}
                                        name="date_of_birth"
                                        label="Celebrated Date"
                                        value={watch('date_of_birth')}
                                        disabled={editable ? false : true}
                                        disableFuture={true}
                                    />
                                </Grid>
                                <Grid item alignItems={'end'} display={'flex'} xs={4}>
                                    <DateInput
                                        showDate={false}
                                        control={control}
                                        name="marriage_date"
                                        label="Marriage Date"
                                        value={watch('marriage_date')}
                                        disabled={editable ? false : true}
                                    />
                                    {
                                        (watch('marriage_date') && editable) &&
                                        <Tooltip title={'clear marriage date'}>
                                            <a onClick={() => setValue('marriage_date', '')} style={{ cursor: 'pointer', color: 'grey' }}>
                                                <Clear />
                                            </a>
                                        </Tooltip>
                                    }


                                </Grid>
                            </Grid>
                            <Grid container spacing={3} mt={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        {...register('current_address')}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        label='Current Address'
                                        name="current_address"
                                        rows={3}
                                        defaultValue={watch('current_address')}
                                        disabled={editable ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        {...register('permanent_address')}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        label='Permanent Address'
                                        name="permanent_address"
                                        rows={3}
                                        disabled={editable ? false : true}
                                    />

                                </Grid>
                            </Grid>
                            <Grid container spacing={3} mt={2}>
                                <Grid item xs={6} >
                                    <TextField
                                        {...register('linkedin_url')}
                                        defaultValue={watch('linkedin_url')}
                                        id="outlined-basic"
                                        label="Linkdln URL"
                                        fullWidth
                                        variant="outlined"
                                        disabled={editable ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        {...register('facebook_url')}
                                        defaultValue={watch('facebook_url')}
                                        id="outlined-basic"
                                        fullWidth
                                        label="Facebook URL"
                                        variant="outlined"
                                        disabled={editable ? false : true}
                                    />
                                </Grid>

                            </Grid>
                            <Grid container spacing={3} mt={2}>

                                <Grid item xs={6}>
                                    <TextField
                                        {...register('instagram_url')}
                                        id="outlined-basic"
                                        defaultValue={watch('instagram_url')}
                                        label="Instagram URL"
                                        fullWidth
                                        variant="outlined"
                                        disabled={editable ? false : true}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        {...register('blog_url')}
                                        id="outlined-basic"
                                        defaultValue={watch('blog_url')}
                                        fullWidth
                                        label="Blog URL"
                                        variant="outlined"
                                        disabled={editable ? false : true}
                                    />
                                </Grid>

                            </Grid>



                        </Grid>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                                mt: 2,
                            }}
                        >
                            {editable && (
                                <div style={{ marginBottom: 30 }}>
                                    <Button
                                        onClick={() => {
                                            setEditable(false); // Turn off editing mode
                                            clearErrors()
                                            fetchEmployeeDetail()
                                        }}
                                        variant="outlined"
                                        color="error"
                                        sx={{ width: '100px' }}
                                    >
                                        Cancel
                                    </Button>

                                    <LoadingButton sx={{ width: '100px', marginLeft: 2 }} loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>

                                </div>
                            )}
                        </Box>
                    </form >

                </Box >

            )
            }

        </>
    );
}

export default EmployeesDetials