import React, { useState } from 'react';


function Invoices() {{

    return (
        <div>
            <h5>Invoices</h5>
        </div >
    );
}}

export default Invoices;
