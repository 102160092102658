import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Avatar, Backdrop, Box, Button, Card, CircularProgress, Grid, TablePagination, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Project } from "../../../api/Endpoints/Project";
import { PersonAddAlt1 } from '@mui/icons-material';
import OwnerPopup from '../common/Popup/projectOwner';
import { useForm } from 'react-hook-form';
import ReactSelector from 'react-select';
import AsyncSelect from "react-select/async";
import { Employee } from '../../../api/Endpoints/Employee';
import { Country } from '../../../api/Endpoints/Country';
import BasicCard from './CardComponent';
import BasicTabs from '../projects/TabMenus';



const WebsiteProjects = (props) => {

    const { watch, formState: { errors }, setValue, getValues, } = useForm();

    const [openOwnerpopup, setOpenOwnerpopup] = useState(false)
    const [ownerDetails, setOwnerDetails] = useState()

    const OwnerPopuphandler = (id, details) => {
        // console.log(id);
        setOwnerDetails(details)
        setOpenOwnerpopup(id)
    }


    const columns = [

        {
            field: 'name', headerName: 'Project name', width: 250, renderCell: (params) => (
                <Button className='project-dt-btn'
                    size="small"
                    onClick={() => viewTabs(params.id)}
                    style={{ color: 'black' }}
                >
                    {params.row.name}

                </Button>
            ),
        }, {
            field: 'client', headerName: 'Client', width: 250, renderCell: (params) => {
                return <>{params.row?.account?.account_name}</  >

            }
        },
        {
            field: 'project_owner', headerName: 'Project Owner', width: 250, renderCell: (params) => {
                const owner = params.row.members.find(item => item.is_project_owner === 1);

                return (
                    <p
                        style={{ cursor: 'pointer', width: '100%', color: 'blue' }}
                        size="small"
                        onClick={() => OwnerPopuphandler(params.id, owner)}
                    >
                        {owner?.user?.name || <PersonAddAlt1 style={{ marginLeft: 'auto', color: 'blue' }} />}
                    </p>
                );
            },

        },
        { field: 'start_date', headerName: 'Start Date', width: 250, renderCell: (params) => { return moment(params.value).format('DD-MM-YYYY') } },


        { field: 'status', headerName: 'Status', width: 100, renderCell: (params) => <>{parseInt(params.value) === 1 ? "Active" : "Inactive"}</> },
    ]

    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }

    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [editId, setEditId] = useState()
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0)
    const [Tabs, setTabs] = useState(false)
    const [projectId, setProjecId] = useState(0)
    const [selectedScope, setSelectedScope] = useState()
    const [selectedOwner, setSelectedOwner] = useState()
    const [selectedCountry, setSelectedCountry] = useState()
    const [selectedProject, setSelectedProject] = useState()
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (event, page) => {
        setPageNumber(page)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const viewTabs = (id) => {
        setProjecId(id)
        setTabs(true)
    }

    const CloseTab = () => {
        setTabs(false)
        setProjecId(0)
    }

    const fetchTable = () => {
        setLoading(true);
        Project.get({ keyword: searchKeyword, page: parseInt(pageNumber) + 1, limit: 10,scope:'Website Development' }).then(response => {
            console.log(response.data.data);
            setList(response.data.data);
            setLoading(false);
        })
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
        setPageNumber(0)
    }

    const handleCreateNew = () => {

    }
    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const listStcope = [
        { id: 1, name: "Website Development", value: "Website Development" },
        { id: 2, name: "SEO", value: "SEO" },
        { id: 3, name: "Influencer Marketing", value: "Influencer Marketing" },
        { id: 4, name: "Social Media Management", value: "Social Media Management" },
        { id: 5, name: "Paid Ads", value: "Paid Ads" },
        { id: 6, name: "Video Shoot", value: "Video Shoot" },
        { id: 7, name: "App development", value: "App development" },
        { id: 8, name: "IT Management", value: "IT Management" },
        { id: 9, name: "Branding", value: "Branding" },
    ]

    const selectScope = (data) => {
        setValue('scope', data?.value)
        setSelectedScope(data?.value)
    }
    const selectOwner = (data) => {
        if (data) {
            setValue('owner', { id: data?.id || '', name: data?.name || '' })
        } else {
            setValue('owner', '')
        }
        setSelectedOwner(data)
    }
    const selectCountry = (data) => {
        if (data) {
            setValue('country', { id: data?.id || '', name: data?.name || '' })
        } else {
            setValue('country', '')
        }
        setSelectedCountry(data)
    }
    const selectProject = (data) => {
        setValue('project', data ? data : '')
        setSelectedProject(data)
    }

    const fetchProject = (e) => {
        return Project.get({ keyword: e }).then(response => {
            // console.log(response);
            if (typeof response.data.data.data !== "undefined") {
                return (response.data.data.data)
            } else {
                return [];
            }
        })
    }

    const fetchEmployees = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            // console.log(response);
            if (typeof response.data.data.data !== "undefined") {
                return (response.data.data.data)
            } else {
                return [];
            }
        })
    }
    const fetchCountry = (e) => {
        return Country.get({ keyword: e }).then(response => {
            // console.log(response);
            if (typeof response.data.data !== "undefined") {
                return (response.data.data)
            } else {
                return [];
            }
        })
    }

    useEffect(async () => {
        fetchTable();
    }, [searchKeyword, listType, pageNumber, selectedCountry, selectedOwner, selectedScope])
    console.log("now project id is", projectId);
    return (
        <>
            {Tabs ? (<BasicTabs fetchTable={fetchTable} setTabs={setTabs} projectID={projectId} CloseTab={CloseTab} setSearchKeyword={setSearchKeyword} />
            ) : (


                <>
                    <PageHeader title={"Website Projects"} onCreateNew={handleCreateNew} />

                    {openOwnerpopup && <OwnerPopup details={ownerDetails} openOwnerpopup={openOwnerpopup} setOpenOwnerpopup={setOpenOwnerpopup} onUpdate={handleUpdate} />}


                    <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
                        <Grid display={'flex'} alignItems={'center'}>

                            <Grid mr={2} sx={{ width: 250 }}>
                                <AsyncSelect
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isClearable={true}
                                    defaultOptions
                                    name='project'
                                    loadOptions={fetchProject}
                                    getOptionLabel={(e) => e.name}
                                    getOptionValue={(e) => e.id}
                                    placeholder={'Project'}
                                    value={watch('project')}
                                    onInputChange={fetchProject}
                                    onChange={selectProject}
                                />
                            </Grid>

                            <Grid mr={2} sx={{ width: 200 }}>
                                <AsyncSelect
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isClearable={true}
                                    defaultOptions
                                    name='owner'
                                    loadOptions={fetchEmployees}
                                    getOptionLabel={(e) => e.name}
                                    getOptionValue={(e) => e.id}
                                    placeholder={'Owner'}
                                    value={watch('owner')}
                                    onInputChange={fetchEmployees}
                                    onChange={selectOwner}
                                />
                            </Grid>

                            <Grid mr={2} sx={{ width: 200 }}>
                                <AsyncSelect
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isClearable={true}
                                    defaultOptions
                                    name='country'
                                    loadOptions={fetchCountry}
                                    getOptionLabel={(e) => e.name}
                                    getOptionValue={(e) => e.id}
                                    placeholder={'Country'}
                                    value={watch('country')}
                                    onInputChange={fetchCountry}
                                    onChange={selectCountry}
                                />
                            </Grid>

                        </Grid>

                        <Grid display={'flex'} alignItems={'center'}>
                            <TextField
                                size='small'
                                style={{ width: 250, marginRight: 10 }}
                                id="outlined-name"
                                autoFocus
                                label="Search projects"
                                onChange={handleSearchEntry}
                            />

                        </Grid>

                    </Box >
                    <Card sx={{ m: 2, p: 1 }} variant="outlined">
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Grid container >
                            {
                                list?.data?.map((cards) => (
                                    <Grid display={'flex'} justifyContent={'center'} mt={2} item xs={6}>
                                        <BasicCard viewTabs={viewTabs} datas={cards} />
                                    </Grid>
                                ))
                            }
                            {
                                list.data ?
                                    <Grid mt={1} ml={'auto'} display={'flex'} justifyContent={'end'}>
                                        <TablePagination
                                            component="div"
                                            count={list?.total}
                                            rowsPerPage={list?.per_page}
                                            page={pageNumber}
                                            onPageChange={handlePageChange}
                                            rowsPerPageOptions={[]}
                                            labelRowsPerPage="" // This will hide the "Rows per page" label
                                            SelectProps={{ native: true }} // This will hide the select dropdown
                                        />
                                    </Grid>
                                    : ''}
                        </Grid>
                    </Card>
                </>
            )}


        </>
    );
};

export default WebsiteProjects;
