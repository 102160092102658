import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Avatar, Backdrop, Box, Button, Card, CircularProgress, Grid, TablePagination, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Project } from "../../../api/Endpoints/Project";
import { PersonAddAlt1 } from '@mui/icons-material';
import OwnerPopup from '../common/Popup/projectOwner';
import { useForm } from 'react-hook-form';
import ReactSelector from 'react-select';
import AsyncSelect from "react-select/async";
import { Employee } from '../../../api/Endpoints/Employee';
import { Country } from '../../../api/Endpoints/Country';
import BasicCard from './CardComponent';
import BasicTabs from '../projects/TabMenus';
import Create from './create';
import ContentTabs from './TabMenu';
import { Activities } from '../../../api/Endpoints/Activities';



const ContentWritingIndex = (props) => {

    const { watch, formState: { errors }, setValue, getValues, } = useForm();

    const [openOwnerpopup, setOpenOwnerpopup] = useState(false)
    const [ownerDetails, setOwnerDetails] = useState()

    const OwnerPopuphandler = (id, details) => {
        // console.log(id);
        setOwnerDetails(details)
        setOpenOwnerpopup(id)
    }


    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }

    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [editId, setEditId] = useState()
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0)
    const [Tabs, setTabs] = useState(false)
    const [detailViewID, setDetailViewID] = useState()
    const [selectedScope, setSelectedScope] = useState()
    const [limit, setLimit] = useState(20)
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }

    // for card view
    // const handlePageChange = (event, page) => {
    //     setPageNumber(page)   
    //     // window.scrollTo({
    //     //     top: 0,
    //     //     behavior: "smooth"
    //     // });
    // }

    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const viewTabs = (id) => {
        setDetailViewID(id)
        setTabs(true)
    }

    const CloseTab = () => {
        setTabs(false)
        setDetailViewID()
    }


    const columns = [

        {
            field: 'name', headerName: 'Title', width: 250, renderCell: (params) => (
                <>
                    <Button
                        size="small"
                        onClick={() => viewTabs(params?.row?.id)}
                        style={{ color: 'black' }}
                    >
                        {params.row.title}

                    </Button>
                </>
            )
        },

        { field: 'assigned_to', headerName: 'Assigned To', width: 250, renderCell: (params) => <>{params.value}</> },
        {
            field: 'priority', headerName: 'Priority', width: 150, renderCell: (params) => {
                return params.value
            }
        },
        { field: 'due_date', headerName: 'Due Date', width: 130, renderCell: (params) => { return params.value ? moment(params.value).format('DD-MM-YYYY') : '' } },



    ]

    const fetchTable = () => {
        setLoading(true);
        // for scroll loading
        // , limit: limit
        Activities.get({ keyword: searchKeyword, page: parseInt(pageNumber) + 1 }).then(response => {
            console.log(response.data.data);
            setList(response.data.data);
            setLoading(false); 
        })
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
        setPageNumber(0)
    }

    const handleCreateNew = () => {

    }
    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }


    useEffect(async () => {
        fetchTable();
    }, [searchKeyword, pageNumber])

    // this is working function for card view
    // const handleScroll = () => {
    //     const windowHeight =
    //         'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    //     const body = document.body;
    //     const html = document.documentElement;
    //     const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    //     const windowBottom = windowHeight + window.scrollY;

    //     if (windowBottom >= docHeight - 1) {
    //         setLimit((prevLimit) => prevLimit + 20);
    //         console.log('Reached the bottom of the page');
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return (
        <>

            <>
                <PageHeader title={"Content Writing"} onCreateNew={handleCreateNew} />

                {
                    Tabs &&
                    <ContentTabs id={detailViewID} handleClose={setTabs} />
                }

                <Box sx={{ p: 2, display: "flex", justifyContent: "end" }}>

                    <Grid display={'flex'} alignItems={'center'}>

                        <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />

                    </Grid>

                </Box >
                <Card sx={{ m: 2, p: 1 }} variant="outlined">
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Grid container spacing={2}>
                        {/* {
                            list?.data?.map((cards) => (
                                <Grid className=""
                                    item
                                    xs={6}
                                    md={4}
                                    xl={4}
                                    key={cards?.id} mt={2}>
                                    <BasicCard viewTabs={viewTabs} datas={cards} />
                                </Grid>
                            ))
                        } */}

                        {typeof list.data === "object" &&
                            <DataGrid
                                paginationMode="server"
                                rows={list?.data}
                                columns={columns}
                                page={pageNumber}
                                pageSize={10}
                                rowHeight={20}
                                rowsPerPageOptions={[10]}
                                rowCount={list.total}
                                onPageChange={handlePageChange}
                                autoHeight
                                density={"comfortable"}
                            />
                        }

                    </Grid>
                </Card>
            </>



        </>
    );
};

export default ContentWritingIndex;
