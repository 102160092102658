import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Button, Checkbox, DialogActions, Divider, Grid, InputLabel, Slide, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-hot-toast';
import { Task } from '../../../../api/Endpoints/Task';
import SelectX from '../../../form/SelectX';
import { Users } from '../../../../api/Endpoints/Users';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline } from '@material-ui/icons';
import Delete from '../../common/Popup/Delete';
import { SubscribeApi } from '../../../../api/Endpoints/Subscribe';
import TextInput from '../../../form/TextInput';
import { CheckList } from '../../../../api/Endpoints/CheckList';
import { Checklist } from '@mui/icons-material';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({

});


const CheckListTab = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editValues, setEditValues] = useState({})
    const [refresh, setRefresh] = React.useState(false);
    const [checkNotes, setCheckNotes] = useState()
    const [pageNumber, setPageNumber] = useState(0);
    const [deleteID, setDeleteID] = useState()



    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Add",
        loading: false,
        disabled: false,
    });

    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })



    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        props.setEditId()
        setOpen(false);
    };
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleDeleteAlert = (id) => {
        setDeleteID(id)
    }


    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let dataToSubmit = {
            project_tasks_id: props.id,
            note: data.check_note
        }

        console.log(dataToSubmit);
        let action;

        // if (props.editId > 0) {
        //     console.log(dataToSubmit);
        //     action = Task.updateNote(dataToSubmit)
        // } else {
        console.log(dataToSubmit);
        action = CheckList.add(dataToSubmit)
        // }
        action.then((response) => {
            console.log(response.data);
            setFormButtonStatus({ label: "Add", loading: false, disabled: true })
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false })
            toast.success(response.data.message)
            setValue('check_note','')
            fetchDetails()
            setOpen(false)
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            setOpen(false)
            toast.error("server error")
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Add", loading: false, disabled: false });
        })
    }

    const fetchDetails = () => {
        setLoading(true)
        CheckList.get({ project_tasks_id: props.id }).then((response) => {
            
            console.log(response.data.data.data);
            setCheckNotes(response.data.data.data)

            setLoading(false)
        })
    }


    useEffect(() => {
        fetchDetails()
        console.log('refreshing');
    }, [props.refresh])

    useEffect(() => {
        fetchDetails()
        setOpen(true);
    }, [])

    return (
        <div>

            {deleteID && <Delete
                ID={deleteID}
                setID={setDeleteID}
                setDeletePopup={setDeleteID}
                Callfunc={() => fetchDetails()}
                url="/subscribers/delete?id="
                title="Subscriber" />}

            <form onSubmit={handleSubmit(onSubmit)}>

                <ErrorMessage
                    errors={errors}
                    name="multipleErrorInput"
                    render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                            <p key={type}>{message}</p>
                        ))
                    }
                />


                <Grid xs={12} display={'flex'} alignItems={'end'} justifyContent={'space-between'}>
                    <Grid width={450} sx={{ pb: 2 }} item sm={9} xs={9}>
                        <TextInput
                            isRequired={true}
                            control={control}
                            name="check_note"
                            value={watch('check_note')} />
                    </Grid>

                    <Grid display={'flex'} alignItems={'end'} sx={{ pt: 2, pb: 2, mt: 3 }} item sm={3} xs={3}>
                        <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                            variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                    </Grid>
                </Grid>
            </form>

            <Divider />

            <Grid mt={4} container sx={12} sm={12}>
                {checkNotes?.map((notes, index) => (
                    <div style={{width:'100%'}} key={index}>
                        <Grid width={'100%'} mb={1} sx={12} sm={12} display={'flex'}>
                            <Grid width={'90%'} sx={11} sm={11}>
                                <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                    {notes.note}
                                </Typography>
                            </Grid>
                            <Grid mt={.5} sx={1} sm={1}>
                                <div>
                                    <Checkbox />
                                </div>
                            </Grid>
                        </Grid>
                        {index !== checkNotes.length - 1 && <Divider />}
                    </div>
                ))}
            </Grid>

        </div>
    )
}

export default CheckListTab;
