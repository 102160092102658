import React, { useEffect, useState } from 'react';
import { Button, DialogActions, Divider, Grid, InputLabel, Slide, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-hot-toast';
import { Task } from '../../../../../../api/Endpoints/Task';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
    // name: yup.string().required(),
});


const AddNote = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editValues, setEditValues] = useState({})
    const [refresh, setRefresh] = React.useState(false);

    console.log(props.editId);

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })



    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        props.setEditId()
        setOpen(false);
    };

    const onSubmit = (data) => {
        console.log(data);

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let dataToSubmit = {
            id: props.editId,
            project_tasks_id: props.id,
            note: data.note,
            // description: data.description
        }

        console.log(dataToSubmit);
        let action;

        if (props.editId > 0) {
            console.log(dataToSubmit);
            action = Task.updateNote(dataToSubmit)
        } else {
            console.log(dataToSubmit);
            action = Task.addNote(dataToSubmit)
        }
        action.then((response) => {
            console.log(response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true })
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false })
            toast.success(response.data.message)
            props.setTab(0)
            props.setEditId()
            // props.onUpdate()
            setOpen(false)
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            setOpen(false)
            toast.error("server error")
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    const fetchDetails = () => {
        setLoading(true)
        Task.getNoteDetails({ id: props.editId }).then((response) => {
            console.log(response);
            let data = response.data.data
            setValue('note', data.note)
            setLoading(false)
        })
    }


    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetails()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        }

    }, [])

    return (
        <div>



            <form onSubmit={handleSubmit(onSubmit)}>

                <ErrorMessage
                    errors={errors}
                    name="multipleErrorInput"
                    render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                            <p key={type}>{message}</p>
                        ))
                    }
                />


                <Grid sx={{ pt: 2 }} item xs={12}>
                    <InputLabel sx={{
                        color: "black",
                        pb: 1
                    }}>Add Note </InputLabel>
                    <TextField
                        {...register('note')}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        sx={{ width: '100%', }}
                        required
                    />
                </Grid>

                <Grid sx={{ pt: 2, pb: 2 }} item xs={12}>
                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                </Grid>
            </form>


            <Divider />

            {/* <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions> */}

        </div>
    )
}

export default AddNote;
