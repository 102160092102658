import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    TextareaAutosize,
    Select,
    TextField,
    Slide, Typography
} from "@mui/material";
import { TimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/lab';
import * as yup from "yup";
import dayjs from 'dayjs';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../form/TextInput";
import { Project } from "../../../api/Endpoints/Project";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import { Employee } from "../../../api/Endpoints/Employee";
import { WorkRequest } from '../../../api/Endpoints/WorkRequest';
import { Account } from '../../../api/Endpoints/Account';
import axios from 'axios';
import SelectX from '../../form/SelectX';
import CurrentDateInput from '../../form/CurrenDateInput';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import { ClientReport } from '../../../api/Endpoints/ClientReport';
import moment from 'moment';
import DeleteFileMoadal from './deleteFileModal';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({


    report_name: yup.string().required("Report name is requried"),
    // file_name: yup.string().required("File name is requried"),

})




const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = React.useState(false);
    const [attachments, setAttachments] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const handleClickOpen = () => { props.onNew(); };
    const insertFile = (e) => {
        setAttachments([e.target.files[0]])
        console.log(attachments);
        setRefresh(Math.random())
    }
    const handleRemoveFile = (file) => {
        setOpenDeleteModal(true)
        // console.log(file);
        // setAttachments((prevFiles) => prevFiles.filter((prevFile) => prevFile !== file));
        // setAttachments([])
        // setRefresh(Math.random())
    };


    const handleClose = () => {
        props.setEditId()
        setAttachments([])
        setOpen(false);
    };

    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })



    const fetchProjects = (e) => {
        return Project.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }



    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let date = moment(data.report_date).format('YYYY-MM-DD');
        const formData = new FormData()

        formData.append('id', props.editId)
        formData.append('projects_id', data.projects_id?.id)
        formData.append('report_name', data.report_name)
        formData.append('file', attachments[0])
        // formData.append('file_name',data.file_name)
        formData.append('report_date', date)


        // let dataToSubmit = {
        //     id: props.editId,
        //     account_name: data.account_name,
        //     company_name: data.company_name,
        //     address: data.address,
        //     remarks: data.remarks,

        // };

        // console.log(dataToSubmit);
        let action;

        if (props.editId > 0) {
            console.log("dataToSubmit");
            action = ClientReport.update(formData);
        } else {
            action = ClientReport.add(formData);
        }
        action.then(response => {
            console.log(response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }



    const fetchClientReporttDetails = async () => {
        setLoading(true)
        const response = await ClientReport.getDetails({ id: props.editId })
        setLoading(false)
        if (response.data.status === "success") {
            const data = response.data.data
            console.log(data);
            setValue('report_name', data.report_name)
            setValue('file_name', data.file_name)
            setValue('projects_id', {
                id: data.project?.id, name: data.project?.name
            })
            setValue('report_date', data?.report_date)
            if(data.document != ""){
                setAttachments([data.document])
            }
        }

    };


    useEffect(() => {
        setRefresh(Math.random())
        console.log(props.editId);
    }, [watch('projects_id')])



    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            console.log("this is editi id");
            fetchClientReporttDetails();
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            console.log("this is edit id", props.editId);
            setOpen(true);
        }

    }, [props.editId])



    return (
        <div>
            {
                openDeleteModal &&
                <DeleteFileMoadal handleOpen={setOpenDeleteModal} deleteFile={setAttachments} />
            }
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Create Report"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Report" : "Create Report"}</DialogTitle>
                <DialogContent>
                    {!loading ?
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <SelectX

                                    label={"Choose project"}
                                    options={fetchProjects}
                                    control={control}
                                    error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                    error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                    name={'projects_id'}
                                    defaultValue={watch('projects_id')}
                                />
                            </Grid>

                            <Grid container spacing={2} sx={{ display: 'flex', p: 1 }}>
                                <Grid item xs={12} sm={6}>
                                    <CurrentDateInput
                                        control={control}
                                        name="report_date"
                                        label="Report Date"
                                        value={watch('report_date')}
                                    />
                                </Grid>

                            </Grid>

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="report_name"
                                    label="Report Name"
                                    value={watch('report_name')} />
                            </Grid>

                            {/* <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="file_name"
                                    label="File Name"
                                    value={watch('file_name')} />
                            </Grid> */}

                            <Grid sx={{ p: 1, }} xs={12}>
                                <input
                                    type="file"
                                    id="file-upload"
                                    style={{ display: 'none' }}
                                    onChange={insertFile}
                                />
                                <label htmlFor="file-upload">
                                    <Button
                                        variant="contained"
                                        startIcon={<CloudUploadIcon />}
                                        component="span"
                                        sx={{
                                            mt: 1,

                                        }}
                                    >
                                        Choose File
                                    </Button>

                                </label>
                                {/* <input onChange={insertFile} type="file" /> */}
                            </Grid>

                            {
                                attachments &&
                                attachments.map((file) => (
                                    <Grid pl={1} display={'flex'} alignItems={'center'}>
                                        <CloudUploadIcon style={{ marginRight: 3, color: 'blue' }} />
                                        {
                                            file.name &&
                                            <p style={{ marginLeft: 20 }}>{file.name}</p>
                                        }
                                        {
                                            !file.name &&
                                            <p style={{ marginLeft: 20 }}>{file.slice(43)}</p>
                                        }
                                        <Button style={{ cursor: 'pointer', color: 'red', marginLeft: 'auto' }} onClick={() => handleRemoveFile(file)}>
                                            <DeleteIcon />
                                        </Button>

                                    </Grid>

                                ))
                            }

                            {/* <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="company_name"
                                    label="Company Name"
                                    value={watch('company_name')} />
                            </Grid>

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="address"
                                    label="Address"
                                    value={watch('address')} />
                            </Grid>
                            <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1
                                }}>Remarks </InputLabel>
                                <TextField
                                    {...register('remarks')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    sx={{ width: '100%', }}

                                />
                            </Grid> */}

                            <Grid sx={{ p: 1,pt:1 }} mb={5} item xs={12}>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            </Grid>

                        </form>
                        :
                        <>Loading...</>
                    }
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        </div >
    );
};

export default Create