import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextareaAutosize,
  Typography,
} from "@mui/material";

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Activities } from "../../../../api/Endpoints/Activities";
import { makeStyles } from "@material-ui/core";
import { DotSpinner } from "@uiball/loaders";
import { WorkPlan } from "../../../../api/Endpoints/WorkPlan";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: "1px 2px 2px 2px rgba(0, 0, 0, 0.3)",
  },
  title: {
    fontWeight: "bold",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  select: {
    minWidth: 200,
  },
}));

const divider = {
  marginTop: 2,
};

const DetailView = (props) => {
  //static data
  const data = [
    {
      id: 1,
      title: "Github Pull Request",
      description:
        "Create a pull request on Github. This will allow for code review and collaboration with other team members.",
      assignedTo: ["User 1", "User 2"],
      dueDate: "2022-01-01",
      status: "Not Started",
      explanation:
        "This task is yet to be started. It is a high-priority task that requires immediate attention.",
    },
    {
      id: 2,
      title: "Address Bug Issues",
      description:
        "Identify and resolve all known bug issues in the system to ensure a smoother user experience.",
      assignedTo: ["User 2", "User 3"],
      dueDate: "2022-01-02",
      status: "In Progress",
      explanation:
        "This task is currently in progress. The process of identifying and resolving bug issues has begun, and significant improvements are expected.",
    },
    {
      id: 3,
      title: "Resolve Deployment Issues",
      description:
        "Address any issues related to the deployment of the system to ensure a seamless transition.",
      assignedTo: ["User 3", "User 4"],
      dueDate: "2022-01-03",
      status: "Partially Achieved",
      explanation:
        "This task has been partially achieved. Most of the deployment issues have been identified and resolved, but a few minor issues remain.",
    },
    {
      id: 4,
      title: "Server Downtime Mitigation",
      description:
        "Implement measures to minimize server downtime and ensure high system availability.",
      assignedTo: ["User 4", "User 1"],
      dueDate: "2022-01-04",
      status: "Achieved",
      explanation:
        "This task has been successfully achieved. Measures have been implemented to minimize server downtime, ensuring high system availability.",
    },
    {
      id: 5,
      title: "Improve System Security",
      description:
        "Implement additional security measures to protect the system from potential threats.",
      assignedTo: ["User 1", "User 2"],
      dueDate: "2022-01-05",
      status: "Not Started",
      explanation:
        "This task is yet to be started. It is a high-priority task that requires immediate attention to ensure the security of the system.",
    },
  ];

  const fetchData = data.find((value) => value.id == props.id);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  // const handleClickOpen = () => { props.onNew(); };
  const handleClose = () => {
    props.handleClose(false);
    // setOpen(false);
  };
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);

  //static data state
  const [detail, setDetail] = useState({
    id: 0,
    title: "",
    description: "",
    assignedTo: "",
    dueDate: "",
  });

  // const fetchTaskDetails = () => {
  //   console.log(props.id);

  //   const fetchDetail = data.find((value) => value.id === props.id);

  //   setDetail(fetchDetail);
  //   console.log(detail);

  //   // WorkPlan.getDetails({ id: props.id })
  //   //   .then((response) => {
  //   //     console.log(response);
  //   //     setDetails(response.data.data);
  //   //     setLoading(false);
  //   //     console.log(response.data.data);
  //   //   })
  //   //   .catch((errors) => {
  //   //     console.log(errors);
  //   //   });
  // };

  const handleStorageChange = () => {
    console.log("testing");
  };

  // useEffect(() => {
  //   fetchTaskDetails();
  // }, []);

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: "100%",
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>VIEW TARGET</DialogTitle>
        <DialogContent>
          <>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  margin: "10px 0",
                }}
              >
                <Typography
                  variant="h1"
                  component="h2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.3rem",
                    marginRight: "10px",
                  }}
                >
                  Title:
                </Typography>
                <Typography variant="body2" style={{ fontSize: "1.2rem" }}>
                  {fetchData.title}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  margin: "10px 0",
                }}
              >
                <Typography
                  variant="body1"
                  component="h2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.3rem",
                    marginRight: "10px",
                  }}
                >
                  Due Date:
                </Typography>
                <Typography variant="body2" style={{ fontSize: "1.2rem" }}>
                  {fetchData.dueDate}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} style={{ margin: "10px 0" }}>
                <Typography
                  variant="body1"
                  component="h2"
                  style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                >
                  Description
                </Typography>
                <Typography
                  variant="body2"
                  style={{ paddingTop: 10, fontSize: "14px" }}
                >
                  {fetchData.description}
                </Typography>
              </Grid>
            </Grid>

            <Divider className={classes.divider} style={{ my: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} style={{ margin: "10px 0" }}>
                <Typography
                  variant="body1"
                  component="h2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    marginTop: 2,
                  }}
                >
                  Result By Assigner:
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item xs={2} sm={2} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body1"
                  component="h2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                  }}
                >
                  Status:
                </Typography>
              </Grid>
              <Grid item xs={10} sm={10} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2" style={{ fontSize: "16px" }}>
                  In Progress
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: 10,display: "flex", alignItems: "center", justifyContent: "space-between",width:'100%' }}>
              <Grid item xs={12} sm={12} md={2} lg={12} xl={12} >
                <Typography
                  variant="body1"
                  component="h2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    marginRight:'50px'
                  }}
                >
                  Explanation
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={12} xl={12} >
                <Typography variant="body2" style={{ fontSize: "1.1rem" }}>
                  Sanjeev has demonstrated exceptional dedication and effort in completing the assigned tasks promptly and to a high standard. His commitment to delivering quality work has been impressive, and his contributions have significantly impacted the project's progress. Keep up the excellent work, Sanjeev!
                </Typography>
              </Grid>
            </Grid>

            <Divider className={classes.divider} style={{ my: 2 }} />

            <Typography
              variant="body1"
              component="h2"
              style={{ fontWeight: "bold", fontSize: "1.1rem", textDecoration: 'underline', margin: "10px 0" }}
            >
              Result By Assignee:
            </Typography>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "15px 0",
                }}
              >
                <Typography
                  variant="body1"
                  component="h2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    marginRight: "10px",
                  }}
                >
                  Status
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={details?.user?.name}
                    label="Status"
                  >
                    <MenuItem value="completed">Not Started</MenuItem>
                    <MenuItem value="in-progress">In Progress</MenuItem>
                    <MenuItem value="pending">Partially Achieved</MenuItem>
                    <MenuItem value="pending">Achieved</MenuItem>
                    <MenuItem value="pending">Not Achieved</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} style={{ margin: "15px 0" }}>
                <Typography
                  variant="body1"
                  component="h2"
                  style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                >
                  Remarks
                </Typography>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={10}
                  placeholder="Minimum 3 rows"
                  defaultValue={details?.status}
                  style={{ width: "100%", paddingTop: 10, fontSize: "16px" }}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" style={{ marginTop: 20 }}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DetailView;
