import { Suspense, lazy, useState } from "react";
import { GuestGuard } from "./guards/GuestGuard";
import { LoadingScreen } from "./components/loading-screen";
import { Home } from "./pages/home";
import { MainLayout } from "./pages/main-layout";
import AuthGuard from "./guards/AuthGuard";
import { DashboardNavbar } from "./components/dashboard-navbar";
import { DashboardLayout } from "./containers/dashboard-layout";
import { Navigate, Outlet } from "react-router-dom";
import { Reports } from "./containers/reports";
import { ProductsSummary } from "./components/product/products-summary";
import LeadIndex from "./components/leads/lead-index";
import PreQualifierIndex from "./components/prequalifiers";
import LeadFilterIndex from "./components/lead-filter";
import LabelIndex from "./components/label";
import MyLeads from "./components/my-leads";
import ClosedLeads from "./components/closed-leads";
import DashboardIndex from "./components/dashboard";
import ForgotPassword from "./components/login/forgot-password";
import UserSettings from "./components/user-settings";
import FollowUpIndex from "./components/pages/follow-up/follow-up-index";
import DemoIndex from "./components/pages/demo/demo-index";
import LeadDetailIndex from "./components/pages/lead/lead-detail-index";
import MissedCallIndex from "./components/pages/missed-calls/missed-call-index";
import Clients from "./project-portal/pages/clients";
import Projects from "./project-portal/pages/projects";
import Employess from "./project-portal/pages/employees";
import Tasks from "./project-portal/pages/tasks";
import Activities from "./project-portal/pages/activities";
import ProjectDetails from "./project-portal/pages/projects/project-detail";
import WorkBoard from "./project-portal/pages/dashboard";
import Attendance from "./project-portal/pages/attendance";
import Create from "./project-portal/pages/employees/create";
import Edit from "./project-portal/pages/employees/edit";
import WorkRequest from "./project-portal/pages/workRequest";
import { element } from "prop-types";
import Tabview from "./project-portal/pages/editProfile";
import BacklinkSites from "./project-portal/pages/BacklinkSites";
import CreativeCalender from "./project-portal/pages/CreativeCalender";
import ReportanIssue from "./ReportanIssue";
import Reimbursement from "./project-portal/pages/reimbursement/Index";
import VendorPayments from "./project-portal/pages/VendorPayments";
import Departments from "./project-portal/pages/departments";
import Designs from "./project-portal/pages/design";
import Links from "./project-portal/pages/BacklinkSites/page/Link";
import Report from "./project-portal/pages/attendance/Report";
import Index from "./project-portal/pages/DataGridSample/Index";
import BacklinkTabs from "./project-portal/pages/BacklinkSites/TabMenus";
import ClientsReport from "./project-portal/pages/ClientReports";
import WorkOrders from "./project-portal/pages/workOrders/index";
import VideoShoots from "./project-portal/pages/VideoShoot";
import WebsiteTracker from "./project-portal/pages/WebsiteTracker/Tab";
import Meeting from "./project-portal/pages/Meetings";
import SignOut from "./project-portal/pages/SignOut";
import WorkPlanIndex from "./project-portal/pages/WorkPlan";
import Documents from "./project-portal/pages/Documents";
import LeaveEligibility from "./project-portal/pages/leaveEligibility";
import Leave from "./project-portal/pages/leave";
import { EditProfile } from "./api/Endpoints/EditProfile";
import ScheduleTask from "./project-portal/pages/schedule task";
import BackLinks from "./project-portal/pages/backlinks/Backlinks";
import WebsiteProjects from "./project-portal/pages/WebsiteProjects/CardIndex";
import TaskMaster from "./project-portal/pages/taskCard/CardIndex";
import ProjectCards from "./project-portal/pages/projectCards/CardIndex";
import ActivityReports from "./project-portal/pages/reports/activity reports";
import ContentWritingIndex from "./project-portal/pages/contentWriting";
import CreativeRquirmentsIndex from "./project-portal/pages/CreativeRequirements";
import CreativeWorkIndex from "./project-portal/pages/CreativeWorks";
import BacklinkReports from "./project-portal/pages/reports/backlink reports";
import DepartmentDashboard from "./project-portal/pages/departmentDashboard";
import PerformanceDashboard from "./project-portal/pages/Performance Dashboard";
import LearningMaterial from "./project-portal/pages/LearningMaterial";
import ArchivedTaskIndex from "./project-portal/pages/ArchivedTask";
import Complaints from "./project-portal/pages/complaints";
import StarRating from "./project-portal/pages/starRating";
import Awards from "./project-portal/pages/awards";
import Targets from "./project-portal/pages/target-goals";
import ChecklistComponent from "./project-portal/pages/checklists";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Not found pages
const NotFound = Loadable(
  lazy(() =>
    import("./containers/not-found").then((module) => ({
      default: module.NotFound,
    }))
  )
);

// Auth pages
const Login = Loadable(
  lazy(() =>
    import("./containers/login").then((module) => ({ default: module.Login }))
  )
);

const Products = Loadable(
  lazy(() =>
    import("./containers/products").then((module) => ({
      default: module.Products,
    }))
  )
);

const routes = [
  {
    path: "",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "forgot-password",
    element: (
      <GuestGuard>
        <ForgotPassword />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="/dashboard/workboard" replace />,
      },
      {
        path: "reports",
        element: <DashboardIndex />,
      },

      {
        path: "under-development",
        children: [
          {
            path: "Performance-dashboard",
            element: <PerformanceDashboard />,
          },
        ],
      },
      {
        path: "",
        children: [
          {
            path: "workboard",
            element: <WorkBoard />,
          },
          {
            path: "department-dashboard",
            element: <DepartmentDashboard />,
          },
        ],
      },
      {
        path: "project-management",
        children: [
          {
            path: "projects",
            element: <Projects />,
          },
          {
            path: "tasks",
            element: <Tasks />,
          },
          {
            path: "activities",
            element: <Activities />,
          },
          {
            path: "work-plan",
            element: <WorkPlanIndex />,
          },
          {
            path: "meetings",
            element: <Meeting />,
          },
          {
            path: "archived-tasks",
            element: <ArchivedTaskIndex />,
          },
          {
            path: "archived-tasks",
            element: <ArchivedTaskIndex />,
          },
          { path: "check-list", element: <ChecklistComponent /> },
        ],
      },
      {
        path: "company",
        children: [
          {
            path: "",
            element: <Employess />,
          },
          {
            path: "employees",
            children: [
              {
                path: "",
                element: <Employess />,
              },
              {
                path: "target",
                element: <Targets />,
              },
            ],
          },
          {
            path: "awards",
            element: <Awards />,
          },
          {
            path: "departments",
            element: <Departments />,
          },
          {
            path: "document",
            element: <Documents />,
          },
          {
            path: "complaints",
            element: <Complaints />,
          },
          {
            path: "star-rating",
            element: <StarRating />,
          },
        ],
      },
      {
        path: "products",
        children: [
          {
            path: "",
            element: <Products />,
          },
        ],
      },
      {
        path: "clients",
        element: <Clients />,
      },
      {
        path: "leads",
        element: <LeadIndex />,
      },
      {
        path: "lead/:leadId",
        element: <LeadDetailIndex />,
      },
      {
        path: "pre-qualifiers",
        element: <PreQualifierIndex />,
      },
      {
        path: "lead-filter",
        element: <LeadFilterIndex />,
      },
      {
        path: "labels",
        element: <LabelIndex />,
      },
      {
        path: "mine",
        element: <MyLeads />,
      },
      ,
      {
        path: "closed",
        element: <ClosedLeads />,
      },
      {
        path: "follow-up",
        element: <FollowUpIndex />,
      },
      {
        path: "Work-orders",
        element: <WorkOrders />,
      },
      {
        path: "Demo",
        element: <DemoIndex />,
      },
      {
        path: "missed-calls",
        element: <MissedCallIndex />,
      },
      {
        path: "settings",
        element: <UserSettings />,
      },

      {
        path: "projects-cards",
        element: <ProjectCards />,
      },
      {
        path: "content-management",
        children: [
          {
            path: "video-shoots",
            element: <VideoShoots />,
          },
          {
            path: "creative-calendar",
            element: <CreativeCalender />,
          },
          {
            path: "creative-works",
            element: <CreativeWorkIndex />,
          },
          {
            path: "creative-requirments",
            element: <CreativeRquirmentsIndex />,
          },
          {
            path: "content-writing",
            element: <ContentWritingIndex />,
          },
        ],
      },

      {
        path: "employees/create",
        element: <Create />,
      },
      ,
      {
        path: "employees/edit/:id",
        element: <Create />,
      },
      {
        path: "employees/edit-employee/:id",
        element: <Edit />,
      },
      {
        path: "edit-profile",
        element: <Tabview />,
      },

      {
        path: "task-card",
        element: <TaskMaster />,
      },

      {
        path: "client-report",
        element: <ClientsReport />,
      },
      {
        path: "website-live-tracker",
        element: <WebsiteTracker />,
      },

      {
        path: "learning-material",
        element: <LearningMaterial />,
      },
      {
        path: "card-task",
        element: <TaskMaster />,
      },
      {
        path: "attendance",
        children: [
          {
            path: "daily",
            element: <Attendance />,
          },
          {
            path: "report",
            element: <Report />,
          },
          {
            path: "leave",
            element: <Leave />,
          },
          {
            path: "leave-eligibility",
            element: <LeaveEligibility />,
          },
        ],
      },
      {
        path: "seo",
        children: [
          {
            path: "back-links",
            element: <BackLinks />,
          },
          {
            path: "Backlink-Sites",
            element: (
              <>
                <Outlet />
              </>
            ),
            children: [
              {
                path: "",
                element: <BacklinkSites />,
              },
              {
                path: "details/:id",
                element: <BacklinkTabs />,
              },
              {
                path: "links/:id",
                element: <Links />,
              },
            ],
          },
        ],
      },
      {
        path: "daily-attendance/edit-employee",
        element: <Edit />,
      },
      {
        path: "projects/:projectId",
        element: <ProjectDetails />,
      },
      {
        path: "report-issue",
        element: <ReportanIssue />,
      },
      {
        path: "website-projects",
        element: <WebsiteProjects />,
      },

      {
        path: "reimbursement",
        element: <Reimbursement />,
      },
      {
        path: "vendor-payments",
        element: <VendorPayments />,
      },
      {
        path: "work-request",
        element: <WorkRequest />,
      },

      {
        path: "designs",
        element: <Designs />,
      },
      {
        path: "datagrid",
        element: <Index />,
      },
      {
        path: "signout",
        element: <SignOut />,
      },

      {
        path: "schedule-task",
        element: <ScheduleTask />,
      },

      //attendandce sub menu
      // {
      //     path: 'attendance',
      //     element: '',
      //     children: [

      //     ]
      // }

      // Reports submenu
      {
        path: "reports",
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            path: "activity",
            element: <ActivityReports />,
          },
          {
            path: "backlink",
            element: <BacklinkReports />,
          },
        ],
      },

      // Submenu Links
      // {
      //     path: 'project',
      //     element: (
      //         <>
      //             <Outlet />

      //         </>),
      //     children: [

      //         {
      //             path: "",
      //             element: <Projects />
      //         },
      //         {
      //             path: 'task',
      //             element: <Tasks />
      //         },
      //         {
      //             path: 'activities',
      //             element: <Activities />
      //         },
      //         {
      //             path: 'meeting',
      //             element: <Meeting />
      //         },
      //         {
      //             path: 'work-request',
      //             element: <WorkRequest />
      //         },
      //         {
      //             path: 'work-plan',
      //             element: <WorkPlanIndex />
      //         },
      //     ],
      // },
      // {
      //     path: 'client',
      //     element: (
      //         <>
      //             <Outlet />

      //         </>),
      //     children: [

      //         {
      //             path: "",
      //             element: <Clients />
      //         },
      //         {
      //             path: "client-report",
      //             element: <ClientsReport />
      //         },
      //     ]
      // },

      // back-link site main

      // back-link main

      // {
      //     path: 'social-media',
      //     element: (
      //         <>
      //             <Outlet />

      //         </>),
      //     children: [
      //         {
      //             path: '',
      //             element: <CreativeCalender />
      //         },
      //         {
      //             path: 'design',
      //             element: < Designs />
      //         },
      //     ]
      // },
      // {
      //     path: 'hr',
      //     element: (
      //         <>
      //             <Outlet />

      //         </>),
      //     children: [
      //         {
      //             path: '',
      //             element: <Attendance />
      //         },
      //         {
      //             path: 'attendence-report',
      //             element: < Report />
      //         },
      //         {
      //             path: 'documents',
      //             element: < Documents />
      //         },
      //         {
      //             path: 'edit-profile',
      //             element: < Tabview />
      //         }, {
      //             path: 'departments',
      //             element: < Departments />
      //         },
      //     ]
      // },
      // {
      //     path: 'accounts',
      //     element: (
      //         <>
      //             <Outlet />

      //         </>),
      //     children: [
      //         {
      //             path: '',
      //             element: <WorkOrders />
      //         },
      //         {
      //             path: 'payments',
      //             element: < VendorPayments />
      //         },
      //     ]
      // },
    ],
  },
  // {
  //     path: 'upload-documents',
  //     element: <CustomeDoctsUpload />
  // },

  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
