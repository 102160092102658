import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    InputLabel,
    TextField,
    Slide,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import TextInput from '../../form/TextInput';
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import SelectX from '../../form/SelectX';
import moment from "moment";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import { Account } from "../../../api/Endpoints/Account";
import CurrentDateInput from '../../form/CurrenDateInput';
import { Project } from '../../../api/Endpoints/Project';
import { Country } from '../../../api/Endpoints/Country';
import ReactSelector from 'react-select';
import { Employee } from '../../../api/Endpoints/Employee';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({


    project_name: yup.string().required(),
    description: yup.string(),
    start_date: yup.string(),
    end_date: yup.string(),
    due_date: yup.string(),
    Owner_Id: yup.object(),
    assigned_by_id: yup.object(),
    projects_id: yup.object().shape({
        id: yup.string().required("Please choose a project").typeError("Please choose a project")
    }).required().typeError("Please choose a project"),
    country: yup.object().nullable()
    // reviewer_id: yup.object().shape({
    //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    // }).required().typeError("Please choose a reviewer"),
})




const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({})
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [selectedScopes, setSelectedScopes] = useState([]);

    const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => {
        props.setEditId(undefined)
        setOpen(false);
    };



    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchClient = (e) => {
        return null
    }



    const fetchEmployees = async (keyword) => {
        const response = await Employee.get({ keyword: keyword });
        return response.data.data?.data;
    };

    const handlePriorityChange = (id) => {
        setSelectedPriority(id);
    }


    const options = [
        { value: "New", label: 'New' },
        { value: "On Hold", label: 'On Hold' },
        { value: "Accepted", label: 'Accepted' },
        { value: "Work In Progress", label: 'Work In Progress' },
        { value: "Completed", label: 'Completed' },
        { value: "Cancelled ", label: 'Cancelled ' },
    ];

    const leaveType = [
        {
            value: 1, label: 'Full Day'
        },
        { value: 2, label: 'Half Day' },
    ];

    const priorityOptions = [
        {
            value: "Low", label: "Low"
        },
        {
            value: "Medium", label: "Medium"
        },
        {
            value: "High", label: "High"
        },

    ];



    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })


        let date = moment(data.start_date).format('YYYY-MM-DD');
        let enddate = moment(data.end_date).format('YYYY-MM-DD');
        let duedate = moment(data.due_date).format('YYYY-MM-DD');

        let dataToSubmit = {
            id: props.editId,
            name: data.project_name,
            description: data.description,
            start_date: date,
            due_date: duedate,
            accounts_id: data.client_id?.id,
            end_date: enddate,
            country_id: data.country?.id,
            scopes: selectedScopes
        };

        let action;

        if (props.editId > 0) {
            console.log("dataToSubmit", dataToSubmit);
            action = Project.update(dataToSubmit)
        } else {
            action = Project.add(dataToSubmit)
        }
        action.then(response => {

            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            props.setEditId()
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }


    const fetchDetails = async () => {
        const response = await Project.getDetails({ project_id: props.editId })
        if (response.data.status === "success") {
            const data = response.data.data
            console.log(data);
            setSelectedScopes(data.scopes?.map(item => item.scope))
            setValue('project_name', data.name)
            setValue('description', data.description)
            if (data.accounts_id) {
                setValue('client_id', {
                    id: data.accounts_id, name: data.account?.account_name
                })
            } if (!data.accounts_id) {
                setValue('client_id', '')
            }
            if (data.country_id) {
                setValue('country', {
                    id: data.country?.id, name: data.country?.name
                })
            }
            setValue('start_date', data?.start_date)

        }

    };





    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetails()
            setOpen(true);
        } else if (props.editId === 0) {
            console.log("truee");
            setOpen(true);
        }

    }, [props.editId])

    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('client_id')])



    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Apply Leave"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Project" : "Apply Leave"}</DialogTitle>
                <DialogContent>
                    {!loading ?
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />

                            <Grid container>

                                <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                                    <TextInput
                                        name="applicant"
                                        label="Applicant"
                                        control={control}
                                        disabled
                                        value={"Ashfak"}
                                    />
                                </Grid>
                                <Grid sx={{ p: 1, }} item xs={12} sm={6}>
                                    <Grid item xs={12}>
                                        <InputLabel sx={{
                                            color: "black",
                                        }}>Attendance Type</InputLabel>
                                        <ReactSelector
                                            label="Choose Client"
                                            options={leaveType.map((leave) => ({
                                                value: leave.value,
                                                label: leave.label
                                            }))}
                                            control={control}
                                            isClearable={true}
                                            error={errors?.client_id?.message}
                                            name="leave_type"
                                            defaultValue={null}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid sx={{ p: 1, }} item xs={12} sm={6}>
                                    <SelectX
                                        label={"Leave Type"}
                                        options={fetchClient}
                                        control={control}
                                        error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                        error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                        name={'client_id'}
                                        defaultValue={watch('client_id')}
                                    />
                                </Grid>

                            </Grid>
                            <Grid container spacing={2} sx={{ display: 'flex', p: 1 }}>
                                <Grid item xs={12} sm={6}>
                                    <CurrentDateInput
                                        control={control}
                                        name="from"
                                        label="From"
                                        value={watch('from')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CurrentDateInput
                                        control={control}
                                        name="to"
                                        label="To"
                                        value={watch('to')}
                                    />
                                </Grid>


                            </Grid>
                            <Grid sx={{ p: 1, }} item xs={12} sm={12}>
                                <SelectX
                                    label={"Manager for Approval"}
                                    options={fetchEmployees}
                                    control={control}
                                    error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                    error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                    name={'manager'}
                                    defaultValue={watch('manger')}
                                />
                            </Grid>


                            <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                                <TextInput
                                    name="reason"
                                    label="Reason"
                                    control={control}
                                    isMultiline
                                    row={2}
                                    value={watch('reason')}
                                />
                            </Grid>


                            <Grid sx={{ p: 1, mb: 3 }} item xs={12}>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            </Grid>



                        </form>
                        :
                        <>Loading...</>
                    }

                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        </div >
    );
};

export default Create
