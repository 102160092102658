import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Select from 'react-select';
import PageHeader from "../common/page-header";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
    Backdrop,
    Button,
    Card,
    CircularProgress,
    Divider,
    List,
    Box,
    Modal,
    IconButton,
    ListItemIcon,
    TextareaAutosize,
    ListItem,
    ListItemText,
    InputLabel,
    TextField,
    Typography
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { SkillsAndHobbie } from '../../../api/Endpoints/SkillsAndHobbie';
import { useEffect } from 'react';
import Delete from '../common/Popup/Delete';
import { LoadingButton } from '@mui/lab';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const options = [
    { value: 'option1', label: 'Hobbie' },
    { value: 'option2', label: 'Learning' },
    { value: 'option3', label: 'Intermediate' },
    { value: 'option4', label: 'Professional' },
    { value: 'option5', label: 'Master' },
];

function SkillandHobbies() {

    const [open, setOpen] = useState(false);
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false);
    const [titleText, settitleText] = useState('');
    const [ExprtaiseLevel, setExprtaiseLevel] = useState('');
    const [PopLoading, setPopupLoading] = useState(false)
    const [textError, setTextError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [ID, setID] = useState(0)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const columns = [
        { field: 'title', headerName: 'Title', width: 200 },
        { field: 'expertise', headerName: 'Expertise', width: 500 },
        {
            field: 'Edit',
            headerName: 'Actions',
            width: 230,
            renderCell: (params) => (
                <>


                    <Button size="small" onClick={() => handleEditButtonClick(params.row.id)}>
                        <EditIcon style={{ color: 'blue', height: 19 }} />
                    </Button>
                    <Button size='small' onClick={() => handleDelete(params.id)}>
                        <DeleteIcon style={{ color: 'red', height: 19 }} />
                    </Button>

                </>
            ),
        },
    ];
    const handleTextChange = (event) => {
        settitleText(event.target.value);
        setTextError('')
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setExprtaiseLevel('')
        settitleText('')
        setTextError('')
        setStatusError('')
        setID(0)
        setOpen(false);
    };

    const handleDelete = (id) => {
        setID(id)
        setDeletePopup(true)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!titleText.trim() && !ExprtaiseLevel) {
            setTextError('field required');
            setStatusError('Please select a status');
            return
        } else if (!ExprtaiseLevel) {
            setStatusError('Please select a status');
            return
        }
        else if (!titleText.trim()) {
            setTextError('Document title is required');
            return
        }
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        const dataToSubmit = {
            title: titleText,
            expertise: ExprtaiseLevel,
        }
        let action
        if (ID > 0) {
            dataToSubmit.id = ID
            action = await SkillsAndHobbie.update(dataToSubmit)
        } else {
            action = await SkillsAndHobbie.add(dataToSubmit)
        }
        if (action.data.message === "Skill has been successfully updated.") {

        }
        setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        fetchDocuments()
        handleClose();
    };
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            padding: '3px', // set the padding value to 10px
            height: '5px'
        })
    };
    useEffect(() => {
        fetchDocuments()
    }, []);


    const fetchDocuments = () => {
        setLoading(true);
        SkillsAndHobbie.get()
            .then(response => {
                setList(response.data.data)
                // setUser({ name: response.data.data.name, email: response.data.data.email });
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });

    }

    const handleEditButtonClick = async (id) => {
        setOpen(true);
        setPopupLoading(true);
        setID(id);
        const response = await SkillsAndHobbie.getDetails({ id: id });
        const data = response.data.data;
        settitleText(data.title);
        setExprtaiseLevel(data.expertise); // Ensure data.expertise is a string or set it to an empty string
        setPopupLoading(false);
    };

    return (
        <div>
            {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                    <CircularProgress size={33} />
                    <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                        Loading...
                    </Typography>
                </Box>
            ) : (
                <>

                    <div className='flex' style={{ width: '100', display: 'flex', justifyContent: "end", marginRight: 10 }}>
                        <Button variant="contained" sx={{ width: "180px", }}
                            onClick={handleOpen}  > Add Skill or Hobbie</Button >
                    </div>


                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >

                        <form onSubmit={handleSubmit}>
                            <Box sx={style}>
                                {PopLoading ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                                        <CircularProgress size={33} />
                                        <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                                            Loading...
                                        </Typography>
                                    </Box>
                                ) : (
                                    <>
                                        <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ textAlign: "center", pb: 5 }}>
                                            Add Skill or Hobbie
                                        </Typography>



                                        <InputLabel sx={{
                                            color: "black",

                                        }}>Enter Skill or Hobbie</InputLabel>
                                        <TextField
                                            aria-label="empty textarea"
                                            multiline
                                            value={titleText}
                                            onChange={handleTextChange}
                                            autoFocus
                                            style={{ width: "100%", marginTop: 8, }}
                                        />
                                        {textError && <p style={{ color: 'red' }}>{textError}</p>}
                                        <Box mt={2}>

                                            <InputLabel sx={{
                                                color: "black",
                                                pb: 1,
                                            }}>Expertise Level</InputLabel>
                                            <Select
                                                placeholder={'sfklsdhfsdfsd'}
                                                options={options}
                                                required
                                                name='skillandhobbie'
                                                styles={customStyles}
                                                value={{ label: ExprtaiseLevel, value: ExprtaiseLevel }}
                                                onChange={(selectedOption) => {
                                                    setExprtaiseLevel(selectedOption.label)
                                                    setStatusError('')
                                                }}
                                            />
                                        </Box>
                                        {statusError && <p style={{ color: 'red' }}>{statusError}</p>}
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <Button
                                                variant="outlined"
                                                color='error'

                                                sx={{
                                                    mt: 2, mr: 2,

                                                }}
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>

                                            <LoadingButton sx={{ mt: 2 }} loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                        </div>

                                    </>
                                )}
                            </Box>
                        </form>

                    </Modal>


                    {showDeletePopup && <Delete
                        ID={ID}
                        setID={setID}
                        setDeletePopup={setDeletePopup}
                        Callfunc={() => fetchDocuments()}
                        url="/employees/skills/delete?id="
                        title="" />}
                    {list?.data?.length !== 0 ? (
                        <Card sx={{ mt: 5 }} variant="outlined">

                            <Card sx={{ mt: 5 }} variant="outlined">
                                {typeof list?.data === "object" && (
                                    <DataGrid
                                        paginationMode="server"
                                        rows={list?.data}
                                        columns={columns}
                                        pageSize={10}
                                        rowHeight={20}
                                        rowsPerPageOptions={[10]}
                                        autoHeight
                                        density={'comfortable'}
                                        getRowHeight={() => 35}
                                    />
                                )}
                            </Card>


                        </Card>) : (
                        <Box mt={10} width={'100%'} textAlign={'center'}>
                            <Typography>Skills and Hobbies not found</Typography>
                        </Box>

                    )}
                </>
            )}
        </div >
    )
}

export default SkillandHobbies
