import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, Card, CircularProgress, Grid, List, Typography } from "@mui/material";
import PageHeader from "../common/page-header";
import Tasks from "./tasks"
import { Users } from "../../../api/Endpoints/Users";
import { Employee } from "../../../api/Endpoints/Employee";
import AsyncSelect from "react-select/async";
import toast from "react-hot-toast";
import { DotSpinner, Waveform } from '@uiball/loaders';

const Index = (props) => {

    const [userId, setUserId] = useState(0);
    const [userType, setUserType] = useState('user');
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(false);


    const fecthCurrentUser = async () => {
        setLoading(true);
        let user = await Users.me();
        setUserId(user.data.data.id);
        setUserType(user.data.data.user_type);
        setLoading(false);
    }

    const handleNewTask = () => {
        props.createNewTask();
    }

    const handleRefresh = () => {
        let user_id = userId;
        toast.success("Refreshing user : " + user_id);
        setLoading(true);
        setUserId(0);
        fecthCurrentUser();
        setLoading(false);
    }
    const fetchEmployees = (e) => {
        if (userType === "Admin") {
            console.log('admin');
            return Employee.get({ keyword: e }).then(response => {
                if (typeof response.data.data !== "undefined") {
                    return response.data.data;
                } else {
                    return [];
                }
            });
        } else {
            return [];
        }
    };



    const handleSpectateChange = (e) => {
        setUserId(e?.id);
    }


    useEffect(async () => {
        fecthCurrentUser();
    }, [searchKeyword, listType, pageNumber]);


    return (
        <>
            <PageHeader refresh onRefresh={handleRefresh} title={"Work board"} />

            {loading ?
                <Box id='loader-circle'>

                    <DotSpinner
                        size={40}
                        speed={0.9}
                        color="blue"
                    />
                </Box > : (

                    <Grid container >
                        {userType === "Admin" &&
                            <Grid item xl={4} md={12} xs={12} sx={{ my: 2, p: 2 }}>
                                <Typography>Spectate other dashboards</Typography>
                                <AsyncSelect
                                    cacheOptions={false}
                                    defaultOptions
                                    loadOptions={fetchEmployees}
                                    getOptionLabel={e => e.name}
                                    getOptionValue={e => e.id}
                                    onChange={handleSpectateChange}
                                />
                            </Grid>
                        }

                        
                        <Grid className="to-review" item xl={12} md={12} xs={12} sx={{ my: 0, p: 2 }} >
                            <Tasks pedndingReview title={"To review"} user_id={userId} onCreateNew={handleNewTask} />
                        </Grid>
                        <Grid className="task-assigned-to-me" item xl={12} md={12} xs={12} sx={{ my: 0, p: 2 }} >
                            <Tasks assignedToME title={"Tasks assigned to me"} user_id={userId} onCreateNew={handleNewTask} />
                        </Grid>
                        <Grid className="task-assigned-by-me" item xl={12} md={12} xs={12} sx={{ my: 0, p: 2 }} >
                            <Tasks asssignedByMe title={"Tasks assigned by me"} user_id={userId} onCreateNew={handleNewTask} />
                        </Grid>
                        <Grid className="task-iam-subscribed-to" item xl={12} md={12} xs={12} sx={{ my: 0, p: 2 }} >
                            <Tasks subscribeByMe title={"Tasks I am Subscribed To"} user_id={userId} onCreateNew={handleNewTask} />
                        </Grid>


                        <Grid item xl={12} md={12} xs={12} sx={{ my: 0, p: 2 }} >
                            {/*<Activity title={"My Activities"} user_id={userId}/>*/}
                        </Grid>
                    </Grid>
                )
            }

        </>
    );
};

export default Index;
