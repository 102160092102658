import { get, post } from './../Config/config';

export const SkillsAndHobbie = {

    get: (data) => get('/employees/skills', { params: data }),
    add: (data) => post('/employees/skills/store', data),
    getDetails: (data) => get('/employees/skills/view?id=', { params: data }),
    update: (data) => post('/employees/skills/update', data)

}
