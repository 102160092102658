
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Input } from '@mui/material';
import { Employee } from "../../../api/Endpoints/Employee";
import { useParams } from 'react-router-dom';
import FileUpload from './FileUpload';
import EmployeesDetials from './employeeDetail';
import SkillandHobbies from './SkillandHobbies';
import EmergencyContact from './EmergencyContact';
import Selfies from './Selfies';


function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {

        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,

    };
}


function Tabview() {

    const [value, setValue] = useState(0);
    const [employeeID, setEmployeeID] = useState()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getID = (id) => {
        setEmployeeID(id)
    }


    return (
        <div>
            <Box sx={{ width: '100%', marginTop: 3, marginLeft: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Employee Details" {...a11yProps(0)} />
                        <Tab label="Documents" {...a11yProps(1)} />
                        <Tab label="Skills and Hobbies" {...a11yProps(2)} />
                        <Tab label="Emergency Contacts" {...a11yProps(3)} />
                        <Tab label="Selfie" {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>

                    <EmployeesDetials getID={getID} />

                </TabPanel>
                <TabPanel value={value} index={1}>
                    <FileUpload />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <SkillandHobbies />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <EmergencyContact />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Selfies employeeID={employeeID} />
                </TabPanel>
            </Box>
        </div>
    )
}

export default Tabview
