import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    TextareaAutosize,
    Select,
    TextField,
    Slide,
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../form/TextInput";
import DateInput from '../../form/DateInput';
import TimeInput from '../../form/DateTime';
import SelectX from "../../form/SelectX";
import { Project } from "../../../api/Endpoints/Project";
import DynamicChip from "../../../utils/DynamicChip";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import { Employee } from "../../../api/Endpoints/Employee";
import CurrentDateInput from '../../form/CurrenDateInput';
import { WorkRequest } from '../../../api/Endpoints/WorkRequest';
import ReactSelector from 'react-select';
import { Departments } from '../../../api/Endpoints/Departments';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({


    title: yup.string().required(),
    description: yup.string(),
    start_date: yup.string(),
    end_date: yup.string(),
    due_date: yup.string(),
    Owner_Id: yup.object(),
    // assigned_by_id: yup.object(),
    projects_id: yup.object().shape({
        id: yup.string().required("Please choose a project").typeError("Please choose a project")
    }).required().typeError("Please choose a project"),
    // reviewer_id: yup.object().shape({
    //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    // }).required().typeError("Please choose a reviewer"),
})




const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => { setOpen(false); };


    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchProjects = (e) => {
        return Project.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const fetchEmployees = (e) => {
        let project = watch('projects_id');
        return Employee.get({ keyword: e, projects_id: project?.id }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                console.log(response.data.data.data);
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const fetchDeparments = (e) => {
        let project = watch('projects_id');
        return Departments.get({ keyword: e, }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                console.log(response.data.data.data);
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const prioriyTask = () => {

    }

        ;


    const handlePriorityChange = (id) => {
        setSelectedPriority(id);
    }


    const options = [
        { value: "New", label: 'New' },
        { value: "On Hold", label: 'On Hold' },
        { value: "Accepted", label: 'Accepted' },
        { value: "Work In Progress", label: 'Work In Progress' },
        { value: "Completed", label: 'Completed' },
        { value: "Cancelled ", label: 'Cancelled ' },
        { value: "Needs Discussion", label: "Needs Discussion" },
    ];

    const RequestType = [
        { value: 'Internal', label: 'Internal' },
        { value: 'Client', label: 'Client' },
    ];

    const priorityOptions = [
        {
            value: "Low", label: "Low"
        },
        {
            value: "Medium", label: "Medium"
        },
        {
            value: "High", label: "High"
        },
        {
            value: "Urgent", label: "Urgent"
        },
        { value: "Critical", label: "Critical" },
    ];



    const costmodel = [
        { value: "No Cost", label: "No Cost" },
        { value: "Fixed Cost", label: "Fixed Cost" },
        { value: "Hourly Cost", label: "Hourly Cost" }
    ]


    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let date = ''
        let closeddate = ''
        let projectdate = ''
        let completion = ''

        if (data.start_date) {
            date = moment(data.start_date).format('YYYY-MM-DD');

        }
        if (data.close_date) {
            closeddate = moment(data.close_date).format('YYYY-MM-DD');
        }

        if (data.project_started_date) {
            projectdate = moment(data.project_started_date).format('YYYY-MM-DD');
        }

        if (data.expected_completion_date) {
            completion = moment(data.expected_completion_date).format('YYYY-MM-DD')
        }




        let dataToSubmit = {
            id: props.editId,
            projects_id: data.projects_id?.id,
            title: data.title,
            description: data.description,
            start_date: projectdate,
            project_started_date: projectdate,
            // status: data.status,
            priority: data.priority,
            // cost_model: data.cost_model,
            request_type: data.request_type,
            assign_to_user_id: data.owner_id?.id,
            expected_completion_date: completion,
            remarks_for_team: data.remarks_for_team,
            remarks_for_client: data.remarks_for_client,
            estimated_hours: data.estimated_hours,
            actual_hours_spent: data.actual_hours_spent,
            closed_on: closeddate,
            status: data.status,
            requested_on: date,
            // accepted_by_user_id:data.assigned_bb_id?.id,
            departments_id: data.department?.id
            // owner_id:data.owner_id?.id

        };


        console.log("dataToSubmit", dataToSubmit);
        let action;

        if (props.editId > 0) {
            action = WorkRequest.update(dataToSubmit);
        } else {
            action = WorkRequest.add(dataToSubmit);
        }
        action.then(response => {
            console.log(response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }



    const fetchWorkRequestDetails = async () => {
        setLoading(true);
        let workrequest = await WorkRequest.getWorkRequestDetails({ id: props.editId });
        if (workrequest.data.status === "success") {
            let data = workrequest.data.data;
            console.log(data.actual_hours_spent);
            setValue('title', data.title);
            setValue('description', data.description);
            setValue('project_started_date', data.start_date ? data.start_date : '');
            setValue('expected_completion_date', data.expected_completion_date ? data.expected_completion_date : '')
            setValue('start_date', data.requested_on ? data.requested_on : '')
            setValue('status', data.status)
            // setValue('cost_model', data.cost_model)
            setValue('actual_hours_spent', data.actual_hours_spent)
            setValue('priority', data.priority)
            setValue('remarks_for_team', data.remarks_for_team)
            setValue('request_type', data.request_type)
            setValue('close_date', data.closed_on ? data.closed_on : '')
            setValue('projects_id', data?.project)
            setValue('owner_id', data?.assigned_to_user)
            // setValue('status', data.status)
            setValue('department', { id: data.department?.id, name: data.department?.name });
            // setValue('assigned_bb_id', { id: data.accepted_by_user?.id, name: data.accepted_by_user?.name });

            if (data.estimated_hours) {
                setValue('estimated_hours',
                    data?.estimated_hours
                )
            }
            if (data.reviewer_id) {

                setValue('reviewer_id', { id: data.reviewer_id, name: data.reviewer?.name });
            }
            if (data.remarks_for_client) {
                setValue('remarks_for_client', data.remarks_for_client)
            }
            if (data.remarks_for_team) {

                setValue('remarks_for_team', data.remarks_for_team)
            }
            setSelectedPriority(data.priority);
        }
        setLoading(false);
    };

    const getProjectDetails = () => {
        Project.getDetails({ project_id: props.projectID }).then((response) => {
            console.log(response);
            setValue('projects_id', response.data.data)
        })
    }

    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('projects_id')])



    useEffect(() => {


        if (parseInt(props.editId) > 0) {
            fetchWorkRequestDetails();
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            if (props.projectID) {
                getProjectDetails()
            }
            setOpen(true);
        }

    }, [props.editId])



    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Add Request"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit work Request" : "Add Work Request"}</DialogTitle>
                <DialogContent>
                    {!loading ?
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="title"
                                    label="Title"
                                    value={watch('title')} />
                            </Grid>

                            <Grid sx={{ p: 1, }} item xs={12}>
                                <SelectX

                                    label={"Choose Project"}
                                    options={fetchProjects}
                                    control={control}
                                    error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                    error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                    name={'projects_id'}
                                    defaultValue={watch('projects_id')}
                                />
                            </Grid>
                            <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1
                                }}>Description </InputLabel>
                                <TextField
                                    {...register('description')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    sx={{ width: '100%', }}
                                    required
                                />
                            </Grid>

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <SelectX
                                    key={refresh * 0.2}
                                    label={"Select Department"}
                                    options={fetchDeparments}
                                    control={control}
                                    // error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                    // error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                    name={'department'}
                                    defaultValue={watch('department')}
                                />
                            </Grid>


                            {/* <Grid sx={{ p: 1 }} item xs={12}>
                                <SelectX
                                    key={refresh * 0.2}
                                    label={"Accepted By"}
                                    options={fetchEmployees}
                                    control={control}
                                    error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                    error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                    name={'assigned_bb_id'}
                                    defaultValue={watch('assigned_bb_id')}
                                />
                            </Grid> */}
                            <Grid sx={{ p: 1 }} item xs={12}>
                                <SelectX
                                    key={refresh * 0.2}
                                    label={"Assign To"}
                                    options={fetchEmployees}
                                    control={control}
                                    error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                    error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                    name={'owner_id'}
                                    defaultValue={watch('owner_id')}
                                />
                            </Grid>


                            <Grid container spacing={2} sx={{ display: 'flex', mt: 1 }}>
                                <Grid item xs={12} sm={6}>
                                    <DateInput
                                        control={control}
                                        name="start_date"
                                        label="Requested Date"
                                        value={watch('start_date')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DateInput
                                        control={control}
                                        name="expected_completion_date"
                                        label="Due Date"
                                        value={watch('expected_completion_date')}
                                    />
                                </Grid>


                            </Grid>
                            <Grid container spacing={2} sx={{ display: 'flex', mt: 1 }}>

                                <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                                    <DateInput
                                        control={control}
                                        name="project_started_date"
                                        label="Started Date"
                                        value={watch('project_started_date')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                                    <DateInput
                                        control={control}
                                        name="close_date"
                                        label="Closed Date"
                                        value={watch('close_date')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ mt: 6 }}>
                                <Grid item sm={6}>

                                    <TimeInput
                                        name="estimated_hours"
                                        control={control}
                                        label="Estimated Hours"
                                        defaultValue={watch('estimated_hours')}
                                    />

                                </Grid>
                                <Grid item sm={6}>
                                    <TimeInput
                                        name="actual_hours_spent"
                                        control={control}
                                        label="Actual Hours Spent"
                                        defaultValue={watch('actual_hours_spent')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid sx={{ p: 1, mt: 2 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1,
                                }}>Remarks For Client
                                </InputLabel>
                                <TextField
                                    {...register('remarks_for_client')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    sx={{ width: '100%', }}
                                    defaultValue={watch('remarks_for_client')}
                                />
                            </Grid>
                            <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1,
                                }}>Internal Remarks </InputLabel>
                                <TextField
                                    {...register('remarks_for_team')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    name='remarks_for_team'
                                    rows={2}
                                    sx={{ width: '100%', }}
                                    defaultValue={watch('remarks_for_team')}
                                />
                            </Grid>


                            {/* Part for Dropdown's   */}

                            {/* <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1,
                                }}>Status</InputLabel>
                                <ReactSelector
                                    options={options}
                                    value={
                                        options.filter(option =>
                                            option.label === watch('status'))
                                    }
                                    name='status'
                                    isClearable={true}
                                    defaultValue={(watch('status'))}
                                    onChange={(selectedOption) => setValue('status', selectedOption?.label || '')}
                                />


                            </Grid> */}
                            <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1,
                                }}>Request Type</InputLabel>
                                <ReactSelector
                                    options={RequestType}
                                    value={
                                        RequestType.filter(requestoptions =>
                                            requestoptions.label === watch('request_type'))
                                    }
                                    name='request_type'
                                    defaultValue={(watch('request_type'))}
                                    isClearable={true}
                                    onChange={(selectedOption) => setValue('request_type', selectedOption?.value || '')}
                                />

                            </Grid>

                            {/* <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1,
                                }}>Cost Model</InputLabel>
                                <ReactSelector
                                    options={costmodel}
                                    value={costmodel.filter(costoptions =>
                                        costoptions.label === watch('cost_model'))}
                                    defaultValue={(watch('cost_model'))}
                                    name='cost_model'
                                    isClearable={true}
                                    onChange={(selectedOption) => setValue('cost_model', selectedOption?.value || '')}
                                />
                            </Grid> */}

                            <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1,
                                }}>Priority</InputLabel>
                                <ReactSelector
                                    options={priorityOptions}
                                    value={
                                        priorityOptions.filter(priorityop =>
                                            priorityop.label === watch('priority'))

                                    }
                                    name='priority'
                                    isClearable={true}
                                    defaultValue={(watch('priority'))}
                                    onChange={(selectedOption) => setValue('priority', selectedOption?.value || '')}
                                />
                            </Grid>
                            <Grid sx={{ p: 1, mt: 1 }} mb={5} item xs={12}>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            </Grid>

                        </form>
                        :
                        <>Loading...</>
                    }

                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        </div >
    );
};

export default Create
