import { get, post } from './../Config/config';


export const ReportIssue = {

    get: (data) => get('/reported-issues?keyword=&show_all=1', { params: data }),
    add: (data) => post('reported-issues/store', data),
    update: (data) => post('/reported-issues/update', data),
    getDetails: (data) => get('/reported-issues/view?id=', { params: data }),
    delete: (data) => get('/reported-issues/delete?id=', { params: data }),
}
