import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Backdrop, Button, Card, CircularProgress, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Task } from "../../../../../api/Endpoints/Task";
import Create from "./create";
import { DeleteOutline } from "@mui/icons-material";
import View from './View';
import DetailView from '../../../tasks/Tabs/Details';
import BasicTabs from '../../../tasks/TabMenus';

const Index = (props) => {

    const [editId, setEditId] = useState();
    const [refresh, setRefresh] = useState(0);
    const [viewPage, setViewPage] = useState(false)
    const [ID, setID] = useState(0)
    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }
    const columns = [

        {
            field: 'title',
            headerName: 'title',
            width: 300,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" onClick={() => ViewTab(id)}>{params.row.title}</Button>
                    </>
                );
            },
        },
        {
            field: 'assigned_to', headerName: 'Assigned to', width: 150,
            valueGetter: (params) => params.row?.assigned_to_user,
            renderCell: (params) => <Button>{params.value?.name}</Button>,
        },
        {
            field: 'assigned_by', headerName: 'Assigned by', width: 150,
            valueGetter: (params) => params.row?.assigned_by_user,
            renderCell: (params) => <Button>{params.value?.name}</Button>,
        },

        {
            field: 'start_date', headerName: 'Start date', width: 150, renderCell: (params) => {

                if (params.value) {

                    return moment(params.value).format('DD-MM-YYYY')
                } else {
                    return "Not started";
                }
            }
        },

        // {field: 'status', headerName: 'Status', width: 100, renderCell: (params) => <>{params.status}</> },
        {
            field: 'action', headerName: 'Edit', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><Button size={'small'}
                    variant='outlined'
                    color='primary'
                    onClick={() => handleEdit(id)}>Edit</Button >
                </>;
            }
        },
        // {
        //     field: 'delete', headerName: 'Delete', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
        //         let id = params.value?.id;
        //         return <><Button size={'small'}
        //             onClick={() => handleDelete(id)}>Delete</Button>
        //         </>;
        //     }
        // },
    ]

    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);

    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    console.log("this is leads", list.data);
    const [loading, setLoading] = useState(false);


    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }


    const ViewTab = (id) => {
        console.log("id here", id);
        // setViewPage(true)
        setID(id)
    }
    const fetchTable = () => {
        setLoading(true);
        Task.get({ keyword: searchKeyword, page: parseInt(pageNumber) + 1, projects_id: props.projectID }).then(response => {
            setList(response.data.data);
            props.setCount(response.data.data.total)
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })
    }

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }


    useEffect(async () => {
        fetchTable();
    }, [searchKeyword, listType, pageNumber])

    return (
        <>
            {
                ID ? <BasicTabs id={ID} handleClose={setID} />
                    : ''
            }


            <Card sx={{ p: 2, display: "flex", justifyContent: "space-between", alignItems: 'center' }} variant="outlined">
                <TextField
                    style={{ width: 300, marginRight: 10 }}
                    id="outlined-name"
                    label="Search Tasks"
                    onChange={handleSearchEntry}

                />

                <Create fetchTable={fetchTable} key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} projectID={props.projectID} />
            </Card >
            <Card sx={{ m: 2 }} variant="outlined">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                {typeof list.data === "object" &&
                    <DataGrid
                        paginationMode="server"
                        rows={list?.data}
                        columns={columns}
                        page={pageNumber}
                        pageSize={10}
                        rowHeight={20}
                        rowsPerPageOptions={[10]}
                        rowCount={list.total}
                        onPageChange={handlePageChange}
                        autoHeight
                        density={"comfortable"}
                    />
                }
            </Card>
        </>
    );
};

export default Index;
