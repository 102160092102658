import logo from './logo.svg';
import './App.css';
import './Custom.css';
import { useEffect, useState } from "react";
import { createCustomTheme } from './theme';
import { useRoutes } from "react-router-dom";
import routes from './routes'
import { CssBaseline, ThemeProvider } from "@mui/material";
import { RTL } from "./components/rtl";
import { useAuth } from "./hooks/use-auth";
import { restoreSettings, useSettings } from "./contexts/Settings/settings-context";
import { useAppSettings } from "./hooks/use-app-settings";
import { Users } from "./api/Endpoints/Users";
import { MeetingeDProvider } from './contexts/MeetingID/meetingID';
import { ProjectProvider } from './contexts/Project ID/projectID';
import { UserIDProvider } from './contexts/UserID/UserID';
// import './messaging_init_in_sw';

function App() {

  const auth = useAuth();
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const { isInitialized } = useAuth();

  const [isTokenFound, setTokenFound] = useState(false);

  //Lead type check
  const appSettings = useAppSettings();
  let lead_type_id = localStorage.getItem('leadType')
  let navbar_color = appSettings.get_navbar_color()


  const theme = createCustomTheme({
    direction: settings.direction,
    theme: 'light'
  });

  useEffect(() => {

  }, [])


  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
       <UserIDProvider>
          <ProjectProvider>
            <MeetingeDProvider>
              {isInitialized && content}
            </MeetingeDProvider>
          </ProjectProvider>
       </UserIDProvider>
      </RTL>
    </ThemeProvider>
  );
}

export default App;
