import React, { useRef } from 'react';
import { useState } from 'react';

import { Button, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { MeetingIdContext } from '../../../../../contexts/MeetingID/meetingID';
import { Employee } from '../../../../../api/Endpoints/Employee';
import { Meetings } from '../../../../../api/Endpoints/Meeting';
import TextInput from '../../../../form/TextInput';

// Using Create For Minutes
function CreateMinute({ meetingID, fetchList,DetailView }) {
  const validationSchema = yup.object().shape({
    title: yup.string().required("Minutes is required"),
    // Add validation rules for other fields if needed
  });


  const { register, handleSubmit, watch, formState: { errors }, control, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) });
  const { MeetingID } = MeetingIdContext();
  const [err, setErr] = useState(false);
  const [value, setValue] = useState('');
  const [rowCount, setRowCount] = useState(1);
  const [formButtonStatus, setFormButtonStatus] = useState({
    label: 'ADD',
    loading: false,
    disabled: false,
  });
  const formRef = useRef();
  const onSubmit = async () => {
    if (!value.trim()) {
      setErr(true);
      return;
    }
    setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
    let meetID = null
    if (meetingID) {
      meetID = meetingID
    } else {
      meetID = MeetingID
    }
    console.log(value);
    // const dataToSubmit = {
    //   meetings_id: meetID,
    //   title: value,
    // }
    // setValue('')
    // setRowCount(1)
    // let action;
    // action = await Meetings.addMeetingMinutes(dataToSubmit);

    // fetchList().then(() => {
    //   toast.success('Meeting Minutes have been successfully saved.')

    // })
    // let id=action?.data?.data?.id
    // setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
  }

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    setErr(false);
  };

  const handleKeyDown = (event) => {

    if (event.shiftKey && event.keyCode === 13) {
      event.preventDefault();
      setRowCount(rowCount + 1);
      setValue(value + '\n');

    } else if (!event.shiftKey && event.keyCode === 13) {
      event.preventDefault();
      onSubmit()

    } else if (event.keyCode === 8 && value.endsWith('\n')) {
      setRowCount(rowCount - 1);
    }
  };



  return (
    <div className='cra-minutes'>
      <form ref={formRef} > {/* Add ref to the form */}
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={12}>
            <textarea
              autoFocus
              value={value}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              style={{
                width: '456px',
                padding: '10px',
                borderRadius: '8px',
                outline: 'none',
                lineHeight: '19px'
              }}
              rows={rowCount}

            />
            {err && <p style={{ color: 'red', padding: 0, margin: 0, fontSize: "14px", paddingBottom: 10, }}>{"please enter the minutes"}</p>}

            <div style={{ fontSize: '12px', color: 'gray' }}>
              * Press Shift + Enter for a new line.
              <br />
              * Press Enter to submit data.
            </div>
          </Grid>
          <Grid item xs={12} sm={6} mt={0.1}>
            <LoadingButton
              sx={{ display: 'none' }}
              loading={formButtonStatus.loading}
              disabled={formButtonStatus.disabled}
              type="submit"
              variant="outlined"

            >
              {formButtonStatus.label}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div >
  );
};



export default CreateMinute;