import { get, post } from './../Config/config';

export const ReimbursementAPI = {

    get: (data) => get('/accounts/reimbursements', { params: data }),
    getDetails: (data) => get('/accounts/reimbursements/view?id=', { params: data }),
    add: (data) => post('/accounts/reimbursements/store', data),
    update: (data) => post('/accounts/reimbursements/update', data),


}
