import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Select from 'react-select';
import PageHeader from "../common/page-header";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
    Backdrop,
    Button,
    Card,
    CircularProgress,
    Divider,
    List,
    Box,
    Modal,
    IconButton,
    ListItemIcon,
    TextareaAutosize,
    ListItem,
    ListItemText,
    InputLabel,
    TextField,
    Typography
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { EmergencyContactAPI } from '../../../api/Endpoints/EmergencyContacts';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import Delete from '../common/Popup/Delete';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const options = [
    { value: 'option1', label: 'Hobbie' },
    { value: 'option2', label: 'Learning' },
    { value: 'option3', label: 'Intermediate' },
    { value: 'option4', label: 'Professional' },
    { value: 'option5', label: 'Master' },
];

function EmergencyContact() {

    const [open, setOpen] = useState(false);
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState({
        contactName: '',
        phoneNumber: '',
        relationShip: ''
    })
    const [PopLoading, setPopupLoading] = useState(false)
    const [ContactName, setContactName] = useState('')
    const [Relationship, setRelationShip] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [ID, setID] = useState(0)
    const columns = [
        { field: 'contact_name', headerName: 'Contact Name', width: 400 },
        { field: 'phone_number', headerName: 'Phone Number', width: 300 },
        {
            field: 'Edit',
            headerName: 'Actions',
            width: 230,
            renderCell: (params) => (
                <>


                    <Button size="small" onClick={() => handleEditButtonClick(params.row.id)}>
                        <EditIcon style={{ color: 'blue', height: 19 }} />
                    </Button>
                    <Button size='small' onClick={() => handleDelete(params.id)}>
                        <DeleteIcon style={{ color: 'red', height: 19 }} />
                    </Button>

                </>
            ),
        },
    ];
    ;

    const handleOpen = () => {
        setOpen(true);
    };

    const handleDelete = (id) => {
        setID(id)
        setDeletePopup(true)
    }

    const handleClose = () => {
        setContactName('');
        setPhoneNumber('')
        setRelationShip('')
        setErr('')
        setID(0)
        setOpen(false);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!ContactName && !Relationship && !phoneNumber) {
            setErr(prevErr => ({
                ...prevErr,
                contactName: 'field is requried',
                relationShip: 'field is requried',
                phoneNumber: 'field is requried'

            }));
            return
        }


        if (!phoneNumber) {
            setErr(prevErr => ({
                ...prevErr,
                phoneNumber: 'field is requried'
            }));
            return
        }

        if (!Relationship) {
            setErr(prevErr => ({
                ...prevErr,
                relationShip: 'field is requried'
            }));
            return
        }

        if (!ContactName) {
            setErr(prevErr => ({
                ...prevErr,
                contactName: 'field is requried'
            }));
            return
        }


        const dataToSubmit = {
            contact_name: ContactName,
            phone_number: phoneNumber,
            relationship: Relationship
        }
        let action
        if (ID > 0) {
            dataToSubmit.id = ID
            action = await EmergencyContactAPI.update(dataToSubmit)
        } else {
            action = await EmergencyContactAPI.add(dataToSubmit)
        }
        if (action.data.message === "Emergency contact has been successfully updated.") {
            toast.success('Emergency contact has been successfully updated.')
        } else if (action.data.message === 'New emergency contact has been successfully saved.') {
            toast.success('New emergency contact has been successfully saved.')
        } else {
            toast.error('Something went wrong!')
        }

        fetchEmergencyContacts()
        handleClose();
    };
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            padding: '3px', // set the padding value to 10px
            height: '5px'
        })
    };
    useEffect(() => {
        fetchEmergencyContacts()
    }, []);

    const fetchEmergencyContacts = async () => {
        setLoading(true);

        try {
            const response = await EmergencyContactAPI.get();
            setList(response.data.data);
            // setUser({ name: response.data.data.name, email: response.data.data.email });
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const handleEditButtonClick = async (id) => {

        setOpen(true);
        setPopupLoading(true);
        setID(id);
        const response = await EmergencyContactAPI.getDetails({ id: id });
        const data = response.data.data;
        setContactName(data.contact_name);
        setPhoneNumber(data.phone_number)
        setRelationShip(data.relationship)

        setPopupLoading(false);
    };

    return (
        <div>
            {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                    <CircularProgress size={33} />
                    <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                        Loading...
                    </Typography>
                </Box>
            ) : (
                <>

                    {showDeletePopup && <Delete
                        ID={ID}
                        setID={setID}
                        setDeletePopup={setDeletePopup}
                        Callfunc={() => fetchEmergencyContacts()}
                        url="/employees/emergency-contacts/delete?id="
                        title="Document" />}
                    <div className='flex' style={{ width: '100', display: 'flex', justifyContent: "end", marginRight: 10 }}>
                        <Button variant="contained" sx={{ width: "200px", }}
                            onClick={handleOpen}  >Add Emergency Contact</Button >
                    </div>

                    {showDeletePopup && <Delete
                        ID={ID}
                        setID={setID}
                        setDeletePopup={setDeletePopup}
                        Callfunc={() => fetchEmergencyContacts()}
                        url="/employees/emergency-contacts/delete?id="
                        title="Contact" />}
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >

                        <Box sx={style}>
                            {PopLoading ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                                    <CircularProgress size={33} />
                                    <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                                        Loading...
                                    </Typography>
                                </Box>
                            ) : (
                                <>
                                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ textAlign: "center", pb: 5 }}>
                                        Emergency Contact Form
                                    </Typography>
                                    <form onSubmit={handleSubmit}>


                                        <TextField
                                            id="text-input"
                                            label="Contact Name"
                                            variant="outlined"
                                            value={ContactName}
                                            onChange={(event) => setContactName(event.target.value)}
                                            fullWidth
                                            margin="normal"
                                            sx={{ mt: 2 }}
                                            autoFocus
                                        />
                                        {err.contactName && <p style={{ color: 'red', margin: 0 }}>{err.contactName}</p>}

                                        <TextField
                                            id="text-input"
                                            label="Phone Number"
                                            variant="outlined"
                                            value={phoneNumber}
                                            fullWidth
                                            onChange={(event) => setPhoneNumber(event.target.value)}
                                            margin="normal"

                                            sx={{ mt: 2 }}
                                        />
                                        {err.phoneNumber && <p style={{ color: 'red', margin: 0 }}>{err.phoneNumber}</p>}
                                        <TextField
                                            id="text-input"
                                            label="Relationship"
                                            value={Relationship}
                                            variant="outlined"
                                            onChange={(event) => setRelationShip(event.target.value)}

                                            fullWidth
                                            margin="normal"
                                            sx={{ mt: 2 }}
                                        />
                                        {err.relationShip && <p style={{ color: 'red', margin: 0 }}>{err.relationShip}</p>}
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <Button
                                                variant="outlined"
                                                color='error'
                                                sx={{
                                                    mt: 2, mr: 2,

                                                }}
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>

                                            <Button variant="outlined" type="submit" sx={{ mt: 2 }}>
                                                Submit
                                            </Button>

                                        </div>
                                    </form>
                                </>
                            )}
                        </Box>


                    </Modal>


                    {list?.data?.length !== 0 ? (
                        <Card sx={{ mt: 5 }} variant="outlined">
                            {typeof list?.data === "object" &&
                                <DataGrid
                                    paginationMode="server"
                                    rows={list?.data}
                                    columns={columns}
                                    // page={pageNumber}
                                    pageSize={10}
                                    rowHeight={20}
                                    rowsPerPageOptions={[10]}
                                    // onPageChange={handlePageChange}
                                    autoHeight
                                    density={'comfortable'}
                                    getRowHeight={() => 35}
                                />
                            }

                        </Card>
                    ) : (
                        <Box mt={10} width={'100%'} textAlign={'center'}>
                            <Typography>Emergency Contacts not found</Typography>
                        </Box>

                    )}
                </>
            )}
        </div >
    )
}

export default EmergencyContact
