import React, { useEffect, useState } from 'react';
import { useAppSettings } from "../../../hooks/use-app-settings";
import { Backdrop, Button, Card, CircularProgress, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import Create from './create';
import { Documents as Docs } from '../../../api/Endpoints/Documents';
import Delete from '../common/Popup/Delete';
import moment from 'moment/moment';

const Documents = (props) => {
    const appSettings = useAppSettings();

    const columns = [
        // { field: 'id', headerName: 'ID' },
        {
            field: 'title', headerName: 'Title', width: 300, renderCell: (params) => (
                <>
                    <a
                        style={{ marginLeft: 1 }} onClick={() => viewTabs(params.id)}>
                        {params.row.title}
                    </a>
                </>
            )
        },
        // {
        //     field: 'url', headerName: 'URL', width: 400,
        //     renderCell: (params) => (
        //         <a target='_blank' href={params.row.url}>{params.row.url}</a>
        //     )
        // },


        // {
        //     field: 'Edit',
        //     // headerName: 'Edit',
        //     width: 100,
        //     renderCell: (params) => (
        //         <>
        //             <Button variant="contained"
        //                 size="small"
        //                 style={{ marginLeft: 1 }} onClick={() => handleEdit(params.id)}>
        //                 Edit
        //             </Button>
        //         </>
        //     )
        // },
        // {
        //     field: 'Delete',
        //     // headerName: 'Delete',
        //     width: 100,
        //     renderCell: (params) => (
        //         <>
        //             <Button variant="contained"
        //                 color='error'
        //                 size="small"
        //                 style={{ marginLeft: 1 }} onClick={() => handleDelete(params.id)}>
        //                 Delete
        //             </Button>
        //         </>
        //     )
        // }

    ]

    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [ClientID, setClientID] = useState(0)
    const [deleteId, setDeleteId] = useState(false)
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleDelete = (id) => {
        setDeleteId(id)
    }
    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }
    const fetchTable = () => {
        setLoading(true);
        Docs.getHR({ keyword: searchKeyword, page: Number(pageNumber) + 1 }).then(response => {
            console.log(response);
            setList(response.data.data);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })


    }

    const handleCreateNew = () => {

    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(async () => {
        // fetchTable();
    }, [searchKeyword, listType, pageNumber])

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }


    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const viewTabs = (id) => {
        // Website_live_tracker.getDetails({id:id}).then((response)=>{
        //     console.log(response);
        // })
        // setTabs(true)
    }

    return (
        <>
            {/* {Tabs ? (<BasicTabs setTabs={setTabs} ClientID={ClientID} />
            ) : ( */}

            {
                deleteId &&
                <Delete
                    ID={deleteId}
                    setID={setDeleteId}
                    setDeletePopup={setDeleteId}
                    Callfunc={() => fetchTable()}
                    url="websites/delete?id="
                    title="Url"
                />
            }

            <>
                <PageHeader title={"Documents"} onCreateNew={handleCreateNew} />

                <Card sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'space-between' }} variant="outlined" >

                    <TextField
                        id="outlined-name"
                        label="Search Document"
                        onChange={handleSearchEntry}
                    />
                    {/* <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} /> */}

                </Card>
                <Card sx={{ m: 2 }} variant="outlined">
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    {typeof list.data === "object" &&
                        <DataGrid
                            paginationMode="server"
                            rows={list?.data}
                            columns={columns}
                            page={pageNumber}
                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            rowCount={list.total}
                            onPageChange={handlePageChange}
                            autoHeight
                            density={"comfortable"}
                        />
                    }
                </Card>
            </>
            {/* )} */}

        </>

    );
};

export default Documents;
