import { Box, Button, DialogActions, Divider, Grid, Slide, Typography, } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Task } from '../../../../api/Endpoints/Task';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { DeleteForever, Edit } from '@material-ui/icons';
import Delete from '../../common/Popup/Delete';
import parse from 'html-react-parser';
import { Auth } from '../../../../api/Endpoints/Auth';
import { Users } from '../../../../api/Endpoints/Users';
import { DotSpinner, Waveform } from '@uiball/loaders';
import { VideoShootApi } from '../../../../api/Endpoints/VideoShoot';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const divider = {
    marginTop: 2,
}

const DetailView = (props) => {

    const classes = useStyles();
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const [open, setOpen] = React.useState(true);
    // const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => {
        props.handleClose()
        // setOpen(false); 
    };
    const [details, setDetails] = useState()
    const [loading, setLoading] = useState(false);
    const [Notes, setNotes] = useState([])
    const [deleteID, setDeleteID] = useState(false)
    const [refresh, setRefresh] = useState()



    const handleEdit = (id) => {
        console.log(id);
        props.setEditId(id)
        props.setTab(1)
    }

    const handleRefresh = () => {
        setRefresh(Math.random)
    }

    const fetchDetails = () => {
        console.log(props.id);
        setLoading(true)
        VideoShootApi.getDetails({ id: props.id }).then((response) => {
            // fetchNotes()
            console.log(response);
            setDetails(response.data.data)
            setLoading(false)
            console.log(response.data.data);
        }).catch(errors => {
            console.log(errors);
            setLoading(false)
        })

    }

    const [deleteId, setDeleteId] = useState(false)
    const handleDelete = (id) => {
        setDeleteId(details?.id)
    }

    const afterDelete = () => {
        props.handleClose()
        props?.fetchList()
    }


    useEffect(() => {
        fetchDetails()
    }, [props.id, refresh, props.refresh])

    return (
        <>
            {deleteId && <Delete
                ID={deleteId}
                setID={setDeleteId}
                setDeletePopup={setDeleteId}
                Callfunc={() => afterDelete()}
                url="/projects/video-shoots/delete?id="
                title="Video Shoot" />}


            {loading ? (
                <Grid mt={'40%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Waveform
                        size={40}
                        lineWeight={3.5}
                        speed={1}
                        color="black"
                    />
                </Grid>
            ) :
                (<>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                Title:
                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {details?.title}
                            </Typography>

                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Project:

                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {details?.project?.name}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Status:
                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                                {details?.status}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Shooting Date:

                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {details?.shooting_date ? moment(details?.shooting_date).format("DD-MM-YYYY") : ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                No of Days:
                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                                {details?.no_of_shoot_days}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Created User:

                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {details?.created_user?.name}
                            </Typography>

                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                Deliverables:
                            </Typography>
                            <Typography variant="body2" style={{ fontSize: '16px' }}>
                                <a>{details?.deliverables}</a>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                Equipments:
                            </Typography>
                            <Typography variant="body2" style={{ fontSize: '16px' }}>
                                <a>{details?.equipments}</a>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                Description:
                            </Typography>
                            <Typography variant="body2" style={{ fontSize: '16px' }}>
                                <a>{details?.description && parse(details?.description)}</a>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />

                    {/* <Grid display={'flex'} >
                        <Button color='error' variant='outlined' size='small'>Delete</Button>

                    </Grid> */}

                    {/* <DialogActions> */}
                    <Grid display={'flex'} justifyContent={'space-between'} alignItems='center'>
                        <Button onClick={handleDelete} sx={{ height: '25px', fontSize: '11px' }} color='error' variant='outlined' size='small'>Delete</Button>
                        <Button onClick={handleClose} >Close</Button>
                    </Grid>
                    {/* </DialogActions> */}

                </>)
            }


        </>
    )
}

export default DetailView;
