import { Edit } from '@mui/icons-material';
import { Avatar, Grid, TablePagination } from '@mui/material'
import { deepPurple } from '@mui/material/colors';
import React from 'react'

function CalenderList({ data, page, handleChangePage, handleEdit,handleOpenPosts }) {

    const style = {
        borderBottom: '1px solid #e8e8e8',
        cursor:'pointer'
    };

    return (
        <>
            <Grid style={{ borderTop: '3px solid #e8e8e8',borderRight: '3px solid #e8e8e8' }}>
                <Grid container style={{ maxHeight: '69vh', height: '69vh', overflowY: 'auto'}}>
                    {
                        data?.data?.map((obj, index) => (
                            <Grid style={style} className='calendar-container' onClick={()=>handleOpenPosts(obj?.id)} md={12} key={index} >
                                <Grid container p={1.5} display={'flex'} alignItems={'center'}>
                                    <Grid item md={2}>
                                        <Avatar sx={{ bgcolor: deepPurple[400], color: "#FFF" }} >{obj?.name?.slice(0, 1).toString().toUpperCase()}</Avatar>
                                    </Grid>
                                    <Grid item md={10} >
                                        <Grid mt={1} display={'flex'} justifyContent={'space-between'}>
                                            <Grid item md={11}>
                                                <a style={{ fontWeight: 600 }}>{obj?.name}</a>
                                            </Grid>
                                            <Grid className='edit-calendar' item md={1}>
                                                <a onClick={(e) => handleEdit(e,obj?.id)}>
                                                    <Edit style={{ cursor: 'pointer', color: 'blue', fontSize: '13px' }} fontSize='small' />
                                                </a>
                                            </Grid>
                                        </Grid>
                                        <Grid mt={1} display={'flex'} justifyContent={'space-between'}>
                                            <Grid item md={6}>
                                                <a style={{ fontSize: '13px', color: 'grey' }}>{obj?.project?.name}</a>
                                            </Grid>
                                            <Grid display={'flex'} justifyContent={'end'} item md={6}>
                                                <a style={{ fontSize: '13px', color: 'grey' }}>{obj?.calendar_month} {obj?.calendar_year}</a>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
           
        </>
    )
}

export default CalenderList
