import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, Card, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import TextInput from '../../../form/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import TimeInput from '../../../form/DateTime';


const useStyles = makeStyles({
    customTextField: {
        height: '10px', // Adjust the height as needed
    },
});

const MissedSign = (props) => {

    const classes = useStyles();

    const validationSchema = yup.object().shape({
        activity: yup.string().required('Activity is required'),
        // hours: yup.number(),
        // hours: yup.number(),
        // Add validation rules for other fields if needed
    });
    const formRef = useRef();
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) });
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [ClientID, setClientID] = useState(0)
    const [deleteId, setDeleteId] = useState(false)
    const [tasks, setTasks] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // You can adjust the number of rows per page as needed

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset the page to the first page when changing rows per page
    };
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    // const handlePageChange = (page) => {
    //     props.setPage(page)
    // }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleDelete = (id) => {
        setDeleteId(id)
    }
    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }


    const handleCreateNew = () => {

    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(async () => {

    }, [searchKeyword, listType, pageNumber])

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }


    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const viewTabs = (id) => {
        setClientID(id)
        setTabs(true)
    }

    // const copyTask = (data) => {
    //     // console.log(data);
    //     sessionStorage.setItem('workTitle', data.title)
    //     props.setTasks(data)
    //     props.setTab(0)
    // }

    const dates = [
        { date: 'Nov 1 2023' },
        { date: 'Nov 2 2023' },
        { date: 'Nov 3 2023' },
        { date: 'Nov 4 2023' },
        { date: 'Nov 5 2023' },
    ]



    useEffect(() => {
        // props.setTasks(data)

    }, [])


    return (
        <>
            <TableContainer component={Paper} style={{ marginTop: '10px', overflowY: 'scroll', height: 410, maxHeight: 410 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={'14%'}>Date</TableCell>
                            <TableCell></TableCell>
                            <TableCell>Sign In Time</TableCell>
                            <TableCell>Sign Out Time</TableCell>
                            <TableCell>Reason for Delay</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dates.map((date) => (

                                <TableRow>
                                    <TableCell sx={{ p: 2 }}>{date.date}</TableCell>
                                    <TableCell>
                                        <Button variant='contained'>
                                            Apply Leave
                                        </Button>
                                    </TableCell>
                                    <TableCell sx={{ p: 2 }} width={'20%'}>
                                        <TimeInput
                                            name="signInTime"
                                            control={control}
                                            // label="Estimated Hours"
                                            defaultValue={watch('signInTime')}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ p: 2 }} width={'20%'}>
                                        <TimeInput
                                            name="signOutTime"
                                            control={control}
                                            // label="Estimated Hours"
                                            defaultValue={watch('signOutTime')}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ p: 2 }} width={'33%'}>
                                        <TextInput
                                            control={control}
                                            // placeholder={"Add Work Plan"}
                                            name="reason"
                                            value={watch('reason')}
                                        />
                                    </TableCell>

                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {/* <TablePagination
                    component="div"
                    count={props.tasks.total}
                    rowsPerPage={15}
                    page={props.page}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[]}
                    labelRowsPerPage="" // This will hide the "Rows per page" label
                    SelectProps={{ native: true }} // This will hide the select dropdown
                /> */}
            </TableContainer>
        </>

    );
};

export default MissedSign;
