import React, { useState } from 'react';


function SiteVisits() {{

    return (
        <div>
            <h5>Site Visits</h5>
        </div >
    );
}}

export default SiteVisits;
