import React, { useEffect, useState } from 'react';
import { Backdrop, Badge, Box, Button, Card, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItemText } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Project } from "../../../../api/Endpoints/Project";
import { DeleteOutline } from "@mui/icons-material";
import { EditOutlined } from "@mui/icons-material";
import AddTeamMember from "./addTeamMember";
import { toast } from 'react-hot-toast';
import { DotSpinner } from '@uiball/loaders';

function Addmembers({ projectID, setCount }) {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemToDelete, setItemToDelete] = useState();
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
    const [editID, setEdit] = useState(0)
    const handleDeleteAlert = (id) => {
        setItemToDelete(id);
        setOpenConfirmation(true);
    };

    const project_roles = [
        { id: 1, name: "Manager" },
        { id: 2, name: "Member" },
        { id: 3, name: "Guest" },
        { id: 4, name: "Client" },
        { id: 5, name: "Manager" },
        { id: 6, name: "Third Party" },
    ]


    const handleConfirmationClose = () => {
        setOpenConfirmation(false);
    };

    const handleDelete = async () => {
        setDeleteButtonLoading(true);
        try {
            const response = await Project.removeEmployee({ id: itemToDelete });
            if (response.data.status === "success") {
                toast.success(response.data.message);
            } else {
                toast.error("Unable to remove the member from the project");
            }
            setOpenConfirmation(false);
            fetchList();
        } catch (error) {
            console.error("Error deleting member:", error);
        }
        setDeleteButtonLoading(false);

    };

    const columns = [
        { field: 'name', headerName: 'Member', width: 250 },
        {
            field: 'user_role',
            headerName: 'Role',
            width: 200,
            valueGetter: (params) => {
                const selectedRole = params.row.user_role; // Assuming user_role is the numeric role ID
                const matchingRole = project_roles.find(role => parseInt(role.id) === parseInt(selectedRole));
                return matchingRole ? matchingRole.name : ' ';
            },
        },

        {
            field: '',
            headerName: '',
            width: 200,
            renderCell: (params) => {
                if (params?.row?.is_project_owner === 1) {
                    return (
                        <div style={{ backgroundColor: 'rgb(204,251,241)', borderRadius: '10px', padding: '4px', color: 'rgb(41,136,127)', textAlign: 'center' }}>
                            Project Owner
                        </div>
                    );
                }
                return null;
            }
        }

        ,
        {
            field: 'action', headerName: 'Action', width: 150,
            valueGetter: (params) => params.row,
            renderCell: (params) => {
                let id = params.value?.project_users_id;
                return (
                    <>
                        <Button style={{ color: 'black' }} size={'small'} onClick={() => handleEdit(id)}>
                            <EditOutlined fontSize='small' />
                        </Button>
                        <Button style={{ color: 'black' }} size={'small'} onClick={() => handleDeleteAlert(id)}>
                            <DeleteOutline fontSize='small' />
                        </Button>
                    </>
                );
            }
        },
    ];

    const fetchList = async () => {
        try {
            setLoading(true);
            const response = await Project.getEmployees({ projects_id: projectID });
            if (response.data.status === "success") {
                setList(response.data.data.data);
                setCount(response?.data?.data?.total)

            }
        } catch (error) {
            console.error("Error fetching list:", error);
        }
        setLoading(false);
    };

    const handleEdit = (id) => {
        setEdit(id)
    }

    useEffect(() => {
        fetchList();
    }, [projectID, refresh]);

    return (
        <div>
            <Grid display={'flex'} width={"96%"} justifyContent={'end'}>

                <AddTeamMember projectID={projectID} setRefresh={setRefresh} fetchList={fetchList} editID={editID} setEditID={setEdit} />


            </Grid>

            {loading ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '30vh'
                }}>
                    <DotSpinner
                        size={40}
                        speed={0.9}
                        color="blue"
                    />
                </Box >
            ) : (
                <Card sx={{ m: 2 }} variant="outlined">
                    {list.length > 0 ? (
                        <DataGrid
                            paginationMode="server"
                            rows={list}
                            columns={columns}
                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            autoHeight
                            density={'comfortable'}
                        />
                    ) : (
                        <List dense={true}><ListItemText>No members added for this project</ListItemText></List>
                    )}
                </Card>
            )}

            <Dialog
                open={openConfirmation}
                onClose={handleConfirmationClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Please confirm your action</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are going to remove a team member from this project. You can add the same member later.
                        Please confirm the action by clicking on the confirm button.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmationClose}>Cancel</Button>
                    <Button onClick={handleDelete} autoFocus disabled={deleteButtonLoading}>
                        {deleteButtonLoading ? (
                            <CircularProgress color="inherit" size={20} />
                        ) : (
                            'Confirm'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Addmembers;
