import React, { useEffect, useState } from 'react';
import {
    Backdrop,
    Box,
    Button, Card,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid, Icon, List, ListItemText,
    Slide, Typography
} from "@mui/material";
import SelectX from "../../../form/SelectX";
import { Project } from "../../../../api/Endpoints/Project";
import DynamicChip from "../../../../utils/DynamicChip";
import { Employee } from "../../../../api/Endpoints/Employee";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Label from "../../../../components/label";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { DeleteOutline } from "@mui/icons-material";
import { EditOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { FormHelpers } from "../../../../helpers/FormHelpers";
import toast from "react-hot-toast";
import TextInput from '../../../form/TextInput';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
    // employees_id: yup.array().of(
    //     yup.object().shape({
    //         id: yup.string().required()
    //     })
    // ).required()
})

const project_roles = [
    { id: 1, name: "Manager" },
    { id: 2, name: "Member" },
    { id: 3, name: "Guest" },
    { id: 4, name: "Client" },
    { id: 5, name: "Manager" },
    { id: 6, name: "Third Party" },
]


const AddTeamMember = ({ projectID, setRefresh, fetchList, editID, setEditID }) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [list, setList] = useState([]);
    const [roles, setRoles] = useState([]);

    const [selectedRole, setSelectedRole] = useState(2);

    const [loading, setLoading] = useState(false);
    const [saveAndAdd, setsaveAndAdd] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const [projectOwner, setProjectOwner] = useState(false);

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Add",
        loading: false,
        disabled: false,
    });
    const [addNewButtton, setaddNewButtton] = useState({
        label: "Add",
        loading: false,
        disabled: false,
    });

    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => {
        setSelectedRole(null)
        setProjectOwner(false)
        setOpen(false);
        setTimeout(() => {
            setEditID(0)
        }, 500)

    };

    const handleConfirmationOpen = () => { setOpenConfirmation(true); };
    const handleConfirmationClose = () => { setOpenConfirmation(false); };

    const fetchEmployess = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const handleRoleChange = (id) => {
        setSelectedRole(id);
    }

    //Delete module
    const [itemToDelete, setItemToDelete] = useState();
    const handleDeleteAlert = (id) => { setItemToDelete(id); setOpenConfirmation(true); }


    const columns = [
        { field: 'name', headerName: 'Name', width: 100, },
        { field: 'user_role', headerName: 'Role', width: 100, },
        { field: 'email', headerName: 'Email', width: 200 },

        {
            field: 'action', headerName: 'Action', width: 100,
            valueGetter: (params) => params.row,
            renderCell: (params) => {
                let id = params.value?.project_users_id;
                return <><Button size={'small'}
                    onClick={() => handleDeleteAlert(id)}><DeleteOutline /></Button>
                </>;
            }
        },
    ]

    useEffect(() => {
        if (editID > 0) {
            fetchDetails()
            setOpen(true)
        }
        return () => {
            setValue('employees_id', '')
        }
    }, [editID])

    const handleSaveAndAdd = () => {
        onSaveandAddNew()
    }

    const handleSave = () => {
        onSubmit()
    }


    const fetchDetails = async () => {
        setLoading(true)
        const response = await Project.GetassignedEmployee({ id: editID });
        setValue('employees_id', response.data.data.user)
        setSelectedRole(response.data.data.user_role)
        setProjectOwner(response.data.data.is_project_owner ? true : false)
        setLoading(false)
    }
    // console.log(saveAndAdd);

    const onSubmit = async () => {
        let find = project_roles.filter(o => o.id === selectedRole);
        let dataToSubmit = {
            projects_id: projectID,
            users_id: watch('employees_id')?.id,
            user_role: find[0]?.id,
            is_project_owner: projectOwner ? 1 : 0
        };

        console.log("data to submit", dataToSubmit);

        setFormButtonStatus(prevState => ({ ...prevState, loading: true }));

        try {
            let assign = await Project.assignEmployee(dataToSubmit);
            if (assign.data?.status === "success") {
                toast.success(assign.data?.message);
            } else {
                throw new Error("Failed to add the user");
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setFormButtonStatus(prevState => ({ ...prevState, loading: false }));
            setaddNewButtton(prevState => ({ ...prevState, loading: false }));
            handleClose();
            setOpen(false);
            setValue('employees_id', '');
            setProjectOwner(false);
            setSelectedRole(2);

            fetchList();
        }
    };

    const onSaveandAddNew = async () => {
        let find = project_roles.filter(o => o.id === selectedRole);
        let dataToSubmit = {
            projects_id: projectID,
            users_id: watch('employees_id')?.id,
            user_role: find[0]?.id,
            is_project_owner: projectOwner ? 1 : 0
        };

        console.log("data to submit", dataToSubmit);
        setaddNewButtton(prevState => ({ ...prevState, loading: true }));

        try {
            let assign = await Project.assignEmployee(dataToSubmit);
            if (assign.data?.status === "success") {
                toast.success(assign.data?.message);
            } else {
                throw new Error("Failed to add the user");
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setFormButtonStatus(prevState => ({ ...prevState, loading: false }));
            setaddNewButtton(prevState => ({ ...prevState, loading: false }));
            setValue('employees_id', '');
            setValue('responsibility', '');
            setProjectOwner(false);
            setSelectedRole(2);
            fetchList();
        }
    }


    return (
        <div>
            <div className='flex' style={{ width: '100', display: 'flex', justifyContent: "end", marginRight: 10 }}>
                <Button variant="contained" sx={{ width: "180px" }} onClick={() => setOpen(true)}>
                    Add Member
                </Button>
            </div>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{editID > 0 ? " Edit Role" : "Add Team Member"} </DialogTitle>
                {!loading ?
                    <DialogContent sx={{ mt: 8 }}>



                        <form>
                            <SelectX
                                label={"Choose team members"}
                                options={fetchEmployess}
                                control={control}
                                error={errors?.employees_id?.id ? errors?.employees_id?.id?.message : false}
                                error2={errors?.employees_id?.message ? errors?.employees_id?.message : false}
                                name={'employees_id'}
                                defaultValue={watch('employees_id')}
                                disabled={editID > 0 ? true : false}

                            />


                            <Typography sx={{ mt: 2 }} variant={"subtitle2"}>Project role,</Typography>
                            {project_roles.map(obj => {
                                return <DynamicChip name={obj.name} id={obj.id} active={selectedRole} onChipCLick={handleRoleChange} />
                            })}

                            <Grid xs={12} mt={3}>
                                <TextInput
                                    // autofocus
                                    label={'Responsibility'}
                                    control={control}
                                    name="responsibility"
                                    value={watch('responsibility')}
                                />
                            </Grid>

                            <Grid item xs={12} mt={3} ml={2}>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={projectOwner}
                                                onChange={(event) => setProjectOwner(event.target.checked)}
                                            />
                                        }
                                        label="Project Owner"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid display={'flex'}>
                                <LoadingButton
                                    sx={{ display: 'block', mt: 5 }}
                                    loading={formButtonStatus.loading}
                                    disabled={formButtonStatus.disabled}
                                    variant="outlined"
                                    onClick={handleSave} // Call onSubmit directly for the "Save" button
                                >
                                    {editID > 0 ? "Update" : "Save"}
                                </LoadingButton>

                                {editID === 0 && (
                                    <LoadingButton
                                        sx={{ display: 'block', mt: 5, ml: 2 }}
                                        loading={addNewButtton.loading}
                                        disabled={addNewButtton.disabled}
                                        variant="outlined"
                                        onClick={handleSaveAndAdd} // Call handleSaveAndAdd for the "Save & Add New" button
                                    >
                                        Save & Add New
                                    </LoadingButton>
                                )}

                            </Grid>
                        </form>





                    </DialogContent>
                    :
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                        <CircularProgress size={33} />
                    </Box>
                }

                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default AddTeamMember;
