import React from 'react'
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    Select,
    Slide, TextField, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-hot-toast';
import { Waveform } from '@uiball/loaders'
import { DeleteOutline } from '@mui/icons-material';
import { MeetingIdContext } from '../../../../../contexts/MeetingID/meetingID';
import Delete from '../../../common/Popup/Delete';
import SelectX from '../../../../form/SelectX';
import { Employee } from '../../../../../api/Endpoints/Employee';
import { VideoShootApi } from '../../../../../api/Endpoints/VideoShoot';

function VideoShootInternalTeam({ fetchList, list, id }) {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({});
    const [loading, setLoading] = useState(false)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [ID, setID] = useState(0)
    const [refresh, setRefresh] = React.useState(false);

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "ADD",
        loading: false,
        disabled: false,
    });

    // console.log(list);
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 180,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.row?.user?.name}</Button >
                    </>
                );
            },
        },

        {
            field: 'email',
            headerName: 'Email',
            width: 330,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.row?.user?.email}</Button >
                    </>
                );
            },
        },

        {
            field: 'action', headerName: '', width: 50, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <>
                    <Button style={{ color: 'black' }} size={'small'} onClick={() => handleDeleteAlert(id)}>
                        <DeleteOutline fontSize='small' />
                    </Button>
                </>;
            }
        },
    ]
    const fetchProjectMembers = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }


    useEffect(() => {
        // fetchList()
        // fetchProjectMembers()
    }, [])

    const handleDeleteAlert = (id) => {
        setID(id)
        setDeletePopup(true)
    }

    const onSubmit = async (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        const dataToSubmit = {
            attendee_type: 'User',
            project_video_shoots_id: id,
            user_id: data.employee_id?.id,
        }

        console.log(dataToSubmit);
        VideoShootApi.addAttendee(dataToSubmit).then((response) => {
            console.log("respponser here", response);
            if (response?.data?.status == "success") {

                fetchList().then(() => {
                    toast.success('Attendee has been successfully saved.')
                })

                setValue('employee_id', null)
            }

            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        }).catch((error) => {
            console.log(error);
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        })
    }



    return (
        <div>

            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                Callfunc={() => fetchList()}
                url="/projects/video-shoots/attendees/delete?id="
                title="Attendee" />}


            <Grid container spacing={2} mb={1} mt={0}>
                <Grid item xs={10} sm={10}>

                    <SelectX
                        key={refresh * 0.2}
                        options={fetchProjectMembers}
                        control={control}
                        error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                        error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                        name={'employee_id'}
                        defaultValue={watch('employee_id')}
                    />
                </Grid>
                <Grid item xs={2} sm={2} mt={0.5}>
                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                        onClick={handleSubmit(onSubmit)}
                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>

                </Grid>

            </Grid>
            {loading ? (
                <Waveform
                    size={40}
                    lineWeight={3.5}
                    speed={1}
                    color="black"
                />
            ) : (


                <div style={{ height: 300 }}>
                    {typeof list === "object" &&
                        <DataGrid
                            paginationMode="server"
                            rows={list}
                            columns={columns}

                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            rowCount={list.total}

                            autoHeight={false}
                            density={"comfortable"}
                        />
                    }
                </div>
            )}
        </div >

    )
}

export default VideoShootInternalTeam
