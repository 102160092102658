
import React, { createContext, useState, useContext, useEffect } from 'react';
import { Auth } from '../../api/Endpoints/Auth';


const StateContext = createContext();


export const UserIdContext = () => useContext(StateContext);


export const UserIDProvider = ({ children }) => {


  const [UserID, setUserID] = useState(null);
  const [UserName, setUserName] = useState(null);
  const [ID, setID] = useState(null);

  //user details such as personal and employee details
  const [userDetail, setUserDetail] = useState({})

  const [SignIn, setSignIn] = useState(false);

  const fetchUser = async () => {
    const accessToken = window.localStorage.getItem('accessToken');
    try {
      const user = await Auth.me(accessToken);
      console.log(user);
      setID(user?.data?.data?.id)
      setUserName(user?.data?.data?.name)
      setUserID(user?.data?.data?.id)
      setUserDetail(user?.data)
    } catch (error) {
      console.log(error);
    }

  }

  useEffect(() => {
    fetchUser()
  }, []);

  useEffect(() => {
    setUserID(UserID)
  }, [UserID]);

  useEffect(() => {
    setID(ID)
  }, [ID]);

  //user details
  useEffect(() => {
    setUserDetail(userDetail)
  }, [userDetail])


  useEffect(() => {
    setUserName(UserName)
  }, [UserName]);

  useEffect(() => {
    setSignIn(SignIn)
  }, [SignIn]);



  return (
    <StateContext.Provider value={{ ID, UserID, SignIn, setSignIn, UserName, setUserName, userDetail }}>
      {children}
    </StateContext.Provider>
  );
};
