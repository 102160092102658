import React, { useEffect, useState } from "react";
import { useAppSettings } from "../../../hooks/use-app-settings";
import moment from "moment";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Activities } from "../../../api/Endpoints/Activities";
import Create from "./create";
import DetailView from "./DetailView";
import ReactSelector from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Employee } from "../../../api/Endpoints/Employee";
import { Project } from "../../../api/Endpoints/Project";
import EditIcon from "@mui/icons-material/Edit";
import { Edit } from "@mui/icons-material";
import { UserIdContext } from "../../../contexts/UserID/UserID";
import { Departments } from "../../../api/Endpoints/Departments";
import CurrentDateInput from "../../form/CurrenDateInput";
import { Box, Select, MenuItem } from "@material-ui/core";
import Projects from "../employees/Tabs/Projects";

const scheme = yup.object().shape({});

const Index = (props) => {
  const { UserID, UserName, ID, userDetail } = UserIdContext();
  const { employeeID, employeeName } = props;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm({ resolver: yupResolver(scheme) });
  const [editId, setEditId] = useState();
  const [refresh, setRefresh] = useState(0);
  const [listByProject, setListByProject] = useState(false);
  const [detailViewId, setDetailViewId] = useState(false);
  const [projects, setProjects] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [selectedDepartments, setselectedDepartments] = useState(null);
  const [selectedProjectID, setSelectedProjectID] = useState(null);

  const DetailViewOpen = (id) => {
    setDetailViewId(id);
  };
  const handleEdit = (id) => {
    setRefresh(Math.random);
    setEditId(parseInt(id));
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 350,
      renderCell: (params) => (
        <p
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => DetailViewOpen(params.row.id)}
        >
          {params.value}
        </p>
      ),
    },
    {
      field: "project",
      headerName: "Project",
      width: 200,
      renderCell: (params) => (
        <p style={{ cursor: "pointer" }}>{params.value?.name}</p>
      ),
    },
    {
      field: "created_user",
      headerName: "User Name",
      width: 150,
      renderCell: (params) => <p>{params.value?.name}</p>,
    },
    {
      field: "created_at",
      headerName: "Created Date",
      width: 180,
      renderCell: (params) => {
        // console.log("This is data", params);
        // if (params) {

        return moment(params.value).format("DD-MM-YYYY");
        // } else {
        //     return "Not started";
        // }
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 140,
      renderCell: (params) => (
        <p>
          {params.row.duration_hours
            ? params.row.duration_hours == 1
              ? params.row.duration_hours + "hr"
              : params.row.duration_hours + "hrs"
            : ""}{" "}
          {params.row.duration_minutes
            ? params.row.duration_minutes + "mins"
            : ""}
        </p>
      ),
    },
    {
      field: "action",
      headerName: "Edit",
      width: 100,
      valueGetter: (params) => params.row,
      renderCell: (params) => {
        let id = params.value?.id;
        return (
          <>
            <a style={{ cursor: "pointer" }} onClick={() => handleEdit(id)}>
              <Edit color="primary" fontSize="small" />
            </a>
          </>
        );
      },
    },
  ];

  const [list, setList] = useState([]);
  const [listType, setListType] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
  //const [selectedUserID, setSelectedUserID] = useState(ID);
  const [selectedUserID, setSelectedUserID] = useState(
    employeeID ? employeeID : ID
  );
  const [loading, setLoading] = useState(false);

  const handleListingSearchChange = (keyword) => {
    setSearchKeyword(keyword);
    setPageNumber(0);
  };
  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handleLeadClick = (leadId) => {
    props.onPreQualifierIdChange(leadId);
    setSelectedPreQualifierId(leadId);
  };

  const fetchTable = () => {
    setLoading(true);
    let from = moment(watch("from")).format("YYYY-MM-DD");
    let to = moment(watch("to")).format("YYYY-MM-DD");

    Activities.get({
      projects_id: selectedProjectID,
      users_id: selectedUserID,
      from,
      to,
      department: selectedDepartments,
      keyword: searchKeyword,
      page: parseInt(pageNumber) + 1,
      limit: 30,
    }).then((response) => {
      setList(response.data.data);
      setLoading(false);
    });
  };

  const fetchProjects = (e) => {
    Project.get({ keyword: e }).then((response) => {
      if (typeof response.data.data.data !== "undefined") {
        setProjects(response.data.data.data);
      } else {
        return [];
      }
    });
  };

  //console.log("departments", departments);

  const fetchEmployees = (e) => {
    Employee.get({ keyword: e, limit: 100 }).then((response) => {
      if (typeof response.data.data.data !== "undefined") {
        setEmployees(response.data.data.data);
      } else {
        return [];
      }
    });
  };

  const fetchDepartments = (e) => {
    Departments.get({ keyword: e, limit: 100 }).then((response) => {
      if (typeof response.data.data.data !== "undefined") {
        setDepartments(response.data.data.data);
      } else {
        return [];
      }
    });
  };

  const selectProject = (project_id) => {
    setSelectedProjectID(project_id?.id);
    setValue("projects", project_id?.name || "");
  };

  const selectByUser = (user) => {
    setSelectedUserID(user?.id);
    setValue("user", user?.name || "");
  };

  const selectDepartments = (data) => {
    setselectedDepartments(data?.id);
    setValue("departments", data?.name || "");
  };

  const handleUpdate = () => {
    setPageNumber();
    setPageNumber(0);
  };

  const handleCreate = () => {
    setEditId(0);
    setRefresh(Math.random);
  };

  const handleSearchEntry = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handlePageNumberChange = (event) => {
    const newPageNumber = parseInt(event.target.value, 10) - 1;
    setPageNumber(newPageNumber);
    // Add any other logic you want to perform when the page number changes
  };

  const handleResetDate = () => {
    setValue("from", moment());
    setValue("to", moment());
  };

  // useEffect(() => {
  //   setValue("user", UserName || "");
  // }, [UserName]);

  //for activities tab in employees , to get selected employee's details
  useEffect(() => {
    setValue("user", employeeName || UserName || "");
  }, [UserName, employeeName]);

  useEffect(() => {
    fetchProjects();
    fetchDepartments();
    fetchEmployees();
  }, []);

  useEffect(() => {
    fetchTable();
  }, [
    searchKeyword,
    listType,
    pageNumber,
    selectedProjectID,
    selectedUserID,
    selectedDepartments,
    watch("from"),
    watch("to"),
  ]);

  return (
    <>
      {detailViewId && (
        <DetailView id={detailViewId} handleClose={setDetailViewId} />
      )}

      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <PageHeader title={"Activities"} />
        {employeeName && (
          <>
            <Grid
              ml={25}
              display={"flex"}
              alignItems={"center"}
              xs={3}
              sx={{ px: 2 }}
            >
              <InputLabel sx={{ mr: 1 }}>From</InputLabel>
              <Grid width={"160px"}>
                <CurrentDateInput
                  control={control}
                  name="from"
                  value={watch("from")}
                />
              </Grid>
            </Grid>
            <Grid display={"flex"} alignItems={"center"} xs={3} sx={{ px: 1 }}>
              <InputLabel sx={{ mr: 1 }}>To</InputLabel>
              <Grid mr={1} width={"160px"}>
                <CurrentDateInput
                  placeholder={"to"}
                  control={control}
                  name="to"
                  value={watch("to")}
                />
              </Grid>
              {/* <Button onClick={handleResetDate} size="small" variant="outlined">
                {" "}
                Reset{" "}
              </Button> */}
            </Grid>{" "}
            <Grid display={"flex"} mr={3}>
              {" "}
              <TextField
                style={{ width: 300, marginRight: 10 }}
                size="small"
                id="outlined-name"
                label="Search Activities"
                onChange={handleSearchEntry}
              />
            </Grid>
          </>
        )}
        {!employeeName && (
          <Grid display={"flex"} mr={3}>
            <TextField
              style={{ width: 300, marginRight: 10 }}
              size="small"
              id="outlined-name"
              label="Search Activities"
              onChange={handleSearchEntry}
            />

            <Create
              key={refresh}
              onNew={handleCreate}
              onUpdate={handleUpdate}
              editId={editId}
              setEditId={setEditId}
            />
          </Grid>
        )}
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!employeeName && (
        <Grid
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          variant="outlined"
        >
          <Grid item display={"flex"} sx={{ ml: "auto" }}>
            <Grid sx={{ width: 200 }} mr={3}>
              <ReactSelector
                placeholder="Project"
                onInputChange={fetchProjects}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                options={projects}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={projects.find(
                  (options) => options.name === watch("projects")
                )}
                name="projects"
                isClearable
                defaultValue={watch("projects")}
                onChange={(selectedOption) => selectProject(selectedOption)}
              />
            </Grid>

            <Grid sx={{ width: 200 }} mr={3}>
              <ReactSelector
                placeholder="User Name"
                onInputChange={fetchEmployees}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                options={employees}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                // inputValue={inProject}
                value={employees.find(
                  (options) => options.name === watch("user")
                )}
                name="user"
                isClearable
                defaultValue={watch("user")}
                onChange={(selectedOption) => selectByUser(selectedOption)}
              />
            </Grid>

            <Grid sx={{ width: 200 }}>
              <ReactSelector
                placeholder="Department"
                onInputChange={fetchDepartments}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                options={departments}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                // inputValue={inProject}
                value={departments.find(
                  (options) => options.name === watch("departments")
                )}
                name="departments"
                isClearable
                defaultValue={watch("departments")}
                onChange={(selectedOption) => selectDepartments(selectedOption)}
              />
            </Grid>

            <Grid
              ml={5}
              display={"flex"}
              alignItems={"center"}
              xs={3}
              sx={{ px: 2 }}
            >
              <InputLabel sx={{ mr: 1 }}>From</InputLabel>
              <Grid width={"160px"}>
                <CurrentDateInput
                  control={control}
                  name="from"
                  value={watch("from")}
                />
              </Grid>
            </Grid>

            <Grid display={"flex"} alignItems={"center"} xs={3} sx={{ px: 2 }}>
              <InputLabel sx={{ mr: 1 }}>To</InputLabel>
              <Grid mr={1} width={"160px"}>
                <CurrentDateInput
                  placeholder={"to"}
                  control={control}
                  name="to"
                  value={watch("to")}
                />
              </Grid>
              <Button onClick={handleResetDate} size="small" variant="outlined">
                {" "}
                Reset{" "}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Card
        sx={{
          m: 2,
          height: list?.data?.length > 0 ? 1350 : 400,
          position: "relative",
        }}
        variant="outlined"
      >
        {typeof list.data === "object" && (
          <>
            <DataGrid
              paginationMode="server"
              rows={list?.data}
              columns={columns}
              page={pageNumber}
              pageSize={list?.per_page}
              rowHeight={20}
              // autoHeight
              // rowsPerPageOptions={[10]}
              rowCount={list?.total}
              onPageChange={handlePageChange}
              density={"comfortable"}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                position: "absolute",
                bottom: 16,
                left: "77%",
                transform: "translateX(-50%)",
                zIndex: 1000,
              }}
            >
              <label htmlFor="pageNumber" style={{ marginRight: "10px" }}>
                Go to Page:
              </label>
              <select
                id="pageNumber"
                value={pageNumber + 1}
                onChange={handlePageNumberChange}
              >
                {Array.from({ length: Math.ceil(list?.last_page) }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default Index;
