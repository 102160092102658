import { get, post } from './../Config/config';

export const Task = {
    get: (data) => get(`/projects/tasks`, { params: data }),
    add: (data) => post('/projects/tasks/store', data),
    update: (data) => post(`/projects/tasks/update`, data),
    search: () => get(`/projects/tasks/search`),
    getDetails: (data) => get(`/projects/tasks/get-account`, { params: data }),
    getTaskDetails: (data) => get(`/projects/tasks/view`, { params: data }),
    changeStatus: (data) => get(`/projects/tasks/change-status`, { params: data }),
    getStatusHistory: (data) => get('projects/tasks/status-history', { params: data }),

    // Api For Task Notes
    getNote: (data) => get(`/projects/tasks/notes`, { params: data }),
    addNote: (data) => post('/projects/tasks/notes/store', data),
    updateNote: (data) => post(`/projects/tasks/notes/update`, data),
    getNoteDetails: (data) => get(`/projects/tasks/notes/view?id=`, { params: data }),
    deleteNote: (data) => get(`/projects/tasks/notes/delete?id=`, { params: data }),

    // Api For Task Subscribe
    subscribe: (data) => post('/subscribers/store', data),
    getSubscribers: (data) => get(`/subscribers?subscribable_type=task&subscribable_id=`, {params:data}),
    viewSubscriber: (data) => get(`/subscribers/view?id=`, {params:data}),
    deleteSubscriber: (data) => get(`/projects/tasks/notes/delete?id=`, {params:data}),

    // archive tasks
    archive: (data) => get(`/projects/tasks/archive`, {params:data}),
    unarchive: (data) => get(`/projects/tasks/remove-archive`, {params:data}),
    
    
}
