import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Backdrop, Button, Card, CircularProgress, Dialog, DialogContent, Slide, TextField } from "@mui/material";
import AddNote from './Tabs/Notes';
import DetailView from './Tabs/Details';
import { useState } from 'react';
import VideoVendorPayment from './Tabs/VideoShootVendor';
import VideoShootAttendees from './Tabs/Attendees';
import VideoWorkOrder from './Tabs/workorder';
import VideoShootLinks from './Tabs/Links';
import VideoShootAttachments from './Tabs/Attachments';
import { useEffect } from 'react';
import { VideoShootApi } from '../../../api/Endpoints/VideoShoot';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function content(props) {
    return (
        <div
            hidden={props.value !== props.index}
        >

        </div>
    )

}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs(props) {
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(true);
    const [editId, setEditId] = useState()
    const [refresh, setRefresh] = useState()
    const [userID, setUserID] = useState()
    const [details, setDetails] = useState(true)
    const [noteCount, setNoteCount] = useState(0)

    const handleClose = () => {
        setOpen(false)
        props.handleClose()
    };

    const handleCreate = () => {
        setEditId(0)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const showDetails = () => {
        setDetails(true)
    }

    const fetchNoteCount = () => {
        VideoShootApi.getNote({ project_video_shoots_id: props.id }).then((notes) => {
            console.log(notes);
            if(notes?.data?.data){
                setNoteCount(notes?.data?.data?.length)
            }
        })
    }
    useEffect(() => {
      fetchNoteCount()
    }, [])
    

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{ sx: { maxWidth: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ ml: 2 }}>
                    <Tab label="Details" {...a11yProps(0)} />
                    <Tab label={`Notes (${noteCount})`} {...a11yProps(1)} />
                    <Tab label="Payments" {...a11yProps(2)} />
                    <Tab label="Attendees" {...a11yProps(3)} />
                    <Tab label="Work Orders" {...a11yProps(4)} />
                    <Tab label="Links" {...a11yProps(5)} />
                    <Tab label="Attachments" {...a11yProps(6)} />
                </Tabs>

                <DialogContent>

                    <TabPanel value={value} index={0}>
                        <DetailView fetchList={props?.fetchList} setTab={setValue} setEditId={setEditId} id={props.id} handleClose={props.handleClose} refresh={refresh} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <AddNote id={props.id} setTab={setValue} setEditId={setEditId} editId={editId} count={fetchNoteCount} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <VideoVendorPayment id={props.id} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <VideoShootAttendees id={props.id} />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <VideoWorkOrder id={props.id} />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <VideoShootLinks id={props.id} />
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        <VideoShootAttachments id={props.id} />
                    </TabPanel>
                </DialogContent>
            </Dialog>
        </>
    );
}
