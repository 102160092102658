import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Popover, MenuItem, Grid, Typography, Tooltip } from '@mui/material';
import AssignTask from './AssignTask';
import EditPopup from './edit';

import { DotSpinner } from '@uiball/loaders';
import Create from './create';
import { MeetingIdContext } from '../../../../../contexts/MeetingID/meetingID';
import { Meetings } from '../../../../../api/Endpoints/Meeting';
import Delete from '../../../common/Popup/Delete';
import { MoreOptionsDropdown } from '../../../common/MoreAction/actionButton';
import Edit from './edit';
import AssignTaskFromMinutes from './AssignTask';
import { AddTask, CheckCircle, CheckCircleOutline, NotInterested, PanoramaFishEye, PersonAddAlt1 } from '@mui/icons-material';
import AssignTo from './AssignTo';
import toast from 'react-hot-toast';
import './MinutesIndex.css'
import moment from 'moment';
import { Task } from '../../../../../api/Endpoints/Task';
import TaskIcon from '@mui/icons-material/Task';
import BasicTabs from '../../../tasks/TabMenus';
import { useForm } from 'react-hook-form';

function Index({ projectID, meetingID }) {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm();

    // const { MeetingID } = MeetingIdContext();
    const [list, setList] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }
    const [loading, setLoading] = useState(false);
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [editId, setEditId] = useState();
    const [ID, setID] = useState(0);
    const [TaskPopup, setTaskPopup] = useState(false);
    const [showTaskIcon, setShowTaskIcon] = useState(true);
    const [AssignID, setAssignID] = useState(0);
    const [OpenEditPopup, setopenEditpopup] = useState(false);
    const [OpendetailsPopup, setOpenDetailPopup] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [minuteId, setMinuteId] = useState()
    const [associatedTask, setAssociatedTask] = useState()
    const [assignToId, setAssignToId] = useState(false)
    const [meeting_ID, setmeeting_ID] = useState()
    const [minutesDetails, setminutesDetails] = useState()
    const [minuteTask, setMinuteTask] = useState([])
    const [taskID, setTaskID] = useState()
    const [editMode, setEditMode] = useState(false)

    const containerRef = useRef(null);

    useEffect(() => {
        console.log('!VALUE DESTROY');
        fetchList();
        setShowTaskIcon(true);
    }, [projectID]);

    // console.log(projectID);

    const fetchList = async () => {
        setLoading(true);
        try {
            const response = await Meetings.getMeetingMinutes({ meetings_id: meetingID });
            if (response.data.status === 'success') {
                // console.log(response.data.data);
                setList(response?.data?.data);
                const taskId = response?.data?.data
                    .filter(items => items?.taskable)
                    .map(items => items?.taskable?.id);
                // console.log(taskId);

                const taskDetailsPromises = taskId.map(async element => {
                    console.log(element);
                    const taskOwner = await Task.getTaskDetails({ id: element });
                    return taskOwner?.data?.data;
                });

                const minuteTaskData = await Promise.all(taskDetailsPromises);
                // console.log(minuteTaskData);

                // Now, you can update minuteTask with the collected data
                setMinuteTask(minuteTaskData);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    // useEffect(async () => {
    //     let taskOwner = await Task.getDetails({ id: taskId[0] })
    //     console.log(taskOwner);
    // }, [taskId])


    const changeMinuteStatus = (status) => {
        let dataToSubmit = {
            id: minuteId,
            status: status
        }

        Meetings.changeMinutesStatus(dataToSubmit).then((response) => {
            console.log(response);
            toast.success(response?.data?.message)
            fetchList()
        })
    }

    const DetailView = (id) => {
        setEditId(id);
        setopenEditpopup(true);
    };


    const handleDeleteAlert = (id) => {
        setID(id);
        setDeletePopup(true);
    };

    const handleEditClick = (id) => {
        setEditId(id);
        setopenEditpopup(true);
    };


    const handleOpenPopover = (event, id, taskable_id) => {
        // if(taskable_id == null){
        setAnchorEl(event.currentTarget);
        // }
        setMinuteId(id);

    };
    const handleClosePopover = () => {
        setAnchorEl(null);
    };
    const handlePopoverChange = (status) => {
        console.log(status)
        // setminuteStatus(data)
        changeMinuteStatus(status)
        handleClosePopover()
    };
    const id = anchorEl ? 'popover-basic' : undefined;

    const getTaskID = (id) => {
        console.log(id);
    }

    const assignTask = (data) => {
        console.log(data);
        setminutesDetails(data)
        setTaskPopup(data.id)
        setmeeting_ID(data.meetings_id)
    }
    // console.log(projectID);

    const viewTask = (id) => {
        console.log(id);
        setTaskID(id)
    }

    const handleEditMode = (id, title) => {
        setValue(`minute${id}`, title || '');
        setEditMode(id)

    }

    const editMinute = (id) => {
        const loadingToast = toast.loading('updating...');
        if (watch(`minute${id}`).includes('---')) {
            let linesArray = watch(`minute${id}`)?.split('---')?.map(line => line.trim());
            const promises = linesArray.map(async (line, index) => {
                let minutes = '';
                if (line.includes('-')) {
                    minutes = line.replace(/-/g, '').trim(); // Replace all dashes with empty string and trim the line
                } else {
                    minutes = line.trim(); // If line does not contain '-', just trim it
                }

                let dataToSubmit = {}

                if (index == 0) {
                    dataToSubmit = {
                        id: id,
                        title: minutes,
                    };
                } else {
                    dataToSubmit = {
                        meetings_id: meetingID,
                        title: minutes,
                    };
                }




                if (minutes) {
                    try {
                        if (index == 0) {
                            await Meetings.updateMeetingMinutes(dataToSubmit);
                        } else {
                            await Meetings.addMeetingMinutes(dataToSubmit);
                        }
                    } catch (error) {
                        console.log(error);
                        toast.dismiss(loadingToast);
                        toast.error('Something went wrong. Please try again.');
                        throw error; // Rethrow the error to halt further processing
                    }
                }

            });
            Promise.all(promises)
                .then(() => {
                    // After all lines are saved successfully
                    toast.dismiss(loadingToast);
                    // setValue('');
                    // setRowCount(1);
                    toast.success('Meeting Minutes have been successfully saved.');
                    setEditMode()
                    fetchList()
                    // let id = action?.data?.data?.id;
                    // setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
                    setLoading(false);
                })
                .catch((error) => {
                    // If any error occurs during saving lines
                    console.error(error);
                    // Any additional error handling if needed
                });
        } else {
            let dataToSubmit = {
                id: id,
                title: watch(`minute${id}`),
            };
            let action;
            action = Meetings.updateMeetingMinutes(dataToSubmit);

            action.then(response => {
                toast.dismiss(loadingToast);
                if (response?.data?.status == 'success') {
                    toast.success(response.data.message)
                    setEditMode()
                    fetchList()
                }
                if (response?.data?.status == 'error') {
                    toast.error(response.data.message || "Internal server error");
                }

            }).catch(errors => {
                toast.error("Internal server error");
            })
        }
    }

    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                setEditMode();
            }
        };
        // Attach the event listener when in edit mode
        if (editMode) {
            window.addEventListener('keydown', handleEscapeKey);
        }
        // Detach the event listener when exiting edit mode or when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleEscapeKey);
        };
    }, [editMode]);

    useEffect(() => {
        // Scroll to the bottom after the list is updated
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [list]); // Assuming `list` is the state or variable that you update after form submission



    return (
        <div>
            {
                taskID &&
                <BasicTabs id={taskID} handleClose={setTaskID} />
            }

            {showDeletePopup && (
                <Delete
                    ID={ID}
                    setID={setID}
                    setDeletePopup={setDeletePopup}
                    Callfunc={() => fetchList()}
                    url="/meeting-minutes/delete?id="
                    title="Meeting Minutes"
                />
            )}
            {TaskPopup && (
                <AssignTaskFromMinutes fetchList={() => fetchList()} projectID={projectID} minutesDetails={minutesDetails} meetings_id={meeting_ID} minuteID={TaskPopup} getTaskID={getTaskID} key={refresh} onNew={handleCreate} onUpdate={handleUpdate} setOpen={setTaskPopup} editId={meetingID} />
            )}
            {
                assignToId &&
                <AssignTo Open={assignToId}
                    setOpen={setAssignToId}
                    editId={assignToId}
                    setEditId={setAssignToId}
                    fetchList={() => fetchList()}
                />
            }
            {OpenEditPopup && (
                <EditPopup
                    Open={OpenEditPopup}
                    setOpen={setopenEditpopup}
                    editId={editId}
                    setEditId={setEditId}
                    fetchList={() => fetchList()}
                />
            )}

            {OpendetailsPopup && (
                <Edit Open={OpendetailsPopup} setOpen={setOpenDetailPopup} editId={ID} setEditId={setID} ViewType={true} />
            )}

            {/* {!loading ? ( */}
            <div>
                <div className="scrollable-container" ref={containerRef}>
                    <TableContainer component={Paper} style={{}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'92%'}>Minutes</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>


                                {
                                    list?.slice()?.reverse()?.map((item) => (
                                        <TableRow className='hover-row' key={item.id} >
                                            <TableCell sx={{ display: 'flex', padding: '1px' }}>

                                                {
                                                    item?.taskable_id ?
                                                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '8%', marginTop: '-10px' }}>
                                                            {
                                                                item?.taskable?.status === "Completed" &&
                                                                <CheckCircle color='success' sx={{ marginRight: 2 }} />
                                                            }
                                                            {
                                                                item?.taskable?.status == "In progress" &&
                                                                <CheckCircleOutline color='success' sx={{ marginRight: 2 }} />
                                                            }
                                                            {
                                                                item?.taskable?.status == "No Status" &&
                                                                <PanoramaFishEye color='inherit' sx={{ marginRight: 2 }} />
                                                            }
                                                            {
                                                                item?.taskable?.status == "Rejected" &&
                                                                <NotInterested color='error' sx={{ marginRight: 2 }} />
                                                            }
                                                        </div> : (
                                                            // status button alignment here
                                                            <div onClick={(e) => handleOpenPopover(e, item.id, item.taskable_id)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '8%', marginTop: '-10px' }}>
                                                                {
                                                                    item.status === "Completed" &&
                                                                    <CheckCircle color='success' sx={{ marginRight: 2 }} />
                                                                }
                                                                {
                                                                    item.status == "In Progress" &&
                                                                    <CheckCircleOutline color='success' sx={{ marginRight: 2 }} />
                                                                }
                                                                {
                                                                    item.status == "No Status" &&
                                                                    <PanoramaFishEye color='inherit' sx={{ marginRight: 2 }} />
                                                                }
                                                                {
                                                                    item.status == "Rejected" &&
                                                                    <NotInterested color='error' sx={{ marginRight: 2 }} />
                                                                }
                                                            </div>
                                                        )
                                                }
                                                {
                                                    item.taskable_type === null &&
                                                    <Popover
                                                        id={id}
                                                        open={anchorEl}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClosePopover}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => handlePopoverChange("Completed", item)}>
                                                            <CheckCircle color='success' sx={{ marginRight: 1 }} /> Completed
                                                        </MenuItem>
                                                        <MenuItem onClick={() => handlePopoverChange("In Progress", item)}>
                                                            <CheckCircleOutline color='success' sx={{ marginRight: 1 }} /> In Progress
                                                        </MenuItem>
                                                        <MenuItem onClick={() => handlePopoverChange("No Status", item)}>
                                                            <PanoramaFishEye color='inherit' sx={{ marginRight: 1 }} />No Status
                                                        </MenuItem>
                                                        <MenuItem onClick={() => handlePopoverChange("Rejected", item)}>
                                                            <NotInterested color='error' sx={{ marginRight: 1 }} />Rejected
                                                        </MenuItem>
                                                    </Popover>
                                                }
                                                <div style={{ width: '100%' }} className='title-row-hover'>
                                                    {
                                                        (editMode && editMode == item?.id) ?
                                                            <TextField multiline
                                                                {...register(`minute${item?.id}`)} value={watch(`minute${item?.id}`)}
                                                                onChange={(e) => setValue(`minute${item?.id}`, e.target.value)}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter' && !e.shiftKey) {
                                                                        e.preventDefault(); // Prevent the default behavior (e.g., form submission)
                                                                        editMinute(item?.id); // Call your save function
                                                                    }
                                                                }}
                                                                style={{ color: 'black', marginTop: 20, textAlign: 'start', width: '100%' }}
                                                                autoFocus
                                                                onFocus={(e) => {
                                                                    e.target.setSelectionRange(e.target.value.length, e.target.value.length);
                                                                }}
                                                            />
                                                            // onClick={() => DetailView(item.id)}
                                                            :
                                                            <a style={{ color: 'black', marginTop: 20, textAlign: 'start', width: '100%', cursor: 'pointer', fontWeight: 450, whiteSpace: 'pre-line' }} size="small" onClick={() => handleEditMode(item?.id, item?.title)}>
                                                                {item?.title}
                                                            </a>
                                                        // item?.title?.length > 120 ? (
                                                        //     <Tooltip title={item.title} arrow>
                                                        //         <a style={{ color: 'black', marginTop: 20, textAlign: 'start', width: '100%',cursor:'pointer',fontWeight:450 }} size="small" onClick={() => handleEditMode(item.id, item?.title)}>
                                                        //             {item?.title?.slice(0, 120)} .....
                                                        //         </a>
                                                        //     </Tooltip>
                                                        // ) : (
                                                        // <a style={{ color: 'black', marginTop: 20, textAlign: 'start', width: '100%', cursor: 'pointer', fontWeight: 450, whiteSpace: 'pre-line' }} size="small" onClick={() => handleEditMode(item?.id, item?.title)}>
                                                        //     {item?.title}
                                                        // </a>
                                                        // )
                                                    }


                                                    <div className='row-user-name' style={{ justifyContent: 'space-between' }}>
                                                        <a style={{ fontSize: 12, marginLeft: 2 }}><span>By:</span>{item.created_user?.name}</a>
                                                        <a style={{ fontSize: 12, marginLeft: 10 }}><span>Date:</span>{moment(item.created_at).format("DD-MM-YYYY")}</a>
                                                    </div>
                                                </div>


                                            </TableCell>
                                            <TableCell sx={{}}>
                                                <div style={{ alignContent: 'end', display: 'flex', marginLeft: '25px' }}>
                                                    {
                                                        item?.taskable_id ?
                                                            <>
                                                                {/* <Button style={{ color: 'black', marginLeft: 'auto' }} size="small" >
                                                                    {minuteTask.find(elem => elem?.id === item.taskable_id)?.assigned_to_user?.name || ''}
                                                                </Button> */}
                                                                <a onClick={() => viewTask(item?.taskable_id)} style={{ marginLeft: 'auto', cursor: 'pointer' }}>
                                                                    <TaskIcon sx={{ color: 'green' }} />
                                                                </a>
                                                            </> :
                                                            <>
                                                                {/* {
                                                                    item.assign_to_user?.name ?
                                                                        <Button style={{ color: 'black', marginLeft: 'auto' }} size="small" onClick={() => setAssignToId(item?.id)}>
                                                                            {item?.assign_to_user?.name?.slice(0, 9)}
                                                                        </Button>
                                                                        :
                                                                        (<Button style={{ color: 'black' }} size="small" onClick={() => setAssignToId(item?.id)}>
                                                                            <PersonAddAlt1 color='primary' sx={{ marginLeft: 2 }} />
                                                                        </Button>)
                                                                } */}
                                                                <a style={{ marginLeft: 'auto', cursor: 'pointer' }} className='hover-icon' onClick={() => assignTask(item)}>
                                                                    <AddTask sx={{ color: 'black' }} />
                                                                </a>
                                                            </>
                                                    }

                                                </div>

                                            </TableCell>
                                            <TableCell>
                                                <MoreOptionsDropdown
                                                    params={item}
                                                    setEditId={setEditId}
                                                    setID={setID}
                                                    setDeletePopup={setDeletePopup}
                                                    setTaskPopup={setTaskPopup}
                                                    TaskPopup={TaskPopup}
                                                    showTaskIcon={showTaskIcon}
                                                    setAssignID={setAssignID}
                                                    setOpen={setopenEditpopup}
                                                    onEditClick={() => handleEditClick(item.id)}
                                                    onDeleteClick={() => handleDeleteAlert(item.id)}
                                                />
                                            </TableCell>

                                        </TableRow>
                                    ))
                                }


                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div onClick={() => setEditMode()} style={{ width: 300 }}>
                    <Create DetailView={DetailView} meetingID={meetingID} fetchList={() => fetchList()} /> {/* Set Create component to full width */}
                </div>
            </div>
            {/* ) 
            : (
                <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'30%'} mt={10}>
                    <DotSpinner size={40} speed={0.9} color="blue" />
                </Box>
            )
            } */}
        </div >
    );
}

export default Index;
