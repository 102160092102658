import { get, post } from './../Config/config';


export const VideoShootApi = {

    get: (data) => get('/projects/video-shoots', { params: data }),
    add: (data) => post('/projects/video-shoots/store', data),
    update: (data) => post('/projects/video-shoots/update', data),
    getDetails: (data) => get('/projects/video-shoots/view', { params: data }),
    delete: (data) => get('/projects/video-shoots/delete', { params: data }),

    listAttachments: (data) => get('/projects/video-shoots/attachments', { params: data }),
    addAttachments: (data) => post('/projects/video-shoots/attachments/store', data),

    getNote: (data) => get('/projects/video-shoots/notes', { params: data }),
    addNote: (data) => post('/projects/video-shoots/notes/store', data),
    updateNote: (data) => post('/projects/video-shoots/notes/update', data),

    getAttendees:(data) => get('/projects/video-shoots/attendees', { params: data }),
    addAttendee: (data) => post('/projects/video-shoots/attendees/store', data),
    updateAttendee: (data) => post('/projects/video-shoots/attendees/update', data),

    getLinks: (data) => get('/projects/video-shoots/links', { params: data }),
    addLink: (data) => post('/projects/video-shoots/links/store', data),
    updateLink: (data) => post('/projects/video-shoots/links/update', data),

    getVendorPayment: (data) => get('/projects/video-shoots/assign-vendor-payment', data),
    assignVendorPayment: (data) => post('/projects/video-shoots/assign-vendor-payment', data),
    assignWorkOrder: (data) => post('/projects/video-shoots/assign-work-order', data),

}
