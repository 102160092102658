import { Facebook, Twitter, WhatsApp } from '@material-ui/icons'
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import Create from './create'

function SubBrand({ClientID}) {
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();

    const handleCreate = () => {
        setEditId(0)
    }

    const handleUpdate = (id) => {
        setEditId(id)
    }

    return (
        <Grid pt={0} p={1} container>
            {/* {
                editId === 0 || editId > 0 && */}
                <Create editId={editId} setEditId={setEditId} ClientID={ClientID}  />
            {/* } */}
            <Grid display={'flex'} justifyContent={'end'} md={12}>
                <Button onClick={handleCreate} variant='outlined'>Add</Button>
            </Grid>
            <Grid p={1} item md={6}>
                <Card>
                    <Grid container>
                        <Grid item p={3} md={5}>
                            <Grid item md={12}>
                                <CardMedia
                                    component="img"
                                    alt="Dummy Image"
                                    height="100"
                                    width='150'
                                    image="https://media.licdn.com/dms/image/C5616AQH_pxI0Ucbbhw/profile-displaybackgroundimage-shrink_200_800/0/1636454175106?e=2147483647&v=beta&t=8YC1Gbq9NVQbNFhP6JRQ29MK6Vwsz8_MJlXgDJXHuuM" // Replace with the URL of your image
                                />
                            </Grid>
                            <Grid mt={1} display={'flex'} item md={12}>
                                <Facebook fontSize='small' style={{ color: 'blue' }} />
                                <WhatsApp fontSize='small' style={{ color: 'green' }} />
                                <Twitter fontSize='small' style={{ color: 'lightblue' }} />
                            </Grid>
                        </Grid>
                        <Grid item md={7}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    Dummy Content
                                </Typography>
                                <a style={{ color: 'grey' }}>
                                    Kochi, India
                                </a>
                                <br />
                                <a style={{ color: 'grey', fontWeight: 500 }}>
                                    Jan 2020, On going
                                </a>
                                <Typography mt={1} variant="body2" color="text.secondary">
                                    SEO, Website Development, Social Media
                                </Typography>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

        </Grid>
    )
}

export default SubBrand
