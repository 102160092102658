import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Meetings } from '../../../../../api/Endpoints/Meeting';
import AsyncSelect from 'react-select/async';
import { useForm } from 'react-hook-form';
import { ContentPaste, CopyAll, Done } from '@mui/icons-material';
import TextInput from '../../../../form/TextInput';

function SendTab(props) {
    const { register, handleSubmit, watch, formState: { errors }, control, getValues, reset, setValue } = useForm();
    const [list, setList] = useState([]);
    const [modal, setModal] = useState(false);
    const [copied, setCopied] = useState(false)

    const modalOpen = () => {
        setModal(true);
    }

    const modalClose = () => {
        setModal(false);
    }

    const fetchEmployees = (e) => {
        return Meetings.getDetails({ id: props.meetingID }).then(response => {
            if (response.data.status === 'success') {
                const internalAttendees = response.data.data?.internal_attendees || [];
                const guestAttendees = response.data.data?.guest_attendees || [];
                // Filter out guest attendees without an email address
                const filteredGuests = guestAttendees.filter(guest => guest?.client_user_email);

                // Concatenate internal and guest attendees into a single array
                const allAttendees = internalAttendees.concat(filteredGuests);
                setValue('employee_email', allAttendees)
                return allAttendees;
            }
        });
    }

    const onSubmit = (data) => {
        console.log(watch('minutes'));
        const emailArray = data?.email.split(',');

        // Trim whitespace from each email and remove empty strings
        const cleanedEmailArray = emailArray.map(email => email.trim()).filter(email => email !== '');

        console.log(cleanedEmailArray);
    }

    useEffect(() => {
        console.log(watch('employee_email'));
    }, [watch('employee_email')])


    const fetchList = async () => {
        try {
            const response = await Meetings.getMeetingMinutes({ meetings_id: props?.meetingID });
            if (response.data.status === 'success') {
                setList(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleCopyToClipboard = () => {
        const minutesValue = watch('minutes');
        navigator.clipboard.writeText(minutesValue);
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000);
    };

    const handleKeyDown = (event) => {
        // Check if the 'Enter' key is pressed without the 'Shift' key
        if (event.key === 'Enter' && !event.shiftKey) {
            // Check if the text area has focus
            const isTextAreaFocused = document.activeElement.tagName === 'TEXTAREA' && document.activeElement.id === 'minutesTextarea';

            if (isTextAreaFocused) {
                event.preventDefault();

                // Update the text field value by appending a bullet point
                setValue('minutes', (watch('minutes') || '') + '\n\n\u2022');
            }
        }
    };


    useEffect(() => {
        // Attach the keydown event listener to the document
        document.addEventListener('keydown', handleKeyDown);

        const textarea = document.getElementById('minutesTextarea');

        if (textarea) {
            // Scroll to the bottom when the content changes
            textarea.scrollTop = textarea.scrollHeight;
        }
        // Cleanup the event listener when the component is unmounted
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        fetchList();
    }, []);

    useEffect(() => {
        if (list.length > 0) {
            // Join the titles with a newline character and set it as the value for the 'minutes' field
            setValue(
                'minutes',
                list.map(obj => `\u2022 ${obj.title}`).join('\n\n') || ''
            );
        }
    }, [list]);

    const handleFormSubmit = (data) => {
        // Your form submission logic here
        console.log('Form data:', data);
        // Trigger the parent component's onSubmit function
        props.onSubmit(data);
    };

    return (
        <Grid>
            <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Send Mail
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid mt={4}>
                        <AsyncSelect
                            name='employee_email'
                            isMulti
                            defaultOptions
                            value={watch('employee_email')}
                            loadOptions={fetchEmployees}
                            getOptionLabel={(e) => e?.user_attendee?.email || (e?.client_user_email && e?.client_user_email)}
                            getOptionValue={(e) => e?.users_id || e?.id}
                            placeholder={<div>select attendees</div>}
                            onChange={(selectedOptions) => {
                                // Update the state when options are selected
                                setValue('employee_email', selectedOptions);
                            }}
                        />
                    </Grid>
                    <Grid md={12} container display={'flex'} mt={2} >
                        <Grid md={12}>
                            <TextInput
                                placeholder='Enter email address'
                                {...register('email')}
                                // value={watch('minutes')}
                                variant="outlined"
                                fullWidth
                                control={control}
                            />
                        </Grid>
                        {/* <Grid display={'flex'} justifyContent={'end'} style={{ marginLeft: 'auto' }} md={2}>
                            <Button variant='outlined'>
                                Add
                            </Button>
                        </Grid> */}
                    </Grid>
                    <Grid mt={2}>
                        <TextField
                            id="minutesTextarea"
                            placeholder='Minutes'
                            {...register('minutes')}
                            value={watch('minutes')}
                            variant="outlined"
                            fullWidth
                            multiline
                            // rows={5}
                            maxRows={15}
                            sx={{ width: '100%' }}
                            required
                        />
                    </Grid>
                    <Grid display={'flex'} justifyContent={'space-between'} mt={2}>
                        {
                            copied ?
                                <a a style={{ cursor: 'pointer', color: 'grey', marginLeft: '10px', display: 'flex', fontSize: '13px', alignItems: 'center', }} onClick={handleCopyToClipboard}><Done style={{ fontSize: '17px' }} /> copied!</a>
                                :
                                <a a style={{ cursor: 'pointer', color: 'black', fontSize: '13px', display: 'flex', alignItems: 'center', }} onClick={handleCopyToClipboard}><ContentPaste style={{ fontSize: '17px' }} fontSize='small' /> copy</a>

                        }
                        <Button type='submit' variant='outlined'>
                            Send Mail
                        </Button>
                    </Grid>
                </form>
            </Box>
        </Grid >
    );
}

export default SendTab;
