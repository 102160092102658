import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    TextareaAutosize,
    Select,
    TextField,
    Slide, Typography, FormControlLabel, Checkbox, Box
} from "@mui/material";
import { TimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/lab';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import ReactSelector from 'react-select';
import { Backlinks } from '../../../../../api/Endpoints/Backlinks';
import TextInput from '../../../../form/TextInput';
import { useParams } from 'react-router-dom';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({


    // site_name: yup.string().required("site name is requried field"),
    // description: yup.string(),
    // start_date: yup.string(),
    // end_date: yup.string(),
    // due_date: yup.string(),
    // assigned_by_id: yup.object(),

    // reviewer_id: yup.object().shape({
    //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    // }).required().typeError("Please choose a reviewer"),
})




const CreateLinks = (props) => {

    // console.log(props);

    const { id } = useParams();
    // console.log(id);

    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [noFollowLinkChecked, setNoFollowLinkChecked] = useState({});

    const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => { setOpen(false); };


    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });


    const SiteCategory = [
        { value: "1", label: '1' },
        { value: "2", label: '2' },
        { value: "3", label: '3' },
        { value: "4", label: '4' },
        { value: "5", label: '5' },

    ];

    const LinkCount = watch('link_count')

    const SiteType = [
        { value: 'Paid', label: 'Paid' },
        { value: 'Free', label: 'Free' },
        { value: 'Exchange', label: 'Exchange' },
        { value: 'Other', label: 'Other' },
    ];


    const handleCheckboxChange = (index, checked) => {
        setNoFollowLinkChecked(prevState => ({
            ...prevState,
            [index]: checked,
        }));
    };



    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        let links = [];

        for (let index = 1; index <= parseInt(data.link_count); index++) {
            let link = {
                url: data[`url${index}`],
                anchor_text: data[`anchor${index}`],
                do_follow: noFollowLinkChecked[index] ? 1 : 0// Assuming your noFollowLinkChecked works in reverse
            };
            links.push(link); // Add the link object to the array
        }

        let dataToSubmit = {
            id: props?.editId,
            article_link: data.article_link,
            payment: data.payment,
            currency: data.currency,
            payment_details: data.payment_details,
            remarks: data.remarks,
            backlink_sites_id: id,
            links: links
        };

        let action;

        if (props.editId > 0) {
            action = Backlinks.updateArticle(dataToSubmit)
        } else {
            action = Backlinks.addArticels(dataToSubmit)
        }
        action.then(response => {
            console.log("this is response data", response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            setOpen(false);
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
        // Close the dialog after submitting



        // Set the newLinkArray to the linkArray state

    };





    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('projects_id')])



    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchAritcleDetails()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        }

    }, [props.editId])


    const fetchAritcleDetails = async () => {
        setLoading(true)
        let vendor = await Backlinks.getArticleDetails({ id: props.editId });
        if (vendor.data.status === "success") {
            console.log("sueccesss",vendor);
            let data = vendor.data.data;
            setValue('article_link', data.article_link);
            setValue('payment', data.payment);
            setValue('currency', data.currency);
            setValue('payment_details', data.payment_details);
            setValue('remarks', data.remarks);



        }
        setLoading(false)
    };

    return (
        <div>

            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Add BackLink"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit BackLink" : "Add BackLink"}</DialogTitle>
                <DialogContent>
                    {loading ? <Typography ml={8} mt={3}> Loading...</Typography> : (
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="article_link"
                                    label="Article Link"
                                    value={watch('article_link')} />
                            </Grid>
                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="other_link"
                                    label="Return Link from Other Site"
                                    value={watch('other_link')} />
                            </Grid>



                            {/* Part for Dropdown's   */}

                            {/* <ListArticles /> */}

                            {/* Part for Dropdown's   */}
                            {props.editId ? null : (


                                <Grid container sx={{ p: 1, mt: 2 }} alignItems="center">
                                    <Grid item xs={2}>
                                        <InputLabel sx={{
                                            color: "black",
                                            pb: 1,
                                        }}>Link Count
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={8} ml={1}>
                                        <ReactSelector
                                            options={SiteCategory}
                                            value={SiteCategory.filter(Maintenance_status => Maintenance_status.label === watch('link_count'))}
                                            name='link_count'
                                            isClearable={true}
                                            defaultValue={watch('link_count')}
                                            styles={{
                                                menu: provided => ({ ...provided, zIndex: 9999 })
                                            }}
                                            onChange={(selectedOption) => setValue('link_count', selectedOption?.value || "")}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {LinkCount === '1' && (
                                <div style={{ border: '1px solid hsl(0, 0%, 80%)', padding: '10px', marginBottom: '10px', marginTop: '10px' }}>
                                    {Array.from({ length: parseInt(LinkCount) }).map((_, index) => (
                                        <div key={index} >
                                            <Grid container sx={{ mt: 1 }} alignItems="center">
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <TextInput
                                                        control={control}
                                                        name={`url${index + 1}`}
                                                        label={`URL ${index + 1}`}
                                                        value={watch(`url${index + 1}`)}
                                                    />
                                                </Grid>
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <TextInput
                                                        control={control}
                                                        name={`anchor${index + 1}`}
                                                        label={`Anchor ${index + 1}`}
                                                        value={watch(`anchor${index + 1}`)}
                                                    />
                                                </Grid>
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={noFollowLinkChecked[String(index + 1)] || false}
                                                                    onChange={(e) => handleCheckboxChange(String(index + 1), e.target.checked)}
                                                                />
                                                            }
                                                            label="NO FOLLOW LINK"


                                                        />

                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ))}
                                </div>
                            )}



                            {LinkCount === '2' && (
                                <div style={{ border: '1px solid hsl(0, 0%, 80%)', padding: '10px', marginBottom: '10px', marginTop: '10px' }}>
                                    {Array.from({ length: parseInt(LinkCount) }).map((_, index) => (
                                        <div key={index} >
                                            <Grid container sx={{ mt: 1 }} alignItems="center">
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <TextInput
                                                        control={control}
                                                        name={`url${index + 1}`}
                                                        label={`URL ${index + 1}`}
                                                        value={watch(`url${index + 1}`)}
                                                    />
                                                </Grid>
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <TextInput
                                                        control={control}
                                                        name={`anchor${index + 1}`}
                                                        label={`Anchor ${index + 1}`}
                                                        value={watch(`anchor${index + 1}`)}
                                                    />
                                                </Grid>
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={noFollowLinkChecked[String(index + 1)] || false}
                                                                    onChange={(e) => handleCheckboxChange(String(index + 1), e.target.checked)}
                                                                />
                                                            }
                                                            label="NO FOLLOW LINK"


                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ))}
                                </div>
                            )}


                            {LinkCount === '3' && (
                                <div style={{ border: '1px solid hsl(0, 0%, 80%)', padding: '10px', marginBottom: '10px', marginTop: '10px' }}>
                                    {Array.from({ length: parseInt(LinkCount) }).map((_, index) => (
                                        <div key={index}>
                                            <Grid container sx={{ mt: 1 }} alignItems="center">
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <TextInput
                                                        control={control}
                                                        name={`url${index + 1}`}
                                                        label={`URL ${index + 1}`}
                                                        value={watch(`url${index + 1}`)}
                                                    />
                                                </Grid>
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <TextInput
                                                        control={control}
                                                        name={`anchor${index + 1}`}
                                                        label={`Anchor ${index + 1}`}
                                                        value={watch(`anchor${index + 1}`)}
                                                    />
                                                </Grid>
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={noFollowLinkChecked[String(index + 1)] || false}
                                                                    onChange={(e) => handleCheckboxChange(String(index + 1), e.target.checked)}
                                                                />
                                                            }
                                                            label="NO FOLLOW LINK"


                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {LinkCount === '4' && (
                                <div style={{ border: '1px solid hsl(0, 0%, 80%)', padding: '10px', marginBottom: '10px', marginTop: '10px' }}>
                                    {Array.from({ length: parseInt(LinkCount) }).map((_, index) => (
                                        <div key={index}>
                                            <Grid container sx={{ mt: 1 }} alignItems="center">
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <TextInput
                                                        control={control}
                                                        name={`url${index + 1}`}
                                                        label={`URL ${index + 1}`}
                                                        value={watch(`url${index + 1}`)}
                                                    />
                                                </Grid>
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <TextInput
                                                        control={control}
                                                        name={`anchor${index + 1}`}
                                                        label={`Anchor ${index + 1}`}
                                                        value={watch(`anchor${index + 1}`)}
                                                    />
                                                </Grid>
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={noFollowLinkChecked[String(index + 1)] || false}
                                                                    onChange={(e) => handleCheckboxChange(String(index + 1), e.target.checked)}
                                                                />
                                                            }
                                                            label="NO FOLLOW LINK"


                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {LinkCount === '5' && (
                                <div style={{ border: '1px solid hsl(0, 0%, 80%)', padding: '10px', marginBottom: '10px', marginTop: '10px' }}>
                                    {Array.from({ length: parseInt(LinkCount) }).map((_, index) => (
                                        <div key={index}>
                                            <Grid container sx={{ mt: 1 }} alignItems="center">
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <TextInput
                                                        control={control}
                                                        name={`url${index + 1}`}
                                                        label={`URL ${index + 1}`}
                                                        value={watch(`url${index + 1}`)}
                                                    />
                                                </Grid>
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <TextInput
                                                        control={control}
                                                        name={`anchor${index + 1}`}
                                                        label={`Anchor ${index + 1}`}
                                                        value={watch(`anchor${index + 1}`)}
                                                    />
                                                </Grid>
                                                <Grid sx={{ p: 1 }} item xs={4}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={noFollowLinkChecked[String(index + 1)] || false}
                                                                    onChange={(e) => handleCheckboxChange(String(index + 1), e.target.checked)}
                                                                />
                                                            }
                                                            label="NO FOLLOW LINK"


                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <Grid container spacing={1} sx={{ p: 1, pt: 1 }}>
                                <Grid item xs={6}>
                                    <TextInput
                                        control={control}
                                        label="Payment"
                                        name='payment'
                                        value={watch('payment')}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextInput
                                        control={control}
                                        name='currency'
                                        label="Currency"
                                        value={watch('currency')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid sx={{ p: 1, pt: 2 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1
                                }}>Payment Details </InputLabel>
                                <TextField
                                    {...register('payment_details')}
                                    defaultValue={watch('payment_details')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    sx={{ width: '100%', }}

                                />
                            </Grid>
                            <Grid sx={{ p: 1, pt: 2 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1
                                }}>Remarks </InputLabel>
                                <TextField
                                    {...register('remarks')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    sx={{ width: '100%', }}

                                />
                            </Grid>

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            </Grid>

                        </form>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog >
        </div >
    );
};

export default CreateLinks