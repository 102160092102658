import { get, post } from './../Config/config';


export const VendorPayments = {

    get: (data) => get('/accounts/vendor-payments', { params: data }),
    vendors: () => get('/inventory/vendors/list'),
    add: (data) => post('/accounts/vendor-payments/store', data),
    update: (data) => post('/accounts/vendor-payments/update', data),
    getDetails: (data) => get('/accounts/vendor-payments/view', { params: data })
}
