import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Modal,
    Typography
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-hot-toast';
import { Waveform } from '@uiball/loaders'
import { CloudUpload, DeleteOutline } from '@mui/icons-material';
import Delete from '../../common/Popup/Delete';
import TextInput from '../../../form/TextInput';
import { VideoShootApi } from '../../../../api/Endpoints/VideoShoot';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function VideoShootAttachments({ id, paymentId }) {
    const { handleSubmit, control, setValue, reset } = useForm();
    const [loading, setLoading] = useState(false);
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [ID, setID] = useState(0);
    const [list, setList] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const pasteAreaRef = useRef(null);

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const columns = [
        {
            field: 'created_user',
            headerName: 'Users',
            width: 180,
            renderCell: (params) => (
                <a style={{ color: 'black' }}>{params.value?.name}</a>
            ),
        },
        {
            field: 'attachment',
            headerName: 'Attachments',
            width: 330,
            renderCell: (params) => (
                <>
                    <a target='_blank' href={`https://dashboard.omnisellcrm.com/${params?.value}`} >{trimUrlAndNumbers(params.row?.attachment)}</a>
                </>
            ),
        },
        {
            field: 'action', headerName: '', width: 50, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <>
                    <Button style={{ color: 'black' }} size={'small'} onClick={() => handleDeleteAlert(params.row.id)}>
                        <DeleteOutline />
                    </Button>
                </>;
            }
        },
    ];

    const fetchList = () => {
        setLoading(true);
        VideoShootApi.listAttachments({ project_video_shoots_id: id }).then(response => {
            setList(response.data.data);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    };
    console.log(list);

    function trimUrlAndNumbers(url) {
        const lastSlashIndex = url?.lastIndexOf('/');
        let trimmedString = url?.substring(lastSlashIndex + 1);
        trimmedString = trimmedString?.replace(/[0-9]/g, ''); // Replace all numeric characters with an empty string
        return trimmedString?.replace(/_/g, ''); // Replace all underscores with an empty string
    }


    const handleFileChange = (e) => {
        const newFile = e?.target?.files[0];
        setFile(newFile);
    };

    const handleDeleteAttachment = () => {
        setFile(null);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            setFile(droppedFiles[0]);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDeleteAlert = (id) => {
        setID(id);
        setDeletePopup(true);
    };

    useEffect(() => {
        fetchList();
    }, []);

    const onSubmit = async () => {
        if (file) {
            setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });

            const formData = new FormData();
            formData.append('project_video_shoots_id', id);
            formData.append('attachment', file);

            VideoShootApi.addAttachments(formData).then((response) => {
                if (response?.data?.status === 'success') {
                    fetchList();
                    setModalOpen(false);
                    toast.success('Attachments has been successfully saved.');
                    setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
                    setFile(null);
                } else {
                    toast.error(response?.data?.error?.message);
                }
            }).catch((error) => {
                console.error(error);
                setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
                toast.error('An error occurred. Please try again.');
            });
        } else {
            toast.error('Please choose a file');
        }
    };

    const handlePaste = (event) => {
        const items = event.clipboardData.items;
        for (let item of items) {
            if (item.kind === 'file' && item.type.startsWith('image/')) {
                const file = item.getAsFile();
                setFile(file);
                event.preventDefault();
                break;
            }
        }
    };

    useEffect(() => {
        const pasteArea = pasteAreaRef.current;
        if (pasteArea) {
            pasteArea.addEventListener('paste', handlePaste);
        }

        return () => {
            if (pasteArea) {
                pasteArea.removeEventListener('paste', handlePaste);
            }
        };
    }, []);

    return (
        <div>
            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                Callfunc={() => fetchList()}
                url="/projects/video-shoots/attachments/delete?id="
                title="Attachment" />}

            {loading ? (
                <Grid mt={'40%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Waveform
                        size={40}
                        lineWeight={3.5}
                        speed={1}
                        color="black"
                    />
                </Grid>
            ) : (
                <>
                    <Grid display={'flex'} justifyContent={'end'} item xs={2} sm={2} >
                        <Button onClick={() => setModalOpen(true)} variant="outlined">Add</Button>
                    </Grid>
                    <div style={{ height: 300, width: '100%', marginTop: '20px' }}>
                        {typeof list === "object" &&
                            <DataGrid
                                paginationMode="server"
                                rows={list}
                                columns={columns}
                                page={pageNumber}
                                pageSize={10}
                                rowHeight={20}
                                rowsPerPageOptions={[10]}
                                rowCount={list?.meta?.total}
                                onPageChange={handlePageChange}
                                autoHeight
                                density={"comfortable"}
                                getRowId={(row) => row?.id || row?.title || row?.shoot_start_date || undefined}
                            />
                        }
                    </div>
                </>
            )}

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ textAlign: "start", pb: 5 }}>
                        Attach Document
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)} onDrop={handleDrop} onDragOver={handleDragOver}>
                        <input
                            type="file"
                            id="file-upload"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <label htmlFor="file-upload">
                            <Button
                                variant="contained"
                                startIcon={<CloudUpload />}
                                component="span"
                                sx={{ mt: 2 }}
                            >
                                Choose File
                            </Button>
                        </label>
                        <Box
                            sx={{
                                border: '2px dashed gray',
                                borderRadius: '4px',
                                p: 2,
                                textAlign: 'center',
                                mt: 2,
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                        >
                            {file ? (
                                <Typography>{file.name}</Typography>
                            ) : (
                                <Typography>Drag and drop a file here</Typography>
                            )}
                        </Box>
                        {file && (
                            <List sx={{ mt: 2, maxHeight: 200, overflowY: 'auto' }}>
                                <ListItem>
                                    <ListItemIcon>
                                        <CloudUpload />
                                    </ListItemIcon>
                                    <ListItemText primary={file.name} />
                                    <Button
                                        edge="end"
                                        onClick={() => handleDeleteAttachment()}
                                    >
                                        <DeleteOutline />
                                    </Button>
                                </ListItem>
                            </List>
                        )}
                        
                        {/* Separate paste area for images */}
                        <textarea
                            ref={pasteAreaRef}
                            style={{
                                border: '2px dashed gray',
                                borderRadius: '4px',
                                p: 2,
                                textAlign: 'center',
                                mt: 2,
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                            placeholder='paste here'
                        >
                            <Typography>Paste an image here</Typography>
                        </textarea>

                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 2, mr: 2,
                                    background: "gray",
                                    '&:hover': {
                                        backgroundColor: 'gray',
                                    },
                                }}
                                onClick={() => setModalOpen(false)}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                style={{ height: '40px' }}
                                sx={{ mt: 2 }}
                                loading={formButtonStatus.loading}
                                disabled={formButtonStatus.disabled}
                                variant="contained"
                                type="submit"
                            >
                                {formButtonStatus.label}
                            </LoadingButton>
                        </div>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default VideoShootAttachments;