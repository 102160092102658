import { get, post } from './../Config/config';
export const Backlinks = {
    get: (data) => get(`/backlink-sites`, { params: data }),
    add: (data) => post('/backlink-sites/store', data),
    getBacklinksitestDetails: (data) => get(`/backlink-sites/view?id=`, { params: data }),
    update: (data) => post('/backlink-sites/update', data),


    //  Articles

    ListArticles: (data) => get('/backlink-sites/articles', { params: data }),
    addArticels: (data) => post('/backlink-sites/articles/store', data),
    getArticleDetails: (data) => get(`/backlink-sites/articles/view?id=${data.id} `,),
    updateArticle: (data) => post('/backlink-sites/articles/update', data),

    // Links

    getLInk: (data) => get('/backlink-sites/articles/links?articles_id=', { params: data }),
    addLink: (data) => post('/backlink-sites/articles/links/store', data),
    // search: (data) => get(`/ accounts / search ? `, { params: data }),
    // getDetails: (data) =>
    //     get(`/ accounts / get - account`, { params: data })
}
