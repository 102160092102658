import React, { useEffect, useState } from 'react';
import { useAppSettings } from "../../../hooks/use-app-settings";
import moment from "moment";
import { Backdrop, Box, Button, Card, CircularProgress, Grid, TextField } from "@mui/material";
import { PreQualifiers } from "../../../api/Endpoints/PreQualifiers";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Account } from "../../../api/Endpoints/Account";
import toast from "react-hot-toast";
// import { Meetings as meeting } from '../../../api/Endpoints/Meeting';
import { AddIcCallOutlined } from '@mui/icons-material';
import Modal from './modal';
import DetailView from './Details/DetailView';
import BasicTabs from './TabMenus';
import Delete from '../common/Popup/Delete';
import { Meetings } from '../../../api/Endpoints/Meeting';
import { update } from '@react-spring/web';
import { Project } from '../../../api/Endpoints/Project';
import AsyncSelect from "react-select/async";
import { useForm } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const Meeting = (props) => {
    const appSettings = useAppSettings();

    const { watch, formState: { errors }, setValue, getValues, } = useForm();


    const columns = [
        // { field: 'id', headerName: 'ID' },
        {
            field: 'title', headerName: 'Title', width: 300, renderCell: (params) => (
                <Grid onClick={() => handleDetailView(params)}>
                    <p
                        size="small"
                        style={{ marginLeft: 1, color: 'blue', cursor: 'pointer' }} >
                        {params.row.title}
                    </p>
                </Grid>
            )
        },
        {
            field: 'project',
            headerName: 'Project',
            width: 300,
            renderCell: (params) => (
                params.row.meetables[0]?.meetable?.name || ''
            )
        },
        // { field: 'meeting_link', headerName: 'Meeting Link', width: 400, renderCell: (params) => <a target='_blank' href={params?.row?.meeting_link}>{params.row.meeting_link}</a> },

        { field: 'meeting_date', headerName: 'Date', width: 120, renderCell: (params) => { return moment(params.row.meeting_on).format('DD-MM-YYYY') } },
        { field: 'meeting_time', headerName: 'Time', width: 120, renderCell: (params) => { return moment(params.row.meeting_on).format('hh:mm A') } },

        // { field: 'remarks', headerName: 'remarks', width: 300 },
        // { field: 'created_at', headerName: 'Created On', width: 150, renderCell: (params) => { return moment(params.value).format('MMM Mo dddd') } },

        {
            field: 'Edit',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                <>
                    <a size={'small'}
                        variant='outlined'
                        color='primary'
                        style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => handleEdit(params)}>
                        <EditIcon fontSize="small" />
                    </a>
                </>
            )
        },
        // {
        //     field: 'Delete',
        //     headerName: '',
        //     width: 80,
        //     renderCell: (params) => (
        //         <>
        //             <a size={'small'}
        //             style={{cursor:'pointer'}}
        //                 variant='outlined'
        //                 color='error'
        //                 onClick={() => handleDelete(params.id)}>
        //                 <DeleteIcon fontSize='small' color='error' />
        //             </a>
        //         </>
        //     )
        // }

    ]

    const [meetings, setMeetings] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [showCratePage, setShowCreatePage] = useState(false)
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [ClientID, setClientID] = useState(0)
    const [deleteId, setDeleteId] = useState(false)
    const [projectID, setprojectID] = useState([])
    const [detailViewID, setDetailViewID] = useState(false)
    const [projects, setProjects] = useState([])
    const [selectedProject, setSelectedProject] = useState()
    const [counter, setcounter] = useState(30)
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handlePageNumberChange = (event) => {
        const newPageNumber = parseInt(event.target.value, 10) - 1;
        setPageNumber(newPageNumber);
        // Add any other logic you want to perform when the page number changes
    };

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleDelete = (id) => {
        setDeleteId(id)
    }
    const handleEdit = (id) => {
        console.log(id);
        setEditId(parseInt(id.id));
        let proIds = id.row.meetables.map(meetable => meetable.meetable.id)
        console.log(proIds);
        setprojectID(proIds)
        // console.log(id.row.meetable_id);
        setShowCreatePage(true)
        setRefresh(Math.random);

    }
    const detailEdit = (id) => {
        console.log(id);
        setEditId(parseInt(id.id));
        let proIds = id.meetables.map(meetable => meetable.meetable.id)
        console.log(proIds);
        setprojectID(proIds)
        // console.log(id.row.meetable_id);
        setShowCreatePage(true)
        setRefresh(Math.random);

    }

    const handleDetailView = (id) => {
        console.log('working');
        setDetailViewID(parseInt(id.id))
        if (id?.row?.meetables) {
            let proIds = id.row.meetables.map(meetable => meetable.meetable.id)
            setprojectID(proIds)
        }
        // console.log(proIds);
    }
    const fetchTable = async () => {

        console.log(searchKeyword);
        setLoading(true);
        Meetings.get({ projects_id: selectedProject?.id, keyword: searchKeyword, page: Number(pageNumber) + 1,limit:30 }).then(response => {
            console.log(response?.data?.data);
            setMeetings(response.data.data);
            let proj = response.data.data.meetables
            setProjects(proj)
            setLoading(false);
        }).catch(error => {
            // console.log(error);
            setLoading(false);
        })


    }

    const handleCreateNew = () => {


    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(async () => {
        fetchTable();

    }, [searchKeyword, listType, pageNumber, selectedProject])

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }


    const handleClickOpen = () => {
        handleCreate()
        setShowCreatePage(true)
    }
    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const viewTabs = (id) => {
        setClientID(id)
        setTabs(true)
    }

    const fetchProject = (e) => {
        return Project.get({ keyword: e }).then(response => {
            // console.log(response);
            if (typeof response.data.data.data !== "undefined") {
                return (response.data.data.data)
            } else {
                return [];
            }
        })
    }

    const selectProject = (data) => {
        if (data) {
            setValue('project', { id: data?.id || '', name: data?.name || '' })
        } else {
            setValue('project', '')
        }
        setSelectedProject(data)
    }

    useEffect(() => {
        function getQueryParam(param) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            return urlParams.get(param);
        }
        const value = getQueryParam('id'); // If the URL is http://example.com?key=value
        console.log(value);
        if (value > 0) {
            setDetailViewID(parseInt(value))
        }
    }, [])

    return (
        <>
            {/* {Tabs ? (<BasicTabs setTabs={setTabs} id={Tabs} />
            ) : ( */}



            <>

                {
                    detailViewID &&
                    <BasicTabs detailEdit={detailEdit} id={detailViewID} projectID={projectID} handleClose={setDetailViewID} />
                }

                {deleteId && <Delete
                    ID={deleteId}
                    setID={setDeleteId}
                    setDeletePopup={setDeleteId}
                    Callfunc={() => fetchTable()}
                    url="/meetings/delete?id="
                    title="Meeting" />}
                <PageHeader title={"Meetings"} onCreateNew={handleCreateNew} />

                <Box sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'space-between' }} variant="outlined" >
                    <Grid display={'flex'} alignItems={'center'}>

                        <Grid mr={2} sx={{ width: 250 }}>
                            <AsyncSelect
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isClearable={true}
                                defaultOptions
                                name='project'
                                loadOptions={fetchProject}
                                getOptionLabel={(e) => e.name}
                                getOptionValue={(e) => e.id}
                                placeholder={'Project'}
                                value={watch('project')}
                                onInputChange={fetchProject}
                                onChange={selectProject}
                            />
                        </Grid>


                    </Grid>
                    <Grid display={'flex'} alignItems={'center'}>
                        <TextField
                            style={{ marginRight: 10 }}
                            size='small'
                            id="outlined-name"
                            label="Search Meetings"
                            onChange={handleSearchEntry}
                        />
                        <Button style={{ width: 100 }} variant="contained" onClick={handleClickOpen}>
                            {/* {props.icon ? <AddIcCallOutlined style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }} /> :  */}
                            Create
                        </Button>
                    </Grid>

                    {showCratePage &&
                        <Modal
                            detailEdit={detailEdit}
                            handleDetailView={handleDetailView}
                            key={refresh}
                            fetchTable={() => fetchTable()}
                            onNew={handleCreate}
                            editId={editId}
                            projectID={projectID}
                            setEditId={setEditId}
                            setShowCreatePage={setShowCreatePage}
                            onUpdate={handleUpdate}
                        />
                    }

                </Box>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>


                <Card sx={{ m: 2, height: 500, position: 'relative' }} variant="outlined">

                    {typeof meetings?.data === "object" &&
                        <>
                            <DataGrid
                                paginationMode="server"
                                rows={meetings?.data}
                                columns={columns} 
                                page={pageNumber}
                                pageSize={10}
                                rowHeight={20}
                                rowsPerPageOptions={[10]}
                                rowCount={meetings.total}
                                onPageChange={handlePageChange}
                                // autoHeight
                                density={"comfortable"}
                            />
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', position: 'absolute', bottom: 16, left: '77%', transform: 'translateX(-50%)', zIndex: 1000 }}>
                                <label htmlFor="pageNumber" style={{ marginRight: '10px' }}>Go to Page:</label>
                                <select
                                    id="pageNumber"
                                    value={pageNumber + 1}
                                    onChange={handlePageNumberChange}
                                >
                                    {Array.from({ length: Math.ceil(meetings?.last_page) }, (_, i) => (
                                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                                    ))}
                                </select>
                            </div>
                        </>
                    }
                </Card>
            </>
            {/* )} */}

        </>

    );
};

export default Meeting;
