import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Card, CircularProgress, Grid, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import Create from './create';
import { Edit, PersonAddAlt, PersonAddAlt1, PersonAddAlt1Sharp } from '@mui/icons-material';
import moment from 'moment';
import { CheckCircle, CheckCircleOutline, Person, PersonAdd, PersonAddSharp } from '@material-ui/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import PageHeader from '../../../common/page-header';
import CostPopup from '../../../common/Popup/CostPopup';
import AcceptPopup from '../../../common/Popup/AcceptPopup';
import AssignToPopup from '../../../common/Popup/AssignTo';
import { WorkRequest } from '../../../../../api/Endpoints/WorkRequest';


const scheme = yup.object().shape({

})

const ClientWorkRequest = (props) => {



    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }


    const { watch, formState: { errors }, setValue, getValues, } = useForm({ resolver: yupResolver(scheme) });

    const [editId, setEditId] = useState();
    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0);
    const [openCostpopup, setOpencosePopup] = useState(false)
    const [openAcceptpopup, setOpenAcceptPopup] = useState(false)
    const [openAssignTopopup, setOpenAssignTopopup] = useState(false)
    const [loading, setLoading] = useState(false);
    const [editedid, setEditedID] = useState(null)
    const [Tabs, setTabs] = useState(false)
    const [WorkID, setWorkID] = useState(0)
    const [selectedProjectID, setSelectedProjectID] = useState(null)
    const [projects, setProjects] = useState([])


    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }
    const viewTabs = (id) => {
        setWorkID(id)
        setTabs(true)
    }

    const CostPopuphandler = () => {
        setOpencosePopup(true)
    }
    const AcceptPopuphandler = (id) => {
        setOpenAcceptPopup(id)
    }
    const AssignToPopuphandler = (id) => {
        setOpenAssignTopopup(id)
    }

    const columns = [
        {
            field: 'name', headerName: 'Title', width: 200, renderCell: (params) => (
                <Button
                    style={{ color: 'black' }}
                    size="small"
                    // onClick={() => viewTabs(params.id)}
                >
                    {params.row.title}

                </Button >
            ),
        },
        {
            field: 'project', headerName: 'Project', width: 200, renderCell: (params) => <>{params.row.project.name}</>
        },
        { field: 'status', headerName: 'Status', width: 100, renderCell: (params) => <>{params.row.status}</> },
        {
            field: 'assignedTo', headerName: 'Assigned To', width: 130, renderCell: (params) => <>
                {params.row.assigned_to_user?.name}
                {
                    //  marginLeft: 'auto',
                    !params.row.assigned_to_user &&
                    <p style={{ cursor: 'pointer', width: 10, color: 'blue' }} size='small' onClick={() => AssignToPopuphandler(params.id)}>
                        <PersonAdd style={{ marginLeft: 'auto', color: 'blue' }} color='blue' />
                    </p>
                }
            </>
        },
        {
            field: 'requestDate', headerName: 'Request Date', width: 150, renderCell: (params) => <>{moment(params.row.start_date).format('DD-MM-YYYY')
            }</>
        },
        {
            field: 'dueDate', headerName: 'Due Date', width: 150, renderCell: (params) => <>{moment(params.row.expected_completion_date).format('DD-MM-YYYY')

            }</>
        },
        {
            field: 'Edit',
            headerName: 'edit',
            width: 100,
            renderCell: (params) => (
                <>
                    <Button variant='contained' size='small' onClick={() => handleEdit(params.id)}>
                        Edit
                    </Button>
                </>
            )
        },
        // {
        //     field: 'Cost',
        //     headerName: 'cost',
        //     width: 75,
        //     renderCell: (params) => (
        //         <>
        //             <Button size='small' onClick={() => CostPopuphandler()}>
        //                 <MonetizationOnIcon color='success' sx={{ color: 'black' }} />
        //             </Button>
        //         </>
        //     )
        // },
        {
            field: 'accept',
            headerName: 'accept',
            width: 100,
            renderCell: (params) => (
                <>
                    <Button size='small' onClick={() => AcceptPopuphandler(params.id)}>
                        <CheckCircleOutline color='primary' />
                    </Button>
                </>
            )
        }


    ];


    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }


    const fetchTable = () => {
        setLoading(true);
        WorkRequest.get({ keyword: searchKeyword,client_id:props.ClientID }).then(response => {
            console.log(response.data.data.data);
            setList(response.data.data.data);
            setLoading(false);
        })
    }


    const handleCreateNew = () => {

    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(async () => {
        fetchTable();
    }, [searchKeyword, listType, pageNumber])


    return (
        <>
            {/* {Tabs ? (<BasicTabs setTabs={setTabs} WorkID={WorkID} />
            ) : ( */}


                <>

                    <Grid sx={{ p: 2, display: "flex", justifyContent: "end" }} variant="outlined">
                        <Grid display={'flex'}>
                            <TextField
                                style={{ width: 300, marginRight: 10 }}
                                id="outlined-name"
                                size="small"
                                autoFocus
                                label="Search Work Requests"
                                onChange={handleSearchEntry}
                            />
                            <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} />

                        </Grid>

                    </Grid >
                    {openCostpopup && <CostPopup openCostpopup={openCostpopup} setOpencosePopup={setOpencosePopup} />}
                    {openAcceptpopup && <AcceptPopup openAcceptpopup={openAcceptpopup} setOpenAcceptPopup={setOpenAcceptPopup} onUpdate={handleUpdate} />}
                    {openAssignTopopup && <AssignToPopup openAssignTopopup={openAssignTopopup} setOpenAssignTopopup={setOpenAssignTopopup} onUpdate={handleUpdate} />}


                    <Card sx={{ m: 2 }} variant="outlined">
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        {list &&
                            <DataGrid
                                paginationMode="server"
                                rows={list}
                                columns={columns}
                                page={pageNumber}
                                pageSize={10}
                                rowHeight={20}
                                rowsPerPageOptions={[10]}
                                onPageChange={handlePageChange}
                                autoHeight
                                density={"comfortable"}
                            />
                        }
                    </Card>
                </>
            {/* )} */}
        </>
    );
};

export default ClientWorkRequest;
