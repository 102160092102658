import React, { useEffect, useState } from 'react';
import PageHeader from "../common/page-header";
import Create from './create';
import { useForm } from 'react-hook-form';
import { CreativeCalenderApi } from '../../../api/Endpoints/CreativeCalender';
import { Close, Edit } from '@mui/icons-material';
import CalenderList from './components/calender';
import ItemsList from './components/items/items';
import ItemDetailsList from './components/post details/item-details';
import { Waveform } from '@uiball/loaders';
import { Grid } from '@mui/material';
import AsyncSelect from "react-select/async";
import SelectedCalendar from './components/selectCalendar';
import Notes from './components/notes/notes';


const DetialIndex = ({ calendarID, setCalendarID, handleClose, selectedCalendar, setselectedCalendar }) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm();
    const [editId, setEditId] = useState();
    const [refresh, setRefresh] = useState(0);
    const [detailViewId, setDetailViewId] = useState(false)

    const DetailViewOpen = (id) => {
        setDetailViewId(id)
    }
    const handleEdit = (id) => {
        // setRefresh(Math.random);
        setEditId(parseInt(id));
    }

    const [list, setList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [calendarLoading, setcalendarLoading] = useState(false)
    const [postID, setPostID] = useState()
    const [postLoading, setpostLoading] = useState(false)
    const [posts, setPosts] = useState([])

    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }

    const fetchTable = () => {
        setcalendarLoading(true);
        CreativeCalenderApi.get({ page: parseInt(pageNumber) + 1 }).then(response => {
            setList(response.data);
            setcalendarLoading(false);
        }).catch((error) => {
            setcalendarLoading(false)
        })
    }

    const fetchPosts = () => {
        CreativeCalenderApi.getPosts({ creative_calendar_id: calendarID, page: parseInt(pageNumber) + 1 }).then(response => {
          console.log("working");
          setPosts(response.data);
        }).catch((error) => {
        })
      }

    const selectCalendar = (data) => {
        setselectedCalendar(data)
        setPostID()
    }

    const handleChangePage = (event, newPage) => {
        setPageNumber(newPage);
    };

    const handleUpdate = () => {
        setRefresh(refresh * 2)
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    const handleOpenPosts = (id) => {
        setPostID()
        setCalendarID(id)
    }

    const handleOpenPostDetails = (id) => {
        setPostID(id)
    }

    const handleCloseOthers = () => {
        setPostID()
        setCalendarID()
    }

    useEffect(() => {
        fetchTable();
        fetchPosts()
    }, [calendarID])


    return (

        <>

            <>
                {
                    editId > 0 &&
                    <Create projectName={selectedCalendar?.project?.name} key={refresh} setRefresh={setRefresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} handleCloseOthers={handleCloseOthers} setValue={setValue} setCalendarID={setCalendarID} setselectedCalendar={setselectedCalendar} />

                }

                <Grid container p={1} pr={0} pb={0} style={{ borderBottom: '3px solid #e8e8e8' }}>
                    <Grid md={12} display={'flex'} justifyContent={'end'}>
                        <a style={{ cursor: 'pointer' }} onClick={handleClose}><Close /></a>
                    </Grid>
                    <Grid md={12}>
                        <SelectedCalendar refresh={refresh} calendarID={calendarID} calendar={selectedCalendar} handleEdit={handleEdit} />
                    </Grid>
                    {
                        (calendarID || postLoading) &&
                        <Grid item md={6.6}>
                            {
                                postLoading ?
                                    (
                                        <Grid mt={'40%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <Waveform
                                                size={40}
                                                lineWeight={4}
                                                speed={1}
                                                color="black"
                                            />
                                        </Grid>
                                    ) :
                                    <Grid container>
                                        <Grid item md={6}>
                                            <Notes calendarID={calendarID} />
                                        </Grid>
                                        <Grid item md={6}>
                                            <ItemsList posts={posts} fetchPost={fetchPosts} calendarID={calendarID} handleOpenPostDetails={handleOpenPostDetails} setPostID={setPostID} />
                                        </Grid>
                                    </Grid>
                            }


                        </Grid>
                    }
                    {
                        postID &&
                        <Grid item md={5.4}>
                            <ItemDetailsList project={selectedCalendar?.project} fetchPosts={fetchPosts} postID={postID} />
                        </Grid>
                    }
                </Grid>

            </>
            {/* )} */}

        </>
    )
}

export default DetialIndex