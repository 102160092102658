import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Backdrop, Button, Card, CircularProgress, Grid, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Create from "./create";
import ReactSelector from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import PageHeader from '../../../common/page-header';
import { Task } from '../../../../../api/Endpoints/Task';
import { Project } from '../../../../../api/Endpoints/Project';
import { Employee } from '../../../../../api/Endpoints/Employee';
import BasicTabs from '../../../tasks/TabMenus';


const scheme = yup.object().shape({


    // title: yup.string().required(),
    // description: yup.string(),
    // start_date: yup.string(),
    // end_date: yup.string(),
    // due_date: yup.string().required(),
    // assigned_to_id: yup.object().required(),
    // assigned_by_id: yup.object(),
    // projects_id: yup.object().required()
    // reviewer_id: yup.object().shape({
    //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    // }).required().typeError("Please choose a reviewer"),
})

const ClientTask = (props) => {
    const { watch, formState: { errors }, setValue, getValues, } = useForm({ resolver: yupResolver(scheme) });
    const [editId, setEditId] = useState();
    const [refresh, setRefresh] = useState(0);
    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }
    const handleDelete = (id) => { }
    const columns = [
        {

            field: 'title', headerName: 'title', width: 300,
            renderCell: (params) => <p style={{ cursor: 'pointer' }} onClick={() => DetailViewOpen(params.row.id)}>{params.value}</p>

        },
        {
            field: 'project', headerName: 'Project name', width: 150,
            renderCell: (params) => <p style={{ cursor: 'pointer' }} >{params.value?.name}</p>
            // onClick={() => fetchByProject(params.value?.id)}
        },
        {
            field: 'assigned_to', headerName: 'Assigned to', width: 150,
            valueGetter: (params) => params.row?.assigned_to_user,
            renderCell: (params) => <Button >{params.value?.name}</Button>,
        },
        // {
        //     field: 'assigned_by', headerName: 'Assigned by', width: 150,
        //     valueGetter: (params) => params.row?.assigned_by_user,
        //     renderCell: (params) => <Button>{params.value?.name}</Button>,
        // },
        // {
        //     field: 'reviewer', headerName: 'Reviewer', width: 150,
        //     valueGetter: (params) => params.row?.reviewer,
        //     renderCell: (params) => <Button>{params.value?.name}</Button>,
        // },
        // {
        //     field: 'start_date', headerName: 'Start date', width: 150, renderCell: (params) => {
        //         console.log("This is data", params);
        //         if (params) {

        //             return moment(params.value).format('MMM Mo dddd')
        //         } else {
        //             return "Not started";
        //         }
        //     }
        // },
        // {
        //     field: 'created_at', headerName: 'Created date', width: 150, renderCell: (params) => {
        //         // console.log("This is data", params);
        //         // if (params) {

        //         return moment(params.value).format('DD MMM YYYY')
        //         // } else {
        //         //     return "Not started";
        //         // }
        //     }
        // },
        {
            field: 'due_date', headerName: 'Due date', width: 150, renderCell: (params) => {
                // console.log("This is data", params);
                // if (params) {

                return moment(params.value).format('DD-MM-YYYY')
                // } else {
                //     return "Not started";
                // }
            }
        },

        { field: 'status', headerName: 'Status', width: 100, renderCell: (params) => <>{params.value}</> },
        {
            field: 'action', headerName: 'Edit', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><Button size={'small'}
                    onClick={() => handleEdit(id)}>Edit</Button>
                </>;
            }
        },
        {
            field: 'delete', headerName: 'Delete', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><Button size={'small'}
                    onClick={() => handleDelete(id)}>Delete</Button>
                </>;
            }
        },
    ]

    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    console.log("this is leads", list.data);
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState([])
    const [employees, setEmployees] = useState([])
    const [detailViewId, setDetailViewId] = useState(false)
    const [selectedProjectID, setSelectedProjectID] = useState(null)
    const [selectedAssignedByID, setSelectedAssignedByID] = useState(null)
    const [selectedAssignedToID, setSelectedAssignedToID] = useState(null)
    const [selectedReviewer, setSelectedReviewer] = useState(null)

    const DetailViewOpen = (id) => {
        setDetailViewId(id)
    }

    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }


    const fetchTable = () => {
        console.log(selectedProjectID);
        setLoading(true);
        console.log(watch('projects'));
        Task.get({ projects_id: selectedProjectID?.id, assigned_by: selectedAssignedByID?.id, assigned_to: selectedAssignedToID, reviewer: selectedReviewer, keyword: searchKeyword, page: parseInt(pageNumber) + 1 ,clients_id:props.ClientID}).then(response => {
            console.log(response.data.data);
            setList(response.data.data);
            setLoading(false);
            setValue('projects', selectedProjectID?.name)
        }).catch(error => {
            setLoading(false);
        })

    }

    // const fetchByProject = (project_id) => {
    //     if (!listByProject) {
    //         setLoading(true);
    //         Task.get({ projects_id: project_id, keyword: searchKeyword, page: parseInt(pageNumber) + 1 }).then(response => {
    //             setList(response.data.data);
    //             setListByProject(true)
    //             setLoading(false);
    //         }).catch(error => {
    //             setLoading(false);
    //         })
    //     } else {
    //         fetchTable()
    //     }
    // }

    // const assignValues = () => {
    //     if (selectedProject){
    //         setValue('projects', selectedProject?.name || "")
    //     }
    // }

    const selectProject = (project_id) => {
        setValue('projects', project_id?.name || "")
        console.log(project_id);
        setSelectedProjectID(project_id)
        console.log(selectedProjectID);

    }

    const selectAssignedBy = (assign_to_id) => {
        console.log(assign_to_id);
        setSelectedAssignedByID(assign_to_id)
        setValue('assign_by_id', assign_to_id?.name || "")

    }

    const selectAssignedTo = (assign_to) => {
        console.log(assign_to);
        setSelectedAssignedToID(assign_to?.id)
        setValue('assigned_to', assign_to?.name || "")


    }

    const selectReviewer = (reviewer) => {
        console.log(reviewer);
        setSelectedReviewer(reviewer?.id)
        setValue('reviewer', reviewer?.name || "")


    }

    const selectStatus = (status) => {
        console.log(status);
        setValue('status', status?.name || "")
        setLoading(true);
        if (status != null) {
            Task.get({ status: status.value, keyword: searchKeyword, page: parseInt(pageNumber) + 1 }).then(response => {
                setList(response.data.data);
                // setListByProject(true)
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            })
        } else {
            fetchTable()
        }
    }

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    const fetchProjects = (e) => {
        Project.get({ keyword: e }).then(response => {
            // console.log(response);
            if (typeof response.data.data.data !== "undefined") {
                setProjects(response.data.data.data)
            } else {
                return [];
            }
        })
    }

    const fetchEmployees = (e) => {
        Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                setEmployees(response.data.data.data)
            } else {
                return [];
            }
        })
    }

    const listStatus = [
        { id: 1, name: "Not Started", value: "Not Started" },
        { id: 2, name: "In progress", value: "In progress" },
        { id: 3, name: "Review pending", value: "Review pending" },
        { id: 4, name: "In review", value: "In review" },
        { id: 5, name: "Completed", value: "Completed" },
    ]


    useEffect(() => {

        fetchProjects()
        fetchEmployees()
        console.log('-----------------------');
    }, [])


    useEffect(() => {
        fetchTable();
    }, [searchKeyword, pageNumber, selectedProjectID, selectedAssignedByID, selectedAssignedToID, selectedReviewer])


    return (
        <>
            {
                detailViewId &&
                <BasicTabs id={detailViewId} handleClose={setDetailViewId} />
            }

            <Grid sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: 'space-between' }} variant="outlined">

                <Grid item display={'flex'}>
                    <Grid sx={{ width: 140 }}>
                        {/* <InputLabel sx={{
                            color: "black",
                            fontSize: "14px",
                            pb: .5
                        }}>Choose Project</InputLabel> */}
                        <ReactSelector
                            onInputChange={fetchProjects}
                            placeholder="Project"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={projects}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}

                            // inputValue={inProject}
                            value={
                                projects.find(options =>
                                    options.name === watch('projects')
                                )
                            }
                            name='projects'
                            isClearable
                            defaultValue={(watch('projects'))}
                            onChange={(selectedOption) => selectProject(selectedOption)}
                        />
                    </Grid>

                    <Grid sx={{ width: 160 }}>
                        {/* <InputLabel sx={{
                            color: "black",
                            fontSize: "14px",
                            pb: .5
                        }}>Choose Project</InputLabel> */}
                        <ReactSelector
                            onInputChange={fetchEmployees}
                            placeholder="Assigned By"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={employees}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}

                            // inputValue={inProject}
                            value={
                                employees.find(options =>
                                    options.name === watch('assign_by_id')
                                )
                            }
                            name='assign_by_id'
                            isClearable
                            defaultValue={(watch('assign_by_id'))}
                            onChange={(selectedOption) => selectAssignedBy(selectedOption)}
                        />
                    </Grid>

                    <Grid sx={{ width: 160 }}>
                        {/* <InputLabel sx={{
                            color: "black",
                            fontSize: "14px",
                            pb: .5
                        }}>Choose Project</InputLabel> */}
                        <ReactSelector
                            onInputChange={fetchEmployees}
                            placeholder="Assigned To"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={employees}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}

                            // inputValue={inProject}
                            value={
                                employees.find(options =>
                                    options.name === watch('assigned_to')
                                )
                            }
                            name='assigned_to'
                            isClearable
                            defaultValue={(watch('assigned_to'))}
                            onChange={(selectedOption) => selectAssignedTo(selectedOption)}
                        />
                    </Grid>

                    <Grid sx={{ width: 150 }}>
                        {/* <InputLabel sx={{
                            color: "black",
                            fontSize: "14px",
                            pb: .5
                        }}>Choose Project</InputLabel> */}
                        <ReactSelector
                            onInputChange={fetchEmployees}
                            placeholder="Reviewer"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={employees}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}

                            // inputValue={inProject}
                            value={
                                employees.find(options =>
                                    options.name === watch('reviewer')
                                )
                            }
                            name='reviewer'
                            isClearable
                            defaultValue={(watch('reviewer'))}
                            onChange={(selectedOption) => selectReviewer(selectedOption)}
                        />
                    </Grid>

                    <Grid sx={{ width: 150 }}>
                        {/* <InputLabel sx={{
                            color: "black",
                            fontSize: "14px",
                            pb: .5
                        }}>Choose Project</InputLabel> */}
                        <ReactSelector
                            placeholder="Status"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={listStatus}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}

                            // inputValue={inProject}
                            value={
                                listStatus.filter(options =>
                                    options.name === watch('status')
                                )
                            }
                            name='status'
                            isClearable
                            defaultValue={(watch('status'))}
                            onChange={(selectedOption) => selectStatus(selectedOption)}
                        />
                    </Grid>

                </Grid>

                <Grid display={'flex'}>
                    <TextField
                        style={{ width: 250, marginRight: 10 }}
                        size='small'
                        id="outlined-name"
                        label="Search Tasks"
                        onChange={handleSearchEntry}

                    />
                    <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} />


                </Grid>


            </Grid >
            <Card sx={{ m: 2 }} variant="outlined">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                {typeof list.data === "object" &&
                    <DataGrid
                        paginationMode="server"
                        rows={list?.data}
                        columns={columns}
                        page={pageNumber}
                        pageSize={10}
                        rowHeight={20}
                        rowsPerPageOptions={[10]}
                        rowCount={list.total}
                        onPageChange={handlePageChange}
                        autoHeight
                        density={"comfortable"}
                    />
                }
            </Card>
        </>
    );
};

export default ClientTask;
