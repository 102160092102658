import React, { useEffect, useState } from 'react';
import { useAppSettings } from "../../../hooks/use-app-settings";
import moment from "moment";
import { Backdrop, Button, Card, CircularProgress, TextField } from "@mui/material";
import { PreQualifiers } from "../../../api/Endpoints/PreQualifiers";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Account } from "../../../api/Endpoints/Account";
import toast from "react-hot-toast";
import Create from './create';
import BasicTabs from './TabMenus';
import EditIcon from '@mui/icons-material/Edit';

const Index = (props) => {
    const appSettings = useAppSettings();


    const columns = [

        {
            field: 'account_name', headerName: 'Client Name', width: 150, renderCell: (params) => (
                <>
                    <Button
                        size="small"
                        style={{ marginLeft: 1, color: 'black' }} onClick={() => viewTabs(params.id)}>
                        {params.row.account_name}
                    </Button>
                </>
            )
        },
        { field: 'address', headerName: 'Address', width: 300 },
        { field: 'company_name', headerName: 'Company Name', width: 200 },
        // { field: 'remarks', headerName: 'remarks', width: 300 },
        { field: 'created_at', headerName: 'Created On', width: 130, renderCell: (params) => { return moment(params.value).format('DD-MM-YYYY') } },

        {
            field: 'Edit',
            headerName: 'Edit',
            width: 100,
            renderCell: (params) => (
                <>
                    <a variant="contained"
                        size="small"
                        style={{ marginLeft: 1,cursor:'pointer',color:'blue' }} onClick={() => handleEdit(params.id)}>
                        <EditIcon fontSize='small'  />
                    </a>
                </>
            )
        }

    ]

    const [accounts, setAccounts] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [ClientID, setClientID] = useState(0)
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }
    const fetchClientAccounts = () => {
        setLoading(true);
        Account.get({ keyword: searchKeyword, page: Number(pageNumber) + 1 }).then(response => {
            setAccounts(response.data.data);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })


    }

    const handleCreateNew = () => {

    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(async () => {
        fetchClientAccounts();
    }, [searchKeyword, listType, pageNumber])

    const handleUpdate = () => {
        setSearchKeyword()
        setPageNumber();
        setPageNumber(0);
    }


    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const viewTabs = (id) => {
        setClientID(id)
        setTabs(true)
    }

    return (
        <>
            {Tabs ? (<BasicTabs setTabs={setTabs} ClientID={ClientID} />
            ) : (


                <>
                    <PageHeader title={"Clients"} onCreateNew={handleCreateNew} />

                    <Card sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'space-between' }} variant="outlined" >

                        <TextField
                            id="outlined-name"
                            size='small'
                            style={{width:250}}
                            label="Search Clients"
                            onChange={handleSearchEntry}
                        />
                        <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />

                    </Card>
                    <Card sx={{ m: 2 }} variant="outlined">
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>

                        {typeof accounts.data === "object" &&
                            <DataGrid
                                paginationMode="server"
                                rows={accounts?.data}
                                columns={columns}
                                page={pageNumber}
                                pageSize={10}
                                rowHeight={20}
                                rowsPerPageOptions={[10]}
                                rowCount={accounts.total}
                                onPageChange={handlePageChange}
                                autoHeight
                                density={"comfortable"}
                            />
                        }
                    </Card>
                </>
            )}

        </>

    );
};

export default Index;
