import React from 'react';
import ReactApexCharts from 'react-apexcharts';

const DonutChart = (props) => {
  const options = {
    chart: {
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false, // Hide the legend on the right side
    },
    labels: ['Apples'],
    plotOptions: {
      pie: {
        donut: {
          size: '45%', // Adjust the size to control the inner space
        },
      },
    },
  };

  const series = [65,35];

  return (
    <div style={{ width: props?.width, }}>
      <div id="chart">
        <ReactApexCharts options={options} series={series} type="donut" height={props?.height} />
      </div>
    </div>
  );
};

export default DonutChart;
