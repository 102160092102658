import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    InputLabel,
    Slide,
    Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../../../form/TextInput";
import DateInput from "../../../../form/DateInput";
import SelectX from "../../../../form/SelectX";
import { Project } from "../../../../../api/Endpoints/Project";
import DynamicChip from "../../../../../utils/DynamicChip";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { Task } from "../../../../../api/Endpoints/Task";
import moment from "moment";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import Editor from "../../../../form/Editor";
import { Employee } from "../../../../../api/Endpoints/Employee";
import { Auth } from '../../../../../api/Endpoints/Auth';
import { Users } from '../../../../../api/Endpoints/Users';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




const scheme = yup.object().shape({

    title: yup.string().required(),
    description: yup.string(),
    start_date: yup.string(),
    end_date: yup.string(),
    due_date: yup.string().required(),
    assigned_by_id: yup.object(),
    assigned_to_id: yup.object().shape({
        id: yup.string().required("Please choose a User").typeError("Please choose a User")
    }).required().typeError("Please choose a User"),
    projects_id: yup.object().shape({
        id: yup.string().required("Please choose a Project").typeError("Please choose a Project")
    }).required().typeError("Please choose a Project"),
    // reviewer_id: yup.object().required()
    reviewer_id: yup.object().shape({
        id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    }).required().typeError("Please choose a reviewer"),
})
const priority = [
    { id: 1, name: "Critical" },
    { id: 2, name: "High" },
    { id: 3, name: "Medium" },
    { id: 4, name: "Low" },
]



const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [recentAssignedTo, setRecentAssignedTo] = useState([])
    const [recentReviewer, setRecentReviewer] = useState([])
    const [recentProjects, setRecentProjects] = useState([])

    const handleClickOpen = () => {
        props.onNew();

    };
    const handleClose = () => { setOpen(false); };


    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchProjects = (e) => {
        return Project.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                console.log(response.data.data.data);
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const fetchEmployees = (e) => {
        let project = watch('projects_id');
        return Employee.get({ keyword: e, projects_id: project?.id }).then(response => {
            if (typeof response.data.data.data !== "undefined") {

                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const fetchRecentAssignedTo = () => {
        Users.recentAssignedTo().then((response) => {
            console.log(response);
            setRecentAssignedTo(response.data.data)
        })
    }
    const fetchRecentReviewer = () => {
        Users.recentReviewer().then((response) => {
            console.log(response);
            setRecentReviewer(response.data.data)
        })
    }
    const fetchRecentProjects = () => {
        Users.recentProjects().then((response) => {
            console.log(response);
            setRecentProjects(response.data.data)
        })
    }


    const selectDate = [
        {
            name: "Today",
            value: moment()
        },
        {
            name: "Tomorrow",
            value: moment().add(1, 'day')
        },
        {
            name: "2 Days",
            value: moment().add(2, 'day')
        },
        {
            name: "3 Days",
            value: moment().add(3, 'day')
        },
        {
            name: "4 Days",
            value: moment().add(4, 'day')
        },
        {
            name: "5 Days",
            value: moment().add(5, 'day')
        },

    ]

    const handlePriorityChange = (id) => {
        setSelectedPriority(id);
    }


    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let find = priority.filter(o => o.id === selectedPriority)
        let date = moment(data.due_date).format('YYYY-MM-DD');

        let dataToSubmit = {
            id: props.editId,
            projects_id: data.projects_id?.id,
            title: data.title,
            description: data.description,
            due_date: date,
            assigned_to_id: data.assigned_to_id?.id,
            reviewer_id: data.reviewer_id?.id,
            priority: find[0]?.id,
            status: "Not Started"
        };


        let action;
        if (props.editId > 0) {
            console.log("dataToSubmit", dataToSubmit);
            action = Task.update(dataToSubmit);
        } else {
            action = Task.add(dataToSubmit);
        }

        action.then(response => {
            console.log(response);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    const fetchTaskDetails = async () => {
        try {

            let task = await Task.getTaskDetails({ id: props.editId });
            if (task.data.status === "success") {
                let data = task.data.data;
                console.log(data.project?.id);
                setValue('title', data.title);
                setValue('description', data.description ? data.description : '');
                setValue('due_date', data.due_date);
                setValue('projects_id', { id: data.project?.id, name: data.project?.name })
                setValue('assigned_to_id', { id: data.assigned_to_user?.id, name: data.assigned_to_user?.name })
                setValue('reviewer_id', { id: data.reviewer?.id, name: data.reviewer?.name })
                setSelectedPriority(data.priority);
                console.log(watch('projects_id'))
                console.log(watch('assigned_to_id'))
                console.log(watch('reviewer_id'))
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchUser = async () => {
        const accessToken = window.localStorage.getItem('accessToken');
        const user = await Auth.me(accessToken);
        console.log(user);
        setValue('reviewer_id', { id: user.data.data.id, name: user.data.data.name })
    }

    const fetchEmployeeDetails = () => {
        console.log(props.EmployeeID);
        Employee.getEmployeeDetails({id:props.employee_id}).then((data)=>{
            console.log(data);
            setValue('assigned_to_id', { id: data.data.data?.user?.id, name: data.data.data?.user?.name })
        })
    }

    useEffect(() => {
        fetchRecentAssignedTo({ project_id: watch('projects_id')?.id })
        fetchRecentReviewer()
        fetchRecentProjects()
    }, [watch('projects_id'), watch('assigned_to_id'), watch('reviewer_id')])

    // useEffect(() => {
    //     fetchRecentAssignedTo()
    //     fetchRecentReviewer()
    // }, [])

    useEffect(() => {

        if (parseInt(props.editId) > 0) {
            fetchTaskDetails();
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            fetchUser()
            fetchEmployeeDetails()
            setOpen(true);
        }

    }, [props.editId])





    return (
        <div>

            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Add Task"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Task" : "Add Task"}</DialogTitle>
                <DialogContent>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <ErrorMessage
                            errors={errors}
                            name="multipleErrorInput"
                            render={({ messages }) =>
                                messages &&
                                Object.entries(messages).map(([type, message]) => (
                                    <p style={{ paddingLeft: -5 }} key={type}>{message}</p>
                                ))
                            }
                        />
                        <Grid sx={{ p: 1 }} item xs={12}>
                            <InputLabel sx={{
                                color: "black",
                                fontWeight: 500,
                                fontSize: '14px'
                            }}>
                                <Grid display={'flex'} justifyContent={'space-between'}>
                                    <a style={{ marginRight: 20 }}>Choose Project</a>
                                    <Grid>
                                        {recentProjects.map((proj, index) => (
                                            <a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setValue('projects_id', { id: proj.id, name: proj.name })}> {proj.name}{index != 2 && ','}</a>
                                        ))}
                                    </Grid>
                                </Grid>
                            </InputLabel>
                            <SelectX
                                key={refresh * 0.2}
                                // label={"Choose project"}
                                options={fetchProjects}
                                control={control}
                                error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                                error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                                name={'projects_id'}
                                defaultValue={watch('projects_id')}
                            />
                        </Grid>
                        {/* <Grid sx={{ p: 1 }} item xs={12}>
                            <SelectX

                                label={"Open Task"}
                                options={''}
                                control={control}
                                error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                name={'projects_id'}
                                defaultValue={watch('projects_id')}
                            />
                        </Grid> */}
                        <Grid sx={{ p: 1 }} item xs={12}>
                            <TextInput control={control} name="title"
                                label="Title"
                                value={watch('title')} />
                        </Grid>

                        <Grid sx={{ p: 1 }} item xs={12}>
                            <InputLabel sx={{
                                color: "black",
                                fontWeight: 500,
                                fontSize: '14px'
                            }}>
                                <Grid display={'flex'} justifyContent={'space-between'}>
                                    <a style={{ marginRight: 20 }}>Due Date</a>
                                    <Grid>
                                        {selectDate.map((date, index) => (
                                            <a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setValue('due_date', date.value)}> {date.name}{index != 5 && ','}</a>
                                        ))}
                                    </Grid>
                                </Grid>
                            </InputLabel>
                            <DateInput control={control} name="due_date"
                                // label="Due Date"
                                value={watch('due_date')} />
                        </Grid>




                        <Grid sx={{ p: 1 }} item xs={12}>
                            <InputLabel sx={{
                                color: "black",
                                fontWeight: 500,
                                fontSize: '14px'
                            }}>
                                <Grid display={'flex'} justifyContent={'space-between'}>
                                    <a style={{ marginRight: 20 }}>Assigned To</a>
                                    <Grid>
                                        {recentAssignedTo.map((emp, index) => (
                                            <a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setValue('assigned_to_id', { id: emp.id, name: emp.name })}> {emp.name}{index != 2 && ','}</a>
                                        ))}
                                    </Grid>
                                </Grid>
                            </InputLabel>

                            <SelectX
                                key={refresh * 0.2}
                                // label={"Assign to"}
                                options={fetchEmployees}
                                control={control}
                                error={errors?.assigned_to_id?.id ? errors?.assigned_to_id?.id?.message : false}
                                error2={errors?.assigned_to_id?.message ? errors?.assigned_to_id?.message : false}
                                name={'assigned_to_id'}
                                defaultValue={watch('assigned_to_id')}
                            />
                        </Grid>

                        <Grid sx={{ p: 1 }} item xs={12}>
                            <InputLabel sx={{
                                color: "black",
                                fontWeight: 500,
                                fontSize: '14px',
                            }}>
                                <Grid display={'flex'} justifyContent={'space-between'}>
                                    <a >Reviewer</a>
                                    <Grid>
                                        {recentReviewer.map((emp, index) => (
                                            <a style={{ color: 'blue', cursor: 'pointer', marginLeft: 'auto' }} onClick={() => setValue('reviewer_id', { id: emp.id, name: emp.name })}> {emp.name}{index != 2 && ','}</a>
                                        ))}
                                    </Grid>
                                </Grid>
                            </InputLabel>
                            <SelectX
                                key={refresh * 0.3}
                                // label={"Reviewer"}
                                options={fetchEmployees}
                                control={control}
                                error={errors?.reviewer_id?.id ? errors?.reviewer_id?.id?.message : false}
                                error2={errors?.reviewer_id?.message ? errors?.reviewer_id?.message : false}
                                name={'reviewer_id'}
                                defaultValue={watch('reviewer_id')}
                            />
                        </Grid>
                        <Grid sx={{ p: 1 }} item xs={12}>
                            <Typography variant={"subtitle2"}>Project priority</Typography>
                            {priority.map(obj => {
                                return <DynamicChip key={obj.id} name={obj.name} id={obj.id} active={selectedPriority} onChipCLick={handlePriorityChange} />
                            })}
                        </Grid>

                        <Grid sx={{ p: 1 }} item xs={12}>

                            <Typography>Description</Typography>
                            <Editor emoji={false} val={watch('description')}
                                onValueChange={e => setValue('description', e)}
                            />
                            {/* <TextField
                                {...register('description')}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={2}
                                sx={{ width: '100%', }}
                                required
                            /> */}


                        </Grid>
                        <Grid sx={{ p: 1, mt: 1 }} mb={5} item xs={12}>
                            <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                        </Grid>

                    </form>

                </DialogContent>
                {/* <DialogActions  >
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        </div>
    );
};

export default Create;
