import React, { useEffect, useState } from 'react';
import Create from './create';
import { Button, Card, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Departments } from "../../../api/Endpoints/Departments";

import BasicTabs from './TabMenus';
import moment from 'moment';

function Reimbursement() {


    const [accounts, setAccounts] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [list, setlist] = useState([])
    const [detailViewId, setDetailViewId] = useState(false)


    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    // const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }

    const fetchTable = () => {

        setLoading(true);
        Departments.get({ page: Number(pageNumber) + 1 }).then(response => {
            console.log(response.data.data);
            setlist(response.data.data);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })


    }

    const handleCreateNew = () => {

    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const viewTabs = () => {
        setTabs(true)
    }

    const DetailViewOpen = (id) => {
        setDetailViewId(id)
    }

    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const columns = [
        {

            field: 'name', headerName: 'Nmae', width: 400,
            renderCell: (params) => <p style={{ cursor: 'pointer' }} onClick={() => DetailViewOpen(params.row.id)}>{params.value}</p>

        },
        //  {
        //     field: 'project', headerName: 'Project name', width: 250,
        //     renderCell: (params) => <p style={{ cursor: 'pointer' }} >{params.value?.name}</p>
        // },
        // {
        //     field: 'created_user', headerName: 'Created User', width: 150,
        //     renderCell: (params) => <p>{params.value?.name}</p>
        // },
        // {
        //     field: 'created_at', headerName: 'Created date', width: 150, renderCell: (params) => {

        //         return moment(params.value).format('DD-MM-YYYY')
        //     }
        // },
        // {
        //     field: 'duration', headerName: 'Duration', width: 150,
        //     renderCell: (params) => <p>{params.row.duration_hours ? params.row.duration_hours + "hrs" : ''} {params.row.duration_minutes ? params.row.duration_minutes + "mins" : ''}</p>
        // },
        // {
        //     field: 'action', headerName: 'Edit', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
        //         let id = params.value?.id;
        //         return <><Button size={'small'}
        //             style={{ color: 'black' }}
        //             onClick={() => handleEdit(id)}>Edit</Button>
        //         </>;
        //     }
        // },
    ]

    useEffect(() => {
        fetchTable();
    }, [searchKeyword, listType, pageNumber])



    return (




        <>

            {Tabs ? (<BasicTabs setTabs={setTabs} />
            ) : (


                <>


                    <PageHeader title={'Departments'} onCreateNew={handleCreateNew} />

                    <Card sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'space-between' }} variant="outlined" >

                        <TextField
                            style={{ width: 300, marginRight: 10 }}
                            size='small'
                            id="outlined-name"
                            label="Search Departments"
                            onChange={handleSearchEntry}
                        />
                        <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />

                    </Card>

                    <Card sx={{ m: 2 }} variant="outlined">
                        {typeof list.data === "object" &&
                            <DataGrid
                                paginationMode="server"
                                rows={list?.data}
                                columns={columns}
                                page={pageNumber}
                                pageSize={10}
                                rowHeight={20}
                                rowsPerPageOptions={[10]}
                                rowCount={list.total}
                                onPageChange={handlePageChange}
                                autoHeight
                                density={"comfortable"}
                            />
                        }
                    </Card>

                </>
            )}

        </>
    )
}



export default Reimbursement


