import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PieChart from '../../form/PieChart';
import { Close } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { Task } from '../../../api/Endpoints/Task';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

const closeButtonStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    padding: '15px',
};

export default function ChartModal(props) {

    const [details, setDetails] = useState()
    const handleOpen = () => {
        props.setOpen(true);
    };

    const handleClose = () => {
        props.setOpen(false);
        props.setID(false);
        setDetails()
    };

    const getDetails = () => {
        Task.getTaskDetails({ id: props.id }).then((response) => {
            setDetails(response.data.data)
        }).catch(errors => {
            console.log(errors);
        })
    }

    useEffect(() => {
        getDetails()
    }, [])


    const test = [
        {
            name: 'one', is_completed: 0
        }, {
            name: 'two', is_completed: 0
        }, {
            name: 'three', is_completed: 0
        }, {
            name: 'four', is_completed: 0
        }, {
            name: 'five', is_completed: 0
        }, {
            name: 'six', is_completed: 1
        }, {
            name: 'seven', is_completed: 0
        }, {
            name: 'eigth', is_completed: 0
        }, {
            name: 'nine', is_completed: 1
        }, {
            name: 'ten', is_completed: 1
        }, {
            name: 'eleven', is_completed: 0
        },
    ]

    return (
        <div>
            <Modal
                open={props.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid display={'flex'} justifyContent={'space-between'}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Checklist Status
                        </Typography>

                        <a style={{ cursor: 'pointer' }} onClick={handleClose}>
                            <Close />
                        </a>
                    </Grid>
                    <Grid mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <PieChart datas={props?.checkLists} />
                    </Grid>
                    <Grid display={'flex'} justifyContent={'end'}>
                        <a onClick={() => props.DetailViewOpen(props.id)} style={{ color: 'blue', textDecoration: 'underLine', cursor: 'pointer', fontSize: '13px' }}>View task</a>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
