import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Card, CircularProgress, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { WorkRequest } from '../../../../../api/Endpoints/WorkRequest';
import EditIcon from '@mui/icons-material/Edit';

import { Autorenew, CancelOutlined, CheckCircleOutline, Edit, PersonAddAlt1 } from '@mui/icons-material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import moment from 'moment';
import View from './View';
import Create from '../../../workRequest/create';
import CostPopup from '../../../common/Popup/CostPopup';
import AcceptPopup from '../../../common/Popup/AcceptPopup';
import AssignToPopup from '../../../common/Popup/AssignTo';
import WRStatus from '../../../common/Popup/WorkRequestStatus';
import ReviewStatus from '../../../common/Popup/ReviewStatus';

const Index = (props) => {



    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }



    const [editId, setEditId] = useState();
    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [viewPage, setViewPage] = useState(false)
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0);
    const [openCostpopup, setOpencosePopup] = useState(false)
    const [loading, setLoading] = useState(false);
    const [editedid, setEditedID] = useState(null)
    const [ID, setID] = useState(0)

    const [openAcceptpopup, setOpenAcceptPopup] = useState(false)
    const [openAssignTopopup, setOpenAssignTopopup] = useState(false)
    const [acceptDetails, setAcceptDetails] = useState()
    const [assignToDetails, setassignToDetails] = useState()
    const [selectedAssignedToID, setSelectedAssignedToID] = useState(null)
    const [selectStatus, setselectStatus] = useState(null)
    const [employees, setEmployees] = useState([])
    const [WRstatusId, setWRstatusId] = useState()
    const [WRstatusDetails, setWRstatusDetails] = useState()
    const [reviewstatusId, setReviewstatusId] = useState()
    const [reviewtatusDetails, setReviewstatusDetails] = useState()

    const [WorkID, setWorkID] = useState(0)
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }
    const viewTabs = (id) => {
        setID(id)
        setViewPage(true)
    }

    const CostPopuphandler = () => {
        setOpencosePopup(true)
    }
    const columns = [
        {
            field: 'name', headerName: 'Title', width: 200, renderCell: (params) => (
                <Button
                    style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'black' }}
                    size="small"
                    // onClick={() => viewTabs(params.id)}
                    
                >
                    {params.row.title}
                </Button>
            ),
        },
        {
            field: 'project', headerName: 'Project', width: 200, renderCell: (params) => <a style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'blue' }}>
                {params.row.project.name}
            </a>
        },
        {
            field: 'status', headerName: 'Status', width: 100, renderCell: (params) => <a style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'blue' }}>
                {params?.row?.status}
            </a>
        },
        {
            field: 'assignedTo', headerName: 'Assigned To', width: 130, renderCell: (params) => <>


                <p style={{ cursor: 'pointer', width: 10, color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'blue' }} size='small' onClick={() => AssignToPopuphandler(params.id, params.row)}>
                    {
                        params.row.assigned_to_user?.name ? params.row.assigned_to_user?.name : <PersonAddAlt1 style={{ marginLeft: 'auto', color: 'blue' }} color='blue' />
                    }
                </p>
            </>
        },
        {
            field: 'requestDate', headerName: 'Request Date', width: 120, renderCell: (params) => <a style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'black' }}>
                {
                    params.row.requested_on ?
                        moment(params.row.requested_on).format('DD-MM-YYYY')
                        : ''
                }</a>
        },
        {
            field: 'dueDate', headerName: 'Due Date', width: 120, renderCell: (params) => <a style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'black' }}>
                {
                    params.row.expected_completion_date ?
                        moment(params.row.expected_completion_date).format('DD-MM-YYYY')
                        : ''

                }</a>
        },
        {
            field: 'Edit',
            headerName: 'Edit',
            width: 80,
            renderCell: (params) => (
                <>
                    <a style={{ cursor: 'pointer' }} onClick={() => handleEdit(params.id)}>
                        <EditIcon fontSize='small' color='primary' />
                    </a>
                </>
            )
        },
        // {
        //     field: 'Cost',
        //     headerName: 'cost',
        //     width: 75,
        //     renderCell: (params) => (
        //         <>
        //             <Button size='small' onClick={() => CostPopuphandler()}>
        //                 <MonetizationOnIcon color='success' sx={{ color: 'black' }} />
        //             </Button>
        //         </>
        //     )
        // },
        {
            field: 'accept',
            headerName: 'Accept',
            width: 100,
            renderCell: (params) => (
                <>
                    {
                        params.row.status === "Rejected" ?
                            <Button size='small' >
                                <CancelOutlined color='error' style={{ color: 'red' }} />
                            </Button> :
                            <Button size='small' onClick={() => AcceptPopuphandler(params.id, params.row)}>
                                <CheckCircleOutline style={{ color: params.row.status === "Accepted" ? 'green' : 'blue' }} />
                            </Button>
                    }
                </>
            )
        },
        {
            field: 'work_status', headerName: 'Work Status', width: 100, renderCell: (params) => <a style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'blue' }}>
                <a style={{ cursor: 'pointer' }} onClick={() => WRstatushandler(params.id, params?.row)}>{params?.row?.work_status ? params?.row?.work_status : <Autorenew />} </a>
            </a>
        },
        {
            field: 'review_status', headerName: 'Review Status', width: 100, renderCell: (params) => <a style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'blue' }}>
                <a style={{ cursor: 'pointer' }} onClick={() => reviewStatusHandler(params.id, params?.row)}>{params?.row?.review_status ? params?.row?.review_status : <Autorenew />} </a>
            </a>
        },


    ];

    const AcceptPopuphandler = (id, detaisl) => {
        // if (!detaisl.accepted_by_user) {
        setOpenAcceptPopup(id)
        setAcceptDetails(detaisl)
        // }
    }
    const AssignToPopuphandler = (id, details) => {
        console.log(details);
        setassignToDetails(details)
        setOpenAssignTopopup(id)
    }

    const WRstatushandler = (id, details) => {
        setWRstatusId(id)
        setWRstatusDetails(details);
    }
    const reviewStatusHandler = (id, details) => {
        setReviewstatusId(id)
        setReviewstatusDetails(details)
    }


    const fetchTable = () => {
        setLoading(true);
        WorkRequest.get({ keyword: searchKeyword, projects_id: props.projectID }).then(response => {
            setList(response.data.data.data);
            props.setCount(response.data.data.total)
            setLoading(false);
        })
    }

    const handleCreateNew = () => {

    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(async () => {
        fetchTable();
    }, [searchKeyword, listType, pageNumber])


    return (
        // <>
        //     {Tabs ? (<BasicTabs setTabs={setTabs} WorkID={WorkID} />
        //     ) : (


        <>
            {openCostpopup && <CostPopup openCostpopup={openCostpopup} setOpencosePopup={setOpencosePopup} />}
            {openAcceptpopup && <AcceptPopup details={acceptDetails} openAcceptpopup={openAcceptpopup} setOpenAcceptPopup={setOpenAcceptPopup} onUpdate={handleUpdate} />}
            {openAssignTopopup && <AssignToPopup details={assignToDetails} openAssignTopopup={openAssignTopopup} setOpenAssignTopopup={setOpenAssignTopopup} onUpdate={handleUpdate} />}
            {WRstatusId && <WRStatus details={WRstatusDetails} openWRstatus={WRstatusId} setOpenWRstatus={setWRstatusId} onUpdate={handleUpdate} />}
            {reviewstatusId && <ReviewStatus     details={reviewtatusDetails} openReviewStatus={reviewstatusId} setOpenReviewStatus={setReviewstatusId} onUpdate={handleUpdate} />}


            {viewPage && <View ID={ID} setViewPage={setViewPage} setID={setID} />}
            <Card sx={{ p: 2, display: "flex", justifyContent: "space-between", alignItems: 'center' }} variant="outlined">
                <TextField
                    size='small'
                    style={{ width: 300, marginRight: 10 }}
                    id="outlined-name"
                    autoFocus
                    label="Search"
                    onChange={handleSearchEntry}
                />
                <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} projectID={props.projectID} />

            </Card >
            {/* {openCostpopup && <CostPopup openCostpopup={openCostpopup} setOpencosePopup={setOpencosePopup} />} */}


            <Card sx={{ m: 2 }} variant="outlined">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {list &&
                    <DataGrid
                        paginationMode="server"
                        rows={list}
                        columns={columns}
                        page={pageNumber}
                        pageSize={10}
                        rowHeight={20}
                        rowsPerPageOptions={[10]}
                        onPageChange={handlePageChange}
                        autoHeight
                        density={"comfortable"}
                    />
                }
            </Card>
            {/* </>
    )
} */}
        </>
    );
};

export default Index;
