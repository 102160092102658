import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PieChart from '../../form/PieChart';
import { Close } from '@mui/icons-material';
import { Grid } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

const closeButtonStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    padding: '15px',
};

export default function ChartModal(props) {
    const handleOpen = () => {
        props.setOpen(true);
    };

    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <div>
            <Modal
                open={props.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid display={'flex'} justifyContent={'space-between'}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Checklist Graph
                        </Typography>
    
                        <a onClick={handleClose}>
                            <Close />
                        </a>
                    </Grid>
                    <Grid mt={2.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <PieChart />
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
