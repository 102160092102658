import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, InputLabel, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import SelectX from '../../../../form/SelectX';
import { useState } from 'react';
import { ErrorMessage } from 'formik';
import TextInput from '../../../../form/TextInput';
import DateInput from '../../../../form/DateInput';
import { Close } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { WebsitePages } from '../../../../../api/Endpoints/WebsitePages';
import { useEffect } from 'react';
import ReactSelector from 'react-select';
import moment from 'moment';
import { Employee } from '../../../../../api/Endpoints/Employee';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    // '#e9faff'
    p: 4,
    borderRadius: 2
};

export default function WebsitePageCreate(props) {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm();

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        props.onNew()
    }
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        props.setEditId()
        reset()
        setOpen(false);
    }

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = React.useState({
        type: '',
        message: '',
        active: false
    })

    const fetchEmployees = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const Status = [
        { name: "Not Started", value: 'Not Started' },
        { name: "In Progress", value: 'In Progress' },
        { name: "Completed", value: 'Completed' }
    ]

    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true,disabled:true });

        let ui_date = ''
        let html_date = ''
        let developer_date = ''
        let data_date = ''
        let testing_date = ''

        if (data.ui_date) {
            ui_date = moment(data.ui_date).format('YYYY-MM-DD')
        } if (data.html_date) {
            html_date = moment(data.html_date).format('YYYY-MM-DD')
        } if (data.developer_date) {
            developer_date = moment(data.developer_date).format('YYYY-MM-DD')
        } if (data.data_entry_date) {
            data_date = moment(data.data_entry_date).format('YYYY-MM-DD')
        } if (data.tester_date) {
            testing_date = moment(data.tester_date).format('YYYY-MM-DD')
        }

        let dataToSubmit = {
            id: props.editId,
            page_name: data.page_name,
            page_url: data.page_url,
            projects_id: props.projectID,

            ui_design_by: data.ui_developer?.id,
            ui_design_status: data.ui_status,
            ui_design_date: ui_date,

            ui_development_by: data.html_developer?.id,
            ui_development_status: data.html_status,
            ui_development_date: html_date,

            integration_by: data.devloper?.id,
            integration_status: data.developer_status,
            integration_date: developer_date,

            data_entry_by: data.data_entry?.id,
            data_entry_status: data.data_entry_status,
            data_entry_on: data_date,

            test_by: data.tester?.id,
            test_date: testing_date,
            testing_status: data.testing_status,

        };

        console.log("dataToSubmit", dataToSubmit);
        let action;
        if (props.editId > 0) {
            action = WebsitePages.update(dataToSubmit);
        } else {
            action = WebsitePages.add(dataToSubmit);
        }

        action.then(response => {
            // console.log(response);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
    
            if (response?.data?.status === 'success') {
                // Reset the form only if the status is success
                reset();
                props.fetchTable();
                props.setEditId();
                handleClose();
                setOpen(false);
            }
    
            toast.success(response.data.message);
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" });
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        });
    }

    const getDetails = async () => {
        let response = await WebsitePages.getDetails({ id: props.editId });
        console.log(response);
        if (response.status === 200) {
            let data = response?.data?.data;
            console.log(data);

            setValue('page_name', data?.page_name);
            setValue('page_url', data.page_url);

            setValue('ui_developer', data?.ui_designed_by_user);
            setValue('ui_status', data.ui_design_status);
            setValue('ui_date', data?.ui_design_date);

            setValue('html_developer', data?.ui_development_by_user);
            setValue('html_status', data.ui_development_status);
            setValue('html_date', data?.ui_development_date);

            setValue('devloper', data?.integration_by_user);
            setValue('developer_status', data.integration_status);
            setValue('developer_date', data?.integration_date);

            setValue('data_entry', data?.data_entry_by_user);
            setValue('data_entry_status', data.data_entry_status);
            setValue('data_entry_date', data?.data_entry_on);

            setValue('tester', data?.test_by_user);
            setValue('testing_status', data.testing_status);
            setValue('tester_date', data?.test_date);

        }
    }

    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            getDetails();
            // fetchUser()
            setOpen(true);
        } else if (parseInt(props.editId) === 0) {
            setOpen(true);
            // fetchUser()
        }

    }, [props.editId])

    return (
        <div>
            <Button variant='contained' onClick={handleClickOpen}>Add Page</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid display={'flex'} justifyContent={'space-between'}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {props.editId > 0 ? "Edit Page" : 'Add Page'}
                        </Typography>
                        <a style={{ cursor: 'pointer' }} onClick={handleClose}><Close /></a>
                    </Grid>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        {/* <ErrorMessage
                            errors={errors}
                            name="multipleErrorInput"
                            render={({ messages }) =>
                                messages &&
                                Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                ))
                            }
                        /> */}

                        <Grid container mt={5} >
                            <Grid item md={5}>
                                <InputLabel sx={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    mb: 0.5
                                }}>
                                    Page Name
                                </InputLabel>
                                <Grid >
                                    <TextInput control={control} name="page_name"
                                        value={watch('page_name')} />
                                </Grid>
                            </Grid>

                            <Grid ml={1} item md={6}>
                                <InputLabel sx={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    mb: 0.5
                                }}>
                                    Page URL
                                </InputLabel>
                                <Grid  >
                                    <TextInput control={control} name="page_url"
                                        value={watch('page_url')} />
                                </Grid>
                            </Grid>


                        </Grid>

                        {/* UI */}
                        <Grid mt={2} display={'flex'} width={'100%'} alignItems={'end'}>
                            <Grid item width={300}>
                                <InputLabel sx={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontSize: '14px'
                                }}>
                                    UI Designer
                                </InputLabel>
                                <SelectX
                                    // label={"Choose project"}
                                    options={fetchEmployees}
                                    control={control}
                                    error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                                    error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                                    name={'ui_developer'}
                                    defaultValue={watch('ui_developer')}
                                />
                            </Grid>
                            <Grid ml={3} width={200}>
                                <ReactSelector
                                    placeholder="Status"
                                    // onInputChange={Status}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={Status}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.value}
                                    value={
                                        Status.find(options =>
                                            options.value === watch('ui_status')
                                        )
                                    }
                                    name='ui_status'
                                    isClearable
                                    defaultValue={(watch('ui_status'))}
                                    onChange={(selectedOption) => setValue('ui_status', selectedOption?.value)}
                                />
                            </Grid>
                            <Grid ml={3} width={200}>
                                <DateInput
                                    placeholder={'date'}
                                    control={control}
                                    name="ui_date"
                                    // label="From"
                                    value={watch('ui_date')}
                                />

                            </Grid>


                        </Grid>


                        {/* HTML */}
                        <Grid mt={2} display={'flex'} width={'100%'} alignItems={'end'}>
                            <Grid item width={300}>
                                <InputLabel sx={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontSize: '14px'
                                }}>
                                    HTML Development
                                </InputLabel>
                                <SelectX
                                    // label={"Choose project"}
                                    options={fetchEmployees}
                                    control={control}
                                    error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                                    error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                                    name={'html_developer'}
                                    defaultValue={watch('html_developer')}
                                />
                            </Grid>
                            <Grid ml={3} width={200}>
                                <ReactSelector
                                    placeholder="Status"
                                    // onInputChange={Status}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={Status}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.value}
                                    value={
                                        Status.find(options =>
                                            options.value === watch('html_status')
                                        )
                                    }
                                    name='html_status'
                                    isClearable
                                    defaultValue={(watch('html_status'))}
                                    onChange={(selectedOption) => setValue('html_status', selectedOption?.value)} />
                            </Grid>
                            <Grid ml={3} width={200}>
                                <DateInput
                                    placeholder={'date'}
                                    control={control}
                                    name="html_date"
                                    // label="From"
                                    value={watch('html_date')}
                                />

                            </Grid>


                        </Grid>

                        {/* Development */}
                        <Grid mt={2} display={'flex'} width={'100%'} alignItems={'end'}>
                            <Grid item width={300}>
                                <InputLabel sx={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontSize: '14px'
                                }}>
                                    Development
                                </InputLabel>
                                <SelectX
                                    // label={"Choose project"}
                                    options={fetchEmployees}
                                    control={control}
                                    error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                                    error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                                    name={'devloper'}
                                    defaultValue={watch('devloper')}
                                />
                            </Grid>
                            <Grid ml={3} width={200}>
                                <ReactSelector
                                    placeholder="Status"
                                    // onInputChange={Status}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={Status}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.value}
                                    value={
                                        Status.find(options =>
                                            options.value === watch('developer_status')
                                        )
                                    }
                                    name='developer_status'
                                    isClearable
                                    defaultValue={(watch('developer_status'))}
                                    onChange={(selectedOption) => setValue('developer_status', selectedOption?.value)} />

                            </Grid>
                            <Grid ml={3} width={200}>
                                <DateInput
                                    placeholder={'date'}
                                    control={control}
                                    name="developer_date"
                                    // label="From"
                                    value={watch('developer_date')}
                                />

                            </Grid>


                        </Grid>

                        {/* Data Entry */}
                        <Grid mt={2} display={'flex'} width={'100%'} alignItems={'end'}>
                            <Grid item width={300}>
                                <InputLabel sx={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontSize: '14px'
                                }}>
                                    Data Entry
                                </InputLabel>
                                <SelectX
                                    // label={"Choose project"}
                                    options={fetchEmployees}
                                    control={control}
                                    error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                                    error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                                    name={'data_entry'}
                                    defaultValue={watch('data_entry')}
                                    menuPlacement="top"
                                />
                            </Grid>
                            <Grid ml={3} width={200}>
                                <ReactSelector
                                    placeholder="Status"
                                    // onInputChange={Status}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={Status}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.value}
                                    value={
                                        Status.find(options =>
                                            options.value === watch('data_entry_status')
                                        )
                                    }
                                    name='data_entry_status'
                                    isClearable
                                    defaultValue={(watch('data_entry_status'))}
                                    onChange={(selectedOption) => setValue('data_entry_status', selectedOption?.value)} />

                            </Grid>
                            <Grid ml={3} width={200}>
                                <DateInput
                                    placeholder={'date'}
                                    control={control}
                                    name="data_entry_date"
                                    // label="From"
                                    value={watch('data_entry_date')}
                                />

                            </Grid>


                        </Grid>

                        {/* Testing */}
                        <Grid mt={2} display={'flex'} width={'100%'} alignItems={'end'}>
                            <Grid item width={300}>
                                <InputLabel sx={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontSize: '14px'
                                }}>
                                    Testing
                                </InputLabel>
                                <SelectX
                                    // label={"Choose project"}
                                    options={fetchEmployees}
                                    control={control}
                                    error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                                    error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                                    name={'tester'}
                                    defaultValue={watch('tester')}
                                    menuPlacement="top"
                                />
                            </Grid>
                            <Grid ml={3} width={200}>
                                <ReactSelector
                                    placeholder="Status"
                                    // onInputChange={Status}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={Status}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.value}
                                    value={
                                        Status.find(options =>
                                            options.value === watch('testing_status')
                                        )
                                    }
                                    name='testing_status'
                                    isClearable
                                    menuPlacement="top"
                                    defaultValue={(watch('testing_status'))}
                                    onChange={(selectedOption) => setValue('testing_status', selectedOption?.value)} />

                            </Grid>
                            <Grid ml={3} width={200}>
                                <DateInput
                                    placeholder={'date'}
                                    control={control}
                                    name="tester_date"
                                    // label="From"
                                    value={watch('tester_date')}
                                />

                            </Grid>


                        </Grid>

                        <Grid textAlign={'end'} sx={{ mt: 2 }} item xs={12}>
                            <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                        </Grid>

                    </form>

                </Box>
            </Modal>
        </div>
    );
}