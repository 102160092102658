import { Backdrop, Button, Card, CircularProgress, Grid, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Project } from "../../../../api/Endpoints/Project";
import { useState, useEffect } from "react";




const rows = [
    {
        id: 1,
        project_name: 'Desai homes',
        created_date: "10/4/2023",

    },

    // Add more rows as needed...
];
const columns = [
    { field: 'id', headerName: 'ID', width: 300 },
    {
        field: 'name', headerName: 'Project Name', width: 300
    },
    {
        field: 'created_at',
        headerName: 'Created Date',
        width: 250,
        valueGetter: (params) => {
            const dateString = params.row.created_at;
            const date = new Date(dateString);
            return date.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
            });
        }
    },


    // Add more columns as needed...                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                q

];

function Projects({ ClientID }) {


    const [Loading, setLoading] = useState(false)
    const [Data, setData] = useState([])



    const fetchDetails = async () => {
        try {
            setLoading(true)
            const response = await Project.get({ accounts_id: ClientID });
            setData(response.data.data.data)
            setLoading(false)
        } catch (error) {
            console.error(error);

        }
    }

    console.log("this client projects", Data);
    useEffect(() => {
        fetchDetails()
    }, [])
    return (
        <div>
            {Loading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={Loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    <Card sx={{ m: 2 }} variant="outlined">

                        <DataGrid
                            paginationMode="server"
                            rows={Data}
                            columns={columns}
                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            autoHeight
                            density={'comfortable'}
                        />

                    </Card>
                </>
            )
            }
        </div>
    )
}

export default Projects
