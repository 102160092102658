
import React, { createContext, useState, useContext, useEffect } from 'react';


const StateContext = createContext();


export const MeetingIdContext = () => useContext(StateContext);


export const MeetingeDProvider = ({ children }) => {


    const [MeetingID, setMeetingID] = useState(null);



    useEffect(() => {
        setMeetingID(MeetingID)
    }, [MeetingID]);



    return (
        <StateContext.Provider value={{ MeetingID, setMeetingID }}>
            {children}
        </StateContext.Provider>
    );
};
