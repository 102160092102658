import React, { useEffect, useState } from "react";
import { useAppSettings } from "../../../hooks/use-app-settings";
import moment from "moment";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Activities } from "../../../api/Endpoints/Activities";
import Create from "./create";
import DetailView from "./DetailView";
import ReactSelector from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Employee } from "../../../api/Endpoints/Employee";
import { Project } from "../../../api/Endpoints/Project";
import EditIcon from "@mui/icons-material/Edit";
import { Edit } from "@mui/icons-material";
import { WorkPlan } from "../../../api/Endpoints/WorkPlan";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DateInput from "../../form/DateInput";
import { Departments } from "../../../api/Endpoints/Departments";
import { UserIdContext } from "../../../contexts/UserID/UserID";
import CurrentDateInput from "../../form/CurrenDateInput";

const scheme = yup.object().shape({});

const WorkPlanIndex = (props) => {
  const { employeeID } = props;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm({ resolver: yupResolver(scheme) });
  const [editId, setEditId] = useState();
  const [refresh, setRefresh] = useState(0);
  const [listByProject, setListByProject] = useState(false);
  const [detailViewId, setDetailViewId] = useState(false);
  const [projects, setProjects] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedProjectID, setSelectedProjectID] = useState(null);

  const { userDetail } = UserIdContext();

  const DetailViewOpen = (id) => {
    setDetailViewId(id);
  };
  const handleEdit = (id) => {
    setRefresh(Math.random);
    setEditId(parseInt(id));
  };

  const columns = [
    {
      field: "title",
      headerName: "title",
      width: 400,
      renderCell: (params) => (
        <p
          style={{ cursor: "pointer" }}
          onClick={() => DetailViewOpen(params.row.id)}
        >
          {params.value}
        </p>
      ),
    },
    {
      field: "project",
      headerName: "Project name",
      width: 200,
      renderCell: (params) => (
        <p style={{ cursor: "pointer" }}>{params.value?.name}</p>
      ),
    },
    {
      field: "created_user",
      headerName: "User Name",
      width: 150,
      renderCell: (params) => <p>{params.value?.name}</p>,
    },
    {
      field: "created_at",
      headerName: "Created date",
      width: 150,
      renderCell: (params) => {
        // if (params) {

        return moment(params.value).format("DD-MM-YYYY");
        // } else {
        //     return "Not started";
        // }
      },
    },
    // {
    //     field: 'duration', headerName: 'Duration', width: 150,
    //     renderCell: (params) => <p>{params.row.duration_hours ? params.row.duration_hours == 1 ? params.row.duration_hours + "hr" : params.row.duration_hours + 'hrs' : ''} {params.row.duration_minutes ? params.row.duration_minutes + "mins" : ''}</p>
    // },
    {
      field: "action",
      headerName: "Edit",
      width: 50,
      valueGetter: (params) => params.row,
      renderCell: (params) => {
        let id = params.value?.id;
        return (
          <>
            <a style={{ cursor: "pointer" }} onClick={() => handleEdit(id)}>
              <Edit color="primary" fontSize="small" />
            </a>
          </>
        );
      },
    },
  ];

  const [list, setList] = useState([]);
  const [listType, setListType] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
  //const [selectedUserID, setSelectedUserID] = useState(null);
  const [selectedUserID, setSelectedUserID] = useState(employeeID || null);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setselectedDepartments] = useState(null);

  const handleListingSearchChange = (keyword) => {
    setSearchKeyword(keyword);
    setPageNumber(0);
  };
  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handleLeadClick = (leadId) => {
    props.onPreQualifierIdChange(leadId);
    setSelectedPreQualifierId(leadId);
  };

  const fetchTable = () => {
    setLoading(true);
    // console.log(searchKeyword);
    WorkPlan.get({
      date: date,
      projects_id: selectedProjectID,
      users_id: selectedUserID,
      department: selectedDepartments,
      keyword: searchKeyword,
      page: parseInt(pageNumber) + 1,
      limit: 50,
    }).then((response) => {
      // console.log(response.data.data);
      setListByProject(false);
      setList(response.data.data);
      setLoading(false);
    });
  };

  // const fetchByProject = (project_id) => {
  //     if (!listByProject) {
  //         setLoading(true);
  //         Activities.get({ projects_id: project_id, keyword: searchKeyword, page: parseInt(pageNumber) + 1 }).then(response => {
  //             console.log(response.data.data);
  //             setList(response.data.data);
  //             setListByProject(true)
  //             setLoading(false);
  //         })
  //     } else {
  //         fetchTable()
  //     }
  // }

  const fetchProjects = (e) => {
    Project.get({ keyword: e }).then((response) => {
      //console.log(response);
      if (typeof response.data.data.data !== "undefined") {
        setProjects(response.data.data.data);
      } else {
        return [];
      }
    });
  };

  const fetchEmployees = (e) => {
    //console.log(e);
    Employee.get({ keyword: e }).then((response) => {
      // console.log(response.data.data.data);
      if (typeof response.data.data.data !== "undefined") {
        setEmployees(response.data.data.data);
      } else {
        return [];
      }
    });
  };

  const fetchDepartments = (e) => {
    Departments.get({ keyword: e, limit: 100 }).then((response) => {
      // console.log(response.data.data.data);
      if (typeof response.data.data.data !== "undefined") {
        setDepartments(response.data.data.data);
      } else {
        return [];
      }
    });
  };

  const selectProject = (project_id) => {
    //console.log(project_id);
    setSelectedProjectID(project_id?.id);
    setValue("projects", project_id?.name || "");
  };

  const selectByUser = (user) => {
    //console.log(user);
    setSelectedUserID(user?.id);
    setValue("user", user?.name || "");
  };

  const selectDepartments = (data) => {
    setselectedDepartments(data?.id);
    setValue("departments", data?.name || "");
  };

  const handleUpdate = () => {
    setPageNumber();
    setPageNumber(0);
  };

  const handleCreate = () => {
    setEditId(0);
    setRefresh(Math.random);
  };

  const handleSearchEntry = (event) => {
    setSearchKeyword(event.target.value);
  };

  //this useEffect is for the WorkPlan tab in Employees to show work plan of the selected employee
  useEffect(() => {
    if (employeeID) {
      setSelectedUserID(employeeID);
    }
  }, [employeeID]);

  useEffect(() => {
    if (watch("date")) {
      setDate(moment(watch("date")).format("DD-MM-YYYY"));
    } else {
      setDate("");
    }
  }, [watch("date")]);

  useEffect(() => {
    fetchTable();
    fetchProjects();
    fetchEmployees();
    fetchDepartments();
  }, [
    searchKeyword,
    listType,
    pageNumber,
    selectedProjectID,
    selectedUserID,
    date,
    selectedDepartments,
  ]);

  return (
    <>
      {detailViewId && (
        <DetailView id={detailViewId} handleClose={setDetailViewId} />
      )}
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mr={3}
      >
        <PageHeader title={"Work Plans "} />
        {employeeID && (
          <>
            <Grid
              container
              alignItems="center"
              spacing={1}
              sx={{ maxWidth: 1000 }}
            >
              <Grid item sx={{ width: 165, ml: "auto" }}>
                <DateInput
                  placeholder="Current date"
                  control={control}
                  name="date"
                  value={moment()}
                />
              </Grid>
              {watch("date") && (
                <Grid item>
                  <Button
                    onClick={() => {
                      setDate("");
                      setValue("date", "");
                    }}
                  >
                    Clear Date
                  </Button>
                </Grid>
              )}
              <Grid item sx={{ ml: 10 }}>
                <TextField
                  style={{ width: 300 }}
                  size="small"
                  id="outlined-name"
                  label="Search"
                  onChange={handleSearchEntry}
                />
              </Grid>
            </Grid>
          </>
        )}
        {!employeeID && (
          <Grid display={"flex"}>
            <TextField
              style={{ width: 300, marginRight: 10 }}
              size="small"
              id="outlined-name"
              label="Search"
              onChange={handleSearchEntry}
            />
            <Create
              key={refresh}
              onNew={handleCreate}
              onUpdate={handleUpdate}
              editId={editId}
              setEditId={setEditId}
            />
          </Grid>
        )}
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!employeeID && (
        <Grid
          sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
          variant="outlined"
        >
          <Grid item display={"flex"} sx={{ ml: "auto" }}>
            <Grid sx={{ width: 200 }} mr={3}>
              <ReactSelector
                placeholder="Project"
                onInputChange={fetchProjects}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                options={projects}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={projects.find(
                  (options) => options.name === watch("projects")
                )}
                name="projects"
                isClearable
                defaultValue={watch("projects")}
                onChange={(selectedOption) => selectProject(selectedOption)}
              />
            </Grid>

            <Grid sx={{ width: 200 }} mr={3}>
              <ReactSelector
                placeholder="User Name"
                onInputChange={fetchEmployees}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                options={employees}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                // inputValue={inProject}
                value={employees.find(
                  (options) => options.name === watch("user")
                )}
                name="user"
                isClearable
                defaultValue={watch("user")}
                onChange={(selectedOption) => selectByUser(selectedOption)}
              />
            </Grid>

            <Grid sx={{ width: 200 }} mr={3}>
              <ReactSelector
                placeholder="Department"
                onInputChange={fetchDepartments}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                options={departments}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={
                  selectedDepartments !== null
                    ? departments.find(
                        (option) => option.id === selectedDepartments
                      )
                    : departments.find(
                        (option) =>
                          option.id ===
                          userDetail?.data?.employee?.departments_id
                      )
                }
                //   defaultValue={departments.find(
                //     (option) =>
                //       option.id === userDetail?.data?.employee?.departments_id
                //   )}
                name="departments"
                isClearable
                onChange={(selectedOption) => selectDepartments(selectedOption)}
              />
            </Grid>

            <Grid mr={0.5} sx={{ width: 200 }}>
              <DateInput
                placeholder="Current date"
                control={control}
                name="date"
                //   label="Current Date"
                value={moment()}
              />
            </Grid>
            {watch("date") && (
              <Button
                onClick={() => {
                  setDate("");
                  setValue("date", "");
                }}
              >
                Clear Date
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      <Card sx={{ m: 2, height: 600 }} variant="outlined">
        {typeof list?.data === "object" && (
          <DataGrid
            paginationMode="server"
            rows={list?.data}
            columns={columns}
            page={pageNumber}
            pageSize={50}
            rowHeight={20}
            rowsPerPageOptions={[50]}
            rowCount={list.total}
            getRowId={(row) => row?.id}
            onPageChange={handlePageChange}
            // autoHeight
            density="comfortable"
          />
        )}
      </Card>
    </>
  );
};

export default WorkPlanIndex;
