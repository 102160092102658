import React, {useEffect, useState} from 'react';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {Task} from "../../../api/Endpoints/Task";
import {Box, Button, Grid} from "@mui/material";
import moment from "moment";
import toast from "react-hot-toast";


const Index = () => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.1 },
        { field: 'project', headerName: 'Project name', flex: 0.2, renderCell: (params) => params.value?.name },
        { field: 'title', headerName: 'title', flex: 0.1 },
        {
            field: 'assigned_to', headerName: 'Assigned to', flex: 0.1,
            valueGetter: (params) => params.row?.assigned_to_user,
            renderCell: (params) => <Button>{params.value?.name}</Button>,
        },
        {
            field: 'assigned_by', headerName: 'Assigned by', flex: 0.1,
            valueGetter: (params) => params.row?.assigned_by_user,
            renderCell: (params) => <Button>{params.value?.name}</Button>,
        },
        {
            field: 'reviewer', headerName: 'Reviewer', flex: 0.1,
            valueGetter: (params) => params.row?.reviewer,
            renderCell: (params) => <Button>{params.value?.name}</Button>,
        },
        {
            field: 'start_date', headerName: 'Start date', flex: 0.1, renderCell: (params) => {
                if (params.value) {
                    return moment(params.value).format('MMM Mo dddd')
                } else {
                    return "Not started";
                }
            }
        },
        { field: 'created_at', headerName: 'Created on', flex: 0.1, renderCell: (params) => { return moment(params.value).format('DD MMM yyyy') } },
        { field: 'status', headerName: 'Status', flex: 0.1, renderCell: (params) => <>{params.status}</> },
        {
            field: 'action', headerName: 'Edit', flex: 0.1, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><Button size={'small'}
                                 onClick={() => handleEdit(id)}>Edit</Button>
                </>;
            }
        },
        {
            field: 'delete', headerName: 'Delete', flex: 0.1, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><Button size={'small'}
                                 onClick={() => handleDelete(id)}>Delete</Button>
                </>;
            }
        },
    ]

    const fetchTable = () => {
        setLoading(true);
        Task.get().then(response => {
            setList(response.data?.data?.data);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })
    }

    const orderChange = (e) => {
        console.log("object", e)
    }

    const handleEdit = (id) => {toast(id);}
    const handleDelete = (id) => {toast(id);}

    useEffect(()=>{
        fetchTable();
    },[])

    return (
        <div>
            <Grid container sx={{p:10}}>

            {list &&
                <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    autoHeight={true}
                    rows={list}
                    columns={columns}
                    density={"comfortable"}
                    paginationMode="server"
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    pageSizeOptions={[20]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
                </Box>
            }
            </Grid>

        </div>
    );
};

export default Index;
