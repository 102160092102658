import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Snackbar,
  Alert,
  Checkbox,
  FormGroup,
} from "@mui/material";

const checklistData = [
  {
    title: "Website Go Live checklist",
    createdBy: "Admin",
    assignedTo: "John Doe",
    questions: [
      { id: 1, text: "Is the website fully functional?" },
      { id: 2, text: "Have all features been tested?" },
      { id: 3, text: "Is the content up-to-date?" },
    ],
  },
  {
    title: "New Employee joining checklist",
    createdBy: "HR",
    assignedTo: "Jane Smith",
    questions: [
      {
        id: 4,
        text: "Has the new employee completed all required onboarding paperwork?",
      },
      {
        id: 5,
        text: "Has the employee attended all scheduled training sessions?",
      },
      {
        id: 6,
        text: "Has the employee reviewed and acknowledged the company policies?",
      },
    ],
  },
  // Add more checklists as needed
];

const Checklist = () => {
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [answers, setAnswers] = useState({});
  const [showToast, setShowToast] = useState(false);

  const handleRowSelect = (item) => {
    setSelectedChecklist(item);
  };

  const handleStart = () => {
    if (selectedChecklist) {
      setModalOpen(true);
      setCurrentQuestionIndex(0);
      setStatus("");
      setRemarks("");
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  const handleNext = () => {
    if (selectedChecklist) {
      const currentQuestionId =
        selectedChecklist.questions[currentQuestionIndex].id;
      setAnswers({
        ...answers,
        [currentQuestionId]: {
          status,
          remarks,
        },
      });
      if (currentQuestionIndex < selectedChecklist.questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setStatus("");
        setRemarks("");
      }
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      const currentQuestionId =
        selectedChecklist.questions[currentQuestionIndex].id;
      setAnswers({
        ...answers,
        [currentQuestionId]: {
          status,
          remarks,
        },
      });
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      const previousQuestionId =
        selectedChecklist.questions[currentQuestionIndex - 1].id;
      const previousAnswer = answers[previousQuestionId] || {
        status: "",
        remarks: "",
      };
      setStatus(previousAnswer.status);
      setRemarks(previousAnswer.remarks);
    }
  };

  const handleSubmit = () => {
    if (selectedChecklist) {
      const currentQuestionId =
        selectedChecklist.questions[currentQuestionIndex].id;
      setAnswers({
        ...answers,
        [currentQuestionId]: {
          status,
          remarks,
        },
      });
    }

    console.log("Submitted answers:", answers); // Log all answers

    setShowToast(true);
    setModalOpen(false);
    setSelectedChecklist(null);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Checklist
          </Typography>
        </CardContent>
      </Card>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>Index</TableCell>
                <TableCell style={{ textAlign: "center" }}>Title</TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  Created By
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checklistData.map((item, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowSelect(item)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell style={{ textAlign: "center" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    {item.title}
                  </TableCell>
                  <TableCell style={{ fontSize: "14px", textAlign: "center" }}>
                    {item.createdBy}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {selectedChecklist === item && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleStart}
                      >
                        Start
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            width: 500,
            padding: 3,
            backgroundColor: "white",
            margin: "auto",
            marginTop: "10%",
            borderRadius: 1,
          }}
        >
          {selectedChecklist && selectedChecklist.questions.length > 0 ? (
            <Box>
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                gutterBottom
              >
                {selectedChecklist.questions[currentQuestionIndex]?.text ||
                  "No questions available"}
              </Typography>
              <FormControl component="fieldset">
                <FormLabel component="legend">Status</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={status === "Yes"}
                        onChange={() => setStatus("Yes")}
                        color="primary"
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={status === "No"}
                        onChange={() => setStatus("No")}
                        color="primary"
                      />
                    }
                    label="No"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={status === "N/A"}
                        onChange={() => setStatus("N/A")}
                        color="primary"
                      />
                    }
                    label="N/A"
                  />
                </FormGroup>
              </FormControl>
              <TextField
                label="Remarks"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={remarks}
                onChange={handleRemarksChange}
                margin="normal"
              />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {currentQuestionIndex > 0 && (
                  <Button
                    variant="outlined"
                    onClick={handleBack}
                    sx={{ marginRight: 1 }}
                  >
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                    currentQuestionIndex <
                    selectedChecklist.questions.length - 1
                      ? handleNext
                      : handleSubmit
                  }
                  disabled={!status}
                >
                  {currentQuestionIndex < selectedChecklist.questions.length - 1
                    ? "Next"
                    : "Submit"}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                gutterBottom
              >
                All questions answered!
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Finish
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
      <Snackbar
        open={showToast}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          Answers submitted!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Checklist;
