import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Card, CircularProgress, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import LinkIcon from '@mui/icons-material/Link';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import { Backlinks } from '../../../api/Endpoints/Backlinks';
import CreateLinks from './CreateLinks';
import Link from './page/Link';
import Delete from '../common/Popup/Delete';
import PageHeader from '../common/page-header';

const BackLinks = (props) => {

    const { id } = useParams();

    const columns = [
        {
            field: 'article_link', headerName: 'Article Link', width: 250, renderCell: (params) => (
                <p
                    size="small"
                    onClick={() => viewTabs(params.id)}
                >
                    {params.row.article_link}

                </p>
            ),

        },
        { field: 'payment', headerName: 'Payment', width: 150, renderCell: (params) => <>{params.row.payment}</> },
        { field: 'currency', headerName: 'Currency', width: 150, renderCell: (params) => <>{params.row.currency}</> },
        {
            field: 'linkss',
            headerName: 'Links',
            width: 150,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => ViewLinksPage(params.id)}>
                        <LinkIcon style={{ height: 19 }} />
                    </IconButton>

                </>
            ),
        },




        {
            field: 'Edit',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <>

                    <Button size="small" onClick={() => handleEdit(params.row.id)}>
                        <EditIcon style={{ color: 'blue', height: 19 }} />
                    </Button>
                    <Button size='small' onClick={() => handleDelete(params.id)}>
                        <DeleteIcon style={{ color: 'red', height: 19 }} />
                    </Button>

                </>
            ),
        },

    ];


    const [editId, setEditId] = useState();
    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0);
    const handleEdit = (id) => { setRefresh(Math.random); setEditId(parseInt(id)); }
    const [loading, setLoading] = useState(false);
    const [Tabs, setTabs] = useState(false)
    const [SiteID, setSiteID] = useState(0)
    const [Linklist, setLinkList] = useState()

    const [LinksView, setLinksView] = useState(false)
    const [ID, setID] = useState(0)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }
    const ViewLinksPage = () => {
        // props.setShowTabs(true)
        setLinksView(true)
    }
    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }
    const handleDelete = (id) => {
        setID(id)
        setDeletePopup(true)
    }

    const fetchTable = () => {
        setLoading(true);
        Backlinks.ListArticles({backlink_sites_id:10}).then(response => {
            console.log(response?.data);
            setList(response.data.data.data);
            setLoading(false);
        })
    }


    const handleCreateNew = () => {

    }

    const viewTabs = (id) => {
        setSiteID(id)
        setTabs(true)
    }
    useEffect(async () => {
        fetchTable();
    }, [searchKeyword, listType, pageNumber])




    return (
        <>
            <PageHeader title={"Backlinks"} />
            <Grid display={'flex'} justifyContent={'end'} mr={2}>
                <CreateLinks key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} />
            </Grid>

            {LinksView && <Link Linklist={Linklist} setLinkList={setLinkList} setLinksView={setLinksView} />}


            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                Callfunc={() => fetchTable()}
                url="/backlink-sites/articles/delete?id="
                title="BackLink" />}

            <Card sx={{ m: 2 }} variant="outlined">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {list &&
                    <DataGrid
                        paginationMode="server"
                        rows={list}
                        columns={columns}
                        page={pageNumber}
                        pageSize={10}
                        rowHeight={50}
                        rowsPerPageOptions={[10]}
                        onPageChange={handlePageChange}
                        autoHeight
                        density={"comfortable"}
                    />
                }
            </Card>
        </>
    )

}



export default BackLinks;

