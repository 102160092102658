import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Employee } from '../../../../api/Endpoints/Employee';
import SelectX from '../../../form/SelectX';
import ReactSelector from 'react-select';

import {
    Button,
    Card,
    Box,
    Modal,
    Typography,
    FormControl,
    InputLabel,
    Table,
    TableHead,
    TableBody,
    Grid,
    TableRow,
    TableCell,
    TableContainer,
    Paper
} from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function Members() {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({})
    const [open, setOpen] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEmployeeChange = (event) => {
        setSelectedEmployee(event.target.value);
    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };
    const onSubmit = () => {

    }


    const fetchEmployees = (e) => {
        let project = watch('projects_id');
        return Employee.get({ keyword: e, projects_id: project?.id }).then(response => {
            console.log("this is projec name resonse", response.data.data.data);
            if (typeof response.data.data.data !== "undefined") {
                console.log(response.data.data.data);
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }
    const
        Role_Dropdown = [
            { value: 'Guest', label: 'Guest' },
            { value: 'Client', label: 'Client' },
            { value: 'Agency', label: 'Agency' },
            { value: 'Employer', label: 'Employer' },
            { value: 'Manager', label: 'Manager' },
            { value: 'Finance Manager', label: 'Finance Manager' },

        ];
    return (
        <div>
            <div className='flex' style={{ width: '100', display: 'flex', justifyContent: "end", marginRight: 10 }}>
                <Button variant="contained" sx={{ width: "180px" }} onClick={handleOpen}>
                    Add Member
                </Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ textAlign: "center", pb: 3 }}>
                            Add Member
                        </Typography>
                        <form onSubmit={handleSubmit(onsubmit)}>
                            <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>

                                <Grid item xs={12} >
                                    <SelectX
                                        label="Choose Employee"
                                        options={fetchEmployees}
                                        control={control}
                                        error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                        error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                        name="employees"
                                        defaultValue={watch('employee+id')}
                                    />
                                </Grid>
                            </FormControl>


                            <Grid container sx={{ mt: 2 }} alignItems="center">
                                <Grid item xs={12}>
                                    <InputLabel sx={{
                                        color: "black",
                                        pb: 1,
                                    }}>Select Role
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} >
                                    <ReactSelector
                                        options={Role_Dropdown}
                                        value={Role_Dropdown.filter(
                                            (Role_Dropdown) => Role_Dropdown.label === watch('role_dropdown')
                                        )}
                                        name='role_dropdown'
                                        defaultValue={watch('role_dropdown') ?? ''}
                                        isClearable={true}
                                        onChange={(selectedOption) => setValue('role_dropdown', selectedOption?.value)}
                                    />

                                </Grid>
                            </Grid>
                            <div style={{ display: "flex", justifyContent: "end", paddingTop: 10 }}>
                                <Button
                                    onClick={() => setOpen(false)}
                                    variant="contained"
                                    color='error'
                                    type="submit"
                                    sx={{ mt: 2, mr: 2 }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{ mt: 2 }}
                                >
                                    Submit
                                </Button>

                            </div>
                        </form>
                    </Box>
                </Modal>
            </div>
            <Card sx={{ mt: 5 }} variant="outlined">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>NO</TableCell>
                                <TableCell>Employee</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>1</TableCell>
                                <TableCell>Basil</TableCell>
                                <TableCell>Employer</TableCell>
                                <TableCell sx={{ color: "blue", cursor: "pointer" }}>
                                    Edit
                                </TableCell>
                            </TableRow>


                            {/* Add more rows as needed */}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </div >
    );
}

export default Members;
