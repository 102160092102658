import React from 'react';
import ReactApexCharts from 'react-apexcharts';

const PieChart = ({ datas }) => {
    console.log(datas);

    const checkedCount = datas?.filter(item => item.is_completed === 1).length;
    const notCheckedCount = datas?.filter(item => item.is_completed === 0).length;

    const options = {
        chart: {
            type: 'pie',
        },
        labels: ['Checked', 'Not Checked'],
        colors: ['#00CC00', '#858585'],
        fill: ['#00CC00', '#858585'],
        dataLabels: {
            offset: -20, // Adjust the offset value to control label placement
        },
        plotOptions: {
            pie: {
                offsetY: -5, // Adjust the offsetY value to control label placement
            },
        },
    };

    // Ensure 'Checked' portion appears first in the pie chart
    const series = [checkedCount, notCheckedCount];

    return (
        <div>
            <ReactApexCharts options={options} series={series} type="pie" width="100%" />
        </div>
    );
};

export default PieChart;
