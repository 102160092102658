import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Backdrop, Button, Card, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Grid, TablePagination, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Project } from "../../../api/Endpoints/Project";
import { Task } from "../../../api/Endpoints/Task";
import Create from "../tasks/create";
import BasicTabs from '../tasks/TabMenus';
import ReactSelector from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { Employee } from '../../../api/Endpoints/Employee';
import { Edit, PieChart } from '@mui/icons-material';
import TaskMasterStatus from '../tasks/statusModal';
import { UserIdContext } from '../../../contexts/UserID/UserID';
import DonutChart from '../../form/DonutChart';
import ChartModal from '../tasks/ChartModal';
import BasicCard from './CardComponent';
import toast from 'react-hot-toast';
import DateInput from '../../form/DateInput';



const scheme = yup.object().shape({


    // title: yup.string().required(),
    // description: yup.string(),
    // start_date: yup.string(),
    // end_date: yup.string(),
    // due_date: yup.string().required(),
    // assigned_to_id: yup.object().required(),
    // assigned_by_id: yup.object(),
    // projects_id: yup.object().required()
    // reviewer_id: yup.object().shape({
    //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    // }).required().typeError("Please choose a reviewer"),
})




const TaskMaster = (props) => {

    const { UserID, UserName, ID } = UserIdContext();

    const { watch, register, control, formState: { errors }, setValue, getValues, } = useForm({ resolver: yupResolver(scheme) });
    const [editId, setEditId] = useState();
    const [refresh, setRefresh] = useState(0);
    const [chartOpen, setChartOpen] = useState(false)
    const [statusNote, setStatusNote] = useState()
    const [chartID, setChartID] = useState()
    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }
    const handleDelete = (id) => { }

    const projectClickFilter = async (data) => {
        // setLoading(true)
        // let Pro = await Project.get({ keyword: data?.name })
        // if (typeof Pro.data.data.data !== "undefined") {
        //     setEmployees(Pro.data.data.data)
        // } else {
        //     return [];
        // }
        fetchProjects(data?.name)
        setSelectedProjectID(data);
        setValue('projects', data?.name || "");
    }
    const assignToClickFilter = async (data) => {
        // setLoading(true)
        // let Emp = await Employee.get({ keyword: data?.name })
        // if (typeof Emp.data.data.data !== "undefined") {
        //     setEmployees(Emp.data.data.data)
        // } else {
        //     return [];
        // }
        fetchEmployees(data?.name)
        setSelectedAssignedToID(data?.id)
        setValue('assigned_to', data?.name || "")
    }
    // console.log(watch('assigned_to'));



    const viewChart = (id) => {
        setChartID(id)
        setChartOpen(true)
    }

    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState([])
    const [employees, setEmployees] = useState([])
    const [detailViewId, setDetailViewId] = useState(false)
    const [selectedProjectID, setSelectedProjectID] = useState(null)
    const [selectedAssignedByID, setSelectedAssignedByID] = useState(null)
    const [selectedReviewer, setSelectedReviewer] = useState(null)
    const [selectedAssignedToID, setSelectedAssignedToID] = useState(ID)
    const [selectStatus, setselectStatus] = useState(null)
    const [overdue, setOverdue] = useState(false);

    const handleCheckboxChange = (event) => {
        setOverdue(event.target.checked);
        if (event.target.checked) {
            setselectStatus(['Not Started', 'In Progress'])
        } else {
            setselectStatus()
        }
    };

    const [openChangeStatus, setOpenChangeStatus] = React.useState(false);
    const handleCloseChangeStatus = () => {
        setOpenChangeStatus(false);
        statusDestroy()
    };

    const DetailViewOpen = (id) => {
        setDetailViewId(id)
    }

    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (event, page) => {
        setPageNumber(page)
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 0);
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }


    const fetchTable = () => {
        console.log(selectedProjectID);
        setLoading(true);
        Task.get({ projects_id: selectedProjectID?.id, assigned_by: selectedAssignedByID?.id, assigned_to: selectedAssignedToID, reviewer: selectedReviewer, keyword: searchKeyword, status: selectStatus, page: parseInt(pageNumber) + 1, limit: 25 }).then(response => {
            console.log(response.data.data);
            setList(response.data.data);
            setLoading(false);
            setValue('projects', selectedProjectID?.name)
        }).catch(error => {
            setLoading(false);
        })

    }

    const selectProject = (project_id) => {
        setValue('projects', project_id?.name || "")
        console.log(project_id);
        setSelectedProjectID(project_id)
        fetchProjects()

    }

    const selectAssignedBy = (assign_to_id) => {
        console.log(assign_to_id);
        setSelectedAssignedByID(assign_to_id)
        setValue('assign_by_id', assign_to_id?.name || "")

    }

    const selectAssignedTo = (assign_to) => {
        console.log(assign_to);
        setSelectedAssignedToID(assign_to?.id)
        setValue('assigned_to', assign_to?.name || "")
        fetchEmployees()
    }


    const selectReviewer = (reviewer) => {
        console.log(reviewer);
        setSelectedReviewer(reviewer?.id)
        setValue('reviewer', reviewer?.name || "")


    }

    const selectStatusFunc = (status) => {
        setselectStatus(status?.name)
        setValue('status', status?.name || "")

    }

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    const fetchProjects = (e) => {
        Project.get({ keyword: e }).then(response => {
            // console.log(response);
            if (typeof response.data.data.data !== "undefined") {
                setProjects(response.data.data.data)
            } else {
                return [];
            }
        })
    }

    const fetchEmployees = (e) => {
        Employee.get({ keyword: e, limit: 100 }).then(response => {
            // console.log(response);
            if (typeof response.data.data.data !== "undefined") {
                setEmployees(response.data.data.data)
            } else {
                return [];
            }
        })
    }

    const listStatus = [
        { id: 1, name: "Not Started", value: "Not Started" },
        { id: 2, name: "In progress", value: "In progress" },
        { id: 3, name: "Review pending", value: "Review pending" },
        { id: 4, name: "Review Failed", value: "Review Failed" },
        { id: 5, name: "Completed", value: "Completed" },
    ]


    const [draggedCard, setDraggedCard] = useState(null);
    const [taskID, setTaskID] = useState()

    // const handleStatusNoteChange = (e) => {
    //     setStatusNote(e.target.va;ue)
    // }


    const handleDragStart = (e, card) => {
        // console.log('drag',card);
        setDraggedCard(card);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    // console.log(draggedCard);

    const statusDestroy = () => {
        setDraggedCard()
        setTaskID()
        setValue('statusNote', '')
    }

    const handleDrop = (e, status) => {
        // console.log(status);
        e.preventDefault();
        if (draggedCard) {
            if (draggedCard?.status != status) {
                setOpenChangeStatus(true)
                handleStatusChange(draggedCard.id, status);
                // setDraggedCard(null);
            }
            setDraggedCard(null);
        }
    };

    const handleStatusChange = (id, status) => {
        setTaskID({
            id: id,
            status: status
        })
    }

    const handleStatusChangeConfirm = async () => {
        setOpenChangeStatus(true);
        // console.log(watch('statusNote'));
        const loadingToast = toast.loading('Changing...');
        let change = await Task.changeStatus(
            {
                task_id: taskID?.id,
                status: taskID?.status,
                status_note: watch('statusNote')
            });

        if (change.data.status === "success") {
            toast.dismiss(loadingToast);
            toast.success(change.data.message)
            handleCloseChangeStatus();
            fetchTable()
        } else {
            toast.dismiss(loadingToast);
            toast.error(change.data.message)
        }
        statusDestroy()
    }

    useEffect(() => {
        fetchProjects()
        fetchEmployees()
    }, [])

    useEffect(() => {
        // fetchTable()
        setValue('assigned_to', UserName)
        setSelectedAssignedToID(ID)
    }, [UserName, ID])


    useEffect(() => {
        fetchTable();
    }, [searchKeyword,
        pageNumber,
        selectedProjectID,
        selectedAssignedByID,
        selectedAssignedToID,
        selectedReviewer,
        selectStatus,
        ID
    ])


    return (
        <>
            {
                detailViewId &&
                <BasicTabs id={detailViewId} handleClose={setDetailViewId} />
            }
            {
                <ChartModal DetailViewOpen={DetailViewOpen} id={chartID} setID={setChartID} open={chartOpen} setOpen={setChartOpen} />
            }

            <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <PageHeader title={"Task Master"} />

                <Grid display={'flex'} mr={3}>
                    <TextField
                        style={{ width: 300, marginRight: 10 }}
                        size='small'
                        id="outlined-name"
                        label="Search Tasks"
                        onChange={handleSearchEntry}

                    />
                    <Create fetchTable={fetchTable} key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} />

                </Grid>

            </Grid>


            <Grid sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: 'space-between' }} variant="outlined">

                <Grid item display={'flex'}>
                    <Grid mr={2} sx={{ width: 180 }}>
                        {/* <InputLabel sx={{
                            color: "black",
                            fontSize: "14px",
                            pb: .5
                        }}>Choose Project</InputLabel> */}
                        <ReactSelector
                            onInputChange={fetchProjects}
                            placeholder="Project"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={projects}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}

                            // inputValue={inProject}
                            value={
                                projects.find(options =>
                                    options.name === watch('projects')
                                )
                            }
                            name='projects'
                            isClearable
                            defaultValue={(watch('projects'))}
                            onChange={(selectedOption) => selectProject(selectedOption)}
                        />
                    </Grid>

                    <Grid mr={2} sx={{ width: 160 }}>
                        {/* <InputLabel sx={{
                            color: "black",
                            fontSize: "14px",
                            pb: .5
                        }}>Choose Project</InputLabel> */}
                        <ReactSelector
                            onInputChange={fetchEmployees}
                            placeholder="Assigned By"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={employees}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}

                            // inputValue={inProject}
                            value={
                                employees.find(options =>
                                    options.name === watch('assign_by_id')
                                )
                            }
                            name='assign_by_id'
                            isClearable
                            defaultValue={(watch('assign_by_id'))}
                            onChange={(selectedOption) => selectAssignedBy(selectedOption)}
                        />
                    </Grid>

                    <Grid mr={2} sx={{ width: 160 }}>
                        <ReactSelector
                            onInputChange={fetchEmployees}
                            placeholder="Assigned To"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={employees} // Ensure that this includes all employees, not just the initial 10.
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            value={
                                employees.find(options =>
                                    options.name === watch('assigned_to')
                                )
                            }
                            name='assigned_to'
                            isClearable
                            defaultValue={(watch('assigned_to'))}
                            onChange={(selectedOption) => selectAssignedTo(selectedOption)}
                        />
                    </Grid>

                    <Grid mr={2} sx={{ width: 150 }}>
                        {/* <InputLabel sx={{
                            color: "black",
                            fontSize: "14px",
                            pb: .5
                        }}>Choose Project</InputLabel> */}
                        <ReactSelector
                            onInputChange={fetchEmployees}
                            placeholder="Reviewer"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={employees}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}

                            // inputValue={inProject}
                            value={
                                employees.find(options =>
                                    options.name === watch('reviewer')
                                )
                            }
                            name='reviewer'
                            isClearable
                            defaultValue={(watch('reviewer'))}
                            onChange={(selectedOption) => selectReviewer(selectedOption)}
                        />
                    </Grid>

                    <Grid mr={2} sx={{ width: 180 }}>
                        <ReactSelector
                            placeholder="Status"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={listStatus}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}

                            // inputValue={inProject}
                            value={
                                listStatus.filter(options =>
                                    options.name === watch('status')
                                )
                            }
                            name='status'
                            isClearable
                            defaultValue={(watch('status'))}
                            onChange={(selectedOption) => selectStatusFunc(selectedOption)}
                        />
                    </Grid>

                </Grid>
                <Grid sx={{ p: 2, display: "flex", alignItems: "center", marginLeft: 'auto' }} variant="outlined">
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={overdue} onChange={handleCheckboxChange} />} label="OverDue Task" />
                    </FormGroup>
                </Grid>
            </Grid >

            <Card sx={{ m: 2 }} variant="outlined">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container display={'flex'} justifyContent={'space-between'} >
                    <Grid sm={2.3} xs={2.3} pl={1}
                        onDragOver={(e) => handleDragOver(e)}
                        onDrop={(e) => handleDrop(e, 'Not Started')}>
                        <h4>NOT STARTED</h4>
                        {
                            list?.data
                                ?.filter(item => item.status === 'Not Started')
                                .map(cards => (
                                    <div draggable={UserID == cards.assigned_to_id || UserID == cards.reviewer_id || UserID == cards.created_by || UserID == cards.project?.members?.find(member => member.is_project_owner == 1)?.user?.id} onDragStart={(e) => handleDragStart(e, cards)} >
                                        {/* draggable={UserID == cards.assigned_to_id || UserID ==cards.reviewer_id || UserID == cards.created_by || UserID == cards.project?.members?.find(member => member.is_project_owner == 1)?.user?.id} */}
                                        <Grid container display="flex" justifyContent="center" mt={2} item xs={12}>
                                            <BasicCard DetailViewOpen={DetailViewOpen} datas={cards} />
                                        </Grid>
                                    </div>
                                ))
                        }
                    </Grid>

                    <Grid sm={2.3} xs={2.3} onDragOver={(e) => handleDragOver(e)}
                        onDrop={(e) => handleDrop(e, 'In progress')}>
                        <h4>IN PROGRESS</h4>
                        {
                            list?.data
                                ?.filter(item => item.status === 'In progress')
                                .map(cards => (
                                    <div draggable={UserID == cards.assigned_to_id || UserID == cards.reviewer_id || UserID == cards.created_by || UserID == cards.project?.members?.find(member => member.is_project_owner == 1)?.user?.id}
                                        onDragStart={(e) => handleDragStart(e, cards)}>
                                        <Grid container display="flex" justifyContent="center" mt={2} item xs={12}>
                                            <BasicCard DetailViewOpen={DetailViewOpen} handleStatusChange={handleStatusChange} datas={cards} />
                                        </Grid>
                                    </div>
                                ))
                        }
                    </Grid>

                    <Grid sm={2.3} xs={2.3}
                        onDragOver={(e) => handleDragOver(e)}
                        onDrop={(e) => handleDrop(e, 'Review pending')}>
                        <h4>UNDER REVIEW</h4>
                        {
                            list?.data
                                ?.filter(item => item.status === 'Review pending')
                                .map(cards => (
                                    <div draggable={UserID == cards.assigned_to_id || UserID == cards.reviewer_id || UserID == cards.created_by || UserID == cards.project?.members?.find(member => member.is_project_owner == 1)?.user?.id}
                                        onDragStart={(e) => handleDragStart(e, cards)}>
                                        <Grid container display="flex" justifyContent="center" mt={2} item xs={12}>
                                            <BasicCard DetailViewOpen={DetailViewOpen} handleStatusChange={handleStatusChange} datas={cards} />
                                        </Grid>
                                    </div>
                                ))
                        }
                    </Grid>

                    <Grid sm={2.3} xs={2.3} onDragOver={(e) => handleDragOver(e)}
                        onDrop={(e) => handleDrop(e, 'Review Failed')}>
                        <h4>REVIEW FAILED</h4>
                        {
                            list?.data
                                ?.filter(item => item.status === 'Review Failed')
                                .map(cards => (
                                    <div draggable={UserID == cards.assigned_to_id || UserID == cards.reviewer_id || UserID == cards.created_by || UserID == cards.project?.members?.find(member => member.is_project_owner == 1)?.user?.id}
                                        onDragStart={(e) => handleDragStart(e, cards)}>
                                        <Grid container display="flex" justifyContent="center" mt={2} item xs={12}>
                                            <BasicCard DetailViewOpen={DetailViewOpen} handleStatusChange={handleStatusChange} datas={cards} />
                                        </Grid>
                                    </div>
                                ))
                        }
                    </Grid>

                    <Grid sm={2.3} xs={2.3} onDragOver={(e) => handleDragOver(e)}
                        onDrop={(e) => handleDrop(e, 'Completed')}>
                        <h4>COMPLETED</h4>
                        {
                            list?.data
                                ?.filter(item => item.status === 'Completed')
                                .map(cards => (
                                    <div draggable={UserID == cards.assigned_to_id || UserID == cards.reviewer_id || UserID == cards.created_by || UserID == cards.project?.members?.find(member => member.is_project_owner == 1)?.user?.id}
                                        onDragStart={(e) => handleDragStart(e, cards)}>
                                        <Grid container display="flex" justifyContent="center" mt={2} item xs={12}>
                                            <BasicCard DetailViewOpen={DetailViewOpen} handleStatusChange={handleStatusChange} datas={cards} />
                                        </Grid>
                                    </div>
                                ))
                        }
                    </Grid>
                </Grid>
                {
                    list.data ?
                        <Grid mt={1} ml={'auto'} display={'flex'} justifyContent={'end'}>
                            <TablePagination
                                component="div"
                                count={list?.total}
                                rowsPerPage={list?.per_page}
                                page={pageNumber}
                                onPageChange={handlePageChange}
                                rowsPerPageOptions={[]}
                                labelRowsPerPage="" // This will hide the "Rows per page" label
                                SelectProps={{ native: true }} // This will hide the select dropdown
                            />
                        </Grid>
                        : ''}
            </Card>

            <Dialog open={openChangeStatus} onClose={handleCloseChangeStatus}>
                <DialogTitle>Change status of task to {taskID?.status}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please submit remarks on this task, you can leave it blank if you want.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        {...register('statusNote')}
                        value={watch('statusNote')}
                        margin="dense"
                        id="name"
                        label="Remarks"
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                    {/* {
                        status == 'Completed' ?
                            <Grid mt={2} width={'50%'}>
                                <DateInput control={control} name="due_date"
                                    label="Date of Completion"
                                    value={watch('due_date')} />
                            </Grid>
                            : ''
                    } */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseChangeStatus}>Cancel</Button>
                    <Button onClick={handleStatusChangeConfirm}>Change</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TaskMaster;
