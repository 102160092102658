import React, { useEffect, useState } from 'react';
import { Modal, TextField, Button, Box, Typography, InputLabel, Grid } from '@mui/material';
import Select from 'react-select';
import DynamicChip from '../../../../utils/DynamicChip';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import ReactSelector from 'react-select';
import TextInput from '../../../form/TextInput';
import DateInput from '../../../form/DateInput';
import SelectX from '../../../form/SelectX';
import { Employee } from '../../../../api/Endpoints/Employee';
import { toast } from 'react-hot-toast';
import { WorkRequest } from '../../../../api/Endpoints/WorkRequest';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const scheme = yup.object().shape({
    remarks: yup.string()

})

function AssignToPopup({ openAssignTopopup, setOpenAssignTopopup, onUpdate, details }) {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedOption, setSelectedOption] = useState('');
    const [selectStatus, setSelectStatus] = useState()
    const [selectCostOption, setSelectCostOption] = useState()


    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const handleClose = () => {
        setOpenAssignTopopup(false);
    };
    console.log("This is selected option", selectedOption);

    const fetchEmployees = (e) => {
        // let project = watch('projects_id');
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const priorityOptions = [
        {
            value: "Low", label: "Low"
        },
        {
            value: "Medium", label: "Medium"
        },
        {
            value: "High", label: "High"
        },
        {
            value: "Urgent", label: "Urgent"
        },
        { value: "Critical", label: "Critical" },
    ];


    const handleStatusChange = (id) => {
        console.log(id);
        setSelectStatus(id);
    }
    const handleCostOptionChange = (id) => {
        setSelectCostOption(id)
    }

    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })


        let date = moment(data.due_date).format('YYYY-MM-DD');

        let dataToSubmit = {
            id: openAssignTopopup,
            assign_to: data.assigned_to_id?.id,
            priority: data.priority,
            expected_completion_date: date,
            remarks_for_team: data.remarks
        };


        let action;

        // if (props.editId > 0) {
        //     console.log("dataToSubmit", dataToSubmit);
        //     action = WorkRequest.update(dataToSubmit);
        // } else {
        action = WorkRequest.assign(dataToSubmit);
        // }
        action.then(response => {
            console.log(response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            onUpdate();
            handleClose()
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    useEffect(() => {
        if (details.assigned_to_user) {
            setValue('assigned_to_id',{id:details.assigned_to_user?.id,name:details.assigned_to_user?.name})
            setValue('priority',details.priority)
            setValue('remarks',details.remarks_for_client)
            setValue('due_date',details.expected_completion_date)
        }
    }, [])



    return (
        <div>
            <Modal
                open={openAssignTopopup}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={style}>

                        <Grid container spacing={1} sx={{ p: 1, }}>
                            <Grid item xs={6}>
                                <SelectX
                                    // key={refresh * 0.2}
                                    label={"Assign to"}
                                    options={fetchEmployees}
                                    control={control}
                                    // error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                    // error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                    name={'assigned_to_id'}
                                    defaultValue={watch('assigned_to_id')}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: .5,
                                    fontSize: '14px',
                                    fontWeight: 500
                                }}>Priority</InputLabel>
                                <ReactSelector
                                    styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                    options={priorityOptions}
                                    value={
                                        priorityOptions.filter(priorityop =>
                                            priorityop.label === watch('priority'))

                                    }
                                    name='priority'
                                    isClearable={true}
                                    defaultValue={(watch('priority'))}
                                    onChange={(selectedOption) => setValue('priority', selectedOption.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} sx={{ p: 1, }}>
                            <Grid item xs={6}>
                                <DateInput
                                    control={control}
                                    name="due_date"
                                    label="Due Date"
                                    value={watch('due_date')}
                                />
                            </Grid>

                        </Grid>

                        <Box p={1}>
                            <TextField
                                {...register('remarks')}
                                label="Remarks"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={2}
                                required
                            />
                        </Box>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 2, mr: 2,
                                    background: "gray",
                                    '&:hover': {
                                        backgroundColor: 'gray',
                                    },
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>

                            <LoadingButton sx={{ height: 40, marginTop: 2 }} loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                        </div>
                    </Box>
                </form>
            </Modal>
        </div>
    );
}

export default AssignToPopup;
