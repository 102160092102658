
import React, { createContext, useState, useContext, useEffect } from 'react';


const StateContext = createContext();


export const ProjectIdContext = () => useContext(StateContext);


export const ProjectProvider = ({ children }) => {


    const [ProjectID, setProjectID] = useState(null);



    useEffect(() => {
        setProjectID(ProjectID)
    }, [ProjectID]);



    return (
        <StateContext.Provider value={{ ProjectID, setProjectID }}>
            {children}
        </StateContext.Provider>
    );
};
