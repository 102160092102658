import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    Select,
    Slide, TextField, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { ErrorMessage } from '@hookform/error-message';
import { Accordion, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MeetingIdContext } from '../../../../contexts/MeetingID/meetingID';
import { Meetings } from '../../../../api/Endpoints/Meeting';
import InternalTeam from '../Tabs/attendess/Internal-Team';
import Guests from '../Tabs/attendess/Guests';
import Subscribers from '../Tabs/attendess/Subscribers';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




const scheme = yup.object().shape({


    // title: yup.string().required(),
    // description: yup.string(),
    // // start_date: yup.string(),
    // // end_date: yup.string(),
    // // due_date: yup.string().required(),
    // assigned_to_id: yup.object().required(),
    // assigned_by_id: yup.object(),
    // projects_id: yup.object().required()
    // reviewer_id: yup.object().shape({
    //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    // }).required().typeError("Please choose a reviewer"),
})
const priority = [
    { id: 1, name: "Critical" },
    { id: 2, name: "High" },
    { id: 3, name: "Medium" },
    { id: 4, name: "Low" },
]



const DetailAttendees = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [ID, setID] = useState(0)
    const [Guest, setGuest] = useState([])
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const { MeetingID, setMeetingID } = MeetingIdContext();
    const [guestList, setGuestList] = useState([]);
    const [InternalTeamlist, setInternalTeamList] = useState([]);
    // const handleClickOpen = () => {
    //     props.onNew();

    // };
    const handleClose = () => { setOpen(false); };


    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })


    useEffect(() => {
        if (props.editId > 0) {
            fetchDetails()
        }
    }, [props.editId])

    useEffect(() => {

        fetchDetails()
        fetchList()

    }, [])





    const fetchDetails = async () => {
        let response = await Meetings.getDetails({ id: props.id });
        if (response.data.status === "success") {
            console.log(response.data.data);
            // setMeetingID(response.data.data.id)
            setInternalTeamList(response.data.data?.internal_attendees)
            setGuestList(response.data.data?.guest_attendees);
        }
    };



    const fetchList = async () => {
        let response = await Meetings.getDetails({ id: props.id });
        if (response.data.status === "success") {
            console.log(response.data.data);
            setInternalTeamList(response.data.data?.internal_attendees)
            setGuestList(response.data.data?.guest_attendees);
        }
    };


    return (
        <div>


            {!loading ?
                <DialogContent sx={{ padding: 0 }}>



                    <ErrorMessage
                        errors={errors}
                        name="multipleErrorInput"
                        render={({ messages }) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (
                                <p key={type}>{message}</p>
                            ))
                        }
                    />
                    {/* <Grid sx={{ p: 1 }} item xs={12}>
                            <SelectX

                                label={"Choose project"}
                                options={fetchProjects}
                                control={control}
                                error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                name={'projects_id'}
                                defaultValue={watch('projects_id')}
                            />
                        </Grid> */}
                    {/* <Grid sx={{ p: 1 }} item xs={12}>
                            <SelectX

                                label={"Open Task"}
                                options={''}
                                control={control}
                                error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                name={'projects_id'}
                                defaultValue={watch('projects_id')}
                            />
                        </Grid> */}


                    <Accordion sx={{ mb: 3, mt: 2 }}>
                        <AccordionSummary
                            sx={{
                                backgroundColor: 'rgb(243,242,255)'
                            }}
                            expandIcon={< ExpandMoreIcon />}
                            aria-controls="internal-content"
                            id="internal-header"
                        >
                            <Typography sx={{ fontWeight: 500 }}>Internal Team</Typography>
                        </AccordionSummary>
                        <div>
                            <InternalTeam
                                projectID={props.projectID}
                                meetingID={props.id > 0 ? props.id : null}
                                list={InternalTeamlist && InternalTeamlist}
                                fetchList={() => fetchList()}
                                fetchTable={props.fetchTable} />
                        </div>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            sx={{ backgroundColor: 'rgb(243,242,255)' }}
                            expandIcon={<ExpandMoreIcon />}
                            projectID={props.projectID} meetingID={ID > 0 ? ID : null}
                            aria-controls="guests-content"
                            id="guests-header"
                        >
                            <Typography sx={{ fontWeight: 500 }}
                            >Guests</Typography>
                        </AccordionSummary>
                        <div>
                            <Guests
                                id={props.id}
                                fetchList={() => fetchDetails()}
                                projectID={props.projectID}
                                meetingID={props.id > 0 ? props.id : null}
                                list={guestList && guestList}
                            />
                        </div>
                    </Accordion>

                    <Accordion sx={{ mb: 3, mt: 2 }}>
                        <AccordionSummary
                            sx={{
                                backgroundColor: 'rgb(243,242,255)'
                            }}
                            expandIcon={< ExpandMoreIcon />}
                            aria-controls="subscriber-content"
                            id="suubscriber-header"
                        >
                            <Typography sx={{ fontWeight: 500 }}>Subscribers</Typography>
                        </AccordionSummary>
                        <div>
                        <Subscribers
                                refresh={setRefresh}
                                projectID={props.projectID}
                                meetingID={props.editId > 0 ? props.editId : null}
                                // list={InternalTeamlist && InternalTeamlist}
                                list={''}
                                fetchList={() => fetchList()}
                                fetchTable={props.fetchTable} />
                        </div>
                    </Accordion>





                </DialogContent>
                : (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <CircularProgress className='ld-circle' />
                    </Box >
                )

            }


        </div >
    );
};

export default DetailAttendees;
