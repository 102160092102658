import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Box, Button, DialogActions, Divider, Grid, InputLabel, Modal, Slide, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-hot-toast';
import { Task } from '../../../../api/Endpoints/Task';
import SelectX from '../../../form/SelectX';
import { Users } from '../../../../api/Endpoints/Users';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline } from '@material-ui/icons';
import Delete from '../../common/Popup/Delete';
import { SubscribeApi } from '../../../../api/Endpoints/Subscribe';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import TextInput from '../../../form/TextInput';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
    user_id: yup.object().required(),
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    // height: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};


const AttachmentsTab = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editValues, setEditValues] = useState({})
    const [refresh, setRefresh] = React.useState(false);
    const [subscribers, setSubscribers] = useState([])
    const [pageNumber, setPageNumber] = useState(0);
    const [deleteID, setDeleteID] = useState()
    const [document, setDocument] = useState([])
    const [uploadModal, setUploadModal] = useState(false)



    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })



    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        props.setEditId()
        setOpen(false);
    };
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleDeleteAlert = (id) => {
        setDeleteID(id)
    }

    const handleFileUpload = (e) => {
        // console.log(e);
        setDocument(e?.target?.files[0])
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Title',
            width: 150,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.row?.user?.name}</Button >
                    </>
                );
            },
        },

        // {
        //     field: 'email',
        //     headerName: 'Email',
        //     width: 250,
        //     renderCell: (params) => {
        //         const id = params.row.id
        //         return (
        //             <>
        //                 <Button style={{ color: 'black' }} size="small" >{params.row?.user?.email}</Button >
        //             </>
        //         );
        //     },
        // },

        // {
        //     field: 'action', headerName: 'delete', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
        //         let id = params.value?.id;
        //         return <>
        //             <Button style={{ color: 'black' }} size={'small'} onClick={() => handleDeleteAlert(id)}>
        //                 <DeleteOutline />
        //             </Button>
        //         </>;
        //     }
        // },
    ]

    const onSubmit = (data) => {
        console.log(data);

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let dataToSubmit = {
            subscribable_type: "task",
            subscribable_id: props.id,
            users_id: data.user_id?.id
        }

        console.log(dataToSubmit);
        let action;

        // if (props.editId > 0) {
        //     console.log(dataToSubmit);
        //     action = Task.updateNote(dataToSubmit)
        // } else {
        console.log(dataToSubmit);
        action = SubscribeApi.subscribe(dataToSubmit)
        // }
        action.then((response) => {
            console.log(response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true })
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false })
            toast.success(response.data.message)
            props.setRefresh(Math.random() * .02)
            // props.onUpdate()
            setValue('user_id', '')
            setOpen(false)
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            setOpen(false)
            toast.error("server error")
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    const fetchDetails = () => {
        setLoading(true)
        SubscribeApi.getSubscribers({ subscribable_type: 'task', subscribable_id: props.id, page: parseInt(pageNumber) + 1 }).then((response) => {
            let data = response.data.data
            console.log(data.data);
            let subscription = data.data.some(item => item.users_id == props.userID)
            if (subscription) {
                props.setSubscription(true)
            } else {
                props.setSubscription(false)
            }
            setSubscribers(data.data)
            // setValue('note', data.note)
            setLoading(false)
        })
    }

    const fetchUsers = (e) => {
        // console.log(e);
        return Users.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const uploadModalOpen = () => {
        setUploadModal(true)
    }
    const uploadModalClose = () => {
        setUploadModal(false)
    }

    useEffect(() => {
        fetchDetails()
        console.log('refreshing');
    }, [props.refresh])

    useEffect(() => {
        // fetchDetails()
        setOpen(true);
    }, [])

    return (
        <div>


            {deleteID && <Delete
                ID={deleteID}
                setID={setDeleteID}
                setDeletePopup={setDeleteID}
                Callfunc={() => fetchDetails()}
                url="/subscribers/delete?id="
                title="Subscriber" />}

            <div>
                <div style={{ height: 350, width: '100%', }}>
                    {subscribers &&
                        <DataGrid
                            paginationMode="server"
                            rows={subscribers}
                            columns={columns}

                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            // rowCount={list.total}
                            onPageChange={handlePageChange}
                            autoHeight={false}
                            density={"comfortable"}
                        />
                    }
                </div>
            </div>
            <Divider />

            <div>
                <Button sx={{ mt: 2, p: 1 }} variant='contained' onClick={uploadModalOpen}>Add Document</Button>
                <Modal
                    open={uploadModal}
                    onClose={uploadModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Upload Document
                        </Typography>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />
                            <Grid sx={{ p: 1,mt:1 }} item xs={12}>
                                <TextInput control={control} name="title"
                                    label="Title"
                                    value={watch('title')} />
                            </Grid>

                            <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 0.5,
                                    fontSize: '14px',
                                    fontWeight: 500
                                }}>Description </InputLabel>
                                <TextField
                                    {...register('description')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    sx={{ width: '100%', }}
                                />
                            </Grid>

                            <Grid display={'flex'} justifyContent={'end'} sx={{ p: 1 }} item xs={12}>
                                <label htmlFor="upload-input">
                                    <input
                                        id="upload-input"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleFileUpload}  // Replace with your file upload logic
                                    />
                                    <Button
                                        component="span"
                                        variant="contained"
                                        color="primary"
                                    >
                                        <CloudUploadIcon sx={{ mr: 1 }} />
                                        Upload
                                    </Button>
                                </label>
                            </Grid>

                            {
                                document ?
                                    <Grid sx={{ p: 1, mt: 2 }} item xs={12}>
                                        <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                            <p>{document?.name?.slice(0, 20)} ...</p>
                                            <a onClick={() => setDocument()} style={{ cursor: 'pointer' }}><DeleteIcon sx={{ color: 'red' }} /></a>
                                        </Grid>
                                    </Grid>
                                    : ''
                            }

                            {
                                document ?
                                    <Grid p={1} xs={12} display={'flex'} justifyContent={'end'}>
                                        <Grid display={'flex'} alignItems={'end'} sx={{ pb: 2 }} item sm={4} xs={4}>
                                            <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                        </Grid>
                                    </Grid>
                                    : ''
                            }
                        </form>
                    </Box>
                </Modal>
            </div>



        </div>
    )
}

export default AttachmentsTab;
