import React, { useEffect, useState } from 'react';
import { Modal, TextField, Button, Box, Typography, InputLabel, Grid } from '@mui/material';
import Select from 'react-select';
import DynamicChip from '../../../../utils/DynamicChip';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import ReactSelector from 'react-select';
import TextInput from '../../../form/TextInput';
import DateInput from '../../../form/DateInput';
import SelectX from '../../../form/SelectX';
import { Employee } from '../../../../api/Endpoints/Employee';
import { toast } from 'react-hot-toast';
import { WorkRequest } from '../../../../api/Endpoints/WorkRequest';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const scheme = yup.object().shape({
    remarks: yup.string()

})

function ReviewStatus({ openReviewStatus, setOpenReviewStatus, onUpdate, details }) {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedOption, setSelectedOption] = useState('');
    const [selectStatus, setSelectStatus] = useState()
    const [selectCostOption, setSelectCostOption] = useState()


    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const handleClose = () => {
        setOpenReviewStatus(false);
    };
    console.log("This is selected option", selectedOption);

    const fetchEmployees = (e) => {
        // let project = watch('projects_id');
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const Options = [
        {
            value: "Not reviewed", label: "Not reviewed"
        },
        {
            value: "Review Success", label: "Review Success"
        },
        {
            value: "Review Failed", label: "Review Failed"
        },
        {
            value: "Needs discussion", label: "Needs discussion"
        },
    ];


    const handleStatusChange = (id) => {
        console.log(id);
        setSelectStatus(id);
    }
    const handleCostOptionChange = (id) => {
        setSelectCostOption(id)
    }

    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })


        let date = moment(data.due_date).format('YYYY-MM-DD');

        let dataToSubmit = {
            id: openReviewStatus,
            review_status: data.status,
            review_status_remarks: data.remarks
        };


        let action;

        // if (props.editId > 0) {
        //     console.log("dataToSubmit", dataToSubmit);
        //     action = WorkRequest.update(dataToSubmit);
        // } else {
        action = WorkRequest.addReviewStatus(dataToSubmit);
        // }
        action.then(response => {
            console.log(response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            onUpdate();
            handleClose()
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    useEffect(() => {
        if (details.review_status) {
            setValue('status',details?.review_status)
            setValue('remarks',details?.review_status_remarks?details.review_status_remarks:'')
        }
    }, [])



    return (
        <div>
            <Modal
                open={openReviewStatus}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={style}>

                        <Grid container spacing={1} sx={{ p: 1, }}>

                            <Grid item xs={6}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: .5,
                                    fontSize: '14px',
                                    fontWeight: 500
                                }}>Status</InputLabel>
                                <ReactSelector
                                    styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                    options={Options}
                                    value={
                                        Options.filter(priorityop =>
                                            priorityop.label === watch('status'))

                                    }
                                    name='status'
                                    isClearable={false}
                                    defaultValue={(watch('status'))}
                                    onChange={(selectedOption) => setValue('status', selectedOption?.value || '')}
                                />
                            </Grid>
                        </Grid>


                        <Box p={1}>
                            <TextField
                                {...register('remarks')}
                                label="Remarks"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={2}
                                // required
                            />
                        </Box>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 2, mr: 2,
                                    background: "gray",
                                    '&:hover': {
                                        backgroundColor: 'gray',
                                    },
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>

                            <LoadingButton sx={{ height: 40, marginTop: 2 }} loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                        </div>
                    </Box>
                </form>
            </Modal>
        </div>
    );
}

export default ReviewStatus;
