import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    Select,
    Slide, TextField, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from '@hookform/error-message';
import { Accordion, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MeetingIdContext } from '../../../../../contexts/MeetingID/meetingID';
import VideoShootInternalTeam from './Internal-Team';
import VideoShootGuests from './Guests';
import { VideoShootApi } from '../../../../../api/Endpoints/VideoShoot';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




const priority = [
    { id: 1, name: "Critical" },
    { id: 2, name: "High" },
    { id: 3, name: "Medium" },
    { id: 4, name: "Low" },
]



const VideoShootAttendees = ({ id }) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm();
    const [open, setOpen] = React.useState(false);
    const [ID, setID] = useState(0)
    const [loading, setLoading] = useState(false)
    const { MeetingID, setMeetingID } = MeetingIdContext();
    const [guestList, setGuestList] = useState([]);
    const [InternalTeamlist, setInternalTeamList] = useState([]);
    const [list, setList] = useState([])
    // const handleClickOpen = () => {
    //     props.onNew();

    // };
    const handleClose = () => { setOpen(false); };


    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })


    // useEffect(() => {
    //     if (props.editId > 0) {
    //         fetchDetails()
    //     }
    // }, [props.editId])



    useEffect(() => {
        fetchList()
    }, [])


    const fetchDetails = async () => {
        // console.log('------------------');
        // let response = await Meetings.getDetails({ id: props.editId });
        // if (response.data.status === "success") {
        //     setInternalTeamList(response.data.data?.internal_attendees)
        //     setGuestList(response.data.data?.guest_attendees);
        // }
    };



    const fetchList = async () => {
        // console.log(id);

        let response = await VideoShootApi.getAttendees({ project_video_shoots_id: id })
        console.log(response);
        if (response.status === 200) {
            const guestsArray = [];
            const internalTeamArray = [];
        
            response?.data?.data?.forEach((obj) => {
                if (obj?.guest?.name) {
                    guestsArray.push(obj);
                } else {
                    internalTeamArray.push(obj);
                }
            });
        
            setGuestList(guestsArray);
            setInternalTeamList(internalTeamArray);
        
        }

    };

    return (
        <div>


            {/* {!loading ? */}
                <DialogContent  sx={{ padding: 0,width:600 }}>



                    <ErrorMessage
                        errors={errors}
                        name="multipleErrorInput"
                        render={({ messages }) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (
                                <p key={type}>{message}</p>
                            ))
                        }
                    />

                    <Accordion sx={{ mb: 3, mt: 2 }}>
                        <AccordionSummary
                            sx={{
                                backgroundColor: 'rgb(243,242,255)'
                            }}
                            expandIcon={< ExpandMoreIcon />}
                            aria-controls="internal-content"
                            id="internal-header"
                        >
                            <Typography sx={{ fontWeight: 500 }}>Internal Team</Typography>
                        </AccordionSummary>
                        <div>
                            <VideoShootInternalTeam
                                id={id}
                                list={InternalTeamlist}
                                fetchList={() => fetchList()}
                            />
                        </div>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            sx={{ backgroundColor: 'rgb(243,242,255)' }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="guests-content"
                            id="guests-header"
                        >
                            <Typography sx={{ fontWeight: 500 }}
                            >Guests</Typography>
                        </AccordionSummary>
                        <div>
                            <VideoShootGuests
                                id={id}
                                fetchList={() => fetchList()}
                                list={guestList}
                            />
                        </div>
                    </Accordion>

                </DialogContent>
                {/* // : (
                //     <Box sx={{
                //         display: 'flex',
                //         justifyContent: 'center',
                //         alignItems: 'center',
                //         // width: '100%',
                //         height: '30vh'
                //     }}>
                //         <CircularProgress className='ld-circle' />
                //     </Box >
                // )

            // } */}


        </div >
    );
};

export default VideoShootAttendees;
