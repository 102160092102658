import React, { useEffect, useState } from 'react';
import { useAppSettings } from "../../../hooks/use-app-settings";
import Create from './create';
import moment from "moment";
import { Backdrop, Button, Card, CircularProgress, TextField } from "@mui/material";
import { PreQualifiers } from "../../../api/Endpoints/PreQualifiers";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Account } from "../../../api/Endpoints/Account";
import toast from "react-hot-toast";
import Box from '@mui/material/Box';
import BasicTabs from './TabMenus';
import { ReimbursementAPI } from '../../../api/Endpoints/Reimbursment';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Delete from '../common/Popup/Delete';

function Reimbursement() {


    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [ID, setID] = useState(0)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    // const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    useEffect(() => {
        fetchReimbursment()
    }, [pageNumber])
    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }
    const fetchReimbursment = () => {
        setLoading(true);
        ReimbursementAPI.get({ page: Number(pageNumber) + 1 }).then(response => {
            setList(response.data.data);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })


    }

    const handleCreateNew = () => {

    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }


    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }
    const viewTabs = (id) => {
        setID(id)
        setTabs(true)
    }

    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }

    const handleDelete = (id) => {
        setID(id)
        setDeletePopup(true)
    }
    // Add more rows as needed...
    const columns = [
        {
            field: 'title', headerName: 'Title', width: 250, renderCell: (params) => (
                <Button
                    size="small"
                    style={{ color: 'black' }}
                    onClick={() => viewTabs(params.id)}
                >
                    {params.row.title}

                </Button>
            ),
        },
        {
            field: 'date_of_activity',
            headerName: 'Date of Activity',
            width: 230,
            renderCell: (params) => {
                return <span>{moment(params.row?.date_of_activity).format('DD-MM-YYYY')}</span>;
            }
        },
        {
            field: 'report_to',
            headerName: 'Manager Name',
            width: 230,
            renderCell: (params) => {
                return <span>{params.value?.name}</span>;
            }
        },

        {
            field: 'Edit',
            headerName: 'Actions',
            width: 230,
            renderCell: (params) => (
                <>

                    <a size="small" onClick={() => handleEdit(params.row.id)}>
                        <EditIcon fontSize='small' style={{ color: 'blue',cursor:'pointer' }} />
                    </a>
                    <a size='small' onClick={() => handleDelete(params.id)}>
                        <DeleteIcon fontSize='small' style={{ color: 'red', cursor:'pointer' }} />
                    </a>

                </>
            ),
        },
        // Add more columns as needed...
    ];

    return (




        <>

            {Tabs ? (<BasicTabs setTabs={setTabs} ID={ID} setID={setID} />
            ) : (


                <>
                    {loading ? <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop> : null}
                    {showDeletePopup && <Delete
                        ID={ID}
                        setID={setID}
                        setDeletePopup={setDeletePopup}
                        Callfunc={() => fetchReimbursment()}
                        url="/accounts/reimbursements/delete?id="
                        title="Reimbursement" />}
                    <PageHeader title={'Reimbursement Requests'} onCreateNew={handleCreateNew} />

                    <Card sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'space-between' }} variant="outlined" >

                        <TextField
                            id="outlined-name"
                            label="Search"
                            onChange={handleSearchEntry}
                        />
                        <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />

                    </Card>

                    <Card sx={{ m: 2 }} variant="outlined">
                        {typeof list.data === "object" &&
                            <DataGrid
                                paginationMode="server"
                                rows={list.data}
                                columns={columns}
                                page={pageNumber}
                                pageSize={10}
                                rowHeight={20}
                                rowsPerPageOptions={[10]}
                                onPageChange={handlePageChange}
                                autoHeight
                                density={'comfortable'}
                            />
                        }
                    </Card>

                </>
            )}

        </>
    )
}



export default Reimbursement


