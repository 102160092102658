import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Card, CircularProgress, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from 'moment/moment';
import { Task } from '../../../../../api/Endpoints/Task';
import Delete from '../../../common/Popup/Delete';
import BasicTabs from './TabMenus';
import Create from './create';

const EmployeeTask = (props) => {

    const columns = [
        {

            field: 'title', headerName: 'title', width: 300,
            renderCell: (params) => <p style={{ cursor: 'pointer' }} onClick={() => DetailViewOpen(params.row.id)}>{params.value}</p>

        },
        {
            field: 'project', headerName: 'Project name', width: 150,
            renderCell: (params) => <p style={{ cursor: 'pointer' }} >{params.value?.name}</p>
            // onClick={() => fetchByProject(params.value?.id)}
        },
        {
            field: 'due_date', headerName: 'Due date', width: 120, renderCell: (params) => (

                <p style={{ color: moment(params.value).isBefore(moment(), 'day') && params.row.status != 'Completed' ? 'red' : 'black' }}>
                    {moment(params.value).format('DD-MM-YYYY')}
                </p>

            )

        },

        { field: 'status', headerName: 'Status', width: 200, renderCell: (params) => <>{params.value}</> },
        {
            field: 'action', headerName: 'Edit', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <>
                    <Button
                        variant='outlined'
                        // className='task-btn'
                        size={'small'}
                        onClick={() => handleEdit(id)}>Edit</Button>
                </>;
            }
        },
        {
            field: 'delete', headerName: 'Delete', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><Button
                    //  className='task-btn'
                    variant='outlined'
                    color='error'
                    size={'small'}
                    onClick={() => handleDelete(id)}>Delete</Button>
                </>;
            }
        },
    ]

    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [detailView, setDetailView] = useState(false)
    const [ClientID, setClientID] = useState(0)
    const [deleteId, setDeleteId] = useState(false)
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleDelete = (id) => {
        setDeleteId(id)
    }
    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }
    const fetchTable = () => {
        setLoading(true);
        Task.get({keyword:searchKeyword, assigned_to: props.EmployeeID, keyword: searchKeyword, page: Number(pageNumber) + 1 }).then(response => {
            console.log(response);
            setList(response.data.data);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        })


    }

    const handleCreateNew = () => {

    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(() => {
        fetchTable();
        console.log('taskss');
    }, [searchKeyword, listType, pageNumber])

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }


    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const DetailViewOpen = (id) => {
        console.log(id);
        setDetailView(id)
    }
    console.log(detailView);

    return (
        <>
            {
                detailView &&
                < BasicTabs id={detailView} handleClose={setDetailView} />
            }
            {/* {
                deleteId &&
                <Delete
                    ID={deleteId}
                    setID={setDeleteId}
                    setDeletePopup={setDeleteId}
                    Callfunc={() => fetchTable()}
                    url="websites/delete?id="
                    title="Url"
                />
            } */}

            <>
                <Card sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'space-between' }} variant="outlined" >

                    <TextField
                        id="outlined-name"
                        label="Search Task"
                        onChange={handleSearchEntry}
                        size='small'
                        style={{width:250}}
                    />
                    <Create employee_id={props.employee_id} key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} />

                </Card>
                <Card sx={{ m: 2 }} variant="outlined">
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    {typeof list.data === "object" &&
                        <DataGrid
                            paginationMode="server"
                            rows={list?.data}
                            columns={columns}
                            page={pageNumber}
                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            rowCount={list.total}
                            onPageChange={handlePageChange}
                            autoHeight
                            density={"comfortable"}
                        />
                    }
                </Card>
            </>
            {/* )} */}

        </>

    );
};

export default EmployeeTask;
