import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CreativeCalenderApi } from '../../../../api/Endpoints/CreativeCalender';

function SelectedCalendar({ calendar, handleEdit, calendarID, refresh }) {

    const [list, setList] = useState(calendar)


    const fetchDetails = () => {
        CreativeCalenderApi.getDetails({ id: calendarID }).then(response => {
            setList(response?.data?.data);
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        if (refresh > 0) {
            fetchDetails()
        }
    }, [calendarID, refresh])

    useEffect(() => {
        setList(calendar);
    }, [calendar]);

    return (
        <Grid>
            <>
                <Grid display={'flex'} alignItems={'center'} style={{ borderTop: '3px solid #e8e8e8', borderBottom: '3px solid #e8e8e8', height: '70px' }}>
                    <Grid p={1} container>
                        <Grid p={1} item md={3}>
                            <a style={{ fontWeight: 500, fontSize: '15px', color: 'grey' }}>CALENDAR: </a>
                            <a style={{ fontWeight: 500, fontSize: '15px' }}>   {list?.name}</a>
                        </Grid>

                        <Grid p={1} item md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            <a style={{ fontWeight: 500, fontSize: '15px', color: 'grey' }}>PROJECT: </a>
                            <a style={{ fontWeight: 500, fontSize: '15px' }}>   {list?.project?.name}</a>
                        </Grid>

                        <Grid p={1} item md={3} display={'flex'} alignItems={'center'}>
                            <a style={{ fontWeight: 500, fontSize: '15px', color: 'grey' }}>STATUS: </a>
                            <a style={{ fontWeight: 500, fontSize: '15px' }}>   {list?.status}</a>
                        </Grid>

                        {
                            list?.id &&
                            <Grid item md={3} display={'flex'} alignItems={'center'} justifyContent={'end'}>
                                {/* <Button sx={{ mr: 1 }} variant='outlined'>Change Status</Button> */}
                                <Button onClick={() => handleEdit(list?.id)} sx={{ mr: 1 }} variant='outlined'>Edit</Button>
                                <Button variant='outlined'>Archive</Button>
                            </Grid>
                        }

                    </Grid>
                </Grid>
            </>
        </Grid>
    )
}

export default SelectedCalendar
