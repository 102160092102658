import {useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast, {LoaderIcon} from 'react-hot-toast';
import useMediaQuery from '@mui/material/useMediaQuery';
import {AppBar, Box, Button, Divider, IconButton, Toolbar, Typography} from '@mui/material';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
import { Moon as MoonIcon } from '../icons/moon';
import { Sun as SunIcon } from '../icons/sun';
import { AccountPopover } from './account-popover';
import { OrganizationPopover } from './organization-popover';
import { Logo } from './logo';
import { DashboardNavbarMenu } from './dashboard-navbar-menu';
import { NotificationsPopover } from './notifications-popover';
import { LanguagePopover } from './laguage-popover';
import {useSettings} from "../contexts/Settings/settings-context";
import {LeadTypes} from "../api/Endpoints/LeadTypes";
import {Lists} from "../api/Lists/Lists";
import {LoadingScreen} from "./loading-screen";
import {LogoutOutlined} from "@mui/icons-material";
import {useAuth} from "../hooks/use-auth";
import {useAppSettings} from "../hooks/use-app-settings";
import {Users} from "../api/Endpoints/Users";

export const DashboardNavbar = () => {
  const navigate = useNavigate();
  const appSettings = useAppSettings();
  const [organizations, setOrganizations] = useState([]);
  const auth = useAuth();
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { i18n, t } = useTranslation();
  const { settings, saveSettings } = useSettings();
  const [openMenu, setOpenMenu] = useState(false);
  const [darkMode, setDarkMode] = useState(settings.theme === 'dark');
  const [rtlDirection, setRtlDirection] = useState(settings.direction === 'rtl');
  const [currentOrganization, setCurrentOrganization] = useState('');

  const fetchOrganizations = async () => {
      await  Users.getLeadTypeOrganisations({organisations_id: appSettings.get_organization()}).then(response => {
          if(parseInt(response.data.error) === 801){
              toast("error")
              fetchOrganizations();
          }else{
              setOrganizations(response.data.data)
          }
      })
  }
  const handleSwitchTheme = () => {
    saveSettings({
      ...settings,
      theme: settings.theme === 'light' ? 'dark' : 'light'
    });

    setDarkMode(settings.theme === 'light');
  };

  const handleSwitchDirection = () => {
    saveSettings({
      ...settings,
      direction: settings.direction === 'ltr' ? 'rtl' : 'ltr'
    });

    setRtlDirection(settings.direction === 'rtl');
  };

  const handleOrganizationChange = () => {
      fetchOrganizations();
      navigate('/dashboard');
  };

  const handleLogOut = () => {
      auth.logout();
  }

  useEffect(()=>{
  },[])

  return (
   <>
   </>
  );
};
