import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, Card, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import TextInput from '../../../form/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { Task } from '../../../../api/Endpoints/Task';
import { ContentCopy, CopyAll } from '@mui/icons-material';
import { DotSpinner } from '@uiball/loaders';
import { DataGrid } from '@mui/x-data-grid';

const useStyles = makeStyles({
    customTextField: {
        height: '10px', // Adjust the height as needed
    },
});

const OpenTask = (props) => {

    const classes = useStyles();

    const validationSchema = yup.object().shape({
        activity: yup.string().required('Activity is required'),
        // hours: yup.number(),
        // hours: yup.number(),
        // Add validation rules for other fields if needed
    });
    const formRef = useRef();
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) });
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [ClientID, setClientID] = useState(0)
    const [deleteId, setDeleteId] = useState(false)
    const [tasks, setTasks] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // You can adjust the number of rows per page as needed

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset the page to the first page when changing rows per page
    };
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    // const handlePageChange = (page) => {
    //     props.setPage(page)
    // }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleDelete = (id) => {
        setDeleteId(id)
    }
    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }


    const handleCreateNew = () => {

    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(async () => {

    }, [searchKeyword, listType, pageNumber])

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }


    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const viewTabs = (id) => {
        setClientID(id)
        setTabs(true)
    }

    const copyTask = (data) => {
        // console.log(data);
        sessionStorage.setItem('workTitle', data.title)
        props.setTasks(data)
        props.setTab(0)
    }
    const columns = [
        {
            field: 'title',
            width: 300,
            headerClassName: 'custom-header',
        },
        {
            field: 'copyButton',
            width: 50,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerClassName: 'custom-header',
            renderCell: (params) => (
                <a onClick={() => copyTask(params.row)} style={{ marginLeft: 4, cursor: 'pointer' }}>
                    <ContentCopy color='primary' />
                </a>
            ),
        },
    ];



    useEffect(() => {
        // props.setTasks(data)
        if (props?.open) {
            props.fetchTask()
        }
    }, [props?.open])


    return (
        <>
            <TableContainer component={Paper} style={{ marginTop: '10px', overflowY: 'scroll', height: 315, maxHeight: 315 }}>
                <Table>

                    <TableBody>

                        {
                            props.tasks?.data?.map((task) => (
                                <TableRow style={{ border: '1px solid #ccc', }}>
                                    <TableCell style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width={'93%'} height={40}>
                                        <a style={{ paddingLeft: 5 }}>
                                            {task.title}
                                        </a>
                                    </TableCell>
                                    <TableCell style={{ borderBottom: '1px solid #ccc', textAlign: 'center' }}>
                                        <a onClick={() => copyTask(task)} style={{ marginLeft: 4, cursor: 'pointer' }}>
                                            <ContentCopy color='primary' />
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))
                        }

                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={props.tasks.total}
                    rowsPerPage={15}
                    page={props.page}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[]}
                    labelRowsPerPage="" // This will hide the "Rows per page" label
                    SelectProps={{ native: true }} // This will hide the select dropdown
                />
            </TableContainer>
        </>

    );
};

export default OpenTask;
