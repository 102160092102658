import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Button, Grid, IconButton } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

// CSS
const linkStyle = {
    textDecoration: 'none',
    color: 'rgb(0,158,102)',
};

const PdfPopup = ({ documentURL, fileName, EditID }) => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            {documentURL || EditID ? (
                <>
                    <IconButton
                        onClick={openModal}
                        startIcon={<RemoveRedEyeIcon style={{ color: 'blue', height: 19 }} />}
                        edge="end"
                    >
                        <RemoveRedEyeIcon />
                    </IconButton>

                    <Modal
                        open={isOpen}
                        onClose={closeModal}
                        aria-labelledby="pdf-modal-title"
                        aria-describedby="pdf-modal-description"
                    >
                        <div>
                            <iframe
                                src={`https://dashboard.omnisellcrm.com/${documentURL}`}
                                title={fileName}
                                width="100%"
                                height="500px"
                            ></iframe>
                            <Grid width={'95%'} display={'flex'} justifyContent={'end'}>
                                <Button variant="contained" color="error" onClick={closeModal}>
                                    Close
                                </Button>
                            </Grid>
                        </div>
                    </Modal>
                </>
            ) : (
                null
            )
            }
        </>
    );
};

export default PdfPopup;
