import React, { useEffect, useState } from "react";
import { useAppSettings } from "../../../hooks/use-app-settings";
import moment from "moment";

import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import PageHeader from "../common/page-header";
import { Employee } from "../../../api/Endpoints/Employee";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Users } from "../../../api/Endpoints/Users";
const Index = (props) => {
  //Date picker state
  const [value, setValue] = useState(dayjs(new Date()));

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => <>{params.value}</>,
    },
  ];

  const [list, setList] = useState([]);
  const [listNotSignedIn, setListNotSignedIn] = useState([]);
  const [listType, setListType] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
  const [userID, setUserID] = useState();
  const [showList, setShowList] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleListingSearchChange = (keyword) => {
    setSearchKeyword(keyword);
    setPageNumber(0);
  };
  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handleLeadClick = (leadId) => {
    props.onPreQualifierIdChange(leadId);
    setSelectedPreQualifierId(leadId);
  };

  const fetchTable = () => {
    setLoading(true);
    Employee.getAttendanceList({
      from: value.format("DD-MM-YYYY 00:00:00"),
      to: value.format("DD-MM-YYYY 23:59:59"),
      user_id: userID,
    }).then((response) => {
      setList(response.data.data);
      setShowList(true);
      setLoading(false);
    });

    Employee.getNotSignedInList({
      date: value.format("DD-MM-YYYY 00:00:00"),
    }).then((response) => {
      setListNotSignedIn(response.data.data);
      setLoading(false);
    });
  };
  const handleCreateNew = () => {};

  const fetchUser = async () => {
    setLoading(true);
    const accessToken = window.localStorage.getItem("accessToken");
    const user = await Users.me(accessToken);
    setUserID(user.data.data.id);
    Employee.getAttendanceList({
      from: value.format("DD-MM-YYYY 00:00:00"),
      to: value.format("DD-MM-YYYY 23:59:59"),
      user_id: user.data.data.id,
    }).then((response) => {
      setList(response.data.data);
      setShowList(true);
      setLoading(false);
    });
  };

  useEffect(async () => {
    // fetchUser()
    fetchTable();
  }, [searchKeyword, listType, pageNumber, value, userID]);
  // useEffect(async () => {
  //     fetchUser()
  //     // fetchTable();
  // }, [])
  return (
    <>
      <PageHeader
        title={"Daily attendance " + value.format("DD-MM-YYYY")}
        onCreateNew={handleCreateNew}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          disableFuture
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select date"
              style={{ width: "200px", marginLeft: 13, marginTop: 5 }}
            />
          )}
          onChange={(newValue) => setValue(newValue)}
          inputFormat="DD-MM-YYYY"
        />
      </LocalizationProvider>

      <Card sx={{ m: 2 }} variant="outlined">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {showList && (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Sign in</TableCell>
                  <TableCell>Sign Out</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.name} </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      {moment(row.created_at).format("hh:mm A")}
                    </TableCell>
                    <TableCell>
                      {row.logged_out_time
                        ? moment(row.logged_out_time).format("hh:mm A")
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>

      <Card sx={{ m: 2 }} variant="outlined">
        <TableContainer component={Paper}>
          <Typography variant={"overline"} sx={{ pl: 2 }}>
            Not signed in
          </Typography>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listNotSignedIn?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.name} </TableCell>
                  <TableCell>{row.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default Index;
