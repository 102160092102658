import { Card,  Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";


import React from 'react'
import CreateIteams from "./Designs";




const rows = [
    {
        id: 1,
        name: 'test',
        facebook: "testtwo",
        description: "testdescription",
        instagram: 'testfour',
        twitter: "testfive",
        linkdlin: "testsix",
        youtub: "testeight",
        facebookad: "testnine",
        instagramad: "testten",
        twitterad: "testeleven",
        Linkdlinad: "testtweleve",
        youtubad: "testthirteen",
        googlebusiness: "testfourteen",
        approvalstatus: "testfifteen",
        redytopublish: "readytopublish",
        sechuldedate: "3/5/2021 10:00"
    },

    // Add more rows as needed...
];
const columns = [
    { field: 'name', headerName: 'Title', width: 200 },
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'facebook', headerName: 'Facebook', width: 150 },
    { field: 'instagram', headerName: 'Instagram ', width: 200 },
    { field: 'twitter', headerName: 'Twitter ', width: 150 },
    { field: 'linkdlin', headerName: 'LinkedIn ', width: 150 },
    { field: 'youtub', headerName: 'YouTube ', width: 150 },
    { field: 'facebookad', headerName: 'Facebook Ad', width: 150 },
    { field: 'instagramad', headerName: 'Instagram Ad', width: 150 },
    { field: 'twitterad', headerName: 'Twitter Ad ', width: 150 },
    { field: 'Linkdlinad', headerName: 'LinkedIn Ad ', width: 150 },
    { field: 'youtubad', headerName: 'YouTube Ad ', width: 150 },
    { field: 'googlebusiness', headerName: 'Google_Business', width: 150 },
    { field: 'approvalstatus', headerName: 'Approval_Status', width: 150 },
    { field: 'redytopublish', headerName: 'Ready To Publish', width: 150 },
    { field: 'sechuldedate', headerName: 'Scheduled Date Time', width: 150 },



    // Add more columns as needed...                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                q

];

function Items() {


    return (
        <div>
            <Grid display={'flex'} width={"96%"} justifyContent={'end'} >
                <CreateIteams />
            </Grid>

            <Card sx={{ m: 2 }} variant="outlined">

                <DataGrid
                    paginationMode="server"
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowHeight={20}
                    rowsPerPageOptions={[10]}
                    autoHeight
                    density={'comfortable'}
                />

            </Card>
        </div >
    )
}

export default Items
