import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Popover, MenuItem, Grid, Typography, Tooltip } from '@mui/material';
import AssignTask from './AssignTask';
import EditPopup from './edit';

import { DotSpinner } from '@uiball/loaders';
import Create from './create';
import { MeetingIdContext } from '../../../../../contexts/MeetingID/meetingID';
import { Meetings } from '../../../../../api/Endpoints/Meeting';
import Delete from '../../../common/Popup/Delete';
import { MoreOptionsDropdown } from '../../../common/MoreAction/actionButton';
import Edit from './edit';
import AssignTaskFromMinutes from './AssignTask';
import { AddTask, CheckCircle, CheckCircleOutline, NotInterested, PanoramaFishEye, PersonAddAlt1 } from '@mui/icons-material';
import AssignTo from './AssignTo';
import toast from 'react-hot-toast';
import './MinutesIndex.css'
import moment from 'moment';
import { Task } from '../../../../../api/Endpoints/Task';
import TaskIcon from '@mui/icons-material/Task';
import BasicTabs from '../../../tasks/TabMenus';

function Agenda({ projectID, meetingID }) {
    // const { MeetingID } = MeetingIdContext();
    const [list, setList] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }
    const [loading, setLoading] = useState(false);
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [editId, setEditId] = useState();
    const [ID, setID] = useState(0);
    const [TaskPopup, setTaskPopup] = useState(false);
    const [showTaskIcon, setShowTaskIcon] = useState(true);
    const [AssignID, setAssignID] = useState(0);
    const [OpenEditPopup, setopenEditpopup] = useState(false);
    const [OpendetailsPopup, setOpenDetailPopup] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [minuteId, setMinuteId] = useState()
    const [associatedTask, setAssociatedTask] = useState()
    const [assignToId, setAssignToId] = useState(false)
    const [meeting_ID, setmeeting_ID] = useState()
    const [minutesDetails, setminutesDetails] = useState()
    const [minuteTask, setMinuteTask] = useState([])
    const [taskID, setTaskID] = useState()

    const containerRef = useRef(null);

    const dummy = [
        { title: 'Sample Title', added_by: 'User 1' },
        { title: 'Sample Title', added_by: 'User 2' },
        { title: 'Sample Title', added_by: 'User 3' },
        { title: 'Sample Title', added_by: 'User 4' },
        { title: 'Sample Title', added_by: 'User 5' },


    ]

    useEffect(() => {
        // fetchList();
        setShowTaskIcon(true);
    }, [projectID]);

    // console.log(projectID);

    const fetchList = async () => {
        setLoading(true);
        try {
            const response = await Meetings.getMeetingMinutes({ meetings_id: meetingID });
            if (response.data.status === 'success') {
                console.log(response.data.data);
                setList(response.data.data);
                const taskId = response.data.data
                    .filter(items => items.taskable)
                    .map(items => items.taskable.id);
                console.log(taskId);

                const taskDetailsPromises = taskId.map(async element => {
                    console.log(element);
                    const taskOwner = await Task.getTaskDetails({ id: element });
                    return taskOwner.data.data;
                });

                const minuteTaskData = await Promise.all(taskDetailsPromises);
                console.log(minuteTaskData);

                // Now, you can update minuteTask with the collected data
                setMinuteTask(minuteTaskData);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    // useEffect(async () => {
    //     let taskOwner = await Task.getDetails({ id: taskId[0] })
    //     console.log(taskOwner);
    // }, [taskId])


    const changeMinuteStatus = (status) => {
        let dataToSubmit = {
            id: minuteId,
            status: status
        }

        Meetings.changeMinutesStatus(dataToSubmit).then((response) => {
            console.log(response);
            toast.success(response.data.message)
            fetchList()
        })
    }

    const DetailView = (id) => {
        setEditId(id);
        setopenEditpopup(true);
    };


    const handleDeleteAlert = (id) => {
        setID(id);
        setDeletePopup(true);
    };

    const handleEditClick = (id) => {
        setEditId(id);
        setopenEditpopup(true);
    };


    const handleOpenPopover = (event, id, taskable_id) => {
        // if(taskable_id == null){
        setAnchorEl(event.currentTarget);
        // }
        setMinuteId(id);

    };
    const handleClosePopover = () => {
        setAnchorEl(null);
    };
    const handlePopoverChange = (status) => {
        console.log(status)
        // setminuteStatus(data)
        changeMinuteStatus(status)
        handleClosePopover()
    };
    const id = anchorEl ? 'popover-basic' : undefined;

    const getTaskID = (id) => {
        console.log(id);
    }

    const assignTask = (data) => {
        console.log(data);
        setminutesDetails(data)
        setTaskPopup(data.id)
        setmeeting_ID(data.meetings_id)
    }
    // console.log(projectID);

    const viewTask = (id) => {
        console.log(id);
        setTaskID(id)
    }

    // useEffect(() => {
    //     // Scroll to the bottom after the list is updated
    //     if (containerRef.current) {
    //         containerRef.current.scrollTop = containerRef.current.scrollHeight;
    //     }
    // }, [list]); // Assuming `list` is the state or variable that you update after form submission



    return (
        <div>
            {
                taskID &&
                <BasicTabs id={taskID} handleClose={setTaskID} />
            }

            {showDeletePopup && (
                <Delete
                    ID={ID}
                    setID={setID}
                    setDeletePopup={setDeletePopup}
                    Callfunc={() => fetchList()}
                    url=""
                    title="Agenda "
                />
            )}
            {TaskPopup && (
                <AssignTaskFromMinutes fetchList={() => fetchList()} projectID={projectID} minutesDetails={minutesDetails} meetings_id={meeting_ID} minuteID={TaskPopup} getTaskID={getTaskID} key={refresh} onNew={handleCreate} onUpdate={handleUpdate} setOpen={setTaskPopup} editId={meetingID} />
            )}
            {
                assignToId &&
                <AssignTo Open={assignToId}
                    setOpen={setAssignToId}
                    editId={assignToId}
                    setEditId={setAssignToId}
                    fetchList={() => fetchList()}
                />
            }
            {OpenEditPopup && (
                <EditPopup
                    Open={OpenEditPopup}
                    setOpen={setopenEditpopup}
                    editId={editId}
                    setEditId={setEditId}
                    fetchList={() => fetchList()}
                />
            )}

            {OpendetailsPopup && (
                <Edit Open={OpendetailsPopup} setOpen={setOpenDetailPopup} editId={ID} setEditId={setID} ViewType={true} />
            )}

            {/* {!loading ? ( */}
            <>
                <div style={{ maxHeight: '400px', overflow: 'auto' }} ref={containerRef}>
                    <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'70%'}>Title</TableCell>
                                    <TableCell sx={{ textAlign: 'start' }}>Added by</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>


                                {
                                    dummy?.map((item) => (
                                        <TableRow className='hover-row' key={item.id}>
                                            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                                                {item?.title}
                                            </TableCell>
                                            <TableCell >
                                                {item?.added_by}
                                            </TableCell>
                                            <TableCell>
                                                <MoreOptionsDropdown
                                                    params={item}
                                                    setEditId={setEditId}
                                                    setID={setID}
                                                    setDeletePopup={setDeletePopup}
                                                    setTaskPopup={setTaskPopup}
                                                    TaskPopup={TaskPopup}
                                                    showTaskIcon={showTaskIcon}
                                                    setAssignID={setAssignID}
                                                    setOpen={setopenEditpopup}
                                                    onEditClick={() => handleEditClick(item.id)}
                                                    onDeleteClick={() => handleDeleteAlert(item.id)}
                                                />
                                            </TableCell>

                                        </TableRow>
                                    ))
                                }


                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div style={{ width: 300 }}>
                    <Create DetailView={DetailView} meetingID={meetingID} fetchList={() => fetchList()} /> {/* Set Create component to full width */}
                </div>
            </>
            {/* ) 
            : (
                <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'30%'} mt={10}>
                    <DotSpinner size={40} speed={0.9} color="blue" />
                </Box>
            )
            } */}
        </div >
    );
}

export default Agenda;
