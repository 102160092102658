import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    TextareaAutosize,
    Select,
    TextField,
    Slide, Typography
} from "@mui/material";
import MonthPickerComponent from '../../form/Monthpicker';
import { DatePicker } from '@mui/lab';
import * as yup from "yup";
import dayjs from 'dayjs';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from '../../form/TextInput';
import DateInput from '../../form/DateInput';
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import SelectX from '../../form/SelectX';
import moment from "moment";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import { Account } from "../../../api/Endpoints/Account";
import CurrentDateInput from '../../form/CurrenDateInput';
import { Project } from "../../../api/Endpoints/Project";
import ReactSelector from 'react-select';
import { faHouseFloodWaterCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Employee } from '../../../api/Endpoints/Employee';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({


    title: yup.string().required(),
    description: yup.string(),
    start_date: yup.string(),
    end_date: yup.string(),
    due_date: yup.string(),
    Owner_Id: yup.object(),
    assigned_by_id: yup.object(),
    projects_id: yup.object().shape({
        id: yup.string().required("Please choose a project").typeError("Please choose a project")
    }).required().typeError("Please choose a project"),
    // reviewer_id: yup.object().shape({
    //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    // }).required().typeError("Please choose a reviewer"),
})


const CurrentStatus = [
    { value: "Not Started", label: 'Not Started' },
    { value: "Development In Progress", label: 'Development In Progress' },
    { value: "Under Maintenance", label: 'Under Maintenance' },
    { value: "Testing In Progress", label: 'Testing In Progress' },
    { value: "Completed", label: 'Completed' },
    { value: "Live ", label: 'Live ' },
];

const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({})
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleClose = () => {
        props.setEditId(undefined)
        setOpen(false);
    };

    const handleClickOpen = () => {
        props.onNew()
    };
    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchClient = (e) => {
        let cleint_id = watch('client_id')
        return Account.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }
    const fetchProjects = async (e) => {
        const client_id = watch('client_id');
        console.log("this is clietn id", client_id.id);
        try {
            const response = await Project.get({ accounts_id: client_id.id });
            console.log("this is project response", response);
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        } catch (error) {
            // Handle error
            console.error(error);
            return [];
        }
    };

    const prioriyTask = () => {

    }

        ;


    const handlePriorityChange = (id) => {
        setSelectedPriority(id);
    }


    const approvelstatus = [
        { value: "New", label: 'New' },
        { value: 'Manager Approval Pending', label: 'Manager Approval Pending' },
        { value: 'Manager_Approved', label: 'Manager Approved' },
        { value: 'finance approved', label: 'Finance Approved' },
        { value: 'User Action Required', label: 'User Action Required' },
        { value: 'paid', label: 'Paid' },
        { value: 'On Hold', label: 'On Hold' },
        { value: 'rejected', label: 'Rejected' },
    ];


    const yearList = [];
    for (let year = 2020; year <= 2030; year++) {
        yearList.push({ value: year.toString(), label: year.toString() });
    }


    const Months = [
        {
            value: "January ", label: "January "
        },
        {
            value: "February", label: "February"
        },
        {
            value: "March", label: "March"
        },

        {
            value: "April", label: "April"
        },
        {
            value: "May", label: "May"
        },
        {
            value: "June", label: "June"
        },
        {
            value: "July", label: "July"
        },
        {
            value: "August", label: "August"
        },
        {
            value: "September", label: "September"
        },
        {
            value: "October", label: "October"
        },
        {
            value: "November", label: "November"
        },
        {
            value: "December", label: "December"
        },



    ];






    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })


        let date = moment(data.start_date).format('YYYY-MM-DD');
        let enddate = moment(data.end_date).format('YYYY-MM-DD');
        let duedate = moment(data.due_date).format('YYYY-MM-DD');

        let dataToSubmit = {
            id: props.editId,
            title: data.project_name,
            description: data.description,
            start_date: date,
            due_date: duedate,
            account: data.client_id?.id,
            end_date: enddate
        };

        console.log(dataToSubmit);
        let action;

        if (props.editId > 0) {
            console.log("dataToSubmit", dataToSubmit);
            action = Account.add(dataToSubmit)
        } else {
            action = Account.add(dataToSubmit)
        }
        action.then(response => {
            console.log("this is response data", response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }



    const fetchWorkRequestDetails = async () => {
        setLoading(true);


        setLoading(false);
    };


    const fetchEmployees = (e) => {
        let project = watch('projects_id');
        return Employee.get({ keyword: e, projects_id: project?.id }).then(response => {

            if (typeof response.data.data.data !== "undefined") {
                console.log(response.data.data.data);
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            setOpen(true);
        } else if (Number(props.editId) === 0) {

            setOpen(true);
        }

    }, [])

    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('projects_id')])



    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Create Department"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMountedvvvvvvvv
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit a work Request" : "Create Department"}</DialogTitle>
                <DialogContent>
                    {!loading ?
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />

                            <Grid container spacing={2} sx={{ display: 'flex', mt: 1 }}>
                                <Grid item xs={12}>
                                    <TextInput
                                        control={control}
                                        name="department_Name"
                                        label="Department Name"
                                        value={watch('department_Name')}
                                    />
                                </Grid>

                                <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                                    <InputLabel sx={{
                                        color: "black",
                                        pb: 1
                                    }}>Description </InputLabel>
                                    <TextField
                                        {...register('description')}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        sx={{ width: '100%', }}
                                        required
                                    />
                                </Grid>


                                {/* <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                                    <InputLabel sx={{
                                        color: "black",
                                        pb: 1,
                                    }}>Campaign Id</InputLabel>
                                    <ReactSelector


                                        name='status'
                                        isClearable={true}
                                        defaultValue={(watch('status'))}
                                        onChange={(selectedOption) => setValue('status', selectedOption.value)}
                                    />


                                </Grid> */}


                                <Grid sx={{ p: 1 }} mb={5} item xs={12}>
                                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                        :
                        <>Loading...</>
                    }

                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        </div >
    );
};

export default Create
