import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Backdrop,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Project } from "../../../api/Endpoints/Project";
import { Task } from "../../../api/Endpoints/Task";
import ReactSelector from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Employee } from "../../../api/Endpoints/Employee";
import { Edit, PieChart } from "@mui/icons-material";
import { UserIdContext } from "../../../contexts/UserID/UserID";
import TaskMasterStatus from "../tasks/statusModal";
import BasicTabs from "../tasks/TabMenus";
import ChartModal from "../tasks/ChartModal";
import Create from "../tasks/create";
import CreateRequirement from "./create";

const scheme = yup.object().shape({
  // title: yup.string().required(),
  // description: yup.string(),
  // start_date: yup.string(),
  // end_date: yup.string(),
  // due_date: yup.string().required(),
  // assigned_to_id: yup.object().required(),
  // assigned_by_id: yup.object(),
  // projects_id: yup.object().required()
  // reviewer_id: yup.object().shape({
  //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
  // }).required().typeError("Please choose a reviewer"),
});

const CreativeRquirmentsIndex = (props) => {
  const { UserID, UserName, ID } = UserIdContext();
  const {
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(scheme) });
  const [editId, setEditId] = useState();
  const [refresh, setRefresh] = useState(0);
  const [statusID, setStatusID] = useState();
  const [statusOpen, setStatusOpen] = useState(false);
  const [chartOpen, setChartOpen] = useState(false);
  const [chartID, setChartID] = useState();
  const [checkLists, setcheckLists] = useState([]);

  const handleEdit = (id) => {
    setRefresh(Math.random);
    setEditId(parseInt(id));
  };
  const handleDelete = (id) => {};

  const projectClickFilter = async (data) => {
    fetchProjects(data?.name);
    setSelectedProjectID(data);
    setValue("projects", data?.name || "");
  };
  const assignToClickFilter = async (data) => {
    fetchEmployees(data?.name);
    setSelectedAssignedToID(data?.id);
    setValue("assigned_to", data?.name || "");
  };

  const handleStatusChange = (data) => {
    setStatusOpen(true);
    setStatusID(data);
  };

  const viewChart = (id, checklist) => {
    setChartID(id);
    setcheckLists(checklist);
    setChartOpen(true);
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 280,
      renderCell: (params) => (
        <p
          style={{ cursor: "pointer" }}
          onClick={() => DetailViewOpen(params.row.id)}
        >
          {params.value}
        </p>
      ),
    },
    {
      field: "chart",
      width: 25,
      renderCell: (params) => {
        return params.row.check_lists?.length > 0 ? (
          <a
            onClick={() => viewChart(params.id, params.row?.check_lists)}
            style={{ cursor: "pointer" }}
          >
            <PieChart
              sx={{
                color:
                  params.row?.check_lists?.length ===
                  params.row.check_lists?.filter(
                    (item) => item.is_completed === 1
                  ).length
                    ? "green"
                    : "orange",
              }}
            />
          </a>
        ) : (
          <Tooltip title="No Checklist for this Task">
            <a>
              <PieChart
                sx={{
                  color: "grey",
                }}
              />
            </a>
          </Tooltip>
        );
      },
    },
    {
      field: "project",
      headerName: "Project name",
      width: 150,
      renderCell: (params) => (
        <p
          onClick={() => projectClickFilter(params.value)}
          style={{ cursor: "pointer" }}
        >
          {params.value?.name}
        </p>
      ),
    },
    {
      field: "assigned_by",
      headerName: "Assigned by",
      width: 150,
      valueGetter: (params) => params.row?.assigned_by_user,
      renderCell: (params) => <Button>{params.value?.name}</Button>,
    },
    {
      field: "assigned_to",
      headerName: "Assigned to",
      width: 150,
      valueGetter: (params) => params.row?.assigned_to_user,
      renderCell: (params) => (
        <Button
          onClick={() => assignToClickFilter(params.row?.assigned_to_user)}
        >
          {params.value?.name}
        </Button>
      ),
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 90,
      valueGetter: (params) => params.row?.priority,
      renderCell: (params) => (
        <a>
          {params.value == 1 && "Critical"}
          {params.value == 2 && "High"}
          {params.value == 3 && "Medium"}
          {params.value == 4 && "Low"}
        </a>
      ),
    },

    // {
    //     field: 'reviewer', headerName: 'Reviewer', width: 150,
    //     valueGetter: (params) => params.row?.reviewer,
    //     renderCell: (params) => <Button>{params.value?.name}</Button>,
    // },
    // {
    //     field: 'start_date', headerName: 'Start date', width: 150, renderCell: (params) => {
    //         console.log("This is data", params);
    //         if (params) {

    //             return moment(params.value).format('MMM Mo dddd')
    //         } else {
    //             return "Not started";
    //         }
    //     }
    // },
    // {
    //     field: 'created_at', headerName: 'Created date', width: 150, renderCell: (params) => {
    //         // console.log("This is data", params);
    //         // if (params) {

    //         return moment(params.value).format('DD MMM YYYY')
    //         // } else {
    //         //     return "Not started";
    //         // }
    //     }
    // },
    {
      field: "due_date",
      headerName: "Due date",
      width: 100,
      renderCell: (params) => {
        return params.row.due_date ? (
          <p
            style={{
              color:
                moment(params.value).isBefore(moment(), "day") &&
                params.row.status !== "Completed"
                  ? "red"
                  : "black",
            }}
          >
            {moment(params.value).format("DD-MM-YYYY")}
          </p>
        ) : null;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          // Currently Users who created,reviewer and to whom assign_to. Not Project Manager(Backend updation Pending)
          UserID == params.row.assigned_to_id ||
            UserID == params.row.reviewer_id ||
            UserID == params.row.created_by ||
            UserID ==
              params.row.project?.members?.find(
                (item) => item.is_project_owner == 1
              )?.user?.id ? (
            <a
              style={{ cursor: "pointer" }}
              onClick={() => handleStatusChange(params.row)}
            >
              {params.value}
            </a>
          ) : (
            <a>{params.value}</a>
          )
        );
      },
    },
    {
      field: "action",
      headerName: "",
      width: 80,
      valueGetter: (params) => params.row,
      renderCell: (params) => {
        let id = params.value?.id;
        return (
          // Currently Users who created,reviewer. Not Project Manager(Backend updation Pending)
          UserID == params.row.reviewer_id ||
            UserID == params.row.created_by ||
            UserID ==
              params.row.project?.members?.find(
                (item) => item.is_project_owner == 1
              )?.user?.id ? (
            <a
              style={{ cursor: "pointer" }}
              variant="outlined"
              // className='task-btn'
              size={"small"}
              onClick={() => handleEdit(id)}
            >
              <Edit color="primary" fontSize="small" />
            </a>
          ) : (
            <Tooltip title="No Permission to Edit">
              <a style={{ color: "grey" }} variant="outlined" size={"small"}>
                <Edit fontSize="small" />
              </a>
            </Tooltip>
          )
          // <a
          //     style={{ cursor: 'pointer' }}
          //     variant='outlined'
          //     // className='task-btn'
          //     size={'small'}
          // ><Edit color='primary' fontSize='small' />
          // </a>
        );
      },
    },
    // {
    //     field: 'delete', headerName: 'Delete', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
    //         let id = params.value?.id;
    //         return <><Button
    //             //  className='task-btn'
    //             variant='outlined'
    //             color='error'
    //             size={'small'}
    //             onClick={() => handleDelete(id)}>Delete</Button>
    //         </>;
    //     }
    // },
  ];

  const [list, setList] = useState([]);
  const [listType, setListType] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [detailViewId, setDetailViewId] = useState(false);
  const [selectedProjectID, setSelectedProjectID] = useState(null);
  const [selectedAssignedByID, setSelectedAssignedByID] = useState(null);
  const [selectedReviewer, setSelectedReviewer] = useState(null);
  const [selectedAssignedToID, setSelectedAssignedToID] = useState(ID);
  const [selectStatus, setselectStatus] = useState(null);
  const [overdue, setOverdue] = useState(false);

  const handleCheckboxChange = (event) => {
    setOverdue(event.target.checked);
    if (event.target.checked) {
      setselectStatus(["Not Started", "In Progress"]);
    } else {
      setselectStatus();
    }
  };

  const DetailViewOpen = (id) => {
    setDetailViewId(id);
  };

  const handleListingSearchChange = (keyword) => {
    setSearchKeyword(keyword);
    setPageNumber(0);
  };
  const handlePageChange = (event, page) => {
    setPageNumber(page);
  };

  const handleLeadClick = (leadId) => {
    props.onPreQualifierIdChange(leadId);
    setSelectedPreQualifierId(leadId);
  };

  const fetchTable = () => {
    console.log(selectedProjectID);
    setLoading(true);
    console.log(watch("projects"));
    Task.get({
      related_type: "creative_calendar_post",
      projects_id: selectedProjectID?.id,
      assigned_by: selectedAssignedByID?.id,
      assigned_to: selectedAssignedToID,
      keyword: searchKeyword,
      status: selectStatus,
      page: parseInt(pageNumber) + 1,
      limit: 25,
    })
      .then((response) => {
        console.log(response.data.data);
        setList(response.data.data);
        setLoading(false);
        setValue("projects", selectedProjectID?.name);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const selectProject = (project_id) => {
    setValue("projects", project_id?.name || "");
    setSelectedProjectID(project_id);
    fetchProjects();
  };

  const selectAssignedBy = (assign_to_id) => {
    setSelectedAssignedByID(assign_to_id);
    setValue("assign_by_id", assign_to_id?.name || "");
  };

  const selectAssignedTo = (assign_to) => {
    setSelectedAssignedToID(assign_to?.id);
    setValue("assigned_to", assign_to?.name || "");
    fetchEmployees();
  };

  // const selectReviewer = (reviewer) => {
  //     setSelectedReviewer(reviewer?.id)
  //     setValue('reviewer', reviewer?.name || "")
  // }

  const selectStatusFunc = (status) => {
    setselectStatus(status?.name);
    setValue("status", status?.name || "");
  };

  const handleUpdate = () => {
    setPageNumber();
    setPageNumber(0);
  };

  const handleCreate = () => {
    setEditId(0);
    setRefresh(Math.random);
  };

  const handleSearchEntry = (event) => {
    setSearchKeyword(event.target.value);
  };

  const fetchProjects = (e) => {
    Project.get({ keyword: e }).then((response) => {
      // console.log(response);
      if (typeof response?.data?.data?.data !== "undefined") {
        setProjects(response.data.data.data);
      } else {
        return [];
      }
    });
  };

  const fetchEmployees = (e) => {
    Employee.get({ keyword: e, limit: 100 }).then((response) => {
      if (typeof response.data.data.data !== "undefined") {
        setEmployees(response.data.data.data);
      } else {
        return [];
      }
    });
  };

  const listStatus = [
    { id: 1, name: "Not Started", value: "Not Started" },
    { id: 2, name: "In progress", value: "In progress" },
    { id: 3, name: "Review pending", value: "Review pending" },
    { id: 4, name: "Review Failed", value: "Review Failed" },
    { id: 5, name: "Completed", value: "Completed" },
  ];

  const handleChartClose = () => {
    setChartID(false);
    setChartOpen(false);
  };

  useEffect(() => {
    setValue("assigned_to", UserName);
    setSelectedAssignedToID(ID);
  }, [UserName, ID]);

  useEffect(() => {
    // searchEmployee()
    fetchProjects();
    fetchEmployees();
  }, []);

  // console.log(selectedAssignedToID);
  // console.log(UserName);
  // console.log(ID);

  useEffect(() => {
    fetchTable();
  }, [
    searchKeyword,
    pageNumber,
    selectedProjectID,
    selectedAssignedByID,
    selectedAssignedToID,
    // selectedReviewer,
    selectStatus,
    UserID,
  ]);

  return (
    <>
      {detailViewId && (
        <BasicTabs
          fetch={fetchTable}
          id={detailViewId}
          handleClose={setDetailViewId}
          handleEdit={handleEdit}
          setChartOpen={handleChartClose}
        />
      )}
      {statusID && (
        <TaskMasterStatus
          onUpdate={fetchTable}
          setDataSet={setStatusID}
          dataSet={statusID}
          setOpen={setStatusOpen}
          Open={statusOpen}
        />
      )}
      {
        <ChartModal
          checkLists={checkLists}
          DetailViewOpen={DetailViewOpen}
          id={chartID}
          setID={setChartID}
          open={chartOpen}
          setOpen={setChartOpen}
        />
      }
      {/* <button onClick={()=>setValue('assigned_to',UserName)}>click</button> */}

      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <PageHeader title={"Creative Requirements"} />

        <Grid display={"flex"} mr={3}>
          <TextField
            style={{ width: 300, marginRight: 10 }}
            size="small"
            id="outlined-name"
            label="Search Tasks"
            onChange={handleSearchEntry}
          />

          <a hidden>
            <Create
              setDetailViewId={setDetailViewId}
              detailViewId={detailViewId}
              fetchTable={fetchTable}
              key={refresh}
              onNew={handleCreate}
              onUpdate={handleUpdate}
              editId={editId}
            />
          </a>
        </Grid>
      </Grid>

      <Grid
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        variant="outlined"
      >
        <Grid item display={"flex"}>
          <Grid mr={2} sx={{ width: 180 }}>
            {/* <InputLabel sx={{
                                color: "black",
                                fontSize: "14px",
                                pb: .5
                            }}>Choose Project</InputLabel> */}
            <ReactSelector
              onInputChange={fetchProjects}
              placeholder="Project"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={projects}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              // inputValue={inProject}
              value={projects.find(
                (options) => options.name === watch("projects")
              )}
              name="projects"
              isClearable
              defaultValue={watch("projects")}
              onChange={(selectedOption) => selectProject(selectedOption)}
            />
          </Grid>

          <Grid mr={2} sx={{ width: 160 }}>
            {/* <InputLabel sx={{
                                color: "black",
                                fontSize: "14px",
                                pb: .5
                            }}>Choose Project</InputLabel> */}
            <ReactSelector
              onInputChange={fetchEmployees}
              placeholder="Assigned By"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={employees}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              // inputValue={inProject}
              value={employees.find(
                (options) => options.name === watch("assign_by_id")
              )}
              name="assign_by_id"
              isClearable
              defaultValue={watch("assign_by_id")}
              onChange={(selectedOption) => selectAssignedBy(selectedOption)}
            />
          </Grid>

          <Grid mr={2} sx={{ width: 160 }}>
            <ReactSelector
              onInputChange={fetchEmployees}
              placeholder="Assigned To"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={employees} // Ensure that this includes all employees, not just the initial 10.
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={employees.find(
                (options) => options.name === watch("assigned_to")
              )}
              name="assigned_to"
              isClearable
              defaultValue={watch("assigned_to")}
              onChange={(selectedOption) => selectAssignedTo(selectedOption)}
            />
          </Grid>

          {/* <Grid mr={2} sx={{ width: 150 }}>
                        <ReactSelector
                            onInputChange={fetchEmployees}
                            placeholder="Reviewer"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={employees}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}

                            // inputValue={inProject}
                            value={
                                employees.find(options =>
                                    options.name === watch('reviewer')
                                )
                            }
                            name='reviewer'
                            isClearable
                            defaultValue={(watch('reviewer'))}
                            onChange={(selectedOption) => selectReviewer(selectedOption)}
                        />
                    </Grid> */}

          <Grid mr={2} sx={{ width: 180 }}>
            <ReactSelector
              placeholder="Status"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={listStatus}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              // inputValue={inProject}
              value={listStatus.filter(
                (options) => options.name === watch("status")
              )}
              name="status"
              isClearable
              defaultValue={watch("status")}
              onChange={(selectedOption) => selectStatusFunc(selectedOption)}
            />
          </Grid>
        </Grid>
        <Grid sx={{ p: 2, display: "flex", alignItems: "center", marginLeft: 'auto' }} variant="outlined">
                <CreateRequirement/>
                </Grid>
      </Grid>

      <Card sx={{ m: 2, height: 550 }} variant="outlined">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {typeof list.data === "object" && (
          <DataGrid
            paginationMode="server"
            rows={list?.data}
            columns={columns}
            page={pageNumber}
            pageSize={25}
            rowHeight={20}
            rowsPerPageOptions={[25]}
            rowCount={list.total}
            onPageChange={handlePageChange}
            // autoHeight={true}
            density={"comfortable"}
          />
        )}
      </Card>
    </>
  );
};

export default CreativeRquirmentsIndex;
