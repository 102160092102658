import React, { useEffect, useState } from 'react';
import { useAppSettings } from "../../../hooks/use-app-settings";
import moment from "moment";
import { Backdrop, Button, Card, CircularProgress, TextField } from "@mui/material";
import { PreQualifiers } from "../../../api/Endpoints/PreQualifiers";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Account } from "../../../api/Endpoints/Account";
import toast from "react-hot-toast";
import Create from './create';
import { ClientReport } from '../../../api/Endpoints/ClientReport';
import DeleteMoadal from './deleteModal';
import Delete from '../common/Popup/Delete';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ClientsReport = (props) => {
    const appSettings = useAppSettings();


    const columns = [
        // { field: 'id', headerName: 'ID' },
        {
            field: 'report_name', headerName: 'Report Name', width: 150, renderCell: (params) => (
                <>
                    <Button
                        size="small"
                        style={{ marginLeft: 1 }} onClick={() => viewTabs(params.id)}>
                        {params.row.report_name}
                    </Button>
                </>
            )
        },
        { field: 'project', headerName: 'Porject Name', width: 300, renderCell: (params) => (params.row.project?.name) },
        { field: 'document', headerName: 'Document', width: 300, renderCell: (params) => <a target='_blank' href={`https://dashboard.omnisellcrm.com/${params?.row?.document}`}>{params.row.document.slice(43)}</a> },

        // { field: 'company_name', headerName: 'Company Name', width: 200 },
        // { field: 'remarks', headerName: 'remarks', width: 300 },
        // { field: 'created_at', headerName: 'Created On', width: 150, renderCell: (params) => { return moment(params.value).format('MMM Mo dddd') } },

        {
            field: 'Edit',
            headerName: 'Edit',
            width: 50,
            renderCell: (params) => (
                <>
                    <a variant="contained"
                        size="small"
                        style={{ marginLeft: 1 }} onClick={() => handleEdit(params.id)}>
                        <EditIcon fontSize='small' style={{ color: 'blue', cursor: 'pointer', marginRight: 15 }} />
                    </a>
                </>
            )
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            width: 100,
            renderCell: (params) => (
                <>
                    <a
                        variant="outlined"
                        color='error'
                        size="small"
                        style={{ marginLeft: 1 }} onClick={() => handleDelete(params.id)}
                    >
                        <DeleteIcon fontSize='small' style={{ color: 'red', cursor: 'pointer' }} />
                    </a>
                </>
            )
        }

    ]

    const [clientReport, setClientReport] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [ClientID, setClientID] = useState(0)
    const [deleteId, setDeleteId] = useState(false)
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleDelete = (id) => {
        setDeleteId(id)
    }
    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }
    const fetchClientReport = () => {
        setLoading(true);
        ClientReport.get({ keyword: searchKeyword, page: Number(pageNumber) + 1 }).then(response => {
            console.log(response);
            setClientReport(response.data.data);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })


    }

    const handleCreateNew = () => {

    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(async () => {
        fetchClientReport();
    }, [searchKeyword, listType, pageNumber])

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }


    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const viewTabs = (id) => {
        setClientID(id)
        setTabs(true)
    }

    return (
        <>
            {/* {Tabs ? (<BasicTabs setTabs={setTabs} ClientID={ClientID} />
            ) : ( */}

            {
                deleteId &&
                <Delete
                    ID={deleteId}
                    setID={setDeleteId}
                    setDeletePopup={setDeleteId}
                    Callfunc={() => fetchClientReport()}
                    url="/projects/client-reports/delete?id="
                    title="Report"
                />}

            <>
                <PageHeader title={"Client Reports"} onCreateNew={handleCreateNew} />

                <Card sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'space-between' }} variant="outlined" >

                    <TextField
                        style={{ width: 300, marginRight: 10 }}
                        size='small'
                        id="outlined-name"
                        label="Search Reports"
                        onChange={handleSearchEntry}
                    />
                    <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />

                </Card>
                <Card sx={{ m: 2 }} variant="outlined">
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    {typeof clientReport.data === "object" &&
                        <DataGrid
                            paginationMode="server"
                            rows={clientReport?.data}
                            columns={columns}
                            page={pageNumber}
                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            rowCount={clientReport.total}
                            onPageChange={handlePageChange}
                            autoHeight
                            density={"comfortable"}
                        />
                    }
                </Card>
            </>
            {/* )} */}

        </>

    );
};

export default ClientsReport;
