import React from 'react';

const WarningIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99999 13.2709C3.54082 13.2709 0.729156 10.4592 0.729156 7.00002C0.729156 3.54085 3.54082 0.729187 6.99999 0.729187C10.4592 0.729187 13.2708 3.54085 13.2708 7.00002C13.2708 10.4592 10.4592 13.2709 6.99999 13.2709ZM6.99999 1.60419C4.02499 1.60419 1.60416 4.02502 1.60416 7.00002C1.60416 9.97502 4.02499 12.3959 6.99999 12.3959C9.97499 12.3959 12.3958 9.97502 12.3958 7.00002C12.3958 4.02502 9.97499 1.60419 6.99999 1.60419Z" fill="#F8434E"></path>
            <path d="M9.16418 9.29253C9.08834 9.29253 9.01251 9.27503 8.94251 9.22836L7.13417 8.14919C6.68501 7.88086 6.35251 7.29169 6.35251 6.77253V4.38086C6.35251 4.14169 6.55084 3.94336 6.79001 3.94336C7.02918 3.94336 7.22751 4.14169 7.22751 4.38086V6.77253C7.22751 6.98253 7.40251 7.29169 7.58334 7.39669L9.39168 8.47586C9.60168 8.59836 9.66584 8.86669 9.54334 9.07669C9.45584 9.21669 9.31001 9.29253 9.16418 9.29253Z" fill="#F8434E"></path>
        </svg>
    );
};

export default WarningIcon;
