import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Activities } from '../../../../../api/Endpoints/Activities';
import moment from 'moment';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Card,
    CardContent,
    Typography,
    Grid,
    Divider,
    Select,
    MenuItem,
    DialogTitle,
    InputLabel,
    TextareaAutosize,
    TextField,
    Slide,
    CircularProgress,
    Backdrop,
    Box,
} from "@mui/material";
import { useEffect } from 'react';
import { useState } from 'react';
import { Task } from '../../../../../api/Endpoints/Task';


const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        border: '1px solid #ccc ',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const Details = ({ ID }) => {
    const classes = useStyles();

    const [Loading, setLoading] = useState(false)
    const [Data, setData] = useState()

    useEffect(() => {
        fetchTaskDetails()
    }, [])

    const fetchTaskDetails = async () => {
        setLoading(true)
        let task = await Activities.getDetails({ id: ID });
        if (task.data.status === "success") {
            let data = task.data.data;
            setData(data)
        }
        setLoading(false)
    };
    const priority = [
        { id: 1, name: "Critical" },
        { id: 2, name: "High" },
        { id: 3, name: "Medium" },
        { id: 4, name: "Low" },
    ]
    const selectedRole = Data?.priority; // Assuming user_role is the numeric role ID
    const matchingRole = priority.find(role => parseInt(role.id) === parseInt(selectedRole));
    console.log("colsdf", matchingRole);
    console.log("tsfsd", Data);

    return (
        <>
            {Loading ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '30vh'
                }}>
                    <CircularProgress className='ld-circle' />
                </Box >
            ) : (
                <>
                    <Card className={classes.card}>
                        <CardContent>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Title:

                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.title}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Project:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.project?.name}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Divider className={classes.divider} />

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Duration:

                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.duration}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>




                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Description:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.description}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>

                        </CardContent>
                    </Card>
                </>
            )
            }
        </>
    );
};

export default Details;