import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Card, CircularProgress, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import Create from './create';
import { MoreOptionsDropdown } from '../common/MoreAction/actionButton';
import { WorkOrders } from '../../../api/Endpoints/WorkOrder';
import Delete from '../common/Popup/Delete';
import { Edit } from '@mui/icons-material';
import DetailView from './DetailView';

const Index = (props) => {

    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }



    const [editId, setEditId] = useState();
    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [refresh, setRefresh] = useState(0);
    const [openCostpopup, setOpencosePopup] = useState(false)
    const [loading, setLoading] = useState(false);
    const [ID, setID] = useState(0)
    const [Tabs, setTabs] = useState(false)
    const [WorkID, setWorkID] = useState(0)

    const [detailViewId, setDetailViewId] = useState(false)

    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    const DetailViewOpen = (id) => {
        setDetailViewId(id)
    }

    const columns = [

        {

            field: 'title', headerName: 'title', width: 400,
            renderCell: (params) => <p style={{ cursor: 'pointer' }} onClick={() => DetailViewOpen(params.row.id)}>{params.value}</p>

        },

        {
            field: 'project',
            headerName: 'Project',
            width: 200,
            renderCell: (params) => {
                return params?.value?.name; // Return the content you want to display in the cell
            }
        },
        {
            field: 'client',
            headerName: 'Client',
            width: 200,
            renderCell: (params) => {
                return params?.value?.name; // Return the content you want to display in the cell
            }
        },

        {
            field: 'action', headerName: '', width: 50, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><a
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEdit(id)}><Edit color='primary' fontSize='small' /></a>
                </>;
            }
        },
        // Add more columns as needed...
    ];

    const fetchTable = () => {
        setLoading(true);
        WorkOrders.get({ keyword: searchKeyword, page: Number(pageNumber) + 1 }).then(response => {
            console.log(response?.data);
            setList(response.data);
            setLoading(false);
        })
    }

    const handleCreateNew = () => {

    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(async () => {
        fetchTable();
    }, [searchKeyword, listType, pageNumber])


    return (


        <>
            {
                detailViewId &&
                <DetailView id={detailViewId} handleClose={setDetailViewId} />
            }

            < PageHeader title={"Work Orders"} onCreateNew={handleCreateNew} />



            <Card sx={{ p: 2, display: "flex", justifyContent: "space-between" }} variant="outlined">
                <TextField
                    style={{ width: 300, marginRight: 10 }}
                    size='small'
                    id="outlined-name"
                    autoFocus
                    label="Search"
                    onChange={handleSearchEntry}
                />
                <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />


            </Card >

            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                Callfunc={() => fetchTable()}
                url="/work-orders/delete?id="
                title="Work Order" />}



            <Card sx={{ m: 2 }} variant="outlined">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {list?.data &&
                    <DataGrid
                        paginationMode="server"
                        rows={list?.data}
                        columns={columns}
                        page={pageNumber}
                        pageSize={list?.meta?.per_page}
                        rowHeight={20}
                        rowsPerPageOptions={[10]}
                        rowCount={list?.meta?.total}
                        onPageChange={handlePageChange}
                        autoHeight
                        density={"comfortable"}
                    />
                }
            </Card>
        </>
    )

};

export default Index;
