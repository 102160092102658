import { Button, Grid, TablePagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ItemTasks from './item sections/item-task/item-tasks'
import { Task } from '../../../../../api/Endpoints/Task'
import { Waveform } from '@uiball/loaders';
import PostTabs from './TabMenus';
import moment from 'moment';
import { CreativeCalenderApi } from '../../../../../api/Endpoints/CreativeCalender';

function ItemDetailsList({ postID ,fetchPosts,project}) {


  const [pageNumber, setPageNumber] = useState(0);
  const [list, setList] = useState([])
  const [taskLoading, setTaskLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState()

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleUpdate = () => {
    setPageNumber();
    setPageNumber(0);
  }

  // const fetchTable = () => {
  //   setTaskLoading(true)
  //   Task.get({ page: parseInt(pageNumber) + 1, related_type: 'creative_calendar_post', related_id: postID }).then(response => {
  //     console.log(response.data.data);
  //     setList(response.data?.data);
  //     setTaskLoading(false)
  //   }).catch(error => {
  //     setTaskLoading(false)
  //   })
  // }

  const fetchDetails = () => {
    setLoading(true)
    CreativeCalenderApi.getPostDetails({ id: postID }).then(response => {
      console.log(response);
      setDetails(response.data?.data);
      setLoading(false)
    }).catch(error => {
      console.log(error);
      setLoading(false)
    })
  }

  console.log(details);

  useEffect(() => {
    fetchDetails()
  }, [postID, pageNumber])

  return (
    <>

      {
        loading ?
          (
            <Grid mt={'40%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Waveform
                size={40}
                lineWeight={4}
                speed={1}
                color="black"
              />
            </Grid>
          ) : (
            <>
              <Grid p={1} container style={{ height: '10vh' }} display={'flex'} alignItems={'center'} mb={1}>
                <Grid display={'flex'} item sx={2.4} md={2.4}>
                  <Grid sx={{ width: '100%', borderTop: '2px solid orange', borderLeft: '1px solid grey', borderRight: '1px solid grey', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', }} >
                    <Grid display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                      <a style={{ fontWeight: 600 }}>{moment(details?.post_date).format('DD')}</a>
                      <a style={{ fontSize: '13px' }}>{moment(details?.post_date).format('dddd')}</a>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid p={1} md={9.6}>
                  <Grid display={'flex'} flexDirection={'column'}>
                    <Grid>
                      <a style={{ fontWeight: 500 }}>{details?.title}</a>
                    </Grid>
                    <Grid display={'flex'} flexDirection={'row'} md={12}>
                      <Grid sx={4} md={4}>
                        <a style={{ color: 'grey', fontSize: '13px' }}> by: </a>
                        <a style={{ fontSize: '13px' }}>{details?.created_user?.name}</a>
                      </Grid>
                      <Grid sx={4} md={4}>
                        <a style={{ color: 'grey', fontSize: '13px' }}> Priority: </a>
                        <a style={{ fontSize: '13px' }}>Priority</a>
                      </Grid>
                      <Grid sx={4} md={4}>
                        <a style={{ color: 'grey', fontSize: '13px' }}> Due: </a>
                        <a style={{ fontSize: '13px' }}>{moment(details?.post_date).format('DD-MM-YYYY')}</a>
                      </Grid> 
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ borderTop: '3px solid #e8e8e8', borderRight: '3px solid #e8e8e8' }}>
                <PostTabs project={project} fetchPosts={fetchPosts} postID={postID} />
                {/* <Grid container style={{ maxHeight: '59vh', height: '59vh', overflowY: 'auto' }}>
                  <Grid md={12}>
                    <ItemTasks postID={postID} handleUpdate={handleUpdate} fetchTable={fetchTable} list={list} />
                  </Grid>
                </Grid> */}
                {/* <TablePagination
                  component="div"
                  count={list?.total}
                  rowsPerPage={10}
                  page={pageNumber}
                  onPageChange={handleChangePage} // Update this line
                  rowsPerPageOptions={[]}
                  labelRowsPerPage="" // This will hide the "Rows per page" label
                  SelectProps={{ native: true }} // This will hide the select dropdown
                /> */}
              </Grid>
            </>
          )}

    </>

  )
}

export default ItemDetailsList
