import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    Select,
    Slide, TextField, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { ErrorMessage } from '@hookform/error-message';
import { MeetingIdContext } from '../../../../contexts/MeetingID/meetingID';
import { Meetings } from '../../../../api/Endpoints/Meeting';
import TextInput from '../../../form/TextInput';
import SelectX from '../../../form/SelectX';
import DateandTime from '../../../form/DateandTimepicker';
import { Project } from '../../../../api/Endpoints/Project';
import ReactSelector from 'react-select';
import { ProjectIdContext } from '../../../../contexts/Project ID/projectID';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




const scheme = yup.object().shape({


    title: yup.string().required(),
    // description: yup.string(),
    // // start_date: yup.string(),
    // // end_date: yup.string(),
    // // due_date: yup.string().required(),
    // assigned_to_id: yup.object().required(),
    // assigned_by_id: yup.object(),
    // projects_id: yup.object().required()
    // meeting_mode: yup.object().shape({
    //     id: yup.string().required("Please choose a Meeting Mode").typeError("Please choose a Meeting Mode")
    // }).required().typeError("Please choose a Meeting Mode"),
})
const priority = [
    { id: 1, name: "Critical" },
    { id: 2, name: "High" },
    { id: 3, name: "Medium" },
    { id: 4, name: "Low" },
]



const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const [test, setTest] = useState()
    const [projectsIDs, setProjectsIDs] = useState(props.projectID)
    const [meetingMode, setMeetingMode] = useState([])

    // console.log(props.projectID);
    const { setMeetingID } = MeetingIdContext();
    // const handleClickOpen = () => {
    //     props.onNew();

    // };
    const handleClose = () => { setOpen(false); };

    const { ProjectID, setProjectID } = ProjectIdContext()


    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    // console.log(projectsIDs);

    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })


        let meeting_on = moment(data.meeting_on).format('YYYY-MM-DD HH:mm:ss');
        // let meeting_on = moment(data.meeting_on).format('YYYY-MM-DD hh:mm:ss a');

        let dataToSubmit = {
            id: props.editId,
            title: data.title,
            meetable_id: projectsIDs,
            meetable_type: "projects",
            meeting_on: meeting_on,
            meeting_link: data.meeting_link,
            remarks: data.description,
            meeting_modes_id: data.meeting_mode ? data.meeting_mode.id : null
        }

        console.log(dataToSubmit);

        let action;
        if (props.editId > 0) {
            action = Meetings.update(dataToSubmit);
        } else {
            action = Meetings.add(dataToSubmit);
        }

        action.then(response => {
            console.log(response);
            //Storing the Meeting Id in a Global State 
            if (response?.data?.data?.id > 0) {
                setMeetingID(response.data.data.id)
            }
            setFormButtonStatus({
                ...formButtonStatus, loading: false, disabled: false
            });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            toast.success(response.data.message)
            // reset()
            props.fetchTable()
            props.setOpen(false)
            sessionStorage.clear();
            // props.onUpdate()
            setOpen(false)
            if (response?.data?.data?.id > 0) {
                props.handleDetailView(response?.data?.data)
            }

        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })

        })

    }




    const [selectedDateTime, setSelectedDateTime] = useState(null);

    // Handle date and time selection
    const handleDateTimeChange = (newDateTime) => {
        setSelectedDateTime(newDateTime);
    };

    const [projects, setProjects] = useState([])

    // useEffect(() => {  
    //     props.projectID(projectID)
    // }, [projectID])

    useEffect(() => {
        fetchProjects()
        fetchMeetingPlatforms()
    }, [])


    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            setOpen(true);
            fetchTaskDetails();
            console.log("details screen");
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        }

    }, [props.editId])

    const fetchMeetingPlatforms = (e) => {
        Meetings.getMeetingPlatform({ keyword: e }).then(response => {
            if (typeof response.data.data !== "undefined") {
                console.log(response);
                setMeetingMode(response.data.data)
            } else {
                return [];
            }
        })
    }

    const fetchProjects = (e) => {
        Project.get({ keyword: e }).then(response => {
            // console.log(response);
            if (typeof response.data.data.data !== "undefined") {
                setProjects(response.data.data.data)
            } else {
                return [];
            }
        })
    }

    const selectMode = (data) => {
        setValue('meeting_mode', data || null);
        console.log(data);
        if (parseInt(props.editId) > 0) {

            let meetingMode = watch('meeting_mode');
            let obj = {
                id: meetingMode?.id || null,
                name: meetingMode?.name || null
            };
            sessionStorage.setItem('mode', JSON.stringify(obj));

        }
    }


    const selectProject = (data) => {
        setValue('projects_id', data);
        console.log(watch('projects_id'));
        console.log(data);
        if (props.editId > 0) {
            sessionStorage.setItem('projects', JSON.stringify(data))
        }
        // setTest(data.id)
        const ids = data.map(item => item.id);
        console.log(ids);
        setProjectsIDs(ids)
        props.getId(ids)

    }

    const fetchTaskDetails = async () => {
        setLoading(true)
        let task = await Meetings.getDetails({ id: props.editId });
        if (task.data.status === "success") {
            let mode = sessionStorage.getItem('mode');
            let parsedObject = JSON.parse(mode);
            const storedProjects = JSON.parse(sessionStorage.getItem('projects'));
            let data = task.data.data;
            // console.log(proIDs);
            console.log(data);
            let proIDs = data.meetables.map((item) => item.meetable)
            setValue('projects_id', storedProjects || proIDs);
            setValue('title', sessionStorage.getItem('title') || data.title);
            setValue('description', sessionStorage.getItem('description') || data.remarks);
            setValue('meeting_link', sessionStorage.getItem('link') || data.meeting_link);
            // setValue('meeting_mode',{id:parsedObject?.id || data.meeting_mode?.id,name:parsedObject?.name || data.meeting_mode?.name} );
            setValue('meeting_mode', parsedObject ? parsedObject : null || data.meeting_mode || '')
            setValue('meeting_on', sessionStorage.getItem('date') || data.meeting_on);

            setSelectedPriority(data.priority);
        }
        setLoading(false)
    };
    // console.log(projectsIDs);
    const titleStore = (e) => {
        if (parseInt(props.editId) > 0) {
            sessionStorage.setItem('title', e.target.value)
        }
    }
    const meetingLinkStore = (e) => {
        if (parseInt(props.editId) > 0) {

            sessionStorage.setItem('link', e.target.value)
        }
    }
    const descriptionStore = (e) => {
        if (parseInt(props.editId) > 0) {

            sessionStorage.setItem('description', e.target.value)
        }
    }
    const dateStore = () => {
        if (parseInt(props.editId) > 0) {

            sessionStorage.setItem('date', watch('meeting_on'))
        }
    }


    return (
        <div>


            {!loading ?
                <DialogContent>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <ErrorMessage
                            errors={errors}
                            name="multipleErrorInput"
                            render={({ messages }) =>
                                messages &&
                                Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                ))
                            }
                        />

                        <Grid sx={{ p: 1 }} item xs={12}>
                            <InputLabel sx={{
                                color: "black",
                                fontWeight: 500,
                                fontSize: "14px",
                                pb: .5
                            }}>Choose Project</InputLabel>
                            <ReactSelector
                                placeholder="Project"
                                isMulti
                                onInputChange={fetchProjects}
                                styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                options={projects}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                value={watch('projects_id')}
                                // {
                                //     projects.find(options =>
                                //         options.name == watch('projects')
                                //     )
                                // }
                                name='projects_id'
                                isClearable={false}
                                onChange={(selectedOption) => {
                                    // setValue('projects_id', selectedOption.name)
                                    selectProject(selectedOption)
                                }
                                }
                            />
                        </Grid>
                        {/* <Grid  sx={{ p: 1 }} item xs={12}>
                           
                                <SelectX
                                    label={"Coose Projects"}
                                    options={fetchProjects}
                                    control={control}
                                    error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                    error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                    name={'projects_id'}
                                    defaultValue={watch('projects_id')}
                                />
                          
                        </Grid> */}

                        <>

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <a onChange={titleStore}>
                                    <TextInput control={control} name="title"
                                        label="Title"
                                        value={watch('title')} />
                                </a>
                            </Grid>


                            <Grid sx={{ p: 1 }} item xs={12}>
                                <a onChange={meetingLinkStore}>
                                    <TextInput control={control} name="meeting_link"
                                        label="Meeting Link"
                                        value={watch('meeting_link')} />
                                </a>
                            </Grid>
                            <Grid sx={{ p: 1, mt: 2 }} item xs={12}>
                                <a onClick={dateStore}>
                                    <DateandTime
                                        control={control}
                                        name="meeting_on"
                                        label="Meeting On"
                                        value={watch('meeting_on')}
                                    />
                                </a>

                            </Grid>
                            <Grid sx={{ p: 1 }} item xs={12} mt={2}>
                                <InputLabel sx={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    pb: .5
                                }}>Select Meeting Platform</InputLabel>
                                <ReactSelector
                                    // required
                                    onInputChange={fetchMeetingPlatforms}
                                    styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                    options={meetingMode}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    value={watch('meeting_mode')}
                                    // {
                                    //     projects.find(options =>
                                    //         options.name == watch('projects')
                                    //     )
                                    // }
                                    name='meeting_mode'
                                    isClearable={true}
                                    onChange={(selectedOption) => {
                                        // setValue('projects_id', selectedOption.name)
                                        selectMode(selectedOption)
                                    }
                                    }
                                />
                                {/* <ErrorMessage
                                    errors={errors}
                                    name="meeting_mode"
                                    render={({ message }) => (
                                        <p style={{ marginTop: -1, fontSize: '12px', color: "red" }} className="error-message">{message}</p>
                                    )}
                                /> */}
                                {/* <a onClick={platformStore} >
                                    <SelectX
                                        key={refresh * 0.2}
                                        label={"Select Meeting Platform"}
                                        options={fetchMeetingPlatforms}
                                        control={control}
                                        error={errors?.meeting_mode?.id ? errors?.meeting_mode?.id?.message : false}
                                        error2={errors?.meeting_mode?.message ? errors?.meeting_mode?.message : false}
                                        name={'meeting_mode'}
                                        defaultValue={watch('meeting_mode')}
                                    />
                                </a> */}
                            </Grid>


                            <Grid sx={{ p: 1, mt: 2 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1,
                                }}>Description
                                </InputLabel>
                                <a onChange={descriptionStore}>
                                    <TextField
                                        {...register('description')}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        sx={{ width: '100%', }}
                                        defaultValue={watch('description')}
                                    />
                                </a>
                            </Grid>
                        </>


                        <Grid sx={{ p: 1, mt: 5 }} item xs={12}>
                            <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                        </Grid>

                    </form>


                </DialogContent>
                : (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <CircularProgress className='ld-circle' />
                    </Box >
                )

            }


        </div >
    );
};

export default Create;
