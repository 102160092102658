import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    TextareaAutosize,
    Select,
    TextField,
    Slide, Typography
} from "@mui/material";
import { TimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/lab';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../form/TextInput";
import { Project } from "../../../api/Endpoints/Project";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import ReactSelector from 'react-select';
import { Backlinks } from '../../../api/Endpoints/Backlinks';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({


    site_name: yup.string().required("site name is requried field"),
    description: yup.string(),
    start_date: yup.string(),
    end_date: yup.string(),
    due_date: yup.string(),
    assigned_by_id: yup.object(),

    // reviewer_id: yup.object().shape({
    //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    // }).required().typeError("Please choose a reviewer"),
})




const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.setEditId(undefined)
        }, 1000)

    };
    const [loading, setloading] = useState(false)

    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchProjects = (e) => {
        return Project.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    // const fetchEmployees = (e) => {
    //     let project = watch('projects_id');
    //     return Employee.get({ keyword: e, projects_id: project?.id }).then(response => {
    //         if (typeof response.data.data.data !== "undefined") {
    //             console.log(response.data.data.data);
    //             return response.data.data.data;
    //         } else {
    //             return [];
    //         }
    //     })
    // }

    const prioriyTask = () => {

    }

        ;


    const handlePriorityChange = (id) => {
        setSelectedPriority(id);
    }


    const SiteCategory = [
        { value: "Classified", label: 'Classified' },
        { value: "Corporate Blog", label: 'Corporate Blog' },
        { value: "Personal Blog", label: 'Personal Blog' },
        { value: "Directory", label: 'Directory' },
        { value: "Forum", label: 'Forum' },
        { value: "Bookmarking", label: 'Bookmarking' },
        { value: "SocialMedia", label: 'SocialMedia' },
        { value: "Corporate website", label: 'Corporate website' },
        { value: "Article Submission", label: 'Article Submission' },
    ];

    const SiteType = [
        { value: 'Paid', label: 'Paid' },
        { value: 'Free', label: 'Free' },
        { value: 'Exchange', label: 'Exchange' },
        { value: 'Other', label: 'Other' },
    ];





    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let dataToSubmit = {
            id: props.editId,
            site_name: data.site_name,
            site_category: data.site_category,
            url: data.url,
            site_type: data.site_type,
            password: data.password,
            user_id: data.user_id,
            remarks: data.remarks

        };

        let action;

        if (props.editId > 0) {
            action = Backlinks.update(dataToSubmit);
        } else {
            action = Backlinks.add(dataToSubmit);
        }
        action.then(response => {

            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }



    const fetchWorkRequestDetails = async () => {
        setloading(true)
        let Backlink = await Backlinks.getBacklinksitestDetails({ id: props.editId });
        if (Backlink.data.status === "success") {
            let data = Backlink.data.data;
            setValue('site_name', data.site_name);
            setValue('site_category', data.site_category);
            setValue('url', data.url);
            setValue('site_type', data.site_type);
            setValue('user_id', data.user_id);
            setValue('password', data.password);
            setValue('is_active', data.is_active);
            setValue('remarks', data.remarks);


        }
        setloading(false)
    };


    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('projects_id')])





    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchWorkRequestDetails();
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        }

    }, [props.editId])



    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Create Backlink Sites"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Backlink Site" : "Create Backlink Site"}</DialogTitle>
                {loading ? <Typography ml={8} mt={3}> Loading...</Typography> : (


                    <DialogContent>

                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="site_name"
                                    label="Site Name"
                                    value={watch('site_name')} />
                            </Grid>
                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="url"
                                    label="URL"
                                    value={watch('url')} />
                            </Grid>
                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput control={control} name="user_id"
                                    label="User ID"
                                    value={watch('user_id')} />
                            </Grid>

                            <Grid sx={{ p: 1 }} item xs={12}>
                                <TextInput
                                    type={'password'}
                                    control={control}
                                    name="password"
                                    label="Password"
                                    value={watch('password')} />
                            </Grid>


                            {/* Part for Dropdown's   */}

                            <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1,
                                }}>Site Category</InputLabel>
                                <ReactSelector
                                    options={SiteCategory}
                                    name='site_category'
                                    value={
                                        SiteCategory.filter(site_category =>
                                            site_category.label === watch('site_category'))
                                    }
                                    isClearable={true}
                                    defaultValue={(watch('site_category'))}
                                    onChange={(selectedOption) => setValue('site_category', selectedOption.value)}
                                />


                            </Grid>
                            <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1,
                                }}>Site Type</InputLabel>
                                <ReactSelector
                                    options={SiteType}
                                    name='site_type'
                                    value={
                                        SiteType.filter(site_type =>
                                            site_type.label === watch('site_type'))
                                    }
                                    defaultValue={(watch('site_type'))}
                                    isClearable={true}
                                    onChange={(selectedOption) => setValue('site_type', selectedOption.value)}
                                />
                                {errors.site_category && <p>{errors.site_category.message}</p>}

                            </Grid>
                            <Grid sx={{ p: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1
                                }}>Is Active </InputLabel>
                                <TextInput control={control} name="isActive"
                                    value={watch('IsActive')} />
                            </Grid>

                            <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1
                                }}>Remarks </InputLabel>
                                <TextField
                                    {...register('remarks')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    sx={{ width: '100%', }}

                                />
                            </Grid>
                            <Grid sx={{ p: 1,mt:1 }} mb={5} item xs={12}>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            </Grid>

                        </form>

                    </DialogContent>
                )}
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog >
        </div >
    );
};

export default Create