import React, { useEffect, useState } from "react";
import PageHeader from "../../common/page-header";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import DetailView from "./DetailView";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";

function AssignedTarget({ detailViewId, setDetailViewId }) {
  const [list, setList] = useState({ data: [] });
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState("All");
  const [filterDueDate, setFilterDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [filteredData, setFilteredData] = useState("");

  const DetailViewOpen = (id) => {
    setDetailViewId(id);
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 200,
      renderCell: (params) => (
        <p style={{ cursor: "pointer" }}>{params.value}</p>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 550,
      renderCell: (params) => {
        <p>
          {params.value?.length > 100
            ? params.value?.slice(0, 100) + "..."
            : params.value}
        </p>;
      },
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      width: 200,
      renderCell: (params) => <p>{params.value.join(", ")}</p>,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 150,
      renderCell: (params) => <p>{params.value}</p>,
    },
    {
      field: "addResult",
      headerName: "Add Result",
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => DetailViewOpen(params.id)}
            >
              <AddIcon />
            </Button>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    setList({
      data: [
        {
          id: 1,
          title: "Github Pull Request",
          description:
            "Create a pull request on Github. This will allow for code review and collaboration with other team members.",
          assignedTo: ["User 1", "User 2"],
          dueDate: "2022-01-01",
          status: "Not Started",
          explanation:
            "This task is yet to be started. It is a high-priority task that requires immediate attention.",
        },
        {
          id: 2,
          title: "Address Bug Issues",
          description:
            "Identify and resolve all known bug issues in the system to ensure a smoother user experience.",
          assignedTo: ["User 2", "User 3"],
          dueDate: "2024-07-26",
          status: "In Progress",
          explanation:
            "This task is currently in progress. The process of identifying and resolving bug issues has begun, and significant improvements are expected.",
        },
        {
          id: 3,
          title: "Resolve Deployment Issues",
          description:
            "Address any issues related to the deployment of the system to ensure a seamless transition.",
          assignedTo: ["User 3", "User 4"],
          dueDate: "2022-01-03",
          status: "Partially Achieved",
          explanation:
            "This task has been partially achieved. Most of the deployment issues have been identified and resolved, but a few minor issues remain.",
        },
        {
          id: 4,
          title: "Server Downtime Mitigation",
          description:
            "Implement measures to minimize server downtime and ensure high system availability.",
          assignedTo: ["User 4", "User 1"],
          dueDate: "2022-01-04",
          status: "Achieved",
          explanation:
            "This task has been successfully achieved. Measures have been implemented to minimize server downtime, ensuring high system availability.",
        },
        {
          id: 5,
          title: "Improve System Security",
          description:
            "Implement additional security measures to protect the system from potential threats.",
          assignedTo: ["User 1", "User 2"],
          dueDate: "2022-01-05",
          status: "Not Started",
          explanation:
            "This task is yet to be started. It is a high-priority task that requires immediate attention to ensure the security of the system.",
        },
      ],
    });
  }, []);
  return (
    <>
      {detailViewId && (
        <DetailView id={detailViewId} handleClose={setDetailViewId} />
      )}

      <Grid
        container
        spacing={2}
        sx={{
          my: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Grid item xs={12} md={6}>
          <PageHeader
            title={"Targets Assigned By Me"}
            size={16}
            color="#77828F"
            variant={"h6"}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel id="filter-status-label">Filter by Status</InputLabel>
            <Select
              labelId="filter-status-label"
              id="filter-status-select"
              value={filterStatus}
              label="Filter by Status"
              onChange={(e) => {
                setFilterStatus(e.target.value);
                // Filter the table data based on the status
                const filteredData = list.data.filter(
                  (item) =>
                    item.status === e.target.value || e.target.value === "All"
                );
                setFilteredData(filteredData);
              }}
            >
              <MenuItem value="All" selected>
                All
              </MenuItem>
              <MenuItem value="Not Started">Not Started</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Partially Achieved">Partially Achieved</MenuItem>
              <MenuItem value="Achieved">Achieved</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <DatePicker
            label="Due Date"
            variant="inline"
            value={filterDueDate}
            onChange={(date) => {
              console.log(date);
              const formattedDate = date
                ? `${date.getDate()}-${(date.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}-${date.getFullYear()}`
                : "";
              console.log(formattedDate);
              setFilterDueDate(formattedDate);
              // Filter the table data based on the due date
              const filteredData = date
                ? list.data.filter((item) => item.dueDate == formattedDate)
                : list.data;
              setFilteredData(filteredData);
            }}
            renderInput={(params) => <TextField {...params} />}
            clearable
          />
        </Grid>
      </Grid>
      <Card sx={{ m: 2, width: { xs: "100%", md: "auto" } }} variant="outlined">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DataGrid
          rows={
            filterStatus === "All"
              ? !filterDueDate
                ? list.data
                : list.data.filter((value) => value.dueDate == filterDueDate)
              : filterDueDate
              ? list.data.filter(
                  (item) =>
                    item.status === filterStatus &&
                    item.dueDate === filterDueDate
                )
              : list.data.filter((item) => item.status === filterStatus)
          }
          columns={columns}
          page={pageNumber}
          pageSize={10}
          rowsPerPageOptions={[10]}
          rowCount={filteredData.length}
          onPageChange={handlePageChange}
          autoHeight
          density={"comfortable"}
          style={{ width: { xs: "100%", md: "auto" } }}
        />
      </Card>
    </>
  );
}

export default AssignedTarget;
