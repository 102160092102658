import { CheckCircle, CheckCircleOutlineRounded, Edit, NotInterested, RadioButtonUnchecked } from '@mui/icons-material';
import { Avatar, CircularProgress, Grid, TablePagination } from '@mui/material'
import { deepPurple } from '@mui/material/colors';
import React, { useEffect, useState } from 'react'
import { Waveform } from '@uiball/loaders';
import { CreativeCalenderApi } from '../../../../../../../api/Endpoints/CreativeCalender';
import CreateNotes from '../../../notes/create-note';

function PostNotes({ calendarID }) {

    const style = {
        borderBottom: '1px solid #e8e8e8',
        cursor: 'pointer'
    };

    // console.log(calendarID);

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [editID, setEditID] = useState()
    const [noteEditID, setNoteEditID] = useState()


    const [limit, setlimit] = useState(10)
    const [total, setTotal] = useState()
    const [scrolLoading, setscrolLoading] = useState(false)

    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            // You can adjust the value (10) based on your preference for when to trigger the function
            // Call your function here when scrolling to the bottom
            setlimit(limit + 10)
        }
    };

    useEffect(() => {
        if (limit > 10 && total !== list?.data?.length) {
            scrollFunction();
        }
    }, [limit])

    const scrollFunction = () => {
        setscrolLoading(true);
        CreativeCalenderApi.getNotes({ creative_calendar_id: calendarID }).then(response => {
            console.log(response.data);
            setList(response.data);
            setTotal(response?.data?.meta?.total)
            setscrolLoading(false);
        }).catch((error) => {
            setscrolLoading(false)
        })
    }

    const statusOptions = [
        { label: 'Good to proceed', value: 'good', icon: <CheckCircle style={{ color: 'green', fontSize: '17px', verticalAlign: 'middle' }} fontSize='small' /> },
        { label: 'Not impressed but OK to proceed', value: 'okay', icon: <CheckCircleOutlineRounded style={{ color: 'green', fontSize: '17px', verticalAlign: 'middle' }} fontSize='small' /> },
        { label: 'No Selection', value: 'no_comment', icon: <RadioButtonUnchecked style={{ color: 'black', fontSize: '17px', verticalAlign: 'middle' }} fontSize='small' /> },
        { label: 'Rejected', value: 'rejected', icon: <NotInterested style={{ color: 'red', fontSize: '17px', verticalAlign: 'middle' }} fontSize='small' /> },
    ];


    const handleCreate = () => {
        setEditID(0)
    }

    const handleNoteEdit = (id) => {
        setNoteEditID(id)
    }

    const fetchData = () => {
        setLoading(true);
        CreativeCalenderApi.getNotes({ creative_calendar_id: calendarID }).then(response => {
            console.log(response.data);
            setList(response.data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [calendarID])


    return (
        <>
            {/* {
                loading ?
                    (
                        <Grid mt={'70%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Waveform
                                size={40}
                                lineWeight={4}
                                speed={1}
                                color="black"
                            />
                        </Grid>
                    ) : ( */}
            <>
                {
                    loading ?
                        (
                            <Grid mt={'40%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Waveform
                                    size={40}
                                    lineWeight={4}
                                    speed={1}
                                    color="black"
                                />
                            </Grid>
                        ) : (

                            <>

                                <Grid Grid container>
                                    {/* <Grid md={12} style={{ borderBottom: '1px solid #e8e8e8' }} p={2} display={'flex'} justifyContent={'end'} alignItems={'center'} >
                                        <Grid display={'flex'} justifyContent={'end'}>
                                            <CreateNotes calendarID={calendarID} editID={noteEditID} setEditID={setNoteEditID} fetch={fetchData} />
                                        </Grid>
                                    </Grid> */}
                                    <Grid container style={{ height: '59vh', maxHeight: '59vh', overflowY: 'auto' }}>
                                        {
                                            list?.data?.length > 0 ?
                                                <>
                                                    {
                                                        list?.data?.map((note, index) => (
                                                            <Grid pl={1} style={{ borderBottom: '1px solid #e8e8e8', width: '100%' }} container key={index} item display={'flex'} justifyContent={'space-between'} md={12}>
                                                                <Grid container item md={11} display={'flex'} >
                                                                    <Grid item md={1}>
                                                                        {statusOptions.find(option => option.value === note.status)?.icon}
                                                                        {/* <a>
                                                    <CheckCircle style={{ fontSize: '17px', verticalAlign: 'middle' }} />
                                                </a> */}
                                                                    </Grid>
                                                                    <Grid item md={10}>
                                                                        <a style={{ fontSize: '15px', marginLeft: 5 }}>{note?.note} </a>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item md={1}>
                                                                    <a onClick={() => handleNoteEdit(note?.id)} style={{ fontSize: '13px', color: 'blue', cursor: 'pointer' }}>
                                                                        <Edit style={{ cursor: 'pointer', color: 'blue', fontSize: '13px' }} />
                                                                    </a>
                                                                </Grid>
                                                                <Grid item display={'flex'} justifyContent={'end'} md={12}>
                                                                    <Grid item mr={3}>
                                                                        <a style={{ fontSize: '13px', color: 'grey' }}>~{note?.created_user?.name} </a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ))
                                                    }
                                                    {
                                                        scrolLoading &&
                                                        <Grid item md={12} display={'flex'} justifyContent={'center'}>
                                                            <CircularProgress />
                                                        </Grid>
                                                    }

                                                </>
                                                :
                                                <Grid display={'flex'} justifyContent={'center'} alignItems={'center'} md={12} height={'69vh'}>
                                                    No Notes Found
                                                </Grid>
                                        }
                                    </Grid>

                                </Grid >
                            </>
                        )
                }
            </>
            {/* )
            } */}
        </>
    )
}

export default PostNotes
