import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Slide,
  Autocomplete,
  debounce,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import { Project } from "../../../api/Endpoints/Project";
import { Employee } from "../../../api/Endpoints/Employee";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CreativeCalenderApi } from "../../../api/Endpoints/CreativeCalender";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const schema = yup.object().shape({
  type: yup.string().required("Type is required"),
  project: yup.object().required("Project is required"),
  calendar: yup.object().required("Calendar is required"),
  calendarItem: yup.number().nullable(),
  title: yup.string().required("Title is required"),
  requirements: yup.string().required("Requirements are required"),
  assignedTo: yup.object().required("Assigned to is required"),
  priority: yup.string().required("Priority is required"),
  dueDate: yup.date().required("Due Date is required"),
  createTask: yup.boolean().default(() => false),
});

const CreateRequirement = () => {
  const [open, setOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [calendarItems, setCalendarItems] = useState([]);
  const [creativeCalendar, setCreativeCalendar] = useState([]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: "",
      project: null,
      calendar: null,
      calendarItem: null,
      title: "",
      requirements: "",
      assignedTo: null,
      priority: "",
      dueDate: null,
      createTask: false,
    },
  });

  useEffect(() => {
    fetchProjects();
    fetchEmployees();
    fetchCreativeCalendar();
  }, []);

  const handleSelectedId = (id) => {
    // Fetch the data for the second grid based on the selected ID

    CreativeCalenderApi.getPosts({ creative_calendar_id: id })
      .then((response) => {
        console.log(response.data.data);
        setCalendarItems(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // Initialize the state for the second grid

  const fetchProjects = () => {
    Project.get().then((response) => {
      if (typeof response?.data?.data?.data !== "undefined") {
        setProjects(response.data.data.data);
      }
    });
  };

  const fetchCreativeCalendar = () => {
    CreativeCalenderApi.get({ limit: 100 }).then((response) => {
      if (typeof response.data.data !== "undefined") {
        setCreativeCalendar(response.data.data);
      }
    });
  };

  const fetchEmployees = () => {
    Employee.get({ limit: 100 }).then((response) => {
      if (typeof response.data.data.data !== "undefined") {
        setEmployees(response.data.data.data);
      }
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    console.log(data);
    reset();
    // fetchTask();
    handleClose();
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Add Requirement
      </Button>

      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: "100%",
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          Create Content Request
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid sx={{ p: 1, mt: 2 }} item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Choose Type</InputLabel>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} label="Choose Type">
                        <MenuItem value="Article">Article</MenuItem>
                        <MenuItem value="Creative">Creative</MenuItem>
                        <MenuItem value="Video">Video</MenuItem>
                        <MenuItem value="Copy">Copy</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    )}
                  />
                  {errors.type && <p>{errors.type.message}</p>}
                </FormControl>
              </Grid>

              <Grid sx={{ p: 1 }} item xs={12}>
                <Controller
                  name="project"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      options={projects}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose Project"
                          variant="outlined"
                          error={!!errors.project}
                          helperText={
                            errors.project ? errors.project.message : ""
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="calendar"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      options={creativeCalendar}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, data) => {
                        if (!data?.id) {
                          field.onChange(data);
                          setCalendarItems([]);
                        } else {
                          field.onChange(data);
                          const selectedId = data.id;
                          console.log("Selected ID:", selectedId);
                          handleSelectedId(selectedId);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose Calendar"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="calendarItem"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth error={!!errors.calendarItem}>
                      <InputLabel id="calendar-item-label">
                        Choose Calendar Item
                      </InputLabel>
                      <Select
                        {...field}
                        labelId="calendar-item-label"
                        label="Choose Calendar Item"
                        variant="outlined"
                      >
                        {calendarItems.length > 0 ? (
                          calendarItems.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.title}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No Option</MenuItem>
                        )}
                      </Select>
                      {errors.calendarItem && (
                        <FormHelperText>
                          {errors.calendarItem.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid sx={{ p: 1 }} item xs={12}>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      id="title-input"
                      label="Title"
                      variant="outlined"
                      error={!!errors.title}
                      helperText={errors.title ? errors.title.message : ""}
                    />
                  )}
                />
              </Grid>

              <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                <InputLabel
                  sx={{
                    color: "black",
                    pb: 1,
                  }}
                >
                  Requirements
                </InputLabel>
                <Controller
                  name="requirements"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      sx={{ width: "100%" }}
                      error={!!errors.requirements}
                      helperText={
                        errors.requirements ? errors.requirements.message : ""
                      }
                    />
                  )}
                />
              </Grid>

              <Grid container spacing={2} sx={{ p: 1 }}>
                <Grid item xs={6}>
                  <Controller
                    name="assignedTo"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        options={employees}
                        getOptionLabel={(option) => option.name}
                        onChange={debounce(
                          (_, data) => field.onChange(data),
                          300
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Assigned to"
                            variant="outlined"
                            error={!!errors.assignedTo}
                            helperText={
                              errors.assignedTo ? errors.assignedTo.message : ""
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth sx={{ height: "100%" }}>
                    <InputLabel>Priority</InputLabel>
                    <Controller
                      name="priority"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Priority"
                          sx={{ height: "100%" }}
                        >
                          <MenuItem value="Critical">Critical</MenuItem>
                          <MenuItem value="High">High</MenuItem>
                          <MenuItem value="Medium">Medium</MenuItem>
                          <MenuItem value="Low">Low</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.priority && <p>{errors.priority.message}</p>}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ p: 1 }} alignItems={"center"}>
                <Grid item xs={8}>
                  <Controller
                    name="dueDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        label="Due Date"
                        onChange={(date) => field.onChange(date)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.dueDate}
                            helperText={
                              errors.dueDate ? errors.dueDate.message : ""
                            }
                            disablePast
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="createTask"
                        control={control}
                        render={({ field }) => {
                          return <Checkbox {...field} checked={field.value} />;
                        }}
                      />
                    }
                    label="Create Task"
                  />
                </Grid>
              </Grid>

              <Grid sx={{ p: 1 }} item xs={12}>
                <Button variant="outlined" type="submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateRequirement;
