import { get, post } from './../Config/config';


export const Website_live_tracker = {

    get: (data) => get('/websites', { params: data }),
    add: (data) => post('/websites/store', data),
    update: (data) => post('/websites/update', data),
    getDetails: (data) => get('/websites/view?id=', { params: data }),
    delete: (data) => get('websites/delete?id=', { params: data }),
    failedWbsites: (data) => get('/websites/fails', { params: data }),
}
