import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Backdrop, Button, Card, CircularProgress, Dialog, DialogContent, Slide, TextField } from "@mui/material";
import AddNote from './Tabs/Notes';
import DetailView from './Tabs/Details';
import { useState } from 'react';
import SubscibeTab from './Tabs/Subscribe';
import { Users } from '../../../api/Endpoints/Users';
import { Task } from '../../../api/Endpoints/Task';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { SubscribeApi } from '../../../api/Endpoints/Subscribe';
import { UserIdContext } from '../../../contexts/UserID/UserID';
import CheckListTab from './Tabs/Checklist';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function content(props) {
    return (
        <div
            hidden={props.value !== props.index}
        >

        </div>
    )

}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs(props) {

    const { UserID } = UserIdContext();

    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(true);
    const [editId, setEditId] = useState()
    const [refresh, setRefresh] = useState()
    const [userID, setUserID] = useState(UserID)
    const [details, setDetails] = useState(true)
    const [subscription, setSubscription] = useState(false)
    const [subsRefresh, setSubsRefresh] = useState()
    const [subscribeLoading, setsubscribeLoading] = useState(false)



    const handleClose = () => {
        props.handleClose(false)
        // setOpen(false); 
    };

    const handleCreate = () => {
        setEditId(0)
    }

    const subscribeTask = async () => {


        let dataToSubmit = {
            subscribable_type: "task",
            subscribable_id: props.id,
            users_id: userID
        }
        if (!subscription) {
            setsubscribeLoading(true)
        }
        SubscribeApi.subscribe(dataToSubmit).then((response) => {
            console.log(response);
            setSubscription(true)
            setsubscribeLoading(false)
            toast.success(response.data.message)
            setRefresh(Math.random() * 0.2)
            console.log(refresh);
        })
    }

    // const fetchUser = async () => {
    //     const accessToken = window.localStorage.getItem('accessToken');
    //     const user = await Users.me(accessToken);
    //     setUserID(user.data.data.id)
    // }
    const checkSubscription = async () => {
        // const accessToken = window.localStorage.getItem('accessToken');
        // const user = await Users.me(accessToken);
        let subscriptions = await SubscribeApi.getSubscribers({ subscribable_type: 'task', subscribable_id: props.id })
        console.log(subscriptions);
        let subscribed = subscriptions.data.data.data.some(item => item.users_id == UserID)
        console.log(subscribed);
        if (subscribed) {
            setSubscription(true)
        }
    }

    useEffect(() => {
        // fetchUser()
        checkSubscription()
    }, [])

    useEffect(() => {
        checkSubscription()
    }, [subsRefresh])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const showDetails = () => {
        setDetails(true)
    }
    // console.log(editId);

    return (
        <>

            {/* <Dialog
                open={open}
                onClose={handleClose}
            // sx={{ width: '100%', mt: 1 }}
            > */}
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ ml: 2 }}>
                    <Tab label="Details" {...a11yProps(0)} />
                    <Tab label="Add Notes" {...a11yProps(1)} />
                    <Tab label="Checklist" {...a11yProps(2)} />
                    <Tab label="Subscribers" {...a11yProps(3)} />
                    <Button
                        variant="contained"
                        onClick={subscribeTask}
                        sx={{ height: 30, width: 90, mt: 1, marginLeft: 'auto', marginRight: 5, backgroundColor: subscription ? '#78b525' : 'blue' }}
                    >
                        {subscribeLoading ? <CircularProgress size={20} color="inherit" /> : subscription ? 'Subscribed' : "Subscribe"}
                    </Button>
                </Tabs>

                <DialogContent>

                    <TabPanel value={value} index={0}>
                        <DetailView setTab={setValue} setEditId={setEditId} id={props.id} handleClose={props.handleClose} refresh={refresh} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <AddNote id={props.id} setTab={setValue} setEditId={setEditId} editId={editId} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <CheckListTab id={props.id} refresh={refresh} setSubscription={setSubscription} userID={userID} setRefresh={setRefresh} setTab={setValue} editId={editId} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <SubscibeTab id={props.id} refresh={refresh} setSubscription={setSubscription} userID={userID} setRefresh={setRefresh} setTab={setValue} editId={editId} />
                    </TabPanel>


                    {/* <Divider />

                    <div style={{ width: '95%', display: 'flex', justifyContent: 'end', marginTop: 5 }}>
                        <Button variant="contained" onClick={() => props.handleClose(false)} style={{ width: 'fit-content', mr: 7 }}>
                            Cancel
                        </Button>

                    </div> */}
                </DialogContent>
            </Dialog>
        </>
    );
}
