import { Box, Button, Dialog, DialogContent, Grid, Slide, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DetailView from './Tabs/DetailView';
import AddNote from './Tabs/Notes';
import TabPanel from '../../../utils/TabPanel';
import VerifyButton from './Modals/verifyModal';
import MarkButton from './Modals/markAsCompletedModal';

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ContentTabs = (props) => {

    const tabs = [
        {
            component: <DetailView id={props?.id} />,
            label: 'Details'
        },
        {
            component: <AddNote id={props?.id} />,
            label: 'Notes'
        },

    ];

    const [activeTab, setActiveTab] = useState(0);
    const [open, setOpen] = React.useState(true);
    const [verifyModal, setVerifyModal] = useState(false)
    const [markModal, setMarkModal] = useState()

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleClose = () => {
        props.handleClose()

    };

    const handleVerifyOpen = () => {
        setVerifyModal(true)
    }

    const handleMarkOpen = () => {
        setMarkModal(true)
    }

    return (
        <>

            <VerifyButton open={verifyModal} setOpen={setVerifyModal} />
            <MarkButton open={markModal} setOpen={setMarkModal} />

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{ sx: { width: "50%", height: "100%", right: 0, top: 0, bottom: 0, m: 0, p: 0, position: 'fixed', borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <Box sx={{ borderBottom: '1px solid #e8e8e8', px: 2 }} >
                    <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example" >
                        {tabs.map((obj, index) => (
                            <Tab label={obj.label} key={index} {...a11yProps(index)} />
                        ))}

                        <Grid display={'flex'} alignItems={'center'} style={{ marginLeft: 'auto' }}>
                            <Button sx={{ mr: 2 }} size='small' variant='outlined' onClick={handleMarkOpen} >Mark as Completed</Button>
                            <Button size='small' variant='outlined' onClick={handleVerifyOpen}>Verify</Button>
                        </Grid>
                    </Tabs>
                </Box>

                <DialogContent>
                    {tabs.map((obj, index) => {
                        return <TabPanel value={activeTab} index={index} key={index}>
                            {obj.component}
                        </TabPanel>
                    })}
                </DialogContent>
            </Dialog>



        </>
    );
};

export default ContentTabs;

