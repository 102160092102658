import React, { useEffect, useState } from 'react';
import { useAppSettings } from "../../../../../hooks/use-app-settings";
import moment from "moment";
import { Backdrop, Box, Button, Card, CircularProgress, TextField } from "@mui/material";
import { PreQualifiers } from "../../../../../api/Endpoints/PreQualifiers";
import { DataGrid } from "@mui/x-data-grid";
import { Account } from "../../../../../api/Endpoints/Account";
import { Project } from "../../../../../api/Endpoints/Project";
import { Task } from "../../../../../api/Endpoints/Task";
import AddIcon from "@mui/icons-material/Add";
import { Meetings } from '../../../../../api/Endpoints/Meeting';
import Delete from '../../../common/Popup/Delete';
import { MeetingIdContext } from '../../../../../contexts/MeetingID/meetingID';
import { DotSpinner } from '@uiball/loaders';
import BasicTabs from '../../../Meetings/TabMenus';
import Modal from '../../../Meetings/modal';
// import View from './View';

const Index = (props) => {

    const [editId, setEditId] = useState();
    const [refresh, setRefresh] = useState(0);
    const [viewPage, setViewPage] = useState(false)
    const [ID, setID] = useState(0)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [deleteID, setDeleteID] = useState(0)
    const columns = [
        {
            field: 'title',
            headerName: 'Titile',
            width: 250,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button
                            style={{ color: 'black' }}
                            size="small"
                            onClick={() => ViewTab(id)} >{params.value}</Button>
                    </>
                );
            },
        },


        {
            field: 'meeting_link',
            headerName: 'Meeting Link',
            width: 300,
            renderCell: (params) => {
                const meetingLink = params.value; // Assuming 'meeting_link' is the field containing the URL
                return (
                    <a href={meetingLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        {meetingLink}
                    </a>
                );
            }
        }
        ,



        {
            field: 'meeting_on', headerName: 'Meeting On', width: 200, renderCell: (params) => {

                if (params.value) {
                    return moment(params.value).format('DD-MM-YYYY - hh:mm:ss')
                } else {
                    return "Not started";
                }
            }
        },

        // {field: 'status', headerName: 'Status', width: 100, renderCell: (params) => <>{params.status}</> },
        // {
        //     field: 'attendees', headerName: '', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
        //         let id = params.value?.id;
        //         return <><Button size={'small'}
        //             variant='outlined'
        //             color='primary'
        //             onClick={() => AddAttendees(id)}>ADD Attendees</Button >
        //         </>;
        //     }
        // },
        {
            field: 'action', headerName: 'Edit', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><Button size={'small'}
                    variant='outlined'
                    color='primary'
                    onClick={() => handleEdit(id)} >Edit</Button >
                </>;
            }
        },
        // {
        //     field: '', headerName: 'Delete', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
        //         let id = params.value?.id;
        //         return <><Button size={'small'}
        //             variant='outlined'
        //             color='error'
        //             onClick={() => handleDelete(id)}>Delete</Button >
        //         </>;
        //     }
        // },
    ]

    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [showCratePage, setShowCreatePage] = useState(false)
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [loading, setLoading] = useState(false);
    const { setMeetingID } = MeetingIdContext();


    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }


    const ViewTab = (id) => {
        setViewPage(id)
        setID(id)
    }
    const fetchTable = async () => {
        setLoading(true);

        try {
            const response = await Meetings.get({
                keyword: searchKeyword,
                page: parseInt(pageNumber) + 1,
                projects_id: props.projectID
            });

            setList(response.data.data);
        } catch (error) {
            // Handle the error here if needed
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }
    const handleClickOpen = () => {
        handleCreate()
        setShowCreatePage(true)
    }

    const handleEdit = (id) => {
        setMeetingID(id)
        setShowCreatePage(true)
        setRefresh(Math.random);
        setEditId(parseInt(id));

    }
    const handleDelete = (id) => {
        setDeletePopup(true)
        setDeleteID(id)
    }
    const AddAttendees = (id) => {

        setShowCreatePage(true)
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }
    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }


    useEffect(() => {
        fetchTable();
    }, [searchKeyword, props.projectID, pageNumber])

    return (
        <>
            {!loading ? (

                <>
                    {
                        viewPage &&
                        <BasicTabs id={viewPage} projectID={props.projectID} handleClose={setViewPage} />
                    }
                    {showDeletePopup && <Delete
                        ID={deleteID}
                        setID={setDeleteID}
                        setDeletePopup={setDeletePopup}
                        Callfunc={() => fetchTable()}
                        url="/meetings/delete?id="
                        title="Meeting" />}
                    <Card sx={{ p: 2, display: "flex", justifyContent: "space-between", alignItems: 'center' }} variant="outlined">
                        <TextField
                            style={{ width: 300, marginRight: 10 }}
                            id="outlined-name"
                            label="Search Meetings"
                            disabled
                            onChange={handleSearchEntry}

                        />

                        <Button variant="contained" onClick={handleClickOpen}>
                            {props.icon ? <AddIcon style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }} /> : "Create Meeting"}
                        </Button>

                        {showCratePage &&
                            <Modal
                                key={refresh}
                                fetchTable={() => fetchTable()}
                                onNew={handleCreate}
                                editId={editId}
                                projectID={props.projectID}
                                setEditId={setEditId}
                                setShowCreatePage={setShowCreatePage}
                                onUpdate={handleUpdate}
                            />}

                        {/* {viewPage && <View ID={ID} setViewPage={setViewPage} setID={setID} />} */}

                    </Card >
                    <Card sx={{ m: 2 }} variant="outlined">
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loading}
                        >
                            < DotSpinner
                                size={40}
                                speed={0.9}
                                color="blue"
                            />
                        </Backdrop>

                        {typeof list.data === "object" &&
                            <DataGrid
                                paginationMode="server"
                                rows={list?.data}
                                columns={columns}
                                page={pageNumber}
                                pageSize={10}
                                rowHeight={20}
                                rowsPerPageOptions={[10]}
                                rowCount={list.total}
                                onPageChange={handlePageChange}
                                autoHeight
                                density={"comfortable"}
                            />
                        }
                    </Card>
                </>
            ) : (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '30vh'
                }}>
                    < DotSpinner
                        size={40}
                        speed={0.9}
                        color="blue"
                    />
                </Box >
            )
            }
        </>
    );
};

export default Index;
