import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Card, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import TextInput from '../../../form/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { AddTask, Edit, Task } from '@mui/icons-material';
import { Activities } from '../../../../api/Endpoints/Activities';
import Create from '../../activities/create';
import toast from 'react-hot-toast';
import SelectX from '../../../form/SelectX';
import { Project } from '../../../../api/Endpoints/Project';
import { LoadingButton } from '@mui/lab';
import LinkTaskModal from '../linkTask';

const useStyles = makeStyles({
    customTextField: {
        height: '10px', // Adjust the height as needed
    },
});

const ActivityTab = (props) => {

    const classes = useStyles();

    const validationSchema = yup.object().shape({
        activity: yup.string().required('Activity is required'),
        // hours: yup.number(),
        // hours: yup.number(),
        // Add validation rules for other fields if needed
    });
    const formRef = useRef();
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) });
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [ClientID, setClientID] = useState(0)
    const [deleteId, setDeleteId] = useState(false)
    const [durationMinutes, setDurationMinutes] = React.useState();
    const [durationHours, setDurationHours] = React.useState();
    const [activityTask, setActivityTask] = useState(false)
    const [linkID, setLinkID] = useState()
    const [linkDetails, setLinkDetails] = useState()

    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Add",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchProjects = (e) => {
        return Project.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleDelete = (id) => {
        setDeleteId(id)
    }
    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }
    const handleCreateNew = () => {

    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(async () => {

    }, [searchKeyword, listType, pageNumber])

    // useEffect(() => {
    //     console.log(props.copiedTask);
    //     if (props.copiedTask) {
    //         setValue('activity', props.copiedTask?.title)
    //     }
    // }, [])

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }


    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const viewTabs = (id) => {
        setClientID(id)
        setTabs(true)
    }

    const onSubmit = (data) => {
        // console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })



        let dataToSubmit = {
            projects_id: data.projects_id?.id,
            title: data.activity,
            duration_hours: data.hours,
            duration_minutes: data.minutes
        };

        let action;

        action = Activities.add(dataToSubmit);

        action.then(response => {
            // console.log(response);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Add", loading: false, disabled: false });
            toast.success(response.data.message)
            sessionStorage.clear()
            setValue('activity', '')
            setValue('projects_id', '')
            setValue('hours', '')
            setValue('minutes', '')
            props.setCopiedTask()
            props.setCopiedPlan()
            props.fetchActivities()
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            // console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Add", loading: false, disabled: false });
        })
    }


    const handleKeyPress = (event) => {
        // console.log(event);
        if (event.key === 'Enter') {
            // Enter key was pressed, submit the form
            handleSubmit(onSubmit)();
        }
    };

    const openActivityEdit = (id) => {
        // console.log(id);
        setEditId(id)
    }
    const handleHourChange = (e) => {
        if (e.target.value.length <= 2) {
            setDurationHours(e.target.value);
        }
    };
    const handleActivity = () => {
        if (sessionStorage.getItem('activityTitle')) {
            sessionStorage.setItem('activityTitle', watch('activity'))
        }
        if (sessionStorage.getItem('workTitle')) {
            sessionStorage.setItem('workTitle', watch('activity'))
        }
        if (!sessionStorage.getItem('workTitle') && !sessionStorage.getItem('activityTitle')) {
            sessionStorage.setItem('activityTitle', watch('activity'))
        }
        // props.setCopiedTask(watch('activity'))
        // console.log(props.copiedTask);
    }

    const linkTask = (id, details) => {
        setLinkID(id)
        setLinkDetails(details)
    }

    useEffect(() => {
        if (props?.open) {
            props.fetchActivities()
            setValue('activity', sessionStorage.getItem('activityTitle') || sessionStorage.getItem('workTitle') || '')
        }
    }, [pageNumber])

    useEffect(() => {
        if (props?.copiedPlan?.project) {
            // console.log('plan');
            let project = props?.copiedPlan?.project
            setValue('projects_id', { id: project?.id, name: project?.name })
        }
        if (props?.copiedTask?.project) {
            // console.log('task');
            let project = props?.copiedTask?.project
            setValue('projects_id', { id: project?.id, name: project?.name })
        }
        setValue('activity', sessionStorage.getItem('activityTitle') || sessionStorage.getItem('workTitle') || '')
    }, [])

    return (
        <>
            {
                <a hidden>
                    <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />
                </a>
            }
            {
                <LinkTaskModal details={linkDetails} setDetails={setLinkDetails} id={linkID} setID={setLinkID} />
            }

            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}> {/* Add ref to the form */}
                <Grid container spacing={2} mt={0.1}>
                    <Grid item width={'49%'}>
                        <a onChange={handleActivity}>
                            <TextInput
                                autofocus
                                control={control}
                                placeholder={"Add Activity"}
                                name="activity"
                                value={watch('activity')}
                                onKeyDown={handleKeyPress}
                            />
                        </a>
                    </Grid>
                    <Grid mt={-0.5} item width={'25%'}>
                        <SelectX
                            placeholder={'Project'}
                            options={props?.open && fetchProjects}
                            control={control}
                            error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                            error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                            name={'projects_id'}
                            defaultValue={watch('projects_id')}
                        />
                    </Grid>
                    <Grid item width={'8%'}>
                        <TextInput
                            // autofocus
                            control={control}
                            placeholder={"Hours"}
                            name="hours"
                            value={watch('hours')}
                        />

                    </Grid> <Grid item width={'8%'}>
                        <TextInput
                            // autofocus
                            control={control}
                            placeholder={"Minutes"}
                            name="minutes"
                            value={watch('minutes')}
                            onKeyPress={handleKeyPress}
                        />
                    </Grid>
                    <Grid item width={'8%'}>
                        <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                            variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                    </Grid>
                </Grid>
            </form >

            <TableContainer component={Paper} style={{ marginTop: '20px', overflowY: 'scroll', height: 250, maxHeight: 250 }}>
                <Table>
                    {/* <TableHead>
                        <TableRow>
                            <TableCell width={'60%'}>Minutes</TableCell>
                            <TableCell>Assigned To</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead> */}
                    <TableBody>

                        {
                            props?.activities.length > 0 ?
                                props.activities?.map((task) => (
                                    <TableRow style={{ borderBottom: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                                        <TableCell style={{ borderRight: '1px solid #ccc', height: 40 }} width={'70%'}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={9}>
                                                    <a style={{ marginLeft: 4 }}>{task.title}</a>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: 'right' }}>
                                                    {activityTask ?
                                                        <a onClick={() => setActivityTask(!activityTask)}>
                                                            <Task style={{ cursor: 'pointer', color: 'green', marginRight: 20 }} />
                                                        </a> :
                                                        <a onClick={() => linkTask(task.id, task)}>
                                                            <AddTask style={{ cursor: 'pointer', marginRight: 20 }} />
                                                        </a>
                                                    }
                                                    <a onClick={() => openActivityEdit(task.id)}>
                                                        <Edit style={{ cursor: 'pointer' }} color='primary' />
                                                    </a>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell style={{ height: 40 }}>
                                            <a style={{ marginLeft: 4 }}>
                                                {task.duration_hours ? task.duration_hours + " Hour" : ''}
                                                {task.duration_hours && task.duration_minutes ? ", " : ''}
                                                {task.duration_minutes ? task.duration_minutes + ' Minutes' : ''}
                                            </a>
                                        </TableCell>
                                    </TableRow>

                                ))
                                :
                                <Grid mt={8} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <a>
                                        No Activity Found For Today.
                                    </a>
                                </Grid>
                        }

                    </TableBody>
                </Table>
            </TableContainer>

        </>

    );
};

export default ActivityTab;
