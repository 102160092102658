import React, { useEffect, useState } from 'react';
import { useAppSettings } from "../../../hooks/use-app-settings";
import moment from "moment";
import { Backdrop, Button, Card, CircularProgress, Grid, TextField } from "@mui/material";
import { PreQualifiers } from "../../../api/Endpoints/PreQualifiers";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Account } from "../../../api/Endpoints/Account";
import toast from "react-hot-toast";
import Box from '@mui/material/Box';
import Create from './create';
import BasicTabs from './TabMenus';
import { VideoShootApi } from '../../../api/Endpoints/VideoShoot';
import { Edit } from '@mui/icons-material';
import Delete from '../common/Popup/Delete';
import DeleteIcon from '@mui/icons-material/Delete';
import { Project } from '../../../api/Endpoints/Project';
import { useForm } from 'react-hook-form';
import AsyncSelect from "react-select/async";
import ReactSelector from 'react-select';




function VideoShoots() {

    const { watch, formState: { errors }, setValue, getValues, } = useForm();

    const listStatus = [
        { label: "Proposed" },
        { label: 'Planned' },
        { label: 'Shooting Completed' },
        { label: 'Editing in Progress' },
        { label: 'Editing Completed' },
        { label: 'Live on Channels' },
        { label: 'Completed' },
        { label: 'Cancelled' },
    ]

    const [accounts, setAccounts] = useState([]);
    const [list, setList] = useState([])
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [detailViewId, setDetailViewId] = useState(false)
    const [deleteId, setDeleteId] = useState(false)
    const [selectedProject, setSelectedProject] = useState()
    const [selectedClient, setSelectedClient] = useState()
    const [selectedStatus, setselectedStatus] = useState()



    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    // const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }

    const handleDelete = (id) => {
        console.log(id);
        setDeleteId(id)
    }

    const fetchList = () => {
        console.log(selectedProject?.id);
        setLoading(true);
        VideoShootApi.get({ page: Number(pageNumber) + 1, project_id: selectedProject?.id ,status:selectedStatus}).then(response => {
            console.log(response);
            setList(response.data);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        })
    }

    const handleCreateNew = () => {

    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const viewTabs = () => {
        setTabs(true)
    }

    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }

    const DetailViewOpen = (id) => {
        setDetailViewId(id)
    }

    const columns = [
        {

            field: 'title', headerName: 'title', width: 250,
            renderCell: (params) => <p style={{ cursor: 'pointer', color: 'blue' }} onClick={() => DetailViewOpen(params.row.id)}>{params.value}</p>

        }, {
            field: 'project', headerName: 'Project name', width: 200,
            renderCell: (params) => <p style={{ cursor: 'pointer' }} >{params.value?.name}</p>
        },
        {
            field: 'created_user', headerName: 'User Name', width: 150,
            renderCell: (params) => <p>{params.value?.name}</p>
        },
        {
            field: 'shooting_date', headerName: 'Shoot Date', width: 150, renderCell: (params) => {
                return params.value ? moment(params.value).format('DD-MM-YYYY') : ''
            }
        },
        // {
        //     field: 'shoot_end_date', headerName: 'End Date', width: 100, renderCell: (params) => {
        //         return params.value?moment(params.value).format('DD-MM-YYYY'):''
        //     }
        // },
        {
            field: 'status', headerName: 'Status', width: 160,
            renderCell: (params) => <p>{params.value}</p>
        },
        {
            field: 'expenses', headerName: 'Expenses', width: 150,
            renderCell: (params) => <p>{params.value}</p>
        },
        {
            field: 'action', headerName: '', width: 50, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><a
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEdit(id)}><Edit color='primary' fontSize='small' /></a>
                </>;
            }
        },
        // {
        //     field: 'Delete',
        //     headerName: '',
        //     width: 80,
        //     renderCell: (params) => (
        //         <>
        //             <a size={'small'}
        //                 style={{ cursor: 'pointer' }}
        //                 variant='outlined'
        //                 color='error'
        //                 onClick={() => handleDelete(params?.row?.id)}>
        //                 <DeleteIcon fontSize='small' color='error' />
        //             </a>
        //         </>
        //     )
        // }

    ]

    const fetchProject = (e) => {
        return Project.get({ keyword: e }).then(response => {
            // console.log(response);
            if (typeof response.data.data.data !== "undefined") {
                return (response.data.data.data)
            } else {
                return [];
            }
        })
    }

    const selectProject = (data) => {
        if (data) {
            setValue('project', { id: data?.id || '', name: data?.name || '' })
        } else {
            setValue('project', '')
        }
        setSelectedProject(data)
    }

    const handleSelectClient = (data) => {
        if (data) {
            setValue('client', { id: data?.id || '', name: data?.name || '' })
        } else {
            setValue('client', '')
        }
        setSelectedClient(data)
    }

    const handleSelectStatus = (data) => {
        if (data) {
            setValue('status', data)
        } else {
            setValue('status', '')
        }
        setselectedStatus(data)
    }

    useEffect(() => {
        fetchList()
    }, [searchKeyword, listType, pageNumber, selectedProject,selectedStatus])


    return (
        <>

            {
                detailViewId &&
                <BasicTabs fetchList={fetchList} id={detailViewId} handleClose={setDetailViewId} />
            }

            {deleteId && <Delete
                ID={deleteId}
                setID={setDeleteId}
                setDeletePopup={setDeleteId}
                Callfunc={() => fetchList()}
                url="/projects/video-shoots/delete?id="
                title="Video Shoot" />}

            <PageHeader title={'Video Shoots'} onCreateNew={handleCreateNew} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Grid sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'space-between' }} variant="outlined" >
                <Grid display={'flex'}>
                    <Grid mr={2} sx={{ width: 250 }}>
                        <AsyncSelect
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isClearable={true}
                            defaultOptions
                            name='project'
                            loadOptions={fetchProject}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            placeholder={'Project'}
                            value={watch('project')}
                            onInputChange={fetchProject}
                            onChange={selectProject}
                        />
                    </Grid>
                    {/* <Grid mr={2} sx={{ width: 200 }}>
                        <AsyncSelect
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isClearable={true}
                            defaultOptions
                            name='client'
                            loadOptions={fetchProject}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            placeholder={'Client'}
                            value={watch('client')}
                            onInputChange={fetchProject}
                            onChange={handleSelectClient}
                        />
                    </Grid> */}

                    <Grid mr={2} sx={{ width: 250 }}>
                        <ReactSelector
                            placeholder="Status"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={listStatus}
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.label}

                            // inputValue={inProject}
                            value={
                                listStatus.filter(options =>
                                    options.label === watch('status')
                                )
                            }
                            name='status'
                            isClearable
                            defaultValue={(watch('status'))}
                            onChange={(selectedOption) => handleSelectStatus(selectedOption?.label)}
                        />
                    </Grid>
                </Grid>

                <Create setDetailView={setDetailViewId} key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />

            </Grid >

            <Card sx={{ m: 2 }} variant="outlined">

                {typeof list?.data === "object" &&
                    <DataGrid
                        paginationMode="server"
                        rows={list?.data}
                        columns={columns}
                        page={pageNumber}
                        pageSize={list?.meta?.per_page}
                        rowHeight={20}
                        // rowsPerPageOptions={[list?.meta?.per_page]}
                        rowCount={list?.meta?.total}
                        onPageChange={handlePageChange}
                        autoHeight
                        density={"comfortable"}
                        getRowId={(row) => row?.id || row?.title || row?.shoot_start_date || /* add more properties to check */ undefined}
                    />

                }
            </Card>



        </>
    )
}



export default VideoShoots


