import { Button, Card, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PageHeader from '../common/page-header'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Activities } from '../../../api/Endpoints/Activities';
import { Edit } from '@mui/icons-material';

function LearningMaterial() {
    const {watch} = useForm()
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            field: 'titleh', headerName: 'Title', width: 350,
        },
        {
            field: 'addedBy', headerName: 'Added By', width: 350,
        },
        {
            field: 'createdDate', headerName: 'Created Date', width: 350,
        },
        {
            field: 'action', headerName: 'Edit', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><a
                    style={{ cursor: 'pointer' }}
                    ><Edit color='primary' fontSize='small' /></a>
                </>;
            }
        },
    ]

    const fetchTable = () => {
        setLoading(true);
        let from = moment(watch('from')).format('YYYY-MM-DD')
        let to = moment(watch('to')).format('YYYY-MM-DD')

        Activities.get({  limit: 1 }).then(response => {
            setList(response.data.data);
            setLoading(false);
        })
    }
    useEffect(() => {
        fetchTable()
    },[])

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>

<PageHeader title={"Learning Material"} />

<Grid display={'flex'} mr={3}>
    <TextField
        style={{ width: 300, marginRight: 10 }}
        size='small'
        id="outlined-name"
        label="Search"
        // onChange={handleSearchEntry}

    />
<Button variant="contained">
+ Add 
</Button>
    {/* <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} /> */}
</Grid>

</Grid>

<Card sx={{ m: 2, height: 200, position: 'relative' }} variant="outlined">
                {typeof list.data === "object" && (
                    <>
                        <DataGrid
                            paginationMode="server"
                            rows={list?.data}
                            columns={columns}
                            // page={pageNumber}
                            pageSize={list?.per_page}
                            rowHeight={20}
                            // autoHeight
                            // rowsPerPageOptions={[10]}
                            rowCount={list?.total}
                            // onPageChange={handlePageChange}
                            density={"comfortable"}
                        />
                        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', position: 'absolute', bottom: 16, left: '77%', transform: 'translateX(-50%)', zIndex: 1000 }}>
                            <label htmlFor="pageNumber" style={{ marginRight: '10px' }}>Go to Page:</label>
                            <select
                                id="pageNumber"
                                // value={pageNumber + 1}
                                // onChange={handlePageNumberChange}
                            >
                                {Array.from({ length: Math.ceil(list?.last_page) }, (_, i) => (
                                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                                ))}
                            </select>
                        </div>
                    </>
                )}
            </Card>

    </>
  )
}

export default LearningMaterial
