import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Card, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BasicTabs from './TabMenus';
import { CircularProgress, Grid } from '@mui/material';
import { CheckCircle, Close, Warning } from '@mui/icons-material';
import moment from 'moment';
import { useState } from 'react';
import { Users } from '../../../api/Endpoints/Users';
import toast from 'react-hot-toast';
import { Employee } from '../../../api/Endpoints/Employee';
import { UserIdContext } from '../../../contexts/UserID/UserID';
import { useEffect } from 'react';
import TextInput from '../../form/TextInput';
import { useForm } from 'react-hook-form';
import ReactSelector from 'react-select';
import MissedSign from './Tabs/missedSign';


const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
        // height:'100%'
    },
    // title: {
    //     fontWeight: 'bold',
    // },
    // divider: {
    //     margin: theme.spacing(2, 0),
    // },
    // select: {
    //     minWidth: 200,
    // },
}));



export default function MissedSignInButton(props) {

    const { UserID, SignIn } = UserIdContext();

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [totalHours, setTotalHours] = useState(0)
    const [totalMinutes, setTotalMinutes] = useState(0)
    const [loading, setloading] = useState(false)
    const [time, setTime] = useState(moment().format('D MMM YYYY HH:mm:ss A'))
    const [currentTime, setcurrentTime] = useState(moment().format('HH:mm'))
    const [isLate, setIsLate] = useState(false)
    const [Ip, setIp] = useState()
    const [signInData, setSignInData] = useState(null);
    const [missedSignedIn, setMissedSignedIn] = useState(false)


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '97%',
        bgcolor: 'background.paper',
        //   border: '2px solid #000',
        boxShadow: 24,
        p: 2,
        borderRadius: 1
    };

    const checkAttendance = async () => {
        let response = await Employee.checkAttendence({ date: moment().format("DD-MM-YYYY") });
        // console.log(response);
        if (response.data.status === "success") {
            setSignInData(response.data.data);

        } else {
            setSignInData();
        }
        // console.log("checkAttendance", response);
    }


    const handleClose = (event, reason) => {
        if (reason !== "backdropClick") {
            setOpen(false);
            // props.setOpen(false)
            sessionStorage.clear()
        }
    }



    // const getHours = (data) => {
    //     console.log(data);
    //     let totalDuration = moment.duration(); 

    //     data.forEach((item) => {
    //         const duration = moment.duration({
    //             hours: item.duration_hours,
    //             minutes: item.duration_minutes,
    //         });

    //         totalDuration.add(duration); 
    //     });

    //     setTotalHours(totalDuration.hours())
    //     setTotalMinutes(totalDuration.minutes())
    // }
    const getIp = async () => {
        let ip = await fetch('https://geolocation-db.com/json/').then(response => {
            return response.json();
        });
        setIp(ip.IPv4)
    }
    const handleSignIn = async () => {
        // setSignInButtonLoading(true);
        // setloading(true)
        // let ip = await fetch('https://geolocation-db.com/json/').then(response => {
        //     return response.json();
        // });
        // console.log("IP", ip)
        // let user = await Users.me();

        // let data = {
        //     // task_list: selectedTasks,
        //     logged_in_ip: ip.IPv4,
        //     users_id: UserID
        // }
        // let resopnse = await Employee.signIn(data);

        // if (resopnse.data.status === "error") {
        //     console.log(resopnse.data.message);
        //     toast.success(resopnse.data.message);
        //     // window.location.reload();
        // }

        // if (resopnse.data.status === "success") {
        //     toast.success("Successfully signed in");
        //     window.location.reload();
        // }
        // // setSignInButtonLoading(false);
        // setloading(false)
    }

    const options = [
        { name: 'Office' },
        { name: 'Home' },
        { name: 'Client Site' },

    ]

    const updateCurrentTime = () => {
        setTime(moment().format('D MMM YYYY HH:mm:ss A'));
        setcurrentTime(moment().format('HH:mm'))
    };

    useEffect(() => {
        // Update the time initially
        updateCurrentTime();

        // Set up an interval to update the time every second
        const intervalId = setInterval(updateCurrentTime, 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    const getLate = (data) => {
        setIsLate(data)
    }

    useEffect(() => {
        getIp()
        checkAttendance()
    }, [])

    return (
        <div>
            <Button variant="contained" onClick={handleOpen}>Missed Sign In</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disable
            >
                <Box sx={style}>
                    <Box sx={{ border: 'solid', padding: 2, borderWidth: 1, height: '99%', borderRadius: '3px' }}>
                        <Grid display={'flex'} justifyContent={'space-between'}>
                            <Typography id="modal-modal-title" variant="h4" component="h2">
                                Missed Sign In/Out
                            </Typography>
                            <Button onClick={handleClose}><Close sx={{ cursor: 'pointer' }} /></Button>
                        </Grid>
                        <Grid xs={8} sm={8}>
                            <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: '18px' }}>
                                You missed to Sign In/Out in the past.Please Correct them now to proceed with manager's approval
                            </Typography>

                        </Grid>

                        <Box >
                            <Box mt={4}>
                                <MissedSign />
                                {/* <BasicTabs getLate={getLate} missedSign={missedSignedIn} /> */}

                                <Grid mt={1} ml={1}>
                                   

                                    <Grid style={{ position: 'absolute', bottom: 30 }} mt={1} display={'flex'}>
                                        <Grid display={'flex'} alignItems={'center'} width={400}>
                                            <a style={{ marginRight: 10, fontSize: '14px' }}>Sign In Time:</a>
                                            <Grid width={250}>
                                                <a style={{ fontSize: '14px', fontStyle: 'italic', fontWeight: '500' }}>
                                                    {signInData ? moment(signInData?.created_at).format("D MMM YYYY HH:mm:ss A") : time}
                                                </a>
                                            </Grid>
                                        </Grid>

                                        <Grid display={'flex'} alignItems={'center'} width={350}>
                                            <a style={{ marginRight: 10, fontSize: '14px' }}>IP Address:</a>
                                            <Grid width={250}>
                                                <a style={{ fontSize: '14px', fontStyle: 'italic', fontWeight: '500' }}>
                                                    {Ip}
                                                </a>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>


                                <div style={{ width: '95%', display: 'flex', justifyContent: 'end', position: 'absolute', bottom: 30, right: 50 }}>
                                    <Button onClick={handleSignIn} variant="contained" style={{ width: 'fit-content' }}>
                                        {loading ? <CircularProgress size={20} color="inherit" /> : "Submit"}
                                    </Button>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div >
    );
}