import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    TextareaAutosize,
    Select,
    TextField,
    Slide, Typography
} from "@mui/material";
import MonthPickerComponent from '../../form/Monthpicker';
import { DatePicker } from '@mui/lab';
import * as yup from "yup";
import dayjs from 'dayjs';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from '../../form/TextInput';
import DateInput from '../../form/DateInput';
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import SelectX from '../../form/SelectX';
import moment from "moment";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import { Account } from "../../../api/Endpoints/Account";
import CurrentDateInput from '../../form/CurrenDateInput';
import { Project } from "../../../api/Endpoints/Project";
import ReactSelector from 'react-select';
import { faHouseFloodWaterCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import Creatig from '../../form/creatig';
import { Employee } from '../../../api/Endpoints/Employee';
import { CreativeCalenderApi } from '../../../api/Endpoints/CreativeCalender';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({


    // title: yup.string().required(),
    // description: yup.string(),
    // start_date: yup.string(),
    // end_date: yup.string(),
    // due_date: yup.string(),
    // Owner_Id: yup.object(),
    // assigned_by_id: yup.object(),
    // projects_id: yup.object().shape({
    //     id: yup.string().required("Please choose a project").typeError("Please choose a project")
    // }).required().typeError("Please choose a project"),
    // reviewer_id: yup.object().shape({
    //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    // }).required().typeError("Please choose a reviewer"),
})


const CurrentStatus = [
    { value: "Not Started", label: 'Not Started' },
    { value: "Development In Progress", label: 'Development In Progress' },
    { value: "Under Maintenance", label: 'Under Maintenance' },
    { value: "Testing In Progress", label: 'Testing In Progress' },
    { value: "Completed", label: 'Completed' },
    { value: "Live ", label: 'Live ' },
];

const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({})
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [clientId, setClientId] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clients, setClients] = useState([]);
    const [projects, setProjects] = useState([]);

    const handleClose = () => {
        props.setEditId()
        setOpen(false);
    };

    const handleClickOpen = () => {
        props.onNew()
    };
    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })
    const fetchClients = (e) => {
        const client_id = watch('client_id');
        setClientId(client_id?.id);
        return Account.get({ keyword: e }).then((response) => {
            if (typeof response.data.data.data !== 'undefined') {
                return response.data.data.data;
            } else {
                return [];
            }
        });
    };
    const fetchProjects = async (e) => {
        try {
            const response = await Project.get({ keyword: e });
            if (typeof response.data.data.data !== 'undefined') {
                return response.data.data.data;
            } else {
                return [];
            }
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    const prioriyTask = () => {

    }

        ;

    const handlePriorityChange = (id) => {
        setSelectedPriority(id);
    }


    const approvelstatus = [
        { value: "Not Started", label: 'Not Started' },
        { value: 'In Progress', label: 'In Progress' },
        { value: "Completed", label: 'Completed' },
        { value: 'Manager Approved', label: 'Manager Approved' },
        { value: 'Needs Revision', label: 'Needs Revision' },
        { value: 'Client Approved', label: 'Client Approved' },
        { value: 'Client Rejected', label: 'Client Rejected' },
        { value: 'Ready To Publish', label: 'Ready To Publish' },
        { value: 'Published', label: 'Published' },

    ];

    const yearList = [];
    for (let year = 2024; year <= 2030; year++) {
        yearList.push({ value: year.toString(), label: year.toString() });
    }

    const Months = [
        {
            value: "January ", label: "January"
        },
        {
            value: "February", label: "February"
        },
        {
            value: "March", label: "March"
        },

        {
            value: "April", label: "April"
        },
        {
            value: "May", label: "May"
        },
        {
            value: "June", label: "June"
        },
        {
            value: "July", label: "July"
        },
        {
            value: "August", label: "August"
        },
        {
            value: "September", label: "September"
        },
        {
            value: "October", label: "October"
        },
        {
            value: "November", label: "November"
        },
        {
            value: "December", label: "December"
        },



    ];

    const types = [
        {
            value: "Social Media", label: "Social Media"
        },
        {
            value: "Website", label: "Website"
        },
        {
            value: "General", label: "General"
        },
    ]





    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let dataToSubmit = {
            id: props.editId,
            projects_id: data?.project?.id,
            name: data.calender_name,
            // calendar_month: data.month,
            // calendar_year: data.year,
            // reports_to_id: data.manager?.id,
            // status: data.status
        };

        console.log(dataToSubmit);
        let action;

        if (props.editId > 0) {
            // console.log("dataToSubmit", dataToSubmit);
            action = CreativeCalenderApi.update(dataToSubmit)
        } else {
            action = CreativeCalenderApi.add(dataToSubmit)
        }
        action.then(response => {
            console.log("this is response data", response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            if (response?.data?.status == 'success') {
                props.setValue('calendar', { id: response?.data?.data?.id || '', name: response?.data?.data?.name || '' })
                props?.setCalendarID(response?.data?.data?.id)
                props?.setselectedCalendar(response?.data?.data)
                // if (props.editId > 0) {
                //     const project = {
                //         name: props.projectName
                //     }
                //     props?.setselectedCalendar(...project)
                // }
                // } else {
                //     props.handleCloseOthers()
                // }
                props.setRefresh(Math.random * 0.2)
                props.setEditId()
                props.onUpdate();
                setOpen(false);
            }
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    const fetchEmployees = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data
            } else {
                return [];
            }
        })
    }



    // useEffect(() => {
    //     if (parseInt(props.editId) > 0) {
    //         setOpen(true);
    //     } else if (Number(props.editId) === 0) {
    //         console.log("edit id is false");
    //         setOpen(true);
    //     }

    // }, [props.editId])

    // useEffect(() => {
    //     fetchClients()
    //         .then((data) => {
    //             setClients(data);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }, []);

    const fetchDetails = async () => {
        setLoading(true)
        try {
            let calender = await CreativeCalenderApi.getDetails({ id: props.editId });
            console.log(calender);
            if (calender?.data?.data) {
                let data = calender?.data?.data;
                setValue('calender_name', data.name);
                setValue('project', data.project);
                // setValue('month', data?.calendar_month);
                // setValue('year', data?.calendar_year);
                // setValue('manager', data?.report_to);
                // setValue('status', data?.status);
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            console.log(props.editId);
            fetchDetails();
            setOpen(true);
        } else if (parseInt(props.editId) === 0) {
            setOpen(true);
        }

    }, [props.editId])

    return (
        <div>
            {
                props?.editId > 0 ? ''
                    :
                    <Button variant="outlined" onClick={handleClickOpen}>
                        {props.icon ? <AddIcon style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }} /> : "Create Calendar"}
                    </Button>
            }

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Calender" : "Create Calendar"}</DialogTitle>
                <DialogContent>
                    {!loading ?
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />

                            <Grid container spacing={2} sx={{ display: 'flex', mt: 1 }}>
                                <Grid item xs={12}>
                                    <TextInput
                                        control={control}
                                        name="calender_name"
                                        label="Calendar Name"
                                        value={watch('project_name')}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SelectX
                                        defaultOptions
                                        label={"Choose a Project"}
                                        options={fetchProjects}
                                        control={control}
                                        name={'project'}
                                        defaultValue={watch('project')}
                                    />
                                </Grid>

                                {/* <Grid p={1} container sx={12} sm={12}>

                                    <Grid sx={{ p: 1 }} item xs={6} sm={6}>
                                        <InputLabel sx={{
                                            color: "black",
                                            pb: 0.5,
                                            fontSize: '14px',
                                            fontWeight: 500
                                        }}></InputLabel>
                                        <ReactSelector
                                            options={types}
                                            name='types'
                                            value={
                                                types.filter(options =>
                                                    options.label === watch('types')
                                                )
                                            }
                                            isClearable={true}
                                            defaultValue={(watch('types'))}
                                            onChange={(selectedOption) => setValue('types', selectedOption?.value || '')}
                                        />


                                    </Grid>

                                </Grid> */}

                                {/* <Grid p={1} container sx={12} sm={12}>

                                    <Grid sx={{ p: 1 }} item xs={6} sm={6}>
                                        <InputLabel sx={{
                                            color: "black",
                                            pb: 0.5,
                                            fontSize: '14px',
                                            fontWeight: 500
                                        }}>Calendar Month</InputLabel>
                                        <ReactSelector
                                            options={Months}
                                            name='month'
                                            value={
                                                Months.filter(options =>
                                                    options.label === watch('month')
                                                )
                                            }
                                            isClearable={true}
                                            defaultValue={(watch('month'))}
                                            onChange={(selectedOption) => setValue('month', selectedOption?.value || '')}
                                        />


                                    </Grid>

                                    <Grid sx={{ p: 1 }} item xs={6} sm={6}>
                                        <InputLabel sx={{
                                            color: "black",
                                            pb: 0.5,
                                            fontSize: '14px',
                                            fontWeight: 500
                                        }}>Calendar Year</InputLabel>
                                        <ReactSelector
                                            options={yearList}
                                            name='year'
                                            value={
                                                yearList.filter(options =>
                                                    options.label == watch('year')
                                                )
                                            }
                                            isClearable={true}
                                            defaultValue={(watch('year'))}
                                            onChange={(selectedOption) => setValue('year', selectedOption?.value || '')}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid p={1} container display={'flex'} justifyContent={'space-between'} sx={12} sm={12}>

                                    <Grid p={1} item xs={6}>
                                        <SelectX
                                            defaultOptions
                                            label={"Manager"}
                                            options={fetchEmployees}
                                            control={control}
                                            name={'manager'}
                                            defaultValue={watch('manager')}
                                        />
                                    </Grid>
                                    <Grid p={1} item xs={6} sm={6}>
                                        <InputLabel sx={{
                                            color: "black",
                                            pb: 0.5,
                                            fontSize: '14px',
                                            fontWeight: 500
                                        }}>Approval Status</InputLabel>
                                        <ReactSelector
                                            options={approvelstatus}
                                            value={
                                                approvelstatus.filter(options =>
                                                    options.label === watch('status')
                                                )
                                            }
                                            name='status'
                                            isClearable={true}
                                            defaultValue={(watch('status'))}
                                            onChange={(selectedOption) => setValue('status', selectedOption.value)}
                                        />
                                    </Grid>
                                </Grid> */}


                                <Grid sx={{ p: 1, mt: 1 }} mb={5} item xs={12}>
                                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                        :
                        <>Loading...</>
                    }

                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        </div >
    );
};

export default Create
