import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import ReactSelector from 'react-select';

import {
    Button,
    Box,
    Modal,
    InputLabel,
    Grid,
    TextField,

} from "@mui/material";
import toast from 'react-hot-toast';
import { Project } from '../../../../../api/Endpoints/Project';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';
import { DotSpinner } from '@uiball/loaders';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '85vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function Create({ fetchNotes, projectID, editID, viewDetails, setViewDetails, ID, setID, setEditId, setViewOnly, viewOnly, fetch }) {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({})
    const [open, setOpen] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setEditId(0)
        setID(0)
        setViewDetails(false)
        setOpen(false);
        setViewOnly(false)
        reset()

    };
    useEffect(() => {
        if (editID > 0) {
            fetchDetails()
            setOpen(true)
        } else if (viewDetails && ID > 0) {
            fetchDetails()
            setOpen(true)
        }
    }, [editID, ID])
    const fetchDetails = async () => {
        setLoading(true)
        let response = await Project.getNoteDetails({ id: editID || ID });
        if (response.data.status === "success") {
            let data = response.data.data;
            setValue('title', data.title);
            setValue('note_type', data.note_type)
            setValue('description', data.description);
        }
        setLoading(false)
    }



    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        console.log(data);

        let dataToSubmit = {
            projects_id: projectID,
            title: data.title,
            note_type: data.note_type,
            // description: data.description ? data.description : ''
        };


        let action;

        if (editID > 0) {
            dataToSubmit.id = editID
            action = Project.updateNote(dataToSubmit);
        } else {
            action = Project.addNotes(dataToSubmit);
        }
        action.then(response => {
            toast.success(response.data.message)
            fetchNotes()
            setViewOnly(false)
            handleClose()
            fetch()
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");


        })
        setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
    }



    const Note_type = [
        { value: 'General Notes', label: 'General Notes' },
        { value: 'Scope Change', label: 'Scope Change' },
        { value: 'Sticky Note', label: 'Sticky Note' },
        { value: 'Milestone', label: 'Milestone' },
        // { value: 'Start Project', label: 'Start Project' },
        // { value: 'Stop Project', label: 'Stop Project' },
        // { value: 'Restart Project', label: 'Restart Project' },
        { value: 'Fees Change', label: 'Fees Change' },
        { value: 'Budget Change', label: 'Budget Change' },
        { value: 'Site Visit', label: 'Site Visit' },
        { value: 'Performance Concern', label: 'Performance Concern' },
        { value: 'Customer Complaint', label: 'Customer Complaint' },
    ];
    return (
        <div>
            <div className='flex' style={{ width: '100', display: 'flex', justifyContent: "end", marginRight: 10 }}>
                <Button variant="contained" sx={{ width: "180px" }} onClick={handleOpen}>
                    Add Note
                </Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        {!loading ? (


                            <form onSubmit={handleSubmit(onSubmit)}>

                                {/* First Field */}
                                <Grid item xs={12}>

                                    <InputLabel sx={{
                                        color: "black",
                                        mt: 1,
                                        mb: 1
                                    }}>Note Type
                                    </InputLabel>


                                    <ReactSelector
                                        options={Note_type}
                                        value={Note_type.filter(
                                            (Note_type) => Note_type.label === watch('note_type')
                                        )}
                                        name='note_type'
                                        // placeholder={'Note Type'}
                                        defaultValue={watch('note_type')}
                                        isClearable={true}
                                        onChange={(selectedOption) => setValue('note_type', selectedOption?.value)}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isDisabled={viewOnly ? true : false}
                                    />


                                </Grid>
                                <Grid item xs={12} mt={2}>
                                    <TextField
                                        multiline
                                        rows={3}
                                        label='Title'
                                        name='title'
                                        {...register('title')}
                                        fullWidth
                                        defaultValue={watch('title')}
                                        disabled={viewOnly ? true : false}
                                    />
                                </Grid>

                                {/* <Grid item xs={12} mt={4}>
                                    <InputLabel sx={{
                                        color: "black",
                                        pb: 1
                                    }}>Description </InputLabel>
                                    <TextField
                                        {...register('description')}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        sx={{ width: '100%', }}
                                        disabled={viewDetails ? true : false}
                                    />
                                </Grid> */}
                                <div style={{ display: "flex", justifyContent: "end", paddingTop: 10 }}>
                                    <Button
                                        onClick={handleClose}
                                        variant="outlined"
                                        color='error'
                                        sx={{ mr: 1 }}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        loading={formButtonStatus.loading}
                                        disabled={formButtonStatus.disabled}
                                        variant="outlined"
                                        type="submit"
                                    >
                                        {formButtonStatus.label}
                                    </LoadingButton>


                                </div>
                            </form>
                        ) : (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '30vh'
                            }}>
                                < DotSpinner
                                    size={40}
                                    speed={0.9}
                                    color="blue"
                                />

                            </Box >
                        )
                        }
                    </Box>
                </Modal>
            </div>
        </div >
    );
}

export default Create;
