import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, Slide, TextField, Typography, } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { DialogTitle, makeStyles } from '@material-ui/core';
import { Task } from '../../../../api/Endpoints/Task';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { DeleteForever, Edit } from '@material-ui/icons';
import Delete from '../../common/Popup/Delete';
import parse from 'html-react-parser';
import { Auth } from '../../../../api/Endpoints/Auth';
import { Users } from '../../../../api/Endpoints/Users';
import { DotSpinner } from '@uiball/loaders';
import ReactSelector from 'react-select';
import { useForm } from 'react-hook-form';
import DateInput from '../../../form/DateInput';
import toast from 'react-hot-toast';
import { UserIdContext } from '../../../../contexts/UserID/UserID';
import { Done } from '@mui/icons-material';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const divider = {
    marginTop: 2,
}

const DetailView = (props) => {


    const { UserID, UserName, ID } = UserIdContext();

    const { control, watch, formState: { errors }, setValue, getValues, } = useForm();

    const classes = useStyles();
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const [open, setOpen] = React.useState(true);
    // const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => {
        props.handleClose(false)
        // setOpen(false); 
    };
    const [details, setDetails] = useState()
    const [loading, setLoading] = useState(false);
    const [Notes, setNotes] = useState([])
    const [deleteID, setDeleteID] = useState(false)
    const [refresh, setRefresh] = useState()
    const [statusOpen, setStatusOpen] = useState()
    const [copiedText, setcopiedText] = useState('Copy Link')

    const [currentUrl, setCurrentUrl] = useState();


    const handleStatusClose = () => {
        setStatusOpen(false)
    }

    const handleEdit = (id) => {
        console.log(id);
        props.setEditId(id)
        props.setTab(1)
    }

    const handleRefresh = () => {
        setRefresh(Math.random)
    }

    const fetchTaskDetails = () => {
        console.log(props.id);
        setLoading(true)
        Task.getTaskDetails({ id: props.id }).then((response) => {
            // fetchNotes()
            setValue('status', response?.data?.data?.status)
            console.log(response);
            setDetails(response.data.data)
            setLoading(false)
            console.log(response.data.data);
        }).catch(errors => {
            console.log(errors);
        })

    }

    const fetchNotes = () => {
        Task.getNote({ project_tasks_id: props.id }).then((notes) => {
            console.log(notes);
            setNotes(notes.data.data.data)
        })
    }
    console.log(details?.check_lists?.length); // Add this line to log params.row
    console.log(details?.check_lists?.filter(item => item.is_completed === 1).length);

    const deleteNote = (id) => {
        setDeleteID(id)
    }


    const listStatus = [
        { id: 1, name: "Not Started", color: "rgb(196, 196, 196)" },
        { id: 2, name: "In progress", color: "rgb(253, 171, 61)" },
        { id: 3, name: "Review pending", color: "#e8c8ff" },
        { id: 4, name: "Review Failed", color: "#b84bff" },
        { id: 5, name: "Completed", color: "#00c875" },
    ]

    const getStatusOptions = () => {
        const userIsAssignedUser = UserID === details?.assigned_to_id;
        const userIsReviewer = UserID === details?.reviewer_id;

        if (userIsAssignedUser && userIsReviewer) {
            return listStatus; // Show all status options
        } else if (userIsAssignedUser) {
            return [
                listStatus[0], // Not Started
                listStatus[1], // In Progress
                listStatus[2] // Review Pending
            ];
        } else if (userIsReviewer) {
            return [
                listStatus[3], // Review Failed
                listStatus[4] // Completed
            ];
        }
        return listStatus;
    };

    const handleStatusChange = (data) => {
        if (data != watch('status')) {
            setStatusOpen(data)
        }
    }

    const handleStatusSubmit = async () => {
        const loadingToast = toast.loading('Changing...');
        let change = await Task.changeStatus(
            {
                task_id: details?.id,
                status: statusOpen,
                status_note: watch('statusNote')
            });

        if (change.data.status === "success") {
            toast.dismiss(loadingToast);
            console.log(change);
            toast.success(change.data.message)
            fetchTaskDetails()
            handleStatusClose()
            props.fetch()
            // handleStatusPopoverClose()
        } else {
            toast.dismiss(loadingToast);
            toast.error(change.data.message)
        }
        setOpen(false)
    }


    const handleCopyLink = async () => {
        setcopiedText('Copied')
        await navigator.clipboard.writeText(currentUrl);

        setTimeout(() => {
            setcopiedText('Copy Link')
        }, 2000);
    }

    useEffect(() => {
        props?.setEditId()
        fetchTaskDetails()
        // fetchNotes()
    }, [props?.id, props?.refresh])

    useEffect(() => {
        if (props.id) {
            const url=window.location.href 
          if (url.includes('?id=')) {
            setCurrentUrl(url);
          }else{
            setCurrentUrl(window.location.href + '?id=' + props?.id)
          }
        }
      }, [props.id, currentUrl]);

    return (
        <>
            {
                deleteID &&
                <Delete
                    ID={deleteID}
                    setID={setDeleteID}
                    setDeletePopup={setDeleteID}
                    Callfunc={() => fetchNotes()}
                    url="/projects/tasks/notes/delete?id="
                    title="Note"
                />
            }

            {/* <Grid container spacing={2}>
                <Grid display={'flex'} justifyContent={'end'} item xs={12} sm={12}>
                    <Button
                        variant="contained"
                        onClick={subscribeTask}
                    >
                        Subscribe
                    </Button>
                </Grid>
            </Grid> */}
            {
                details ? <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                Title:
                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px', textAlign: 'justify' }}>
                                {details?.title}
                            </Typography>

                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Project:

                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {details?.project?.name}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Reviewer:
                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                                {details?.reviewer?.name}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Assigned To:

                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {details?.assigned_to_user?.name}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Assigned By:
                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                                {details?.assigned_by_user?.name}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Priority:

                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {details?.priority == 1 && 'Critical'}
                                {details?.priority == 2 && 'High'}
                                {details?.priority == 3 && 'Medium'}
                                {details?.priority == 4 && 'Low'}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Status:
                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px', width: 200 }} >
                                {
                                    UserID == details.assigned_to_id || UserID == details.reviewer_id || UserID == details.created_by || UserID == details.project?.members?.find(member => member.is_project_owner == 1)?.user?.id ?

                                        <ReactSelector
                                            placeholder="Status"
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            options={getStatusOptions()}
                                            getOptionLabel={option => option?.name}
                                            getOptionValue={option => option?.name}
                                            value={
                                                listStatus.find(options =>
                                                    options?.name === watch('status')
                                                )
                                            }
                                            name='status'
                                            isClearable
                                            defaultValue={(watch('status'))}
                                            onChange={(selectedOption) => handleStatusChange(selectedOption?.name)}
                                        />
                                        :
                                        details?.status
                                }
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Due Date:

                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {moment(details?.due_date).format("DD-MM-YYYY")}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                Created Date:

                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                {moment(details?.created_at).format("DD-MM-YYYY")}
                            </Typography>

                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                Description:
                            </Typography>
                            <Typography variant="body2" style={{ fontSize: '16px', textAlign: 'justify' }}>
                                <a>{details?.description && parse(details?.description)}</a>
                                {/* {details?.description} */}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Button onClick={handleCopyLink} variant='outlined'>{copiedText}{copiedText == 'Copied' && <Done fontSize='small' />}</Button>
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem', marginTop: 2 }}>
                                Notes:
                            </Typography>
                            {
                                Notes &&
                                Notes.map((note) => (


                                    <>

                                        <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                            <Grid display={'flex'} justifyContent={'space-between'} xs={8}>
                                                <a style={{ fontSize: 13 }}>Date: {moment(note.created_at).format('DD-MM-YYYY')}</a>
                                                <a style={{ fontSize: 13 }}>Added By: {note.created_user?.name}</a>
                                            </Grid>
                                           

                                            <p style={{}} className='text'>
                                                {
                                                    note.note.length <= 119 ? (
                                                        <a>{note.note}</a>
                                                    ) : (
                                                        isReadMore ? (
                                                            <div>
                                                                <a>{note.note.slice(0, 120)}</a>
                                                                <a onClick={toggleReadMore} style={{ color: 'blue', cursor: 'pointer' }}> ...read more</a>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <a>{note.note}</a>
                                                                <a onClick={toggleReadMore} style={{ color: 'blue', cursor: 'pointer' }}>show less</a>
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </p>
                                            <Grid display={'flex'} justifyContent={'end'}>
                                                <Button onClick={() => handleEdit(note.id)}>
                                                    <Edit fontSize='small' />
                                                </Button>
                                                <LoadingButton onClick={() => deleteNote(note.id)}>
                                                    <DeleteForever color='error' fontSize='small' />
                                                </LoadingButton>
                                            </Grid>

                                            
                                        </Typography>
                                        <Divider className={classes.divider} />

                                    </>
                                ))}
                        </Grid>
                    </Grid> */}

                    {/* <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Start Date:
    
                        </Typography>
                        <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                            {details?.start_date?.slice(8, 10)}-{details?.start_date?.slice(5, 7)}-{details?.start_date?.slice(0, 4)}
                        </Typography>
    
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Remarks By SpiderWorks:
                        </Typography>
                        <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
                            {details?.remarks_for_client}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Cost Model:
    
                        </Typography>
                        <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
    
                            {details?.cost_model}
                        </Typography>
    
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                            Status :
                        </Typography>
                        <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }} >
    
    
                            {details?.status}
                        </Typography>
                    </Grid>
                </Grid> */}
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>

                </> : (
                    <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'30%'} mt={10}>
                        <DotSpinner size={40} speed={0.9} color="blue" />
                    </Box>
                )}

            <Dialog open={statusOpen} onClose={handleStatusClose}>
                <DialogTitle>Change status of task</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please submit remarks on this task, you can leave it blank if you want.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        name='statusNote'
                        value={watch('statusNote')}
                        onChange={(e) => setValue('statusNote', e.target.value)}
                        margin="dense"
                        id="name"
                        label="Remarks"
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                    {
                        statusOpen == 'Completed' ?
                            <Grid mt={2} width={'50%'}>
                                <DateInput control={control} name="due_date"
                                    label="Date of Completion"
                                    value={watch('due_date')} />
                            </Grid>
                            : ''
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleStatusClose}>Cancel</Button>
                    <Button onClick={handleStatusSubmit}>Change</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DetailView;
