import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DonutChart from '../../form/DonutChart';
import { Grid, Popover, Tooltip } from '@mui/material';
import { useState } from 'react';
import moment from 'moment';
import { Group, Person, Search } from '@mui/icons-material';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

export default function BasicCard({ datas, viewTabs,selectCalendar }) {

    // console.log(datas);

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        // console.log(event);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event) => {
        // console.log(event);
        event.preventDefault()
        setAnchorEl(false);
    };

    const open = Boolean(anchorEl);

    return (
        <Card sx={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)', height: 150, display: 'flex', flexDirection: 'column' }}>
            <CardContent style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography style={{ cursor: 'pointer', marginBottom: '8px' }} onClick={() => selectCalendar(datas)} variant="h6" component="div">
                    {datas?.name}
                </Typography>
                <Typography style={{ marginBottom: '8px' }} variant="body1" color="textSecondary" component="div">
                    {datas?.project?.name}
                </Typography>


                {/* <Grid container justifyContent="space-between" style={{ marginTop: 'auto' }}>
                    <Typography style={{ cursor: 'pointer', marginBottom: '8px' }} variant="body2" color="textSecondary" >
                        <span style={{ fontSize: '12px', fontWeight: 600, color: 'grey' }}> Open Tasks</span>  (5)
                    </Typography>
                    <Typography style={{ cursor: 'pointer', marginBottom: '8px' }} variant="body2" color="textSecondary">
                        <span style={{ fontSize: '12px', fontWeight: 600, color: 'grey' }}>Delayed Tasks</span>   (5)
                    </Typography>
                </Grid> */}

                <Grid container display={'flex'} justifyContent="space-between" style={{ marginTop: 'auto' }}>
                    <Typography style={{ marginBottom: '8px' }} variant="body2" color="textSecondary" component="div">
                        <span style={{ fontSize: '12px', fontWeight: 600, color: 'grey' }}>Created by:</span> {datas?.created_user?.name}
                    </Typography>
                    <Typography style={{ marginBottom: '8px' }} variant="body2" color="textSecondary" component="div">
                        <span style={{ fontSize: '12px', fontWeight: 600, color: 'grey' }}>Created date:</span> {moment(datas?.created_at).format('DD-MM-YYYY')}
                    </Typography>
                </Grid>
            </CardContent>
        </Card >


    );
}
