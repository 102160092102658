// Awards.js
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PageHeader from "../common/page-header";
import { StyledTableCell, StyledTableRow } from "./StyledComponents";
import { Filters } from "./Filters";
import AwardDetailsDrawer from "./AwardDetailsDrawer"; // Import the new Drawer component
import AwardDrawer from "./AwardDetailsDrawer";

function createData(name, awards, awardtype, issuedate, issuedby) {
  return { name, awards, awardtype, issuedate, issuedby };
}

const rows = [
  createData(
    "kevin",
    "Employee of the Month",
    "Performance",
    "2023-12-04",
    "John"
  ),
  createData("vivin", "Top Salesperson", "Sales", "2024-11-04", "Sarah"),
  createData("thomas", "Best Team Player", "Teamwork", "2023-12-12", "David"),
  createData(
    "susan",
    "Outstanding Leadership",
    "Leadership",
    "2020-03-14",
    "Rachel"
  ),
  createData(
    "peter",
    "Innovator of the Year",
    "Innovation",
    "2023-06-23",
    "Mike"
  ),
  createData(
    "linda",
    "Customer Service Star",
    "Customer Service",
    "2022-09-30",
    "Angela"
  ),
  createData("james", "Rookie of the Year", "Newcomer", "2021-01-20", "Chris"),
];

export default function Awards() {
  const [filter, setFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleNameFilterChange = (event) => {
    setSelectedName(event.target.value);
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const sortedRows = [...rows].sort((a, b) => {
    const dateA = new Date(a.issuedate);
    const dateB = new Date(b.issuedate);
    return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
  });

  const filteredRows = sortedRows.filter((row) => {
    const issueDate = new Date(row.issuedate);
    const startDateMatch = startDate ? issueDate >= new Date(startDate) : true;
    const endDateMatch = endDate ? issueDate <= new Date(endDate) : true;
    const filterMatch =
      row.awards.toLowerCase().includes(filter.toLowerCase()) ||
      row.name.toLowerCase().includes(filter.toLowerCase());
    const nameFilterMatch = selectedName ? row.name === selectedName : true;
    return startDateMatch && endDateMatch && filterMatch && nameFilterMatch;
  });

  return (
    <>
      <PageHeader title="Awards" />
      <Filters
        filter={filter}
        startDate={startDate}
        endDate={endDate}
        sortOrder={sortOrder}
        selectedName={selectedName}
        handleFilterChange={handleFilterChange}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleNameFilterChange={handleNameFilterChange}
        handleSortOrderChange={handleSortOrderChange}
        rows={rows}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="left">Awards</StyledTableCell>
              <StyledTableCell align="left">Awards type</StyledTableCell>
              <StyledTableCell align="left">Issue Date</StyledTableCell>
              <StyledTableCell align="left">Issued by</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ cursor: "pointer" }}>
            {filteredRows.map((row) => (
              <StyledTableRow key={row.name} onClick={() => handleOpen(row)}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="left">{row.awards}</StyledTableCell>
                <StyledTableCell align="left">{row.awardtype}</StyledTableCell>
                <StyledTableCell align="left">
                  {new Date(row.issuedate).toLocaleDateString("en-GB")}
                </StyledTableCell>
                <StyledTableCell align="left">{row.issuedby}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AwardDrawer
        open={open}
        onClose={handleClose}
        selectedRow={selectedRow}
      />
    </>
  );
}
