import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Card,
    Backdrop,
    CardContent,
    Typography,
    Grid,
    Divider,
    Select,
    MenuItem,
    DialogTitle,
    InputLabel,
    TextareaAutosize,
    TextField,
    Slide,
    CircularProgress,
} from "@mui/material";
import { Project } from '../../../../api/Endpoints/Project';
import { useState } from 'react';
import Create from '../create';
import { Cancel, CheckCircle } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const Details = ({ projectID, setProjectName, getScope }) => {
    const classes = useStyles();



    const [Loading, setLoading] = useState(false)
    const [Data, setData] = useState([])
    const [refresh, setRefresh] = useState(0)

    const [editId, setEditId] = useState()


    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }
    const fetchDetails = () => {
        setLoading(true);
        Project.getDetails({ project_id: projectID }).then(response => {
            setData(response.data.data);
            setProjectName(response.data.data.name)
            let website = response.data.data.scopes.find(item => item.scope == 'Website Development')
            getScope(website)
            console.log(response.data.data);
            setLoading(false);
        })
    }









    const Scopes = [
        { name: 'Website Development' },
        { name: 'SEO' },
        { name: 'Influencer Marketing' },
        { name: 'Social Media Management' },
        { name: 'Paid Ads' },
        { name: 'Video Shoot' },
        { name: 'App Development' },
        { name: 'IT Managemenet' },
        { name: 'Branding' },
    ]


    useEffect(() => {
        fetchDetails()
    }, [])
    // console.log(editId);
    return (
        <>
            {
                editId &&
                <a hidden>
                    <Create key={refresh} onNew={handleCreate} onUpdate={fetchDetails} editId={editId} setEditId={setEditId} />

                </a>}
            {Loading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={Loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    <div style={{ textAlign: 'end', marginRight: 5 }}><Button onClick={() => setEditId(projectID)} variant='contained'>Edit</Button></div>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sm={3}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Client :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data.account?.account_name}
                                        </Typography>
                                    </Typography>

                                </Grid>
                                {/* <Grid item xs={3} sm={3}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Started Date :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.start_date ? new Date(Data.start_date).toLocaleDateString("en-GB") : ""}
                                        </Typography>
                                    </Typography>

                                </Grid> */}
                                <Grid item xs={3} sm={3}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Started Date :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.start_date ? new Date(Data.start_date).toLocaleDateString("en-GB") : ""}
                                        </Typography>
                                    </Typography>

                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        End Date :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.end_date ? new Date(Data.end_date).toLocaleDateString("en-GB") : ""}
                                        </Typography>
                                    </Typography>

                                </Grid>
                            </Grid>

                            <Divider className={classes.divider} sx={{ pt: 2 }} />

                            {/* <Grid container spacing={2}>

                            </Grid>

                            <Divider className={classes.divider} sx={{ pt: 2 }} /> */}

                            <Grid item xs={12} sm={12} mt={2}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Scope :
                                </Typography>
                                <Grid container>

                                    <>
                                        <Grid p={1} container xs={12} sm={12}>
                                            {
                                                Scopes.map((item) => (
                                                    <Grid display={'flex'} alignItems={'center'} justifyContent={''} xs={3} sm={3}>
                                                        {
                                                            Data?.scopes?.find((scope) => scope?.scope == item.name) ?
                                                                <CheckCircle fontSize='small' style={{ color: 'green' }}  />
                                                                :
                                                                <Cancel fontSize='small' style={{ color: 'red' }}  />
                                                        }
                                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                                            {item.name}
                                                        </Typography>

                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                        <Grid xs={6} sm={6}>

                                        </Grid>
                                    </>

                                </Grid>




                            </Grid>
                            <Grid item xs={12} sm={12} mt={2}>
                                <Typography variant="body1" component="p" style={{ fontWeight: '500', fontSize: '1.0rem' }}>
                                    Scope Details :
                                </Typography>
                            </Grid>

                            <Divider className={classes.divider} sx={{ pt: 2 }} />
                            <Grid container spacing={2} >
                                <Grid item xs={6} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Country  :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.country?.name}
                                        </Typography>
                                    </Typography>


                                </Grid>

                            </Grid>

                            <Divider className={classes.divider} sx={{ pt: 2 }} />
                            <Grid item xs={12} sm={12} mt={2}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Description :
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        {Data?.description}

                                    </Typography>

                                </Typography>


                            </Grid>
                        </CardContent>
                    </Card>

                </>
            )
            }
        </>
    );

};

export default Details;
