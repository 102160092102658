import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import AsyncSelect from "react-select/async";
import { Employee } from "../../../../api/Endpoints/Employee";

const EmployeeAttendance = (props) => {
  //Date picker state
  const [from, setFrom] = useState(dayjs(new Date()));
  const [to, setTo] = useState(dayjs(new Date()));
  const [user, setUser] = useState();
  const [update, setUpdate] = useState(1);

  const [list, setList] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchTable = () => {
    setLoading(true);
    console.log(user);
    Employee.getAttendanceList({
      from: from.format("DD-MM-YYYY 00:00:00"),
      to: to.format("DD-MM-YYYY 23:59:59"),
      user_id: props.EmployeeID,
    }).then((response) => {
      console.log(response.data.data);
      setList(response.data.data);

      setLoading(false);
    });
  };

  const handleCreateNew = (e) => {};

  const handleUserSelect = (e) => {
    setUser(e.id);
  };

  const fetchEmployees = (keyword) => {
    return Employee.get({ keyword: keyword }).then((response) => {
      return response.data.data?.data;
    });
  };

  useEffect(async () => {
    fetchTable();
  }, [from, to, update]);
  return (
    <>
      <Grid container sx={{}}>
        {/* <Grid xs={3} >

                    <AsyncSelect
                        defaultOptions
                        loadOptions={fetchEmployees}
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.id}
                        placeholder={<div>Please select an employee</div>}
                        onChange={handleUserSelect}
                    />
                </Grid> */}

        <Grid xs={3} sx={{ px: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={from}
              disableFuture
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="From"
                  fullWidth
                />
              )}
              onChange={(newValue) => setFrom(newValue)}
              inputFormat="DD-MM-YYYY"
            />
          </LocalizationProvider>
        </Grid>

        <Grid xs={3} sx={{ px: 5 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={to}
              disableFuture
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="To"
                  fullWidth
                />
              )}
              onChange={(newValue) => setTo(newValue)}
              inputFormat="DD-MM-YYYY"
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Card sx={{ m: 2 }} variant="outlined">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                {/* <TableCell>Name</TableCell> */}
                {/* <TableCell>Email</TableCell> */}
                <TableCell>Sign in</TableCell>
                <TableCell>Sign Out</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    {moment(row.created_at).format("DD-MM-YYYY")}{" "}
                  </TableCell>
                  {/* <TableCell>{row.name} </TableCell> */}
                  {/* <TableCell>{row.email}</TableCell> */}
                  <TableCell>
                    {moment(row.created_at).format("hh:mm A")}
                  </TableCell>
                  <TableCell>
                    {row.logged_out_time
                      ? moment(row.logged_out_time).format("hh:mm A")
                      : "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default EmployeeAttendance;
