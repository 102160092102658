import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField } from '@mui/material';
import TextInput from '../../../../form/TextInput';
import { useForm } from 'react-hook-form';
import DateInput from '../../../../form/DateInput';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import moment from 'moment';
import { CreativeCalenderApi } from '../../../../../api/Endpoints/CreativeCalender';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { Plus } from '../../../../../icons/plus';
import { CheckCircle, CheckCircleOutlineRounded, NotInterested, RadioButtonUnchecked } from '@mui/icons-material';
import { DotSpinner } from '@uiball/loaders';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
};

function CreateNotes({ calendarID, editID, setEditID, fetch, from, handleCreate, parent, relatedID, relatedType, fetchPosts }) {

    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm()


    const [open, setOpen] = React.useState(false);
    const [selectedStatus, setSelectedStatus] = useState()
    const [loading, setLoading] = useState(false)

    const handleOpen = () => setOpen(true)

    const handleCreateOpen = () => {
        setEditID(0)
        setOpen(true);
    }
    const handleClose = () => {
        setEditID(null)
        setOpen(false);
        reset()
        setSelectedStatus()
        setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
    }
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Save",
        loading: false,
        disabled: false,
    });


    const statusOptions = [
        { label: 'Good to proceed', value: 'good', icon: <CheckCircle style={{ color: selectedStatus === 'good' ? 'green' : 'grey' }} fontSize='small' /> },
        { label: 'Not impressed but OK to proceed', value: 'okay', icon: <CheckCircleOutlineRounded style={{ color: selectedStatus === 'okay' ? 'green' : 'grey' }} fontSize='small' /> },
        { label: 'No Selection', value: 'no_comment', icon: <RadioButtonUnchecked style={{ color: selectedStatus === 'no_comment' ? 'black' : 'grey' }} fontSize='small' /> },
        { label: 'Rejected', value: 'rejected', icon: <NotInterested style={{ color: selectedStatus === 'rejected' ? 'red' : 'grey' }} fontSize='small' /> },
    ];
    const onSubmit = (data) => {
        if (selectedStatus) {
            // console.log(data);   
            setFormButtonStatus({ ...formButtonStatus, loading: true });
            let dataToSubmit = {}

            if (parent == 'post') {
                dataToSubmit = {
                    id: editID,
                    related_id: relatedID,
                    related_type: relatedType,
                    note: data?.note,
                    status: selectedStatus
                }
            } else {
                dataToSubmit = {
                    id: editID,
                    project_creative_calendar_id: calendarID,
                    note: data?.note,
                    status: selectedStatus
                }
            }

            console.log(dataToSubmit);

            let action;
            if (parent == 'post') {
                if (editID > 0) {
                    action = CreativeCalenderApi.updatePostNote(dataToSubmit)
                } else {
                    action = CreativeCalenderApi.addPostNote(dataToSubmit)
                }
            } else {
                if (editID > 0) {
                    action = CreativeCalenderApi.updateNote(dataToSubmit)
                } else {
                    action = CreativeCalenderApi.addNote(dataToSubmit)
                }
            }


            action.then(response => {
                console.log("this is response data", response);
                setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
                setFormButtonStatus({ label: "Save", loading: false, disabled: false });
                toast.success(response?.data?.message)
                if (fetchPosts) {
                    fetchPosts()
                }
                handleClose()
                if (fetch) {
                    fetch()
                }
                setOpen(false);
            }).catch(errors => {
                console.log(errors);
                toast.error("Internal server error");
                setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            })
        } else {
            toast.error("Please Select a Status");
        }

    }


    const getPostNoteDetails = async () => {
        setLoading(true)
        try {
            let response = await CreativeCalenderApi.getPostNoteDetails({ id: editID });
            console.log(response);
            if (response.status === 200) {
                setLoading(false)
                let data = response?.data?.data;
                // console.log(data);
                setValue('note', data.note)
                setSelectedStatus(data?.status);
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    const getNotetDetails = async () => {
        setLoading(true)
        try {
            let response = await CreativeCalenderApi.getNoteDetails({ id: editID });
            console.log(response);
            if (response.status === 200) {
                setLoading(false)
                let data = response?.data?.data;
                // console.log(data);
                setValue('note', data.note)
                setSelectedStatus(data?.status);
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    useEffect(() => {
        if (parent == 'post') {
            if (editID > 0) {
                setOpen(true)
                getPostNoteDetails()
            }
            if (editID == 0) {
                setOpen(true)
            }
        } else {
            if (editID > 0) {
                setOpen(true)
                getNotetDetails()
            }
            if (editID == 0) {
                setOpen(true)
            }
        }
    }, [editID])


    return (
        <div>
            {
                from ?
                    ''
                    // <a onClick={handleOpen} style={{ fontSize: '13px', color: 'blue', cursor: 'pointer' }}>
                    //     <Plus />
                    // </a>
                    :
                    <Button size='small' sx={{ height: '23px', width: '23px' }} variant='outlined' onClick={handleCreateOpen}><Plus /></Button>
            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {
                        loading ?
                            (
                                <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'30%'} >
                                    <DotSpinner size={40} speed={0.9} color="blue" />
                                </Box>
                            ) :
                            <>
                                <Typography style={{ fontSize: '18px' }} id="modal-modal-title" variant="h6" component="h2">
                                    {editID > 0 ? 'Edit Note' : 'Add Note'}
                                </Typography>
                                <Typography id="modal-modal-title" mt={1}>
                                    <a style={{ color: 'grey', fontWeight: 500 }}>Project Name:calender name</a>
                                </Typography>

                                <Grid mt={1} style={{ border: '1px solid black' }}>
                                    <Grid container>
                                        <Grid item md={4} style={{ borderRight: '1px solid black', width: '300px' }}>
                                            <Grid display={'flex'} flexDirection={'column'}>
                                                <Grid p={1}>
                                                    <a style={{ fontWeight: 500, fontSize: '16px' }}>Select Status:</a>
                                                </Grid>
                                                {
                                                    statusOptions?.map((obj, index) => (
                                                        <Grid container key={index} Grid p={1}>
                                                            <Grid item md={1.5} >
                                                                <a style={{ color: 'grey' }}>{obj?.icon}</a>
                                                            </Grid>
                                                            <Grid item md={10.5} >
                                                                <a onClick={() => setSelectedStatus(obj?.value)} style={{ color: selectedStatus == obj?.value ? 'black' : 'grey', cursor: 'pointer' }}> {obj?.label}</a>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                }

                                            </Grid>

                                        </Grid>
                                        <Grid p={1} item md={8}>
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <Typography>
                                                    Note:
                                                </Typography>
                                                <Grid mt={2} sx={{}} item sm={12} >
                                                    <TextField
                                                        // placeholder='Add Note'
                                                        {...register('note')}
                                                        variant="outlined"
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                        sx={{ width: '100%', }}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid display={'flex'} justifyContent={'end'} sx={{ mt: 1 }} item xs={12}>
                                                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                                </Grid>
                                            </form>
                                        </Grid>

                                    </Grid>

                                </Grid>
                            </>
                    }


                </Box>
            </Modal>
        </div >
    )
}

export default CreateNotes
