import React, { useEffect, useState } from "react";
import { useAppSettings } from "../../../hooks/use-app-settings";
import moment from "moment";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { PreQualifiers } from "../../../api/Endpoints/PreQualifiers";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Account } from "../../../api/Endpoints/Account";
import { Project } from "../../../api/Endpoints/Project";
import { AvatarGroup } from "@mui/lab";
import AddTeamMember from "./actions/addTeamMember";
import Create from "./create";
import BasicTabs from "./TabMenus";
import { PersonAddAlt1 } from "@mui/icons-material";
import OwnerPopup from "../common/Popup/projectOwner";
import { useForm } from "react-hook-form";
import ReactSelector from "react-select";
import AsyncSelect from "react-select/async";
import { Employee } from "../../../api/Endpoints/Employee";
import { Country } from "../../../api/Endpoints/Country";
import EditIcon from "@mui/icons-material/Edit";

const Index = (props) => {
  const {
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const { employeeID } = props;

  const [openOwnerpopup, setOpenOwnerpopup] = useState(false);
  const [ownerDetails, setOwnerDetails] = useState();

  const OwnerPopuphandler = (id, details) => {
    // console.log(id);
    setOwnerDetails(details);
    setOpenOwnerpopup(id);
  };

  const columns = [
    {
      field: "name",
      headerName: "Project name",
      width: 280,
      renderCell: (params) => (
        <Button
          className="project-dt-btn"
          size="small"
          onClick={() => viewTabs(params.id)}
          style={{ color: "black" }}
        >
          {params.row.name}
        </Button>
      ),
    },
    {
      field: "client",
      headerName: "Client",
      width: 200,
      renderCell: (params) => {
        return <>{params.row?.account?.account_name}</>;
      },
    },
    {
      field: "project_owner",
      headerName: "Project Owner",
      width: 200,
      renderCell: (params) => {
        const owner = params.row.members.find(
          (item) => item.is_project_owner === 1
        );

        return (
          <p
            style={{ cursor: "pointer", width: "100%", color: "blue" }}
            size="small"
            onClick={() => OwnerPopuphandler(params.id, owner)}
          >
            {owner?.user?.name || (
              <PersonAddAlt1 style={{ marginLeft: "auto", color: "blue" }} />
            )}
          </p>
        );
      },
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 150,
      renderCell: (params) => {
        return moment(params.value).format("DD-MM-YYYY");
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <>{parseInt(params.value) === 1 ? "Active" : "Inactive"}</>
      ),
    },

    {
      field: "Edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <>
          <a
            variant="contained"
            size="small"
            style={{ marginLeft: 1, color: "blue", cursor: "pointer" }}
            onClick={() => handleEdit(params.id)}
          >
            <EditIcon fontSize="small" />
          </a>
        </>
      ),
    },
  ];

  const handleEdit = (id) => {
    setRefresh(Math.random);
    setEditId(parseInt(id));
  };

  const [list, setList] = useState([]);
  const [listType, setListType] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
  const [editId, setEditId] = useState();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [Tabs, setTabs] = useState(false);
  const [projectId, setProjecId] = useState(0);
  const [selectedScope, setSelectedScope] = useState();
  const [selectedOwner, setSelectedOwner] = useState();
  const [selectedCountry, setSelectedCountry] = useState();

  const handleListingSearchChange = (keyword) => {
    setSearchKeyword(keyword);
    setPageNumber(0);
  };
  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handlePageNumberChange = (event) => {
    const newPageNumber = parseInt(event.target.value, 10) - 1;
    setPageNumber(newPageNumber);
    // Add any other logic you want to perform when the page number changes
  };

  const handleLeadClick = (leadId) => {
    props.onPreQualifierIdChange(leadId);
    setSelectedPreQualifierId(leadId);
  };

  const viewTabs = (id) => {
    setProjecId(id);
    setTabs(true);
  };

  const CloseTab = () => {
    setTabs(false);
    setProjecId(0);
  };

  const fetchTable = () => {
    setLoading(true);
    // console.log(selectedOwner);
    Project.get({
      scope: selectedScope,
      owner: employeeID ? employeeID : selectedOwner?.id,
      country_id: selectedCountry?.id,
      keyword: searchKeyword,
      page: parseInt(pageNumber) + 1,
      limit: 30,
    }).then((response) => {
      // console.log(response.data.data);
      setList(response.data.data);
      setLoading(false);
    });
  };

  const handleSearchEntry = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleCreateNew = () => {};
  const handleCreate = () => {
    setEditId(0);
    setRefresh(Math.random);
  };
  const handleUpdate = () => {
    setPageNumber();
    setPageNumber(0);
  };

  const listStcope = [
    { id: 1, name: "Website Development", value: "Website Development" },
    { id: 2, name: "SEO", value: "SEO" },
    { id: 3, name: "Influencer Marketing", value: "Influencer Marketing" },
    {
      id: 4,
      name: "Social Media Management",
      value: "Social Media Management",
    },
    { id: 5, name: "Paid Ads", value: "Paid Ads" },
    { id: 6, name: "Video Shoot", value: "Video Shoot" },
    { id: 7, name: "App development", value: "App development" },
    { id: 8, name: "IT Management", value: "IT Management" },
    { id: 9, name: "Branding", value: "Branding" },
  ];

  const selectScope = (data) => {
    setValue("scope", data?.value);
    setSelectedScope(data?.value);
  };
  const selectOwner = (data) => {
    if (data) {
      setValue("owner", { id: data?.id || "", name: data?.name || "" });
    } else {
      setValue("owner", "");
    }
    setSelectedOwner(data);
  };
  const selectCountry = (data) => {
    if (data) {
      setValue("country", { id: data?.id || "", name: data?.name || "" });
    } else {
      setValue("country", "");
    }
    setSelectedCountry(data);
  };

  const fetchEmployees = (e) => {
    return Employee.get({ keyword: e }).then((response) => {
      if (typeof response.data.data.data !== "undefined") {
        return response.data.data.data;
      } else {
        return [];
      }
    });
  };
  const fetchCountry = (e) => {
    return Country.get({ keyword: e }).then((response) => {
      // console.log(response);
      if (typeof response.data.data !== "undefined") {
        return response.data.data;
      } else {
        return [];
      }
    });
  };

  useEffect(async () => {
    fetchTable();
  }, [
    searchKeyword,
    listType,
    pageNumber,
    selectedCountry,
    selectedOwner,
    selectedScope,
  ]);
  return (
    <>
      {Tabs ? (
        <BasicTabs
          fetchTable={fetchTable}
          setTabs={setTabs}
          projectID={projectId}
          CloseTab={CloseTab}
          setSearchKeyword={setSearchKeyword}
        />
      ) : (
        <>
          <PageHeader title={"Projects"} onCreateNew={handleCreateNew} />

          {openOwnerpopup && (
            <OwnerPopup
              details={ownerDetails}
              openOwnerpopup={openOwnerpopup}
              setOpenOwnerpopup={setOpenOwnerpopup}
              onUpdate={handleUpdate}
            />
          )}

          <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
            {!employeeID && (
              <Grid display={"flex"} alignItems={"center"}>
                <Grid mr={2} sx={{ width: 200 }}>
                  <ReactSelector
                    placeholder="Scope"
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    options={listStcope}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    // inputValue={inProject}
                    value={listStcope.filter(
                      (options) => options.name === watch("scope")
                    )}
                    name="scope"
                    isClearable
                    defaultValue={watch("scope")}
                    onChange={(selectedOption) => selectScope(selectedOption)}
                  />
                </Grid>

                <Grid mr={2} sx={{ width: 200 }}>
                  <AsyncSelect
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    isClearable={true}
                    defaultOptions
                    name="owner"
                    loadOptions={fetchEmployees}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    placeholder={"Owner"}
                    value={watch("owner")}
                    onInputChange={fetchEmployees}
                    onChange={selectOwner}
                  />
                </Grid>

                <Grid mr={2} sx={{ width: 200 }}>
                  <AsyncSelect
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    isClearable={true}
                    defaultOptions
                    name="country"
                    loadOptions={fetchCountry}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    placeholder={"Country"}
                    value={watch("country")}
                    onInputChange={fetchCountry}
                    onChange={selectCountry}
                  />
                </Grid>
              </Grid>
            )}

            <Grid display={"flex"} alignItems={"center"}>
              <TextField
                size="small"
                style={{ width: 250, marginRight: 10 }}
                id="outlined-name"
                autoFocus
                label="Search projects"
                onChange={handleSearchEntry}
              />
              {!employeeID && (
                <Create
                  key={refresh}
                  onNew={handleCreate}
                  onUpdate={handleUpdate}
                  editId={editId}
                  setEditId={setEditId}
                />
              )}
            </Grid>
          </Box>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Card
            sx={{ m: 2, height: 550, position: "relative" }}
            variant="outlined"
          >
            {typeof list.data === "object" && (
              <>
                <DataGrid
                  paginationMode="server"
                  rows={list?.data}
                  columns={columns}
                  page={pageNumber}
                  pageSize={list?.per_page}
                  rowHeight={20}
                  rowsPerPageOptions={[10]}
                  rowCount={list?.total}
                  onPageChange={handlePageChange}
                  // autoHeight
                  density={"comfortable"}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    position: "absolute",
                    bottom: 16,
                    left: "77%",
                    transform: "translateX(-50%)",
                    zIndex: 1000,
                  }}
                >
                  <label htmlFor="pageNumber" style={{ marginRight: "10px" }}>
                    Go to Page:
                  </label>
                  <select
                    id="pageNumber"
                    value={pageNumber + 1}
                    onChange={handlePageNumberChange}
                  >
                    {Array.from(
                      { length: Math.ceil(list?.last_page) },
                      (_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </>
            )}
          </Card>
        </>
      )}
    </>
  );
};

export default Index;
