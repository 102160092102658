import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Card, CircularProgress, Grid, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { WorkRequest } from '../../../api/Endpoints/WorkRequest';
import EditIcon from '@mui/icons-material/Edit';
import Create from './create';
import { Autorenew, Details, Edit, PersonAddAlt, PersonAddAlt1, PersonAddAlt1Sharp } from '@mui/icons-material';
import BasicTabs from './TabMenus';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import moment from 'moment';
import CostPopup from '../common/Popup/CostPopup';
import { Cancel, CancelOutlined, CheckCircle, CheckCircleOutline, Person, PersonAdd, PersonAddSharp } from '@material-ui/icons';
import AcceptPopup from '../common/Popup/AcceptPopup';
import AssignTo from '../common/Popup/AssignTo';
import AssignToPopup from '../common/Popup/AssignTo';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import ReactSelector from 'react-select';
import { Project } from '../../../api/Endpoints/Project';
import { red } from '@mui/material/colors';
import { Employee } from '../../../api/Endpoints/Employee';
import WRStatus from '../common/Popup/WorkRequestStatus';
import ReviewStatus from '../common/Popup/ReviewStatus';


const scheme = yup.object().shape({

})

const Index = (props) => {



    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }


    const { watch, formState: { errors }, setValue, getValues, } = useForm({ resolver: yupResolver(scheme) });

    const [editId, setEditId] = useState();
    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0);
    const [openCostpopup, setOpencosePopup] = useState(false)
    const [openAcceptpopup, setOpenAcceptPopup] = useState(false)
    const [openAssignTopopup, setOpenAssignTopopup] = useState(false)
    const [loading, setLoading] = useState(false);
    const [editedid, setEditedID] = useState(null)
    const [Tabs, setTabs] = useState(false)
    const [WorkID, setWorkID] = useState(0)
    const [selectedProjectID, setSelectedProjectID] = useState(null)
    const [projects, setProjects] = useState([])
    const [acceptDetails, setAcceptDetails] = useState()
    const [assignToDetails, setassignToDetails] = useState()
    const [selectedAssignedToID, setSelectedAssignedToID] = useState(null)
    const [selectStatus, setselectStatus] = useState(null)
    const [employees, setEmployees] = useState([])
    const [WRstatusId, setWRstatusId] = useState()
    const [WRstatusDetails, setWRstatusDetails] = useState()
    const [reviewstatusId, setReviewstatusId] = useState()
    const [reviewtatusDetails, setReviewstatusDetails] = useState()



    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }
    const viewTabs = (id) => {
        setWorkID(id)
        setTabs(true)
    }

    const CostPopuphandler = () => {
        setOpencosePopup(true)
    }
    const AcceptPopuphandler = (id, detaisl) => {
        // if (!detaisl.accepted_by_user) {
        setOpenAcceptPopup(id)
        setAcceptDetails(detaisl)
        // }
    }
    const AssignToPopuphandler = (id, details) => {
        console.log(details);
        setassignToDetails(details)
        setOpenAssignTopopup(id)
    }

    const WRstatushandler = (id, details) => {
        setWRstatusId(id)
        setWRstatusDetails(details);
    }
    const reviewStatusHandler = (id, details) => {
        setReviewstatusId(id)
        setReviewstatusDetails(details)
    }

    const columns = [
        {
            field: 'name', headerName: 'Title', width: 200, renderCell: (params) => (
                <Button
                    style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'black' }}
                    size="small"
                    onClick={() => viewTabs(params.id)}
                >
                    {params.row.title}
                </Button>
            ),
        },
        {
            field: 'project', headerName: 'Project', width: 200, renderCell: (params) => <a style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'blue' }}>
                {params.row.project.name}
            </a>
        },
        {
            field: 'status', headerName: 'Status', width: 100, renderCell: (params) => <a style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'blue' }}>
                {params?.row?.status}
            </a>
        },
        {
            field: 'assignedTo', headerName: 'Assigned To', width: 130, renderCell: (params) => <>


                <p style={{ cursor: 'pointer', width: 10, color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'blue' }} size='small' onClick={() => AssignToPopuphandler(params.id, params.row)}>
                    {
                        params.row.assigned_to_user?.name ? params.row.assigned_to_user?.name : <PersonAddAlt1 style={{ marginLeft: 'auto', color: 'blue' }} color='blue' />
                    }
                </p>
            </>
        },
        {
            field: 'requestDate', headerName: 'Request Date', width: 120, renderCell: (params) => <a style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'black' }}>
                {
                    params.row.requested_on ?
                        moment(params.row.requested_on).format('DD-MM-YYYY')
                        : ''
                }</a>
        },
        {
            field: 'dueDate', headerName: 'Due Date', width: 120, renderCell: (params) => <a style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'black' }}>
                {
                    params.row.expected_completion_date ?
                        moment(params.row.expected_completion_date).format('DD-MM-YYYY')
                        : ''

                }</a>
        },
        {
            field: 'Edit',
            headerName: 'Edit',
            width: 80,
            renderCell: (params) => (
                <>
                    <a style={{ cursor: 'pointer' }} onClick={() => handleEdit(params.id)}>
                        <EditIcon fontSize='small' color='primary' />
                    </a>
                </>
            )
        },
        // {
        //     field: 'Cost',
        //     headerName: 'cost',
        //     width: 75,
        //     renderCell: (params) => (
        //         <>
        //             <Button size='small' onClick={() => CostPopuphandler()}>
        //                 <MonetizationOnIcon color='success' sx={{ color: 'black' }} />
        //             </Button>
        //         </>
        //     )
        // },
        {
            field: 'accept',
            headerName: 'Accept',
            width: 100,
            renderCell: (params) => (
                <>
                    {
                        params.row.status === "Rejected" ?
                            <Button size='small' >
                                <CancelOutlined color='error' style={{ color: red }} />
                            </Button> :
                            <Button size='small' onClick={() => AcceptPopuphandler(params.id, params.row)}>
                                <CheckCircleOutline style={{ color: params.row.status === "Accepted" ? 'green' : 'blue' }} />
                            </Button>
                    }
                </>
            )
        },
        {
            field: 'work_status', headerName: 'Work Status', width: 100, renderCell: (params) => <a style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'blue' }}>
                <a style={{ cursor: 'pointer' }} onClick={() => WRstatushandler(params.id, params?.row)}>{params?.row?.work_status ? params?.row?.work_status : <Autorenew />} </a>
            </a>
        },
        {
            field: 'review_status', headerName: 'Review Status', width: 100, renderCell: (params) => <a style={{ color: (moment(params.row.expected_completion_date).isBefore(moment(), 'day') && params.row.work_status !== "Completed") ? 'red' : 'blue' }}>
                <a style={{ cursor: 'pointer' }} onClick={() => reviewStatusHandler(params.id, params?.row)}>{params?.row?.review_status ? params?.row?.review_status : <Autorenew />} </a>
            </a>
        },


    ];

    const listStatus = [
        { value: "New", label: 'New' },
        { value: "On Hold", label: 'On Hold' },
        { value: "Accepted", label: 'Accepted' },
        { value: "Work In Progress", label: 'Work In Progress' },
        { value: "Completed", label: 'Completed' },
        { value: "Cancelled ", label: 'Cancelled ' },
        { value: "Needs Discussion", label: "Needs Discussion" },
    ];


    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }


    const fetchTable = () => {
        setLoading(true);
        WorkRequest.get({ keyword: searchKeyword, projects_id: selectedProjectID, assigned_to: selectedAssignedToID, status: selectStatus,page: Number(pageNumber) + 1 }).then(response => {
            console.log(response.data.data.data);
            setList(response.data.data.data);
            setLoading(false);
        })
    }

    const fetchProjects = (e) => {
        Project.get({ keyword: e }).then(response => {
            // console.log(response);
            if (typeof response.data.data.data !== "undefined") {
                setProjects(response.data.data.data)
            } else {
                return [];
            }
        })
    }

    const handleCreateNew = () => {

    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    const selectProject = (project_id) => {
        if (project_id == null) {
            setValue('projects', "")
        }
        setValue('projects', project_id?.name || "")
        console.log(project_id);
        setSelectedProjectID(project_id?.id)
        console.log(selectedProjectID);
    }
    const selectAssignedTo = (assign_to) => {
        console.log(assign_to);
        setSelectedAssignedToID(assign_to?.id)
        setValue('assigned_to', assign_to?.name || "")
    }

    const selectStatusFunc = (status) => {
        setselectStatus(status?.label)
        setValue('status', status?.label || "")

    }
    const fetchEmployees = (e) => {
        Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                setEmployees(response.data.data.data)
            } else {
                return [];
            }
        })
    }

    useEffect(async () => {
        fetchTable();
    }, [searchKeyword, listType, pageNumber, selectedProjectID, selectedAssignedToID, selectStatus])
    useEffect(async () => {
        // fetchTable();
        fetchProjects()
        fetchEmployees()
    }, [])



    return (
        <>
            {Tabs ? (<BasicTabs setTabs={setTabs} WorkID={WorkID} />
            ) : (


                <>

                    < PageHeader title={"Work Requests"} onCreateNew={handleCreateNew} />
                    <Grid sx={{ p: 2, display: "flex", justifyContent: "space-between" }} variant="outlined">
                        <Grid display={'flex'}>
                            <Grid mr={2} sx={{ width: 250 }}>
                                <ReactSelector
                                    onInputChange={fetchProjects}
                                    placeholder="Project"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={projects}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}

                                    // inputValue={inProject}
                                    value={
                                        projects.find(options =>
                                            options.name === watch('projects')
                                        )
                                    }
                                    name='projects'
                                    isClearable
                                    defaultValue={(watch('projects'))}
                                    onChange={(selectedOption) => selectProject(selectedOption)}
                                />
                            </Grid>
                            <Grid mr={2} sx={{ width: 160 }}>
                                <ReactSelector
                                    onInputChange={fetchEmployees}
                                    placeholder="Assigned To"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={employees}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}

                                    // inputValue={inProject}
                                    value={
                                        employees.find(options =>
                                            options.name === watch('assigned_to')
                                        )
                                    }
                                    name='assigned_to'
                                    isClearable
                                    defaultValue={(watch('assigned_to'))}
                                    onChange={(selectedOption) => selectAssignedTo(selectedOption)}
                                />
                            </Grid>
                            <Grid mr={2} sx={{ width: 180 }}>
                                <ReactSelector
                                    placeholder="Status"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={listStatus}
                                    getOptionLabel={option => option.label}
                                    getOptionValue={option => option.label}

                                    // inputValue={inProject}
                                    value={
                                        listStatus.filter(options =>
                                            options.label === watch('status')
                                        )
                                    }
                                    name='status'
                                    isClearable
                                    defaultValue={(watch('status'))}
                                    onChange={(selectedOption) => selectStatusFunc(selectedOption)}
                                />
                            </Grid>
                        </Grid>

                        <Grid display={'flex'}>
                            <TextField
                                style={{ width: 150, marginRight: 10 }}
                                id="outlined-name"
                                size="small"
                                autoFocus
                                label="Search"
                                onChange={handleSearchEntry}
                            />
                            <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} />

                        </Grid>

                    </Grid >
                    {openCostpopup && <CostPopup openCostpopup={openCostpopup} setOpencosePopup={setOpencosePopup} />}
                    {openAcceptpopup && <AcceptPopup details={acceptDetails} openAcceptpopup={openAcceptpopup} setOpenAcceptPopup={setOpenAcceptPopup} onUpdate={handleUpdate} />}
                    {openAssignTopopup && <AssignToPopup details={assignToDetails} openAssignTopopup={openAssignTopopup} setOpenAssignTopopup={setOpenAssignTopopup} onUpdate={handleUpdate} />}
                    {WRstatusId && <WRStatus details={WRstatusDetails} openWRstatus={WRstatusId} setOpenWRstatus={setWRstatusId} onUpdate={handleUpdate} />}
                    {reviewstatusId && <ReviewStatus details={reviewtatusDetails} openReviewStatus={reviewstatusId} setOpenReviewStatus={setReviewstatusId} onUpdate={handleUpdate} />}


                    <Card sx={{ m: 2 }} variant="outlined">
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        {list &&
                            <DataGrid
                                paginationMode="server"
                                rows={list}
                                columns={columns}
                                page={pageNumber}
                                pageSize={10}
                                rowHeight={20}
                                rowsPerPageOptions={[10]}
                                onPageChange={handlePageChange}
                                autoHeight
                                density={"comfortable"}
                            />
                        }
                    </Card>
                </>
            )}
        </>
    );
};

export default Index;
