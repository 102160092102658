import React, { useState } from "react";
import { Tab, Tabs, Box, Typography } from "@mui/material";
import WebsiteTracker from "./Tracker"; // Assuming you have a component for the first tab
import PageHeader from "../common/page-header";
import FailedWebsites from "./FailedWebsits";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const AppTabs = () => {
  const [tabValue, setTabValue] = useState(0);
  const [refresh, setRefresh] = useState();
  const [total, setTotal] = useState();
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // console.log(total);

  const onRefresh = () => {
    setRefresh(Math.random());
  };
  return (
    <div>
      <PageHeader
        title={"Website Live Tracker"}
        refresh
        onRefresh={onRefresh}
        total={total}
      />

      <Tabs value={tabValue} onChange={handleChange} aria-label="Website Tabs">
        <Tab label="Tracker" id="tab-0" />
        <Tab label="Failed Websites" id="tab-1" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <WebsiteTracker
          refresh={refresh}
          setTotal={setTotal}
          setRefresh={setRefresh}
          total={total}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <FailedWebsites refresh={refresh} />
      </TabPanel>
    </div>
  );
};

export default AppTabs;
