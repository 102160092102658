import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel, FormGroup, Grid,
    InputLabel,
    Select,
    Slide, TextField, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../form/TextInput";
import DateInput from "../../form/DateInput";
import SelectX from "../../form/SelectX";
import { Project } from "../../../api/Endpoints/Project";
import DynamicChip from "../../../utils/DynamicChip";
import { FormHelpers } from "../../../helpers/FormHelpers";
import { FollowUp } from "../../../api/Endpoints/FollowUp";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { Task } from "../../../api/Endpoints/Task";
import { Activities } from "../../../api/Endpoints/Activities";
import { ErrorMessage } from "@hookform/error-message";
import LoadingScreen from '../../form/progress';
import { Users } from '../../../api/Endpoints/Users';
import { Auth } from '../../../api/Endpoints/Auth';
import ReactSelector from 'react-select';
import { Employee } from '../../../api/Endpoints/Employee';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
    title: yup.string().required(),
    task: yup.string(),
    projects_id: yup.object().nullable()
})

const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });

    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => { setOpen(false); };
    const [recentProjects, setRecentProjects] = useState([])
    const [currentUser, setCurrentUser] = useState(0)
    const [tasks, setTasks] = useState([])
    const [taskId, setTaskId] = useState()
    const [durationMinutes, setDurationMinutes] = React.useState();
    const [durationHours, setDurationHours] = React.useState();
    const [submitTask, setSubmitTask] = useState(false);

    const handleCheckboxChange = (event) => {
        setSubmitTask(event.target.checked);
    };

    const [taskStatus, setTaskStatus] = useState([
        "Not Started",
        "In progress",
        "Review pending",
        "In review"
    ])


    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchProjects = (e) => {
        return Project.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    // const fetchTask = (e) => {
    //     return Task.get({ keyword: e, assigned_to: currentUser, status: "Not Started" }).then(response => {
    //         if (typeof response.data.data.data !== "undefined") {
    //             console.log();
    //             return response.data.data.data;
    //         } else {
    //             return [];
    //         }
    //     })
    // }
    const fetchTask = (e) => {
        Task.get({ keyword: e, assigned_to: currentUser, status: "Not Started" }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                console.log();
                setTasks(response.data.data.data)
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }
    const fetchEmployees = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {

                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const priority = [
        { id: 1, name: "Critical" },
        { id: 2, name: "High" },
        { id: 3, name: "Medium" },
        { id: 4, name: "Low" },
    ]


    const handleMinuteChange = (e) => {
        if (e.target.value.length <= 2) {
            setDurationMinutes(e.target.value);
        }
    };
    const handleHourChange = (e) => {
        if (e.target.value.length <= 2) {
            setDurationHours(e.target.value);
        }
    };


    const handlePriorityChange = (id) => {
        setSelectedPriority(id);
    }

    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })



        let dataToSubmit = {
            id: props.editId,
            projects_id: data.projects_id?.id,
            title: data.title,
            description: data.description,
            due_date: data.due_date,
            assigned_to_id: data.assign_to?.id,
            reviewer_id: data.reviewer_id?.id,
            project_tasks_id: taskId,
            duration_hours: durationHours,
            duration_minutes: durationMinutes

        };


        let action;
        if (props.editId > 0) {
            console.log("dataToSubmit", dataToSubmit);
            action = Activities.update(dataToSubmit);
        } else {
            action = Activities.add(dataToSubmit);
        }

        action.then(response => {
            console.log(response);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            props.setEditId()
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    const fetchActivityDetails = async () => {
        setLoading(true)
        let task = await Activities.getDetails({ id: props.editId });
        if (task.data.status === "success") {
            let data = task.data.data;
            setValue('title', data.title);
            setValue('description', data.description);
            // setValue('durationMinutes', data.duration_minutes);
            // setValue('durationHours', data.duration_hours);
            setValue('start_time', data.start_time);
            setValue('end_time', data.end_time);
            setValue('projects_id', { id: data.project?.id, name: data.project?.name });
            setValue('task', data.project_task?.title);
            setSelectedPriority(data.priority);
            setDurationMinutes(data.duration_minutes)
            setDurationHours(data.duration_hours)
        }
        setLoading(false)
    };

    const fetchRecentProjects = () => {
        Users.recentProjects().then((response) => {
            console.log(response);
            setRecentProjects(response.data.data)

        })
    }

    const fetchUser = async () => {
        const accessToken = window.localStorage.getItem('accessToken');
        const user = await Auth.me(accessToken);
        setCurrentUser(user.data.data.id)
        console.log(user);
        // setValue('reviewer_id', { id: user.data.data.id, name: user.data.data.name })
    }
    const selectTask = (data) => {
        setValue('task', data?.title, '')
        setTaskId(data?.id)
    }
    // console.log(currentUser);
    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('projects_id')])

    useEffect(() => {
        fetchRecentProjects()
        fetchUser()
    }, [])

    // useEffect(() => {
    //     fetchTasks()
    //     setRefresh(Math.random())
    // }, [currentUser])


    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchActivityDetails();
            fetchUser()
            setOpen(true);
        } else if (parseInt(props.editId) === 0) {
            setOpen(true);
            fetchUser()
        }

    }, [props.editId])

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                Request
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{parseInt(props.editId) > 0 ? "Edit Request" : "Add Request"}</DialogTitle>

                {!loading ? (

                    <>
                        <DialogContent>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <ErrorMessage
                                    errors={errors}
                                    name="multipleErrorInput"
                                    render={({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(([type, message]) => (
                                            <p key={type}>{message}</p>
                                        ))
                                    }
                                />

                                <Grid sx={{ mt: 2 }} item xs={12}>
                                    <InputLabel sx={{
                                        color: "black",
                                        fontWeight: 500,
                                        fontSize: '14px'
                                    }}>
                                        <Grid display={'flex'} justifyContent={'space-between'}>
                                            <a style={{ marginRight: 20 }}>Choose Project</a>
                                            <Grid>
                                                {recentProjects.map((proj, index) => (
                                                    <a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setValue('projects_id', { id: proj.id, name: proj.name })}> {proj.name}{index != 2 && ','}</a>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </InputLabel>
                                    <SelectX
                                        // label={"Choose project"}
                                        options={fetchProjects}
                                        control={control}
                                        error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                                        error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                                        name={'projects_id'}
                                        defaultValue={watch('projects_id')}
                                    />
                                </Grid>

                                <Grid sx={{ mt: 2 }} item xs={12}>
                                    <TextInput control={control} name="title"
                                        label="Title"
                                        value={watch('title')} />
                                </Grid>

                                <Grid sx={{ mt: 2 }} item xs={12}>
                                    <InputLabel sx={{
                                        color: "black",
                                        pb: .5
                                    }}>Requirements </InputLabel>
                                    <TextField
                                        {...register('requirements')}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        sx={{ width: '100%', }}
                                    />
                                </Grid>

                                <Grid container sx={{ mt: 2 }} >
                                    <Grid item md={6} pr={1}>
                                        <SelectX
                                            label='Assigned to'
                                            key={refresh * 0.2}
                                            placeholder={'Employees'}
                                            options={fetchEmployees}
                                            control={control}
                                            error={errors?.assigned_to_id?.id ? errors?.assigned_to_id?.id?.message : false}
                                            error2={errors?.assigned_to_id?.message ? errors?.assigned_to_id?.message : false}
                                            name={'assigned_to_id'}
                                            defaultValue={watch('assigned_to_id')}
                                        />
                                    </Grid>
                                    <Grid item md={6} pl={1}>
                                        <InputLabel sx={{
                                            color: "black",
                                            fontSize: "15px",
                                            pb: .5,
                                            // fontWeight: 500
                                        }}>Priority</InputLabel>
                                        <ReactSelector
                                            // onInputChange={fetchTask}
                                            // placeholder="Reviewer"
                                            styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                            options={priority}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.name}
                                            // inputValue={inProject}
                                            value={
                                                priority.filter(options =>
                                                    options.name === watch('priority')
                                                )
                                            }
                                            name='priority'
                                            isClearable={true}
                                            defaultValue={(watch('priority'))}
                                            onChange={(selectedOption) => setValue('priority', selectedOption?.name || '')}
                                        />
                                    </Grid>


                                </Grid>

                                <Grid container mt={2} spacing={2} >
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel sx={{
                                            color: "black",
                                            fontSize: "15px",
                                            pb: .5,
                                            // fontWeight: 500
                                        }}>Due Date</InputLabel>
                                        <DateInput control={control} name="due_date"
                                            // label="Due Date"
                                            value={watch('due_date')} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextInput type={'number'} control={control} name="expected_word_count"
                                            label="Expected Word Count"
                                            value={watch('expected_word_count')} />
                                    </Grid>
                                </Grid>


                                <Grid sx={{ mt: 4 }} item xs={12} display={'flex'} justifyContent={'space-between'}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={submitTask} onChange={handleCheckboxChange} />} label="Submit Task" />
                                    </FormGroup>
                                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                </Grid>

                            </form>

                        </DialogContent>
                        {/* <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions> */}
                    </>
                ) : (

                    <Box className="loadingScreen" >
                        <LoadingScreen />
                    </Box>


                )}
            </Dialog>
        </div >
    );
};

export default Create;
