import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DonutChart from '../../form/DonutChart';
import { Dialog, Grid, Popover, Tooltip } from '@mui/material';
import { useState } from 'react';
import moment from 'moment';
import { Group, Person, Search } from '@mui/icons-material';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

export default function BasicCard({ datas, viewTabs, selectCalendar, index }) {

    const imgArray = [
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
        "https://www.planetware.com/wpimages/2020/02/france-in-pictures-beautiful-places-to-photograph-eiffel-tower.jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYfUyqK7UFuJdzHRDWZXf0LCa9lJ_dphbMWicejmPFeA&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVSo5CIHyqLHifK9M9PQl-fhmrC3FvaTa9Sv0TyjklOg&s",
        "https://i0.wp.com/picjumbo.com/wp-content/uploads/beautiful-fall-nature-scenery-in-park-free-photo.jpg?w=600&quality=80"
    ]

    // console.log(datas);

    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false)
    const [selectedImgSrc, setselectedImgSrc] = useState('')

    const handlePopoverOpen = (event) => {
        // console.log(event);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event) => {
        // console.log(event);
        event.preventDefault()
        setAnchorEl(false);
    };

    const handleImgClose = () => {
        setDialogOpen(false)
        setselectedImgSrc('')
    }

    const imgClick = (src) => {
        setselectedImgSrc(src)
        setDialogOpen(true)
    }

    const open = Boolean(anchorEl);

    return (
        <Card sx={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)', height: 300, display: 'flex', flexDirection: 'column' }}>
            <CardContent style={{ flex: 1, padding: 0 }}>
                <img onClick={() => imgClick(imgArray[index])} src={imgArray[index]} style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }} />
            </CardContent>
            <Dialog open={dialogOpen} onClose={handleImgClose}>
                {
                    selectedImgSrc &&
                    <img src={selectedImgSrc} alt={`Image`} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                } 
            </Dialog>

        </Card >


    );
}
