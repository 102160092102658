import React, { useState } from 'react';
import { Modal, TextField, Button, Box, InputLabel } from '@mui/material';
import Select from 'react-select';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function CostPopup({ openCostpopup, setOpencosePopup }) {
    const [selectedOption, setSelectedOption] = useState('');

    const handleClose = () => {
        setOpencosePopup(false);
    };
    console.log("This is selected option", selectedOption);

    const options = [
        { value: 'No Cost', label: 'No Cost' },
        { value: 'Hourly Cost', label: 'Hourly Cost' },
        { value: 'Fixed Cost', label: 'Fixed Cost' },
    ];

    return (
        <div>
            <Modal
                open={openCostpopup}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form>
                    <Box sx={style}>
                        <InputLabel sx={{
                            color: "black",
                            pb: 1,
                        }}>Cost</InputLabel>
                        <Select
                            options={options}
                            required
                            name='skillandhobbie'
                            onChange={setSelectedOption}
                        />

                        {selectedOption.label === 'Hourly Cost' && (
                            <Box mt={2}>
                                <TextField
                                    label="Hourly Rate"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Box>
                        )}

                        {selectedOption.label === 'Fixed Cost' && (
                            <>
                                <Box mt={2}>
                                    <TextField
                                        label="Fixed Amount"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Box>
                                <Box mt={2}>
                                    <TextField
                                        label="Additional Costs"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Box>
                            </>
                        )}

                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 2, mr: 2,
                                    background: "gray",
                                    '&:hover': {
                                        backgroundColor: 'gray',
                                    },
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>

                            <Button variant="contained" type="submit" sx={{ mt: 2 }}>
                                Submit
                            </Button>
                        </div>
                    </Box>
                </form>
            </Modal>
        </div>
    );
}

export default CostPopup;
