import { get, post } from './../Config/config';

export const EmergencyContactAPI = {

    get: (data) => get('/employees/emergency-contacts', { params: data }),
    add: (data) => post('/employees/emergency-contacts/store', data),
    getDetails: (data) => get('employees/emergency-contacts/view?id=', { params: data }),
    update: (data) => post('/employees/emergency-contacts/update', data)

}
