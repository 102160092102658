import React from 'react'
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    Select,
    Slide, TextField, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-hot-toast';
import { Waveform } from '@uiball/loaders'
import { DeleteOutline } from '@mui/icons-material';
import { MeetingIdContext } from '../../../../../contexts/MeetingID/meetingID';
import { Project } from '../../../../../api/Endpoints/Project';
import { Meetings } from '../../../../../api/Endpoints/Meeting';
import Delete from '../../../common/Popup/Delete';
import SelectX from '../../../../form/SelectX';
import { Employee } from '../../../../../api/Endpoints/Employee';

function InternalTeam({ meetingID, projectID, fetchList, list }) {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({});
    const { MeetingID, setMeetingID } = MeetingIdContext();
    const [loading, setLoading] = useState(false)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [ID, setID] = useState(0)
    const [refresh, setRefresh] = React.useState(false);

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "ADD",
        loading: false,
        disabled: false,
    });
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.row?.user_attendee?.name}</Button >
                    </>
                );
            },
        },

        {
            field: 'email',
            headerName: 'Email',
            width: 250,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.row?.user_attendee?.email}</Button >
                    </>
                );
            },
        },

        // {
        //     field: 'meeting_link',
        //     headerName: 'Meeting Link',
        //     width: 300,
        //     renderCell: (params) => {
        //         const meetingLink = params.value; // Assuming 'meeting_link' is the field containing the URL
        //         return (
        //             <a href={meetingLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        //                 {meetingLink}
        //             </a>
        //         );
        //     }
        // }
        // ,



        // {
        //     field: 'meeting_on', headerName: 'Meeting On', width: 200, renderCell: (params) => {

        //         if (params.value) {
        //             return moment(params.value).format('DD-MM-YYYY - hh:mm:ss')
        //         } else {
        //             return "Not started";
        //         }
        //     }
        // },

        // {field: 'status', headerName: 'Status', width: 100, renderCell: (params) => <>{params.status}</> },
        {
            field: 'action', headerName: 'delete', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <>
                    <Button style={{ color: 'black' }} size={'small'} onClick={() => handleDeleteAlert(id)}>
                        <DeleteOutline />
                    </Button>
                </>;
            }
        },
        // {
        //     field: 'delete', headerName: 'Delete', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
        //         let id = params.value?.id;
        //         return <><Button size={'small'}
        //             onClick={() => handleDelete(id)}>Delete</Button>
        //         </>;
        //     }
        // },
    ]
    const fetchProjectMembers = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            console.log(projectID);
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    // console.log("this is list", list);

    useEffect(() => {

        // fetchProjectMembers()
    }, [])

    // const fetchInternalTeam = () => {

    //     Meetings.getDetails({ id: meetingID }).then(response => {
    //         console.log("this is respons", response);
    //         setList(response.data.data.internal_attendees)
    //     }).catch(error => {

    //     })
    // }

    const handleDeleteAlert = (id) => {
        setID(id)
        setDeletePopup(true)
    }

    const onSubmit = async (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        const dataToSubmit = {
            meetings_id: meetingID,
            users_id: data.employee_id?.id,

        }

        console.log(dataToSubmit);
        Meetings.addAttendees(dataToSubmit).then((response) => {

            console.log("respponser here", response);
            fetchList().then(() => {
                toast.success('Attendee has been successfully saved.')
            })
            
            // fetchList().then(() => {
            //     toast.success('Attendee has been successfully saved.')
            // })
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            setValue('employee_id', null)
        })
    }



    return (
        <div>

            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                Callfunc={() => fetchList()}
                url="/meeting-attendee/delete?id="
                title="Attendee" />}


            <Grid container spacing={2} mb={1} mt={0}>
                <Grid item xs={10} sm={10}>

                    <SelectX
                         key={refresh * 0.2}
                        options={fetchProjectMembers}
                        control={control}
                        error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                        error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                        name={'employee_id'}
                        defaultValue={watch('employee_id')}
                    />
                </Grid>
                <Grid item xs={2} sm={2} mt={0.5}>
                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                        onClick={handleSubmit(onSubmit)}
                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>

                </Grid>

            </Grid>
            {loading ? (
                <Waveform
                    size={40}
                    lineWeight={3.5}
                    speed={1}
                    color="black"
                />
            ) : (


                <div style={{ height: 300, width: '100%', }}>
                    {typeof list === "object" &&
                        <DataGrid
                            paginationMode="server"
                            rows={list}
                            columns={columns}

                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            rowCount={list.total}

                            autoHeight={false}
                            density={"comfortable"}
                        />
                    }
                </div>
            )}
        </div >

    )
}

export default InternalTeam
