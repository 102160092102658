import { Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Slide, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from '@mui/lab';
import { ErrorMessage } from '@hookform/error-message';
import TextInput from '../../form/TextInput';
import { useForm } from 'react-hook-form';
import SelectX from '../../form/SelectX';
import ReactSelector from "react-select";
import DateInput from '../../form/DateInput';
import Delete from '../common/Popup/Delete';
import { VideoShootApi } from '../../../api/Endpoints/VideoShoot';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Project } from '../../../api/Endpoints/Project';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Create(props) {

    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({})
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [file, setFile] = useState([])
    const [selectedValue, setSelectedValue] = useState('');

    const handleClose = () => {
        props.setEditId()
        setOpen(false);
    };

    const handleClickOpen = () => {
        props.onNew()
    };
    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchProjects = (e) => {
        return Project.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const Status = [
        { label: "Proposed" },
        { label: 'Planned' },
        { label: 'Shooting Completed' },
        { label: 'Editing in Progress' },
        { label: 'Editing Completed' },
        { label: 'Live on Channels' },
        { label: 'Completed' },
        { label: 'Cancelled' },
    ]


    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })
        let start_date = ''
        let end_date = ''
        if (data?.shoot_start_date) {
            start_date = moment(data?.shoot_start_date).format('YYYY-MM-DD')
        }
        // if (data?.shoot_end_date) {
        //     end_date = moment(data?.shoot_end_date).format('YYYY-MM-DD')
        // }

        const formData = new FormData();

        formData.append('id', props.editId)
        formData.append('title', data?.title)
        formData.append('project_id', data?.projects_id?.id)
        formData.append('title', data?.title)
        formData.append('shooting_date', start_date)
        formData.append('no_of_shoot_days', data?.days_of_shoot)
        formData.append('deliverables', data?.deliverables || '')
        formData.append('description', data?.description || '')
        formData.append('equipments', data?.equipments || '')
        if (data?.status) {
            formData.append('status', data?.status?.label || data?.status)
        }
        if (props?.editId === 0) {
            file?.map(obj => {
                formData.append('attachments[]', obj)
            })
        }

        // formData.forEach(function (value, key) {
        //     console.log(key, ':', value);
        // });

        let action;

        if (props.editId > 0) {
            action = VideoShootApi.update(formData)
        } else {
            action = VideoShootApi.add(formData)
        }
        action.then(response => {
            console.log("this is response data", response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props?.setDetailView(response?.data?.id)
            handleClose()
            props.onUpdate();
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    const handleFileChange = (e) => {
        const newFile = e?.target?.files[0];
        setFile([...file, newFile]); // Add the new file to the state
    };

    const handleDeleteAttachment = (index) => {
        const updatedAttachments = [...file];
        updatedAttachments.splice(index, 1);
        setFile(updatedAttachments);
    };

    const getDetails = async () => {
        setLoading(true)
        let response = await VideoShootApi.getDetails({ id: props.editId });
        console.log(response);
        if (response.status === 200) {
            let data = response?.data?.data;
            // console.log(data);

            setValue('projects_id', { id: data.project?.id, name: data.project?.name })
            setValue('title', data?.title);
            setValue('shoot_start_date', data?.shooting_date);
            setValue('days_of_shoot', data?.no_of_shoot_days);
            setValue('deliverables', data?.deliverables);
            setValue('description', data?.description);
            setValue('equipments', data?.equipments);
            setValue('status', data?.status);
        }
        setLoading(false)
    }

    // console.log(watch('description'));

    // console.log("initial edit id  vlaue", props.editId);
    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            setOpen(true);
            getDetails()
        } else if (Number(props.editId) === 0) {
            // console.log("edit id is false");
            setOpen(true);
        }

    }, [])

    // console.log(file);

    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('client_id')])

    // bonus eligiable 
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

  return (
    <>
    <Button variant="outlined" onClick={handleClickOpen}
    style={{
        color: 'green', 
        borderColor: 'green', 
    }}
    >
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "ADD NEW GOAL"}
            </Button>
            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Goal" : "Add New Goal"}</DialogTitle>
                <DialogContent>
                    {!loading ?
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />
                            <Grid sm={12} container sx={{ p: 1, mt: 0.5 }}>
                                <Grid sx={{}} item sm={12} >
                                    <SelectX
                                        label={'Assign To'}
                                        options={fetchProjects}
                                        control={control}
                                        error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                                        error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                                        name={'projects_id'}
                                        defaultValue={watch('projects_id')}
                                    />
                                </Grid>
                            </Grid>
                            
                            <Grid sm={12} container sx={{ p: 1, mt: 1 }} alignItems="center">
                                <Grid sx={{}} item sm={12} >
                                    <TextInput control={control} name="title"
                                        label="Title"
                                        value={watch('title')} />
                                </Grid>
                            </Grid>

                            
                            {/* {props.editId > 0 &&
                                <Grid container sx={{ mt: 0.5 }} alignItems="center">
                                    <Grid sx={{ p: 1 }} item xs={6}>
                                        <InputLabel sx={{
                                            color: "black",
                                            fontWeight: 500,
                                            fontSize: '14px'
                                        }}>
                                            Status
                                        </InputLabel>
                                        <ReactSelector
                                            placeholder="Status"
                                            styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                            options={Status}
                                            getOptionLabel={option => option.label}
                                            getOptionValue={option => option.label}

                                            // inputValue={inProject}
                                            value={
                                                Status.find(options =>
                                                    options.label === watch('status')
                                                )
                                            }
                                            name='status'
                                            isClearable
                                            defaultValue={(watch('status'))}
                                            onChange={(selectedOption) => setValue('status', selectedOption)}
                                        />
                                    </Grid>
                                </Grid>
                            } */}
                            <Grid sx={{ p: 1, mt: 0.5 }} item xs={6}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 0.5
                                }}>Description  </InputLabel>
                                <TextField
                                    {...register('deliverables')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    sx={{ width: '100%', }}
                                />
                            </Grid>

                                <Grid sx={{ p: 1 }} item xs={6}>
                                    <DateInput
                                        control={control}
                                        name="shoot_start_date"
                                        label="Due Date"
                                        value={watch('shoot_start_date')}

                                    />
                                </Grid>
                                <Grid sx={{ p: 1, mt: 0.5, }} item xs={6}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 0.5
                                }}>Bonus   </InputLabel>
                                <Grid sx={{ p: 1, mt: 0.5, display: "flex",  alignItems: "center"  }} item xs={6}>
                                <RadioGroup
                                 row
                                 aria-labelledby="demo-row-radio-buttons-group-label"
                                 name="row-radio-buttons-group"
                                 value={selectedValue}
                                 onChange={handleChange}
                                >
                                <FormControlLabel value="Eligiable" control={<Radio />} label="Eligiable" />
                                <FormControlLabel value="Not Eligiable" control={<Radio />} label="Not Eligiable" />
                                </RadioGroup>
                                <Grid sx={{ width: "140px", display: "flex", gap: "8px", alignItems: "center"}}  >
                                <Typography sx={{
                                    color: "black",
                                }}>Amount</Typography>
                                <TextInput control={control} name="title"
                                        // label=" Amount"
                                        value={watch('title')} />
                                        </Grid>
                                        </Grid>
                                </Grid>
                                
                            <Grid sx={{ p: 1, mt: 0.5 }} item xs={6}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 0.5
                                }}>Bonus Requirements  </InputLabel>
                                <TextField
                                    {...register('deliverables')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    sx={{ width: '100%', }}
                                />
                            </Grid>
                            
                                

                            {/* <Grid container sx={{ mt: 0.5 }} alignItems="center">
                                <Grid sx={{ p: 1 }} item xs={6}>
                                    <TextInput control={control} name="external_crew"
                                        label="Crew External"
                                        value={watch('external_crew')} />
                                </Grid>


                                <Grid sx={{ p: 1 }} item xs={6}>
                                    <TextInput control={control} name="internal_crew"
                                        label="Internal Team"
                                        value={watch('internal_crew')} />
                                </Grid>


                            </Grid> */}

                            

                            {/* <Grid sx={{ p: 1, mt: 0.5 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 0.5
                                }}>Equipments  </InputLabel>
                                <TextField
                                    {...register('equipments')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    sx={{ width: '100%', }}

                                />
                            </Grid>

                            <Grid sx={{ p: 1, mt: 0.5 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 0.5
                                }}>Description </InputLabel>
                                <TextField
                                    {...register('description')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    sx={{ width: '100%', }}

                                />
                            </Grid> */}

                            {
                                // props?.editId == 0 &&
                                // <>
                                //     <Grid sx={{ p: 1, mt: 0.5 }} item xs={12}>
                                //         <label htmlFor="file-input">
                                //             <input
                                //                 type="file"
                                //                 id="file-input"
                                //                 style={{ display: 'none' }}
                                //                 onChange={handleFileChange}
                                //             />
                                //             <Button variant='outlined' component="span">
                                //                 Add 
                                //             </Button>
                                //         </label>

                                //     </Grid>

                                //     <>
                                //         {file?.map((obj, index) => (
                                //             <Grid display={'flex'} justifyContent={'space-between'} key={index} sx={{ pl: 1, mt: 0.5 }} item xs={12}>
                                //                 <a style={{ color: 'grey', fontSize: '14px' }}>{obj?.name}</a>
                                //                 <a style={{ cursor: 'pointer' }} onClick={() => handleDeleteAttachment(index)}>
                                //                     {/* You can use any icon for delete, for example, a delete icon */}
                                //                     <Delete fontSize='small' style={{ color: 'red' }} />
                                //                 </a>
                                //             </Grid>
                                //         ))}
                                //     </>
                                // </>
                            }

                            {/* <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1
                                }}>Script  </InputLabel>
                                <TextField
                                    {...register('script ')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    sx={{ width: '100%', }}

                                />
                            </Grid> */}


                            {/* display={'flex'} justifyContent={'end'} */}
                            <Grid sx={{ p: 1, mt: 1 }} mb={5} item xs={12}>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            </Grid>



                        </form>
                        :
                        <>Loading...</>
                    }

                </DialogContent>
            </Dialog>
    </>
  )
}

export default Create
