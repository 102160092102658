import React, { useEffect, useState } from 'react';
import { TextField, Button, Modal, Box, Typography, CircularProgress } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Selfi } from '../../../../api/Endpoints/Selfie';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
function ImageView({ showImageUploadPopup, setShowImagePopup, callFunc, setID, ID, source, setSource }) {
    const [title, setTitle] = useState('');
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [open, setOpen] = useState(false)
    const [Loading, setLoading] = useState(false)
    const [err, setErr] = useState({
        title: '',
        phot: '',

    })
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    useEffect(() => {
        if (ID > 0) {
            fetchDetails()
            setOpen(true)
        } else if (ID === undefined) {
            setOpen(true)
        }
    }, [])


    const fetchDetails = async () => {
        setLoading(true)
        const response = await Selfi.getDetails({ id: ID })
        if (response.data.status === "success") {
            const data = response.data.data
            setTitle(data.title)
            setSelectedPhoto(`https://dashboard.omnisellcrm.com/${data.photo}`);

        }
        setLoading(false)
    }

    const handlePhotoSelect = (event) => {
        const file = event.target.files[0];

        setSelectedPhoto(file)
    };

    const handleClose = () => {
        setShowImagePopup(false);
        setID(0)
        setSource()
        setErr('')
        setOpen(false)
        setSelectedPhoto(null)
    };




    const handleSubmit = async (event) => {


        event.preventDefault();

        if (source != 'Family') {

            if (!selectedPhoto) {
                setErr(prevErr => ({
                    ...prevErr,
                    photo: 'photo is requried',


                }));
                return
            }


            if (selectedPhoto == null) {
                setErr(prevErr => ({
                    ...prevErr,
                    photo: 'photo is requried',
                }));
                return
            }
            setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
            const formData = new FormData()
            formData.append('id', ID)
            formData.append('title', title)
            formData.append('selfi_file', selectedPhoto);
            let action
            // if (ID > 0) {
            //     formData.append('id', ID)
            //     action = await Selfi.update(formData)
            // } else {
            action = await Selfi.addSelfie(formData)
            // }
            console.log(action);


            if (action.data.message === "New photo has been successfully saved.") {
                toast.success('New photo has been successfully saved')
            }



            if (action.data.message === "Photo has been successfully updated.") {
                toast.success('Photo has been successfully updated.')
            }
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            callFunc()
            handleClose();
            setSource()
        } else {
            console.log('Api Pending');
        }

    };
    return (

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            }}
        >
            <>


                <form onSubmit={handleSubmit}>
                    <Box sx={style}>
                        {Loading ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                                <CircularProgress size={33} />
                                <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                                    Loading...
                                </Typography>
                            </Box>
                        ) : (
                            <>
                                {/* < TextField
                                    fullWidth
                                    label="Title"
                                    variant="outlined"
                                    value={title}
                                    onChange={(event) => setTitle(event.target.value)}
                                /> */}
                                <h2 style={{ textAlign: 'center' }}>Add {source} {source == 'Family' ? 'Photo' : ''}</h2>

                                <Button variant="contained" color="primary" component="label" sx={{ mt: 4 }}>
                                    <PhotoCameraIcon /> <Typography ml={1}>{ID > 0 ? "Update Photo" : "Upload Photo"}</Typography>
                                    <input type="file" accept="image/*" hidden onChange={handlePhotoSelect} />
                                </Button>
                                {err.phot && <p style={{ color: 'red', margin: 3, }}>{err.phot}</p>}
                                {selectedPhoto && (
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', marginTop: 30 }}>
                                        {/* <h3>Selected Photo:</h3> */}
                                        <div style={{ border: '1px solid black', width: 'auto', height: '200px', overflow: 'hidden', }}>
                                            {selectedPhoto.name ? (
                                                <img
                                                    src={URL.createObjectURL(selectedPhoto)}
                                                    alt="Selected"
                                                    style={{
                                                        width: '100%', height: '100% ', objectFit: 'contain'
                                                    }}
                                                />
                                            ) : (


                                                <img
                                                    src={selectedPhoto}
                                                    alt="Selected"
                                                    style={{
                                                        width: '100%', height: '100% ', objectFit: 'contain'
                                                    }}
                                                />
                                            )}
                                        </div>

                                    </div>
                                )}
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: 10 }}>
                                    <Button variant="outlined" color="error" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <LoadingButton sx={{ ml: 1 }} loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                </div>
                            </>
                        )}
                    </Box>

                </form>

            </>

        </Modal >

    );
}

export default ImageView;
