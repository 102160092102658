import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Backdrop, Button, Card, CircularProgress, Grid, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ReactSelector from 'react-select';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import EditIcon from '@mui/icons-material/Edit';
import { Edit } from '@mui/icons-material';

import dayjs from 'dayjs';
import { WorkPlan } from '../../../../../api/Endpoints/WorkPlan';
import { Project } from '../../../../../api/Endpoints/Project';
import { Employee } from '../../../../../api/Endpoints/Employee';
import WebsitePageCreate from './CreatePage';
import DonutChart from '../../../../form/DonutChart';
import { WebsitePages } from '../../../../../api/Endpoints/WebsitePages';
import DetailView from './PageDetails';

const scheme = yup.object().shape({

})

const WorkPlanIndex = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [editId, setEditId] = useState();
    const [refresh, setRefresh] = useState(0);
    const [listByProject, setListByProject] = useState(false)
    const [detailViewId, setDetailViewId] = useState(false)
    const [projects, setProjects] = useState([])
    const [employees, setEmployees] = useState([])
    const [selectedProjectID, setSelectedProjectID] = useState(null)




    const DetailViewOpen = (id) => {
        setDetailViewId(id)
    }
    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }

    const columns = [
        {

            field: 'page_name', headerName: 'Page Name', width: 170,
            renderCell: (params) => <p onClick={()=>setDetailViewId(params?.row?.id)} style={{ cursor: 'pointer' }}>{params.value}</p>

        }, {
            field: 'page_url', headerName: 'Links', width: 170,
            renderCell: (params) => <a href={params.value} target='_blank' style={{ cursor: 'pointer' }} >{params.value}</a>
        },
        {
            field: 'ui_designed_by_user', headerName: 'UI Designer', width: 150,
            renderCell: (params) => <p>{params?.row?.ui_designed_by_user?.name}</p>
        },
        {
            field: 'html', headerName: 'HTML Developer', width: 150,
            renderCell: (params) => <p>{params?.row?.ui_development_by_user?.name}</p>
        },
        {
            field: 'integration', headerName: 'Integration', width: 150,
            renderCell: (params) => <p>{params?.row?.integration_by_user?.name}</p>
        },
        {
            field: 'data_entry', headerName: 'Data Entry', width: 150,
            renderCell: (params) => <p>{params?.row?.data_entry_by_user?.name}</p>
        },
        {
            field: 'tester', headerName: 'Tester', width: 150,
            renderCell: (params) => <p>{params?.row?.test_by_user?.name}</p>
        },

        // {
        //     field: 'duration', headerName: 'Duration', width: 150,
        //     renderCell: (params) => <p>{params.row.duration_hours ? params.row.duration_hours == 1 ? params.row.duration_hours + "hr" : params.row.duration_hours + 'hrs' : ''} {params.row.duration_minutes ? params.row.duration_minutes + "mins" : ''}</p>
        // },
        {
            field: 'action', headerName: 'Edit', width: 50, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><a
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEdit(id)}><Edit color='primary' fontSize='small' /></a>
                </>;
            }
        },
    ]

    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [selectedUserID, setSelectedUserID] = useState(null)
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState('');



    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }



    const fetchTable = () => {
        setLoading(true);
        console.log(searchKeyword);
        WebsitePages.get({ keyword: searchKeyword, page: parseInt(pageNumber) + 1, projects_id: props.projectID }).then(response => {
            console.log(response.data.data);
            setListByProject(false)
            setList(response.data.data);
            setLoading(false);
        })
    }

    // const fetchByProject = (project_id) => {
    //     if (!listByProject) {
    //         setLoading(true);
    //         Activities.get({ projects_id: project_id, keyword: searchKeyword, page: parseInt(pageNumber) + 1 }).then(response => {
    //             console.log(response.data.data);
    //             setList(response.data.data);
    //             setListByProject(true)
    //             setLoading(false);
    //         })
    //     } else {
    //         fetchTable()
    //     }
    // }

    const fetchProjects = (e) => {
        Project.get({ keyword: e }).then(response => {
            // console.log(response);
            if (typeof response.data.data.data !== "undefined") {
                setProjects(response.data.data.data)
            } else {
                return [];
            }
        })
    }

    const fetchEmployees = (e) => {
        // console.log(e);
        Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                setEmployees(response.data.data.data)
            } else {
                return [];
            }
        })
    }

    const selectProject = (project_id) => {
        console.log(project_id);
        setSelectedProjectID(project_id?.id)
        setValue('projects', project_id?.name || "")

    }

    const selectByUser = (user) => {
        console.log(user);
        setSelectedUserID(user?.id)
        setValue('user', user?.name || "")
    }

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    // useEffect(() => {
    //     fetchProjects()
    //     fetchEmployees()
    // }, [])


    useEffect(() => {
        fetchTable();
        fetchProjects()
        fetchEmployees()
    }, [searchKeyword, listType, pageNumber, selectedProjectID, selectedUserID])

    return (
        <>

            {
                detailViewId &&
                <DetailView id={detailViewId} handleClose={setDetailViewId} />
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Card sx={{ p: 2 }} variant="outlined">
                <Grid mb={2} textAlign={'end'}>
                    <WebsitePageCreate projectID={props.projectID} onNew={handleCreate} editId={editId} setEditId={setEditId} fetchTable={fetchTable} />
                </Grid>


                <Grid display={'flex'} justifyContent={'space-between'}>
                    <Grid>
                        <DonutChart width={150} height={150} />
                        <h5 style={{ textAlign: 'center', marginTop: -2 }}>UI Design</h5>
                    </Grid>
                    <Grid>
                        <DonutChart width={150} height={150} />
                        <h5 style={{ textAlign: 'center', marginTop: -2 }}>HTML Conversion</h5>
                    </Grid>
                    <Grid>
                        <DonutChart width={150} height={150} />
                        <h5 style={{ textAlign: 'center', marginTop: -2 }}>Integration</h5>
                    </Grid>
                    <Grid>
                        <DonutChart width={150} height={150} />
                        <h5 style={{ textAlign: 'center', marginTop: -2 }}>Data Entry</h5>
                    </Grid>
                    <Grid>
                        <DonutChart width={150} height={150} />
                        <h5 style={{ textAlign: 'center', marginTop: -2 }}>Testing</h5>
                    </Grid>
                </Grid>

                <Grid height={400}>
                    {typeof list?.data === "object" &&
                        <DataGrid
                            paginationMode="server"
                            rows={list?.data}
                            columns={columns}
                            page={pageNumber}
                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            rowCount={list.total}
                            onPageChange={handlePageChange}
                            // autoHeight
                            density={"comfortable"}
                        />
                    }
                </Grid>
            </Card>
        </>
    );
};

export default WorkPlanIndex;
