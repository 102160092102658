import React, { useState, useEffect } from "react";
import Create from "./create";
import { Button, Card, Tab, Tabs } from "@mui/material";
import PageHeader from "../common/page-header";
import DetailView from "./my-target/DetailView";

import MyTargets from "./my-target/MyTargets";
import AssignedTarget from "./assigned-target/AssignedTarget";

function Targets() {
  const [editId, setEditId] = useState();
  const [refresh, setRefresh] = useState(0);
  const [detailViewId, setDetailViewId] = useState(false);

  //state for store tab value such as My Targets and Target set by Me
  const [tabValue, setTabValue] = useState("My Targets");

  const handleCreate = () => {
    setEditId(0);
    setRefresh(Math.random());
  };

  return (
    <div>
      <PageHeader title={"Targets & Goals"} />

      <Card
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          width: { xs: "100%", md: "auto" },
        }}
        variant="outlined"
      >
        <Tabs value={tabValue} aria-label="basic tabs example">
          <Button
            variant={tabValue === "My Targets" ? "contained" : "text"}
            onClick={() => setTabValue("My Targets")}
            sx={{
              padding: "12px 45px",
              borderRadius: "12px",
              position: "relative",
              transition: "background-color 0.3s, transform 0.3s",
              "&:hover": {
                backgroundColor: "lightgray",
                transform: "scale(1.05)",
              },
              '&[aria-selected="true"]': {
                backgroundColor: "lightgray", // Changed to match hover effect background color
                color: "white",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                transform: "scale(1.1)",
              },
            }}
          >
            My Targets
          </Button>
          <Button
            variant={tabValue === "Targets Set by Me" ? "contained" : "text"}
            onClick={() => setTabValue("Targets Set by Me")}
            sx={{
              padding: "12px 20px",
              borderRadius: "12px",
              position: "relative",
              transition: "background-color 0.3s, transform 0.3s",
              "&:hover": {
                backgroundColor: "lightgray",
                transform: "scale(1.05)",
              },
              '&[aria-selected="true"]': {
                backgroundColor: "lightgray", // Changed to match hover effect background color
                color: "white",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                transform: "scale(1.1)",
              },
            }}
          >
            Targets Set by Me
          </Button>
        </Tabs>
        {tabValue !== "My Targets" && (
          <Create
            key={refresh}
            onNew={handleCreate}
            editId={editId}
            setEditId={setEditId}
          />
        )}
      </Card>

      {tabValue == "My Targets" ? (
        <MyTargets
          setDetailViewId={setDetailViewId}
          detailViewId={detailViewId}
        />
      ) : (
        <AssignedTarget
          setDetailViewId={setDetailViewId}
          detailViewId={detailViewId}
        />
      )}
    </div>
  );
}

export default Targets;
