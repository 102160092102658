import { Outlet } from 'react-router-dom';
import {
    Button, Checkbox,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide, TextField, Typography,
    useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Footer } from '../components/footer';
import { DashboardNavbar } from '../components/dashboard-navbar';
import { DashboardSidebar } from '../components/dashboard-sidebar';
import { useSettings } from "../contexts/Settings/settings-context";
import { forwardRef, useEffect, useState } from "react";
import { Employee } from "../api/Endpoints/Employee";
import moment from "moment";
import { Task } from "../api/Endpoints/Task";
import toast from "react-hot-toast";
import { User } from "../icons/user";
import { Users } from "../api/Endpoints/Users";
import LinearProgress from '@mui/material/LinearProgress';
import LoginStatus from "../project-portal/layout/LoginStatus";
import { LoadingButton } from "@mui/lab";
import SignOutButton from '../project-portal/pages/SignOut/create';
import SignInButton from '../project-portal/pages/SignIn/create';
import { UserIdContext } from '../contexts/UserID/UserID';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    paddingTop: 0
}));

const DashboardLayoutContent = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}));



export const DashboardLayout = () => {
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { settings, saveSettings } = useSettings();
    const { setSignIn } = UserIdContext()
    const [signInButtonLoading, setSignInButtonLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };


    const [openSignOut, setOpenSignOut] = useState(false);
    const handleSignOutClickOpen = () => { setOpenSignOut(true); };
    const handleSignOutClose = () => { setOpenSignOut(false); };


    const [taskList, setTaskList] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);

    const [taskWithTime, setTaskWithTime] = useState([]);

    const defaultSignInTime = moment().format("DD-MM-YYYY 09:00:00");
    // console.log(defaultSignInTime)

    const [signInData, setSignInData] = useState(null);

    const checkAttendance = async () => {
        let response = await Employee.checkAttendence({ date: moment().format("DD-MM-YYYY") });
        if (response.data.status === "error") {
            handleClickOpen();
            fetchTaskList();

        } else {
            setSignIn(true)
            setSignInData(response.data.data);
            toast.success("Logged in " + moment(response.data.data.created_at).format('LT'))
        }
        console.log("checkAttendance", response);
    }

    useEffect(() => {
        checkAttendance();
    }, [])


    const handlePinSidebar = () => {
        saveSettings({
            ...settings,
            pinSidebar: !settings.pinSidebar
        });
    };

    const generateGreetings = () => {

        var currentHour = moment().format("HH");

        if (currentHour >= 3 && currentHour < 12) {
            return "Good Morning";
        } else if (currentHour >= 12 && currentHour < 15) {
            return "Good Afternoon";
        } else if (currentHour >= 15 && currentHour < 20) {
            return "Good Evening";
        } else if (currentHour >= 20 && currentHour < 3) {
            return "Good Night";
        } else {
            return "Hello"
        }

    }



    const fetchTaskList = async () => {
        let user = await Users.me();
        let data;
        data = { assigned_to: user.data?.data?.id };
        data.status = ["Not started", "In progress"];
        console.log("NHA")
        let list = await Task.get(data);
        if (list.data.status === "success") {
            setTaskList(list.data.data.data)
        } else {
            toast.error("Unable to fetch the tasks " + list.data?.message);
        }
    }

    const handleCheck = (e) => {
        let id = parseInt(e.target.id);
        let data = selectedTasks;
        data.includes(id) ? setSelectedTasks(data.filter(el => el !== id)) : setSelectedTasks([...data, id]);
        console.table(selectedTasks);
        console.log(selectedTasks.includes(22));
    }

    const handleSignIn = async () => {
        setSignInButtonLoading(true);
        // let ip = await fetch('https://geolocation-db.com/json/').then(response => {
        //     return response.json();
        // });
        // console.log("IP", ip)
        let user = await Users.me();

        let data = {
            task_list: selectedTasks,
            // logged_in_ip: ip.IPv4,
            users_id: user.data?.data?.id
        }
        console.log('resopnse');

        let resopnse = await Employee.signIn(data);
        console.log(resopnse);

        if (resopnse.data.status === "error") {
            toast.success(resopnse.data.message);
            window.location.reload();
        }

        if (resopnse.data.status === "success") {
            toast.success("Successfully signed in");
            window.location.reload();
        }
        setSignInButtonLoading(false);

    }

    const handleSignOut = async () => {
        let ip = await fetch('https://geolocation-db.com/json/').then(response => {
            return response.json();
        }).catch((error) => {
            // Your error is here!
            console.log(error)
        });

        console.log("IP", ip)
        let user = await Users.me();

        let data = {
            task_list: selectedTasks.map(obj => {
                return {
                    id: obj,
                    duration: 0
                }
            }),
            logged_out_ip: ip.IPv4,
            logged_out_time: moment().format('yyyy-MM-DD HH:mm:ss'),
            users_id: user.data?.data?.id
        }
        let resopnse = await Employee.signOut(data);

        if (resopnse.data.status === "error") {
            toast.success(resopnse.data.message);
            window.location.reload();
        }

        if (resopnse.data.data.status === "success") {
            toast.success("Successfully signed out in");
            window.location.reload();
        }
        console.log("handleSignIn", resopnse)

    }

    const lateCalculator = (loginTime) => {
        let late = moment(moment(loginTime), "DD-MM-YYYY HH:mm:ss", "HH", "mm").diff(moment(defaultSignInTime, "DD-MM-YYYY HH:mm:ss", "HH", "mm"), 'minutes');
        if (late > 0) {
            return Number(late);
        }

    }

    const handleSignOutClick = () => {
        fetchTaskList();
        handleSignOutClickOpen();
    }

    const handleSignOutReport = (taskId, duration) => {
        let newElement = { id: taskId, duration: duration }
        let data = taskWithTime;

        var index = data.findIndex(x => x.id === taskId);
        if (index === -1) {
            setTaskWithTime(taskWithTime => {
                return {
                    ...data,
                    newElement
                }
            });
        } else {
            data = data.splice(index, 1);
            setTaskWithTime(taskWithTime => {
                return {
                    ...data,
                    newElement
                }
            });
        }
        console.log(taskWithTime)
    }

    return (
        <DashboardLayoutRoot>

            <DashboardNavbar />
            {!mdDown && (
                <DashboardSidebar
                    onPin={handlePinSidebar}
                    pinned={settings.pinSidebar}
                />
            )}
            <DashboardLayoutContent
                sx={{
                    ml: {
                        md: settings.pinSidebar ? '270px' : '73px'
                    }
                }}
            >
                {
                    signInData ?
                        <Grid>
                            <LoginStatus signInOpen={setOpen} loggedOutAt={signInData.logged_out_time} loggedInAt={signInData.created_at} lateBy={lateCalculator(signInData.created_at)} onSignOut={handleSignOutClick} />
                        </Grid>
                        : <div>
                            <div className="crm-header-block">
                                <div style={{marginLeft:'auto',marginRight:20}} className="crm-header-block-right">
                                    <div className="crm-header-profile-block">
                                        <div onClick={()=>setOpen(true)} style={{cursor:'pointer'}} className="crm-header-profile-block-item sign-out">
                                            <a>
                                                Sign In       
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                }
                {
                    <a hidden>
                        <SignOutButton open={openSignOut} setOpen={setOpenSignOut} handleSignOut={handleSignOut} />
                    </a>
                }
                {
                    <a hidden>
                        <SignInButton open={open} setOpen={setOpen} handleSignIn={handleSignIn} />
                    </a>
                }


                {/* <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {generateGreetings()}
                    </DialogTitle>
                    <DialogContent>

                        <Typography sx={{ mb: 2 }} variant={"h6"}>These are the pending tasks assigned for you. Please select the tasks you are working on today</Typography>
                        {taskList.map(obj => {
                            return <div key={obj.id}>
                                <Checkbox
                                    onChange={e => handleCheck(e)} id={obj.id}
                                    checked={selectedTasks.includes(parseInt(obj.id)) ? true : false}
                                    defaultChecked size="small"
                                    aria-label={1}
                                /> {obj.title}
                            </div>;
                        })}

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        <LoadingButton loading={signInButtonLoading} variant="contained" onClick={handleSignIn}>
                            Sign In To Work
                        </LoadingButton>

                    </DialogActions>
                </Dialog> */}


                {/* <Dialog
                    open={openSignOut}
                    onClose={handleSignOutClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {generateGreetings()}
                    </DialogTitle>
                    <DialogContent>

                        <Typography sx={{ mb: 2 }} variant={"h6"}>These are the tasks assigned for you. Please select the tasks you are worked on today</Typography>
                        {taskList.map(obj => {
                            return <div key={obj.id}>
                                <Checkbox
                                    onChange={e => handleCheck(e)} id={obj.id}
                                    checked={selectedTasks.includes(parseInt(obj.id)) ? true : false}
                                    defaultChecked size="small"
                                    aria-label={1}
                                /> {obj.title}

                                {selectedTasks.includes(parseInt(obj.id)) === "10" &&
                                    <TextField type={'time'} onChange={e => handleSignOutReport(obj.id, e.target.value)}
                                        id="outlined-basic" label="Task duration" variant="standard" size={"small"}
                                        sx={{ width: '130px', marginLeft: '10px' }} />
                                }
                            </div>;
                        })}

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSignOutClose}>Close</Button>
                        <Button variant="contained" onClick={handleSignOut} autoFocus>
                            Sign Out
                        </Button>
                    </DialogActions>
                </Dialog> */}



                <Outlet />
                <Footer />
            </DashboardLayoutContent>
        </DashboardLayoutRoot>
    );
};
