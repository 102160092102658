import React from 'react';

const CloseIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.34917 9.08835C5.23834 9.08835 5.12751 9.04752 5.04001 8.96002C4.87084 8.79085 4.87084 8.51085 5.04001 8.34169L8.34167 5.04002C8.51084 4.87086 8.79084 4.87086 8.96001 5.04002C9.12917 5.20919 9.12917 5.48919 8.96001 5.65835L5.65834 8.96002C5.57667 9.04752 5.46001 9.08835 5.34917 9.08835Z" fill="#D7B5B5"></path>
            <path d="M8.65084 9.08835C8.54001 9.08835 8.42917 9.04752 8.34167 8.96002L5.04001 5.65835C4.87084 5.48919 4.87084 5.20919 5.04001 5.04002C5.20917 4.87086 5.48917 4.87086 5.65834 5.04002L8.96001 8.34169C9.12917 8.51085 9.12917 8.79085 8.96001 8.96002C8.87251 9.04752 8.76167 9.08835 8.65084 9.08835Z" fill="#D7B5B5"></path>
            <path d="M8.75 13.2709H5.25C2.08251 13.2709 0.729172 11.9175 0.729172 8.75002V5.25002C0.729172 2.08252 2.08251 0.729187 5.25 0.729187H8.75C11.9175 0.729187 13.2708 2.08252 13.2708 5.25002V8.75002C13.2708 11.9175 11.9175 13.2709 8.75 13.2709ZM5.25 1.60419C2.56084 1.60419 1.60417 2.56085 1.60417 5.25002V8.75002C1.60417 11.4392 2.56084 12.3959 5.25 12.3959H8.75C11.4392 12.3959 12.3958 11.4392 12.3958 8.75002V5.25002C12.3958 2.56085 11.4392 1.60419 8.75 1.60419H5.25Z" fill="#D7B5B5"></path>
        </svg>
    );
};

export default CloseIcon;
