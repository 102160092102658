import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SelectX from '../../form/SelectX';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { Close, KeyboardReturn } from '@mui/icons-material';
import { Task } from '../../../api/Endpoints/Task';
import { UserIdContext } from '../../../contexts/UserID/UserID';
import SignOutTask from '../SignOut/taskModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 350,
    bgcolor: 'background.paper',
    //   border: '2px solid #000',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

export default function LinkTaskModal(props) {

    const { UserID } = UserIdContext();


    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm();
    const [open, setOpen] = React.useState(false);
    const [taskOpen, setTaskOpen] = React.useState(false)

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        props.setID()
        setOpen(false);
    }

    const fetchTask = (e) => {
        return Task.get({ keyword: e, assigned_to: UserID, status: ['Not Started', 'In Progress'] }).then((response) => {
            // console.log(response);
            return response.data.data.data
        })
    }

    const handleTaskOpen = () => {
        setTaskOpen(true)
        handleClose()
    }


    return (
        <div>
            {
                < SignOutTask open={taskOpen} setOpen={setTaskOpen} id={props.id} setID={props.setID} linkModalClose={handleClose} />
            }
            <Modal
                open={props.id}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                 <Box sx={style}>
                    <Grid display={'flex'} justifyContent={'space-between'}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Link to Task
                        </Typography>
                        <a style={{ cursor: 'pointer' }} onClick={handleClose}> <Close sx={{ fontWeight: 200 }} /> </a>
                    </Grid>
                    <Grid mt={5} flexDirection={'column'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <SelectX
                            placeholder={'Choose Task'}
                            options={fetchTask}
                            control={control}
                            error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                            error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                            name={'projects_id'}
                            defaultValue={watch('projects_id')}
                        />
                        <h5>Or</h5>
                        <Button onClick={handleTaskOpen} variant='contained'>Add New</Button>
                    </Grid>

                    <Grid mt={6} display={'flex'} justifyContent={'end'}>
                        <Button variant='outlined'>Submit</Button>
                    </Grid>

                </Box>
            </Modal>
        </div>
    );
}
