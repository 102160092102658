import React, { useEffect, useState } from 'react';
import { useAppSettings } from "../../../hooks/use-app-settings";
import { Backdrop, Button, Card, CircularProgress, Grid, TextField } from "@mui/material";
import PageHeader from "../common/page-header";
import Create from './create';
import BasicModal from './create';
import SignInTab from '../SignIn/TabMenus';
import SignOutButton from './create';
import SignInButton from '../SignIn/create';
import MissedSignInButton from '../Signin Missed/create';

const SignOut = (props) => {
    const appSettings = useAppSettings();


    const [clientReport, setClientReport] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [ClientID, setClientID] = useState(0)
    const [deleteId, setDeleteId] = useState(false)
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleDelete = (id) => {
        setDeleteId(id)
    }
    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }


    const handleCreateNew = () => {

    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(async () => {

    }, [searchKeyword, listType, pageNumber])

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }


    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const viewTabs = (id) => {
        setClientID(id)
        setTabs(true)
    }

    return (
        <>

            <>
                <PageHeader title={"Sign Out"} onCreateNew={handleCreateNew} />

                <Card sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'end' }} variant="outlined" >
                    <Grid style={{ marginRight: 50 }}>
                        <SignInButton />
                    </Grid>
                    <Grid style={{ marginRight: 50 }}>
                        <SignOutButton />
                    </Grid>
                    <MissedSignInButton />
                    {/* <BasicModal key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} /> */}

                </Card>
            </>
            {/* )} */}

        </>

    );
};

export default SignOut;
