import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Card,
    CardContent,
    Typography,
    Grid,
    Divider,
    Select,
    Backdrop,
    MenuItem,
    DialogTitle,
    InputLabel,
    TextareaAutosize,
    TextField,
    Slide,
    CircularProgress,
} from "@mui/material";
import { useEffect } from 'react';
import { useState } from 'react';
import { Account } from '../../../../api/Endpoints/Account';


const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));




const Details = ({ ClientID }) => {
    const classes = useStyles();


    const [Loading, setLoading] = useState(false)
    const [Data, setData] = useState()



    const fetchDetails = () => {
        setLoading(true);
        Account.getDetails({ account_id: ClientID }).then(response => {
            setData(response.data.data);
            setLoading(false);
        })
    }

    console.log(Data);

    useEffect(() => {
        fetchDetails()
    }, [])
    return (




        <>
            {Loading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={Loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Client:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.account_name}
                                        </Typography>
                                    </Typography>


                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Company Name:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.company_name}
                                        </Typography>
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} sx={{ pt: 4 }} />
                            <Grid container spacing={2} >
                                <Grid item xs={6} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Started Date :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.created_at ? new Date(Data.created_at).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric"
                                            }) : ""}

                                        </Typography>
                                    </Typography>


                                </Grid>

                                <Grid item xs={12} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Address:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.address}

                                        </Typography>

                                    </Typography>


                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} sx={{ pt: 4 }} />
                            <Grid item xs={12} sm={12} mt={2}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Remarks :
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        {Data?.remarks}
                                    </Typography>
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )
            }
        </>
    );
};

export default Details;
