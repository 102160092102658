import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DonutChart from '../../form/DonutChart';
import { Grid, Popover, Tooltip } from '@mui/material';
import { useState } from 'react';
import moment from 'moment';
import { Group, Person, Search } from '@mui/icons-material';
import AssignToPopup from './Modals/changeAssignToModal';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

export default function BasicCard({ datas, viewTabs }) {
    const [count, setcount] = useState([
        {
            no: 1, name: 'UI'
        }, {
            no: 2, name: 'HTML'
        }, {
            no: 3, name: 'Integration'
        }, {
            no: 4, name: 'Data Entry'
        }, {
            no: 5, name: 'Testing'
        },
    ])

    const [anchorEl, setAnchorEl] = useState(null);
    const [id, setId] = useState()
    const [openAssignTopopup, setOpenAssignTopopup] = useState()

    const handlePopoverOpen = (event) => {
        // console.log(event);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event) => {
        // console.log(event);
        event.preventDefault()
        setAnchorEl(false);
    };

    const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'

    const open = Boolean(anchorEl);

    const handleChangeAssignTo = (id) => {
        setId(id)
        setOpenAssignTopopup(true)
    }

    return (
        <>
            {
                openAssignTopopup &&
                <AssignToPopup open={openAssignTopopup} id={id} setId={setId} setOpen={setOpenAssignTopopup} />
            }
            <Card sx={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)', maxHeight: 400, display: 'flex', flexDirection: 'column' }}>
                <CardContent style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ cursor: 'pointer', marginBottom: '8px' }} onClick={() => viewTabs(datas.id)} variant="h6" component="div">
                        Title
                    </Typography>
                    <Typography style={{ marginBottom: '8px', textAlign: 'justify' }} variant="body1" color="textSecondary" component="div">
                        {
                            lorem?.length > 300 ?
                                <p>{lorem?.slice(0, 300)} ...</p>
                                :
                                <p>{lorem}</p>
                        }
                    </Typography>

                    <Grid container justifyContent="space-between" style={{ marginTop: 'auto' }}>
                        <Typography style={{ marginBottom: '8px' }} variant="body2" color="textSecondary" >
                            <span style={{ fontSize: '12px', fontWeight: 600, color: 'grey' }}> Notes</span>  (3)
                        </Typography>
                        <Typography style={{ marginBottom: '8px' }} variant="body2" color="textSecondary">
                            <span style={{ fontSize: '12px', fontWeight: 600, color: 'grey' }}>Priority:</span>  Medium
                        </Typography>
                    </Grid>

                    <Grid container display={'flex'} justifyContent="space-between" style={{ marginTop: 'auto' }}>
                        <Typography style={{ marginBottom: '8px' }} variant="body2" color="textSecondary" component="div">
                            <span style={{ fontSize: '12px', fontWeight: 600, color: 'grey' }}>Assigned to:</span> <span onClick={() => handleChangeAssignTo(datas?.id)} style={{ cursor: 'pointer', }}>Muzammil</span>
                        </Typography>
                        <Typography style={{ marginBottom: '8px' }} variant="body2" color="textSecondary" component="div">
                            <span style={{ fontSize: '12px', fontWeight: 600, color: 'grey' }}>Due date:</span> {moment(datas?.start_date).format('DD-MM-YYYY')}
                        </Typography>
                    </Grid>
                </CardContent>
            </Card >
        </>
    );
}
