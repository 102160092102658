import { get, post } from './../Config/config';

export const Documents = {

    get: (data) => get('/employees/documents', { params: data }),
    add: (data) => post('/employees/documents/store', data),
    getDetails: (data) => get('/employees/documents/view?id=', { params: data }),
    update: (data) => post('/employees/documents/update', data),

    // Hr Documents
    getHR: (data) => get('/hr/documents', { params: data }),
    getHRDetails: (data) => get('/hr/documents/view?id=', { params: data }),

}
