import { Backdrop, Button, Card, CircularProgress, Grid, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";


import React from 'react'




const rows = [
    {
        id: 1,
        project_name: 'Desai homes',
        created_date: "10/4/2023",

    },

    // Add more rows as needed...
];
const columns = [
    { field: 'id', headerName: 'ID', width: 200 },
    {
        field: 'project_name', headerName: 'Project Name', width: 250
    },
    { field: 'created_date', headerName: 'Created Date', width: 250 },

    {
        field: 'edit',
        headerName: 'Edit',
        width: 100,
        renderCell: (params) => (
            <Button size="small" >
                Edit
            </Button>
        ),
    }
    // Add more columns as needed...                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                q

];

function Projects() {


    return (
        <div>

            <Card sx={{ m: 2 }} variant="outlined">

                <DataGrid
                    paginationMode="server"
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowHeight={20}
                    rowsPerPageOptions={[10]}
                    autoHeight
                    density={'comfortable'}
                />

            </Card>
        </div >
    )
}

export default Projects
