import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Slide,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Rating,
  Box,
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../../components/Form/TextInput";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import { Employee } from "../../../api/Endpoints/Employee";
import DateInput from "../../../components/Form/DateInput";
import { DatePicker, StaticDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import axios from "axios";
import { debounce } from "../../../utils/debounce";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
  title: yup.string().required(),
  priority: yup.string(),
  description: yup.string(),
  achieved: yup.number(),
  unachieved: yup.number(),
});

const Create = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    Controller,
    setValue,
    getValues,
    reset,
  } = useForm({ resolver: yupResolver(scheme) });
  const [open, setOpen] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleClickOpen = () => {
    props.onNew();
  };
  const handleClose = () => {
    props.setEditId();
    setOpen(false);
  };

  //employees list
  const [employees, setEmployees] = useState([]);
  //assigned employees list
  const [assignedTo, setAssignedTo] = useState([]);
  //achieved rating
  const [achieved, setAchieved] = useState(0);
  //unachieved rating
  const [unachieved, setUnachieved] = useState(0);

  //fetch all employees list
  const fetchEmployees = async (keyword) => {
    try {
      const response = await Employee.get({ keyword }); // Adjust API call to include pagination if needed
      console.log(response.data);
      if (response.data.data && response.data.data.data) {
        setEmployees(response.data.data.data); // Set employees based on fetched data
      } else {
        setEmployees([]);
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
      setEmployees([]);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  //component variables
  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Submit",
    loading: false,
    disabled: false,
  });
  const [alerts, setAlerts] = useState({
    type: "",
    message: "",
    active: false,
  });

  const onSubmit = (data) => {
    console.log(data);
    console.log(assignedTo);
    console.log({ achieved, unachieved });
    // setFormButtonStatus({ ...formButtonStatus, loading: true });
    // setAlerts({
    //   active: false,
    //   message: "Internal server error",
    //   type: "error",
    // });

    // let dataToSubmit = {
    //   id: props.editId,
    //   title: data.title,
    //   description: data.description,
    //   priority: data.priority,
    // };

    // console.log(dataToSubmit);
    // let action;

    // if (props.editId > 0) {
    //   action = ReportIssue.update(dataToSubmit);
    // } else {
    //   action = ReportIssue.add(dataToSubmit);
    // }

    // action
    //   .then((response) => {
    //     console.log(response.data);
    //     setFormButtonStatus({
    //       label: "Submitted",
    //       loading: false,
    //       disabled: true,
    //     });
    //     setAlerts({
    //       active: true,
    //       message: response.data.message,
    //       type: response.data.status,
    //     });
    //     setFormButtonStatus({
    //       label: "Create",
    //       loading: false,
    //       disabled: false,
    //     });
    //     toast.success(response.data.message);
    //     props.onUpdate();
    //     setOpen(false);
    //     setTimeout(() => {
    //       setAlerts({});
    //     }, 2000);
    //   })
    //   .catch((errors) => {
    //     console.log(errors);
    //     toast.error("Internal server error");
    //     setAlerts({
    //       active: true,
    //       message: "Internal server error",
    //       type: "error",
    //     });
    //     setFormButtonStatus({
    //       label: "Create",
    //       loading: false,
    //       disabled: false,
    //     });
    //   });
  };

  const fetchWorkRequestDetails = async () => {
    setLoading(true);

    setLoading(false);
  };

  // const fetchDetails = async () => {
  //   setLoading(true);
  //   const response = await ReportIssue.getDetails({ id: props.editId });
  //   setLoading(false);
  //   if (response.data.status === "success") {
  //     const data = response.data.data;
  //     console.log(data);
  //     setValue("title", data.title);
  //     setValue("priority", data.priority);
  //     setValue("description", data.description);
  //   }
  // };

  useEffect(() => {
    if (parseInt(props.editId) > 0) {
      // fetchDetails();
      setOpen(true);
    } else if (Number(props.editId) === 0) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    setRefresh(Math.random());
  }, []);

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        {props.icon ? (
          <AddIcon
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          />
        ) : (
          "Add Target"
        )}
      </Button>

      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: "100%",
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {parseInt(props.editId) > 0
            ? "Edit Targets & Goals"
            : "Set Targets & Goals"}
        </DialogTitle>
        <DialogContent>
          {!loading ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <ErrorMessage
                errors={errors}
                name="multipleErrorInput"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p key={type}>{message}</p>
                  ))
                }
              />

              <Grid sx={{ p: 1 }} item xs={12}>
                <Autocomplete
                  multiple
                  id="assigned-to"
                  options={employees}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  {...register("assigned_to")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assigned To"
                      placeholder="Select Employees"
                    />
                  )}
                  onChange={(e, value) => {
                    console.log(
                      "Selected Employees:",
                      value.map((user) => user.name).join(", ")
                    );
                    setAssignedTo(value.map((user) => user.id));
                  }}
                  onInputChange={debounce((event, newInputValue) => {
                    fetchEmployees(newInputValue); // Fetch employees based on input
                  }, 300)}
                  getOptionDisabled={(option) => assignedTo.includes(option.id)}
                />
              </Grid>

              <Grid sx={{ p: 1 }} item xs={12}>
                <TextInput
                  control={control}
                  name="title"
                  label="Title"
                  value={watch("title")}
                />
              </Grid>

              <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                <InputLabel
                  sx={{
                    color: "black",
                    pb: 1,
                  }}
                >
                  Description{" "}
                </InputLabel>
                <TextField
                  {...register("description")}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                <DatePicker
                  label="Due Date"
                  value={watch("dueDate")}
                  onChange={(newValue) => setValue("dueDate", newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  disablePast
                />
              </Grid>

              <Grid sx={{ p: 1, pt: 1 }} item xs={12}>
                <InputLabel
                  sx={{
                    color: "black",
                    pb: 1,
                  }}
                >
                  Select Star Rating
                </InputLabel>
                <Grid container spacing={2}>
                  <Grid item>
                    <Box
                      sx={{ border: "1px solid gray", p: 2, borderRadius: 2 }}
                    >
                      <InputLabel
                        sx={{
                          color: "black",
                          pb: 1,
                        }}
                      >
                        Achieved
                      </InputLabel>
                      <Rating
                        name="green-rating"
                        value={achieved}
                        onChange={(e, newValue) => setAchieved(newValue)}
                        getLabelText={(value) =>
                          `${value} Heart${value !== 1 ? "s" : ""}`
                        }
                        label="Green Rating"
                        labelPlacement="end"
                        style={{ color: "green", fontSize: "40px" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{ border: "1px solid gray", p: 2, borderRadius: 2 }}
                    >
                      <InputLabel
                        sx={{
                          color: "black",
                          pb: 1,
                        }}
                      >
                        UnAchieved
                      </InputLabel>
                      <Rating
                        name="red-rating"
                        value={unachieved}
                        onChange={(e, newValue) => setUnachieved(newValue)}
                        getLabelText={(value) =>
                          `${value} Heart${value !== 1 ? "s" : ""}`
                        }
                        label="Red Rating"
                        labelPlacement="end"
                        style={{ color: "red", fontSize: "40px" }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid sx={{ p: 1 }} item xs={12}>
                <LoadingButton
                  loading={formButtonStatus.loading}
                  disabled={formButtonStatus.disabled}
                  variant="outlined"
                  type="submit"
                >
                  {formButtonStatus.label}
                </LoadingButton>
              </Grid>
            </form>
          ) : (
            <>Loading...</>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Create;
