import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Card, CircularProgress, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { WorkRequest } from '../../../api/Endpoints/WorkRequest';
import Create from './create';
import axios from 'axios';
import { Backlinks } from '../../../api/Endpoints/Backlinks';
import BasicTabs from './TabMenus';
import { useNavigate } from 'react-router-dom';
import { MoreOptionsDropdown } from '../common/MoreAction/actionButton';

import TableTools from '../common/TableTools';

import Delete from '../common/Popup/Delete';
const Index = (props) => {


    const navigate = useNavigate();

    const columns = [
        {
            field: 'site_name', headerName: 'Site name', width: 250, renderCell: (params) => (
                <p
                    style={{ margin: 0, padding: 0, color: 'black' }}
                    size="small"

                    onClick={() => navigate(`details/${params.id}`)}
                >
                    {params.row.site_name}

                </p>
            ),

        },
        { field: 'URL', headerName: 'URL', width: 250, renderCell: (params) => <>{params.row.url}</> },
        { field: 'Site Category ', headerName: 'Site Category ', width: 200, renderCell: (params) => <>{params.row.site_category}</> },
        { field: 'Site Type ', headerName: 'Site Type', width: 140, renderCell: (params) => <>{params.row.site_type}</> },
        {
            width: 50,
            renderCell: (params) => <MoreOptionsDropdown params={params} setEditId={setEditId} setID={setID} setDeletePopup={setDeletePopup} />,
        },

    ];


    const [editId, setEditId] = useState();
    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [refresh, setRefresh] = useState(0);
    const handleEdit = (id) => { setRefresh(Math.random); setEditId(parseInt(id)); }
    const [loading, setLoading] = useState(false);
    const [Tabs, setTabs] = useState(false)
    const [SiteID, setSiteID] = useState(0)
    const [ID, setID] = useState(0)
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }


    const fetchTable = () => {
        setLoading(true);
        Backlinks.get({keyword:searchKeyword}).then(response => {
            setList(response.data.data.data);
            setLoading(false);
        })
    }
    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }


    const handleCreateNew = () => {

    }

    const viewTabs = (id) => {
        setSiteID(id)
        setTabs(true)
    }
    useEffect(async () => {
        fetchTable();
    }, [searchKeyword, listType, pageNumber])

    return (
        <>
            {/* {Tabs ? (<BasicTabs setTabs={setTabs} SiteID={SiteID} />
            ) : ( */}


            <>


                {showDeletePopup && <Delete
                    ID={ID}
                    setID={setID}
                    setDeletePopup={setDeletePopup}
                    Callfunc={() => fetchTable()}
                    url="/backlink-sites/delete?id="
                    title="BackLink" />}



                <Card className="backlink-title-block" sx={{ p: 2, display: "flex", justifyContent: "end" }} variant="outlined">
                    <PageHeader className="backlink-title" title={"Backlink Sites Master"} onCreateNew={handleCreateNew} />
                    <div className='TableTools-top-block'>
                        <TableTools />
                        <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />
                    </div>

                </Card >
                <Card sx={{ m: 2 }} variant="outlined">
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>


                    {list &&
                        <DataGrid
                            paginationMode="server"
                            rows={list}
                            columns={columns}
                            page={pageNumber}
                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            onPageChange={handlePageChange}
                            autoHeight
                            density={"comfortable"}
                        />
                    }
                </Card>
            </>

        </>
    );
};

export default Index;

< PageHeader title={"Backlink Sites Master"} />