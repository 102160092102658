import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Card,
    CardContent,
    Typography,
    Grid,
    Divider,
    Select,
    MenuItem,
    DialogTitle,
    Backdrop,
    CircularProgress,
    InputLabel,
    TextareaAutosize,
    TextField,
    Slide,
} from "@mui/material";
import { Backlinks } from '../../../../api/Endpoints/Backlinks';

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const Details = ({ SiteID }) => {
    const classes = useStyles();
    const { id } = useParams();



    const [Loading, setLoading] = useState(false)
    const [Data, setData] = useState([])


    const fetchDetails = async () => {
        setLoading(true);
        try {
            const response = await Backlinks.getBacklinksitestDetails({ id: id });
            setData(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchDetails()
    }, [])

    // Function to mask the password
    const maskPassword = (password) => {
        if (password) {
            return '*'.repeat(password.length); // Replace all characters with asterisks
        }
        return ''; // Handle undefined or empty password
    };

    return (
        <>
            {Loading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={Loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        User ID:
                                    </Typography>
                                    <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                        {Data?.user_id}
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Password:
                                    </Typography>
                                    <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                        {maskPassword(Data?.password)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Site Name :
                                    </Typography>
                                    <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                        {Data?.site_name}

                                    </Typography>

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        URL:
                                    </Typography>
                                    <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                        {Data?.url}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Site Category :
                                    </Typography>
                                    <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                        {Data?.site_category}

                                    </Typography>

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Site Type:
                                    </Typography>
                                    <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                        {Data?.site_type}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Divider className={classes.divider} />

                            <Grid item xs={12} sm={12} mt={2}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Remarks :
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        {Data?.remarks}

                                    </Typography>

                                </Typography>


                            </Grid>
                        </CardContent>
                    </Card>

                </>
            )
            }
        </>
    );
};

export default Details;
