import { Button, Card, CircularProgress, Grid, TablePagination, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Create from '../../../../../tasks/create'
import { Task } from '../../../../../../../api/Endpoints/Task';
import moment from 'moment';
import { Waveform } from '@uiball/loaders';
import { CheckCircle, CheckCircleOutlineRounded, Edit, NotInterested, RadioButtonUnchecked } from '@mui/icons-material';
import BasicTabs from '../../../../../tasks/TabMenus';
import { Plus } from '../../../../../../../icons/plus';
import CreateNotes from '../../../notes/create-note';
import CopyModal from './add-copy';
import { CreativeCalenderApi } from '../../../../../../../api/Endpoints/CreativeCalender';
import DetailView from './detailview';

function PostCopy({ postID, refresh, setRefresh, fetchPosts }) {

    // console.log(refresh);

    const [pageNumber, setPageNumber] = useState(0);
    // const [refresh, setRefresh] = useState(0);
    const [editId, setEditId] = useState();
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [noteEditID, setNoteEditID] = useState()
    const [detailViewId, setDetailViewId] = useState()
    const [detalView, setDetalView] = useState(false)
    const [relatedID, setrelatedID] = useState()


    const [limit, setlimit] = useState(10)
    const [total, setTotal] = useState()
    const [scrolLoading, setscrolLoading] = useState(false)

    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            // You can adjust the value (10) based on your preference for when to trigger the function
            // Call your function here when scrolling to the bottom
            setlimit(limit + 10)
        }
    };

    useEffect(() => {
        if (limit > 10 && total !== list?.data?.length) {
            scrollFunction();
        }
    }, [limit])

    const scrollFunction = () => {
        setscrolLoading(true);
        CreativeCalenderApi.getCopies({ creative_calendar_post_id: postID, limit: limit }).then(response => {
            console.log(response.data);
            setList(response.data);
            setTotal(response?.data?.meta?.total)
            setscrolLoading(false);
        }).catch((error) => {
            setscrolLoading(false)
        })
    }


    const handleDetailView = (id) => {
        setDetailViewId(id)
        setDetalView(true)
        setrelatedID()
    }


    const handleNoteCreate = (Rid) => {
        setNoteEditID(0)
        setrelatedID(Rid)
    }

    const handleNoteEdit = (id, Rid) => {
        setNoteEditID(id)
        setrelatedID(Rid)
    }

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        // setRefresh(Math.random)
    }

    const handleEdit = (id) => {
        setEditId(id)
    }

    const handleChartClose = () => {

    }

    const handleChangePage = (event, newPage) => {
        setPageNumber(newPage);
    };

    const statusOptions = [
        { label: 'Good to proceed', value: 'good', icon: <CheckCircle style={{ color: 'green', fontSize: '17px', verticalAlign: 'middle' }} fontSize='small' /> },
        { label: 'Not impressed but OK to proceed', value: 'okay', icon: <CheckCircleOutlineRounded style={{ color: 'green', fontSize: '17px', verticalAlign: 'middle' }} fontSize='small' /> },
        { label: 'No Selection', value: 'no_comment', icon: <RadioButtonUnchecked style={{ color: 'black', fontSize: '17px', verticalAlign: 'middle' }} fontSize='small' /> },
        { label: 'Rejected', value: 'rejected', icon: <NotInterested style={{ color: 'red', fontSize: '17px', verticalAlign: 'middle' }} fontSize='small' /> },
    ];

    const fetchTable = () => {
        setLoading(true)
        // page: parseInt(pageNumber) + 1,
        CreativeCalenderApi.getCopies({ creative_calendar_post_id: postID, limit: 25 }).then(response => {
            setList(response.data);
            setLoading(false)
        }).catch(error => {
            console.log(error);
            setLoading(false)
        })
    }

    console.log(list);

    useEffect(() => {
        fetchTable()
    }, [postID, refresh])



    return (


        <>


            {
                detalView &&
                <DetailView open={DetailView} id={detailViewId} handleClose={setDetalView} setId={setDetailViewId} />
            }
            {
                loading ?
                    (
                        <Grid mt={'40%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Waveform
                                size={40}
                                lineWeight={4}
                                speed={1}
                                color="black"
                            />
                        </Grid>
                    ) : (

                        <>
                            <CopyModal id={editId} setId={setEditId} postID={postID} setRefresh={setRefresh} />
                            <CreateNotes fetchPosts={fetchPosts} parent={'post'} relatedID={relatedID} relatedType={'creative_calendar_post_copy'} from={'copy'} editID={noteEditID} setEditID={setNoteEditID} fetch={fetchTable} />

                            <Grid container>
                                <Grid container style={{ height: '59vh', maxHeight: '59vh', overflowY: 'auto', background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0))' }}>
                                    {
                                        list?.data?.length > 0 ? (
                                            <>{
                                                list?.data?.map((obj, index) => (
                                                    <Grid container key={index} item md={12}>
                                                        <Grid p={1} pb={3} pt={0} container item md={12} >
                                                            <Card sx={{ p: 1, pb: 1, boxShadow: 4, width: '100%' }} >
                                                                <Grid item md={12} display={'flex'} justifyContent={'space-between'}>
                                                                    <Grid item md={11}>

                                                                        <a onClick={() => handleDetailView(obj?.id)} style={{ fontSize: '15px', cursor: 'pointer', textAlign: 'justify' }}>
                                                                            <a style={{whiteSpace: 'pre-line'}}>{obj?.copy_text}</a>
                                                                        </a>

                                                                    </Grid>
                                                                    <Grid display={'flex'} justifyContent={'end'} item md={1}>
                                                                        <a onClick={() => handleEdit(obj?.id)}>
                                                                            <Edit style={{ cursor: 'pointer', color: 'blue', fontSize: '13px' }} fontSize='small' />
                                                                        </a>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item display={'flex'} justifyContent={'end'} md={12}>
                                                                    <Grid item>
                                                                        <a style={{ fontSize: '13px', color: 'grey' }}>{obj?.created_user?.name} {moment(obj?.created_at).format('DD MMM YYYY')}</a>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item display={'flex'} justifyContent={'space-between'} style={{ borderTop: '1px solid #e8e8e8' }} md={12}>
                                                                    <Grid>
                                                                        <a style={{ fontSize: '13px', verticalAlign: 'middle' }}>Notes :</a>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <a onClick={() => handleNoteCreate(obj?.id)} style={{ fontSize: '13px', color: 'blue', cursor: 'pointer' }}>
                                                                            <Plus />
                                                                        </a>
                                                                        {/* <CreateNotes parent={'post'} relatedID={obj?.id} relatedType={'creative_calendar_post_copy'} from={'copy'} editID={noteEditID} setEditID={setNoteEditID} fetch={fetchTable} /> */}
                                                                    </Grid>
                                                                </Grid>
                                                                {
                                                                    obj?.notes?.map((note, index) => (
                                                                        <Grid style={{ borderBottom: '1px solid lightgrey' }} container key={index} pl={2} p={1} item display={'flex'} justifyContent={'space-between'} md={12}>
                                                                            <Grid container item md={11} display={'flex'} alignItems={'center'}>
                                                                                <Grid item md={1}>
                                                                                    {statusOptions.find(option => option.value === note.status)?.icon}
                                                                                    {/* <a>
                                                                                        <CheckCircle style={{ fontSize: '17px', verticalAlign: 'middle' }} />
                                                                                    </a> */}
                                                                                </Grid>
                                                                                <Grid item md={10} display={'flex'} justifyContent={'space-between'}>
                                                                                    <a style={{ fontSize: '15px', verticalAlign: 'middle' }}>{note?.note}</a>
                                                                                    <a style={{ fontSize: '13px', color: 'grey' }}>~{note?.created_user?.name} {moment(note?.created_at).format('DD-MM-YYYY')} </a>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item md={1}>
                                                                                <a onClick={() => handleNoteEdit(note?.id, obj?.id)} style={{ fontSize: '13px', color: 'blue', cursor: 'pointer' }}>
                                                                                    <Edit style={{ cursor: 'pointer', color: 'blue', fontSize: '13px', verticalAlign: 'middle' }} />
                                                                                </a>
                                                                            </Grid>
                                                                            {/* <Grid item display={'flex'} justifyContent={'end'} md={12}>
                                                                                <Grid item mr={3}>
                                                                                    <a style={{ fontSize: '13px', color: 'grey' }}>~{note?.created_user?.name} </a>
                                                                                </Grid>
                                                                            </Grid> */}
                                                                        </Grid>

                                                                    ))
                                                                }
                                                            </Card>
                                                        </Grid>

                                                    </Grid>
                                                ))}
                                                {
                                                    scrolLoading &&
                                                    <Grid item md={12} display={'flex'} justifyContent={'center'}>
                                                        <CircularProgress />
                                                    </Grid>
                                                }
                                            </>
                                        )
                                            : <Grid md={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                <a> No Copy Found</a>
                                            </Grid>

                                    }
                                </Grid>

                            </Grid>
                        </>
                    )}
        </>
    )
}

export default PostCopy
