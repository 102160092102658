import { Avatar, CircularProgress, Grid, TablePagination, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import CreateItems from './create-items'
import { Edit, ExpandLess, ExpandMore } from '@mui/icons-material'
import { CreativeCalenderApi } from '../../../../../api/Endpoints/CreativeCalender'
import { useState } from 'react'
import moment from 'moment'
import { Waveform } from '@uiball/loaders'

function ItemsList({ calendarID, handleOpenPostDetails, setPostID, posts }) {

  const [list, setList] = useState(posts)
  const [loading, setLoading] = useState(false)
  const [editID, setEditID] = useState(0)
  const [pageNumber, setPageNumber] = useState(0);

  const [limit, setlimit] = useState(10)
  const [total, setTotal] = useState()
  const [scrolLoading, setscrolLoading] = useState(false)

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      // You can adjust the value (10) based on your preference for when to trigger the function
      // Call your function here when scrolling to the bottom
      setlimit(limit + 10)
    }
  };

  useEffect(() => {
    if (limit > 10 && total !== list?.data?.length) {
      scrollFunction();
    }
  }, [limit])


  const [isExpanded, setIsExpanded] = useState(false);
  const [expandableId, setexpandableId] = useState()

  const handleToggleExpand = (id) => {
    // console.log('clicked');

    if (id == expandableId) {
      setIsExpanded((prevExpanded) => !prevExpanded);
    } 

    setexpandableId(id)
  };

  const scrollFunction = () => {
    setscrolLoading(true);
    CreativeCalenderApi.getPosts({ creative_calendar_id: calendarID, limit: limit }).then(response => {
      console.log(response.data);
      setList(response.data);
      setTotal(response?.data?.meta?.total)
      setscrolLoading(false);
    }).catch((error) => {
      setscrolLoading(false)
    })
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleEdit = (e, id) => {
    e.stopPropagation();
    setEditID(id)
  }


  const fetchPosts = () => {
    setLoading(true);
    CreativeCalenderApi.getPosts({ creative_calendar_id: calendarID, page: parseInt(pageNumber) + 1 }).then(response => {
      console.log(response.data);
      setList(response.data);
      setTotal(response?.data?.meta?.total)
      setLoading(false);
    }).catch((error) => {
      setLoading(false)
    })
  }


  const fetch = () => {
    setPostID()
    fetchPosts()
  }




  useEffect(() => {
    fetchPosts();
  }, [calendarID, pageNumber])



  return (
    <>
      {
        loading ?
          (
            <Grid mt={'70%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Waveform
                size={40}
                lineWeight={4}
                speed={1}
                color="black"
              />
            </Grid>
          ) : (
            <>

              <Grid style={{ height: '79vh', borderRight: '3px solid #e8e8e8' }}>
                <Grid item md={12} style={{ borderBottom: '3px solid #e8e8e8' }} p={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                  <Grid item md={6}>
                    <a style={{ fontWeight: 500, fontSize: '15px', color: 'grey' }}>POSTS</a>
                  </Grid>
                  <Grid item md={6} display={'flex'} justifyContent={'end'}>
                    <CreateItems calendarID={calendarID} editID={editID} setEditID={setEditID} fetch={fetch} />
                  </Grid>
                </Grid>
                <Grid container style={{ maxHeight: '69vh', overflowY: 'auto', spacing: 0 }} onScroll={handleScroll}>
                  {
                    list?.data?.length > 0 ?
                      <>
                        {list?.data?.map((obj, index) => (
                          <Grid
                            container
                            style={{ borderBottom: '1px solid #e8e8e8', cursor: 'pointer', marginBottom: 0 }}
                            // onClick={() => handleOpenPostDetails(obj?.id)}
                            key={index}
                            item md={12}
                          >
                            <Grid item md={12} style={{ marginBottom: 0 }}>
                              <Grid container p={1.5} pb={0} display={'flex'} alignItems={'center'}>
                                <Grid item md={3.4}>
                                  <Grid
                                    sx={{
                                      width: '100%',
                                      borderTop: '2px solid orange',
                                      borderLeft: '1px solid grey',
                                      borderRight: '1px solid grey',
                                      borderBottomLeftRadius: '5px',
                                      borderBottomRightRadius: '5px',
                                    }}
                                  >
                                    <Grid display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                      <a style={{ fontWeight: 600 }}>{moment(obj?.post_date).format('DD')}</a>
                                      <a style={{ fontSize: '13px' }}>{moment(obj?.post_date).format('dddd')}</a>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid onClick={() => handleOpenPostDetails(obj?.id)} item md={8.6} pl={1}>
                                  <Grid mt={1} display={'flex'} justifyContent={'space-between'}>
                                    <Grid item md={11}>
                                      {obj?.title?.length > 16 ? (
                                        <Tooltip title={obj?.title}>
                                          <a style={{ fontWeight: 400 }}>{obj?.title?.slice(0, 16)}...</a>
                                        </Tooltip>
                                      ) : (
                                        <a style={{ fontWeight: 400 }}>{obj?.title}</a>
                                      )}
                                    </Grid>
                                    <Grid item md={1}>
                                      <a>
                                        <Edit
                                          className='edit-post'
                                          onClick={(e) => handleEdit(e, obj?.id)}
                                          style={{ cursor: 'pointer', color: 'blue', fontSize: '13px' }}
                                          fontSize='small'
                                        />
                                      </a>
                                    </Grid>
                                  </Grid>
                                  <Grid container mt={1} display={'flex'} justifyContent={'space-between'}>
                                    <Grid item md={6}>
                                      <a style={{ fontSize: '13px', color: 'grey' }}>{obj?.created_user?.name}</a>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid mr={1} style={{ width: '100%' }} display={'flex'} justifyContent={'end'}>
                              {
                                (isExpanded && expandableId == obj?.id) ?
                                  <ExpandLess onClick={() => handleToggleExpand(obj?.id)} style={{ cursor: 'pointer' }} />
                                  :
                                  <ExpandMore onClick={() => handleToggleExpand(obj?.id)} style={{ cursor: 'pointer' }} />
                              }
                            </Grid>
                            {
                              (isExpanded && expandableId == obj?.id) &&
                              (
                                <Grid p={1.5} item md={12}>

                                  <Grid item display={'flex'} alignItems={'center'} sx={12} md={12}>
                                    <Grid item md={2.5}>
                                      <a style={{ fontSize: '13px', color: 'grey' }}>Tasks:</a>
                                    </Grid>
                                    <Grid item md={9.5} p={1} display={'flex'} alignItems={'center'}>
                                      <Tooltip title={'Completed'}>
                                        <Avatar style={{ backgroundColor: 'lightgreen', marginRight: 3, width: 35, height: 35 }}>
                                          {obj?.task_status?.find((statusObj) => statusObj?.status === 'Completed') ? obj?.task_status?.find((statusObj) => statusObj?.status === 'Completed')?.count : 0}
                                        </Avatar>
                                      </Tooltip>
                                      <Tooltip title={'Not Started'}>
                                        <Avatar style={{ backgroundColor: 'white', color: 'black', border: '2px solid black', color: 'black', marginRight: 3, width: 35, height: 35 }}>
                                          {obj?.task_status?.find((statusObj) => statusObj?.status === 'Not Started') ? obj?.task_status?.find((statusObj) => statusObj?.status === 'Not Started')?.count : 0}
                                        </Avatar>
                                      </Tooltip>
                                      <Tooltip title={'In Progress'}>
                                        <Avatar style={{ backgroundColor: 'black', color: 'white', marginRight: 3, width: 35, height: 35 }}>
                                          {obj?.task_status?.find((statusObj) => statusObj?.status === 'In Progress') ? obj?.task_status?.find((statusObj) => statusObj?.status === 'In Progress')?.count : 0}
                                        </Avatar>
                                      </Tooltip>
                                      <Tooltip title={'Review Pending'}>
                                        <Avatar style={{ backgroundColor: 'lightgreen', marginRight: 3, width: 35, height: 35 }}>
                                          {obj?.task_status?.find((statusObj) => statusObj?.status === 'Review Pending') ? obj?.task_status?.find((statusObj) => statusObj?.status === 'Review Pending')?.count : 0}
                                        </Avatar>
                                      </Tooltip>
                                      <Tooltip title={'Review Failed'}>
                                        <Avatar style={{ backgroundColor: 'red', color: 'white', width: 35, height: 35 }}>
                                          {obj?.task_status?.find((statusObj) => statusObj?.status === 'Review Failed') ? obj?.task_status?.find((statusObj) => statusObj?.status === 'Review Failed')?.count : 0}
                                        </Avatar>
                                      </Tooltip>

                                    </Grid>

                                  </Grid>

                                  <Grid item display={'flex'} alignItems={'center'} sx={12} md={12}>
                                    <Grid item md={2.5}>
                                      <a style={{ fontSize: '13px', color: 'grey' }}>Preview:</a>
                                    </Grid>
                                    <Grid item md={9.5} p={1} display={'flex'} alignItems={'center'}>
                                      <Tooltip title={'Good To Proceed'}>
                                        <Avatar style={{ backgroundColor: 'lightgreen', marginRight: 3, width: 35, height: 35 }}>
                                          {obj?.preview_status?.find((statusObj) => statusObj.status === 'good') ? obj?.preview_status?.find((statusObj) => statusObj.status === 'good')?.count : 0}
                                        </Avatar>
                                      </Tooltip>
                                      <Tooltip title={'Not impressed but OK to proceed'}>
                                        <Avatar style={{ backgroundColor: 'white', marginRight: 3, border: '2px solid black', color: 'black', width: 35, height: 35 }}>
                                          {obj?.preview_status?.find((statusObj) => statusObj.status === 'okay') ? obj?.preview_status?.find((statusObj) => statusObj.status === 'okay')?.count : 0}
                                        </Avatar>
                                      </Tooltip>
                                      <Tooltip title={'No Selection'}>
                                        <Avatar style={{ backgroundColor: 'black', marginRight: 3, width: 35, height: 35, color: 'white' }}>
                                          {obj?.preview_status?.find((statusObj) => statusObj.status === 'no_comment') ? obj?.preview_status?.find((statusObj) => statusObj.status === 'no_comment')?.count : 0}
                                        </Avatar>
                                      </Tooltip>
                                      <Tooltip title={'Rejected'}>
                                        <Avatar style={{ backgroundColor: 'red', width: 35, height: 35, color: 'white' }}>
                                          {obj?.preview_status?.find((statusObj) => statusObj.status === 'rejected') ? obj?.preview_status?.find((statusObj) => statusObj.status === 'rejected')?.count : 0}
                                        </Avatar>
                                      </Tooltip>

                                    </Grid>

                                  </Grid>

                                  <Grid item display={'flex'} alignItems={'center'} sx={12} md={12}>
                                    <Grid item md={2.5}>
                                      <a style={{ fontSize: '13px', color: 'grey' }}>Creatives:</a>
                                    </Grid>
                                    <Grid item md={9.5} p={1} display={'flex'} alignItems={'center'}>
                                      <Tooltip title={'Good To Proceed'}>
                                        <Avatar style={{ backgroundColor: 'lightgreen', marginRight: 3, width: 35, height: 35 }}>
                                          {obj?.creative_status?.find((statusObj) => statusObj.status === 'good') ? obj?.creative_status?.find((statusObj) => statusObj.status === 'good')?.count : 0}
                                        </Avatar>
                                      </Tooltip>
                                      <Tooltip title={'Not impressed but OK to proceed'}>
                                        <Avatar style={{ backgroundColor: 'white', marginRight: 3, border: '2px solid black', color: 'black', width: 35, height: 35 }}>
                                          {obj?.creative_status?.find((statusObj) => statusObj.status === 'okay') ? obj?.creative_status?.find((statusObj) => statusObj.status === 'okay')?.count : 0}
                                        </Avatar>
                                      </Tooltip>
                                      <Tooltip title={'No Selection'}>
                                        <Avatar style={{ backgroundColor: 'black', marginRight: 3, width: 35, height: 35, color: 'white' }}>
                                          {obj?.creative_status?.find((statusObj) => statusObj.status === 'no_comment') ? obj?.creative_status?.find((statusObj) => statusObj.status === 'no_comment')?.count : 0}
                                        </Avatar>
                                      </Tooltip>
                                      <Tooltip title={'Rejected'}>
                                        <Avatar style={{ backgroundColor: 'red', width: 35, height: 35, color: 'white' }}>
                                          {obj?.creative_status?.find((statusObj) => statusObj.status === 'rejected') ? obj?.creative_status?.find((statusObj) => statusObj.status === 'rejected')?.count : 0}
                                        </Avatar>
                                      </Tooltip>

                                    </Grid>
                                  </Grid>

                                  <Grid item display={'flex'} alignItems={'center'} sx={12} md={12}>
                                    <Grid item md={2.5}>
                                      <a style={{ fontSize: '13px', color: 'grey' }}>Copys:</a>
                                    </Grid>
                                    <Grid item md={9.5} p={1} display={'flex'} alignItems={'center'}>
                                      <Tooltip title={'Good To Proceed'}>
                                        <Avatar style={{ backgroundColor: 'lightgreen', marginRight: 3, width: 35, height: 35 }}>
                                          {obj?.copy_status?.find((statusObj) => statusObj.status === 'good') ? obj?.copy_status?.find((statusObj) => statusObj.status === 'good')?.count : 0}
                                        </Avatar>
                                      </Tooltip>
                                      <Tooltip title={'Not impressed but OK to proceed'}>
                                        <Avatar style={{ backgroundColor: 'white', marginRight: 3, border: '2px solid black', color: 'black', width: 35, height: 35 }}>
                                          {obj?.copy_status?.find((statusObj) => statusObj.status === 'okay') ? obj?.copy_status?.find((statusObj) => statusObj.status === 'okay')?.count : 0}
                                        </Avatar>
                                      </Tooltip>
                                      <Tooltip title={'No Selection'}>
                                        <Avatar style={{ backgroundColor: 'black', marginRight: 3, width: 35, height: 35, color: 'white' }}>
                                          {obj?.copy_status?.find((statusObj) => statusObj.status === 'no_comment') ? obj?.copy_status?.find((statusObj) => statusObj.status === 'no_comment')?.count : 0}
                                        </Avatar>
                                      </Tooltip>
                                      <Tooltip title={'Rejected'}>
                                        <Avatar style={{ backgroundColor: 'red', width: 35, height: 35, color: 'white' }}>
                                          {obj?.copy_status?.find((statusObj) => statusObj.status === 'rejected') ? obj?.copy_status?.find((statusObj) => statusObj.status === 'rejected')?.count : 0}
                                        </Avatar>
                                      </Tooltip>

                                    </Grid>
                                  </Grid>

                                </Grid>
                              )
                            }
                          </Grid>))}
                        {
                          scrolLoading &&
                          <Grid item md={12} display={'flex'} justifyContent={'center'}>
                            <CircularProgress />
                          </Grid>
                        }

                      </>
                      :
                      <Grid display={'flex'} justifyContent={'center'} alignItems={'center'} md={12} height={'69vh'}>
                        No Post Found
                      </Grid>
                  }
                </Grid>

                {/* {
                  list?.data?.length > 9 ?
                    <Grid style={{ marginTop: 'auto' }} item md={12}>
                      <TablePagination
                        component="div"
                        count={list?.meta?.total}
                        rowsPerPage={list?.meta?.per_page}
                        page={pageNumber}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[]}
                        labelRowsPerPage=""
                        SelectProps={{ native: true }}
                      />
                    </Grid> :
                    ''
                } */}


              </Grid>

            </>
          )
      }
    </>

  )
}

export default ItemsList
