import React, { useState, useEffect } from 'react'
import Create from './create';
import { Backdrop, Button, Card, CircularProgress, TextField } from "@mui/material";
import { ReportIssue } from '../../../api/Endpoints/ReportIssue';
import { DataGrid } from '@mui/x-data-grid';
// import Delete from '../project-portal/pages/common/Popup/Delete';
import DetailView from './details';

function Complaints() {

    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [ClientID, setClientID] = useState(0)
    const [deleteId, setDeleteId] = useState(false)
    const [detailViewID, setDetailViewID] = useState(false)


    const columns = [
        // { field: 'id', headerName: 'ID' },
        {
            field: 'title', headerName: 'Title', width: 200, renderCell: (params) => (
                <>
                    <Button
                        size="small"
                        style={{ marginLeft: 1 }} onClick={() => openDetailview(params.id)}>
                        {/* {params.row.title} */}
                    </Button>
                </>
            )
        },
        {
            field: 'complaint', headerName: 'Complaint', width: 200, renderCell: (params) => (
                <>
                    <Button
                        size="small"
                        style={{ marginLeft: 1 }} onClick={() => openDetailview(params.id)}>
                        {/* {params.row.title} */}
                    </Button>
                </>
            )
        },
        {
            field: 'reported-on', headerName: 'Reported On', width: 200, renderCell: (params) => (
                <>
                    <Button
                        size="small"
                        style={{ marginLeft: 1 }} onClick={() => openDetailview(params.id)}>
                        {/* {params.row.title} */}
                    </Button>
                </>
            )
        },
        {
            field: 'resolution', headerName: 'Resolution', width: 200, renderCell: (params) => (
                <>
                    <Button
                        size="small"
                        style={{ marginLeft: 1 }} onClick={() => openDetailview(params.id)}>
                        {/* {params.row.title} */}
                    </Button>
                </>
            )
        },
    ]


    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    // const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }

    const handleDelete = (id) => {
        setDeleteId(id)
    }
    const handleEdit = (id) => {

        setRefresh(Math.random);
        setEditId(parseInt(id));
    }
    const fetchTable = () => {
        setLoading(true);
        ReportIssue.get({ keyword: searchKeyword, page: Number(pageNumber) + 1 }).then(response => {
            console.log(response);
            setList(response.data.data);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })


    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    useEffect(async () => {
        fetchTable();
    }, [searchKeyword, listType, pageNumber])

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }


    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const openDetailview = (id) => {
        setDetailViewID(id)
    }

    return (
        <div>
            {/* {
                deleteId &&
                <Delete
                    ID={deleteId}
                    setID={setDeleteId}
                    setDeletePopup={setDeleteId}
                    Callfunc={() => fetchTable()}
                    url="/reported-issues/delete?id="
                    title="Issue"
                />
            } */}
            {
                detailViewID &&
                <DetailView id={detailViewID} handleClose={setDetailViewID} />
            }
            <h2 style={{ marginLeft: 6, marginTop: "20px" }}>Report Complaints</h2>
            <Card sx={{ p: 2, display: "flex", justifyContent: "end" }} variant="outlined">
                <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />
            </Card >
            <Card sx={{ m: 2 }} variant="outlined">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                {typeof list.data === "object" &&
                    <DataGrid
                        paginationMode="server"
                        rows={list?.data}
                        columns={columns}
                        page={pageNumber}
                        pageSize={10}
                        rowHeight={20}
                        rowsPerPageOptions={[10]}
                        rowCount={list.total}
                        onPageChange={handlePageChange}
                        autoHeight
                        density={"comfortable"}
                    />
                }
            </Card>
        </div>
    )
}

export default Complaints

