import React from 'react'
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    Select,
    Slide, TextField, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-hot-toast';
import { DeleteOutline } from '@mui/icons-material';
import { LoadingButton } from "@mui/lab";
import { MeetingIdContext } from '../../../../../contexts/MeetingID/meetingID';
import { Meetings } from '../../../../../api/Endpoints/Meeting';
import Delete from '../../../common/Popup/Delete';
import TextInput from '../../../../form/TextInput';
function Guests({meetingID, projectID, fetchList, list }) {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({});
    const { MeetingID } = MeetingIdContext();
    const [showDeletePopup, setDeletePopup] = useState(false)

    const [ID, setID] = useState(0)
    const columns = [
        {
            field: 'client_user_name',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small" >{params.row?.client_user_name}</Button >
                    </>
                );
            },
        },

        {
            field: 'client_user_email',
            headerName: 'Email',
            width: 250,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <>
                        <Button style={{ color: 'black' }} size="small">{params.row?.client_user_email}</Button >
                    </>
                );
            },
        },

        // {
        //     field: 'meeting_link',
        //     headerName: 'Meeting Link',
        //     width: 300,
        //     renderCell: (params) => {
        //         const meetingLink = params.value; // Assuming 'meeting_link' is the field containing the URL
        //         return (
        //             <a href={meetingLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        //                 {meetingLink}
        //             </a>
        //         );
        //     }
        // }
        // ,



        // {
        //     field: 'meeting_on', headerName: 'Meeting On', width: 200, renderCell: (params) => {

        //         if (params.value) {
        //             return moment(params.value).format('DD-MM-YYYY - hh:mm:ss')
        //         } else {
        //             return "Not started";
        //         }
        //     }
        // },

        // {field: 'status', headerName: 'Status', width: 100, renderCell: (params) => <>{params.status}</> },
        {
            field: 'action', headerName: 'delete', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <>
                    <Button style={{ color: 'black' }} size={'small'} onClick={() => handleDeleteAlert(id)}>
                        <DeleteOutline />
                    </Button>
                </>;
            }

        },

        // {
        //     field: 'delete', headerName: 'Delete', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
        //         let id = params.value?.id;
        //         return <><Button size={'small'}
        //             onClick={() => handleDelete(id)}>Delete</Button>
        //         </>;
        //     }
        // },
    ]
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "ADD",
        loading: false,
        disabled: false,
    });
    const onSubmit = async (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: false });
    
        const dataToSubmit = {
            meetings_id:  meetingID,
            client_user_name: data.client_user_name,
            client_user_email: data.client_user_email,
        };


        let action;
        action = await Meetings.addAttendees(dataToSubmit);
        console.log(action);
        if (action.data.status === "success") {
            fetchList()
            toast.success(action.data.message)
        }

        setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
    }
    const handleDeleteAlert = (id) => {
        setID(id)
        setDeletePopup(true)
    }


    return (

        <div>
            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                Callfunc={() => fetchList()}
                url="/meeting-attendee/delete?id="
                title="Attendee" />}

            <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                    <TextInput
                        control={control}
                        name="client_user_name"
                        label="Name"
                        value={watch('client_user_name')}
                        re
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <TextInput
                        control={control}
                        name="client_user_email"
                        label="Email"
                        value={watch('client_user_email')}
                    />
                </Grid>
                <Grid item xs={12} sm={2} mt={3}>
                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                        onClick={handleSubmit(onSubmit)}
                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                </Grid>
            </Grid>
            <div style={{ height: 500, width: '100%', }}>
                {typeof list === "object" &&
                    <DataGrid
                        paginationMode="server"
                        rows={list}
                        columns={columns}

                        pageSize={10}
                        rowHeight={20}
                        rowsPerPageOptions={[10]}
                        rowCount={list.total}

                        autoHeight={false}
                        density={"comfortable"}
                    />
                }
            </div>
        </div>
    )
}

export default Guests 
