import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DonutChart from '../../form/DonutChart';
import { Grid } from '@mui/material';
import { useState } from 'react';
import moment from 'moment';
import { UserIdContext } from '../../../contexts/UserID/UserID';


const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

export default function BasicCard({ datas, viewTabs, DetailViewOpen }) {
    const { UserID } = UserIdContext();

    return (
        <Card sx={{ width: 580, maxWidth: 580, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',}}>
             {/* border:  moment(datas?.due_date).isBefore(moment(), 'day') && datas?.status !== 'Completed' ? "1px solid red" : '' ,  */}
            <CardContent>
                {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Word of the Day
                </Typography> */}
                <Typography style={{ cursor: 'pointer', fontSize: '14px' }} onClick={() => DetailViewOpen(datas.id)} variant="h6" component="div">
                    {datas.title}
                </Typography>
                {/* <Grid container mt={1} display={'flex'} justifyContent={'space-between'} spacing={1}>
                    {count.map((item, index) => (
                        <Grid item xs={2} key={index}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                                <DonutChart width={140} />
                                <h6 style={{ marginTop: -10,fontSize:'13px' }}>{item.name}</h6>
                            </div>
                        </Grid>
                    ))}
                </Grid> */}
                {/* {moment(datas?.assigned_to_user?.name).format('DD-MM-YYYY')} */}
                <Grid mt={1} display={'flex'} flexDirection={'column'}>
                    <a style={{ fontSize: '13px', fontWeight: 600, marginBottom: 5 }}><span style={{ color: 'black', marginLeft: 'auto' }}>{datas?.project?.name}</span></a>
                    {/* <a style={{ fontSize: '12px', fontWeight: 600, color: 'grey' }}>Assigned By: <span style={{ color: 'black', marginLeft: 'auto' }}>{datas?.assigned_by_user?.name}</span></a> */}
                    <a style={{ fontSize: '12px', fontWeight: 500 }}><span style={{ color: 'black' }}>{datas?.assigned_to_user?.name}</span></a>
                    <a style={{ fontSize: '12px', fontWeight: 500, color: 'grey' }}>Priority:
                        <span style={{ color: 'black', marginLeft: 'auto' }}>
                            {datas?.priority == 1 && ' Critical'}
                            {datas?.priority == 2 && ' High'}
                            {datas?.priority == 3 && ' Medium'}
                            {datas?.priority == 4 && ' Low'}
                        </span>
                    </a>
                    <a style={{ fontSize: '12px', fontWeight: 500, color: 'grey' }}>Due:
                        <span style={{
                            color: moment(datas?.due_date).isBefore(moment(), 'day') && datas?.status !== 'Completed' ? 'red' : 'black',
                        }}>
                            {moment(datas?.due_date).format(' DD-MM-YYYY')}
                        </span>
                    </a>
                    {/* <span style={{ fontSize: '12px', fontWeight: 600, color: 'grey' }}>
                        Status:
                        <span style={{ color: 'black' }}>
                            {
                                (UserID === datas?.assigned_to_id || UserID === datas?.reviewer_id || UserID === datas?.created_by || UserID === datas?.project?.members?.find(item => item.is_project_owner === 1)?.user?.id) ? (
                                    <span style={{ cursor: 'pointer' }} onClick={() => handleStatusChange(datas)}>
                                        {datas?.status}
                                    </span>
                                ) : (
                                    <span>
                                        {datas?.status}
                                    </span>
                                )

                            }
                        </span>
                    </span> */}

                    {/* (UserID == datas?.assigned_to_id || UserID == datas?.reviewer_id || UserID == datas?.created_by || UserID == datas?.project?.members?.find(item => item.is_project_owner == 1)?.user?.id) ? (
                    <a style={{ cursor: 'pointer' }} onClick={() => handleStatusChange(params.row)}>
                        {params.value}
                    </a>
                    ) : (
                    <a>
                        {params.value}
                    </a>
                    ) */}
                </Grid>
            </CardContent>
        </Card>
    );
}
