import React, { useEffect, useState } from "react";
import ReactSelector from "react-select";
import PageHeader from "../common/page-header";
import { Departments } from "../../../api/Endpoints/Departments";
import { useForm } from "react-hook-form";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { Activities } from "../../../api/Endpoints/Activities";
import { Employee } from "../../../api/Endpoints/Employee";
import Tasks from "../dashboard/tasks";
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { WorkPlan } from "../../../api/Endpoints/WorkPlan";
import { Backdrop, Button, Card, CircularProgress, Grid, Typography } from "@mui/material";
function DepartmentDashboard() {

  //Date picker state
  const [dateValue, setDateValue] = useState(dayjs(new Date()));

  const { watch, setValue } = useForm();
  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState();
  const [list, setList] = useState([]);
  const [activity, setActivity] = useState([])
  const [workPlan, setWorkPlan] = useState([])
  const [listNotSignedIn, setListNotSignedIn] = useState([]);
  const [showList, setShowList] = useState(false)
  const [selectedProjectID, setSelectedProjectID] = useState(null);
  // const [selectedUserID, setSelectedUserID] = useState(ID)

  const handleListingSearchChange = (keyword) => {
    setSearchKeyword(keyword);
    setPageNumber(0);
  };
  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  // defining columns added to the table
  // const columns = [
  //   {
  //     field: "name",
  //     headerName: "NAME",
  //     width: 200,
  //     renderCell: (params) => (
  //       <>
  //         <Button
  //           size="small"
  //           onClick={() => params.row}
  //           style={{ color: "black" }}
  //         >
  //           {params.row.name}
  //         </Button>
  //       </>
  //     ),
  //   },
  //   {
  //     field: "email",
  //     headerName: "EMAIL",
  //     width: 250,
  //     renderCell: (params) => <>{params.value}</>,
  //   },
  //   {
  //     field: "last_login",
  //     headerName: "SIGN IN",
  //     width: 130,
  //     renderCell: (params) => {
  //       return params.value ? moment(params.value).format("DD-MM-YYYY") : "";
  //     },
  //   },
  //   {
  //     field: "sign-out",
  //     headerName: "SIGN OUT",
  //     width: 130,
      
  //   },
  // ];

  const activities = [
    {
        field:"title", headerName: "title",width: 200,
    }
  ];
  const workPlanColumn = [
    {
        field:"title", headerName: "title",width: 200,
    }
  ]
  // const notSigned = [
  //   {
  //       field:"name", headerName: "Name",width: 200,
  //   }
  // ]

  // fetching departments
  const fetchDepartments = (e) => {
    Departments.get({ keyword: e, limit: 100 }).then((response) => {
      if (typeof response.data.data.data !== "undefined") {
        setDepartments(response.data.data.data);
      } else {
        console.log("department not fetched");
      }
    });
  };
//   console.log(departments);

  //fetching table data
  // const fetchTable = () => {
  //   setLoading(true);
  //   let from = moment(watch("from")).format("YYYY-MM-DD");
  //   let to = moment(watch("to")).format("YYYY-MM-DD");

  //   Employee.get({
  //     projects_id: selectedProjectID,
  //     from,
  //     to,
  //     department: selectedDepartments,
  //     page: parseInt(pageNumber) + 1,
  //     limit: 30,
  //   }).then((response) => {
  //     setList(response.data.data);
  //     setLoading(false);
  //   });
  // };

  const ActivityList = () => {
    setLoading(true);
    let from = moment(watch('from')).format('YYYY-MM-DD')
    let to = moment(watch('to')).format('YYYY-MM-DD')

    Activities.get({ projects_id: selectedProjectID, from, to, department: selectedDepartments, keyword: searchKeyword, page: parseInt(pageNumber) + 1, limit: 30 }).then(response => {
        setActivity(response.data.data);
        setLoading(false);
    })
}
// console.log(activity)
const WorkPlanList = () => {
    setLoading(true);
    // console.log(searchKeyword);
    WorkPlan.get({  projects_id: selectedProjectID, department: selectedDepartments, keyword: searchKeyword, page: parseInt(pageNumber) + 1, limit: 50 }).then(response => {
        // console.log(response.data.data);
        setLoading(false)
        setWorkPlan(response.data);
        setLoading(false);
    })
}
// console.log(workPlan)
console.log(list);


//fetching not signed in users
const fetchTable = () => {
  setLoading(true);
  Employee.getAttendanceList({ from: dateValue.format('DD-MM-YYYY 00:00:00'), to: dateValue.format('DD-MM-YYYY 23:59:59')}).then(response => {
    setList(response.data.data);
        
    setShowList(true)
    setLoading(false);
})
  Employee.getNotSignedInList({ date: dateValue.format('DD-MM-YYYY 00:00:00') }).then(response => {
      setListNotSignedIn(response.data.data);
      setLoading(false);
  })
}
  useEffect(() => {
    fetchDepartments();
    fetchTable();
    // fetchNotSigned()
  }, []);
  useEffect(() => {
    fetchTable();
    ActivityList();
    WorkPlanList()
  }, [
    searchKeyword,
    pageNumber,
    selectedProjectID,
    selectedDepartments,
    watch("from"),
    watch("to"),
  ]);

  const selectDepartments = (data) => {
    setSelectedDepartments(data?.id);
    setValue("departments", data?.name || "");
  };
  console.log(selectedDepartments);

  const handleUpdate = () => {
    setPageNumber();
    setPageNumber(0);
  };

  const handlePageNumberChange = (event) => {
    const newPageNumber = parseInt(event.target.value, 10) - 1;
    setPageNumber(newPageNumber);
    // Add any other logic you want to perform when the page number changes
  };
  const handleSearchEntry = (event) => {
    setSearchKeyword(event.target.value);
  };
  return (
    <>
      <Grid display={"flex"} alignItems={"center"} gap={"40px"}>
        <PageHeader title={"Department Dashboard"} />
        <ReactSelector
          placeholder="Department"
          onInputChange={fetchDepartments}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          options={departments}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          // inputValue={inProject}
          value={departments.find(
            (options) => options.name === watch("departments")
          )}
          name="departments"
          isClearable
          defaultValue={watch("departments")}
          onChange={(selectedOption) => selectDepartments(selectedOption)}
        />
      </Grid>
      <Grid width={800}  display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
      <PageHeader title={"Attendance"}  />
      <PageHeader  title={"Leave"}  />
      </Grid>
      {loading ? (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : (
      <>
      <Grid display={"flex"} alignItems={"start"} >
      <Card sx={{ m: 2 }} variant="outlined">
      {
                    showList &&
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 450 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Sign in</TableCell>
                                    <TableCell>Sign Out</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list?.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.name} </TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{moment(row.created_at).format('hh:mm A')}</TableCell>
                                        <TableCell>{row.logged_out_time ? moment(row.logged_out_time).format('hh:mm A') : "-"}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
      </Card>
      <Card sx={{ m: 2 }} variant="outlined">
                <TableContainer component={Paper} >
                    <Typography variant={"overline"} sx={{ pl: 2 }}>Not signed in</Typography>
                    <Table sx={{ minWidth: 450 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listNotSignedIn?.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{row.name} </TableCell>
                                    <TableCell>{row.email}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
      </Grid>

      <Grid display={"flex"} alignItems={"center"}  gap={35}>
      <PageHeader title={"Work Plan"}  /> 
      <PageHeader title={"Activities"}  /> 
      <PageHeader title={"Open tasks"}  />
      </Grid>

      <Grid display={"flex"} alignItems={"center"} >
      <Card sx={{  width:400, height: 500, position: "relative" }} variant="outlined">
        {typeof workPlan.data === "object" && (
          <>
            <DataGrid
              paginationMode="server"
              rows={workPlan?.data}
              columns={workPlanColumn}
              page={pageNumber}
              pageSize={workPlan?.per_page}
              rowHeight={20}
              // autoHeight
              // rowsPerPageOptions={[10]}
              rowCount={workPlan?.total}
              onPageChange={handlePageChange}
              density={"comfortable"}
            />
          </>
        )}
      </Card>
      <Card sx={{  width:400, height: 500, position: "relative" }} variant="outlined">
        {typeof activity.data === "object" && (
          <>
            <DataGrid
              paginationMode="server"
              rows={activity?.data}
              columns={activities}
              page={pageNumber}
              pageSize={activity?.per_page}
              rowHeight={20}
              // autoHeight
              // rowsPerPageOptions={[10]}
              rowCount={activity?.total}
              onPageChange={handlePageChange}
              density={"comfortable"}
            />
          </>
        )}
      </Card>
      <Card sx={{  width:400, height: 500, position: "relative" }} variant="outlined">
      {typeof activity.data === "object" && (
          <>
            <DataGrid
              paginationMode="server"
              rows={activity?.data}
              columns={activities}
              page={pageNumber}
              pageSize={activity?.per_page}
              rowHeight={20}
              // autoHeight
              // rowsPerPageOptions={[10]}
              rowCount={activity?.total}
              onPageChange={handlePageChange}
              density={"comfortable"}
            />
          </>
        )}
      </Card>
      </Grid>
      </>)}
    </>
  );
}

export default DepartmentDashboard;
