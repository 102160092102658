import React from "react";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

const AwardDrawer = ({ open, onClose, selectedRow }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        sx={{
          width: 350,
          padding: 3,
          bgcolor: "background.default",
          height: "100%",
          position: "relative",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            color: "#1976d2", // Blue color for close icon
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ mt: 5 }}>
          <Typography
            variant="h5"
            component="h2"
            sx={{ fontWeight: "bold", mb: 3, color: "#333" }}
          >
            {selectedRow?.awards || "Award Details"}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <Box>
              <Typography variant="subtitle1" fontWeight="bold" color="#555">
                Name:
              </Typography>
              <Typography variant="body1" color="#555">
                {selectedRow?.name || "N/A"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" fontWeight="bold" color="#555">
                Type:
              </Typography>
              <Typography variant="body1" color="#555">
                {selectedRow?.awardtype || "N/A"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" fontWeight="bold" color="#555">
                Issue Date:
              </Typography>
              <Typography variant="body1" color="#555">
                {selectedRow?.issuedate
                  ? new Date(selectedRow.issuedate).toLocaleDateString("en-GB")
                  : "N/A"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" fontWeight="bold" color="#555">
                Issued by:
              </Typography>
              <Typography variant="body1" color="#555">
                {selectedRow?.issuedby || "N/A"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AwardDrawer;
