import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Backdrop, Button, Card, CircularProgress, Grid, TextField } from "@mui/material";
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { Users } from '../../../api/Endpoints/Users';
import ActivityTab from './Tabs/workplan';
import OpenTask from './Tabs/openTasks';
import { UserIdContext } from '../../../contexts/UserID/UserID';
import { Task } from '../../../api/Endpoints/Task';
import { Activities } from '../../../api/Endpoints/Activities';
import moment from 'moment';
import WorkPlanSignIn from './Tabs/workplan';
import { WorkPlan } from '../../../api/Endpoints/WorkPlan';
import { Employee } from '../../../api/Endpoints/Employee';
import MissedSign from './Tabs/missedSign';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs({ getLate, missedSign,open }) {
    const { UserID, SignIn } = UserIdContext();
    // console.log(UserID);

    const [value, setValue] = React.useState(0);
    // const [userID, setUserID] = useState()
    const [copiedTasks, setCopiedTasks] = useState()
    const [workplan, setWorkplan] = useState([])
    const [tasks, setTasks] = useState([])
    const [taskPage, setTaskPage] = useState(0)
    const [editId, setEditId] = useState()
    const [project, setProject] = useState()
    const [currentTime, setcurrentTime] = useState(moment().format('HH:mm'))
    const [isLate, setIsLate] = useState(false)




    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    // const fetchUser = async () => {
    //     const accessToken = window.localStorage.getItem('accessToken');
    //     const user = await Users.me(accessToken);
    //     // setUserID(user.data.data.id)
    // }

    const fetchTask = () => {
        Task.get({ limit: 15, assigned_to: UserID, status: ['Not Started', 'In Progress',], page: parseInt(taskPage) + 1 }).then((response) => {
            // console.log(response);
            setTasks(response.data.data)
        })
    }
    const fetchWorkplan = () => {

        WorkPlan.get({ limit: 15, users_id: UserID, }).then((response) => {
            // console.log(response.data);
            const today = moment().format("DD-MM-YYYY");
            // const tomorrow = moment().add(1, 'day').format("DD-MM-YYYY");
            let todaysWorkplan = response.data.data.data.filter(item => moment(item.created_at).format('DD-MM-YYYY') === today);
            // console.log(todaysWorkplan);

            // getHours(todaysActivities)
            setWorkplan(todaysWorkplan)

            // getHours(response.data.data.data)
            // setWorkplan(response.data.data.data)
        })
    }
    const checkAttendance = async () => {
        let response = await Employee.checkAttendence({ date: moment().format("DD-MM-YYYY") });
        if (response.data.status === "error") {
        } else {
            toast.success("Already Signed In")
        }
    }

    const LateCheck = () => {
        if (!SignIn) {
            const lateTime = moment().format('10:00'); // Assuming 10:00 is the late time
            console.log(SignIn);
            if (currentTime > lateTime) {
                console.log('late condition');
                console.log(SignIn, currentTime, lateTime);
                getLate(true)
                setIsLate(true);
            } else {
                setIsLate(false);
            }
        } else {
            setIsLate(false);
        }
    };
    const updateCurrentTime = () => {
        setcurrentTime(moment().format('HH:mm'))
    };

    useEffect(() => {
        updateCurrentTime();
        const intervalId = setInterval(updateCurrentTime, 1000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (!SignIn) {
            LateCheck()
        }
    }, [currentTime])

    useEffect(() => {
        checkAttendance()
        LateCheck()
    }, [])

    useEffect(() => {
        fetchTask()
        // fetchWorkplan() 
    }, [taskPage])


    useEffect(() => {
        fetchTask()
        fetchWorkplan()
    }, [])


    return (
        <>

            <Box sx={{ width: '100%', mt: 1 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ ml: 2 }}>
                        <Tab label="Work Plans" {...a11yProps(0)} />
                        <Tab label="Open Tasks" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <WorkPlanSignIn open={open} setProject={setProject} project={project} fetchWorkplan={fetchWorkplan} userID={UserID} setCopiedTask={setCopiedTasks} workplan={workplan} setWorkplan={setWorkplan} copiedTask={copiedTasks} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <OpenTask open={open} page={taskPage} setPage={setTaskPage} fetchTask={fetchTask} tasks={tasks} setTasks={setCopiedTasks} setTab={setValue} userID={UserID} />
                </TabPanel>


            </Box>
            <Divider />


        </>
    );
}
