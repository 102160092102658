import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, InputLabel, TextField } from '@mui/material';
import TextInput from '../../../../form/TextInput';
import { useForm } from 'react-hook-form';
import DateInput from '../../../../form/DateInput';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import moment from 'moment';
import { CreativeCalenderApi } from '../../../../../api/Endpoints/CreativeCalender';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { Plus } from '../../../../../icons/plus';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

function CreateItems({ calendarID, editID, setEditID, fetch }) {

    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm()


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setEditID(0)
        setOpen(false);
        setValue('title', '')
        setValue('date', '')
        setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
    }
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const onSubmit = (data) => {
        // console.log(data);   
        setFormButtonStatus({ ...formButtonStatus, loading: true });

        let date = ''
        if (data?.date) {
            date = moment(data?.date).format('YYYY-MM-DD HH:mm:ss')
        }

        let dataToSubmit = {
            id: editID,
            project_creative_calendar_id: calendarID,
            title: data?.title,
            post_date: date
        }

        console.log(dataToSubmit);

        let action;
        if (editID > 0) {
            action = CreativeCalenderApi.updatePost(dataToSubmit)
        } else {
            action = CreativeCalenderApi.addPost(dataToSubmit)
        }

        action.then(response => {
            console.log("this is response data", response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            handleClose()
            fetch()
            setOpen(false);
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })


    }

    const getDetails = async () => {
        try {
            let response = await CreativeCalenderApi.getPostDetails({ id: editID });
            console.log(response);
            if (response.status === 200) {
                let data = response?.data?.data;
                // console.log(data);

                setValue('projects_id', { id: data.project?.id, name: data.project?.name })
                setValue('title', data?.title);
                setValue('date', data.post_date);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (editID > 0) {
            setOpen(true)
            getDetails()
        }
    }, [editID])


    return (
        <div>
            <Button size='small' sx={{ height: '23px', width: '23px' }} variant='outlined' onClick={handleOpen}><Plus /></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {editID > 0 ? 'Edit Post' : 'Add Post'}
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid mt={2} sx={{}} item sm={12} >
                            <TextInput control={control} name="title"
                                label="Title"
                                value={watch('title')} />
                        </Grid>
                        <Grid mt={2} sx={{}} item sm={12} >
                            <Grid item xs={12}>
                                <DateInput
                                    control={control}
                                    name="date"
                                    label="Posting Date"
                                    value={watch('date')}
                                />
                            </Grid>
                        </Grid>
                        <Grid mt={2} sx={{}} item sm={12} >
                            <InputLabel>
                                Remarks
                            </InputLabel>

                            <TextField
                                // placeholder='Add Note'
                                {...register('remarks')}
                                // label="Remarks"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={2}
                                sx={{ width: '100%', }}
                                InputLabelProps={{ shrink: true }}
                            // required
                            />
                        </Grid>

                        <Grid display={'flex'} justifyContent={'end'} sx={{ mt: 1 }} item xs={12}>
                            <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    )
}

export default CreateItems
