// Filters.js
import React from "react";
import {
  TextField,
  Box,
  Grid,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FilterListIcon from "@mui/icons-material/FilterList";

export function Filters({
  filter,
  startDate,
  endDate,
  sortOrder,
  selectedName,
  handleFilterChange,
  handleStartDateChange,
  handleEndDateChange,
  handleNameFilterChange,
  handleSortOrderChange,
  rows,
}) {
  return (
    <Box sx={{ marginBottom: 2, marginLeft: "auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            placeholder="Search"
            variant="outlined"
            fullWidth
            value={filter}
            onChange={handleFilterChange}
            sx={{
              height: "40px",
              "& .MuiInputBase-root": { height: "100%" },
              "& .MuiInputLabel-root": {
                top: "50%",
                transform: "translateY(-50%)",
                left: "8px",
              },
              "& .MuiInputBase-input": {
                paddingLeft: "8px",
              },
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="From"
              inputFormat="dd-MM-yyyy"
              value={startDate}
              onChange={handleStartDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputLabelProps={{ shrink: startDate !== null }}
                  placeholder="From"
                  sx={{
                    height: "40px",
                    "& .MuiInputBase-root": { height: "100%" },
                    "& .MuiInputLabel-root": {
                      top: "50%",
                      transform: "translateY(-50%)",
                      left: "8px",
                    },
                    "& .MuiInputBase-input": {
                      paddingLeft: "8px",
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="To"
              inputFormat="dd-MM-yyyy"
              value={endDate}
              onChange={handleEndDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{
                    height: "40px",
                    "& .MuiInputBase-root": { height: "100%" },
                    "& .MuiInputLabel-root": {
                      top: "50%",
                      transform: "translateY(-50%)",
                      left: "8px",
                    },
                    "& .MuiInputBase-input": {
                      paddingLeft: "8px",
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            sx={{ minWidth: "100%", height: "100%" }}
          >
            <InputLabel>Filter by Name</InputLabel>
            <Select
              value={selectedName}
              onChange={handleNameFilterChange}
              label="Filter by Name"
              sx={{ height: "40px" }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {rows.map((row) => (
                <MenuItem key={row.name} value={row.name}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            sx={{ minWidth: "100%", height: "100%" }}
          >
            <InputLabel>Sort by Date</InputLabel>
            <Select
              value={sortOrder}
              onChange={handleSortOrderChange}
              label="Sort by Date"
              sx={{ height: "40px" }}
            >
              <MenuItem value="asc">Ascending</MenuItem>
              <MenuItem value="desc">Descending</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
