import { get, post} from './../Config/config';

export const CheckList = {
    get: (data) => get(`/projects/tasks/check-lists?project_tasks_id=`, {params:data}),
    add: (data) => post('/projects/tasks/check-lists/store', data),
    update: (data) => post(`/projects/tasks/check-lists/update`,data),
    delete: () => get(`/projects/tasks/check-lists/delete?id=`),
    getDetails: (data) => get(`/projects/tasks/check-lists/view?id=`, {params:data}),
    changeStatus: (data) => post(`/projects/tasks/check-lists/change-status`,data),
}
