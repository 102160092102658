import React, { useEffect, useState } from 'react';
import { CloseOutlined, Info, Warning } from "@mui/icons-material";
import CloseIcon from "./CloseIcon";
import WarningIcon from "./WarningIcon";
import moment from "moment";
import { setIn } from "formik";

const LoginStatus = (props) => {
    const [activeHours, setActiveHours] = useState("00:00:00");

    const drawAlert = () => {

        if (props.lateBy > 0) {
            return (<div className="crm-header-block-left">
                <div className="crm-login-alert-block">
                    <div className="login-alert-icon late-time">
                        <WarningIcon />
                    </div>
                    <div className="login-alert-text late-time">
                        You are late by <b>{props.lateBy} Minutes</b>, Logged in time <b>{moment(props.loggedInAt).format('hh:mm A')}</b>
                        <a href="#" className="close">
                            <CloseIcon />
                        </a>
                    </div>
                </div>
            </div>);
        } else {
            return (<div className="crm-header-block-left">
                <div className="crm-login-alert-block">
                    <div className="login-alert-icon ">
                        <Info />
                    </div>
                    <div className="login-alert-text cr-time">
                        Logged in at <b>{moment(props.loggedInAt).format('hh:mm A')}</b>
                    </div>
                </div>
            </div>);
        }


    }

    const drawActiveHours = (t) => {
        let now = moment(t);
        let from = moment(props.loggedInAt);
        var duration = moment.duration(now.diff(from));
        var minutes = duration.asMinutes();
        let time = moment.utc(moment.duration(minutes, "minutes").asMilliseconds()).format("HH:mm:ss");
        setActiveHours(time)
    }

    useEffect(() => {
        if (props.loggedOutAt === null) {
            const interval = setInterval(() => {
                drawActiveHours();
            }, 1000);
            return () => clearInterval(interval);
        } else {
            drawActiveHours(props.loggedOutAt);
        }
    }, [props.loggedOutAt])

    const handleSignOut = () => {
        props.onSignOut();
    }
   


    return (
        <div>
            <div className="crm-header-block">
                {drawAlert(props.loggedInAt)}

                <div className="crm-header-block-right">
                    <div className="crm-header-profile-block">
                        {props.loggedOutAt === null &&
                            <>
                                <div className="crm-header-profile-block-item alert-text">
                                    Active hours
                                </div>
                                <div className="crm-header-profile-block-item working-time">
                                    <a href="#">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13.8333 8.83333C13.8333 12.0533 11.22 14.6667 7.99999 14.6667C4.77999 14.6667 2.16666 12.0533 2.16666 8.83333C2.16666 5.61333 4.77999 3 7.99999 3C11.22 3 13.8333 5.61333 13.8333 8.83333Z"
                                                stroke="#16CC70" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M8 5.33334V8.66668" stroke="#16CC70" strokeLinecap="round"
                                                strokeLinejoin="round"></path>
                                            <path d="M6 1.33334H10" stroke="#16CC70" strokeMiterlimit="10"
                                                strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                        {activeHours}
                                    </a>
                                </div>
                            </>
                        }
                        <div className="crm-header-profile-block-item take-break">
                            <a href="#">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9.05334 15.1667H4.14001C2.31334 15.1667 0.833344 13.68 0.833344 11.86V6.98001C0.833344 5.15334 2.32001 3.67334 4.14001 3.67334H9.05334C10.88 3.67334 12.36 5.16001 12.36 6.98001V11.86C12.36 13.68 10.8733 15.1667 9.05334 15.1667ZM4.14001 4.67334C2.86668 4.67334 1.83334 5.70667 1.83334 6.98001V11.86C1.83334 13.1333 2.86668 14.1667 4.14001 14.1667H9.05334C10.3267 14.1667 11.36 13.1333 11.36 11.86V6.98001C11.36 5.70667 10.3267 4.67334 9.05334 4.67334H4.14001V4.67334Z"
                                        fill="#FF8761"></path>
                                    <path
                                        d="M3.66666 3.16667C3.39332 3.16667 3.16666 2.94 3.16666 2.66667V1.5C3.16666 1.22667 3.39332 1 3.66666 1C3.93999 1 4.16666 1.22667 4.16666 1.5V2.66667C4.16666 2.94 3.93999 3.16667 3.66666 3.16667Z"
                                        fill="#FF8761"></path>
                                    <path
                                        d="M6.33334 3.16667C6.06001 3.16667 5.83334 2.94 5.83334 2.66667V1.5C5.83334 1.22667 6.06001 1 6.33334 1C6.60668 1 6.83334 1.22667 6.83334 1.5V2.66667C6.83334 2.94 6.60668 3.16667 6.33334 3.16667Z"
                                        fill="#FF8761"></path>
                                    <path
                                        d="M9 3.16667C8.72667 3.16667 8.5 2.94 8.5 2.66667V1.5C8.5 1.22667 8.72667 1 9 1C9.27333 1 9.5 1.22667 9.5 1.5V2.66667C9.5 2.94 9.27333 3.16667 9 3.16667Z"
                                        fill="#FF8761"></path>
                                    <path
                                        d="M11.86 12.08C11.5867 12.08 11.36 11.8533 11.36 11.58V5.96667C11.36 5.69334 11.5867 5.46667 11.86 5.46667C13.6867 5.46667 15.1667 6.95334 15.1667 8.77334C15.1667 10.5933 13.68 12.08 11.86 12.08ZM12.36 6.52001V11.0267C13.3933 10.8 14.1667 9.87334 14.1667 8.77334C14.1667 7.67334 13.3933 6.74667 12.36 6.52001Z"
                                        fill="#FF8761"></path>
                                    <path
                                        d="M11.6733 8.5H1.33334C1.06001 8.5 0.833344 8.27333 0.833344 8C0.833344 7.72667 1.06001 7.5 1.33334 7.5H11.6733C11.9467 7.5 12.1733 7.72667 12.1733 8C12.1733 8.27333 11.9467 8.5 11.6733 8.5Z"
                                        fill="#FF8761"></path>
                                </svg>
                                Take a break
                            </a>
                        </div>


                        <div className="crm-header-profile-block-item sign-out">
                            {props.loggedOutAt === null ?
                                <a href="#" onClick={handleSignOut}>
                                    Sign Out From Work
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.19165 4.41001C5.37248 2.31001 6.45165 1.45251 8.81415 1.45251H8.88998C11.4975 1.45251 12.5417 2.49668 12.5417 5.10418V8.90751C12.5417 11.515 11.4975 12.5592 8.88998 12.5592H8.81415C6.46915 12.5592 5.38998 11.7133 5.19748 9.64835"
                                            stroke="#77828F" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M8.75003 7H2.11169" stroke="#77828F" strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                        <path d="M3.41248 5.04584L1.45831 7L3.41248 8.95417" stroke="#77828F"
                                            strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </a> :
                                <a href="#">
                                    Signed out on {moment(props.loggedOutAt).format('hh:mm A')}
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.19165 4.41001C5.37248 2.31001 6.45165 1.45251 8.81415 1.45251H8.88998C11.4975 1.45251 12.5417 2.49668 12.5417 5.10418V8.90751C12.5417 11.515 11.4975 12.5592 8.88998 12.5592H8.81415C6.46915 12.5592 5.38998 11.7133 5.19748 9.64835"
                                            stroke="#77828F" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M8.75003 7H2.11169" stroke="#77828F" strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                        <path d="M3.41248 5.04584L1.45831 7L3.41248 8.95417" stroke="#77828F"
                                            strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </a>
                            }
                        </div>
                        <div className="crm-header-profile-block-item profile">
                            <a href="#">
                                <img src="/user-photo.jpg" alt="" />
                            </a>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginStatus;
