import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';

import moment from 'moment';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Card,
    CardContent,
    Typography,
    Grid,
    Divider,
    Select,
    MenuItem,
    DialogTitle,
    InputLabel,
    TextareaAutosize,
    TextField,
    Slide,
    CircularProgress,
    Backdrop,
    Box,
} from "@mui/material";
import { useEffect } from 'react';
import { useState } from 'react';


const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        border: '1px solid #ccc ',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const Details = ({ dataSet }) => {
    const classes = useStyles();
    const [Loading, setLoading] = useState(false)




    return (
        <>
            {Loading ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '30vh'
                }}>
                    <CircularProgress className='ld-circle' />
                </Box >
            ) : (
                <>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Title:

                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {dataSet.title}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Project:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {dataSet.project.name}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Due Date:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {moment(dataSet.due_date).format('DD-MM-YYYY')}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>
                            {/* <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Assign To:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {dataSet.assigned_to_user.name}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid> */}
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Assigned To:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {dataSet.assigned_to_user.name}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Assigned By:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {dataSet.assigned_by_user.name}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>


                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Reviewer:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {dataSet.assigned_by_user.name}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>

                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Priority:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {dataSet.priority ==1 && 'Critical'}
                                            {dataSet.priority ==2 && 'High'}
                                            {dataSet.priority ==3 && 'Medium'}
                                            {dataSet.priority ==4 && 'Low'}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>

                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Status:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {dataSet.status}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>


                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Description:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            <a>{dataSet.description && parse(dataSet.description)}</a>
                                            {/* {dataSet.description} */}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>

                        </CardContent>
                    </Card>
                </>
            )
            }
        </>
    );
};

export default Details;