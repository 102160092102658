import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Card, CircularProgress, Grid, InputLabel, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../../common/page-header";
import { Activities } from "../../../../api/Endpoints/Activities";
import Create from "./create";
import DetailView from './DetailView';
import ReactSelector from 'react-select';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Employee } from '../../../../api/Endpoints/Employee';
import { Project } from '../../../../api/Endpoints/Project';
import { UserIdContext } from '../../../../contexts/UserID/UserID';
import CurrentDateInput from '../../../form/CurrenDateInput';
import { ReportsApi } from '../../../../api/Endpoints/Reports';
import moment from 'moment';
import { Sort } from '@mui/icons-material';

const scheme = yup.object().shape({

})

const BacklinkReports = (props) => {

    const { UserID, UserName, ID } = UserIdContext();

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });
    const [editId, setEditId] = useState();
    const [refresh, setRefresh] = useState(0);
    const [listByProject, setListByProject] = useState(false)
    const [detailViewId, setDetailViewId] = useState(false)
    const [projects, setProjects] = useState([])
    const [employees, setEmployees] = useState([])
    const [selectedProjectID, setSelectedProjectID] = useState(null)
// Sorting count on descending form 
const [sortCount, setSortCount] = useState([{ field:"activities", Sort:"desc" }])



    const DetailViewOpen = (id) => {
        setDetailViewId(id)
    }
    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }

    const columns = [
        {

            field: 'name', headerName: 'Name', width: 180,
            renderCell: (params) => <p style={{ cursor: 'pointer', color: 'blue' }} >{params.row.name}</p>

        }, {
            field: 'activities', headerName: 'Count', width: 170,
            renderCell: (params) => <p style={{ cursor: 'pointer' }} >{params.value}</p>
        },
       
    ]

    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [selectedUserID, setSelectedUserID] = useState(ID)
    const [loading, setLoading] = useState(false);


    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const fetchTable = () => {
        setLoading(true);
        console.log(searchKeyword);
        ReportsApi.getActivity({ from: moment(watch('from')).format('YYYY-MM-DD 00:00:00'), to: moment(watch('to')).format('YYYY-MM-DD 23:59:59'), page: parseInt(pageNumber) + 1,limit:50 }).then(response => {
            console.log(response.data);
            setList(response.data);
            setLoading(false);
        })
    }


    const fetchProjects = (e) => {
        Project.get({ keyword: e }).then(response => {
            console.log(response);
            if (typeof response.data.data.data !== "undefined") {
                setProjects(response.data.data.data)
            } else {
                return [];
            }
        })
    }

    const fetchEmployees = (e) => {
        console.log(e);
        Employee.get({ keyword: e, limit: 100 }).then(response => {
            // console.log(response.data.data.data);
            if (typeof response.data.data.data !== "undefined") {
                setEmployees(response.data.data.data)
            } else {
                return [];
            }
        })
    }

    const selectProject = (project_id) => {
        console.log(project_id);
        setSelectedProjectID(project_id?.id)
        setValue('projects', project_id?.name || "")

    }

    const selectByUser = (user) => {
        console.log(user);
        setSelectedUserID(user?.id)
        setValue('user', user?.name || "")
    }

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }

    // useEffect(() => {
    //     fetchProjects()
    //     fetchEmployees()
    // }, [])

    // useEffect(() => {
    //     setValue('user', UserName || "")
    //     console.log(UserName);
    // }, [UserName])

    useEffect(() => {
        fetchTable();
        // fetchProjects()
        // fetchEmployees()
    }, [searchKeyword, listType, pageNumber, selectedProjectID, selectedUserID, watch('from'), watch('to')])


     // creating Array of month names
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
    return (
        <>
            {
                detailViewId &&
                <DetailView id={detailViewId} handleClose={setDetailViewId} />
            }
            <PageHeader title={"BackLink Report"} />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: 'space-between' }} variant="outlined">

                <Grid item display={'flex'} alignItems={'center'}>
                    {/* <InputLabel sx={{ mr: 1,fontWeight:500,fontSize:'13px' }}>MONTH</InputLabel> */}
                    <Grid display={'flex'} alignItems={'center'} xs={3} sx={{ px: 2, }} >
                        <InputLabel sx={{ mr: 1 }}>From</InputLabel>
                        <Grid width={'200px'}>
                            <CurrentDateInput
                                control={control}
                                name="from"
                                value={watch('from')}
                            // format='MMMM YYYY'
                            // view={['year', 'month']}
                            // openTo='month'
                            />
                        </Grid>
                    </Grid>

                    <Grid display={'flex'} alignItems={'center'} xs={3} sx={{ px: 2, }} >
                        <InputLabel sx={{ mr: 1 }}>To</InputLabel>
                        <Grid width={'200px'}>
                            <CurrentDateInput
                                placeholder={'to'}
                                control={control}
                                name="to"
                                value={watch('to')}
                            // format='MMMM YYYY'
                            // view={['year', 'month']}
                            // openTo='month'
                            />
                        </Grid>
                    </Grid>
                    {/* <TextField
                    sx={{ml: 8}}
                        style={{ width: 300, marginRight: 10 }}
                        size='small'
                        id="outlined-name"
                        label="Search Backlinks"
                        onChange={handleSearchEntry}
                    /> */}

<Grid display={'flex'} alignItems={'center'} xs={3} sx={{ px: 2, }} >
                        <InputLabel >Select Month</InputLabel>
                        </Grid>
                        <Grid sx={{ width: 200 }} mr={3}>
                        <ReactSelector
                            placeholder="Months"
                            // onInputChange={handleInputChange}
      options={months.map((month, index) => ({ value: index + 1, label: month }))} 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            // options={projects}
                            // getOptionLabel={option => option.name}
                            // getOptionValue={option => option.id}
                            // value={
                            //     projects.find(options =>
                            //         options.name === watch('projects')
                            //     )
                            // }
                            // name='projects'
                            // isClearable
                            // defaultValue={(watch('projects'))}
                            // onChange={(selectedOption) => selectProject(selectedOption)}
                        />
                    </Grid>

                </Grid>

                {/* <Grid display={'flex'}>
                    <TextField
                        style={{ width: 300, marginRight: 10 }}
                        size='small'
                        id="outlined-name"
                        label="Search Activities"
                        onChange={handleSearchEntry}
                    />
                </Grid> */}
            </Grid>
            <Card sx={{ m: 2,height:450 }} variant="outlined">
                {typeof list?.data === "object" &&
                    <DataGrid
                        paginationMode="server"
                        rows={list?.data}
                        columns={columns}
                        page={pageNumber}
                        pageSize={50}
                        rowHeight={20}
                        rowsPerPageOptions={[50]}
                        rowCount={list?.meta?.total}
                        onPageChange={handlePageChange}
                        getRowId={(row) => row.user_id}
                        density={"comfortable"}
                        // sortModel={sortCount}
                        // onSortModelChange={(count) => setSortCount(count)}
                    />
                }
            </Card>
        </>
    );
};

export default BacklinkReports;
