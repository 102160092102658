import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Backdrop, Button, Card, CircularProgress, Grid, TextField } from "@mui/material";
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { Users } from '../../../api/Endpoints/Users';
import ActivityTab from './Tabs/activityTab';
import OpenTask from './Tabs/openTasks';
import { UserIdContext } from '../../../contexts/UserID/UserID';
import { Task } from '../../../api/Endpoints/Task';
import { Activities } from '../../../api/Endpoints/Activities';
import moment from 'moment';
import WorkPlanSignOut from './Tabs/workplanTab';
import { WorkPlan } from '../../../api/Endpoints/WorkPlan';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs({ getHours,open }) {
    const { UserID } = UserIdContext();
    // console.log(UserID);

    const [value, setValue] = React.useState(0);
    // const [userID, setUserID] = useState()
    const [copiedTasks, setCopiedTasks] = useState()
    const [copiedWorkplan, setCopiedWorkplan] = useState()
    const [activities, setActivities] = useState([])
    const [tasks, setTasks] = useState([])
    const [workplan, setWorkplan] = useState([])
    const [taskPage, setTaskPage] = useState(0)




    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    // const fetchUser = async () => {
    //     const accessToken = window.localStorage.getItem('accessToken');
    //     const user = await Users.me(accessToken);
    //     // setUserID(user.data.data.id)
    // }

    const fetchTask = () => {
        Task.get({ limit: 15, assigned_to: UserID, status: ['Not Started', 'In Progress'], page: parseInt(taskPage) + 1 }).then((response) => {
            // console.log(response);
            setTasks(response.data.data)
        })
    }

    const today = moment().format("DD-MM-YYYY");
    const fetchActivities = () => {

        Activities.get({ limit: 40, users_id: UserID,date:today }).then((response) => {
            console.log(response);
            // const tomorrow = moment().add(1, 'day').format("DD-MM-YYYY");
            let todaysActivities = response.data.data.data.filter(item => moment(item.created_at).format('DD-MM-YYYY') === today);
            // console.log(todaysActivities);

            // getHours(todaysActivities)
            // setActivities(todaysActivities)

            getHours(response.data.data.data)
            setActivities(response.data.data.data)
        })
    }
    const fetchWorkplan = () => {

        WorkPlan.get({ limit: 15, users_id: UserID,date:today }).then((response) => {
            // console.log(response.data);
            const today = moment().format("DD-MM-YYYY");
            // const tomorrow = moment().add(1, 'day').format("DD-MM-YYYY");
            let todaysWorkplan = response.data.data.data.filter(item => moment(item.created_at).format('DD-MM-YYYY') === today);
            // console.log(todaysWorkplan);

            // getHours(todaysActivities)
            // setWorkplan(todaysWorkplan)

            // getHours(response.data.data.data)
            setWorkplan(response.data.data.data)
        })
    }
    // console.log('plan'+copiedWorkplan, "task"+copiedTasks);
    useEffect(() => {
        fetchTask()
        // fetchWorkplan() 
        // fetchActivities()
    }, [taskPage])


    useEffect(() => {
        fetchTask()
        fetchWorkplan()
        fetchActivities()
    }, [])


    return (
        <>

            <Box sx={{ width: '100%', mt: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ ml: 2 }}>
                        <Tab label="Activities" {...a11yProps(0)} />
                        <Tab label="Open Tasks" {...a11yProps(1)} />
                        <Tab label="Work Plans" {...a11yProps(2)} />

                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <ActivityTab open={open} copiedPlan={copiedWorkplan} setCopiedPlan={setCopiedWorkplan} fetchActivities={fetchActivities} userID={UserID} setCopiedTask={setCopiedTasks} activities={activities} setActivities={setActivities} copiedTask={copiedTasks} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <OpenTask open={open} setCopiedPlan={setCopiedWorkplan} page={taskPage} setPage={setTaskPage} fetchTask={fetchTask} tasks={tasks} setTasks={setCopiedTasks} setTab={setValue} userID={UserID} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <WorkPlanSignOut open={open} setCopiedTask={setCopiedTasks} setTab={setValue} fetchWorkplan={fetchWorkplan} userID={UserID} setCopiedPlan={setCopiedWorkplan} workplan={workplan} setWorkplan={setWorkplan} copiedPlan={copiedWorkplan} />
                </TabPanel>

            </Box>
            <Divider />

           
        </>
    );
}
