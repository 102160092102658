import React, { useEffect, useState } from 'react';
import { useAppSettings } from "../../../hooks/use-app-settings";
import Create from './create';
import moment from "moment";
import { Backdrop, Button, Card, CircularProgress, Grid, List, TextField } from "@mui/material";
import { PreQualifiers } from "../../../api/Endpoints/PreQualifiers";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { VendorPayments } from '../../../api/Endpoints/VendorPayments';
import BasicTabs from './TabMenus';
import Delete from '../common/Popup/Delete';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { VideoShootApi } from '../../../api/Endpoints/VideoShoot';
function Reimbursement(props) {


    const [accounts, setAccounts] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [refresh, setRefresh] = useState(0)
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState();
    const [Tabs, setTabs] = useState(false)
    const [list, setList] = useState([])
    const [detailViewId, setDetailViewId] = useState(false)
    const [ID, setID] = useState()
    const [showDeletePopup, setDeletePopup] = useState(false)
    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    // const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }


    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }


    const handleCreateNew = () => {

    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }


    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const viewTabs = (id) => {
        setID(id)
    }

    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const DetailViewOpen = (id) => {
        setDetailViewId(id)
    }

    // ...
    // const params = { searchKeyword, pageNumber };
    const fetchList = () => {
        // console.log(selectedProject?.id);
        setLoading(true);
        VendorPayments.get({keyword: searchKeyword, page: Number(pageNumber) + 1}).then(response => {
            console.log(response);
            setList(response.data?.data);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        })
    }
    console.log(list);

    useEffect(() => {
        fetchList();
    }, [searchKeyword, pageNumber,])
    const handleDelete = (id) => {
        setID(id)
        setDeletePopup(true)
    }


    const columns = [
        {
            field: 'title', headerName: 'Title', width: 250, renderCell: (params) => (
                <Button
                    size="small"
                    style={{ color: 'black' }}
                    onClick={() => viewTabs(params.id)
                    }
                >
                    {params.row.title}

                </Button >
            ),
        },
        {
            field: 'reimbursement_type',
            headerName: 'Reimbursement Type',
            width: 220,
            renderCell: (params) => {
                return <span>{params.value}</span>;
            }
        },
        {
            field: 'channel',
            headerName: 'Channel',
            width: 220,
            renderCell: (params) => {
                return <span>{params.row?.vendor?.name || params.row?.employee?.name}</span>;
            }
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 100,
            renderCell: (params) => {
                return <span>{params.row?.amount}</span>;
            }
        },

        {
            field: 'report_to',
            headerName: 'Manager Name',
            width: 150,
            renderCell: (params) => {
                return <span>{params.value?.name}</span>;
            }
        },
        {
            field: 'created_user',
            headerName: 'Added By',
            width: 150,
            renderCell: (params) => {
                return <span>{params.value?.name}</span>;
            }
        },
        // { field: 'status', headerName: "Status", width: 100 },
        {
            field: 'Edit',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                <>
                    <a size="small" onClick={() => handleEdit(params.row.id)}>
                        <EditIcon fontSize='small' style={{ color: 'blue', cursor: 'pointer', marginRight: 15 }} />
                    </a>
                </>
            ),
        },
        {
            field: 'delete',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                <>
                    <a size='small' onClick={() => handleDelete(params.id)}>
                        <DeleteIcon fontSize='small' style={{ color: 'red', cursor: 'pointer' }} />
                    </a>

                </>
            ),
        },
    ];

    return (




        <>

            {
                ID &&
                <BasicTabs id={ID} setId={setID} />
            }

            <>
                {loading ? <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop> : null}
                <PageHeader title={'Payments'} onCreateNew={handleCreateNew} />

                <Card sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'space-between' }} variant="outlined" >
                    <TextField
                        style={{ width: 300, marginRight: 10 }}
                        size='small'
                        id="outlined-name"
                        label="Search Payments"
                        onChange={handleSearchEntry}
                    />
                    <Create setDetailView={setDetailViewId} source={''} key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />

                </Card>

                {showDeletePopup && <Delete
                    ID={ID}
                    setID={setID}
                    setDeletePopup={setDeletePopup}
                    Callfunc={() => fetchList()}
                    url="/accounts/vendor-payments/delete?id="
                    title="Vendor Payment" />}
                <Card sx={{ m: 2 }} variant="outlined">
                    {typeof list === "object" &&
                        <DataGrid
                            paginationMode="server"
                            rows={list}
                            columns={columns}
                            page={pageNumber}
                            pageSize={10}
                            rowHeight={20}
                            rowsPerPageOptions={[10]}
                            rowCount={list.total}
                            onPageChange={handlePageChange}
                            autoHeight
                            density={'comfortable'}
                        />
                    }
                </Card>

            </>


        </>
    )
}



export default Reimbursement


