import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    InputLabel,
    TextField,
    Slide,  FormControlLabel, Checkbox
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import TextInput from '../../form/TextInput';
import toast from "react-hot-toast";
import SelectX from '../../form/SelectX';
import moment from "moment";
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import { Account } from "../../../api/Endpoints/Account";
import { Project } from "../../../api/Endpoints/Project";
import { Employee } from '../../../api/Endpoints/Employee';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





const scheme = yup.object().shape({


    title: yup.string().required(),
    description: yup.string(),
    start_date: yup.string(),
    end_date: yup.string(),
    due_date: yup.string(),
    Owner_Id: yup.object(),
    assigned_by_id: yup.object(),
    projects_id: yup.object().shape({
        id: yup.string().required("Please choose a project").typeError("Please choose a project")
    }).required().typeError("Please choose a project"),
    // reviewer_id: yup.object().shape({
    //     id: yup.string().required("Please choose a reviewer").typeError("Please choose a reviewer")
    // }).required().typeError("Please choose a reviewer"),
})


const CurrentStatus = [
    { value: "Not Started", label: 'Not Started' },
    { value: "Development In Progress", label: 'Development In Progress' },
    { value: "Under Maintenance", label: 'Under Maintenance' },
    { value: "Testing In Progress", label: 'Testing In Progress' },
    { value: "Completed", label: 'Completed' },
    { value: "Live ", label: 'Live ' },
];

const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({})
    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [clientId, setClientId] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clients, setClients] = useState([]);
    const [projects, setProjects] = useState([]);

    const handleClose = () => {
        props.setEditId(undefined)
        setOpen(false);
    };

    const handleClickOpen = () => {
        props.onNew()
    };
    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })
    const fetchClients = (e) => {
        const client_id = watch('client_id');
        setClientId(client_id?.id);
        return Account.get({ keyword: e }).then((response) => {
            if (typeof response.data.data.data !== 'undefined') {
                return response.data.data.data;
            } else {
                return [];
            }
        });
    };
    const fetchProjects = async (clientId) => {
        try {
            const response = await Project.get({ accounts_id: clientId });
            if (typeof response.data.data.data !== 'undefined') {
                return response.data.data.data;
            } else {
                return [];
            }
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    const prioriyTask = () => {

    }

        ;

    const handlePriorityChange = (id) => {
        setSelectedPriority(id);
    }


    const approvelstatus = [
        { value: "Not Started", label: 'Not Started' },
        { value: 'In Progress', label: 'In Progress' },
        { value: "Completed", label: 'Completed' },
        { value: 'Manager_Approved', label: 'Manager Approved' },
        { value: 'Needs Revision', label: 'Needs Revision' },
        { value: 'Client Approved', label: 'Client Approved' },
        { value: 'Client_Rejected', label: 'Client Rejected' },
        { value: 'Ready_To_Publish', label: 'Ready To Publish' },
        { value: 'Published', label: 'Published' },

    ];


    const yearList = [];
    for (let year = 2020; year <= 2030; year++) {
        yearList.push({ value: year.toString(), label: year.toString() });
    }


    const Months = [
        {
            value: "January ", label: "January "
        },
        {
            value: "February", label: "February"
        },
        {
            value: "March", label: "March"
        },

        {
            value: "April", label: "April"
        },
        {
            value: "May", label: "May"
        },
        {
            value: "June", label: "June"
        },
        {
            value: "July", label: "July"
        },
        {
            value: "August", label: "August"
        },
        {
            value: "September", label: "September"
        },
        {
            value: "October", label: "October"
        },
        {
            value: "November", label: "November"
        },
        {
            value: "December", label: "December"
        },



    ];






    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })


        let date = moment(data.start_date).format('YYYY-MM-DD');
        let enddate = moment(data.end_date).format('YYYY-MM-DD');
        let duedate = moment(data.due_date).format('YYYY-MM-DD');

        let dataToSubmit = {
            id: props.editId,
            title: data.project_name,
            description: data.description,
            start_date: date,
            due_date: duedate,
            account: data.client_id?.id,
            end_date: enddate
        };

        let action;

        if (props.editId > 0) {

            action = Account.add(dataToSubmit)
        } else {
            action = Account.add(dataToSubmit)
        }
        action.then(response => {
            console.log("this is response data", response.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }





    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            setOpen(true);
        } else if (Number(props.editId) === 0) {

            setOpen(true);
        }

    }, [])

    useEffect(() => {
        fetchClients()
            .then((data) => {
                setClients(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (selectedClient) {
            fetchProjects(selectedClient)
                .then((data) => {
                    setProjects(data);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            setProjects([]);
        }
    }, [selectedClient]);

    const fetchClient = (e) => {
        let cleint_id = watch('client_id')
        return Account.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const fetchEmployees = (e) => {
        let project = watch('projects_id');
        return Employee.get({ keyword: e, projects_id: project?.id }).then(response => {

            if (typeof response.data.data.data !== "undefined") {

                return response.data.data.data;
            } else {
                return [];
            }
        })
    }
    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Create Requirement"}
            </Button>
            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit  Requirement" : "Create Requirement"}</DialogTitle>
                <DialogContent>
                    {!loading ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ErrorMessage
                                errors={errors}
                                name="multipleErrorInput"
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type}>{message}</p>
                                    ))
                                }
                            />
                            <Grid container spacing={2} sx={{ display: 'flex', mt: 1 }}>
                                <Grid item xs={12}>
                                    <TextInput
                                        control={control}
                                        name="title"
                                        label="Title"
                                        value={watch('title')}
                                    />
                                </Grid>
                                <Grid sx={{ p: 1, }} item xs={12}>
                                    <SelectX
                                        label={"Choose Client"}
                                        options={fetchClient}
                                        control={control}
                                        error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                        error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                        name={'client'}
                                        defaultValue={watch('client')}
                                    />
                                </Grid>

                                <Grid sx={{ p: 1, }} item xs={12}>
                                    <SelectX
                                        label={"Choose Project"}
                                        options={fetchProjects}
                                        control={control}
                                        error={errors?.projects_id?.id ? errors?.country_id?.id?.message : false}
                                        error2={errors?.projects_id?.message ? errors?.country_id?.message : false}
                                        name={'projects_id'}
                                        defaultValue={watch('projects_id')}
                                    />
                                </Grid>
                                <Grid sx={{ p: 1 }} item xs={12}>
                                    <SelectX
                                        key={refresh * 0.2}
                                        label={"Assign To"}
                                        options={fetchEmployees}
                                        control={control}
                                        error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                        error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                        name={'owner_id'}
                                        defaultValue={watch('owner_id')}
                                    />
                                </Grid>
                                <Grid sx={{ p: 1, }} item xs={12}>
                                    <InputLabel sx={{
                                        color: "black",
                                        pb: 1
                                    }}>Dimensions</InputLabel>
                                    <TextField
                                        {...register('dimensions')}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        sx={{ width: '100%', }}

                                    />
                                </Grid>
                                <Grid sx={{ p: 1, }} item xs={12}>
                                    <InputLabel sx={{
                                        color: "black",
                                        pb: 1
                                    }}>Description </InputLabel>
                                    <TextField
                                        {...register('description')}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        sx={{ width: '100%', }}

                                    />
                                </Grid>


                                <Grid item xs={12} sx={{ p: 1 }}>

                                    <FormControlLabel control={<Checkbox />} label="Website" />
                                    <FormControlLabel control={<Checkbox />} label="Facebook" />
                                    <FormControlLabel control={<Checkbox />} label="Instagram" />
                                    <FormControlLabel control={<Checkbox />} label="Twitter" />
                                    <FormControlLabel control={<Checkbox />} label="Linkdlin" />
                                    <Grid item xs={12} sx={{ mt: 1 }}>

                                        <FormControlLabel control={<Checkbox />} label="GMB" />
                                        <FormControlLabel control={<Checkbox />} label="Other" />
                                    </Grid>
                                </Grid>


                            </Grid>
                        </form>
                        :
                        <>Loading...</>
                    }

                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        </div >
    );
};

export default Create
