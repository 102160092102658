import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Box, Button, Checkbox, DialogActions, Divider, Grid, InputLabel, MenuItem, Popover, Slide, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-hot-toast';
import { Task } from '../../../../api/Endpoints/Task';
import SelectX from '../../../form/SelectX';
import { Users } from '../../../../api/Endpoints/Users';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline } from '@material-ui/icons';
import Delete from '../../common/Popup/Delete';
import { SubscribeApi } from '../../../../api/Endpoints/Subscribe';
import TextInput from '../../../form/TextInput';
import { CheckList } from '../../../../api/Endpoints/CheckList';
import { CheckCircle, Checklist, DeleteOutlineOutlined, Edit, MoreVert, PanoramaFishEye } from '@mui/icons-material';
import { DotSpinner } from '@uiball/loaders';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({

});


const CheckListTab = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editValues, setEditValues] = useState({})
    const [refresh, setRefresh] = React.useState(false);
    const [checkNotes, setCheckNotes] = useState()
    const [pageNumber, setPageNumber] = useState(0);
    const [deleteID, setDeleteID] = useState()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEls, setAnchorEls] = useState({})
    const [checkNoteEdit, setcheckNoteEdit] = useState(0)

    const handleOpenPopover = (event, noteId) => {
        setAnchorEls({ ...anchorEls, [noteId]: event.currentTarget });
    };

    const handleClosePopover = (noteId) => {
        setAnchorEls({ ...anchorEls, [noteId]: null });
    };

    const id = anchorEl ? 'popover-basic' : undefined;



    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Add",
        loading: false,
        disabled: false,
    });

    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })



    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        props.setEditId()
        setOpen(false);
    };
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleDeleteAlert = (id) => {
        setDeleteID(id)
    }

    const statusChange = (data) => {
        // setLoading(true); // Set loading to true when the checkbox is changed
        const loadingToast = toast.loading('Changing...');
        // console.log(e);

        let status = data.is_completed === 0 ? 1 : 'not completed'
        console.log(status);

        CheckList.changeStatus({ id: data.id, status: status })
            .then((response) => {
                toast.dismiss(loadingToast);
                console.log(response);
                fetchDetails()
                props.setChartOpen()
                props?.fetch()  
                toast.success(response.data.message)
                // Once the operation is completed, set loading back to false
                // setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                // Make sure to handle errors and set loading to false in case of an error
                // setLoading(false);
            });
    };



    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let dataToSubmit = {
            id: checkNoteEdit,
            project_tasks_id: props.id,
            note: data.check_note
        }

        console.log(dataToSubmit);
        let action;

        if (checkNoteEdit > 0) {
            console.log(dataToSubmit);
            action = CheckList.update(dataToSubmit)
        } else {
            console.log(dataToSubmit);
            action = CheckList.add(dataToSubmit)
        }
        action.then((response) => {
            console.log(response.data);
            setFormButtonStatus({ label: "Add", loading: false, disabled: true })
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false })
            toast.success(response.data.message)
            props.count()
            console.log( props.setChartOpen);
            props.setChartOpen()
            props?.fetch()
            setValue('check_note', '')
            fetchDetails()
            setOpen(false)
            setcheckNoteEdit(0)
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            setOpen(false)
            toast.error("server error")
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Add", loading: false, disabled: false });
        })
    }

    const handleDelete = (id) => {
        // console.log(id);
        setDeleteID(id)
        handleClosePopover(id)
    }
    const handleEdit = (data) => {
        handleClosePopover(data.id);
        setcheckNoteEdit(data.id)
        setValue('check_note', data.note)
        setFormButtonStatus({ ...formButtonStatus, label: 'Edit' });
    }
    const handleCancelEdit = () => {
        setcheckNoteEdit(0)
        setValue('check_note', '')
        setFormButtonStatus({ ...formButtonStatus, label: 'Add' });
    }

    const fetchDetails = () => {
        setLoading(true)
        CheckList.get({ project_tasks_id: props.id }).then((response) => {

            console.log(response.data.data.data);
            setCheckNotes(response.data.data.data)

            setLoading(false)
        })
    }

    const deleteFunction = () => {
        fetchDetails()
        props?.count()
    }


    useEffect(() => {
        fetchDetails()
        console.log('refreshing');
    }, [props.refresh])

    useEffect(() => {
        fetchDetails()
        setOpen(true);
    }, [])

    return (
        <div>

            {deleteID && <Delete
                ID={deleteID}
                setID={setDeleteID}
                setDeletePopup={setDeleteID}
                Callfunc={() => deleteFunction()}
                url="/projects/tasks/check-lists/delete?id="
                title="Checklist" />}

            <form onSubmit={handleSubmit(onSubmit)}>

                <ErrorMessage
                    errors={errors}
                    name="multipleErrorInput"
                    render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                            <p key={type}>{message}</p>
                        ))
                    }
                />

                {
                    checkNoteEdit ? <Grid mb={1}><a style={{ fontSize: '14px', color: 'blue' }}>You are editing a Checklist, <span onClick={handleCancelEdit} style={{ textDecoration: 'underline', color: 'red', cursor: 'pointer', fontSize: '14px' }}>Click</span> to cancel</a></Grid>
                        : ''
                }
                <Grid xs={12} display={'flex'} justifyContent={'space-between'}>

                    <Grid width={450} sx={{}} item sm={9} xs={9}>
                        <TextInput
                            isRequired={true}
                            control={control}
                            name="check_note"
                            value={watch('check_note')} />
                    </Grid>

                    <Grid display={'flex'} alignItems={'end'} sx={{}} item sm={3} xs={3}>
                        <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                            variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                    </Grid>
                </Grid>
            </form>

            {/* <Divider sx={{mt:1}} /> */}

            {
                // loading ? (
                //     <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'30%'} mt={10}>
                //         <DotSpinner size={40} speed={0.9} color="blue" />
                //     </Box>
                // ) :
                checkNotes && checkNotes.length > 0 ? (
                    <Grid p={1} style={{ overflowY: 'auto', maxHeight: '500px', border: checkNotes ? '1px solid #ccc' : '', borderRadius: 2.5 }} mt={1} container sx={12} sm={12}>
                        {checkNotes.map((notes, index) => (
                            <div style={{ width: '100%' }} key={index}>
                                <Grid width={'100%'} mb={1} sx={12} sm={12} display={'flex'} alignItems={'start'}>
                                    <Grid width={'90%'} sx={10} sm={10}>
                                        <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>
                                            {notes.note}
                                        </Typography>
                                    </Grid>
                                    <Grid display={'flex'} alignItems={'center'} mt={.6} sx={2} sm={2} pl={2}>
                                        {
                                            notes.is_completed === 1 ?
                                                <a onClick={() => statusChange(notes)}><CheckCircle sx={{ color: 'green', cursor: 'pointer', marginTop: 1 }} fontSize='small' /></a>
                                                :
                                                <a onClick={() => statusChange(notes)}><PanoramaFishEye sx={{ color: 'grey', cursor: 'pointer', marginTop: 1 }} fontSize='small' /></a>
                                        }

                                        {/* <Checkbox style={{ color: notes.is_completed === 1 ? 'green' : '' }} defaultChecked={notes.is_completed === 1} onChange={(e) => statusChange(e, notes)} /> */}
                                        <a data-item-id={notes.id} onClick={(e) => handleOpenPopover(e, notes.id)} style={{ marginLeft: 10, cursor: 'pointer', marginTop: 5 }}><MoreVert fontSize='small' /></a>
                                    </Grid>
                                </Grid>
                                <Popover
                                    id={notes.id} // Use the checklist item's ID as the popover ID
                                    open={Boolean(anchorEls[notes.id])}
                                    anchorEl={anchorEls[notes.id]}
                                    onClose={() => handleClosePopover(notes.id)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={() => handleEdit(notes)}>
                                        <Edit fontSize='small' sx={{ marginRight: 1, color: 'blue' }} />
                                    </MenuItem>
                                    <MenuItem onClick={() => handleDelete(notes.id)}>
                                        <DeleteOutlineOutlined fontSize='small' sx={{ marginRight: 1, color: 'red' }} />
                                    </MenuItem>
                                </Popover>
                                {index !== checkNotes.length - 1 && <Divider />}
                            </div>
                        ))}

                    </Grid>
                ) : null
            }


        </div>
    )
}

export default CheckListTab;
