import React, { useEffect, useState } from "react";
import PageHeader from "../../common/page-header";
import {
  Autocomplete,
  Backdrop,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import DetailView from "./DetailView";

import moment from "moment";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";

function MyTargets({ detailViewId, setDetailViewId }) {
  const [list, setList] = useState({ data: [] });
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [filterTitle,setFilterTitle]=useState('');
  const [filterStatus, setFilterStatus] = useState("All");
  const [filterDueDate, setFilterDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [filteredData, setFilteredData] = useState([]);

  const DetailViewOpen = (id) => {
    setDetailViewId(id);
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 200,
      renderCell: (params) => (
        <p style={{ cursor: "pointer" }}>{params.value}</p>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 550,
      renderCell: (params) => {
        <p>
          {params.value?.length > 100
            ? params.value?.slice(0, 100) + "..."
            : params.value}
        </p>;
      },
    },
    {
      field: "assignedTo",
      headerName: "Assigned",
      width: 200,
      renderCell: (params) => <p>{params.value.join(", ")}</p>,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 150,
      renderCell: (params) => <p>{params.value}</p>,
    },
    {
      field: "addResult",
      headerName: "Add Result",
      width: 100,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.assignedTo.includes("User 1") && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => DetailViewOpen(params.id)}
              >
                <AddIcon />
              </Button>
            )}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    setList({
      data: [
        {
          id: 1,
          title: "Implement New Feature",
          description:
            "Implement a new feature to enhance user experience. This feature will provide users with a more efficient and enjoyable experience.",
          assignedTo: ["User 1", "User 2"],
          dueDate: "1-01-2022",
          status: "Not Started",
          explanation:
            "This task is yet to be started. It is a high-priority task that requires immediate attention.",
        },
        {
          id: 2,
          title: "Optimize Database",
          description:
            "Optimize database performance for faster data retrieval. This will improve the overall system performance and user experience.",
          assignedTo: ["User 2", "User 3"],
          dueDate: "1-01-2022",
          status: "In Progress",
          explanation:
            "This task is currently in progress. The database optimization process has begun, and significant improvements are expected.",
        },
        {
          id: 3,
          title: "Fix Bugs",
          description:
            "Identify and fix all known bugs in the system. This will ensure a more stable and reliable system for the users.",
          assignedTo: ["User 3", "User 4"],
          dueDate: "3-01-2022",
          status: "Partially Achieved",
          explanation:
            "This task has been partially achieved. Most of the bugs have been identified and fixed, but a few minor issues remain.",
        },
        {
          id: 4,
          title: "Deploy to Production",
          description:
            "Deploy the updated system to production. This will make the new features and improvements available to all users.",
          assignedTo: ["User 4", "User 1"],
          dueDate: "2024-07-26",
          status: "Achieved",
          explanation:
            "This task has been successfully achieved. The updated system has been deployed to production without any issues.",
        },
      ],
    });
  }, []);
  return (
    <>
      {detailViewId && (
        <DetailView id={detailViewId} handleClose={setDetailViewId} />
      )}
      <Grid
        container
        spacing={2}
        sx={{
          my: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Grid item xs={12} md={6}>
          <PageHeader
            title={"My Targets"}
            size={16}
            color="#77828F"
            variant={"h6"}
          />
        </Grid>
        {/* <Grid item xs={12} md={2}>
          <Autocomplete
            id="filter-title"
            disableRipple
            options={list.data.map((option) => option.title)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter by Title"
                variant="outlined"
                fullWidth
                value={filterTitle}
                onChange={(e) => {
                  setFilterTitle(e.target.value);
                  console.log(e.target.value);
                  // Filter the table data based on the title
                  const filteredData = list.data.filter(item => item.title === e.target.value);
                  setFilteredData(filteredData);
                }}
              />
            )}
          />
        </Grid> */}
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel id="filter-status-label">Filter by Status</InputLabel>
            <Select
              labelId="filter-status-label"
              id="filter-status-select"
              value={filterStatus}
              label="Filter by Status"
              onChange={(e) => {
                setFilteredData([]);
                setFilterStatus(e.target.value);
                // Filter the table data based on the status
                const filteredData = list.data.filter(
                  (item) =>
                    item.status === e.target.value || e.target.value === "All"
                );
                setFilteredData(filteredData);
              }}
            >
              <MenuItem value="All" selected>
                All
              </MenuItem>
              <MenuItem value="Not Started">Not Started</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Partially Achieved">Partially Achieved</MenuItem>
              <MenuItem value="Achieved">Achieved</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <DatePicker
            label="Due Date"
            variant="inline"
            value={filterDueDate}
            onChange={(date) => {
              console.log(date);
              const formattedDate = date
                ? `${date.getDate()}-${(date.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}-${date.getFullYear()}`
                : "";
              console.log(formattedDate);
              setFilterDueDate(formattedDate);
              // Filter the table data based on the due date
              const filteredData = date
                ? list.data.filter((item) => item.dueDate == formattedDate)
                : list.data;
              setFilteredData(filteredData);
            }}
            renderInput={(params) => <TextField {...params} />}
            clearable
          />
        </Grid>
      </Grid>

      <Card sx={{ m: 2, width: { xs: "100%", md: "auto" } }} variant="outlined">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <DataGrid
          rows={
            filterStatus === "All"
              ? !filterDueDate
                ? list.data
                : list.data.filter((value) => value.dueDate == filterDueDate)
              : filterDueDate
              ? list.data.filter(
                  (item) =>
                    item.status === filterStatus &&
                    item.dueDate === filterDueDate
                )
              : list.data.filter((item) => item.status === filterStatus)
          }
          columns={columns}
          page={pageNumber}
          pageSize={10}
          rowsPerPageOptions={[10]}
          rowCount={filteredData.length}
          onPageChange={handlePageChange}
          autoHeight
          density={"comfortable"}
          style={{ width: { xs: "100%", md: "auto" } }}
        />
      </Card>
    </>
  );
}

export default MyTargets;
